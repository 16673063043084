// Action Type
export const SET_PURCHASER_SSN_PROVIDED = 'SET_PURCHASER_SSN_PROVIDED';
// Action
export function setPurchaserSsnProvidedAction(newValue: boolean) {
    return { type: SET_PURCHASER_SSN_PROVIDED, newValue };
}
// Reducer
export function setPurchaserSsnProvidedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_PURCHASER_SSN_PROVIDED:
            return action.newValue;
        default:
            return state;
    }
}
