import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_FOOTER_FONT_COLOR = 'SET_STYLING_FOOTER_FONT_COLOR';
// Action
export function setStylingFooterFontColorAction(newValue: string) {
    return { type: SET_STYLING_FOOTER_FONT_COLOR, newValue };
}
// Reducer
export function setStylingFooterFontColorReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().colors.footerFontColor;
    }
    switch (action.type) {
        case SET_STYLING_FOOTER_FONT_COLOR:
            return action.newValue;
        default:
            return state;
    }
}
