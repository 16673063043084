import React, { Fragment } from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FuneralAgentFormWrapper from '../../form-components/funeral-agent-form-component/FuneralAgentFormWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import FuneralHomeSearchWrapper from '../../global-components/funeral-home-search-component/FuneralHomeSearchWrapper';
import PageCardUnderlinedSectionWrapper from '../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import BasicOpportunityInformationGroupingWrapper from '../../global-components/basic-opportunity-information-components/basic-opportunity-information-grouping-component/BasicOpportunityInformationGroupingWrapper';
import EnabledCorrectionButtonLinkWrapper from '../../global-components/buttons/enabled-correction-button-link-component/EnabledCorrectionButtonLinkWrapper';
import MockLinkWrapper from '../../global-components/mock-link-component/MockLinkWrapper';
import getEnvironment from '../../../env-config/get-environment';
import { openTabWithUrl } from '../../../toolboxes/reuseable-logic/tab-opener';
import { Element, scroller } from 'react-scroll';

class FuneralAgentStartPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            triedToProgress: false
        };
    }

    static defaultProps = {
        // Default prop values
        formsComplete: false,
        touchAllFields: () => {},
        fieldToJumpTo: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="FuneralAgentStart" metaPageNumber="1" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Start Agent Sale</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please fill out the information below to begin an agent sale. To learn more about eFuneral Agent
                        and signup click{' '}
                        <MockLinkWrapper
                            id="agent-signup"
                            onClick={() => openTabWithUrl(getEnvironment().agentSignupUrl)}
                        >
                            here
                        </MockLinkWrapper>
                        .
                    </PageCardTextWrapper>
                    <Element name="locationSearch">
                        <PageCardUnderlinedSectionWrapper
                            sectionTitle="Location Search"
                            sectionId="location"
                            fullContent={
                                <Fragment>
                                    <FuneralHomeSearchWrapper touched={this.state.triedToProgress} />
                                    <MockLinkWrapper
                                        id="agent-funeral-home-signup"
                                        onClick={() =>
                                            openTabWithUrl('https://www.cognitoforms.com/EFuneral/AgentSignup')
                                        }
                                    >
                                        Can't find the funeral home you're looking for? Sign up here.
                                    </MockLinkWrapper>
                                </Fragment>
                            }
                        />
                    </Element>
                    <BasicOpportunityInformationGroupingWrapper touched={this.state.triedToProgress} />
                    <Element name="agentForm">
                        <PageCardUnderlinedSectionWrapper
                            sectionTitle="Agent Information"
                            sectionId="agent"
                            fullContent={<FuneralAgentFormWrapper />}
                        />
                    </Element>
                    <EnabledCorrectionButtonLinkWrapper
                        buttonId="continue"
                        to={urlPaths.productSelection}
                        shouldRouteOnClick={this.props.formsComplete}
                        onClickWhenNotRouting={() => {
                            this.props.touchAllFields();
                            this.setState({
                                triedToProgress: true
                            });
                            scroller.scrollTo(this.props.fieldToJumpTo, {
                                duration: 500,
                                smooth: true
                            });
                        }}
                        stylingOptions={undefined}
                    >
                        Continue
                    </EnabledCorrectionButtonLinkWrapper>
                    {this.state.triedToProgress && (
                        <label style={{ marginBottom: '10px', color: 'red', textAlign: 'center' }}>
                            Please fill out all of the above information
                        </label>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formsComplete: boolean;
    touchAllFields: Function;
    fieldToJumpTo: string;
}

interface StateShape {
    // Shape of local state
    triedToProgress: boolean;
}

export default FuneralAgentStartPageComponent;
