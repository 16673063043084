import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { normalizePhoneNumber, normalizeZipCode } from '../../../toolboxes/redux-form/redux-form-normalizers';
import { valueDisplaysFromEnum } from '../../../toolboxes/reuseable-logic/value-displays';
import { GeographicState } from '../../../static/constants/enums/states';

class PersonContactInformationFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        disabled: false
    };

    render() {
        return (
            <FormWrapper formName={this.props.formName} validate={this.props.validate}>
                <TextBoxWrapper
                    normalize={normalizePhoneNumber}
                    formName={this.props.formName}
                    fieldPath="phone"
                    labelString="Phone Number"
                    placeholder="555-555-5555"
                    extraFieldProps={{
                        disabled: false,
                        inputProps: {
                            inputMode: 'numeric'
                        }
                    }}
                />
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="email"
                    labelString="Email Address"
                    extraFieldProps={{
                        disabled: false,
                        inputProps: {
                            inputMode: 'email'
                        }
                    }}
                />
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="address"
                    labelString="Address"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="city"
                    labelString="City"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <SelectWrapper
                    formName={this.props.formName}
                    fieldPath="state"
                    labelString="State"
                    valueDisplays={valueDisplaysFromEnum(GeographicState)}
                    extraFieldProps={{
                        disabled: true
                    }}
                    spacing={{
                        xs: 6,
                        sm: 2
                    }}
                />
                <TextBoxWrapper
                    normalize={normalizeZipCode}
                    formName={this.props.formName}
                    fieldPath="zipCode"
                    labelString="Zip Code"
                    extraFieldProps={{
                        disabled: false,
                        inputProps: {
                            inputMode: 'numeric'
                        }
                    }}
                    spacing={{
                        xs: 6,
                        sm: 4
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    validate: Function;
    formName: string;
    disabled: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PersonContactInformationFormComponent;
