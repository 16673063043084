import { connect } from 'react-redux';
import PurchaseProcessingBlankPageComponent, { PropShape } from './PurchaseProcessingBlankPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape): PropShape => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseProcessingBlankPageComponent);
