import {
    runAgentUpdate,
    runGetAgentPermissionsForAccountId
} from 'toolboxes/web-requests/specific-requests/agent-requests';
import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';

export function sendSaveAgentData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const agentData = {
        firstName: getFormData(state, 'representative', 'firstName'),
        lastName: getFormData(state, 'representative', 'lastName'),
        phoneNumber: getFormData(state, 'representative', 'phone'),
        email: getFormData(state, 'representative', 'email'),
        agentCode: getFormData(state, 'representative', 'id')
    };

    runAgentUpdate(agentData, state.account.id, (result, error) => {
        return callback(result, error);
    });
}

export function getAgentPermissionsForAccountId(
    agentId: string,
    accountId: string,
    callback: (result: any, error: any) => void
) {
    runGetAgentPermissionsForAccountId(agentId, accountId, (result, error) => {
        return callback(result, error);
    });
}
