import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

class ButtonFunctionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false
        };
    }

    static defaultProps: any = {
        // Default prop values
        disabled: false,
        onClick: null,
        fullWidth: true,
        variant: 'contained',
        color: 'primary'
    };

    render() {
        // Peel off buttonId, passthrough props from passed through props
        const { buttonId, ...passthroughProps } = { ...this.props };

        return (
            <Button
                {...passthroughProps}
                style={{
                    fontFamily: ``,
                    marginBottom: '20px'
                }}
                id={'button-' + buttonId.toLowerCase()}
            >
                {this.props.children}
            </Button>
        );
    }
}

export interface PropShape extends ButtonProps {
    buttonId: string;
    children: any;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
}

export default ButtonFunctionComponent;
