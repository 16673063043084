import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React from 'react';
import { ContractSigningCredentialStatusOptions } from 'static/models/ContractSigningCredentials';

class InvalidContractSigningCredentialsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        signingStatus: undefined
        // Default prop values
    };

    render() {
        let titleText = 'Invalid Contract';
        let contentText = 'Unfortunately, this link is no longer valid. Please reach out to get a new link.';
        if (this.props.signingStatus === ContractSigningCredentialStatusOptions.awaitingPreviousSigners) {
            titleText = 'Waiting on Signers';
            contentText =
                'This contract is waiting on someone else to sign before you. Once they have signed, an email will be sent to you.';
        }
        if (this.props.signingStatus === ContractSigningCredentialStatusOptions.void) {
            titleText = 'Contract Outdated';
            contentText = `The contract you are trying to sign is outdated. If you don't have a newer link, please reach out to support@efuneral.com.`;
        }
        if (this.props.signingStatus === ContractSigningCredentialStatusOptions.signed) {
            titleText = 'Contract Signed';
            contentText =
                'This contract has already been signed. If you have any questions, please reach out to support@efuneral.com.';
        }
        return (
            <FullContentCenterWrapper
                metaPageName={undefined}
                metaPageNumber={undefined}
                metaPagebackAvailable={undefined}
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>{titleText}</PageCardTitleWrapper>
                    <PageCardTextWrapper>{contentText}</PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    signingStatus: ContractSigningCredentialStatusOptions;
}

interface StateShape {
    // Shape of local state
}

export default InvalidContractSigningCredentialsPageComponent;
