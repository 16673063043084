import { connect } from 'react-redux';
import PaymentPageComponent from './PaymentPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { PaymentMethod } from '../../../static/constants/enums/paymentMethods';
import {
    calculateHealthQuestionsNecessary,
    calculateIncapacitatedDisclaimerNecessary
} from '../../../toolboxes/reuseable-logic/incapacitated-calculator';
import { getSinglePayAlternative } from '../../../toolboxes/reuseable-logic/payment-options-calculator';
import { setSelectedPaymentOptionAction } from '../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import urlPaths from '../../../static/constants/enums/urlPaths';
import accountComponentSwitch from '../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import EverdaysPaymentPageWrapper from '../../account-specific-components/accounts/everdays/payment-page-component/EverdaysPaymentPageWrapper';
import { EVERDAYS_STYLING_KEY } from '../../../static/constants/styling-company-constants/companies/everdays';
import { setPurchaserWillEnterAction } from 'redux/opportunity/pricing/purchaserWillEnter';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import EarthPaymentPageWrapper from 'components/account-specific-components/accounts/earth/payment-page-component/EarthPaymentPageWrapper';

const getNextUrl = (state: GlobalStateShape, isAgent: boolean): urlPaths => {
    if (isAtNeed(state)) {
        return urlPaths.purchaseProcessing;
    }
    if (isAgent) {
        return urlPaths.representativeIdentity;
    }
    return urlPaths.ssnCapture;
};

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const paymentMethodsAvailable: PaymentMethod[] = [];
    const isAgent = !!representativeId;

    if (!state.opportunity.pricing.isPredetermined) {
        state.opportunity.pricing.paymentOptions.availableOptions.forEach(option => {
            if (!paymentMethodsAvailable.includes(option.paymentMethod)) {
                paymentMethodsAvailable.push(option.paymentMethod);
            }
        });
    } else {
        paymentMethodsAvailable.push(state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod);
    }

    return {
        paymentMethodsAvailable,
        startingError: state.opportunity.pricing.creditCardInfo.cardRejected
            ? 'Card did not sucessfully process. Please retry, or use another card.'
            : '',
        incapacitatedDisclaimerNecessary: calculateIncapacitatedDisclaimerNecessary(state),
        singlePayAlternative: getSinglePayAlternative(state),
        healthQuestionsNecessary: calculateHealthQuestionsNecessary(state),
        isBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled,
        wasBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears,
        isAgent: isAgent,
        nextUrl: getNextUrl(state, isAgent)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue)),
        setPurchaserWillEnterAction: newValue => dispatch(setPurchaserWillEnterAction(newValue))
    };
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(PaymentPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EVERDAYS_STYLING_KEY]: EverdaysPaymentPageWrapper,
    [EARTH_STYLING_KEY]: EarthPaymentPageWrapper
});
