import GlobalStateShape from '../../redux/GlobalStateShape';
import { GeographicState } from '../../static/constants/enums/states';
import { getFormData } from '../redux-form/redux-form-data-access';

/*
    In the state of new york, we can't use the word 'package'
*/

export function getWordForPackage(state: GlobalStateShape) {
    if (state.account.state === GeographicState.NY || getFormData(state, 'purchaser', 'state') === GeographicState.NY) {
        return 'offering';
    }
    return 'package';
}
