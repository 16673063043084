import { RepresentativeType } from '../../static/constants/enums/representativeType';

// Action Type
export const SET_REPRESENTATIVE_TYPE = 'SET_REPRESENTATIVE_TYPE';
// Action
export function setRepresentativeTypeAction(newValue: RepresentativeType) {
    return { type: SET_REPRESENTATIVE_TYPE, newValue };
}
// Reducer
export function setRepresentativeTypeReducer(state: any, action: any): RepresentativeType {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_REPRESENTATIVE_TYPE:
            return action.newValue;
        default:
            return state;
    }
}
