import { getStylingKeyForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_KEY = 'SET_STYLING_KEY';
// Action
export function setStylingKeyAction(newValue: string) {
    return { type: SET_STYLING_KEY, newValue };
}
// Reducer
export function setStylingKeyReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        return getStylingKeyForDomain();
    }
    switch (action.type) {
        case SET_STYLING_KEY:
            return action.newValue;
        default:
            return state;
    }
}
