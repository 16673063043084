import EmploymentVitalFormWrapper from 'components/form-components/employment-vital-form-component/EmploymentVitalFormWrapper';
import FatherVitalFormWrapper from 'components/form-components/father-vital-form-component/FatherVitalFormWrapper';
import MilitaryVitalFormWrapper from 'components/form-components/military-vital-form-component/MilitaryVitalFormWrapper';
import MotherVitalFormWrapper from 'components/form-components/mother-vital-form-component/MotherVitalFormWrapper';
import RecipientVitalFormWrapper from 'components/form-components/recipient-vital-form-component/RecipientVitalFormWrapper';
import SpouseVitalFormWrapper from 'components/form-components/spouse-vital-form-component/SpouseVitalFormWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React, { Fragment } from 'react';
import urlPaths from 'static/constants/enums/urlPaths';

class VitalStatistics extends React.Component<PropShape> {
    static defaultProps = {
        formIsValid: false,
        recipientName: ''
    };

    render() {
        return (
            <Fragment>
                <PageCardTitleWrapper>Vital Statistics Information.</PageCardTitleWrapper>
                <PageCardTextWrapper>
                    Please provide us with the following information about {this.props.recipientName}. This information
                    is needed for us to file the appropriate paperwork with the government. If you don't know all of the
                    information, please fill in as much as you do know and the rest can be gathered at a later time.
                </PageCardTextWrapper>

                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Recipient's Info</PageCardTitleWrapper>
                    <RecipientVitalFormWrapper />
                </PageCardWrapper>
                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Mother's Info</PageCardTitleWrapper>
                    <MotherVitalFormWrapper />
                </PageCardWrapper>
                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Father's Info</PageCardTitleWrapper>
                    <FatherVitalFormWrapper />
                </PageCardWrapper>
                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Marital Info</PageCardTitleWrapper>
                    <SpouseVitalFormWrapper />
                </PageCardWrapper>
                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Employment Info</PageCardTitleWrapper>
                    <EmploymentVitalFormWrapper />
                </PageCardWrapper>
                <PageCardWrapper fullWidth>
                    <PageCardTitleWrapper>Military Info</PageCardTitleWrapper>
                    <MilitaryVitalFormWrapper />
                </PageCardWrapper>

                <PageCardWrapper fullWidth>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div>
                            <ButtonLinkWrapper
                                to={urlPaths.vitalStatisticsSaved}
                                buttonId="save"
                                disabled={!this.props.formIsValid}
                            >
                                Submit
                            </ButtonLinkWrapper>
                        </div>
                    </div>
                </PageCardWrapper>
            </Fragment>
        );
    }
}

interface PropShape {
    formIsValid: boolean;
    recipientName: string;
}

export default VitalStatistics;
