import React from 'react';
import { PropShape as ButtonLinkProps } from '../button-link-component/ButtonLinkComponent';
import ButtonLinkWrapper from '../button-link-component/ButtonLinkWrapper';
import Button from '@material-ui/core/Button';

class EnabledCorrectionButtonLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        navigationSideAffect: () => {},
        disabled: false,
        fullWidth: true,
        variant: 'contained',
        color: 'primary'
    };

    render() {
        if (this.props.shouldRouteOnClick) {
            // This creates a new const called passthroughProps which includes only
            // props that can be passed to the child component
            // eslint-disable-next-line
            const { onClickWhenNotRouting, shouldRouteOnClick, ...passthroughProps } = { ...this.props };
            return <ButtonLinkWrapper {...passthroughProps}>{this.props.children}</ButtonLinkWrapper>;
        } else {
            // This creates a new const called passthroughProps which includes only
            // props that can be passed to the child component
            // eslint-disable-next-line
            const { navigationSideAffect, buttonId, onClickWhenNotRouting, shouldRouteOnClick, ...passthroughProps } = {
                ...this.props
            };
            return (
                <Button
                    {...passthroughProps}
                    onClick={() => this.props.onClickWhenNotRouting()}
                    style={{
                        fontFamily: ``,
                        marginBottom: '20px'
                    }}
                    id={'button-' + buttonId.toLowerCase()}
                >
                    {this.props.children}
                </Button>
            );
        }
    }
}

export interface PropShape extends ButtonLinkProps {
    // Shape of passed in props (including redux dispatch functions)
    onClickWhenNotRouting: Function;
    shouldRouteOnClick: boolean;
    buttonId: string;
    //Needed to avoid incorrect typescript errors
    variant;
    color;
}

interface StateShape {
    // Shape of local state
}

export default EnabledCorrectionButtonLinkComponent;
