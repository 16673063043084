import { connect } from 'react-redux';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import { setSelectedPaymentOptionAction } from '../../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import InstallmentPaymentTrustOptionsDisplayComponent from './InstallmentPaymentTrustOptionsDisplayComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const isTrust = state.opportunity.preneedContractType === 'Trust';
    // These values will be passed in as props
    return {
        multiPayOptions: state.opportunity.pricing.paymentOptions.availableOptions.filter(
            option => option.paymentMode === 'multi'
        ),
        isTrust: isTrust
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentPaymentTrustOptionsDisplayComponent);
