import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    saveBankingInformation,
    sendSaveCreditCardInformation,
    sendSavePaymentOption
} from '../../server-application-buffer/payment-data';
import { setPaymentInitiatedAction } from '../../../redux/opportunity/pricing/paymentInitiated';
import { setCardRejectedAction } from '../../../redux/opportunity/pricing/creditCardInfo/cardRejected';
import { sendSaveHealthHistoryAnswers } from '../../server-application-buffer/general-opportunity-data';
import {
    sendCalculatedAgesToOpportunity,
    sendSaveHealthHistoryDisclaimer,
    sendSavePaymentHoldDisclaimer,
    sendSaveSinglePaySettingDisclaimer
} from '../../server-application-buffer/disclaimer-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { initiateOutgoingDataApi } from '../../server-application-buffer/outgoing-api-triggering-data';
import { dataWrapper } from '../../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { Action } from 'history';
import { PaymentMethod } from '../../../static/constants/enums/paymentMethods';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { formIsValid, validateBankingInformation } from '../../redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import { setPaymentOptionsUpToDateAction } from '../../../redux/opportunity/pricing/paymentOptions/upToDate';
import { OutGoingApiEventType } from '../../../static/constants/enums/OutGoingApiEventType';

export function savePaymentData(
    state: GlobalStateShape,
    dispatch: Function,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    let blockingFunctions: BlockingFunction[] = [
        {
            promiseGenerator: () =>
                new Promise<void>(resolve => {
                    dispatch(setPaymentOptionsUpToDateAction(false));
                    resolve();
                }),
            blocksPageStarts: ['']
        }
    ];

    if (state.opportunity.pricing.paymentOptions.selectedOption) {
        switch (state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod) {
            case PaymentMethod.CreditCard:
                if (!state.opportunity.pricing.creditCardInfo.lastFour) {
                    return blockingFunctions;
                }
                break;
            case PaymentMethod.ACH:
                if (!formIsValid(getFormValues('bankingInformation')(state), validateBankingInformation)) {
                    return blockingFunctions;
                }
                break;
            default:
                break;
        }
    }
    if (!lastNavigationWasInternal) {
        return blockingFunctions;
    }

    // If the agent is controlling the flow, and there is no payment info, let's skip
    if (getFormData(state, 'representative', 'homesteadersMarketCode')) {
        // No payment option, let's skip and let purchaser fill it out
        if (!state.opportunity.pricing.paymentOptions.selectedOption) {
            return blockingFunctions;
        }
        // No payment information, let's skip and let purchaser fill it out
        switch (state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod) {
            case PaymentMethod.ACH:
                if (!formIsValid(getFormValues('bankingInformation')(state), validateBankingInformation)) {
                    return blockingFunctions;
                }
                break;
            case PaymentMethod.CreditCard:
                if (state.opportunity.pricing.stripeTokenId) {
                    return blockingFunctions;
                }
                break;
        }
    }

    // Exiting a page calls payment option saving task
    // Payment option saving task calls payment data saving task, which is dynamic based on payment method
    // Payment data saving task calls other information saving tasks when it's done

    let paymentOptionSavingTaskGenerator: () => Promise<void>;
    let paymentDataSavingTaskGenerator: () => Promise<void> = null;
    let otherInformationSavingTasksGenerator: () => Promise<void>[] = null;

    let paymentInfoEntered = dataWrapper(() => {
        switch (state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod) {
            case PaymentMethod.CreditCard:
                return !!state.opportunity.pricing.creditCardInfo.lastFour;
            case PaymentMethod.ACH:
                return formIsValid(getFormValues('bankingInformation')(state), validateBankingInformation);
            default:
                return false;
        }
    });

    // Setup otherInformationSavingTasks
    otherInformationSavingTasksGenerator = () => [
        new Promise<void>((resolve, reject) => {
            if (!state.disclaimerData.disclaimerAddressings.paymentPlanHealthImpact.responseToDisclaimer) {
                return resolve();
            }
            sendSaveHealthHistoryDisclaimer(state, (result, error) => {
                if (error) {
                    return reject(error);
                }
                return resolve();
            });
        }),
        new Promise<void>(resolve => {
            if (paymentInfoEntered) {
                initiateOutgoingDataApi(OutGoingApiEventType.paymentCaptured, state, dispatch, () => {
                    return resolve();
                });
            }
        }),
        new Promise<void>((resolve, reject) => {
            if (
                state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled === null &&
                state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears === null
            ) {
                return resolve();
            }
            sendSaveHealthHistoryAnswers(state, (result, error) => {
                if (error) {
                    return reject(error);
                }
                return resolve();
            });
        }),
        new Promise((resolve, reject) => {
            if (!state.disclaimerData.disclaimerAddressings.singlePaySetting.responseToDisclaimer) {
                return resolve();
            }
            sendSaveSinglePaySettingDisclaimer(state, (result, error) => {
                if (error) {
                    return reject(error);
                }
                return resolve();
            });
        }),

        new Promise((resolve, reject) => {
            sendCalculatedAgesToOpportunity(state, (result, error) => {
                if (error) {
                    return reject(error);
                }
                return resolve();
            });
        }),
        new Promise((resolve, reject) => {
            if (!state.disclaimerData.disclaimerAddressings.paymentHold.responseToDisclaimer) {
                return resolve();
            }
            sendSavePaymentHoldDisclaimer(state, (result, error) => {
                if (error) {
                    return reject(error);
                }
                return resolve();
            });
        })
    ];

    // Setup payment data saving task
    switch (dataWrapper(() => state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod)) {
        case PaymentMethod.ACH:
            paymentDataSavingTaskGenerator = () =>
                new Promise<void>((resolve, reject) => {
                    saveBankingInformation(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        Promise.all(otherInformationSavingTasksGenerator())
                            .then(() => {
                                dispatch(setPaymentInitiatedAction(true));
                                return resolve();
                            })
                            .catch(error => {
                                return reject(error);
                            });
                    });
                });
            break;
        case PaymentMethod.CreditCard:
            paymentDataSavingTaskGenerator = () =>
                new Promise((resolve, reject) => {
                    sendSaveCreditCardInformation(state, dispatch, (result, error) => {
                        if (error) {
                            dispatch(setPaymentInitiatedAction(false));
                            dispatch(setCardRejectedAction(true));
                            return resolve();
                        }
                        dispatch(setPaymentInitiatedAction(true));
                        dispatch(setCardRejectedAction(false));
                        // Initiate the outgoing data call
                        // Save payment impact disclaimer
                        // Save health question answers
                        Promise.all(otherInformationSavingTasksGenerator())
                            .then(() => {
                                return resolve();
                            })
                            .catch(error => {
                                return reject(error);
                            });
                    });
                });
            break;
        default:
            break;
    }

    // Setup payment option saving task
    paymentOptionSavingTaskGenerator = () =>
        new Promise((resolve, reject) => {
            sendSavePaymentOption(
                state.opportunity.pricing.paymentOptions.selectedOption,
                state.opportunity.id,
                (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    paymentDataSavingTaskGenerator()
                        .then(() => {
                            return resolve();
                        })
                        .catch(error => {
                            return reject(error);
                        });
                }
            );
        });

    blockingFunctions.push({
        promiseGenerator: paymentOptionSavingTaskGenerator,
        blocksPageStarts: [
            urlPaths.purchaserIdentity,
            urlPaths.ssnCapture,
            urlPaths.representativeIdentity,
            urlPaths.purchaseProcessing
        ]
    });
    return blockingFunctions;
}
