// Action Type
export const SET_STYLING_HEADER_BACKGROUND_COLOR = 'SET_STYLING_HEADER_BACKGROUND_COLOR';
// Action
export function setStylingHeaderBackgroundColorAction(newValue: string) {
    return { type: SET_STYLING_HEADER_BACKGROUND_COLOR, newValue };
}
// Reducer
export function setStylingHeaderBackgroundColorReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STYLING_HEADER_BACKGROUND_COLOR:
            return action.newValue;
        default:
            return state;
    }
}
