import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import {
    fetchAndStoreFuneralContractUrl,
    fetchAndStoreTravelContractUrl
} from '../../server-application-buffer/contracts-data';

export default function loadPurchaserBlockScoreData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const loadingFunctions: BlockingFunction[] = [];

    if (!state.opportunity.contractSigning.contractUrl) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    fetchAndStoreFuneralContractUrl(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }

    if (!state.travelOpportunity.contractUrl && state.travelOpportunity.id) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    fetchAndStoreTravelContractUrl(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.travelContracts]
        });
    }
    return loadingFunctions;
}
