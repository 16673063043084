import { connect } from 'react-redux';
import FuneralAgentStartPageComponent from './FuneralAgentStartPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { formIsValid, validateFuneralAgent } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import { generateTouchFullFormFunction } from '../../../toolboxes/reuseable-logic/form-field-list-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    let fieldToJumpTo = '';
    if (!formIsValid(getFormValues('representative')(state), validateFuneralAgent)) {
        fieldToJumpTo = 'agentForm';
    }
    if (![true, false].includes(state.opportunity.recipient.sameAsPurchaser)) {
        fieldToJumpTo = 'planningFor';
    }
    if (!state.opportunity.disposition) {
        fieldToJumpTo = 'disposition';
    }
    if (!state.account.subdomain) {
        fieldToJumpTo = 'locationSearch';
    }
    return {
        formsComplete: !fieldToJumpTo,
        fieldToJumpTo
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        touchAllFields: generateTouchFullFormFunction(validateFuneralAgent, 'representative', dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralAgentStartPageComponent);
