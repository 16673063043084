import React from 'react';
import { connect } from 'react-redux';
import RoutingComponent from './RoutingComponent';
import GlobalStateShape from '../../redux/GlobalStateShape';
import { withRouter } from 'react-router';
import generatePageEnterFunctions from '../../toolboxes/page-enter-functions/generate-page-enter-functions';
import generatePageExitFunctions from '../../toolboxes/page-exit-functions/generate-page-exit-functions';
import generatePageReroute from '../../toolboxes/page-reroute-functions/generate-page-reroutes';
import RouterFormattedBlockingFunction from '../../static/models/blockingFunctions/RouterFormattedBlockingFunction';
import BlockingFunction from '../../static/models/blockingFunctions/BlockingFunction';
import uuid from 'uuid';
import queryStringParser from 'query-string';
import { setErrorLoggingDataAction } from 'redux/errorLogging/errorData';

function appendIdsToBlockingFunctions(calculatedFunctions: BlockingFunction[]): RouterFormattedBlockingFunction[] {
    const appendedFunctions: RouterFormattedBlockingFunction[] = [];
    calculatedFunctions.forEach(blockingFunction => {
        const uniqueId = uuid();
        appendedFunctions.push({
            id: uniqueId,
            promiseGenerator: () =>
                new Promise<string>((resolve, reject) => {
                    blockingFunction
                        .promiseGenerator()
                        .then(() => {
                            return resolve(uniqueId);
                        })
                        .catch(error => {
                            return reject(error);
                        });
                }),
            blocksPageStarts: blockingFunction.blocksPageStarts
        });
    });
    return appendedFunctions;
}

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed into the merge function as stateProps
    return {
        state
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These values will be passed into the merge function as dispatchProps
    return {
        dispatch,
        setErrorLoggingDataAction: newValue => dispatch(setErrorLoggingDataAction(newValue))
    };
};

const mergeProps = (stateProps: any, dispatchProps: any, myProps: any) => {
    // These values will be passed in as props
    const state = stateProps.state;
    const dispatch = dispatchProps.dispatch;
    const setErrorLoggingDataAction = dispatchProps.setErrorLoggingDataAction;
    const currentPage = myProps.currentUrl;
    const previousPage = myProps.previousUrl;
    const previousUrlFull = myProps.previousUrlFull;
    const queryParams = myProps.queryParams;
    const showDebugInfo = myProps.showDebugInfo;
    const basicPageEnterFunctions = generatePageEnterFunctions(
        state,
        dispatch,
        currentPage,
        queryParams,
        myProps.lastNavigationWasInternal
    );
    const basicPreviousPageExitFunctions = generatePageExitFunctions(
        state,
        dispatch,
        previousPage,
        queryParams,
        myProps.lastNavigationWasInternal
    );
    const pageEnterFunctions = appendIdsToBlockingFunctions(basicPageEnterFunctions);
    const previousPageExitFunctions = appendIdsToBlockingFunctions(basicPreviousPageExitFunctions);
    const pageReroute = generatePageReroute(
        state,
        currentPage,
        previousPage,
        queryParams,
        myProps.lastNavigationWasInternal
    );
    return {
        currentPage,
        previousUrlFull,
        pageEnterFunctions,
        pageReroute,
        previousPageExitFunctions,
        showDebugInfo,
        setErrorLoggingDataAction
    };
};

// @ts-ignore
const NakedRoutingComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps)(RoutingComponent);

class RoutingWrapper extends React.Component<
    any,
    {
        currentUrl: string;
        currentUrlFull: string;
        previousUrl: string;
        previousUrlFull: string;
        /**
         * False if the last navigation was caused by a history popstate event
         * This allows blocking functions to know if they should run or not
         * based on whether or not the action taken to leave or enter a page was browser navigation
         * If the user clicks back on the browser, this will be false
         * if the user clicks a continue button on the screen, this will be true
         */
        lastNavigationWasInternal: boolean;
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentUrl: props.location.pathname,
            currentUrlFull: window.location.href,
            previousUrl: props.location.pathname,
            previousUrlFull: window.location.href,
            lastNavigationWasInternal: false
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.location.pathname !== prevState.currentUrl) {
            return {
                currentUrl: nextProps.location.pathname,
                currentUrlFull: window.location.href,
                previousUrl: prevState.currentUrl,
                previousUrlFull: prevState.currentUrlFull,
                /**
                 * Research, and some tests show the existence of
                 * nextProps.location.key (coming from react-router)
                 * is indicative of an internal navigation
                 */
                lastNavigationWasInternal: !!nextProps.location.key
            };
        }
        return {
            ...prevState
        };
    }

    render() {
        let queryParams = {};
        try {
            queryParams = queryStringParser.parse(this.props.location.search);
        } catch (e) {}
        return <NakedRoutingComponent {...this.props} {...this.state} queryParams={queryParams} />;
    }
}

export default withRouter(RoutingWrapper);
