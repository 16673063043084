import GlobalStateShape from '../../../../redux/GlobalStateShape';
import BlockingFunction from '../../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import { sendSaveRecipientUnavailableRatesDisclaimer } from '../../../server-application-buffer/disclaimer-data';
import { sendRecipientUnavailableDataUpdate } from '../../../server-application-buffer/general-opportunity-data';
import { fetchAndStorePaymentOptions, sendSavePaymentOption } from '../../../server-application-buffer/payment-data';
import { getSinglePayAlternative } from '../../../reuseable-logic/payment-options-calculator';
import { setSelectedPaymentOptionAction } from '../../../../redux/opportunity/pricing/paymentOptions/selectedOption';

export function saveRecipientAbsentData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const loadFunctions = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveRecipientUnavailableRatesDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.recipientIdentity, urlPaths.orderSummary]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendRecipientUnavailableDataUpdate(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.recipientIdentity, urlPaths.orderSummary]
        }
    ];
    if (state.opportunity.recipient.isUnavailable && state.opportunity.pricing.paymentOptions.selectedOption) {
        loadFunctions.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    fetchAndStorePaymentOptions(state, dispatch, (result, error) => {
                        const singlePayAlternative = getSinglePayAlternative({
                            ...state,
                            opportunity: {
                                ...state.opportunity,
                                pricing: {
                                    ...state.opportunity.pricing,
                                    paymentOptions: {
                                        ...state.opportunity.pricing.paymentOptions,
                                        availableOptions: result
                                    }
                                }
                            }
                        });
                        dispatch(setSelectedPaymentOptionAction(singlePayAlternative));
                        sendSavePaymentOption(singlePayAlternative, state.opportunity.id, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    });
                }),
            blocksPageStarts: [urlPaths.orderSummary, urlPaths.purchaserOrderSummary]
        });
    }
    return loadFunctions;
}
