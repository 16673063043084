import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SectionDividerWrapper from 'components/page-components/order-summary-page-component/page-specific-components/section-divider-component/SectionDividerWrapper';
import React from 'react';
import BlockscoreAlertWrapper from '../blockscore-alert-component/BlockscoreAlertWrapper';
import DemographicInformationWrapper from '../demographic-information-component/DemographicInformationWrapper';

class RecipientInformationCopmponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formData: null,
        recipient: null
    };

    render() {
        return (
            <div>
                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>Recipient</div>
                {this.props.recipient ? (
                    <>
                        {this.props.recipient.sameAsPurchaser ? (
                            <Typography>Same as Purchaser</Typography>
                        ) : (
                            <>
                                <DemographicInformationWrapper contact={this.props.formData} />
                                <SectionDividerWrapper isSubsection />
                                {this.props.recipient.isUnavailable && (
                                    <Alert color="warning">Recipient Unavailable</Alert>
                                )}
                                <BlockscoreAlertWrapper
                                    blockscoreQuestionScore={this.props.recipient.blockscoreQuestionScore}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div>None</div>
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formData?: any;
    recipient?: any;
}

interface StateShape {
    // Shape of local state
}

export default RecipientInformationCopmponent;
