import { combineReducers } from 'redux';
import setPaymentOptionsReducer from './paymentOptions/root';
import { setPaymentInitiatedReducer } from './paymentInitiated';
import { setStripeTokenReducer } from './stripeTokenId';
import { setPaymentIsPredeterminedReducer } from './isPredetermined';
import creditCardInfoReducer from './creditCardInfo/root';
import { setPurchaserWillEnterReducer } from './purchaserWillEnter';

export const setPricingReducer = combineReducers({
    paymentOptions: setPaymentOptionsReducer,
    paymentInitiated: setPaymentInitiatedReducer,
    stripeTokenId: setStripeTokenReducer,
    isPredetermined: setPaymentIsPredeterminedReducer,
    creditCardInfo: creditCardInfoReducer,
    purchaserWillEnter: setPurchaserWillEnterReducer
});
