import React, { Fragment } from 'react';
import PageCardUnderlinedSectionWrapper from '../../page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import DispositionSelectionWrapper from '../disposition-selection-component/DispositionSelectionWrapper';
import SelfOrOtherSelectionWrapper from '../self-or-other-selection-component/SelfOrOtherSelectionWrapper';
import { Element } from 'react-scroll';

class BasicOpportunityInformationGroupingComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        touched: false
    };

    render() {
        return (
            <Fragment>
                <Element name="disposition">
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="What type of funeral is this?"
                        sectionId="disposition"
                        fullContent={<DispositionSelectionWrapper touched={this.props.touched} />}
                    />
                </Element>
                <Element name="planningFor">
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="Who is the purchaser planning for?"
                        sectionId="planningFor"
                        fullContent={<SelfOrOtherSelectionWrapper touched={this.props.touched} />}
                    />
                </Element>
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    touched: boolean;
}

interface StateShape {
    // Shape of local state
}

export default BasicOpportunityInformationGroupingComponent;
