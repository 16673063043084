import React from 'react';
import { dataWrapper } from '../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';

class AccountFlexibleComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        const DefaultComponent = this.props.accountComponentMappings.default;
        let FinalizedComponent = dataWrapper(() => {
            if (this.props.accountComponentMappings[this.props.accountCustomizationKey]) {
                return this.props.accountComponentMappings[this.props.accountCustomizationKey];
            }
        });
        if (!FinalizedComponent) {
            FinalizedComponent = DefaultComponent;
        }
        return <FinalizedComponent {...this.props.passthroughProps} />;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountComponentMappings: any;
    accountCustomizationKey: string;
    passthroughProps: any;
}

interface StateShape {
    // Shape of local state
}

export default AccountFlexibleComponent;
