import { Grid } from '@material-ui/core';
import PurchaserFormWrapper from 'components/form-components/purchaser-form-component/PurchaserFormWrapper';
import RecipientFormWrapper from 'components/form-components/recipient-form-component/RecipientFormWrapper';
import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';
import React from 'react';
import { earthStyling } from 'static/constants/styling-company-constants/companies/earth';
import ApplicationStyling from 'static/models/ApplicationStyling';
import DisabledField from 'static/models/DisabledField';

class EarthOrderSummaryCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            onEdit: false
        };
    }

    static defaultProps = {
        // Default prop values
        stylingData: earthStyling
    };

    render() {
        const showEdit = ['purchaser', 'recipient'].includes(this.props.title.toLowerCase());

        return (
            <div
                style={{
                    marginBottom: this.props.stylingData.spacing?.md
                }}
            >
                <Grid
                    container
                    style={{
                        backgroundColor: this.props.stylingData.colors.footerBackgroundColor,
                        color: this.props.stylingData.colors.footerFontColor,
                        fontFamily: this.props.stylingData.fonts.bodyFontFamily,
                        fontSize: this.props.stylingData.fonts.bodyFontSize,
                        fontWeight: 700,
                        padding: '.8rem'
                    }}
                >
                    <Grid
                        item
                        xs={6}
                        style={{
                            textDecoration: 'underline'
                        }}
                    >
                        {this.props.title}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            textAlign: 'end'
                        }}
                    >
                        {showEdit && (
                            <MockLinkWrapper id={'edit'} onClick={() => this.setState({ onEdit: !this.state.onEdit })}>
                                Edit
                            </MockLinkWrapper>
                        )}
                    </Grid>
                </Grid>
                {!this.state.onEdit &&
                    this.props.rowsContent
                        .filter(content => !!content && /\S/.test(content))
                        .map((rowContent, index) => (
                            <div
                                // Not a performance perfect way to key these,
                                // But also it's rendering a single div, so I'm not going to spend time on it
                                key={`${rowContent}-${index}`}
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    color: this.props.stylingData.colors.bodyColor,
                                    fontFamily: this.props.stylingData.fonts.bodyFontFamily,
                                    fontSize: this.props.stylingData.fonts.bodyFontSize,
                                    fontWeight: this.props.stylingData.fonts.bodyWeight,
                                    padding: '.8rem',
                                    border: `1px solid ${this.props.stylingData.colors.footerBackgroundColor}`
                                }}
                            >
                                {rowContent}
                            </div>
                        ))}
                {this.state.onEdit && (
                    <>
                        {this.props.title.toLowerCase() === 'purchaser' && (
                            <PurchaserFormWrapper
                                isAtNeed={this.props.isAtNeed}
                                validationFunction={this.props.purchaserValidationFunction}
                                disableFields={this.props.disabledPurchaserFields}
                            />
                        )}
                        {this.props.title.toLowerCase() === 'recipient' && (
                            <RecipientFormWrapper
                                isAtNeed={this.props.isAtNeed}
                                validationFunction={this.props.recipientValidationFunction}
                                disableFields={this.props.disabledRecipientFields}
                                hideSSN={true}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    title: string;
    rowsContent: string[];
    stylingData: ApplicationStyling;
    purchaserValidationFunction?: (personData: any) => any;
    recipientValidationFunction?: (personData: any) => any;
    beneficiaryValidationFunction?: (personData: any) => any;
    isAtNeed?: boolean;
    disabledPurchaserFields?: DisabledField[];
    disabledRecipientFields?: DisabledField[];
}

interface StateShape {
    // Shape of local state
    onEdit: boolean;
}

export default EarthOrderSummaryCardComponent;
