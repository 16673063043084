import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';
import React, { Fragment } from 'react';
import FundingProvider from 'static/models/FundingProvider';
import { openTabWithUrl } from 'toolboxes/reuseable-logic/tab-opener';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';

class TrustFundedPrearrangementPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setTrustFundedPrearrangementAction: () => {}
    };

    getFundingProvider() {
        return this.props.fundingProvider?.account?.knownAs ?? this.props.fundingProvider?.account?.name;
    }

    generateDisclaimerText() {
        return (
            <Fragment>
                <div>
                    When prearranging a funeral, the state requires that all or a portion of your funds be put into a
                    trust to provide protection and ensure that funds are available at your time of need. We partner
                    with {this.getFundingProvider()} as the trust provider for your prepaid funeral funds.
                </div>
            </Fragment>
        );
    }

    render() {
        return (
            <StandardDisclaimerPageWrapper
                disclaimerTitle="Trust Funded Prearrangement"
                acceptTo={urlPaths.beneficiaryCheck}
                acceptanceText="Continue"
                rejectionText=""
                rejectTo=""
                saveDisclaimerAddressingToRedux={this.props.setTrustFundedPrearrangementAction}
                pageName="TrustFundedPrearrangement"
                pageBack="true"
                pageNumber=""
            >
                {this.generateDisclaimerText()}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setTrustFundedPrearrangementAction: (saveDisclaimer: DisclaimerAddressing) => void;
    fundingProvider: FundingProvider;
}

interface StateShape {
    // Shape of local state
}

export default TrustFundedPrearrangementPageComponent;
