import { Alert } from '@material-ui/lab';
import PageCardUnderlinedSectionWrapper from 'components/global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import React from 'react';
import { getMaskedCreditCardLastFour } from 'toolboxes/reuseable-logic/string-formatters';

class PaymentInformationComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        selectedOption: null,
        creditCardInfo: null
    };

    render() {
        return (
            <PageCardUnderlinedSectionWrapper
                sectionTitle="Payment Information"
                sectionId="payment-information"
                fullContent={
                    <>
                        {this.props.selectedOption ? (
                            <>
                                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>
                                    {this.props.selectedOption.paymentMode === 'single' ? (
                                        <>Single Payment</>
                                    ) : (
                                        <>{this.props.selectedOption.years} year payment plan</>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div>No Payment Option Selected</div>
                        )}
                        {this.props.creditCardInfo && this.props.creditCardInfo.lastFour ? (
                            <>
                                <div>{this.props.creditCardInfo.type}</div>
                                <div>{getMaskedCreditCardLastFour(this.props.creditCardInfo.lastFour)}</div>
                                {this.props.creditCardInfo.rejected && (
                                    <Alert color="error">Rejected Credit Card</Alert>
                                )}
                            </>
                        ) : (
                            <div>No credit card</div>
                        )}
                    </>
                }
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    selectedOption?: any;
    creditCardInfo?: any;
}

interface StateShape {
    // Shape of local state
}

export default PaymentInformationComponent;
