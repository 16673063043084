import { getFormValues } from 'redux-form';
import GlobalStateShape from 'redux/GlobalStateShape';
import { formIsValid, validateBeneficiary } from 'toolboxes/redux-form/redux-form-validation';

export const beneficiaryIsComplete = (state: GlobalStateShape) => {
    const beneficiaryIsPurchaser = state.yesNoAnswers.policyQuestions.beneficiaryIsPurchaser;
    const beneficiaryCheckAnswered = [true, false].includes(state.yesNoAnswers.policyQuestions.beneficiaryProvided);
    const beneficaryShouldBeProvided = state.yesNoAnswers.policyQuestions.beneficiaryProvided;
    const validBeneficaryProvided = formIsValid(getFormValues('beneficiary')(state), validateBeneficiary);

    if (beneficiaryIsPurchaser) {
        return true;
    }
    if (beneficiaryCheckAnswered) {
        if (beneficaryShouldBeProvided) {
            return validBeneficaryProvided;
        } else {
            return true;
        }
    }
    return false;
};
