import { connect } from 'react-redux';
import EarthProductDisclaimerPagePageComponent from './EarthProductDisclaimerPageComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setAnnuityInsuranceAddressingAction } from '../../../../../redux/disclaimerData/disclaimerAddressings/annuityInsurance';
import getEnvironment from '../../../../../env-config/get-environment';
import { FundingProductType } from '../../../../../static/constants/enums/fundingProductType';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        isInsurance: state.opportunity.selectedFundingProduct.productType === FundingProductType.Insurance,
        accountName: state.account.name,
        purchaserState: getFormData(state, 'purchaser', 'state'),
        accountState: state.account.state,
        efuneralMediaUrl: getEnvironment().efuneralMediaBucket,
        firstPage: firstPageCalculator(state, urlPaths.disclaimerProduct)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setAnnuityInsuranceAddressingAction: newValue => dispatch(setAnnuityInsuranceAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarthProductDisclaimerPagePageComponent);
