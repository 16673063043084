import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { validateBankingInformation } from '../../../toolboxes/redux-form/redux-form-validation';
import {
    normalizeRoutingNumber,
    normalizeBankAccountNumber
} from '../../../toolboxes/redux-form/redux-form-normalizers';
import { ReactComponent as LockIcon } from '../../../static/media/lock-icon.svg';
import './BankingInformationFormComponent.css';
import ConfirmationInputFormWrapper from '../base-form-components/confirmation-input-form-component/ConfirmationInputFormWrapper';

const stripeLikeStyling = {
    padding: '0px',
    paddingLeft: '10px',
    font: '400 13.3333px Arial',
    height: '37px',
    backgroundColor: 'white',
    flexGrow: '1',
    minWidth: '60px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '1px',
    boxShadow: 'none'
};

class BankingInformationFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountNumberValidation: null
    };

    render() {
        return (
            <div className="check-container">
                <FormWrapper formName="bankingInformation" validate={validateBankingInformation}>
                    <TextBoxWrapper
                        formName="bankingInformation"
                        fieldPath="nameOnAccount"
                        placeholder="ACCOUNT HOLDER NAME"
                        extraFieldProps={{
                            disabled: false,
                            style: stripeLikeStyling
                        }}
                        spacing={{
                            xs: 12
                        }}
                    />
                    <TextBoxWrapper
                        formName="bankingInformation"
                        fieldPath="bankName"
                        placeholder="BANK NAME"
                        extraFieldProps={{
                            style: stripeLikeStyling,
                            disabled: false
                        }}
                        spacing={{
                            xs: 12,
                            sm: 6
                        }}
                    />
                    <SelectWrapper
                        formName="bankingInformation"
                        fieldPath="accountType"
                        extraFieldProps={{
                            style: stripeLikeStyling,
                            disabled: false
                        }}
                        spacing={{
                            xs: 12,
                            sm: 6
                        }}
                        valueDisplays={[
                            {
                                value: 'Checking',
                                display: 'Checking'
                            },
                            {
                                value: 'Savings',
                                display: 'Savings'
                            }
                        ]}
                    />
                    <TextBoxWrapper
                        formName="bankingInformation"
                        fieldPath="routingNumber"
                        normalize={normalizeRoutingNumber}
                        placeholder="ROUTING NUMBER"
                        extraFieldProps={{
                            style: stripeLikeStyling,
                            disabled: false,
                            inputProps: {
                                inputMode: 'numeric'
                            }
                        }}
                        spacing={{
                            xs: 12,
                            sm: 12
                        }}
                    />
                    <ConfirmationInputFormWrapper
                        formName="bankingInformation"
                        fieldPath="accountNumber"
                        normalize={normalizeBankAccountNumber}
                        validationFunction={this.props.accountNumberValidation}
                        placeholder="ACCOUNT NUMBER"
                        confirmationPlaceholder="CONFIRM ACCOUNT NUMBER"
                        extraFieldProps={{
                            style: stripeLikeStyling,
                            disabled: false,
                            inputProps: {
                                inputMode: 'numeric'
                            }
                        }}
                        spacing={{
                            xs: 12,
                            sm: 6
                        }}
                    />
                </FormWrapper>
                <LockIcon /> Secure checkout
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    accountNumberValidation: Function;
}

interface StateShape {
    // Shape of local state
}

export default BankingInformationFormComponent;
