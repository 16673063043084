import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import urlPaths from 'static/constants/enums/urlPaths';

const setVitalStatisticsSavedFalse = (): BlockingFunction[] => {
    const functionsToRun = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    const expiration = new Date();
                    expiration.setUTCMinutes(expiration.getUTCMinutes() + 1);
                    const vitalStatisticsSavedValue = {
                        vitalStatisticsSaved: 'false',
                        expiration: expiration
                    };
                    window.sessionStorage.setItem('vitalStatisticsSaved', JSON.stringify(vitalStatisticsSavedValue));
                    return resolve();
                }),
            blocksPageStarts: [urlPaths.vitalStatistics]
        }
    ];
    return functionsToRun;
};

export default setVitalStatisticsSavedFalse;
