import { connect } from 'react-redux';
import TulipTotalDisplayComponent from './TulipTotalDisplayComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getTravelTotal, getCartTotal } from '../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { formatMonetaryAmount } from '../../../../../toolboxes/reuseable-logic/string-formatters';
import { setSelectedPaymentOptionAction } from 'redux/opportunity/pricing/paymentOptions/selectedOption';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        selectedPaymentPlan: state.opportunity.pricing.paymentOptions.selectedOption,
        allPaymentPlans: state.opportunity.pricing.paymentOptions.availableOptions,
        travelProtectionCost: getTravelTotal(state),
        arrangementTotal: formatMonetaryAmount(getCartTotal(state).toString())
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TulipTotalDisplayComponent);
