import GlobalStateShape from 'redux/GlobalStateShape';
import urlPaths from 'static/constants/enums/urlPaths';

export function firstPageCalculator(state: GlobalStateShape, currentPage: urlPaths) {
    if (state.urlPaths.firstPage !== '') {
        return state.urlPaths.firstPage === currentPage;
    } else {
        return true;
    }
}
