import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import DocusignIframeWrapper from '../../global-components/docusign-iframe-component/DocusignIframeWrapper';
import { formatMonetaryAmount } from '../../../toolboxes/reuseable-logic/string-formatters';
import { Redirect } from 'react-router';
import HelloSignSigningWrapper from 'components/global-components/hellosign-signing-component/HelloSignSigningWrapper';
import urlPaths from 'static/constants/enums/urlPaths';

class TravelContractsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            hasGoneFullScreen: false,
            redirectToPurchaseProcessing: false
        };
    }

    static defaultProps = {
        // Default prop values
        contractsUrl: '',
        price: ''
    };

    render() {
        if (this.props.contractsUrl.toLowerCase().includes('hellosign')) {
            if (this.state.redirectToPurchaseProcessing) {
                return <Redirect to={urlPaths.purchaserSigningComplete} push />;
            }
            return (
                <HelloSignSigningWrapper
                    hellosignEmbeddedUrl={this.props.contractsUrl}
                    onSigningComplete={() =>
                        this.setState({
                            redirectToPurchaseProcessing: true
                        })
                    }
                />
            );
        }
        if (this.state.hasGoneFullScreen) {
            return <DocusignIframeWrapper key="frame-section" contractsUrl={this.props.contractsUrl} />;
        }
        return [
            <FullContentCenterWrapper
                key="description-section"
                metaPageName="TravelContracts"
                metaPageNumber="4"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>Travel Protection Form</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        <p>
                            This is the final step of purchasing your travel protection. Please complete the forms
                            below. When you{`’`}re finished, your credit card will be charged the full amount of&nbsp;
                            {formatMonetaryAmount(this.props.price)} and your purchase will be complete.
                        </p>
                        <p>
                            DocuSign is a service we use for signing electronic documents quickly and securely. Please
                            accept their terms of service and sign your name where indicated.
                        </p>
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>,
            <DocusignIframeWrapper
                key="frame-section"
                contractsUrl={this.props.contractsUrl}
                onGoFull={() => {
                    this.setState({
                        hasGoneFullScreen: true
                    });
                }}
            />
        ];
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    contractsUrl: string;
    price: string;
}

interface StateShape {
    // Shape of local state
    hasGoneFullScreen: boolean;
    redirectToPurchaseProcessing: boolean;
}

export default TravelContractsPageComponent;
