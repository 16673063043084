import { connect } from 'react-redux';
import EverdaysPurchaserIdentityPageComponent from './EverdaysPurchaserIdentityPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { setPurchaserPlaidIdentityConsentDisclaimerAddressingAction } from 'redux/disclaimerData/disclaimerAddressings/purchaserPlaidIdentityConsent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        purchaserFirstName: getFormData(state, 'purchaser', 'firstName'),
        purchaserId: state.opportunity.purchaser.id
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPurchaserPlaidIdentityConsentDisclaimerAddressingAction: newValue =>
            dispatch(setPurchaserPlaidIdentityConsentDisclaimerAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EverdaysPurchaserIdentityPageComponent);
