import React, { Fragment } from 'react';
import { Input, Grid } from '@material-ui/core';

class ConfirmationInputComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            inputValue: '',
            inputTouched: false,
            inputInvalid: !!props.validationFunction(''),
            confirmationInputValue: '',
            confirmationInputTouched: false,
            confirmationInputInvalid: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleConfirmationInputChange = this.handleConfirmationInputChange.bind(this);
    }

    static defaultProps = {
        // Default prop values
        labelString: '',
        input: null,
        extraFieldProps: null,
        placeholder: '',
        confirmationPlaceHolder: '',
        type: '',
        spacing: null,
        autoFocus: false,
        validationFunction: () => {},
        normalize: newValue => newValue
    };

    handleChange(inputValue, confirmationInputValue) {
        let formattedInputValue = inputValue;
        try {
            formattedInputValue = this.props.normalize(formattedInputValue);
        } catch {}
        let formattedConfirmationInputValue = confirmationInputValue;
        try {
            formattedConfirmationInputValue = this.props.normalize(formattedConfirmationInputValue);
        } catch {}
        const inputInvalid = !!this.props.validationFunction(formattedInputValue);
        const confirmationInputInvalid = formattedConfirmationInputValue !== formattedInputValue;
        if (!inputInvalid && !confirmationInputInvalid) {
            this.props.onChange(formattedInputValue);
        } else {
            this.props.onChange('');
        }
        this.setState({
            inputValue: formattedInputValue,
            inputInvalid,
            confirmationInputValue: formattedConfirmationInputValue,
            confirmationInputInvalid
        });
    }

    handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({
            inputTouched: true
        });
        this.handleChange(e.target.value, this.state.confirmationInputValue);
    }

    handleConfirmationInputChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({
            confirmationInputTouched: true
        });
        this.handleChange(this.state.inputValue, e.target.value);
    }

    render() {
        return (
            <Fragment>
                <Grid item {...this.props.spacing}>
                    {this.props.labelString && <label>{this.props.labelString}</label>}
                    <Input
                        error={this.state.inputTouched && this.state.inputInvalid}
                        {...this.props.input}
                        {...this.props.extraFieldProps}
                        placeholder={this.props.placeholder}
                        type={this.props.type}
                        id={`input-${this.props.id}`}
                        fullWidth={true}
                        onChange={this.handleInputChange}
                        value={this.state.inputValue}
                    />
                </Grid>
                <Grid item {...this.props.spacing}>
                    {this.props.labelString && <label>Confirm {this.props.labelString}</label>}
                    <Input
                        error={this.state.confirmationInputTouched && this.state.confirmationInputInvalid}
                        {...this.props.input}
                        {...this.props.extraFieldProps}
                        placeholder={
                            this.props.confirmationPlaceholder
                                ? this.props.confirmationPlaceholder
                                : this.props.placeholder
                        }
                        type={this.props.type}
                        id={`input-${this.props.id}-confirmation`}
                        fullWidth={true}
                        onChange={this.handleConfirmationInputChange}
                        value={this.state.confirmationInputValue}
                    />
                </Grid>
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    id: string;
    labelString: string;
    input: any;
    extraFieldProps: any;
    placeholder: string;
    confirmationPlaceholder: string;
    type: string;
    spacing: any;
    autoFocus: boolean;
    validationFunction: Function;
    normalize: Function;
    onChange: (string) => void;
}

interface StateShape {
    // Shape of local state
    inputValue: string;
    inputTouched: boolean;
    inputInvalid: boolean;
    confirmationInputValue: string;
    confirmationInputTouched: boolean;
    confirmationInputInvalid: boolean;
}

export default ConfirmationInputComponent;
