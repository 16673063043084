import { v4 } from 'uuid';

export const ORIGIN_TOKEN_LOCAL_STORAGE_KEY = 'originToken';

// Ticket EFIT-761 - Keep a unique key for users that can be used across opportunities for marketing data purposes
export function getOrGenerateOriginToken(): string {
    try {
        const originToken = window.localStorage.getItem(ORIGIN_TOKEN_LOCAL_STORAGE_KEY);
        if (originToken) {
            return originToken;
        } else {
            const newOriginToken = v4();
            window.localStorage.setItem(ORIGIN_TOKEN_LOCAL_STORAGE_KEY, newOriginToken);
            return newOriginToken;
        }
    } catch (e) {
        // Don't stop the rest of the process from happening
        console.error(e);
        return '';
    }
}
