import React from 'react';
import RadioDescriptionWrapper from '../../buttons/radio-description-component/RadioDescriptionWrapper';
import { Disposition } from '../../../../static/constants/enums/disposition';

class TimingSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            touched: false
        };
    }

    static defaultProps = {
        // Default prop values
        selectedDisposition: null,
        setOpportunityDispositionAction: () => {},
        touched: false
    };

    render() {
        return (
            <div
                style={{
                    marginBottom: '10px'
                }}
            >
                <RadioDescriptionWrapper
                    onClick={() => {
                        this.props.setOpportunityDispositionAction(Disposition.Burial);
                        this.setState({
                            touched: true
                        });
                    }}
                    isSelected={this.props.selectedDisposition === Disposition.Burial}
                    mainContent="Burial"
                    descriptionContent="The customer would like a burial funeral"
                    showError={(this.state.touched || this.props.touched) && !this.props.selectedDisposition}
                    id="burial"
                />
                <RadioDescriptionWrapper
                    onClick={() => {
                        this.props.setOpportunityDispositionAction(Disposition.Cremation);
                        this.setState({
                            touched: true
                        });
                    }}
                    isSelected={this.props.selectedDisposition === Disposition.Cremation}
                    mainContent="Cremation"
                    descriptionContent="The customer would like a cremation funeral"
                    id="cremation"
                />
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    selectedDisposition: Disposition;
    setOpportunityDispositionAction: Function;
    touched: boolean;
}

interface StateShape {
    // Shape of local state
    touched: boolean;
}

export default TimingSelectionComponent;
