import React from 'react';
import FullContentCenterComponent from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterComponent';
import { Container, Grid, Typography } from '@material-ui/core';
import './OpportunityStatusPageComponent.css';
import StageProgressWrapper from 'components/global-components/opportunity-status-components/stage-progress-component/StageProgressWrapper';
import TotalDisplayWrapper from '../order-summary-page-component/page-specific-components/total-display-component/TotalDisplayWrapper';
import RecipientInformationWrapper from 'components/global-components/opportunity-status-components/contact-information/recipient-information-component/RecipientInformationWrapper';
import PurchaserInformationWrapper from 'components/global-components/opportunity-status-components/contact-information/purchaser-information-component/PurchaserInformationWrapper';
import PaymentInformationWrapper from 'components/global-components/opportunity-status-components/payment-information-component/PaymentInformationWrapper';
import { addSpaceBetweenWords } from 'toolboxes/reuseable-logic/string-formatters';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import BeneficiaryInformationWrapper from 'components/global-components/opportunity-status-components/contact-information/beneficiary-information-component/BeneficiaryInformationWrapper';
import ProductInformationWrapper from 'components/global-components/opportunity-status-components/product-information-component/ProductInformationWrapper';
import NotificationsWrapper from 'components/global-components/opportunity-status-components/notifications-component/NotificationsWrapper';

class OpportunityStatusPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            stylingOptions: null,
            stageName: ''
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterComponent metaPageName="" metaPageNumber="-1" metaPagebackAvailable="false">
                <Container>
                    <PageCardTitleWrapper>Opportunity Status</PageCardTitleWrapper>
                    <div className="information-section">
                        <Grid container justify="space-between">
                            <Grid item sm={8}>
                                <Grid item style={{ marginBottom: '10px' }}>
                                    <Typography>
                                        Opportunity Id:{' '}
                                        <span
                                            className="current-stage"
                                            style={{ color: this.props.stylingOptions.colors.primaryColor }}
                                        >
                                            {this.props.opportunityId}
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }}>
                                    <Typography>
                                        This opportunity is currently in the{' '}
                                        <span
                                            className="current-stage"
                                            style={{ color: this.props.stylingOptions.colors.primaryColor }}
                                        >
                                            {addSpaceBetweenWords(this.props.stageName)}{' '}
                                        </span>
                                        Stage
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        Customer Return Later Link:{' '}
                                        <a href={this.props.returnLaterLink}>{this.props.returnLaterLink}</a>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sm={4}>
                                <TotalDisplayWrapper />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="information-section">
                        <StageProgressWrapper stageName={this.props.stageName} />
                    </div>
                    <div className="information-section">
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <PurchaserInformationWrapper />
                            </Grid>
                            <Grid item md={3}>
                                <RecipientInformationWrapper />
                            </Grid>
                            <Grid item md={3}>
                                <BeneficiaryInformationWrapper />
                            </Grid>
                            <Grid item md={3}>
                                <PaymentInformationWrapper />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="information-section">
                        <Grid container>
                            <Grid item md={6}>
                                <ProductInformationWrapper />
                            </Grid>
                            <Grid item md={6}>
                                <NotificationsWrapper />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </FullContentCenterComponent>
        );
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: any;
    stageName?: string;
    opportunityId: string;
    returnLaterLink: string;
}

interface StateShape {
    // Shape of local state
}

export default OpportunityStatusPageComponent;
