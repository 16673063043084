// Action Type
export const SET_END_OF_EXPERIENCE_RETURN_LINK = 'SET_END_OF_EXPERIENCE_RETURN_LINK';
// Action
export function setEndOfExperienceReturnLinkAction(newValue: string) {
    return { type: SET_END_OF_EXPERIENCE_RETURN_LINK, newValue };
}
// Reducer
export function setEndOfExperienceReturnLinkReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_END_OF_EXPERIENCE_RETURN_LINK:
            return action.newValue;
        default:
            return state;
    }
}
