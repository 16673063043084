import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { fetchAndStoreOpportunityLoadData } from '../../server-application-buffer/loaded-opportunity-data';
import { sendSaveRepresentativeData } from '../../server-application-buffer/general-opportunity-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { change } from 'redux-form';
import { fetchAndStoreGlobalFeatureFlags } from 'toolboxes/server-application-buffer/feature-flags-data';
import smartlookClient from 'smartlook-client';

const loadOpportunityLoadFunctions = (state: GlobalStateShape, dispatch: Function, params: any): BlockingFunction[] => {
    const functionsToRun: BlockingFunction[] = [];

    // set the User Id in smartlook to the opportunity id
    if (!!params.id && smartlookClient.initialized()) {
        smartlookClient.identify(params.id, {});
    }

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStoreGlobalFeatureFlags(dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.loadOpportunity]
    });

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStoreOpportunityLoadData(state, dispatch, params.id, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    const repId =
                        params.representative ||
                        params.repId ||
                        getFormData(state, 'representative', 'id') ||
                        window.localStorage.getItem('representativeId');
                    if (!repId || !result.opportunityId) {
                        return resolve();
                    } else {
                        sendSaveRepresentativeData(params.id, repId, result.accountData.id, (result, error) => {
                            if (error) {
                                return reject(error);
                            } else {
                                dispatch(change('representative', 'id', repId));
                                return resolve();
                            }
                        });
                    }
                });
            }),
        blocksPageStarts: [urlPaths.loadOpportunity]
    });

    return functionsToRun;
};

export default loadOpportunityLoadFunctions;
