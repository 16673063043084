import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { calculateFundingProduct } from 'toolboxes/reuseable-logic/funding-product-calculator';
import { setSelectedFundingProductAction } from 'redux/opportunity/selectedFundingProduct';
import urlPaths from 'static/constants/enums/urlPaths';
import { calculateAndUpdateOpportunityContractCodes } from 'toolboxes/server-application-buffer/general-opportunity-data';

function setTrustFundingProduct(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    calculateAndUpdateOpportunityContractCodes(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.trustFundedPrearrangement]
        }
    ];
}

export default setTrustFundingProduct;
