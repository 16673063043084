import { connect } from 'react-redux';
import EarthOrderSummaryPageComponent from './EarthOrderSummaryPageComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { formatDate, formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        purchaser: {
            name: `${getFormData(state, 'purchaser', 'firstName')} ${getFormData(state, 'purchaser', 'lastName')}`,
            phoneNumber: formatPhoneNumber(getFormData(state, 'purchaser', 'phone')),
            address: `${getFormData(state, 'purchaser', 'address')}, ${getFormData(
                state,
                'purchaser',
                'city'
            )}, ${getFormData(state, 'purchaser', 'state')} ${getFormData(state, 'purchaser', 'zipCode')}`,
            email: getFormData(state, 'purchaser', 'email'),
            gender: getFormData(state, 'purchaser', 'gender'),
            dateOfBirth: formatDate(getFormData(state, 'purchaser', 'birthdate')),
            relationshipToRecipient: getFormData(state, 'purchaser', 'relationshipToRecipient')
        },
        recipient: {
            name: `${getFormData(state, 'recipient', 'firstName')} ${getFormData(state, 'recipient', 'lastName')}`,
            phoneNumber: formatPhoneNumber(getFormData(state, 'recipient', 'phone')),
            address: `${getFormData(state, 'recipient', 'address')}, ${getFormData(
                state,
                'recipient',
                'city'
            )}, ${getFormData(state, 'recipient', 'state')} ${getFormData(state, 'recipient', 'zipCode')}`,
            email: getFormData(state, 'recipient', 'email'),
            gender: getFormData(state, 'recipient', 'gender'),
            dateOfBirth: formatDate(getFormData(state, 'recipient', 'birthdate')),
            sameAsPurchaser: state.opportunity.recipient.sameAsPurchaser
        },
        beneficiary: {
            name: `${getFormData(state, 'beneficiary', 'firstName')} ${getFormData(state, 'beneficiary', 'lastName')}`,
            phoneNumber: formatPhoneNumber(getFormData(state, 'beneficiary', 'phone')),
            address: `${getFormData(state, 'beneficiary', 'address')}, ${getFormData(
                state,
                'beneficiary',
                'city'
            )}, ${getFormData(state, 'beneficiary', 'state')} ${getFormData(state, 'beneficiary', 'zipCode')}`,
            email: getFormData(state, 'beneficiary', 'email'),
            gender: getFormData(state, 'beneficiary', 'gender'),
            dateOfBirth: formatDate(getFormData(state, 'beneficiary', 'birthdate')),
            relationshipToRecipient: getFormData(state, 'beneficiary', 'relationshipToRecipient'),
            paidToEstate: !state.yesNoAnswers.policyQuestions.beneficiaryProvided,
            paidToPurchaser: state.yesNoAnswers.policyQuestions.beneficiaryIsPurchaser
        },
        irevocability: state.yesNoAnswers.policyQuestions.irrevocable || false,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EarthOrderSummaryPageComponent);
