import GlobalStateShape from '../../redux/GlobalStateShape';
import {
    fetchContractUrls,
    fetchTravelContractUrl,
    getSecureSigningContractUrl,
    getVerificationGeneratedFuneralContract,
    runCustomerDeclinedContract
} from '../web-requests/specific-requests/docusign-requests';
import { setTravelContractsUrlAction } from '../../redux/travelOpportunity/contractUrl';
import urlPaths from '../../static/constants/enums/urlPaths';
import { setTravelContractUrlDateAction } from '../../redux/travelOpportunity/contractUrlDate';
import { fetchContractV3Urls, fetchTravelContractV3Url } from '../web-requests/specific-requests/contracts-v3-requests';
import { getBasePath } from 'toolboxes/reuseable-logic/get-base-path';
import { VerificationContractsDetails } from 'static/models/VerificationContractDetails';
import { setFuneralIsDemoContractIncursCostAction } from 'redux/opportunity/isDemoAndContractWouldIncurCost';
import { setTravelIsDemoContractIncursCostAction } from 'redux/travelOpportunity/isDemoAndContractWouldIncurCost';
import { setStylingKeyAction } from 'redux/account/stylingKey';
import { TULIP_STYLING_KEY } from 'static/constants/styling-company-constants/companies/tulip';
import { setAccountHellosignKeyAction } from 'redux/account/hellosignClientKey';
import { setFuneralContractUrlAction } from 'redux/opportunity/contractSigning/contractUrl';
import { setFuneralContractUrlDateAction } from 'redux/opportunity/contractSigning/contractUrlDate';

export function fetchAndStoreFuneralContractUrl(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    const basePath = getBasePath();

    if (state.featureFlags.contractsV3) {
        fetchContractV3Urls(
            state.opportunity.id,
            state.opportunity.preneedContractType,
            state.featureFlags.usePreneedContractTypeCalculator,
            `${window.location.origin}${basePath}${urlPaths.docusignReroute}?intentPage=${urlPaths.travelContracts}`,
            (result, error) => {
                if (error) {
                    return callback(undefined, 'Failed to fetch docusign url');
                }
                dispatch(setFuneralContractUrlAction(result.url));
                dispatch(setFuneralContractUrlDateAction(new Date()));
                dispatch(setFuneralIsDemoContractIncursCostAction(result.isDemoAndWouldIncurCost));
                return callback(undefined, undefined);
            }
        );
    } else {
        fetchContractUrls(
            state.opportunity.id,
            {
                redirectUrl: `${window.location.origin}${basePath}${urlPaths.docusignReroute}?intentPage=${urlPaths.travelContracts}`
            },
            (result, error) => {
                if (error) {
                    return callback(undefined, 'Failed to fetch docusign url');
                }
                dispatch(setFuneralContractUrlAction(result.url));
                dispatch(setFuneralContractUrlDateAction(new Date()));
                return callback(undefined, undefined);
            }
        );
    }
}

export function fetchAndStoreTravelContractUrl(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    const basePath = getBasePath();

    if (state.featureFlags.contractsV3) {
        fetchTravelContractV3Url(
            state.travelOpportunity.id,
            `${window.location.origin}${basePath}${urlPaths.docusignReroute}?intentPage=${urlPaths.purchaserSigningComplete}`,
            (result, error) => {
                if (error) {
                    return callback(undefined, 'Failed to fetch docusign url');
                }
                dispatch(setTravelContractsUrlAction(result.url));
                dispatch(setTravelContractUrlDateAction(new Date()));
                dispatch(setTravelIsDemoContractIncursCostAction(result.isDemoAndWouldIncurCost));
                return callback(undefined, undefined);
            }
        );
    } else {
        fetchTravelContractUrl(
            state.travelOpportunity.id,
            {
                redirectUrl: `${window.location.origin}${basePath}${urlPaths.docusignReroute}?intentPage=${urlPaths.purchaserSigningComplete}`
            },
            (result, error) => {
                if (error) {
                    return callback(undefined, 'Failed to fetch docusign url');
                }
                dispatch(setTravelContractsUrlAction(result.url));
                dispatch(setTravelContractUrlDateAction(new Date()));
                return callback(undefined, undefined);
            }
        );
    }
}

export function fetchDemoFuneralContract(
    opportunityId: string,
    callback: (result: VerificationContractsDetails, error: any) => void
) {
    getVerificationGeneratedFuneralContract(opportunityId, callback, 'Preneed Contract');
}

export function fetchDemoTravelContract(
    opportunityId: string,
    callback: (result: VerificationContractsDetails, error: any) => void
) {
    getVerificationGeneratedFuneralContract(opportunityId, callback, 'Travel Protection Contract');
}

export function fetchAndStoreSecureSigningContractUrl(
    key: string,
    dispatch: Function,
    callback: (result: string, error: any) => void
) {
    getSecureSigningContractUrl(key, (result, error) => {
        if (error) {
            return callback(null, error);
        }
        // Not actually necessarily a funeral contract - whatever contract matches the key will be loaded
        dispatch(setFuneralContractUrlAction(result.embeddableUrl));
        // Makes sure we use tulip's hellosign application so later signers don't get errors
        dispatch(setAccountHellosignKeyAction(result.helloSignClientId));
        if (result.isTulip) {
            dispatch(setStylingKeyAction(TULIP_STYLING_KEY));
        }
        return callback(undefined, undefined);
    });
}

export function sendCustomerDeclinedContract(opportunityId: string, callback: (result: string, error: any) => void) {
    runCustomerDeclinedContract(opportunityId, callback);
}
