import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';

const loadFromGuidedFunctions = (state: GlobalStateShape, dispatch: Function, params: any): BlockingFunction[] => {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    window.localStorage.setItem('opportunityId', params.id);
                    resolve();
                }),
            blocksPageStarts: [urlPaths.loadFromGuided]
        }
    ];
};

export default loadFromGuidedFunctions;
