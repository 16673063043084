import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { notifyParentApplicationOfContractCompletion } from 'toolboxes/reuseable-logic/iframe-notification';

export function secureLinkSigning(): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    notifyParentApplicationOfContractCompletion();
                    return resolve();
                }),
            blocksPageStarts: []
        }
    ];
}
