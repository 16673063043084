import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import * as Sentry from '@sentry/browser';
import { dataWrapper } from '../../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { change } from 'redux-form';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import urlPaths from 'static/constants/enums/urlPaths';
import { sendErrorDataToCreateErrorCase } from 'toolboxes/server-application-buffer/outgoing-api-triggering-data';
import { setErrorLoggingCaseNumberAction } from 'redux/errorLogging/errorCaseNumber';
import { setErrorLoggingCaseCreationFailedAction } from 'redux/errorLogging/errorCaseCreationFailed';
import { setErrorLoggingCaseIdAction } from 'redux/errorLogging/errorCaseId';

export default function sendErrorFunctions(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const loadFunctions: BlockingFunction[] = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    let opportunityId = dataWrapper(() => state.opportunity.id);
                    if (!opportunityId) {
                        opportunityId = localStorage.getItem('opportunityId') || 'NOT PROVIDED';
                        opportunityId += ' (Fetched from localStorage)';
                    }
                    Sentry.captureException(
                        `Opportunity ${opportunityId} has experienced an error in their flow. Error Data: ${state.errorLogging.errorData}`
                    );

                    const firstName = getFormData(state, 'purchaser', 'firstName');
                    const lastName = getFormData(state, 'purchaser', 'lastName');
                    const name = (firstName ? firstName : '') + (lastName ? ' ' + lastName : '');
                    const phone = getFormData(state, 'purchaser', 'phone');
                    const email = getFormData(state, 'purchaser', 'email');

                    dispatch(change('errorForm', 'name', name));
                    dispatch(change('errorForm', 'phone', phone));
                    dispatch(change('errorForm', 'email', email));

                    return resolve();
                }),
            blocksPageStarts: [urlPaths.error]
        }
    ];

    loadFunctions.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendErrorDataToCreateErrorCase(state, (result, error) => {
                    if (result.caseId === `couldn't make the error case`) {
                        dispatch(setErrorLoggingCaseCreationFailedAction(true));
                    } else if (result.caseId) {
                        dispatch(setErrorLoggingCaseIdAction(result.caseId));
                        dispatch(setErrorLoggingCaseNumberAction(result.caseNumber));
                    } else {
                        dispatch(setErrorLoggingCaseCreationFailedAction(true));
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.error]
    });

    return loadFunctions;
}
