import { connect } from 'react-redux';
import EarthPaymentPageComponent from './EarthPaymentPageComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { PaymentMethod } from '../../../../../static/constants/enums/paymentMethods';
import {
    calculateIncapacitatedDisclaimerNecessary,
    calculateHealthQuestionsNecessary
} from '../../../../../toolboxes/reuseable-logic/incapacitated-calculator';
import { getSinglePayAlternative } from '../../../../../toolboxes/reuseable-logic/payment-options-calculator';
import { setSelectedPaymentOptionAction } from '../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';
import urlPaths from '../../../../../static/constants/enums/urlPaths';
import { setPurchaserWillEnterAction } from 'redux/opportunity/pricing/purchaserWillEnter';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';

const getNextUrl = (state: GlobalStateShape, isAgent: boolean): urlPaths => {
    if (isAtNeed(state)) {
        return urlPaths.purchaseProcessing;
    }
    if (isAgent) {
        return urlPaths.representativeIdentity;
    }
    return urlPaths.ssnCapture;
};

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const paymentMethodsAvailable: PaymentMethod[] = [];
    const isAgent = !!representativeId;

    if (!state.opportunity.pricing.isPredetermined) {
        state.opportunity.pricing.paymentOptions.availableOptions.forEach(option => {
            if (!paymentMethodsAvailable.includes(option.paymentMethod)) {
                paymentMethodsAvailable.push(option.paymentMethod);
            }
        });
    } else {
        paymentMethodsAvailable.push(state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod);
    }

    return {
        paymentMethodsAvailable,
        startingError: state.opportunity.pricing.creditCardInfo.cardRejected
            ? 'Card did not sucessfully process. Please retry, or use another card.'
            : '',
        incapacitatedDisclaimerNecessary: calculateIncapacitatedDisclaimerNecessary(state),
        singlePayAlternative: getSinglePayAlternative(state),
        healthQuestionsNecessary: calculateHealthQuestionsNecessary(state),
        isBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled,
        wasBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears,
        isAgent: isAgent,
        nextUrl: getNextUrl(state, isAgent)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue)),
        setPurchaserWillEnterAction: newValue => dispatch(setPurchaserWillEnterAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarthPaymentPageComponent);
