import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_BODY_FONT_FAMILY = 'SET_STYLING_BODY_FONT_FAMILY';
// Action
export function setBodyFontFamilyAction(newValue: string) {
    return { type: SET_STYLING_BODY_FONT_FAMILY, newValue };
}
// Reducer
export function setBodyFontFamilyReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().fonts.bodyFontFamily;
    }
    switch (action.type) {
        case SET_STYLING_BODY_FONT_FAMILY:
            return action.newValue;
        default:
            return state;
    }
}
