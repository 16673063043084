import { connect } from 'react-redux';
import CartComponent from './CartComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { removeOpportunityProductAction, addOpportunityProductAction } from '../../../redux/opportunity/products';
import { getCartTotal } from '../../../toolboxes/reuseable-logic/pricing-calculator';
import { removeTravelProductAction, addTravelProductAction } from '../../../redux/travelOpportunity/products';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        funeralProducts: state.opportunity.products,
        travelProducts: state.travelOpportunity.products,
        cartTotal: getCartTotal(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        removeFuneralProduct: removedValue => dispatch(removeOpportunityProductAction(removedValue)),
        removeTravelProduct: removedValue => dispatch(removeTravelProductAction(removedValue)),
        addFuneralProduct: addedValue => dispatch(addOpportunityProductAction(addedValue)),
        addTravelProduct: addedValue => dispatch(addTravelProductAction(addedValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartComponent);
