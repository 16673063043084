import { connect } from 'react-redux';
import ProductCreationModalComponent from './ProductCreationModalComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import translateCreatedProduct from './product-translator';
import { getFormValues } from 'redux-form';
import { formIsValid, validateProductCreation } from '../../../toolboxes/redux-form/redux-form-validation';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        translatedProduct: translateCreatedProduct(state),
        formValid: formIsValid(getFormValues('productCreation')(state), validateProductCreation)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreationModalComponent);
