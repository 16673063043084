import { connect } from 'react-redux';
import BeneficiaryPageComponent from './BeneficiaryPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { formIsValid, validateBeneficiary } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import { areContactsSimilar } from 'toolboxes/reuseable-logic/are-contacts-similar';
import { setBeneficiaryRecipientSamePersonAction } from 'redux/disclaimerData/disclaimerAddressings/beneficiaryRecipientSamePerson';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const beneficiary = getFormValues('beneficiary')(state) as any;
    const recipient = state.opportunity.recipient.sameAsPurchaser
        ? (getFormValues('purchaser')(state) as any)
        : (getFormValues('recipient')(state) as any);
    const beneficiaryAndRecipientAreSimilar = areContactsSimilar(
        recipient,
        beneficiary,
        beneficiary?.relationshipToRecipient
    );

    return {
        beneficiaryComplete: formIsValid(getFormValues('beneficiary')(state), validateBeneficiary),
        beneficiaryAndRecipientAreSimilar,
        checkIfBeneficiaryAndRecipientAreSimilar: state.featureFlags.checkIfBeneficiaryAndRecipientAreSimilar,
        beneficiaryName: `${beneficiary?.firstName} ${beneficiary?.lastName}`,
        recipientName: `${recipient?.firstName} ${recipient?.lastName}`,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setBeneficiaryRecipientSamePersonAction: newValue => dispatch(setBeneficiaryRecipientSamePersonAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryPageComponent);
