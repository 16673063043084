import React from 'react';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import GeneralDisclaimerWrapper from '../../../global-components/general-disclaimer-components/general-disclaimer-component/GeneralDisclaimerWrapper';
import { Redirect } from 'react-router';
import BackButtonLinkWrapper from '../../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            redirectTo: ''
        };
        this.calculateChildrenTextRecursive = this.calculateChildrenTextRecursive.bind(this);
    }

    static defaultProps = {
        // Default prop values
        addressingContactId: '',
        opportunityId: '',
        acceptanceSideAffect: () => {},
        rejectionSideAffect: () => {},
        backTo: '',
        extraContentLength: true,
        extraCardWidth: false,
        allowBack: true,
        metaPageName: '',
        metaFirstPage: 'false'
    };

    calculateChildrenTextRecursive(children: any): string {
        let fullText = '';
        if (children) {
            React.Children.forEach(children, child => {
                if (child) {
                    if (typeof child === 'string') {
                        return (fullText += child);
                    }
                    fullText += this.calculateChildrenTextRecursive(child.props.children);
                }
            });
        }
        return fullText;
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push />;
        }
        return (
            <FullContentCenterWrapper
                metaPageName={this.props.pageName}
                metaPageNumber={this.props.pageNumber}
                metaPagebackAvailable={this.props.pageBack}
                metaFirstPage={this.props.metaFirstPage}
            >
                <GeneralDisclaimerWrapper
                    acceptanceText={this.props.acceptanceText}
                    rejectionText={this.props.rejectionText}
                    onAccept={() => {
                        if (this.props.acceptanceSideAffect) {
                            this.props.acceptanceSideAffect();
                        }
                        this.setState({
                            redirectTo: this.props.acceptTo
                        });
                    }}
                    onReject={() => {
                        if (this.props.rejectionSideAffect) {
                            this.props.rejectionSideAffect();
                        }
                        this.setState({
                            redirectTo: this.props.rejectTo
                        });
                    }}
                    disclaimerTitle={this.props.disclaimerTitle}
                    saveDisclaimerAddressingToRedux={this.props.saveDisclaimerAddressingToRedux}
                    addressingContactId={this.props.addressingContactId}
                    opportunityId={this.props.opportunityId}
                    extraContentLength={this.props.extraContentLength}
                    extraCardWidth={this.props.extraCardWidth}
                >
                    {this.props.children}
                </GeneralDisclaimerWrapper>
                {this.props.allowBack && <BackButtonLinkWrapper />}
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    acceptTo: string;
    acceptanceText: string;
    rejectTo: string;
    rejectionText: string;
    disclaimerTitle: string;
    saveDisclaimerAddressingToRedux: (addressing: DisclaimerAddressing) => void;
    acceptanceSideAffect: Function;
    rejectionSideAffect: Function;
    backTo: string;
    addressingContactId?: string;
    opportunityId?: string;
    extraContentLength: boolean;
    extraCardWidth: boolean;
    allowBack: boolean;
    pageName: string;
    metaPageName: string;
    metaFirstPage: string;
    pageBack: string;
    pageNumber: string;
}

interface StateShape {
    // Shape of local state
    redirectTo: string;
}

export default TemplateComponent;
