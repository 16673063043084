import { OpportunityCommissionOption } from 'static/constants/enums/opportunityCommissionOption';

// Action Type
export const SET_OPPORTUNITY_COMMISSION_OPTION = 'SET_OPPORTUNITY_COMMISSION_OPTION';
// Action
export function setOpportunityCommissionOptionAction(newValue: OpportunityCommissionOption) {
    return { type: SET_OPPORTUNITY_COMMISSION_OPTION, newValue };
}
// Reducer
export function setOpportunityCommissionOptionReducer(state: any, action: any): OpportunityCommissionOption {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_OPPORTUNITY_COMMISSION_OPTION:
            return action.newValue ?? null;
        default:
            return state;
    }
}
