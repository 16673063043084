import SectionDividerWrapper from 'components/page-components/order-summary-page-component/page-specific-components/section-divider-component/SectionDividerWrapper';
import React from 'react';
import BlockscoreAlertWrapper from '../blockscore-alert-component/BlockscoreAlertWrapper';
import DemographicInformationWrapper from '../demographic-information-component/DemographicInformationWrapper';
import PurchaserSignedAlertWrapper from '../purchaser-signed-alert-component/PurchaserSignedAlertWrapper';

class PurchaserInformationComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formData: null,
        purchaser: null
    };

    render() {
        return (
            <div>
                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>Purchaser</div>
                <DemographicInformationWrapper contact={this.props.formData} />
                <SectionDividerWrapper isSubsection />
                {this.props.purchaser && (
                    <>
                        <BlockscoreAlertWrapper
                            blockscoreQuestionScore={this.props.purchaser.blockscoreQuestionScore}
                        />
                        <PurchaserSignedAlertWrapper />
                    </>
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formData?: any;
    purchaser?: any;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserInformationComponent;
