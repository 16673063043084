import { connect } from 'react-redux';
import PurchaserOrderSummaryDemographicSummaryComponent from './PurchaserOrderSummaryDemographicSummaryComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { Timing } from 'static/constants/enums/timing';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        isAtNeed: state.opportunity.timing === Timing.IMMEDIATE
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryDemographicSummaryComponent);
