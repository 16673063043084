import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import { documentUrl } from '../../../toolboxes/web-requests/web-request-tools';
import ButtonFunctionWrapper from '../../global-components/buttons/button-function-component/ButtonFunctionWrapper';

class PurchaserSaleCompletePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        isAtNeed: false,
        opportunityId: ''
    };

    handleClick() {
        window.open(`${documentUrl}/completed/${this.props.opportunityId}`);
    }

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaserSaleComplete"
                metaPageNumber="5"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Your Sale Is Complete</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        You have completed your sale
                        {!this.props.isAtNeed &&
                            ' and all necessary contracts have been signed. Please use the button below to download your completed contracts'}
                        .
                    </PageCardTextWrapper>
                    {!this.props.isAtNeed && (
                        <ButtonFunctionWrapper buttonId="download-contracts" onClick={this.handleClick}>
                            Download Contracts
                        </ButtonFunctionWrapper>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    isAtNeed: boolean;
    opportunityId: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserSaleCompletePageComponent;
