import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

export function travelContractsReroutes(state: GlobalStateShape) {
    if (!state.travelOpportunity.id) {
        return urlPaths.purchaserSigningComplete;
    }
    if (state.travelOpportunity.isDemoAndContractWouldIncurCost) {
        return urlPaths.docusignFreeContractPage;
    }
}
