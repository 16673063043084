import { connect } from 'react-redux';
import GlobalStateShape from '../../redux/GlobalStateShape';
import AccountFlexibleComponent from '../../components/account-specific-components/account-flexible-component/AccountFlexibleComponent';

// This connect is an extension of react-redux's connect function.
// It takes an extra optional parameter for accountComponentMappings.
// If the account customization key (The subdomain at the time of writing) matches a component
// Then, the matched component will render instead

function mapStateToProps(state: GlobalStateShape, myProps: any) {
    return {
        accountCustomizationKey: state.account.stylingKey,
        passthroughProps: myProps
    };
}

function mapStateToPropsCreator(accountComponentMappings: any) {
    return (state: GlobalStateShape, myProps: any) => {
        const props = {
            ...mapStateToProps(state, myProps),
            accountComponentMappings
        };
        return props;
    };
}

function mapDispatchToProps(state: GlobalStateShape) {
    return {};
}

const SwitchingComponent = accountComponentMappings => {
    return connect(mapStateToPropsCreator(accountComponentMappings), mapDispatchToProps)(AccountFlexibleComponent);
};

export default function accountComponentSwitch<ComponentType>(accountComponentMappings: any): ComponentType {
    // @ts-ignore
    return SwitchingComponent(accountComponentMappings);
}
