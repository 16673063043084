import { runSubmitIdentityPlaid } from 'toolboxes/web-requests/specific-requests/plaid-identity-requests';

export function sendSubmitIdentityPlaid(contactId: string, callback: (result: any, error: any) => void) {
    runSubmitIdentityPlaid(contactId, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        // Result should be SUCCESS or FAILED
        return callback(result, error);
    });
}
