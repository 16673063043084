import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import urlPaths from 'static/constants/enums/urlPaths';
import {
    sendUpdateErrorCase,
    sendErrorDataToCreateErrorCase
} from 'toolboxes/server-application-buffer/outgoing-api-triggering-data';
import { setErrorLoggingCaseCreationFailedAction } from 'redux/errorLogging/errorCaseCreationFailed';
import { setErrorLoggingCaseNumberAction } from 'redux/errorLogging/errorCaseNumber';
import { setErrorLoggingCaseIdAction } from 'redux/errorLogging/errorCaseId';

export function saveErrorFormData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    if (state.errorLogging.errorCaseCreationFailed) {
                        sendErrorDataToCreateErrorCase(state, (result, error) => {
                            if (result.caseId !== `couldn't make the error case`) {
                                dispatch(setErrorLoggingCaseCreationFailedAction(false));
                                dispatch(setErrorLoggingCaseIdAction(result.caseId));
                                dispatch(setErrorLoggingCaseNumberAction(result.caseNumber));
                                sendUpdateErrorCase(state, result.caseId, (result, error) => {
                                    return resolve();
                                });
                            } else {
                                return resolve();
                            }
                        });
                    } else if (state.errorLogging.errorCaseNumber) {
                        sendUpdateErrorCase(state, state.errorLogging.errorCaseId, (result, error) => {
                            return resolve();
                        });
                    } else {
                        return resolve();
                    }
                }),
            blocksPageStarts: [urlPaths.errorSubmission]
        }
    ];
}
