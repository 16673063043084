import { combineReducers } from 'redux';
import { setAccountIdReducer } from './id';
import { setAccountStateReducer } from './state';
import { setAccountPhoneReducer } from './phone';
import { setAccountNameReducer } from './name';
import { setTaxRateReducer } from './taxRate';
import { setAccountSubdomainReducer } from './subdomain';
import { setAccountFundingProductsReducer } from './fundingProducts';
import { setStylingKeyReducer } from './stylingKey';
import { setAccountWebsiteReducer } from './website';
import { setAccountFacebookUrlReducer } from './facebookUrl';
import { setAccountInstagramUrlReducer } from './instagramUrl';
import { setAccountTwitterUrlReducer } from './twitterUrl';
import { setAccountYelpUrlReducer } from './yelpUrl';
import { setAccountAddressReducer } from './address';
import { setAccountCityReducer } from './city';
import { setAccountZipReducer } from './zip';
import { setAccountFuneralHomeNumberReducer } from './funeralHomeNumber';
import { setAccountMediaDomainReducer } from './mediaDomain';
import { setAccountEmailOnBehalfReducer } from './emailOnBehalf';
import { setAccountEmailSentFromEfuneralReducer } from './emailSentFromEfuneral';
import setAgentReducer from '../account/agent/root';
import { setDisableACHReducer } from './disableACH';
import { setAccountHellosignKeyReducer } from './hellosignClientKey';
import { setAccountHostedDomainReducer } from './hostedDomain';
import { setAccountDemoHostedDomainReducer } from './demoHostedDomain';
import { setFeatureVitalStatisticsReducer } from './featureVitalStatistics';
import { setAccountDisablePaymentPlansWhenBelowReducer } from './disablePaymentPlansWhenBelow';
import { setAccountGoogleTagManagerIdReducer } from './googleTagManagerId';
import { setFeatureDisableCreditCardPaymentsReducer } from './featureDisableCreditCardPayments';
import { setFeatureEnhancedWebhooksReducer } from './featureEnhancedWebhooks';
import { setAccountNotificationEmailReducer } from './notificationEmail';
import { setAccountEfuneralServiceTierReducer } from './efuneralServiceTier';

export default combineReducers({
    id: setAccountIdReducer,
    state: setAccountStateReducer,
    phone: setAccountPhoneReducer,
    name: setAccountNameReducer,
    taxRate: setTaxRateReducer,
    subdomain: setAccountSubdomainReducer,
    fundingProducts: setAccountFundingProductsReducer,
    stylingKey: setStylingKeyReducer,
    website: setAccountWebsiteReducer,
    facebookUrl: setAccountFacebookUrlReducer,
    instagramUrl: setAccountInstagramUrlReducer,
    twitterUrl: setAccountTwitterUrlReducer,
    yelpUrl: setAccountYelpUrlReducer,
    address: setAccountAddressReducer,
    city: setAccountCityReducer,
    funeralHomeNumber: setAccountFuneralHomeNumberReducer,
    zip: setAccountZipReducer,
    mediaDomain: setAccountMediaDomainReducer,
    emailOnBehalf: setAccountEmailOnBehalfReducer,
    emailSentFromEfuneral: setAccountEmailSentFromEfuneralReducer,
    disableACH: setDisableACHReducer,
    featureVitalStatistics: setFeatureVitalStatisticsReducer,
    agent: setAgentReducer,
    hellosignKey: setAccountHellosignKeyReducer,
    hostedDomain: setAccountHostedDomainReducer,
    demoHostedDomain: setAccountDemoHostedDomainReducer,
    disablePaymentPlansWhenBelow: setAccountDisablePaymentPlansWhenBelowReducer,
    googleTagManagerId: setAccountGoogleTagManagerIdReducer,
    featureDisableCreditCardPayments: setFeatureDisableCreditCardPaymentsReducer,
    featureEnhancedWebhooks: setFeatureEnhancedWebhooksReducer,
    notificationEmail: setAccountNotificationEmailReducer,
    efuneralServiceTier: setAccountEfuneralServiceTierReducer
});
