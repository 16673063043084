import { connect } from 'react-redux';
import CheckPaymentContentComponent from './CheckPaymentContentComponent';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import { getAmountToBePaidTotal } from '../../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { setSelectedPaymentOptionAction } from '../../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { formatMonetaryAmount } from '../../../../../../toolboxes/reuseable-logic/string-formatters';
import { formIsValid, validateBankingInformation } from '../../../../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import {
    getPaymentOptionsForPaymentType,
    calculateAmountDueToday
} from '../../../../../../toolboxes/reuseable-logic/payment-options-calculator';
import { dataWrapper } from '../../../../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import urlPaths from '../../../../../../static/constants/enums/urlPaths';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const isAgent = !!representativeId;
    let nextUrl = undefined;

    if (isAgent) {
        nextUrl = urlPaths.representativeIdentity;
    } else {
        nextUrl = urlPaths.ssnCapture;
    }

    return {
        totalPrice: formatMonetaryAmount(getAmountToBePaidTotal(state).toString()),
        dueToday: formatMonetaryAmount(calculateAmountDueToday(state).toString()),
        dueMonthly: formatMonetaryAmount(
            dataWrapper(() => state.opportunity.pricing.paymentOptions.selectedOption.premium)
        ),
        numberOfMonthsToPay: dataWrapper(() => state.opportunity.pricing.paymentOptions.selectedOption.years * 12 - 1),
        paymentOptions: state.opportunity.pricing.isPredetermined
            ? [state.opportunity.pricing.paymentOptions.selectedOption]
            : getPaymentOptionsForPaymentType(state, PaymentMethod.ACH),
        selectedOption: state.opportunity.pricing.paymentOptions.selectedOption,
        formValid: formIsValid(getFormValues('bankingInformation')(state), validateBankingInformation),
        nextUrl: nextUrl
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOption: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPaymentContentComponent);
