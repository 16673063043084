import React, { Fragment } from 'react';
import Product from '../../../../static/models/Product';
import { formatMonetaryAmount } from '../../../../toolboxes/reuseable-logic/string-formatters';
import './BasicProductCardComponent.css';
import Button from '@material-ui/core/Button';
import { Input } from '@material-ui/core';
import { normalizePrice } from '../../../../toolboxes/redux-form/redux-form-normalizers';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ProductDescriptionModalWrapper from '../../../modal-components/product-description-modal-component/ProductDescriptionModalWrapper';
import MockLinkWrapper from '../../mock-link-component/MockLinkWrapper';

class BasicProductCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            customPricing: props.product.price,
            moreDetailsOpen: false
        };
    }

    static defaultProps = {
        // Default prop values
        canEditPrice: false
    };

    handlePriceRewrite(newPrice: string) {
        const formattedPrice = normalizePrice(newPrice);
        try {
            this.setState({
                customPricing: parseFloat(formattedPrice)
            });
        } catch {}
    }

    render() {
        return (
            <Fragment>
                {this.state.moreDetailsOpen && (
                    <ProductDescriptionModalWrapper
                        product={this.props.product}
                        onRequestClose={() => this.setState({ moreDetailsOpen: false })}
                    />
                )}
                <div className="agent-product-card" id={`component-product-card-${this.props.product.productId}`}>
                    <div className="agent-product-card-non-controls">
                        {this.props.product.imageUrl && (
                            <div className="agent-product-card-image-container">
                                <img
                                    className="agent-product-card-image"
                                    src={this.props.product.imageUrl}
                                    alt={`${this.props.product.name} Diplay`}
                                />
                            </div>
                        )}
                        <div className="agent-product-card-title">{this.props.product.name}</div>
                        <div className="agent-product-card-more-info">
                            <MockLinkWrapper id="more-details" onClick={() => this.setState({ moreDetailsOpen: true })}>
                                More Details
                            </MockLinkWrapper>
                        </div>
                    </div>
                    <div className="agent-product-card-controls">
                        {this.props.canEditPrice ? (
                            <div className="agent-product-card-price">
                                <Input
                                    className="agent-product-card-price-input"
                                    value={formatMonetaryAmount(this.state.customPricing.toString()).substring(1)}
                                    onChange={event => this.handlePriceRewrite(event.target.value)}
                                    style={{
                                        fontSize: '20px'
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        ) : (
                            <div className="agent-product-card-price">
                                {formatMonetaryAmount(this.state.customPricing.toString())}
                            </div>
                        )}

                        <Button
                            id={`button-add-product-${this.props.product.productId}`}
                            onClick={() =>
                                this.props.addOpportunityProductAction({
                                    ...this.props.product,
                                    quantity: 1,
                                    price: this.state.customPricing
                                })
                            }
                            color="primary"
                            variant="contained"
                            style={{
                                height: '45px',
                                margin: '10px',
                                width: '150px'
                            }}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    addOpportunityProductAction: (product: Product) => void;
    product: Product;
    canEditPrice: boolean;
}

interface StateShape {
    // Shape of local state
    customPricing: number;
    moreDetailsOpen: boolean;
}

export default BasicProductCardComponent;
