import { connect } from 'react-redux';
import PurchaserSaleCompletePageComponent from './PurchaserSaleCompletePageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        isAtNeed: isAtNeed(state),
        opportunityId: state.opportunity.id
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserSaleCompletePageComponent);
