import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import {
    sendSaveHealthHistoryDisclaimer,
    sendSaveIrrevocabilityDisclaimer
} from '../../server-application-buffer/disclaimer-data';
import {
    sendSaveHealthHistoryAnswers,
    sendSaveIrrevocableData
} from '../../server-application-buffer/general-opportunity-data';

export function savePurchaserOrderSummaryData(state: GlobalStateShape): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveHealthHistoryDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserIdentity]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveHealthHistoryAnswers(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserIdentity]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveIrrevocableData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserIdentity]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveIrrevocabilityDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserIdentity]
        }
    ];
}
