import React from 'react';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ErrorPageFormWrapper from 'components/form-components/error-page-form-component/ErrorPageFormWrapper';

class ErrorPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="ErrorPage" metaPageNumber="" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Sorry</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Something went wrong. If you would like to request support, please fill out the form below and
                        one of our team members will work to help resolve the issue.
                    </PageCardTextWrapper>
                    <ErrorPageFormWrapper />
                    <ButtonLinkWrapper buttonId="Submit" to={urlPaths.errorSubmission}>
                        Submit
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default ErrorPageComponent;
