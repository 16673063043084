import { connect } from 'react-redux';
import StateFilingFeePageComponent from './StateFilingFeePageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { formatMonetaryAmount } from '../../../../toolboxes/reuseable-logic/string-formatters';
import { FilingFeeData } from '../../../../static/constants/stateSpecificLists/filingFeeData';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';
import { setStateFilingFeeAction } from '../../../../redux/disclaimerData/disclaimerAddressings/stateFilingFee';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const purchaserFirstName = getFormData(state, 'purchaser', 'firstName');
    const purchaserLastName = getFormData(state, 'purchaser', 'lastName');
    const filingFeeAmount = FilingFeeData[state.account.state].filingFee;
    const serviceFeeAmount = FilingFeeData[state.account.state].serviceFee;
    const totalAmount = filingFeeAmount + serviceFeeAmount;
    return {
        amount: formatMonetaryAmount(filingFeeAmount.toString()),
        fee: formatMonetaryAmount(serviceFeeAmount.toString()),
        totalAmount: formatMonetaryAmount(totalAmount.toString()),
        state: state.account.state,
        purchaserFirstName,
        purchaserLastName,
        isHomesteadersAgent: !!getFormData(state, 'representative', 'homesteadersMarketCode')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setStateFilingFeeAction: newValue => dispatch(setStateFilingFeeAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StateFilingFeePageComponent);
