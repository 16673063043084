import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { Action } from 'history';
import { sendSaveStateFilingFeeDisclaimer } from '../../server-application-buffer/disclaimer-data';
import { saveStateFilingFee } from '../../server-application-buffer/general-opportunity-data';
import { getFormData } from '../../redux-form/redux-form-data-access';

export function exitStateFilingFee(
    state: GlobalStateShape,
    dispatch: Function,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    if (!lastNavigationWasInternal) {
        return [];
    }
    return [
        {
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    sendSaveStateFilingFeeDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.orderSummary]
        },
        {
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    if (getFormData(state, 'representative', 'homesteadersMarketCode')) {
                        return resolve();
                    }
                    saveStateFilingFee(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.orderSummary]
        }
    ];
}
