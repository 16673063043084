import React from 'react';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { Timing } from 'static/constants/enums/timing';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import AtNeedPurchaseProcessing from './AtNeedPurchaseProcessing';
import TrustPurchaseProcessing from './TrustPurchaseProcessing';
import VitalStatistics from './VitalStatistics';

class PurchaseProcessingPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        creditCardLastFour: '',
        funeralPrice: 0,
        timing: Timing.FUTURE,
        vitalStatisticsUrl: '',
        preneedContractType: '',
        opportunityId: '',
        paymentMethod: '',
        funeralHomeName: '',
        email: '',
        formIsValid: false,
        contactPresent: true,
        funeralHomePhone: '',
        recipientName: '',
        featureVitalStatistics: true
    };

    render() {
        if (this.props.timing === Timing.IMMEDIATE) {
            return <AtNeedPurchaseProcessing {...this.props} />;
        } else if (this.props.preneedContractType === PreneedContractType.Trust) {
            return <TrustPurchaseProcessing {...this.props} />;
        } else {
            return (
                <FullContentCenterWrapper
                    metaPageName="PurchaseProcessing"
                    metaPageNumber="5"
                    metaPagebackAvailable="false"
                >
                    <PageCardWrapper extraCardWidth>
                        <PageCardTitleWrapper>Thank you.</PageCardTitleWrapper>
                        {this.props.opportunityId && (
                            <PageCardTextWrapper>
                                Arrangements Confirmation: {this.props.opportunityId}
                            </PageCardTextWrapper>
                        )}
                        <PageCardTextWrapper>
                            Thank you for making your prearrangements with {this.props.funeralHomeName || 'us'}. Be on
                            the lookout for an email from {this.props.email || 'us'} with an order confirmation.
                        </PageCardTextWrapper>
                        <PageCardTextWrapper>
                            Once our staff reviews and approves your order, we will charge the{' '}
                            {this.props.paymentMethod || 'payment method'} you have provided. When this happens we will
                            send another confirmation email along with a copy of the paperwork. At that time, we will
                            also send a copy to{' '}
                            {/*
                                If we have an opportunity Id, then we should have the funding provider in state
                                If we have the provider in state, then we shouldn't show this page component for non-homesteaders sales
                            */}
                            {this.props.opportunityId ? 'Homesteaders Life Company' : 'the funding provider'}. They will
                            process the application within three to five business days.
                        </PageCardTextWrapper>
                        <PageCardTextWrapper>
                            We will also maintain a copy of your application at our office.
                        </PageCardTextWrapper>
                        <PageCardTextWrapper>
                            {this.props.funeralHomePhone &&
                                `If you have any questions, please don't hesitate to contact us during regular business hours at ${formatPhoneNumber(
                                    this.props.funeralHomePhone
                                )}.`}
                            Thank you again for trusting {this.props.funeralHomeName || 'us'} with your preneed planning
                            arrangements.
                        </PageCardTextWrapper>
                        {this.props.contactPresent && this.props.featureVitalStatistics && (
                            <VitalStatistics
                                recipientName={this.props.recipientName}
                                formIsValid={this.props.formIsValid}
                            />
                        )}
                    </PageCardWrapper>
                </FullContentCenterWrapper>
            );
        }
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    creditCardLastFour: string;
    funeralPrice: number;
    timing: Timing;
    vitalStatisticsUrl: string;
    preneedContractType: string;
    opportunityId: string;
    paymentMethod: string;
    funeralHomeName: string;
    email: string;
    formIsValid: boolean;
    contactPresent: boolean;
    funeralHomePhone: string;
    recipientName: string;
    featureVitalStatistics: boolean;
    fundingProviderAccountKnownAs: string;
    fundingProviderAccountName: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaseProcessingPageComponent;
