import React, { Fragment } from 'react';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import { formatMonetaryAmount } from '../../../../../../toolboxes/reuseable-logic/string-formatters';
import './PaymentTrustOptionSelectionComponent.css';
import GeneralModalWrapper from '../../../../../modal-components/general-modal-component/GeneralModalWrapper';
import PageCardTitleWrapper from '../../../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import { PaymentMode } from '../../../../../../static/constants/enums/paymentModes';
import ButtonBoxWrapper from 'components/global-components/buttons/button-box-component/ButtonBoxWrapper';

class PaymentTrustOptionSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            descriptionOfChargesOpen: false
        };
        this.generateMainContent = this.generateMainContent.bind(this);
        this.generateSelectedContent = this.generateSelectedContent.bind(this);
    }

    static defaultProps = {
        // Default prop values
        setSelectedPaymentOption: () => {},
        selectedOption: null,
        amountDueToday: '',
        onClick: null,
        amountToBePaidTotal: ''
    };

    generateMainContent() {
        const paymentText =
            this.props.paymentOption.paymentMethod === PaymentMethod.CreditCard ? 'card' : 'bank account';

        const mainContent =
            this.props.paymentOption.paymentMode === PaymentMode.Single ? (
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                    <div style={{ padding: '5px' }}>
                        Your total amount
                        <br />
                        <b style={{ fontSize: '30px' }}>{this.props.amountDueToday}</b>
                        <br />
                        The total amount will be charged to your {paymentText} today
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                    <div>
                        Your monthly cost estimate
                        <br />
                        <b style={{ fontSize: '30px' }}>{`${formatMonetaryAmount(
                            this.props.paymentOption.premium.toString()
                        )} / mo`}</b>
                        <br />
                        for {this.props.paymentOption.months} months
                        <br />
                        The amount due today {this.props.amountDueToday}
                    </div>
                </div>
            );

        return mainContent;
    }

    generateSelectedContent() {
        return null;
    }

    render() {
        const mainContent = this.generateMainContent();
        const selectedContent = this.generateSelectedContent();
        let onClick = () => this.props.setSelectedPaymentOption(this.props.paymentOption);
        if (this.props.onClick) {
            onClick = () => this.props.onClick();
        }
        return (
            <Fragment>
                <ButtonBoxWrapper
                    onClick={onClick}
                    isSelected={JSON.stringify(this.props.selectedOption) === JSON.stringify(this.props.paymentOption)}
                    mainContent={mainContent}
                    selectedContent={selectedContent}
                />
                {this.state.descriptionOfChargesOpen && (
                    <GeneralModalWrapper onRequestClose={() => this.setState({ descriptionOfChargesOpen: false })}>
                        <FullContentCenterWrapper
                            metaPageName="PaymentOptionSelection"
                            metaPageNumber="3"
                            metaPagebackAvailable="true"
                        >
                            <PageCardTitleWrapper>{this.props.amountDueToday} Due Today</PageCardTitleWrapper>
                            <PageCardTextWrapper>
                                This includes any travel protection purchased, any relevant fees, and the first month's
                                payment.
                            </PageCardTextWrapper>
                        </FullContentCenterWrapper>
                    </GeneralModalWrapper>
                )}
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    paymentOption: PaymentOption;
    setSelectedPaymentOption: Function;
    selectedOption: PaymentOption;
    amountDueToday: string;
    onClick: Function;
    amountToBePaidTotal: string;
}

interface StateShape {
    // Shape of local state
    descriptionOfChargesOpen: boolean;
}

export default PaymentTrustOptionSelectionComponent;
