import urlPaths from 'static/constants/enums/urlPaths';
import {
    sendSaveCurrentPolicyData,
    sendSaveReplacingCurrentPolicyData
} from 'toolboxes/server-application-buffer/general-opportunity-data';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export function replacementExitFunctions(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];
    if ([true, false].includes(state.yesNoAnswers.policyQuestions.hasCurrentPolicies)) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveCurrentPolicyData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }
    if ([true, false].includes(state.yesNoAnswers.policyQuestions.replacingCurrentPolicies)) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveReplacingCurrentPolicyData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }
    return functionsToRun;
}
