import React from 'react';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import urlPaths from '../../../../static/constants/enums/urlPaths';

class RecipientUnavailableImpactPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        recipientFirstName: '',
        setRecipientUnavailalbeRatesDisclaimerAction: () => {},
        setRecipientIsUnavailableAction: () => {},
        trustText: ''
    };

    render() {
        return (
            <StandardDisclaimerPageWrapper
                disclaimerTitle="Recipient Unavailable"
                acceptTo={urlPaths.beneficiaryCheck}
                rejectTo={urlPaths.recipientIdentity}
                acceptanceText="Pay in Full"
                rejectionText="Cancel"
                saveDisclaimerAddressingToRedux={newValue =>
                    this.props.setRecipientUnavailalbeRatesDisclaimerAction(newValue)
                }
                acceptanceSideAffect={() => this.props.setRecipientIsUnavailableAction(true)}
                pageName="RecipientUnavailableImpact"
                pageBack="false"
                pageNumber=""
            >
                If the recipient {this.props.recipientFirstName} is unavailable, a policy can still be issued. However,
                without the recipient present to answer questions and agree to {this.props.trustText}, certain payment
                options will not be available. If you'd like to continue without {this.props.recipientFirstName} you
                will be required to pay for the arrangement in full.
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    recipientFirstName: string;
    setRecipientUnavailalbeRatesDisclaimerAction: Function;
    setRecipientIsUnavailableAction: Function;
    trustText: string;
}

interface StateShape {
    // Shape of local state
}

export default RecipientUnavailableImpactPageComponent;
