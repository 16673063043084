import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    sendIdentityEmail,
    sendAgentFlowCompleteEmail
} from '../../server-application-buffer/outgoing-api-triggering-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from '../../redux-form/redux-form-data-access';

export default function sendIdentityEmailLink(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendIdentityEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.sendIdentityLinkEmail]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    if (!getFormData(state, 'representative', 'homesteadersMarketCode')) {
                        return resolve();
                    }
                    sendAgentFlowCompleteEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.sendIdentityLinkEmail]
        }
    ];
    return functionsToRun;
}
