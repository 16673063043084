import { FontWeightProperty } from 'csstype';
import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_BODY_WEIGHT = 'SET_STYLING_BODY_WEIGHT';
// Action
export function setBodyWeightAction(newValue: FontWeightProperty) {
    return { type: SET_STYLING_BODY_WEIGHT, newValue };
}
// Reducer
export function setBodyWeightReducer(state: any, action: any): FontWeightProperty {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().fonts.bodyWeight;
    }
    switch (action.type) {
        case SET_STYLING_BODY_WEIGHT:
            return action.newValue;
        default:
            return state;
    }
}
