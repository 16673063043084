import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from '../../redux-form/redux-form-data-access';

const recipientIdentityReroutes = (state: GlobalStateShape): string => {
    if (
        state.opportunity?.recipient?.identityVerificationPlaid &&
        state.opportunity?.recipient?.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
    ) {
        return urlPaths.recipientIdentityFailed;
    }
    if (getFormData(state, 'representative', 'id') && !state.representative.insuredAvailabilityAddressed) {
        return urlPaths.recipientAvailabilityCheck;
    }
};

export default recipientIdentityReroutes;
