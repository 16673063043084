export function translateYesNoStringIntoBoolean(data: string): boolean | undefined {
    if (data === 'Yes') {
        return true;
    }
    if (data === 'No') {
        return false;
    }
    return undefined;
}

export function translateBooleanIntoYesNoString(data: boolean): string {
    if (data === true) {
        return 'Yes';
    }
    if (data === false) {
        return 'No';
    }
    return '';
}
