import { fetchGlobalFeatureFlags } from '../web-requests/specific-requests/feature-flags';
import { defaultFeatureFlags, setFeatureFlagAction } from 'redux/featureFlags/root';

export function fetchAndStoreGlobalFeatureFlags(dispatch: Function, callback: (result: any, error: any) => void): void {
    // Calculate feature flags
    // TODO need to figure a way to call the backend to recieve the flags
    fetchGlobalFeatureFlags((result, error) => {
        result.forEach(flag => {
            if (flag.description in defaultFeatureFlags) {
                dispatch(setFeatureFlagAction(flag.description, flag.enabled));
            }
        });
        return callback(result, undefined);
    });
}
