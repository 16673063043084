import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { setOpportunityIdAction } from '../../../redux/opportunity/id';
import { setTravelOpportunityIdAction } from '../../../redux/travelOpportunity/id';
import { setStylingLoadingCompleteAction } from '../../../redux/stylingOptions/loadingComplete';
import { fetchAndStoreGlobalFeatureFlags } from 'toolboxes/server-application-buffer/feature-flags-data';

export function agentStartEntry(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStoreGlobalFeatureFlags(dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.agentStart]
    });

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                dispatch(setStylingLoadingCompleteAction(true));
                resolve();
            }),
        blocksPageStarts: [urlPaths.agentStart]
    });

    if (state.opportunity.id) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    dispatch(setOpportunityIdAction(''));
                    resolve();
                }),
            blocksPageStarts: [urlPaths.agentStart]
        });
    }
    if (state.travelOpportunity.id) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    dispatch(setTravelOpportunityIdAction(''));
                    resolve();
                }),
            blocksPageStarts: [urlPaths.agentStart]
        });
    }
    if (window.localStorage.getItem('opportunityId')) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    window.localStorage.removeItem('opportunityId');
                    resolve();
                }),
            blocksPageStarts: [urlPaths.agentStart]
        });
    }
    return functionsToRun;
}
