import { connect } from 'react-redux';
import VitalStatisticsPageComponent from './VitalStatisticsPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import {
    formIsValid,
    validateRecipientVital,
    validateMotherVital,
    validateFatherVital,
    validateSpouseVital,
    validateEmploymentVital,
    validateMilitaryVital
} from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const recipientVitalFormValid = formIsValid(getFormValues('recipientVital')(state), validateRecipientVital);
    const motherVitalFormValid = formIsValid(getFormValues('motherVital')(state), validateMotherVital);
    const fatherVitalFormValid = formIsValid(getFormValues('fatherVital')(state), validateFatherVital);
    const spouseVitalFormValid = formIsValid(getFormValues('spouseVital')(state), validateSpouseVital);
    const employmentVitalFormValid = formIsValid(getFormValues('employmentVital')(state), validateEmploymentVital);
    const militaryVitalFormValid = formIsValid(getFormValues('militaryVital')(state), validateMilitaryVital);
    return {
        formIsValid:
            recipientVitalFormValid &&
            motherVitalFormValid &&
            fatherVitalFormValid &&
            spouseVitalFormValid &&
            employmentVitalFormValid &&
            militaryVitalFormValid
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(VitalStatisticsPageComponent);

export default DefaultComponent;
