import React from 'react';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import { formatMonetaryAmount, formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import { Timing } from 'static/constants/enums/timing';
import VitalStatistics from './VitalStatistics';

class PurchaseProcessingPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        creditCardLastFour: '',
        funeralPrice: 0,
        timing: Timing.FUTURE,
        vitalStatisticsUrl: '',
        preneedContractType: '',
        opportunityId: '',
        paymentMethod: '',
        funeralHomeName: '',
        email: '',
        formIsValid: false,
        contactPresent: true,
        funeralHomePhone: '',
        recipientName: '',
        featureVitalStatistics: true
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaseProcessing"
                metaPageNumber="5"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>Thank you.</PageCardTitleWrapper>
                    <PageCardTextWrapper>Arrangements Confirmation: {this.props.opportunityId}</PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Thank you for trusting {this.props.funeralHomeName} with your arrangements. We are sorry for
                        your loss and want to help you and your loved ones through this difficult time.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Your purchase has been successfully processed. The card ending in{' '}
                        {this.props.creditCardLastFour} has been charged {formatMonetaryAmount(this.props.funeralPrice)}
                        .
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        One of our representatives will be contacting you to begin the process of transferring your
                        loved one into our care and to finalize the details of your services. In the meantime, if you
                        have any further questions or immediate needs, please don't hesitate to contact us at{' '}
                        {formatPhoneNumber(this.props.funeralHomePhone)}.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Please print off this page for your records. You should also receive an email from{' '}
                        {this.props.email} with these details shortly.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Thank you again for planning with {this.props.funeralHomeName}.
                    </PageCardTextWrapper>
                    {this.props.contactPresent && this.props.featureVitalStatistics && (
                        <VitalStatistics
                            recipientName={this.props.recipientName}
                            formIsValid={this.props.formIsValid}
                        />
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    creditCardLastFour: string;
    funeralPrice: number;
    timing: Timing;
    vitalStatisticsUrl: string;
    preneedContractType: string;
    opportunityId: string;
    paymentMethod: string;
    funeralHomeName: string;
    email: string;
    formIsValid: boolean;
    contactPresent: boolean;
    funeralHomePhone: string;
    recipientName: string;
    featureVitalStatistics: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PurchaseProcessingPageComponent;
