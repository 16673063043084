import { connect } from 'react-redux';
import FuneralContractsPageComponent from './FuneralContractsPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import EverdaysFuneralContractsPageWrapper from 'components/account-specific-components/accounts/everdays/funeral-contracts-page-component/EverdaysFuneralContractsPageWrapper';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        contractsUrl: state.opportunity.contractSigning.contractUrl,
        funeralHomeName: state.account.name
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

//export default connect(mapStateToProps, mapDispatchToProps)(FuneralContractsPageComponent);
const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(FuneralContractsPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EVERDAYS_STYLING_KEY]: EverdaysFuneralContractsPageWrapper
});
