import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_PAYMENT_PLAN_HEALTH_ADDRESSING = 'SET_PAYMENT_PLAN_HEALTH_ADDRESSING';
// Action
export function setPaymentPlanHealthAddressingAction(newValue: DisclaimerAddressing) {
    return { type: SET_PAYMENT_PLAN_HEALTH_ADDRESSING, newValue };
}
// Reducer
export function setPaymentPlanHealthAddressingReducer(state: any, action: any): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_PAYMENT_PLAN_HEALTH_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
