import { connect } from 'react-redux';
import EverdaysPurchaseProcessingPageComponent from './EverdaysPurchaseProcessingPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        vitalStatisticsUrl: state.urlPaths.vitalStatistics
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EverdaysPurchaseProcessingPageComponent);
