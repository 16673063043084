import { combineReducers } from 'redux';
import { setErrorLoggingDataReducer } from './errorData';
import { setErrorLoggingCaseNumberReducer } from './errorCaseNumber';
import { setErrorLoggingCaseCreationFailedReducer } from './errorCaseCreationFailed';
import { setErrorLoggingCaseIdReducer } from './errorCaseId';

export default combineReducers({
    errorData: setErrorLoggingDataReducer,
    errorCaseNumber: setErrorLoggingCaseNumberReducer,
    errorCaseCreationFailed: setErrorLoggingCaseCreationFailedReducer,
    errorCaseId: setErrorLoggingCaseIdReducer
});
