import { connect } from 'react-redux';
import SpouseVitalFormComponent from './SpouseVitalFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { MaritalStatus } from '../../../static/constants/enums/maritalStatus';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const maritalStatus = getFormData(state, 'spouseVital', 'maritalStatus');
    return {
        wasOrIsMarried: maritalStatus && maritalStatus !== MaritalStatus.NeverMarried
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpouseVitalFormComponent);
