// Action Type
export const SET_DISABLE_ACH = 'SET_DISABLE_ACH';
// Action
export function setDisableACHAction(newValue: boolean) {
    return { type: SET_DISABLE_ACH, newValue };
}
// Reducer
export function setDisableACHReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_DISABLE_ACH:
            return action.newValue;
        default:
            return state;
    }
}
