import React from 'react';
import { Redirect } from 'react-router';
import urlPaths from '../../../static/constants/enums/urlPaths';

class DocusignReroutePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            outdatedDataCleared: false
        };
    }

    static defaultProps = {
        // Default prop values
        outdatedInformationNeedsCleared: false,
        pageToRerouteTo: '',
        clearFuneralContractUrlAction: () => {},
        clearTravelContractsUrlAction: () => {}
    };

    componentDidMount() {
        if (this.props.outdatedInformationNeedsCleared) {
            if (this.props.pageToRerouteTo === urlPaths.funeralContracts) {
                this.props.clearFuneralContractUrlAction();
            }
            if (this.props.pageToRerouteTo === urlPaths.travelContracts) {
                this.props.clearTravelContractsUrlAction();
            }
            this.setState({
                outdatedDataCleared: true
            });
        }
    }

    render() {
        if (this.props.outdatedInformationNeedsCleared && !this.state.outdatedDataCleared) {
            return null;
        }
        if (!this.props.pageToRerouteTo) {
            return null;
        }
        return <Redirect to={this.props.pageToRerouteTo} />;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    outdatedInformationNeedsCleared: boolean;
    pageToRerouteTo: string;
    clearFuneralContractUrlAction: Function;
    clearTravelContractsUrlAction: Function;
}

interface StateShape {
    // Shape of local state
    outdatedDataCleared: boolean;
}

export default DocusignReroutePageComponent;
