import Product from '../../static/models/Product';

export function addProductToList(existingList: Product[], newProduct: Product) {
    // Look through all existing products in card, find if the new one matches one
    // If it does, add to the quantity
    // If it doesn't add to the list
    let newProductList: Product[] = [];
    let productAddedToExistingProduct = false;
    existingList.forEach((previousProduct: Product) => {
        const comparablePrevious = {
            ...newProduct,
            quantity: 0
        };
        const comparableNew = {
            ...previousProduct,
            quantity: 0
        };
        const areEqual = Object.keys(comparablePrevious).every(key => {
            return comparablePrevious[key] === comparableNew[key];
        });
        if (areEqual) {
            productAddedToExistingProduct = true;
            newProductList.push({
                ...previousProduct,
                quantity: previousProduct.quantity + newProduct.quantity
            });
        } else {
            newProductList.push(previousProduct);
        }
    });
    if (!productAddedToExistingProduct) {
        newProductList.push(newProduct);
    }
    return newProductList;
}

export function removeProductFromList(existingList: Product[], newProduct: Product) {
    // Look through all existing products in card, find if the new one matches one
    // If it does, add to the quantity
    // If it doesn't add to the list
    let newProductList: Product[] = [];
    existingList.forEach((previousProduct: Product) => {
        const comparablePrevious = {
            ...newProduct,
            quantity: 0
        };
        const comparableNew = {
            ...previousProduct,
            quantity: 0
        };
        const areEqual = Object.keys(comparablePrevious).every(key => {
            return comparablePrevious[key] === comparableNew[key];
        });
        if (areEqual) {
            const newAmount = previousProduct.quantity - newProduct.quantity;
            if (newAmount > 0) {
                newProductList.push({
                    ...previousProduct,
                    quantity: newAmount
                });
            }
        } else {
            newProductList.push(previousProduct);
        }
    });
    return newProductList;
}
