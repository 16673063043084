import { setOpportunityUpdateMarketCodeAction } from 'redux/opportunity/marketCode';
import Account from 'static/models/Account';
import { getBasePath } from 'toolboxes/reuseable-logic/get-base-path';
import GlobalStateShape from '../../redux/GlobalStateShape';
import { setOpportunityIdAction } from '../../redux/opportunity/id';
import { setSelectedFundingProductAction } from '../../redux/opportunity/selectedFundingProduct';
import { setOpportunityTimingAction } from '../../redux/opportunity/timing';
import { GeographicState } from '../../static/constants/enums/states';
import { FilingFeeData } from '../../static/constants/stateSpecificLists/filingFeeData';
import ContractCodesResponse from '../../static/models/contract-codes/contract-codes-response';
import FundingProduct from '../../static/models/FundingProduct';
import { getFormData } from '../redux-form/redux-form-data-access';
import { OpportunityCreationBlob } from '../testing-data';
import { runBeneficiaryCheckData } from '../web-requests/specific-requests/contact-update-requests';
import {
    runAgentOpportunitySetting,
    runCalculateContractCodes,
    runIrrevocableUpdate,
    runOpportunityCreation,
    runSaveCustomerPathAddition,
    runSaveCustomerPathAssociation,
    runStateFilingFeeUpdate,
    runSubmitHealthHistoryAnswers,
    runUpdateOpportunity
} from '../web-requests/specific-requests/opportunity-update-requests';
import { getAccountBySubdomain } from './account-data';
import { dataWrapper } from './generalized-data-buffer-tools/data-wrapper';
import { translateBooleanIntoYesNoString } from './generalized-data-buffer-tools/yes-no-translator';

export function sendSaveIrrevocableData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const irrevocable = translateBooleanIntoYesNoString(state.yesNoAnswers.policyQuestions.irrevocable);
    runIrrevocableUpdate({ irrevocable }, state.opportunity.id, (result, error) => {
        if (error) {
            return callback(result, error);
        }
        if (getFormData(state, 'purchaser', 'state') === GeographicState.MN) {
            const yesNoBeneficiaryValue = translateBooleanIntoYesNoString(
                dataWrapper(() => state.yesNoAnswers.policyQuestions.beneficiaryProvided)
            );
            runBeneficiaryCheckData(
                state.opportunity.id,
                {
                    beneficiaryProvided: yesNoBeneficiaryValue,
                    opportunityId: state.opportunity.id
                },
                callback
            );
        } else {
            return callback(result, error);
        }
    });
}

export function sendSaveCurrentPolicyData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const replacementExistingCoverage = translateBooleanIntoYesNoString(
        state.yesNoAnswers.policyQuestions.hasCurrentPolicies
    );
    runUpdateOpportunity(state.opportunity.id, state.account.id, { replacementExistingCoverage }, callback);
}

export function sendSaveReplacingCurrentPolicyData(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    const replacementReplacing = translateBooleanIntoYesNoString(
        state.yesNoAnswers.policyQuestions.replacingCurrentPolicies
    );
    runUpdateOpportunity(state.opportunity.id, state.account.id, { replacementReplacing }, callback);
}

export function sendRecipientUnavailableDataUpdate(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    const incapacitatedRates = state.opportunity.recipient.isUnavailable;
    runUpdateOpportunity(state.opportunity.id, state.account.id, { incapacitatedRates }, callback);
}

export function sendSaveRepresentativeData(
    opportunityId: string,
    repId: string,
    accountId: string,
    callback: (result: any, error: any) => void
) {
    const associationData = {
        externalPartnerRepresentativeId: repId
    };
    runUpdateOpportunity(opportunityId, accountId, associationData, callback);
}

export function sendSaveHealthHistoryAnswers(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const healthHistoryData = {
        hasAilment: translateBooleanIntoYesNoString(
            state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears
        ),
        hasCurrentHealthProblem: translateBooleanIntoYesNoString(
            state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled
        )
    };
    const personConcerned = state.opportunity.recipient.sameAsPurchaser
        ? state.opportunity.purchaser
        : state.opportunity.recipient;
    runSubmitHealthHistoryAnswers(personConcerned.id, healthHistoryData, callback);
}

export function updateOpportunityProductCode(
    state: GlobalStateShape,
    productCode: string,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    runUpdateOpportunity(
        state.opportunity.id,
        state.account.id,
        {
            contractProductCode: productCode
        },
        callback
    );
}

export function updateOpportunityMarketCode(
    state: GlobalStateShape,
    marketCode: string,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    dispatch(setOpportunityUpdateMarketCodeAction(marketCode));
    runUpdateOpportunity(
        state.opportunity.id,
        state.account.id,
        {
            contractMarketCode: marketCode
        },
        callback
    );
}

export function saveRelationshipToRecipient(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const relationship = getFormData(state, 'purchaser', 'relationshipToRecipient');
    runUpdateOpportunity(
        state.opportunity.id,
        state.account.id,
        {
            relationship
        },
        callback
    );
}

export function saveIsPlanningForSelf(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const planningFor = state.opportunity.recipient.sameAsPurchaser ? 'Self' : 'Other';
    runUpdateOpportunity(
        state.opportunity.id,
        state.account.id,
        {
            planningFor
        },
        callback
    );
}

export function updateOpportunityTiming(
    state: GlobalStateShape,
    timing: string,
    callback: (result: any, error: any) => void
) {
    runUpdateOpportunity(state.opportunity.id, state.account.id, { timing }, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}

export function saveStateFilingFee(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    runStateFilingFeeUpdate(
        state.opportunity.id,
        FilingFeeData[state.account.state].filingFee,
        FilingFeeData[state.account.state].serviceFee,
        callback
    );
}

export function saveAgentToOpportunity(
    state: GlobalStateShape,
    dispatch: Function,
    opportunityIdOverride: string,
    accountIdOverride: string,
    callback: (result: any, error: any) => void
) {
    let oppId = opportunityIdOverride || state.opportunity.id;
    let accountId = accountIdOverride || state.account.id;
    runUpdateOpportunity(
        oppId,
        accountId,
        {
            referralAgentMarketCode: getFormData(state, 'representative', 'homesteadersMarketCode')
        },
        (result, error) => {
            if (error) {
                return callback(null, error);
            }
            return runAgentOpportunitySetting(
                oppId,
                {
                    firstName: getFormData(state, 'representative', 'firstName'),
                    lastName: getFormData(state, 'representative', 'lastName'),
                    phoneNumber: getFormData(state, 'representative', 'phone'),
                    email: getFormData(state, 'representative', 'email'),
                    agentCode: getFormData(state, 'representative', 'id')
                },
                (result, error) => {
                    if (error) {
                        return callback(null, error);
                    }
                    return callback(result, null);
                }
            );
        }
    );
}

export function createOpportunityAndSaveId(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    if (window.localStorage.getItem('opportunityId')) {
        window.localStorage.removeItem('opportunityId');
    }
    dispatch(setOpportunityIdAction(''));
    getAccountBySubdomain(state.account.subdomain, (result, error) => {
        if (error) {
            return callback(null, error);
        }
        const accountId = result.id;
        // Create Products
        const products = state.opportunity.products.map(selectedProduct => {
            return {
                quantity: selectedProduct.quantity,
                priceListId: selectedProduct.productId,
                price: selectedProduct.price
            };
        });
        // Create travel products
        const travelProtectionProducts = state.travelOpportunity.products.map(selectedProduct => {
            return {
                quantity: selectedProduct.quantity,
                priceListId: selectedProduct.productId,
                price: selectedProduct.price
            };
        });
        //Create Opportunity
        const creationData: OpportunityCreationBlob = {
            products,
            travelProtectionProducts,
            paymentOption: state.opportunity.pricing.paymentOptions.selectedOption
                ? {
                      mode: state.opportunity.pricing.paymentOptions.selectedOption.paymentMode,
                      yearsPayable: state.opportunity.pricing.paymentOptions.selectedOption.years.toString(),
                      premiumAmount: state.opportunity.pricing.paymentOptions.selectedOption.premium.toString(),
                      faceAmount: state.opportunity.pricing.paymentOptions.selectedOption.faceAmount.toString()
                  }
                : null,
            accountId,
            timing: state.opportunity.timing,
            disposition: state.opportunity.disposition,
            purchaser: {
                lastName: null
            },
            insured: {
                lastName: null
            },
            isPlanningForSelf: state.opportunity.recipient.sameAsPurchaser
        };
        runOpportunityCreation(creationData, (result, error) => {
            if (error) {
                return callback(null, error);
            }
            const oppId = result.saleId;
            const homesteadersMarketCode = getFormData(state, 'representative', 'homesteadersMarketCode');
            window.localStorage.setItem('opportunityId', oppId);
            dispatch(setOpportunityIdAction(oppId));
            if (homesteadersMarketCode) {
                return saveAgentToOpportunity(state, dispatch, oppId, accountId, (result, error) => {
                    if (error) {
                        return callback(null, error);
                    }
                    return callback(oppId, null);
                });
            }
            return callback(oppId, null);
        });
    });
}

export function addPathToCustomerPathHistory(
    state: GlobalStateShape,
    newPath: string,
    callback: (result: any, error: any) => void
) {
    if (!state.opportunity.id) {
        return callback(null, null);
    }
    const currentTime = new Date(Date.now());
    newPath = getBasePath() + newPath;

    runSaveCustomerPathAddition(state.opportunity.id, `${currentTime.toISOString()}: ${newPath}`, callback);
}

/**
 * DEV2020-2171
 * Since adding a new addition to the Customer Path field in Salesforce requires accessing
 * the Opportunity multiple times, often paths weren't getting added or we ended up with the
 * "Unable to Lock Row" error. This adds an association object to the Opportunity rather than
 * updates the Opportunity itself so we don't run into these issues.
 */
export function addCustomerPathAssociation(
    state: GlobalStateShape,
    newPath: string,
    callback: (result: any, error: any) => void
) {
    if (!state.opportunity.id) {
        return callback(null, null);
    }
    const currentTime = new Date(Date.now());

    runSaveCustomerPathAssociation(state.opportunity.id, `${currentTime.toISOString()} --- ${newPath}`, callback);
}

export function calculateAndUpdateOpportunityContractCodes(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: ContractCodesResponse, error: any) => void
) {
    if (!state.opportunity.id) {
        return callback(null, null);
    }

    runCalculateContractCodes(state.opportunity.id, (result: ContractCodesResponse, error: any) => {
        if (error) {
            return callback(null, error);
        }

        const fundingProduct: FundingProduct = {
            productId: result.contractProductCode,
            contractId: result.contractId,
            productType: result.funeralFundingProduct,
            provider: result.funeralFundingProvider
        };

        dispatch(setOpportunityUpdateMarketCodeAction(result.contractMarketCode));
        dispatch(setOpportunityTimingAction(result.timing));
        dispatch(setSelectedFundingProductAction(fundingProduct));
        return callback(result, null);
    });
}
