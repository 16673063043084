import React from 'react';
import RadioDescriptionWrapper from '../../buttons/radio-description-component/RadioDescriptionWrapper';
import { Timing } from '../../../../static/constants/enums/timing';

class TimingSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        selectedTiming: null,
        setOpportunityTimingAction: () => {}
    };

    render() {
        return (
            <div
                style={{
                    marginBottom: '10px'
                }}
            >
                <RadioDescriptionWrapper
                    onClick={() => this.props.setOpportunityTimingAction(Timing.IMMEDIATE)}
                    isSelected={this.props.selectedTiming === Timing.IMMEDIATE}
                    mainContent="Immediately"
                    descriptionContent=""
                    id="immediately"
                />
                <RadioDescriptionWrapper
                    onClick={() => this.props.setOpportunityTimingAction(Timing.IMMINENT)}
                    isSelected={this.props.selectedTiming === Timing.IMMINENT}
                    mainContent="Imminently"
                    descriptionContent="I need annuity because I am planning during the final stages of life (within 6 months)"
                    id="imminent"
                />
                <RadioDescriptionWrapper
                    onClick={() => this.props.setOpportunityTimingAction(Timing.FUTURE)}
                    isSelected={this.props.selectedTiming === Timing.FUTURE}
                    mainContent="In the Future"
                    descriptionContent="I need insurance because I am planning ahead (beyond 6 months)"
                    id="future"
                />
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    selectedTiming: Timing;
    setOpportunityTimingAction: Function;
}

interface StateShape {
    // Shape of local state
}

export default TimingSelectionComponent;
