import { PaymentMethod } from '../../static/constants/enums/paymentMethods';
import GlobalStateShape from '../../redux/GlobalStateShape';
import PaymentOption from '../../static/models/PaymentOption';
import { dataWrapper } from '../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { getTravelTotal } from './pricing-calculator';
import { PaymentMode } from '../../static/constants/enums/paymentModes';
import { OpportunityCommissionOption } from 'static/constants/enums/opportunityCommissionOption';
import { CommissionOption } from 'static/constants/enums/commissionOption';

export function getPaymentAllAvailablePaymentOptions(state: GlobalStateShape): PaymentOption[] {
    return dataWrapper(() => {
        if (state.opportunity.recipient.isUnavailable) {
            return state.opportunity.pricing.paymentOptions.availableOptions.filter(
                option => option.paymentMode === PaymentMode.Single
            );
        }
        return state.opportunity.pricing.paymentOptions.availableOptions;
    }, []);
}

export function getPaymentOptionsForPaymentType(
    state: GlobalStateShape,
    paymentMethod: PaymentMethod
): PaymentOption[] {
    return dataWrapper(() => {
        return getPaymentAllAvailablePaymentOptions(state).filter(option => {
            return option.paymentMethod === paymentMethod;
        });
    }, []);
}

export function getSinglePayAlternative(state: GlobalStateShape): PaymentOption {
    return dataWrapper(() => {
        const chosenOption = state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod;
        return getPaymentOptionsForPaymentType(state, chosenOption).find(
            option => option.paymentMode === PaymentMode.Single
        );
    }, null);
}

export function calculateAmountDueToday(state: GlobalStateShape): number {
    return dataWrapper(() => {
        const selectedPaymentOption = state.opportunity.pricing.paymentOptions.selectedOption;
        return selectedPaymentOption.amountOfFirstPayment + getTravelTotal(state);
    }, null);
}

export function paymentOptionsAreEquivalent(firstOption: PaymentOption, secondOption: PaymentOption) {
    if (!firstOption || !secondOption) {
        return !firstOption && !secondOption;
    }
    if (firstOption.paymentMode === PaymentMode.Single) {
        // Ignore the years for now
        return (
            JSON.stringify({
                ...firstOption,
                years: 0
            }) ===
            JSON.stringify({
                ...secondOption,
                years: 0
            })
        );
    }
    return JSON.stringify(firstOption) === JSON.stringify(secondOption);
}

export function getCommissionOptionForCalculator(option: OpportunityCommissionOption): CommissionOption {
    switch (option) {
        case OpportunityCommissionOption.Advantage:
            return CommissionOption.Advantage;
        case OpportunityCommissionOption.SecurityPlus:
            return CommissionOption.Plus;
        case OpportunityCommissionOption.Security:
            return CommissionOption.Security;
        default:
            return CommissionOption.Security;
    }
}
