import React from 'react';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { valueDisplaysFromEnum } from '../../../toolboxes/reuseable-logic/value-displays';
import { Relationships } from '../../../static/constants/enums/relationships';

class RelationshipToRecipientFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        relationshipToRecipient: ''
    };

    render() {
        return (
            <FormWrapper formName={'purchaser'} validate={() => ({})}>
                <SelectWrapper
                    formName={'purchaser'}
                    fieldPath={'relationshipToRecipient'}
                    valueDisplays={valueDisplaysFromEnum(Relationships)}
                    labelString={'I am arranging for my:'}
                    spacing={{
                        sm: 12
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    relationshipToRecipient: string;
}

interface StateShape {
    // Shape of local state
}

export default RelationshipToRecipientFormComponent;
