import { connect } from 'react-redux';
import GlobalStateShape from 'redux/GlobalStateShape';
import FailingRequestButtonComponent from './FailingRequestButtonComponent';
import getEnvironment from 'env-config/get-environment';
import { testEnvironment } from 'env-config/environment-configs/test';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const env = getEnvironment();
    return {
        disabled: env !== testEnvironment ? true : false,
        footerBackgroundColor: state.stylingOptions.colors.generalBackgroundColor
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FailingRequestButtonComponent);
