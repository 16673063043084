import { connect } from 'react-redux';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import { formIsValid, validatePhoneVerify } from '../../../toolboxes/redux-form/redux-form-validation';
import PurchaserPhoneVerifyPageComponent from './PurchaserPhoneVerifyPageComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const reachedStopPage =
        state.opportunity.stopPageDetail === 'Stopped because they failed identity too many times' ||
        state.opportunity.stopPageDetail ===
            'Stopped because purchaser decided to provide alternative form of identification' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid - special reasons';
    // These values will be passed in as props
    return {
        formIsValid: formIsValid(getFormValues('purchaserPhoneVerification')(state), validatePhoneVerify),
        phoneVerified: state.opportunity.purchaser.phoneVerified,
        timesPhoneVerificationSubmitted: state.opportunity.purchaser.timesPhoneVerificationSubmitted,
        reachedStopPage: reachedStopPage
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserPhoneVerifyPageComponent);
