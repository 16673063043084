// Action Type
export const SET_IS_BYPASSING_SECURITY = 'SET_IS_BYPASSING_SECURITY';
// Action
export function setIsBypassingSecurityAction(newValue: boolean) {
    return { type: SET_IS_BYPASSING_SECURITY, newValue };
}
// Reducer
export function setIsBypassingSecurityReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_IS_BYPASSING_SECURITY:
            return action.newValue;
        default:
            return state;
    }
}
