import { connect } from 'react-redux';
import YesNoComponent from './YesNoComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import EarthYesNoWrapper from 'components/account-specific-components/accounts/earth/yes-no-component/EarthYesNoWrapper';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { TULIP_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/tulip';
import { TITAN_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/titan';
import TitanYesNoWrapper from '../../../account-specific-components/accounts/titan/yes-no-component/TitanYesNoWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(YesNoComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EARTH_STYLING_KEY]: EarthYesNoWrapper,
    [TITAN_STYLING_KEY]: TitanYesNoWrapper
});
