import GlobalStateShape from '../../redux/GlobalStateShape';
import { dataWrapper } from '../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { getFormData } from '../redux-form/redux-form-data-access';
import { PaymentMode } from '../../static/constants/enums/paymentModes';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

export default function recipientConsentIsStillNecessary(state: GlobalStateShape) {
    const sameAsPurchaser = state.opportunity.recipient.sameAsPurchaser;
    const recipientDisclaimerGood =
        state.disclaimerData.disclaimerAddressings.recipientConsent.hasBeenAcceptedOrPositivelyAddressed;
    const recipientUnavailableRatesDisclaimerGood =
        state.disclaimerData.disclaimerAddressings.recipientUnavailableRates.hasBeenAcceptedOrPositivelyAddressed;
    const singlePaySet = dataWrapper(
        () => state.opportunity.pricing.paymentOptions.selectedOption.paymentMode === PaymentMode.Single
    );
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    const representativeIsActiveAndIndicatedAvailability =
        getFormData(state, 'representative', 'id') && state.representative.insuredAvailabilityAddressed;
    return !(
        isTrust ||
        sameAsPurchaser ||
        recipientDisclaimerGood ||
        recipientUnavailableRatesDisclaimerGood ||
        singlePaySet ||
        representativeIsActiveAndIndicatedAvailability
    );
}
