import { connect } from 'react-redux';
import PaymentPriceCardComponent from './PaymentPriceCardComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import accountComponentSwitch from '../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import TulipPaymentPriceCardWrapper from '../../account-specific-components/accounts/tulip-cremation/payment-price-card-component/TulipPaymentPriceCardWrapper';
import { TULIP_STYLING_KEY } from '../../../static/constants/styling-company-constants/companies/tulip';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(PaymentPriceCardComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TULIP_STYLING_KEY]: TulipPaymentPriceCardWrapper
});
