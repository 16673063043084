import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';
import {
    runSendEmail,
    runOutgoingDataSending,
    runSendFuneralReceivedEmail,
    runFailingRequest,
    runCreateErrorCase,
    runUpdateErrorCase,
    runCreateCase
} from '../web-requests/specific-requests/outgoing-data-trigger-requests';
import { TULIP_STYLING_KEY } from '../../static/constants/styling-company-constants/companies/tulip';
import { setVitalStatisticsUrlAction } from '../../redux/urlPaths/vitalStatistics';
import ErrorData from 'static/models/errorLogging/ErrorData';
import { getSubdomain } from 'toolboxes/reuseable-logic/get-base-path';
import CaseData from '../../static/models/case/caseData';
import { OutGoingApiEventType } from '../../static/constants/enums/OutGoingApiEventType';

export function sendIdentityEmail(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const data = {
        templateName: 'Purchaser - Identity Link',
        opportunityId: state.opportunity.id,
        toEmails: [getFormData(state, 'purchaser', 'email')]
    };
    runSendEmail(data, callback);
}

export function sendAgentFlowCompleteEmail(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const data = {
        templateName: 'Referral Agent - Agent Flow Complete',
        opportunityId: state.opportunity.id
    };
    runSendEmail(data, callback);
}

export function sendFuneralReceivedEmail(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    // May not have loaded entire opportunity if only using secure signing
    const opportunityId =
        state.opportunity.id || state.opportunity.contractSigning.activeContractSigningCredentials?.opportunityId;
    runSendFuneralReceivedEmail(opportunityId, callback);
}

export function sendFailingRequest(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const data = {
        message:
            'sending the test failing request. ' +
            (state.opportunity.id ? 'Opportunity Id: ' + state.opportunity.id : 'No opportunity id')
    };
    runFailingRequest(data, callback);
}
export function sendErrorDataToCreateErrorCase(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const storedOpportunityId = window.localStorage.getItem('opportunityId');
    const oppId = state.opportunity.id ? state.opportunity.id : storedOpportunityId;

    const data: ErrorData = {
        opportunityId: oppId,
        accountId: state.account.id,
        contactId: state.opportunity.purchaser.id,
        subdomain: getSubdomain(),
        request: state.errorLogging.errorData.request,
        response: state.errorLogging.errorData.response,
        url: state.errorLogging.errorData.url
    };
    runCreateErrorCase(data, callback);
}

export function sendDataToCreateTwilioVerificationCase(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    const storedOpportunityId = window.localStorage.getItem('opportunityId');
    const oppId = state.opportunity.id ? state.opportunity.id : storedOpportunityId;
    const caseData: CaseData = {
        contactId: state.opportunity.purchaser.id,
        accountId: state.account.id,
        origin: 'Web',
        type: 'Error Assistance',
        reason: 'Twilio',
        subject: 'Return Later Twilio Failure: Manual Assistance',
        description:
            'This customer attempted to use the return later link but failed all options for Twilio verification on the security check. We need to reach out to assist.',
        opportunityId: oppId
    };
    runCreateCase(caseData, callback);
}

export function sendUpdateErrorCase(
    state: GlobalStateShape,
    errorCaseNumber: string,
    callback: (result: any, error: any) => void
) {
    const name = getFormData(state, 'errorForm', 'name');
    const phone = getFormData(state, 'errorForm', 'phone');
    const email = getFormData(state, 'errorForm', 'email');
    const subject = getFormData(state, 'errorForm', 'subject');
    const details = getFormData(state, 'errorForm', 'details');

    const data = {
        suppliedName: name,
        SuppliedPhone: phone,
        SuppliedEmail: email,
        subject: subject,
        Description: details
    };
    runUpdateErrorCase(errorCaseNumber, data, callback);
}

export function initiateOutgoingDataApi(
    eventType: OutGoingApiEventType,
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    runOutgoingDataSending({ eventType: eventType, opportunityId: state.opportunity.id }, (result, error) => {
        // If a funeral home doesn't have api enabled an error will occur. We don't want this to stop our flow
        if (error) {
            callback(undefined, undefined);
        }

        /* This whole if statement is deprecated. the end of experience/vitial statistics url is now going to be sent on the
         * api opportunity creation. This is left here to give Tulip time to switch up their api call.
         * DEV2020-1826 added the feature flag check here. Remove this entirely with DEV2020-1830
         */
        if (!state.featureFlags.useEndOfExperienceOption && state.account.stylingKey === TULIP_STYLING_KEY) {
            try {
                const redirectUrl = result.redirect_url;
                if (redirectUrl) {
                    dispatch(setVitalStatisticsUrlAction(redirectUrl));
                }
            } catch {}
        }
        callback(result, error);
    });
}
