import GlobalStateShape from 'redux/GlobalStateShape';
import { Disposition } from 'static/constants/enums/disposition';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import { FundingProductType } from '../../static/constants/enums/fundingProductType';
import { TITAN_STYLING_KEY } from '../../static/constants/styling-company-constants/companies/titan';

export function getDispositionText(state: GlobalStateShape, capitalize = false): string {
    let dispositionText = '';
    switch (state.opportunity?.disposition) {
        case Disposition.Burial:
            dispositionText = 'Burial';
            break;
        case Disposition.Cremation:
            dispositionText = 'Cremation';
            break;
        default:
            dispositionText = 'Funeral';
            break;
    }

    switch (state.account?.stylingKey) {
        case EARTH_STYLING_KEY:
            dispositionText = 'Soil Transformation';
            break;
        case TITAN_STYLING_KEY:
            dispositionText = 'Arrangement';
            break;
        default:
            break;
    }

    if (!capitalize) {
        return dispositionText.toLowerCase();
    }
    return dispositionText;
}

export function getPolicyContractText(state: GlobalStateShape, capitalize = false): string {
    let policyContractText = '';

    switch (state.opportunity?.selectedFundingProduct?.productType) {
        case FundingProductType.Trust:
            policyContractText = 'Contract';
            break;
        default:
            policyContractText = 'Policy';
            break;
    }

    if (!capitalize) {
        return policyContractText.toLowerCase();
    }
    return policyContractText;
}

export function getBeneficiaryLanguage(state: GlobalStateShape, capitalize = false): string {
    let beneficiaryText = ``;

    switch (state.account?.stylingKey) {
        case EARTH_STYLING_KEY:
            beneficiaryText = `Rarely there are proceeds remaining after the cost of the arranged soil transformation. 
            You can designate a specific beneficiary to receive these. 
            Alternatively, you can elect to have remaining proceeds paid to your estate`;
            break;
        case TITAN_STYLING_KEY:
            beneficiaryText = `Occasionally there are remaining proceeds after the cost of the arrangements at the time of 
            need. You can designate a specific beneficiary to receive these.`;
            break;
        default:
            beneficiaryText = `Occasionally there are remaining proceeds after the cost of the arranged
    ${getDispositionText(state)}. You can designate a specific beneficiary to receive these.
        Alternatively, you can elect to have remaining proceeds paid to the estate.`;
            break;
    }

    if (capitalize) {
        return beneficiaryText.toUpperCase();
    }
    return beneficiaryText;
}
