export const normalizeBooleanFromString = (value: string) => {
    if (!value) return '';
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return value;
};

const normalizeNumberWithLength = (length: number) => {
    return (value: string) => {
        if (!value) return '';
        const digitsOnlyString = value.replace(/\D/g, '');
        const shortenedString = digitsOnlyString.substring(0, length);
        return shortenedString;
    };
};

export const normalizeNumber = (value: string) => {
    if (!value) return '';
    const digitsOnlyString = value.replace(/\D/g, '');
    return digitsOnlyString;
};

export const normalizePrice = (value: string) => {
    let digitsOnly = normalizeNumber(value);
    while (digitsOnly.startsWith('0')) {
        digitsOnly = digitsOnly.substring(1);
    }
    while (digitsOnly.length < 3) {
        digitsOnly = '0' + digitsOnly;
    }
    const formattedString =
        digitsOnly.substring(0, digitsOnly.length - 2) + '.' + digitsOnly.substring(digitsOnly.length - 2);
    return formattedString;
};

export const normalizeRoutingNumber = normalizeNumberWithLength(9);

export const normalizeLastFourSocial = normalizeNumberWithLength(4);

export const normalizePhoneNumber = (value: string) => {
    if (!value) return '';
    const digitsOnlyString = value.replace(/\D/g, '');
    let finalValue = digitsOnlyString.substring(0, 10);
    if (finalValue.length > 3) {
        finalValue = `${finalValue.substring(0, 3)}-${finalValue.substring(3)}`;
    }
    if (finalValue.length > 7) {
        finalValue = `${finalValue.substring(0, 7)}-${finalValue.substring(7)}`;
    }
    return finalValue;
};

export const normalizeSocialSecurityNumber = normalizeNumberWithLength(9);

export const normalizeSocialSecurityNumberDisplay = (value: string) => {
    if (!value) return '';
    const digitsOnlyString = value.replace(/\D/g, '');
    let finalValue = digitsOnlyString.substring(0, 9);
    if (finalValue.length > 3) {
        finalValue = `${finalValue.substring(0, 3)}-${finalValue.substring(3)}`;
    }
    if (finalValue.length > 6) {
        finalValue = `${finalValue.substring(0, 6)}-${finalValue.substring(6)}`;
    }

    return finalValue;
};

export const normalizeZipCode = normalizeNumberWithLength(5);

export const normalizeBankAccountNumber = normalizeNumberWithLength(20);

export const normalizeAgentCode = (value: string) => {
    if (!value) {
        return '';
    }
    if (value.length > 5) {
        return value.substring(0, 5);
    }
    return value;
};

export const normalizeMarketCode = (value: string) => {
    if (!value) {
        return '';
    }
    if (value.length > 2) {
        return value.substring(0, 2);
    }
    return value;
};
