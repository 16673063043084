import React from 'react';
import ButtonSubtitledLinkWrapper from '../../global-components/buttons/button-subtitled-link-component/ButtonSubtitledLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonSubtitledLinkGroupWrapper from '../../global-components/buttons/button-subtitled-link-component/button-subtitled-link-group-component/ButtonSubtitledLinkGroupWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class RecipientCheckPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setSameAsPurchaser: () => {},
        isSameAsPurchaser: false,
        clearRecipient: () => {},
        firstPage: false,
        planningForSelfNextUrl: undefined,
        planningForOtherNextUrl: undefined,
        dispositionText: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="RecipientCheck"
                metaPageNumber="1"
                metaPagebackAvailable="true"
                metaFirstPage={this.props.firstPage.toString()}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Who is the {this.props.dispositionText} for?</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Are you making these arrangements for yourself or a loved one?
                    </PageCardTextWrapper>
                    <ButtonSubtitledLinkGroupWrapper>
                        <ButtonSubtitledLinkWrapper
                            buttonId="myself"
                            to={this.props.planningForSelfNextUrl}
                            title="Myself"
                            subtitle=""
                            navigationSideEffect={() => this.props.setSameAsPurchaser(true)}
                        />
                        <ButtonSubtitledLinkWrapper
                            buttonId="loved-one"
                            to={this.props.planningForOtherNextUrl}
                            title="A loved one"
                            subtitle=""
                            navigationSideEffect={() => {
                                if (this.props.isSameAsPurchaser) {
                                    this.props.clearRecipient();
                                }
                                this.props.setSameAsPurchaser(false);
                            }}
                        />
                    </ButtonSubtitledLinkGroupWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setSameAsPurchaser: Function;
    isSameAsPurchaser: boolean;
    clearRecipient: Function;
    firstPage: boolean;
    planningForSelfNextUrl: urlPaths;
    planningForOtherNextUrl: urlPaths;
    dispositionText: string;
}

interface StateShape {
    // Shape of local state
}

export default RecipientCheckPageComponent;
