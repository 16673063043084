import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import ReduxRootReducer from './redux/root';
import { devToolsEnhancer } from 'redux-devtools-extension';
import AppWrapper from './components/global-components/app-component/AppWrapper';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import getEnvironment from './env-config/get-environment';
import smartlookClient from 'smartlook-client';
import queryStringParser from 'query-string';
import { logUrlTracking, runAPITest } from './toolboxes/page-enter-functions/pages/ALL';

const env = getEnvironment();
//Setup code
if (!window.location.origin.includes('localhost')) {
    Sentry.init({ dsn: env.sentryUrl });
}

if (env.googleTagManagerId) {
    const tagManagerArgs = {
        gtmId: env.googleTagManagerId
    };

    TagManager.initialize(tagManagerArgs);
}

const params = queryStringParser.parse(window.location.search);

logUrlTracking(params);

if (params?.testingQuery) {
    runAPITest();
}

if (env.smartlookKey) {
    smartlookClient.init(env.smartlookKey);
}

const store = createStore(ReduxRootReducer, {}, devToolsEnhancer({}));

if (!params?.testingQuery) {
    ReactDOM.render(
        <Provider store={store}>
            <AppWrapper />
        </Provider>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
