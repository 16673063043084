import { List, ListItem } from '@material-ui/core';
import React from 'react';

class NotificationsComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stopPageDetails: ''
    };

    render() {
        return (
            <div>
                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>Notifications</div>
                <List>
                    {this.props.stopPageDetails && (
                        <ListItem divider disableGutters>
                            {this.props.stopPageDetails}
                        </ListItem>
                    )}
                </List>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stopPageDetails?: string;
}

interface StateShape {
    // Shape of local state
}

export default NotificationsComponent;
