// Action Type
import { Disposition } from '../../static/constants/enums/disposition';

export const SET_OPPORTUNITY_DISPOSITION = 'SET_OPPORTUNITY_DISPOSITION';
// Action
export function setOpportunityDispositionAction(newValue: Disposition) {
    return { type: SET_OPPORTUNITY_DISPOSITION, newValue };
}
// Reducer
export function setOpportunityDispositionReducer(state: any, action: any): Disposition {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_OPPORTUNITY_DISPOSITION:
            return action.newValue;
        default:
            return state;
    }
}
