import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import RelationshipToRecipientFormWrapper from '../../form-components/relationship-to-recipient-form-component/RelationshipToRecipientFormWrapper';
import { Redirect } from 'react-router';

class RelationshipToRecipientPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        relationshipIsSelected: false,
        reachedStopPage: false,
        nextUrl: ''
    };

    render() {
        if (this.props.reachedStopPage) {
            return <Redirect to={urlPaths.recipientRelationshipNotListed} push />;
        }
        return (
            <FullContentCenterWrapper
                metaPageName="RelationshipToRecipient"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Relationship to Recipient</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        What is your relationship to the individual receiving these arrangements?
                    </PageCardTextWrapper>
                    <RelationshipToRecipientFormWrapper />

                    <ButtonLinkWrapper
                        disabled={!this.props.relationshipIsSelected}
                        to={this.props.nextUrl}
                        buttonId="Continue"
                    >
                        Continue
                    </ButtonLinkWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    relationshipIsSelected: boolean;
    reachedStopPage: boolean;
    nextUrl: string;
}

interface StateShape {
    // Shape of local state
}

export default RelationshipToRecipientPageComponent;
