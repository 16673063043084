import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';
import { calculateAge } from './age-calculator';

export function getInsuredBirthday(state: GlobalStateShape) {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return getFormData(state, 'purchaser', 'birthdate');
    }
    return getFormData(state, 'recipient', 'birthdate');
}

export function getInsuredAge(state: GlobalStateShape) {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return calculateAge(new Date(getFormData(state, 'purchaser', 'birthdate')));
    }
    return calculateAge(new Date(getFormData(state, 'recipient', 'birthdate')));
}
