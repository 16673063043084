import { connect } from 'react-redux';
import BackButtonLinkComponent from './BackButtonLinkComponent';
import EmptyComponent from 'components/global-components/empty-component/EmptyComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import withTheme from '@material-ui/styles/withTheme';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import EarthBackButtonLinkWrapper from 'components/account-specific-components/accounts/earth/back-button-link-component/EarthBackButtonLinkWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        textColor: state.stylingOptions.colors.bodyColor
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = withTheme(connect(mapStateToProps, mapDispatchToProps)(BackButtonLinkComponent));

export default accountComponentSwitch<typeof DefaultComponent>({
    [EVERDAYS_STYLING_KEY]: EmptyComponent,
    [EARTH_STYLING_KEY]: EarthBackButtonLinkWrapper,
    default: DefaultComponent
});
