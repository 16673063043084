import React from 'react';
import Radio from '@material-ui/core/Radio';
import './RadioDropdownComponent.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { colorDoesBlendWithBackground } from 'static/styling/colors-that-blend-with-background';
import { dataWrapper } from 'toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';

class RadioDropdownComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        applicationStyling: null
    };

    render() {
        const baseComponent = (
            <div className="radio-dropdown-container">
                <Radio
                    checked={this.props.isSelected}
                    className="radio-dropdown-radio-button"
                    onClick={() => this.props.onClick()}
                />
                <div className="radio-dropdown-main-content" onClick={() => this.props.onClick()}>
                    {this.props.mainContent}
                </div>
                <div className="radio-dropdown-selected-content">
                    {this.props.isSelected && this.props.selectedContent}
                </div>
            </div>
        );
        // If clicking the button would turn it white, use a different color for the secondary mui theme color
        if (
            colorDoesBlendWithBackground(
                this.props.applicationStyling,
                dataWrapper(() => this.props.applicationStyling.colors.secondaryColor)
            )
        ) {
            return (
                <ThemeProvider
                    theme={createMuiTheme({
                        palette: {
                            secondary: {
                                main: '#d3d3d3'
                            }
                        }
                    })}
                >
                    {baseComponent}
                </ThemeProvider>
            );
        }
        return baseComponent;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    onClick: Function;
    isSelected: boolean;
    mainContent: React.ReactNode;
    selectedContent: React.ReactNode;
    applicationStyling: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default RadioDropdownComponent;
