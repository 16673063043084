import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import SSNCaptureFormWrapper from '../../form-components/ssn-capture-form-component/SSNCaptureFormWrapper';
import React from 'react';
import ApplicationStyling from '../../../static/models/ApplicationStyling';
import MockLinkRedirectionWrapper from 'components/global-components/buttons/mock-link-redirection-component/MockLinkRedirectionWrapper';
import { Redirect } from 'react-router';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formComplete: false,
        stylingOptions: undefined,
        SSNToLast4Digits: false,
        showAlternativeLink: false,
        reachedStopPage: false,
        purchaserName: ''
    };

    render() {
        if (this.props.reachedStopPage) {
            return <Redirect to={urlPaths.unavailableIdentityFailed} push />;
        }
        return (
            <FullContentCenterWrapper metaPageName="SSNCapture" metaPageNumber="" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Purchaser Identity Verification</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        In order to help us verify your identity, {this.props.purchaserName}, please enter your Social
                        Security Number (SSN).
                    </PageCardTextWrapper>
                    <SSNCaptureFormWrapper />
                    <ButtonLinkWrapper
                        to={urlPaths.purchaserIdentity}
                        buttonId="submit"
                        disabled={!this.props.formComplete}
                    >
                        Submit
                    </ButtonLinkWrapper>
                    <div></div>
                    <small>
                        <MockLinkRedirectionWrapper
                            to={urlPaths.verifyPurchaserIdentity}
                            linkId="alternative identification form"
                            hidden={this.props.showAlternativeLink === false}
                        >
                            I would prefer to provide an alternate form of identification
                        </MockLinkRedirectionWrapper>
                    </small>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formComplete: boolean;
    stylingOptions: ApplicationStyling;
    SSNToLast4Digits: boolean;
    showAlternativeLink: boolean;
    reachedStopPage: boolean;
    purchaserName: string;
}

interface StateShape {
    // Shape of local state
}

export default TemplateComponent;
