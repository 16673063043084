export interface ProductCreationType {
    goodsAndServicesDisplay: string;
    goodsAndServicesValue: string;
    subcategory: string;
    category: string;
    // Setting both of these to true will make the subcategory appear regardless of state
    // This option will be shown if the account is based in texas
    texasApplicable: boolean;
    // This option will be shown if the account is not based in texas
    nonTexasApplicable: boolean;
}

// Creates a basic product creation subcategory with given values
function generateStandardProductCreationProductType(
    goodsAndServicesDisplay: string,
    category: string,
    subcategory: string,
    goodsAndServicesValue: string,
    texasApplicable: boolean,
    nonTexasApplicable: boolean
): ProductCreationType {
    return {
        goodsAndServicesDisplay,
        category,
        subcategory,
        goodsAndServicesValue,
        texasApplicable,
        nonTexasApplicable
    };
}

export const productCreationProductTypes: ProductCreationType[] = [
    generateStandardProductCreationProductType('Embalmbing', 'Professional Services', 'Staff', 'ps_embalm', true, true),
    generateStandardProductCreationProductType(
        'Cremation Fee',
        'Professional Services',
        'Staff',
        'ps_cremation',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Other Preparation',
        'Professional Services',
        'Staff',
        'ps_prep',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Other Preparation',
        'Professional Services',
        'Staff',
        'ps_prep_other',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Funeral Ceremony/Memorial Service',
        'Professional Services',
        'Facilities',
        'ps_ceremony',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Bathing of Body',
        'Professional Services',
        'Staff',
        'ps_bathing_body',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Cosmetic/Beautician',
        'Professional Services',
        'Staff',
        'ps_cosmetic_beautician',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Dressing/Casketing',
        'Professional Services',
        'Staff',
        'ps_dressing_casketing',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Refrigeration',
        'Professional Services',
        'Staff',
        'ps_refrigeration_fee',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Rosary or Prayer Service',
        'Professional Services',
        'Facilities',
        'ps_rosary_prayer_service',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Viewing or Visitation',
        'Professional Services',
        'Facilities',
        'ps_visitation',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Funeral Ceremony at Funeral Home',
        'Professional Services',
        'Facilities',
        'ps_facility',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Funeral Ceremony at other facility',
        'Professional Services',
        'Facilities',
        'ps_facilities_other',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Memorial Service at Funeral Home',
        'Professional Services',
        'Facilities',
        'ps_memorial',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Memorial Service at other facility',
        'Professional Services',
        'Facilities',
        'ps_memorial_other',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Use of equipment and staff for graveside service',
        'Professional Services',
        'Facilities',
        'ps_use_of_equipment_and_staff',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Other use of Facilities',
        'Professional Services',
        'Facilities',
        'ps_ceremony_other',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Transfer of Remains',
        'Transportation',
        'Transfer',
        'ps_transfer',
        true,
        true
    ),
    generateStandardProductCreationProductType('Family Car', 'Transportation', 'Transfer', 'ps_car_cost', false, true),
    generateStandardProductCreationProductType('Hearse', 'Transportation', 'Procession', 'ps_hearse', true, true),
    generateStandardProductCreationProductType('Funeral Sedan', 'Transportation', 'Transfer', 'ps_sedan', true, false),
    generateStandardProductCreationProductType('Limo', 'Transportation', 'Procession', 'ps_limo', true, true),
    generateStandardProductCreationProductType(
        'Pallbearer Car',
        'Transportation',
        'Procession',
        'ps_pallbearer_car',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Clergy Car',
        'Transportation',
        'Procession',
        'ps_clergy_car',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Flower Car',
        'Transportation',
        'Transfer',
        'ps_flower_car',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Other Transportation',
        'Transportation',
        'Transfer',
        'ps_car',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Immediate Burial',
        'Professional Services',
        'Staff',
        'ps_immediate_burial_basic_charge',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Direct Cremation',
        'Professional Services',
        'Staff',
        'ps_direct_cremation_basic_charge',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Forwarding/Receiving of Remains',
        'Professional Services',
        'Staff',
        'ps_forward',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Forwarding of Remains',
        'Professional Services',
        'Staff',
        'ps_forwarding_remains',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Receiving of Remains',
        'Professional Services',
        'Staff',
        'ps_receiving_remains',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Other Services',
        'Professional Services',
        'Staff',
        'ps_other_service2',
        true,
        true
    ),
    generateStandardProductCreationProductType('Casket', 'Merchandise', 'Casket', 'm_casket_cost', true, true),
    // This item was provided in the list of product subcategories, but is never used, and has no goods and services
    generateStandardProductCreationProductType('Alternative Container', 'Merchandise', 'Casket', 'NA', false, false),
    generateStandardProductCreationProductType(
        'Vault (Outer Burial Container)',
        'Merchandise',
        'Outer Burial Container',
        'm_vault_cost',
        true,
        true
    ),
    generateStandardProductCreationProductType('Urn', 'Merchandise', 'Urn', 'm_urn', true, true),
    // This item was provided in the list of product subcategories, but is never used, and has no goods and services, and no standard subcategory
    generateStandardProductCreationProductType('Memorial', 'Additional Items', 'NA', 'NA', false, false),
    // This item was provided in the list of product subcategories, but is never used, and has no goods and services, and no standard subcategory
    generateStandardProductCreationProductType('Memorial Base', 'Additional Items', 'NA', 'NA', false, false),
    generateStandardProductCreationProductType(
        'Shipping Container',
        'Additional Items',
        'Personalizations',
        'ca_shipping_container',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Clothing',
        'Additional Items',
        'Personalizations',
        'ca_clothing',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Stationery/Cards',
        'Additional Items',
        'Personalizations',
        'ca_stationery_cards',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Memorial Book',
        'Additional Items',
        'Personalizations',
        'ca_memorial_book',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Acknowledgement cards',
        'Additional Items',
        'Personalizations',
        'ca_acknowledgement_cards',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Other Merchandise',
        'Additional Items',
        'Personalizations',
        'm_other_merch',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Grave Opening and Closing',
        'Additional Items',
        'Personalizations',
        'ca_grave_open',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Cemetery Opening & Closing Fee',
        'Additional Items',
        'Personalizations',
        'ca_cemetery_opening_and_closing_fee',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Cemetery Set-up',
        'Additional Items',
        'Personalizations',
        'ca_cemetery_set_up',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Crematory Fees',
        'Additional Items',
        'Personalizations',
        'ca_crematory_charges',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Clergy Honorarium',
        'Additional Items',
        'Personalizations',
        'ca_honorariums',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Death Certificates',
        'Additional Items',
        'Personalizations',
        'ca_death_cert',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Flowers',
        'Additional Items',
        'Personalizations',
        'ca_flowers',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Obituary Notice',
        'Additional Items',
        'Personalizations',
        'ca_obituaries',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Organist/Pianist',
        'Additional Items',
        'Personalizations',
        'ca_organist',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Outside Facility Rental',
        'Additional Items',
        'Personalizations',
        'ca_outside_facility_rental',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Escort',
        'Additional Items',
        'Personalizations',
        'ca_escort',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Transportation',
        'Additional Items',
        'Personalizations',
        'ca_transportation',
        true,
        false
    ),
    generateStandardProductCreationProductType(
        'Vocalist',
        'Additional Items',
        'Personalizations',
        'ca_vocalist',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Other Cash Advance',
        'Additional Items',
        'Personalizations',
        'ca_other1',
        true,
        true
    ),
    generateStandardProductCreationProductType(
        'Hairdresser',
        'Additional Items',
        'Personalizations',
        'ca_hairdresser',
        false,
        true
    ),
    generateStandardProductCreationProductType(
        'Discount/Adjustment',
        'Additional Items',
        'Personalizations',
        'preneed_adjustment',
        true,
        true
    )
];
