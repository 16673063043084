import { connect } from 'react-redux';
import EverdaysFuneralContractsPageComponent from './EverdaysFuneralContractsPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        contractsUrl: state.opportunity.contractSigning.contractUrl,
        funeralHomeName: state.account.name
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EverdaysFuneralContractsPageComponent);
