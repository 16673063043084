import { formValueSelector, getFormMeta, getFormValues, getFormError, getFormSyncErrors } from 'redux-form';
import GlobalStateShape from '../../redux/GlobalStateShape';

export function getFormData(state: GlobalStateShape, formName: string, fieldPath: string) {
    const valueSelector = formValueSelector(formName);
    return valueSelector(state, fieldPath);
}

export function getFormMetaData(state: GlobalStateShape, formName: string) {
    return getFormMeta(formName)(state);
}

export function getFormErrorData(state: GlobalStateShape, formName: string) {
    return getFormError(formName)(state);
}

export function getFormSyncErrorsData(state: GlobalStateShape, formName: string) {
    return getFormSyncErrors(formName)(state);
}

export function getFullFormData(state: GlobalStateShape, formName: string) {
    return getFormValues(formName)(state);
}
