import {
    sendRequest,
    baseUrl,
    defaultPostConfiguration,
    defaultGetConfiguration,
    sendRequests
} from '../web-request-tools';
import axios from 'axios';
import getEnvironment from '../../../env-config/get-environment';
import { getRequestForGlobalFeatureFlags } from './feature-flags';

export function fetchOpportunityData(
    opportunityId,
    verificationData: any,
    callback: (result: any, error: any) => void
) {
    sendRequests(
        [
            {
                promise: axios.post(
                    baseUrl + `/api/loadOpportunity/${opportunityId}`,
                    { ...verificationData, opportunityId },
                    defaultPostConfiguration
                ),
                key: 'allLoadedData',
                ignoreErrors: false
            },
            {
                promise: getRequestForGlobalFeatureFlags(),
                key: 'featureFlags',
                ignoreErrors: true
            }
        ],
        (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            const allLoadedData = result.allLoadedData;
            return sendRequest(
                axios.get(
                    `${getEnvironment().efuneralMediaBucket}/custom-styles/${allLoadedData.opportunityData
                        .referralSubdomain ||
                        allLoadedData.opportunityData.funeralHomeSubdomain}-v2.css?${new Date().getTime()}`
                ),
                (stylingData, error) => {
                    let newStylingData = undefined;
                    if (!error) {
                        newStylingData = stylingData;
                    }
                    // The old method of doing this data fetching grabbed the opportunity data, then added the rest of the data to it
                    // So here, we're mimicking that data shape
                    // We grab the opportunity data, then add the rest of the loaded data to it, adding feature flags and styling in as well
                    let resultingData = allLoadedData.opportunityData;
                    resultingData = {
                        ...resultingData,
                        ...allLoadedData,
                        featureFlags: result.featureFlags,
                        opportunityData: undefined,
                        stylingData: newStylingData
                    };
                    if (!resultingData.opportunityId) {
                        resultingData.stageName = allLoadedData.opportunityData.stageName;
                        resultingData.referralSubdomain = allLoadedData.opportunityData.referralSubdomain;
                    }
                    return callback(resultingData, undefined);
                },
                false
            );
        }
    );
}

// see if access code is associated with any opportunities
export function fetchOpportunityIdByAccessCode(accessCode: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/AccessCode/code/${accessCode}`, defaultGetConfiguration), callback);
}

export function createAccessCode(opportunityId: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/AccessCode/${opportunityId}`, defaultGetConfiguration), callback);
}

export function fetchFullOpportunity(opportunityId, opportunityKey, callback: (result: any, error: any) => void) {
    sendRequests(
        [
            {
                promise: axios.get(
                    baseUrl + `/api/loadOpportunity/${opportunityId}/${opportunityKey}`,
                    defaultGetConfiguration
                ),
                key: 'allLoadedData',
                ignoreErrors: false
            },
            {
                promise: getRequestForGlobalFeatureFlags(),
                key: 'featureFlags',
                ignoreErrors: true
            }
        ],
        (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            const allLoadedData = result.allLoadedData;
            return sendRequest(
                axios.get(
                    `${getEnvironment().efuneralMediaBucket}/custom-styles/${allLoadedData.opportunityData
                        .referralSubdomain ||
                        allLoadedData.opportunityData.funeralHomeSubdomain}-v2.css?${new Date().getTime()}`
                ),
                (stylingData, error) => {
                    let newStylingData = undefined;
                    if (!error) {
                        newStylingData = stylingData;
                    }
                    // The old method of doing this data fetching grabbed the opportunity data, then added the rest of the data to it
                    // So here, we're mimicking that data shape
                    // We grab the opportunity data, then add the rest of the loaded data to it, adding feature flags and styling in as well
                    let resultingData = allLoadedData.opportunityData;
                    resultingData = {
                        ...resultingData,
                        ...allLoadedData,
                        featureFlags: result.featureFlags,
                        opportunityData: undefined,
                        stylingData: newStylingData
                    };
                    if (!resultingData.opportunityId) {
                        resultingData.accountData = {
                            subdomain: allLoadedData.opportunityData.funeralHomeSubdomain
                        };
                        resultingData.stageName = allLoadedData.opportunityData.stageName;
                        resultingData.referralSubdomain = allLoadedData.opportunityData.referralSubdomain;
                    }
                    return callback(resultingData, undefined);
                },
                false
            );
        }
    );
}
