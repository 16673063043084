import React from 'react';
import PageCardWrapper from '../../page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../page-card-components/page-card-text-component/PageCardTextWrapper';
import YesNoWrapper from '../../buttons/yes-no-component/YesNoWrapper';

class PastHealthComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        customizableText: {
            personIndicationText: 'has the recipient',
            personPronounText: 'are they',
            personPronounCapitalized: 'They'
        },
        setWasBedriddenOrAiledAction: () => {}
    };

    render() {
        return (
            <PageCardWrapper>
                <PageCardTitleWrapper>Health and History Questionnaire</PageCardTitleWrapper>
                <PageCardTextWrapper>
                    Within the past five years, {this.props.customizableText.personIndicationText} been diagnosed for,
                    been treated, or {this.props.customizableText.personPronounText} currently receiving treatment by a
                    licensed medical physician for any of the following ailments?
                </PageCardTextWrapper>
                <div className="ailments-container">
                    <div className="ailments-list">
                        <ul>
                            <li>Alcohol abuse</li>
                            <li>Anemia</li>
                            <li>Cancer</li>
                            <li>Circulatory disease</li>
                            <li>Dementia/Alzheimer's</li>
                            <li>Diabetes</li>
                            <li>Drug Abuse</li>
                        </ul>
                    </div>
                    <div className="ailments-list">
                        <ul>
                            <li>Heart disease</li>
                            <li>Kidney disease</li>
                            <li>Liver disease</li>
                            <li>Lung disease</li>
                            <li>Nervous Disorder</li>
                            <li>Stroke</li>
                        </ul>
                    </div>
                </div>
                <YesNoWrapper
                    onYesClick={() => this.props.setWasBedriddenOrAiledAction(true)}
                    onNoClick={() => this.props.setWasBedriddenOrAiledAction(false)}
                    yesNoId="past-health"
                    answer={null}
                />
            </PageCardWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    customizableText: {
        personIndicationText: string;
        personPronounText: string;
        personPronounCapitalized: string;
    };
    setWasBedriddenOrAiledAction: Function;
}

interface StateShape {
    // Shape of local state
}

export default PastHealthComponent;
