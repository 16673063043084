import GlobalStateShape from '../../redux/GlobalStateShape';
import noAnnuityStates from '../../static/constants/stateSpecificLists/noAnnuityStates';
import { runSubmitDisclaimer } from '../web-requests/specific-requests/disclaimer-requests';
import { translateBooleanIntoYesNoString } from './generalized-data-buffer-tools/yes-no-translator';
import DisclaimerAddressing from '../../static/models/disclaimers/DisclaimerAddressing';
import { getFormData } from '../redux-form/redux-form-data-access';
import { calculateAge } from '../reuseable-logic/age-calculator';
import { runUpdateOpportunity } from '../web-requests/specific-requests/opportunity-update-requests';

function sendSaveDisclaimer(
    disclaimerAddressing: DisclaimerAddressing,
    opportunityId: string,
    topic: string,
    callback: (result: any, error: any) => void
) {
    const disclaimerData = {
        accepted: translateBooleanIntoYesNoString(disclaimerAddressing.hasBeenAcceptedOrPositivelyAddressed),
        contactId: disclaimerAddressing.addressedByContactId,
        opportunityId: opportunityId,
        text: disclaimerAddressing.disclaimerText,
        topic,
        userDecision: disclaimerAddressing.responseToDisclaimer
    };
    runSubmitDisclaimer(disclaimerData, opportunityId, callback);
}

export function sendSaveSinglePaySettingDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.singlePaySetting,
        state.opportunity.id,
        'Incapacitated Rates',
        callback
    );
}

export function sendSaveRecipientUnavailableRatesDisclaimer(
    state: GlobalStateShape,
    callback: (Result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.recipientUnavailableRates,
        state.opportunity.id,
        'Incapacitated Rates',
        callback
    );
}

export function sendSaveHealthHistoryDisclaimer(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.paymentPlanHealthImpact,
        state.opportunity.id,
        'Payment Plan',
        callback
    );
}

export function sendSaveIrrevocabilityDisclaimer(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.irrevocabilityConfirmation,
        state.opportunity.id,
        'Irrevocable',
        callback
    );
}

export function sendSaveRecipientConsentDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.recipientConsent,
        state.opportunity.id,
        'Insured Consent',
        callback
    );
}

export function sendSaveAnnuityInsuranceDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.annuityInsurance,
        state.opportunity.id,
        noAnnuityStates.includes(getFormData(state, 'purchaser', 'state')) ? 'Insurance' : 'Annuity',
        callback
    );
}

export function sendCalculatedAgesToOpportunity(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const recipientBirthdate = new Date(getFormData(state, 'recipient', 'birthdate'));
    const purchaserBirthdate = new Date(getFormData(state, 'purchaser', 'birthdate'));
    const recipientAge = calculateAge(recipientBirthdate);
    const purchaserAge = calculateAge(purchaserBirthdate);

    runUpdateOpportunity(
        state.opportunity.id,
        state.account.id,
        {
            recipientAge,
            purchaserAge
        },
        callback
    );
}

export function sendSaveStateFilingFeeDisclaimer(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.stateFilingFee,
        state.opportunity.id,
        'State Filing Fee',
        callback
    );
}

export function sendSavePriceListDisclaimer(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.priceList,
        state.opportunity.id,
        'Price List',
        callback
    );
}

export function sendSaveCPRADisclosure(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.priceList,
        state.opportunity.id,
        'CPRA Disclosure',
        callback
    );
}

export function sendSaveAlternativePurchaserIdentityDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.verifyPurchaserIdentity,
        state.opportunity.id,
        'Verify Purchaser Identity',
        callback
    );
}

export function sendSaveTrustFundedPrearrangementDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.trustFundedPrearrangement,
        state.opportunity.id,
        'Trust Funded Prearrangement',
        callback
    );
}

export function sendSaveRecipientIdentityPlaidConsentDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.recipientPlaidIdentityConsent,
        state.opportunity.id,
        'Consent to Share Data with Plaid',
        callback
    );
}

export function sendSavePurchaserIdentityPlaidConsentDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.purchaserPlaidIdentityConsent,
        state.opportunity.id,
        'Consent to Share Data with Plaid',
        callback
    );
}

export function sendSavePurchaserRecipientSamePersonDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.purchaserRecipientSamePerson,
        state.opportunity.id,
        'Purchaser and Recipient Similar',
        callback
    );
}

export function sendSaveBeneficiaryRecipientSamePersonDisclaimer(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.beneficiaryRecipientSamePerson,
        state.opportunity.id,
        'Beneficiary and Recipient Similar',
        callback
    );
}

export function sendSavePaymentHoldDisclaimer(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveDisclaimer(
        state.disclaimerData.disclaimerAddressings.paymentHold,
        state.opportunity.id,
        'Payment Hold',
        callback
    );
}
