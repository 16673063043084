import Product from '../../static/models/Product';
import { addProductToList, removeProductFromList } from '../../toolboxes/reuseable-logic/product-combiner';

// Action Type
export const SET_OPPORTUNITY_PRODUCTS = 'SET_OPPORTUNITY_PRODUCTS';
export const ADD_OPPORTUNITY_PRODUCT = 'ADD_OPPORTUNITY_PRODUCT';
export const REMOVE_OPPORTUNITY_PRODUCT = 'REMOVE_OPPORTUNITY_PRODUCT';
// Action
export function setOpportunityProductsAction(newValue: Product[]) {
    return { type: SET_OPPORTUNITY_PRODUCTS, newValue };
}

export function addOpportunityProductAction(newValue: Product) {
    return { type: ADD_OPPORTUNITY_PRODUCT, newValue };
}

export function removeOpportunityProductAction(newValue: Product) {
    return { type: REMOVE_OPPORTUNITY_PRODUCT, newValue };
}

// Reducer
export function setOpportunityProductsReducer(state: any, action: any): Product[] {
    if (typeof state === 'undefined') {
        // Initial data
        return [];
    }
    switch (action.type) {
        case SET_OPPORTUNITY_PRODUCTS:
            return action.newValue;
        case ADD_OPPORTUNITY_PRODUCT:
            return addProductToList(state, action.newValue);
        case REMOVE_OPPORTUNITY_PRODUCT:
            return removeProductFromList(state, action.newValue);
        default:
            return state;
    }
}

const initialState: Product = {
    name: '',
    productId: '',
    price: 0,
    category: '',
    subcategory: '',
    taxIsApplicable: false,
    explicitTax: 0,
    priceIsGuaranteed: false,
    quantity: 0,
    manufacturer: '',
    modelNumber: '',
    salesDescription: '',
    included: false
};

export { initialState as initialProduct };
