import { connect } from 'react-redux';
import EverdaysPaymentPageComponent from './EverdaysPaymentPageComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { PaymentMethod } from '../../../../../static/constants/enums/paymentMethods';
import {
    calculateIncapacitatedDisclaimerNecessary,
    calculateHealthQuestionsNecessary
} from '../../../../../toolboxes/reuseable-logic/incapacitated-calculator';
import { getSinglePayAlternative } from '../../../../../toolboxes/reuseable-logic/payment-options-calculator';
import { setSelectedPaymentOptionAction } from '../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const paymentMethodsAvailable: PaymentMethod[] = [];

    let defaultPaymentMethod = state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod;

    state.opportunity.pricing.paymentOptions.availableOptions.forEach(option => {
        if (!paymentMethodsAvailable.includes(option.paymentMethod)) {
            paymentMethodsAvailable.push(option.paymentMethod);
        }
    });
    return {
        paymentMethodsAvailable,
        startingError: state.opportunity.pricing.creditCardInfo.cardRejected
            ? 'Card did not sucessfully process. Please retry, or use another card.'
            : '',
        incapacitatedDisclaimerNecessary: calculateIncapacitatedDisclaimerNecessary(state),
        singlePayAlternative: getSinglePayAlternative(state),
        healthQuestionsNecessary: calculateHealthQuestionsNecessary(state),
        isBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled,
        wasBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears,
        isAgent: !!representativeId,
        defaultPaymentMethod: defaultPaymentMethod,
        selectedOption: state.opportunity.pricing.paymentOptions.selectedOption,
        allPaymentOptions: state.opportunity.pricing.paymentOptions.availableOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EverdaysPaymentPageComponent);
