import { connect } from 'react-redux';
import DispositionSelectionComponent from './DispositionSelectionComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setOpportunityDispositionAction } from '../../../../redux/opportunity/disposition';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        selectedDisposition: state.opportunity.disposition
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setOpportunityDispositionAction: newValue => dispatch(setOpportunityDispositionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DispositionSelectionComponent);
