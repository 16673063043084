import { connect } from 'react-redux';
import ProductSelectionPageComponent from './ProductSelectionPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { setFuneralHomeProductsAction } from '../../../redux/availableProducts/funeralHomeProducts';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const canAddProducts = state.featureFlags.useSalesForceAgents ? state.account.agent.canAddProducts : true;
    const representativeId = getFormData(state, 'representative', 'id');

    return {
        selectedFuneralProducts: state.opportunity.products,
        isAgentDriven: !!representativeId,
        homesteadersAgentNumber: getFormData(state, 'representative', 'id'),
        accountId: state.account.id,
        agentId: state.account.agent.id,
        useSalesForceAgents: state.featureFlags.useSalesForceAgents,
        canAddProducts
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setFuneralHomeProducts: newValue => dispatch(setFuneralHomeProductsAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectionPageComponent);
