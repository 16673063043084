import { connect } from 'react-redux';
import TimingSelectionComponent from './TimingSelectionComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setOpportunityTimingAction } from '../../../../redux/opportunity/timing';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        selectedTiming: state.opportunity.timing
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setOpportunityTimingAction: newValue => dispatch(setOpportunityTimingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimingSelectionComponent);
