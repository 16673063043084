import { demoEnvironment } from 'env-config/environment-configs/demo';
import getEnvironment from 'env-config/get-environment';
import { TULIP_STYLING_KEY } from 'static/constants/styling-company-constants/companies/tulip';

export const calcuateHelloSignClientKey = (
    hellosignKey: string,
    stylingKey: string,
    forceDefaultHelloSignClientId: boolean
) => {
    if (getEnvironment() !== demoEnvironment && !forceDefaultHelloSignClientId) {
        if (hellosignKey) {
            return hellosignKey;
        }
        if (stylingKey === TULIP_STYLING_KEY) {
            return getEnvironment().tulipHelloSignKey;
        }
    }
    return getEnvironment().generalHellosignKey;
};
