// Action Type
export const SET_INSURED_AVAILABILITY_ADDRESSED = 'SET_INSURED_AVAILABILITY_ADDRESSED';
// Action
export function setInsuredAvailabilityAddressedAction(newValue: boolean) {
    return { type: SET_INSURED_AVAILABILITY_ADDRESSED, newValue };
}
// Reducer
export function setInsuredAvailabilityAddressedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_INSURED_AVAILABILITY_ADDRESSED:
            return action.newValue;
        default:
            return state;
    }
}
