import FullContentCenterComponent from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterComponent';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React from 'react';

class SecureSignCompletePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterComponent metaPageName="" metaPageNumber="-1" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Thank you for signing.</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        If additional signatures are needed, the {`individual(s)`} have been notified. Once all
                        signatures are captured, you will receive a copy of the contract via email.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterComponent>
        );
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    policyContractLanguage: string;
}

interface StateShape {
    // Shape of local state
}

export default SecureSignCompletePageComponent;
