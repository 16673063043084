import { connect } from 'react-redux';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setTrustFundedPrearrangementAction } from 'redux/disclaimerData/disclaimerAddressings/trustFundedPrearrangement';
import TrustFundedPrearrangementPageComponent from './TrustFundedPrearrangementPageComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        fundingProvider: state.opportunity.selectedFundingProduct?.provider
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setTrustFundedPrearrangementAction: newValue => dispatch(setTrustFundedPrearrangementAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrustFundedPrearrangementPageComponent);
