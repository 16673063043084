import { sendRequest, baseUrl, defaultPostConfiguration } from '../web-request-tools';
import axios from 'axios';

export function runSubmitDisclaimer(
    disclaimerData: any,
    opportunityId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/disclaimer/${opportunityId}`, disclaimerData, defaultPostConfiguration),
        callback
    );
}
