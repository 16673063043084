export function dataWrapper(wrappedValueFunction: Function, defaultValue: any = null) {
    try {
        const value = wrappedValueFunction();
        if (typeof value === 'undefined') {
            return defaultValue;
        }
        return value;
    } catch {
        return defaultValue;
    }
}
