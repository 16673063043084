import React from 'react';
import { Field } from 'redux-form';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import ConfirmationInputWrapper from '../../../global-components/specialty-input-components/confirmation-input-component/ConfirmationInputWrapper';

const renderField = (reduxFieldData: any) => {
    return (
        <ConfirmationInputWrapper
            {...reduxFieldData.input}
            {...reduxFieldData.extraFieldProps}
            placeholder={reduxFieldData.placeholder}
            confirmationPlaceholder={reduxFieldData.confirmationPlaceholder}
            type={reduxFieldData.type}
            id={reduxFieldData.id}
            labelString={reduxFieldData.labelString}
            spacing={reduxFieldData.spacing}
            extraFieldProps={reduxFieldData.extraFieldProps}
            validationFunction={reduxFieldData.validationFunction}
            fullWidth={true}
            normalize={reduxFieldData.normalizerPassthrough}
        />
    );
};

class ConfirmationInputFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        placeholder: '',
        confirmationPlaceholder: '',
        formName: '',
        spacing: defaultSpacing,
        labelString: '',
        normalize: null,
        validationFunction: null
    };

    render() {
        return (
            <Field
                id={`${this.props.formName}.${this.props.fieldPath}`}
                labelString={this.props.labelString}
                name={this.props.fieldPath}
                placeholder={this.props.placeholder}
                confirmationPlaceholder={this.props.confirmationPlaceholder}
                component={renderField}
                type="text"
                extraFieldProps={this.props.extraFieldProps}
                {...this.props.extraFieldProps}
                normalize={this.props.normalize}
                normalizerPassthrough={this.props.normalize}
                validationFunction={this.props.validationFunction}
                spacing={this.props.spacing}
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    placeholder: string;
    confirmationPlaceholder: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
    normalize: Function;
    validationFunction: Function;
}

interface StateShape {
    // Shape of local state
}

export default ConfirmationInputFormComponent;
