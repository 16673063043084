import React from 'react';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';

class RepresentativeIdentityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        opportunityId: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="RepresentativeIdentity"
                metaPageNumber="4"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Identity Questions</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        The next step in the process is identity verification, and contract signing. Because a funeral
                        home representative cannot do this for the customer, the customer must navigate to this portion.
                        Click below to send the customer an email with the link.
                    </PageCardTextWrapper>
                    <ButtonLinkWrapper buttonId="send-email" to={urlPaths.sendIdentityLinkEmail}>
                        Send Email
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    opportunityId: string;
}

interface StateShape {
    // Shape of local state
}

export default RepresentativeIdentityPageComponent;
