import React from 'react';
import PageCardWrapper from '../../page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../page-card-components/page-card-text-component/PageCardTextWrapper';
import YesNoWrapper from '../../buttons/yes-no-component/YesNoWrapper';

class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        customizableText: {
            personIndicationText: 'Is the recipient',
            personPronounText: 'have they'
        },
        setIsBedriddenOrAiledAction: () => {}
    };

    render() {
        return (
            <PageCardWrapper>
                <PageCardTitleWrapper>Health and History Questionnaire</PageCardTitleWrapper>
                <PageCardTextWrapper>
                    {this.props.customizableText.personIndicationText} bedridden or currently admitted to being advised
                    to enter a public hospital, nursing home, hospice program, or any extended care facility; or{' '}
                    {this.props.customizableText.personPronounText} been diagnosed or being treated by a licensed
                    medical physician for AIDS or ARC?
                </PageCardTextWrapper>
                <YesNoWrapper
                    onYesClick={() => this.props.setIsBedriddenOrAiledAction(true)}
                    onNoClick={() => this.props.setIsBedriddenOrAiledAction(false)}
                    yesNoId="current-health"
                    answer={null}
                />
            </PageCardWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    customizableText: {
        personIndicationText: string;
        personPronounText: string;
    };
    setIsBedriddenOrAiledAction: Function;
}

interface StateShape {
    // Shape of local state
}

export default TemplateComponent;
