import React from 'react';
import RadioDescriptionWrapper from '../../buttons/radio-description-component/RadioDescriptionWrapper';

class SelfOrOtherSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            touched: false
        };
    }

    static defaultProps = {
        // Default prop values
        planningForSelf: null,
        setOpportunityRecipientSameAsPurchaserAction: () => {},
        touched: false
    };

    render() {
        return (
            <div
                style={{
                    marginBottom: '10px'
                }}
            >
                <RadioDescriptionWrapper
                    onClick={() => {
                        this.props.setOpportunityRecipientSameAsPurchaserAction(true);
                        this.setState({
                            touched: true
                        });
                    }}
                    isSelected={this.props.planningForSelf === true}
                    mainContent="Themselves"
                    descriptionContent="The customer will be purchasing for themselves"
                    showError={
                        (this.state.touched || this.props.touched) &&
                        ![true, false].includes(this.props.planningForSelf)
                    }
                    id="self"
                />
                <RadioDescriptionWrapper
                    onClick={() => {
                        this.props.setOpportunityRecipientSameAsPurchaserAction(false);
                        this.setState({
                            touched: true
                        });
                    }}
                    isSelected={this.props.planningForSelf === false}
                    mainContent="A Loved One"
                    descriptionContent="The customer will be purchasing for another"
                    id="other"
                />
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    planningForSelf: boolean;
    setOpportunityRecipientSameAsPurchaserAction: Function;
    touched: boolean;
}

interface StateShape {
    // Shape of local state
    touched: boolean;
}

export default SelfOrOtherSelectionComponent;
