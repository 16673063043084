import React from 'react';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import { formatRemoveNonNumeric } from '../../../../toolboxes/reuseable-logic/string-formatters';

class IdentityFailedPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        yesToAlternitiveID: false
    };

    render() {
        if (this.props.yesToAlternitiveID) {
            return (
                <FullContentCenterWrapper
                    metaPageName="IdentityFailed"
                    metaPageNumber="4"
                    metaPagebackAvailable="false"
                >
                    <PageCardWrapper>
                        <PageCardTitleWrapper>Alternative Purchaser Identity Verification</PageCardTitleWrapper>
                        <PageCardTextWrapper>
                            In order to help us verify your identity, please send a photo of your valid
                            government-issued ID (Driver's License preferred) via:
                        </PageCardTextWrapper>
                        <PageCardTextWrapper>Email: help@efuneral.com</PageCardTextWrapper>
                    </PageCardWrapper>
                </FullContentCenterWrapper>
            );
        } else {
            return (
                <FullContentCenterWrapper
                    metaPageName="IdentityFailed"
                    metaPageNumber="4"
                    metaPagebackAvailable="false"
                >
                    <PageCardWrapper>
                        <PageCardTitleWrapper>Identity Verification Failed</PageCardTitleWrapper>
                        <PageCardTextWrapper>
                            Unfortunately, we were unable to verify your identity with the information provided. Someone
                            from eFuneral will be reaching out to help you through this process. In order to proceed, we
                            will need a copy of a Government Issued ID. To expedite this process, please email&nbsp;
                            <a href={'mailto:help@efuneral.com'}>help@efuneral.com</a>.
                        </PageCardTextWrapper>
                    </PageCardWrapper>
                </FullContentCenterWrapper>
            );
        }
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    yesToAlternitiveID: boolean;
}

interface StateShape {
    // Shape of local state
}

export default IdentityFailedPageComponent;
