// Action Type
export const SET_CARD_LAST_FOUR = 'SET_CARD_LAST_FOUR';
// Action
export function setCardLastFourAction(newValue: string) {
    return { type: SET_CARD_LAST_FOUR, newValue };
}
// Reducer
export function setCardLastFourReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_CARD_LAST_FOUR:
            return action.newValue;
        default:
            return state;
    }
}
