import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Fragment, useState } from 'react';
import { Field } from 'redux-form';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { browserSupportsDatePickers } from 'toolboxes/reuseable-logic/browser-detector';
import {
    formatDateValuesForDisplay,
    formatDateValuesForState,
    getValuesFromStringDate
} from 'toolboxes/reuseable-logic/date-form-formatter';
import InfoPopupComponent from '../info-popup-component/InfoPopupComponent';

const renderDatePickerField = (reduxFieldData: any) => {
    const [isFocused, setIsFocused] = useState(false);
    let shouldShowError = reduxFieldData.meta.touched && !reduxFieldData.meta.valid && !reduxFieldData.meta.active;
    const brokenDownDatePieces = getValuesFromStringDate(reduxFieldData.input.value);
    let displayValue = formatDateValuesForDisplay(brokenDownDatePieces);
    let cleanedFocusedValue = reduxFieldData.input.value;

    let endAdornment = null;
    if (reduxFieldData.extraFieldProps?.disabledreason) {
        endAdornment = (
            <>
                <InputAdornment position="end">
                    <InfoPopupComponent
                        infoModalTitle="Why can I not edit this?"
                        infoModalContent={reduxFieldData.extraFieldProps?.disabledreason}
                    />
                </InputAdornment>
            </>
        );
    }
    return (
        <Fragment>
            <TextField
                placeholder={reduxFieldData.placeholder}
                type={isFocused ? 'date' : ''}
                {...reduxFieldData.input}
                {...reduxFieldData.extraFieldProps}
                onFocus={event => {
                    setIsFocused(true);
                    reduxFieldData.input.onFocus(event);
                }}
                onBlur={event => {
                    setIsFocused(false);
                    reduxFieldData.input.onBlur(event);
                }}
                error={shouldShowError}
                value={isFocused ? cleanedFocusedValue : displayValue}
                id={`input-${reduxFieldData.id}`}
                fullWidth={true}
                inputProps={{
                    ...reduxFieldData.extraFieldProps,
                    'aria-label': `${reduxFieldData.ariaLabel}`,
                    style: { color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor }
                }}
                style={{
                    backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                    color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor,
                    marginBottom: reduxFieldData.stylingOptions?.spacing?.innerBody
                }}
                InputProps={{ endAdornment }}
            />
            {shouldShowError && <Alert severity="error">Invalid date</Alert>}
        </Fragment>
    );
};

const renderTextDateField = (reduxFieldData: any) => {
    const [isFocused, setIsFocused] = useState(false);
    let shouldShowError = reduxFieldData.meta.touched && !reduxFieldData.meta.valid && !reduxFieldData.meta.active;
    const brokenDownDatePieces = getValuesFromStringDate(reduxFieldData.input.value);
    let displayValue = formatDateValuesForDisplay(brokenDownDatePieces);

    let endAdornment = null;
    if (reduxFieldData.extraFieldProps.disabledreason) {
        endAdornment = (
            <>
                <InputAdornment position="end">
                    <InfoPopupComponent
                        infoModalTitle="Why can I not edit this?"
                        infoModalContent={reduxFieldData.extraFieldProps?.disabledreason}
                    />
                </InputAdornment>
            </>
        );
    }
    return (
        <Fragment>
            <TextField
                placeholder={isFocused ? 'MM/DD/YYYY' : reduxFieldData.placeholder}
                {...reduxFieldData.input}
                {...reduxFieldData.extraFieldProps}
                onFocus={event => {
                    setIsFocused(true);
                    reduxFieldData.input.onFocus(event);
                }}
                onBlur={() => {
                    setIsFocused(false);
                    reduxFieldData.input.onBlur(reduxFieldData.input.value);
                }}
                error={shouldShowError}
                value={displayValue}
                id={`input-${reduxFieldData.id}`}
                fullWidth={true}
                inputProps={{
                    ...reduxFieldData.extraFieldProps,
                    'aria-label': `${reduxFieldData.ariaLabel}`,
                    style: { color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor }
                }}
                style={{
                    backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                    color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor,
                    marginBottom: reduxFieldData.stylingOptions?.spacing?.innerBody
                }}
                onChange={evt => {
                    const newValue = evt?.target?.value;
                    let digitsOnlyValue = newValue;
                    while (/\D/.test(digitsOnlyValue)) {
                        digitsOnlyValue = digitsOnlyValue.replace(/\D/, '');
                    }
                    if (digitsOnlyValue) {
                        const month = digitsOnlyValue.substring(0, 2);
                        const day = digitsOnlyValue.substring(2, 4);
                        const year = digitsOnlyValue.substring(4, 8);
                        const formattedValue = formatDateValuesForState({
                            day: day,
                            month: month,
                            year: year
                        });
                        reduxFieldData.input.onChange(formattedValue);
                    } else {
                        reduxFieldData.input.onChange(digitsOnlyValue);
                    }
                }}
                InputProps={{ endAdornment }}
            />
            {shouldShowError && <Alert severity="error">Invalid date</Alert>}
        </Fragment>
    );
};

class CondensedDatePickerComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        useLabelAsPlaceholder: false,
        stylingOptions: undefined
    };

    render() {
        let fieldRender = renderDatePickerField;
        if (!browserSupportsDatePickers()) {
            fieldRender = renderTextDateField;
        }
        return (
            <Grid item {...this.props.spacing}>
                <div
                    style={{
                        marginRight: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : '',
                        marginLeft: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : ''
                    }}
                >
                    {!this.props.useLabelAsPlaceholder && <label>{this.props.labelString}</label>}
                    <Field
                        placeholder={this.props.useLabelAsPlaceholder ? this.props.labelString : 'MM/DD/YYYY'}
                        id={`${this.props.formName}.${this.props.fieldPath}`}
                        name={this.props.fieldPath}
                        component={fieldRender}
                        type="text"
                        extraFieldProps={this.props.extraFieldProps}
                        {...this.props.extraFieldProps}
                        ariaLabel={this.props.ariaLabel}
                        stylingOptions={this.props.stylingOptions}
                    />
                </div>
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
    ariaLabel: string;
    useLabelAsPlaceholder: boolean;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default CondensedDatePickerComponent;
