import React, { Fragment } from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { MaritalStatus } from '../../../static/constants/enums/maritalStatus';
import StateSelectionWrapper from '../base-form-components/state-selection-component/StateSelectionWrapper';
import DatePickerWrapper from '../base-form-components/date-picker-component/DatePickerWrapper';
import { validateSpouseVital } from '../../../toolboxes/redux-form/redux-form-validation';
class SpouseVitalFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        wasOrIsMarried: false
    };

    render() {
        return (
            <FormWrapper formName="spouseVital" validate={validateSpouseVital}>
                <SelectWrapper
                    formName="spouseVital"
                    fieldPath="maritalStatus"
                    labelString="Marital Status"
                    valueDisplays={Object.keys(MaritalStatus).map(key => {
                        return {
                            value: MaritalStatus[key],
                            display: MaritalStatus[key]
                        };
                    })}
                    spacing={{
                        xs: 12,
                        sm: 12
                    }}
                />
                {this.props.wasOrIsMarried && (
                    <Fragment>
                        <TextBoxWrapper
                            formName="spouseVital"
                            fieldPath="marriageCity"
                            labelString="Marriage City"
                            spacing={{
                                xs: 12,
                                sm: 8
                            }}
                        />
                        <StateSelectionWrapper
                            formName="spouseVital"
                            fieldPath="marriageState"
                            labelString="Marriage State"
                            spacing={{
                                xs: 12,
                                sm: 4
                            }}
                        />
                        <DatePickerWrapper
                            formName="spouseVital"
                            fieldPath="marriageDate"
                            labelString="Marriage Date"
                            spacing={{
                                xs: 12,
                                sm: 12
                            }}
                        />
                        <TextBoxWrapper
                            formName="spouseVital"
                            fieldPath="firstName"
                            labelString="Spouse First Name"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <TextBoxWrapper
                            formName="spouseVital"
                            fieldPath="middleName"
                            labelString="Spouse Middle Name"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <TextBoxWrapper
                            formName="spouseVital"
                            fieldPath="lastName"
                            labelString="Spouse Last Name"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <TextBoxWrapper
                            formName="spouseVital"
                            fieldPath="maidenName"
                            labelString="Spouse Maiden Name"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                    </Fragment>
                )}
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    wasOrIsMarried: boolean;
}

interface StateShape {
    // Shape of local state
}

export default SpouseVitalFormComponent;
