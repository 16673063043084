import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import DatePickerWrapper from '../base-form-components/date-picker-component/DatePickerWrapper';
import { normalizePhoneNumber, normalizeZipCode } from '../../../toolboxes/redux-form/redux-form-normalizers';
import { valueDisplaysFromEnum } from '../../../toolboxes/reuseable-logic/value-displays';
import { GeographicState } from '../../../static/constants/enums/states';
import { Button } from '@material-ui/core';
import DisabledField from 'static/models/DisabledField';

class PersonDemographicsFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        disabled: false,
        copyPurchaserPhone: null,
        copyPurchaserEmail: null,
        copyPurchaserFullAddress: null,
        hideBirthdate: false,
        hideGender: false,
        hideEmail: false,
        hidePhone: false
    };

    isDisabled(field: string): boolean {
        const ret = this.props.disableFields?.find(f => {
            return f?.field === field;
        });
        return ret !== undefined;
    }

    getDisabledReason(field: string): string {
        const ret = this.props.disableFields?.find(f => {
            if (f?.field === field) {
                return f?.disabledReason;
            }
        });
        return ret?.disabledReason;
    }

    render() {
        return (
            <FormWrapper formName={this.props.formName} validate={this.props.validate}>
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="firstName"
                    labelString="First Name"
                    ariaLabel="first name"
                    extraFieldProps={{
                        disabled: this.isDisabled('firstName'),
                        disabledreason: this.getDisabledReason('firstName')
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="lastName"
                    labelString="Last Name"
                    ariaLabel="last name"
                    extraFieldProps={{
                        disabled: this.isDisabled('lastName'),
                        disabledreason: this.getDisabledReason('lastName')
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                {!this.props.hidePhone && (
                    <TextBoxWrapper
                        normalize={normalizePhoneNumber}
                        formName={this.props.formName}
                        fieldPath="phone"
                        labelString="Phone Number - Must Have Text or Call Capabilities"
                        ariaLabel="phone number"
                        placeholder="555-555-5555"
                        extraFieldProps={{
                            inputProps: {
                                inputMode: 'numeric'
                            },
                            disabled: this.isDisabled('phone'),
                            disabledreason: this.getDisabledReason('phone')
                        }}
                        copyText={this.props.canCopyPurchaserInfo ? `Copy Purchaser's Phone` : null}
                        copyFunction={this.props.canCopyPurchaserInfo ? () => this.props.copyPurchaserPhone() : null}
                    />
                )}
                {!this.props.hideEmail && (
                    <TextBoxWrapper
                        formName={this.props.formName}
                        fieldPath="email"
                        labelString="Email Address"
                        ariaLabel="email address"
                        extraFieldProps={{
                            inputProps: {
                                inputMode: 'email'
                            },
                            disabled: this.isDisabled('email'),
                            disabledreason: this.getDisabledReason('email')
                        }}
                        copyText={this.props.canCopyPurchaserInfo ? `Copy Purchaser's Email` : null}
                        copyFunction={this.props.canCopyPurchaserInfo ? () => this.props.copyPurchaserEmail() : null}
                    />
                )}
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="address"
                    labelString="Address"
                    ariaLabel="address"
                    extraFieldProps={{
                        disabled: this.isDisabled('address'),
                        disabledreason: this.getDisabledReason('address')
                    }}
                    copyText={this.props.canCopyPurchaserInfo ? `Copy Purchaser's Address` : null}
                    copyFunction={this.props.canCopyPurchaserInfo ? () => this.props.copyPurchaserFullAddress() : null}
                />
                <TextBoxWrapper
                    formName={this.props.formName}
                    fieldPath="city"
                    labelString="City"
                    ariaLabel="city"
                    extraFieldProps={{
                        disabled: this.isDisabled('city'),
                        disabledreason: this.getDisabledReason('city')
                    }}
                    spacing={{
                        xs: 12,
                        sm: 5
                    }}
                />
                <SelectWrapper
                    formName={this.props.formName}
                    fieldPath="state"
                    labelString="State"
                    ariaLabel="state"
                    valueDisplays={valueDisplaysFromEnum(GeographicState)}
                    extraFieldProps={{
                        disabled: this.isDisabled('state'),
                        disabledreason: this.getDisabledReason('state')
                    }}
                    spacing={{
                        xs: 6,
                        sm: 3
                    }}
                />
                <TextBoxWrapper
                    normalize={normalizeZipCode}
                    formName={this.props.formName}
                    fieldPath="zipCode"
                    labelString="Zip Code"
                    ariaLabel="zip code"
                    extraFieldProps={{
                        inputProps: {
                            inputMode: 'numeric'
                        },
                        disabled: this.isDisabled('zipCode'),
                        disabledreason: this.getDisabledReason('zipCode')
                    }}
                    spacing={{
                        xs: 6,
                        sm: 4
                    }}
                />
                <>
                    {!this.props.hideBirthdate && (
                        <DatePickerWrapper
                            formName={this.props.formName}
                            fieldPath="birthdate"
                            labelString="Birthdate"
                            ariaLabel="birthdate"
                            extraFieldProps={{
                                disabled: this.isDisabled('birthdate'),
                                disabledreason: this.getDisabledReason('birthdate')
                            }}
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                    )}
                    {!this.props.hideGender && (
                        <SelectWrapper
                            formName={this.props.formName}
                            fieldPath="gender"
                            labelString="Gender"
                            ariaLabel="gender"
                            valueDisplays={[
                                {
                                    value: 'Female',
                                    display: 'Female'
                                },
                                {
                                    value: 'Male',
                                    display: 'Male'
                                }
                            ]}
                            extraFieldProps={{
                                disabled: this.isDisabled('gender'),
                                disabledreason: this.getDisabledReason('gender')
                            }}
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                    )}
                </>
                {this.props.includeAutofillButton && (
                    <Button size={'small'} onClick={() => this.props.autoFillForm()}>
                        {' '}
                    </Button>
                )}
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    validate: Function;
    formName: string;
    disabled: boolean;
    copyPurchaserPhone: Function;
    copyPurchaserEmail: Function;
    copyPurchaserFullAddress: Function;
    autoFillForm?: Function;
    canCopyPurchaserInfo: boolean;
    hidePhone?: boolean;
    hideEmail?: boolean;
    hideBirthdate?: boolean;
    hideGender?: boolean;
    includeAutofillButton?: boolean;
    onAutofillChange?: Function;
    disableFields?: DisabledField[];
}

interface StateShape {
    // Shape of local state
}

export default PersonDemographicsFormComponent;
