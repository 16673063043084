import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import MotherVitalFormWrapper from '../../form-components/mother-vital-form-component/MotherVitalFormWrapper';
import FatherVitalFormWrapper from '../../form-components/father-vital-form-component/FatherVitalFormWrapper';
import RecipientVitalFormWrapper from '../../form-components/recipient-vital-form-component/RecipientVitalFormWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import MilitaryVitalFormWrapper from '../../form-components/military-vital-form-component/MilitaryVitalFormWrapper';
import SpouseVitalFormWrapper from '../../form-components/spouse-vital-form-component/SpouseVitalFormWrapper';
import EmploymentVitalFormWrapper from '../../form-components/employment-vital-form-component/EmploymentVitalFormWrapper';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class VitalStatisticsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formIsValid: false
    };

    render() {
        return (
            <PageCardWrapper extraCardWidth>
                <FullContentCenterWrapper
                    metaPageName="VitalStatistics"
                    metaPageNumber="1"
                    metaPagebackAvailable="false"
                >
                    <h1>Recipient's Info</h1>
                    <RecipientVitalFormWrapper />

                    <h1>Mother's Info</h1>
                    <MotherVitalFormWrapper />

                    <h1>Father's Info</h1>
                    <FatherVitalFormWrapper />

                    <h1>Marital Info</h1>
                    <SpouseVitalFormWrapper />

                    <h1>Employment Info</h1>
                    <EmploymentVitalFormWrapper />

                    <h1>Military Info</h1>
                    <MilitaryVitalFormWrapper />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div>
                            <ButtonLinkWrapper
                                to={urlPaths.vitalStatisticsSaved}
                                buttonId="save"
                                disabled={!this.props.formIsValid}
                            >
                                Save
                            </ButtonLinkWrapper>
                        </div>
                    </div>
                    <BackButtonLinkWrapper />
                </FullContentCenterWrapper>
            </PageCardWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formIsValid: boolean;
}

interface StateShape {
    // Shape of local state
}

export default VitalStatisticsPageComponent;
