import React from 'react';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
class PurchaseProcessingNavigationPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps: PropShape = {
        // Default prop values
        nextUrl: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaseProcessingNavigation"
                metaPageNumber=""
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Thank you.</PageCardTitleWrapper>
                    <PageCardTextWrapper>You have now purchased your prepaid plan.</PageCardTextWrapper>
                    <PageCardTextWrapper>
                        The final step is to complete the cremation paperwork. These are the legal documents that will
                        allow us to register the death and authorize the cremation when the time comes. You will need
                        about 15 minutes to complete this paperwork.
                    </PageCardTextWrapper>
                    <ButtonLinkWrapper
                        to={urlPaths.purchaseProcessingNavigation} // just navigate to current location; side effect takes care of the redirect
                        buttonId="continue"
                        navigationSideAffect={() => {
                            window.location.href = this.props.nextUrl;
                        }}
                    >
                        Continue
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape {
    nextUrl: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaseProcessingNavigationPageComponent;
