import React, { Fragment } from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { normalizeBooleanFromString } from '../../../toolboxes/redux-form/redux-form-normalizers';
import DatePickerWrapper from '../base-form-components/date-picker-component/DatePickerWrapper';
import { validateMilitaryVital } from '../../../toolboxes/redux-form/redux-form-validation';
class MilitaryVitalFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        isMilitaryVeteran: false
    };

    render() {
        return (
            <FormWrapper formName="militaryVital" validate={validateMilitaryVital}>
                <SelectWrapper
                    formName="militaryVital"
                    fieldPath="isMilitaryVeteran"
                    labelString="Military Service Veteran Status"
                    normalize={normalizeBooleanFromString}
                    valueDisplays={[
                        {
                            value: true,
                            display: 'Yes'
                        },
                        {
                            value: false,
                            display: 'No'
                        }
                    ]}
                />
                {this.props.isMilitaryVeteran && (
                    <Fragment>
                        <TextBoxWrapper
                            formName="militaryVital"
                            fieldPath="militaryServiceBranch"
                            labelString="Military Service Branch"
                        />
                        <TextBoxWrapper
                            formName="militaryVital"
                            fieldPath="militaryServiceRank"
                            labelString="Military Service Rank"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <TextBoxWrapper
                            formName="militaryVital"
                            fieldPath="militaryServiceNumber"
                            labelString="Military Service Number"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <DatePickerWrapper
                            formName="militaryVital"
                            fieldPath="militaryServiceStartDate"
                            labelString="Military Service Start Date"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <DatePickerWrapper
                            formName="militaryVital"
                            fieldPath="militaryServiceEndDate"
                            labelString="Military Service End Date"
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                        <TextBoxWrapper
                            formName="militaryVital"
                            fieldPath="honors"
                            labelString="Honors or Commendations"
                        />
                    </Fragment>
                )}
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    isMilitaryVeteran: boolean;
}

interface StateShape {
    // Shape of local state
}

export default MilitaryVitalFormComponent;
