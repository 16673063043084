import ButtonSubtitledLinkWrapper from 'components/global-components/buttons/button-subtitled-link-component/ButtonSubtitledLinkWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setIrrevocableAction: () => {}
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName={undefined}
                metaPageNumber={undefined}
                metaPagebackAvailable={undefined}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Would you like to make your arrangement irrevocable?</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        By making your {this.props.policyContractLanguage} irrevocable, you are waiving your rights to
                        cancel or receive a refund of the monies paid. A common reason for doing this is to qualify for
                        Medicaid or other public assistance programs.
                    </PageCardTextWrapper>
                    <ButtonSubtitledLinkWrapper
                        to={urlPaths.orderSummary}
                        title={'Yes'}
                        subtitle=""
                        buttonId="make-irrevocable"
                        navigationSideEffect={() => {
                            this.props.setIrrevocableAction(true);
                        }}
                    />
                    <ButtonSubtitledLinkWrapper
                        to={urlPaths.orderSummary}
                        title={'No'}
                        subtitle=""
                        buttonId="dont-make-irrevocable"
                        navigationSideEffect={() => {
                            this.props.setIrrevocableAction(false);
                        }}
                    />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setIrrevocableAction: (newValue: boolean) => void;
    policyContractLanguage: string;
}

interface StateShape {
    // Shape of local state
}

export default TemplateComponent;
