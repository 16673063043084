import { connect } from 'react-redux';
import HealthQuestionInstructionsModalComponent from './HealthQuestionInstructionsModalComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getWordForPackage } from '../../../../toolboxes/reuseable-logic/get-word-for-package';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        wordForPackage: getWordForPackage(state),
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthQuestionInstructionsModalComponent);
