import React, { Fragment } from 'react';
import BasicProductCardComponent from '../basic-product-card-component/BasicProductCardWrapper';
import './GeneralProductsDisplayComponent.css';
import { Input, Select, Grid, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { dataWrapper } from '../../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import Product from '../../../../static/models/Product';
import { sortedCategoryStrings } from '../../../../static/constants/sortedCategories';
import { generateFullCategoryStringForProduct } from '../../../../toolboxes/reuseable-logic/product-category-display-calculator';

class GeneralProductsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            currentSearch: '',
            filteredCategory: '',
            sortedBy: 'Name Asc.'
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        if (this.props.availableProducts.length === 0) {
            return <div className="empty-products-display">No Products Available</div>;
        }
        return (
            <Fragment>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <label>Search for products</label>
                        <Input
                            fullWidth
                            placeholder="Product Search"
                            value={this.state.currentSearch}
                            onChange={event => this.setState({ currentSearch: event.target.value })}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            id="input-search-products"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label>Sort by</label>
                        <Select
                            fullWidth
                            native
                            onChange={event => {
                                // @ts-ignore
                                this.setState({ sortedBy: event.target.value });
                            }}
                        >
                            {['Name Asc.', 'Name Desc.', 'Price Asc.', 'Price Desc.'].map(sortingType => {
                                return (
                                    <option
                                        value={sortingType}
                                        id={`select-option-${sortingType}`}
                                        key={`select-option-${sortingType}`}
                                    >
                                        {sortingType}
                                    </option>
                                );
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label>Filter by</label>
                        <Select
                            fullWidth
                            native
                            onChange={event => {
                                // @ts-ignore
                                this.setState({ filteredCategory: event.target.value });
                            }}
                        >
                            <option value="" id="select-option-none">
                                None
                            </option>
                            {sortedCategoryStrings.map(category => {
                                return (
                                    <option
                                        value={category}
                                        id={`select-option-${category}`}
                                        key={`select-option-${category}`}
                                    >
                                        {category}
                                    </option>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <div className="agent-product-display-list">
                    {this.props.availableProducts
                        .sort((a, b) => {
                            switch (this.state.sortedBy) {
                                case 'Name Asc.':
                                    return a.name.localeCompare(b.name);
                                case 'Name Desc.':
                                    return b.name.localeCompare(a.name);
                                case 'Price Asc.':
                                    return a.price - b.price;
                                case 'Price Desc.':
                                    return b.price - a.price;
                                default:
                                    return a.name.localeCompare(b.name);
                            }
                        })
                        .filter(product => {
                            if (!this.state.filteredCategory) {
                                return true;
                            }
                            return generateFullCategoryStringForProduct(product) === this.state.filteredCategory;
                        })
                        .map(product => {
                            let metSearchCriteria = true;
                            this.state.currentSearch.split(' ').forEach(searchTerm => {
                                if (!searchTerm) {
                                    return;
                                }
                                let searchTermMet = false;
                                let lowercaseSearchTerm: string = dataWrapper(() => searchTerm.toLowerCase(), '');
                                if (lowercaseSearchTerm.endsWith('s')) {
                                    lowercaseSearchTerm = lowercaseSearchTerm.substring(
                                        0,
                                        lowercaseSearchTerm.length - 1
                                    );
                                }
                                if (dataWrapper(() => product.name.toLowerCase().includes(lowercaseSearchTerm))) {
                                    searchTermMet = true;
                                }
                                if (dataWrapper(() => product.category.toLowerCase().includes(lowercaseSearchTerm))) {
                                    searchTermMet = true;
                                }
                                if (
                                    dataWrapper(() => product.subcategory.toLowerCase().includes(lowercaseSearchTerm))
                                ) {
                                    searchTermMet = true;
                                }
                                if (!searchTermMet) {
                                    metSearchCriteria = false;
                                }
                            });
                            if (!metSearchCriteria) {
                                return null;
                            }
                            return (
                                <BasicProductCardComponent
                                    key={product.productId}
                                    product={product}
                                    addOpportunityProductAction={this.props.addProductAction}
                                />
                            );
                        })}
                </div>
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    availableProducts: Product[];
    addProductAction: (Producet) => void;
}

interface StateShape {
    // Shape of local state
    currentSearch: string;
    filteredCategory: string;
    sortedBy: 'Price Asc.' | 'Name Asc.' | 'Price Desc.' | 'Name Desc.';
}

export default GeneralProductsDisplayComponent;
