// Action Type
export const SET_STYLING_SPACING_ABOVE_CONTINUE_BUTTON = 'SET_STYLING_SPACING_ABOVE_CONTINUE_BUTTON';
// Action
export function setSpacingAboveContinueButtonAction(newValue: string) {
    return { type: SET_STYLING_SPACING_ABOVE_CONTINUE_BUTTON, newValue };
}
// Reducer
export function setSpacingAboveContinueButtonReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STYLING_SPACING_ABOVE_CONTINUE_BUTTON:
            return action.newValue;
        default:
            return state;
    }
}
