import { sendRequest, defaultPostConfiguration } from '../web-request-tools';
import axios from 'axios';
import getEnvironment from 'env-config/get-environment';

const identityBaseUrl = getEnvironment().efuneralIdentityVerificationUrl;

export function runSubmitIdentityPlaid(contactId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(
            `${identityBaseUrl}/verify-contact`,
            {
                contactId: contactId
            },
            defaultPostConfiguration
        ),
        callback
    );
}
