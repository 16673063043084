import React from 'react';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import SelectWrapper from '../select-component/SelectWrapper';
import { valueDisplaysFromEnum } from '../../../../toolboxes/reuseable-logic/value-displays';
import { GeographicState } from '../../../../static/constants/enums/states';

class StateSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        spacing: defaultSpacing,
        labelString: ''
    };

    render() {
        return <SelectWrapper {...this.props} valueDisplays={valueDisplaysFromEnum(GeographicState)} />;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
}

interface StateShape {
    // Shape of local state
}

export default StateSelectionComponent;
