import React from 'react';
import './FullContentCenterComponent.css';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';
import { Helmet } from 'react-helmet';

class FullContentCenterComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined,
        alignTop: false,
        metaFirstPage: 'false',
        metaLastPage: 'false',
        applicationStylingKey: ''
    };

    componentDidMount() {
        const targetOrigin = '*';
        window.parent.postMessage(
            {
                pageName: this.props.metaPageName,
                firstPage: this.props.metaFirstPage,
                lastPage: this.props.metaLastPage
            },
            targetOrigin
        );
    }

    render() {
        const className =
            this.props.applicationStylingKey === 'everdays' ? 'full-content-center-everdays' : 'full-content-center';

        return (
            <div
                className={className}
                style={
                    this.props.stylingOptions?.spacing?.abovePageTitle
                        ? { marginTop: '0px' }
                        : this.props.alignTop
                        ? {}
                        : { alignItems: 'center' }
                }
            >
                {(this.props.metaPageName || this.props.metaPageNumber || this.props.metaPagebackAvailable) && (
                    <Helmet>
                        {this.props.metaPageName && <meta name="pageName" content={this.props.metaPageName} />}
                        {this.props.metaPageNumber && <meta name="pageNumber" content={this.props.metaPageNumber} />}
                        {this.props.metaPagebackAvailable && (
                            <meta name="pageBackAvailable" content={this.props.metaPagebackAvailable} />
                        )}
                        {<meta name="firstPage" content={this.props.metaFirstPage} />}
                        {<meta name="lastPage" content={this.props.metaLastPage} />}
                    </Helmet>
                )}
                {this.props.children}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    alignTop: boolean;
    metaPageName: string;
    metaPageNumber: string;
    metaPagebackAvailable: string;
    metaFirstPage: string;
    metaLastPage: string;
    applicationStylingKey: string;
}

interface StateShape {
    // Shape of local state
}

export default FullContentCenterComponent;
