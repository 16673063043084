import { connect } from 'react-redux';
import SendIdentityLinkEmailPageComponent from './SendIdentityLinkEmailPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        purchaserFullName: `${getFormData(state, 'purchaser', 'firstName')} ${getFormData(
            state,
            'purchaser',
            'lastName'
        )}`,
        purcahserEmail: getFormData(state, 'purchaser', 'email')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SendIdentityLinkEmailPageComponent);
