import React from 'react';
import { Field } from 'redux-form';
import ValueDisplay from '../../../../static/models/ValueDisplay';
import Radio from '@material-ui/core/Radio';
import { FormLabel, Grid, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import { colorDoesBlendWithBackground } from 'static/styling/colors-that-blend-with-background';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { dataWrapper } from 'toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';

const renderField = (reduxFieldData: any) => {
    return reduxFieldData.valueDisplays.map((valueDisplay: ValueDisplay) => {
        return (
            <div
                key={valueDisplay.value + '-' + valueDisplay.display}
                onClick={() => {
                    reduxFieldData.input.onChange(valueDisplay.value);
                }}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Radio
                    checked={reduxFieldData.input.value === valueDisplay.value}
                    id={`button-${valueDisplay.value}-${reduxFieldData.meta.form}.${reduxFieldData.input.name}`}
                />
                <div
                    style={{
                        fontSize: reduxFieldData.stylingOptions?.fonts?.bodyFontSize
                    }}
                >
                    {valueDisplay.display}
                </div>
            </div>
        );
    });
};

class RadioGroupComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        labelString: '',
        spacing: defaultSpacing,
        normalizer: value => value,
        currentValue: null,
        applicationStyling: null
    };

    render() {
        const baseComponent = (
            <Grid item {...this.props.spacing}>
                <FormLabel component="legend">{this.props.labelString}</FormLabel>
                <Field
                    id={`${this.props.formName}.${this.props.fieldPath}`}
                    name={this.props.fieldPath}
                    component={reduxFieldData => {
                        // Sometimes the value doesn't get passed through by redux form?
                        reduxFieldData.input.value = this.props.currentValue;
                        return renderField(reduxFieldData);
                    }}
                    type="radio"
                    valueDisplays={this.props.valueDisplays}
                    extraFieldProps={this.props.extraFieldProps}
                    normalize={this.props.normalizer}
                    {...this.props.extraFieldProps}
                    stylingOptions={this.props.applicationStyling}
                />
            </Grid>
        );
        if (
            colorDoesBlendWithBackground(
                this.props.applicationStyling,
                dataWrapper(() => this.props.applicationStyling.colors.secondaryColor)
            )
        ) {
            return (
                <ThemeProvider
                    theme={createMuiTheme({
                        palette: {
                            secondary: {
                                main: '#d3d3d3'
                            }
                        }
                    })}
                >
                    {baseComponent}
                </ThemeProvider>
            );
        }
        return baseComponent;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    valueDisplays: ValueDisplay[];
    formName: string;
    labelString: string;
    fieldPath: string;
    extraFieldProps: object;
    spacing: object;
    normalizer: (string) => any;
    currentValue: any;
    applicationStyling: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default RadioGroupComponent;
