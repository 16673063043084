// Action Type
export const SET_TRAVEL_CONTRACT_URL_DATE = 'SET_TRAVEL_CONTRACT_URL_DATE';
// Action
export function setTravelContractUrlDateAction(newValue: Date) {
    return { type: SET_TRAVEL_CONTRACT_URL_DATE, newValue };
}
// Reducer
export function setTravelContractUrlDateReducer(state: any, action: any): Date {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_TRAVEL_CONTRACT_URL_DATE:
            return action.newValue;
        default:
            return state;
    }
}
