import React from 'react';
import { Field } from 'redux-form';
import './DatePickerComponent.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import {
    getValuesFromStringDate,
    formatDateValuesForState
} from '../../../../toolboxes/reuseable-logic/date-form-formatter';
import {
    validDayRequired,
    validMonthRequired,
    validYearRequired,
    validCurrentDateRequired
} from '../../../../toolboxes/redux-form/redux-form-validation';
import CondensedDatePickerWrapper from '../condensed-date-picker-component/CondensedDatePickerWrapper';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { InputAdornment } from '@material-ui/core';
import InfoPopupComponent from '../info-popup-component/InfoPopupComponent';

const renderField = (reduxFieldData: any) => {
    let endAdornment = null;
    if (reduxFieldData.extraFieldProps?.disabledreason) {
        endAdornment = (
            <InputAdornment position="end">
                <InfoPopupComponent
                    infoModalTitle="Why can I not edit this?"
                    infoModalContent={reduxFieldData.extraFieldProps?.disabledreason}
                />
            </InputAdornment>
        );
    }

    let canShowError = reduxFieldData.meta.touched && !reduxFieldData.meta.active;
    const brokenDownDatePieces = getValuesFromStringDate(reduxFieldData.input.value);
    const slicedAndDicedOnChange = (day: string, month: string, year: string) => {
        let filledYear = year;
        let filledMonth = month;
        let filledDay = day;
        if (filledYear === null) {
            filledYear = brokenDownDatePieces.year;
        }
        if (filledMonth === null) {
            filledMonth = brokenDownDatePieces.month;
        }
        if (filledDay === null) {
            filledDay = brokenDownDatePieces.day;
        }
        const cleanedInput = formatDateValuesForState({
            year: filledYear,
            month: filledMonth,
            day: filledDay
        });
        reduxFieldData.input.onChange(cleanedInput);
    };
    const currentDateInvalid = validCurrentDateRequired(reduxFieldData.input.value) && canShowError;
    const dateInvalid = (validDayRequired(brokenDownDatePieces.day) && canShowError) || currentDateInvalid;
    const monthInvalid = (validMonthRequired(brokenDownDatePieces.month) && canShowError) || currentDateInvalid;
    const yearInvalid = (validYearRequired(brokenDownDatePieces.year) && canShowError) || currentDateInvalid;
    return (
        <Grid container spacing={1} justify="center">
            <Grid item xs={3}>
                <TextField
                    {...reduxFieldData.input}
                    {...reduxFieldData.extraFieldProps}
                    onBlur={() => {
                        reduxFieldData.input.onBlur(reduxFieldData.input.value);
                    }}
                    error={monthInvalid}
                    value={brokenDownDatePieces.month || ''}
                    onChange={event => slicedAndDicedOnChange(null, event.target.value, null)}
                    placeholder="MM"
                    id={`input-${reduxFieldData.id}-month`}
                    fullWidth={true}
                    inputProps={{
                        ...reduxFieldData.extraFieldProps,
                        'aria-label': `${reduxFieldData.ariaLabel} month`
                    }}
                    style={{
                        backgroundColor: reduxFieldData.stylingOptions.colors.formFieldsBackgroundColor,
                        color: reduxFieldData.stylingOptions.colors.formFieldsTextColor,
                        marginBottom: reduxFieldData.stylingOptions.spacing?.innerBody
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    {...reduxFieldData.input}
                    {...reduxFieldData.extraFieldProps}
                    onBlur={() => {
                        reduxFieldData.input.onBlur(reduxFieldData.input.value);
                    }}
                    error={dateInvalid}
                    value={brokenDownDatePieces.day || ''}
                    onChange={event => slicedAndDicedOnChange(event.target.value, null, null)}
                    placeholder="DD"
                    id={`input-${reduxFieldData.id}-day`}
                    fullWidth={true}
                    inputProps={{
                        ...reduxFieldData.extraFieldProps,
                        'aria-label': `${reduxFieldData.ariaLabel} day`
                    }}
                    style={{
                        backgroundColor: reduxFieldData.stylingOptions.colors.formFieldsBackgroundColor,
                        color: reduxFieldData.stylingOptions.colors.formFieldsTextColor
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    {...reduxFieldData.input}
                    {...reduxFieldData.extraFieldProps}
                    onBlur={() => {
                        reduxFieldData.input.onBlur(reduxFieldData.input.value);
                    }}
                    error={yearInvalid}
                    value={brokenDownDatePieces.year || ''}
                    onChange={event => slicedAndDicedOnChange(null, null, event.target.value)}
                    placeholder="YYYY"
                    id={`input-${reduxFieldData.id}-year`}
                    fullWidth={true}
                    inputProps={{
                        ...reduxFieldData.extraFieldProps,
                        'aria-label': `${reduxFieldData.ariaLabel} year`
                    }}
                    style={{
                        backgroundColor: reduxFieldData.stylingOptions.colors.formFieldsBackgroundColor,
                        color: reduxFieldData.stylingOptions.colors.formFieldsTextColor
                    }}
                    InputProps={{ endAdornment }}
                />
            </Grid>
        </Grid>
    );
};

class DatePickerComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        placeholder: '',
        spacing: defaultSpacing,
        labelString: '',
        ariaLabel: '',
        useLabelAsPlaceholder: false,
        stylingOptions: undefined,
        disabled: false
    };

    render() {
        if (this.props.useLabelAsPlaceholder) {
            return (
                <CondensedDatePickerWrapper
                    formName={this.props.formName}
                    fieldPath={this.props.fieldPath}
                    extraFieldProps={this.props.extraFieldProps}
                    spacing={this.props.spacing}
                    labelString={this.props.labelString}
                    ariaLabel={this.props.ariaLabel}
                />
            );
        }
        return (
            <Grid item {...this.props.spacing}>
                <div
                    style={{
                        marginRight: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : '',
                        marginLeft: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : ''
                    }}
                >
                    {!this.props.useLabelAsPlaceholder && <label>{this.props.labelString}</label>}
                    <Field
                        id={`${this.props.formName}.${this.props.fieldPath}`}
                        name={this.props.fieldPath}
                        component={renderField}
                        type="text"
                        extraFieldProps={this.props.extraFieldProps}
                        {...this.props.extraFieldProps}
                        ariaLabel={this.props.ariaLabel}
                        stylingOptions={this.props.stylingOptions}
                    />
                </div>
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
    ariaLabel: string;
    useLabelAsPlaceholder: boolean;
    stylingOptions: ApplicationStyling;
    disabled: boolean;
}

interface StateShape {
    // Shape of local state
}

export default DatePickerComponent;
