import { connect } from 'react-redux';
import StartPlanningPageComponent from './StartPlanningPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StartPlanningPageComponent);
