import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    fetchAndStorePaymentOptions,
    sendSavePaymentOption,
    sendSaveEmptyPaymentOption
} from '../../server-application-buffer/payment-data';
import { setSelectedPaymentOptionAction } from '../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { setPaymentOptionsUpToDateAction } from '../../../redux/opportunity/pricing/paymentOptions/upToDate';
import { setPaymentIsPredeterminedAction } from '../../../redux/opportunity/pricing/isPredetermined';
import { paymentOptionsAreEquivalent } from '../../reuseable-logic/payment-options-calculator';
import { PaymentMode } from '../../../static/constants/enums/paymentModes';

export default function loadOrderSummaryPage(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const loadFunctions: BlockingFunction[] = [];

    loadFunctions.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                if (state.opportunity.pricing.paymentOptions.upToDate) {
                    return resolve();
                }
                fetchAndStorePaymentOptions(state, dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    const selectedPaymentOption = state.opportunity.pricing.paymentOptions.selectedOption;
                    if (selectedPaymentOption) {
                        if (
                            !result.some(x => {
                                return paymentOptionsAreEquivalent(x, selectedPaymentOption);
                            })
                        ) {
                            let PaymentOptionLookup = result.find(x => {
                                if (selectedPaymentOption.paymentMode === PaymentMode.Single) {
                                    // Ignore the years for now
                                    return (
                                        x.paymentMethod === selectedPaymentOption.paymentMethod &&
                                        x.paymentMode === selectedPaymentOption.paymentMode
                                    );
                                }
                                return (
                                    x.paymentMethod === selectedPaymentOption.paymentMethod &&
                                    x.years === selectedPaymentOption.years &&
                                    x.paymentMode === selectedPaymentOption.paymentMode
                                );
                            });
                            if (!PaymentOptionLookup) {
                                PaymentOptionLookup = null;
                                dispatch(setPaymentIsPredeterminedAction(false));
                            }
                            dispatch(setSelectedPaymentOptionAction(PaymentOptionLookup));
                            if (PaymentOptionLookup) {
                                sendSavePaymentOption(PaymentOptionLookup, state.opportunity.id, (result, error) => {
                                    if (error) {
                                        return reject(error);
                                    }
                                    return resolve();
                                });
                            } else if (!PaymentOptionLookup) {
                                sendSaveEmptyPaymentOption(state.opportunity.id, (result, error) => {
                                    if (error) {
                                        return reject(error);
                                    }
                                    return resolve();
                                });
                            }
                        }
                        dispatch(setPaymentOptionsUpToDateAction(true));
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.orderSummary]
    });

    return loadFunctions;
}
