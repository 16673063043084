import { GeographicState } from '../enums/states';

const funeralDirectorStates: string[] = [
    GeographicState.MA,
    GeographicState.NJ,
    GeographicState.NC,
    GeographicState.SC,
    GeographicState.VT,
    GeographicState.VA
];

export default funeralDirectorStates;
