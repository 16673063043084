import { connect } from 'react-redux';
import PageCardComponent from './PageCardComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import getEnvironment from 'env-config/get-environment';
import { testEnvironment } from 'env-config/environment-configs/test';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const env = getEnvironment();
    return {
        includeFailingRequestButton: env === testEnvironment ? true : false,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCardComponent);
