import { combineReducers } from 'redux';
import { setIrrevocableReducer } from './irrevocable';
import { setHasCurrentPoliciesReducer } from './hasCurrentPolicies';
import { setReplacingCurrentPoliciesReducer } from './replacingCurrentPolicies';
import { setBeneficaryProvidedReducer } from './beneficiaryProvided';
import { setTrustPaymentMonthlyReducer } from './trustPaymentMonthly';
import { setBeneficaryIsPurchaserReducer } from './beneficiaryIsPurchaser';

export default combineReducers({
    irrevocable: setIrrevocableReducer,
    hasCurrentPolicies: setHasCurrentPoliciesReducer,
    replacingCurrentPolicies: setReplacingCurrentPoliciesReducer,
    beneficiaryProvided: setBeneficaryProvidedReducer,
    trustPaymentMonthly: setTrustPaymentMonthlyReducer,
    beneficiaryIsPurchaser: setBeneficaryIsPurchaserReducer
});
