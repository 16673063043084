import DisclaimerAddressing from 'static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_TRUST_FUNDED_PREARRAGEMENT_ADDRESSING = 'SET_TRUST_FUNDED_PREARRAGEMENT_ADDRESSING';
// Action
export function setTrustFundedPrearrangementAction(newValue: DisclaimerAddressing) {
    return { type: SET_TRUST_FUNDED_PREARRAGEMENT_ADDRESSING, newValue };
}
// Reducer
export function setTrustFundedprearrangementReducer(state: any, action: any): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_TRUST_FUNDED_PREARRAGEMENT_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
