import React, { ReactNode } from 'react';
import './PageCardUnderlinedSectionComponent.css';
import MockLinkWrapper from '../../mock-link-component/MockLinkWrapper';

class PageCardUnderlinedSectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            isOpen: false
        };
        this.getSectionIsCollapseable = this.getSectionIsCollapseable.bind(this);
        this.getRenderContent = this.getRenderContent.bind(this);
    }

    static defaultProps = {
        // Default prop values
        summaryContent: null
    };

    getSectionIsCollapseable() {
        return !!this.props.summaryContent;
    }

    getRenderContent() {
        if (this.getSectionIsCollapseable() && !this.state.isOpen) {
            return this.props.summaryContent;
        }
        return this.props.fullContent;
    }

    render() {
        return (
            <div className="underlined-section-component">
                <div className="underlined-section-component-title-container">
                    <div>
                        <b style={{ fontSize: '1.2rem' }}>{this.props.sectionTitle}</b>
                    </div>
                    <div>
                        {this.getSectionIsCollapseable() && (
                            <MockLinkWrapper
                                onClick={() =>
                                    this.setState({
                                        isOpen: !this.state.isOpen
                                    })
                                }
                                id={this.props.sectionId + '-see-details'}
                            >
                                {this.state.isOpen ? '- Hide details' : '+ See details'}
                            </MockLinkWrapper>
                        )}
                    </div>
                </div>
                {this.getRenderContent()}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    sectionTitle: string;
    sectionId: string;
    fullContent: ReactNode;
    summaryContent: ReactNode;
}

interface StateShape {
    // Shape of local state
    isOpen: boolean;
}

export default PageCardUnderlinedSectionComponent;
