import React from 'react';
import SearchBarWrapper from '../search-bar-component/SearchBarWrapper';
import {
    getAccountsBySearchTerms,
    AgentSearchedAccount
} from '../../../toolboxes/server-application-buffer/account-data';
import FundingProduct from '../../../static/models/FundingProduct';
import { calculateHomesteadersFundingProductsForAccount } from '../../../toolboxes/reuseable-logic/funding-product-calculator';

interface FuneralHomeFundingProductMapping {
    subdomain: string;
    funeralHomeFundingProducts: FundingProduct[];
}

class FuneralHomeSearchComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            touched: false
        };
    }

    static defaultProps = {
        // Default prop values
        setAccountSubdomainAction: () => {},
        setAccountFundingProductsAction: () => {},
        setAccountIdAction: () => {},
        touched: false,
        currentSubdomain: ''
    };

    render() {
        return (
            <SearchBarWrapper
                id="funeral-home-lookup"
                onChange={(newValue: AgentSearchedAccount) => {
                    if (newValue) {
                        this.props.setAccountSubdomainAction(newValue.subdomain);
                        this.props.setAccountFundingProductsAction(
                            calculateHomesteadersFundingProductsForAccount(newValue.homesteadersProductCode)
                        );
                        this.props.setAccountIdAction(newValue.id);
                    } else {
                        this.props.setAccountSubdomainAction('');
                        this.props.setAccountFundingProductsAction([]);
                        this.props.setAccountIdAction('');
                    }
                }}
                hasError={!this.props.currentSubdomain}
                touched={this.props.touched}
                searchPromiseGenerator={searchTerms =>
                    new Promise((resolve, reject) => {
                        if (!searchTerms) {
                            return resolve([]);
                        }
                        getAccountsBySearchTerms(searchTerms, (result, error) => {
                            if (error) {
                                console.error(error);
                                return resolve([]);
                            }
                            const displayValues = [];
                            result.forEach(unformattedAccount => {
                                if (unformattedAccount.subdomain) {
                                    displayValues.push({
                                        display: `${unformattedAccount.name} - ${unformattedAccount.address}`,
                                        value: unformattedAccount
                                    });
                                }
                            });
                            return resolve(displayValues);
                        });
                    })
                }
                label="Find Funeral Home"
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setAccountSubdomainAction: Function;
    setAccountFundingProductsAction: Function;
    setAccountIdAction: Function;
    currentSubdomain: string;
    touched: boolean;
}

interface StateShape {
    // Shape of local state
    touched: boolean;
}

export default FuneralHomeSearchComponent;
