import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

export function funeralContractsReroutes(state: GlobalStateShape) {
    if (
        state.opportunity?.purchaser?.identityVerificationPlaid &&
        state.opportunity?.purchaser?.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
    ) {
        if (state.opportunity?.purchaser?.phoneVerificationRequested !== true) {
            return urlPaths.purchaserPhoneVerificationRequest;
        }
        if (state.opportunity?.purchaser?.phoneVerified !== true) {
            return urlPaths.purchaserPhoneVerify;
        }
    }
    if (state.opportunity?.isDemoAndContractWouldIncurCost) {
        return urlPaths.docusignFreeContractPage;
    }
}
