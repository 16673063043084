import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Routes from './Routes';
import LoadingScreenWrapper from '../global-components/loading-screen-component/LoadingScreenWrapper';
import RouterFormattedBlockingFunction from '../../static/models/blockingFunctions/RouterFormattedBlockingFunction';
import ErrorData from 'static/models/errorLogging/ErrorData';
import urlPaths from 'static/constants/enums/urlPaths';
import getEnvironment from 'env-config/get-environment';

class RoutingComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);

        this.someRunningFunctionBlockingPage = this.someRunningFunctionBlockingPage.bind(this);
        this.handleFinishedFunction = this.handleFinishedFunction.bind(this);
        this.beginErrorStage = this.beginErrorStage.bind(this);
        this.beginExitStage = this.beginExitStage.bind(this);
        this.beginRerouteStage = this.beginRerouteStage.bind(this);
        this.beginEntryStage = this.beginEntryStage.bind(this);
        this.beginDisplayStage = this.beginDisplayStage.bind(this);
        this.handleBrowserNavigation = this.handleBrowserNavigation.bind(this);
        this.startRunningFunction = this.startRunningFunction.bind(this);

        window.addEventListener('beforeunload', this.handleBrowserNavigation);
        this.state = {
            runningFunctions: [],
            preppedToDisplayPage: '',
            routingStatus: 'rerouting'
        };
    }

    static defaultProps = {
        // Default prop values
        currentPage: '',
        previousUrlFull: '',
        pageEnterFunctions: [],
        pageReroute: '',
        pageExitFunctions: [],
        showDebugInfo: false
    };

    handleBrowserNavigation(e: Event) {
        if (this.state.runningFunctions.length > 0) {
            e.preventDefault();
            return true;
        }
    }

    someRunningFunctionBlockingPage(runningFunctions: RouterFormattedBlockingFunction[], page: string) {
        return runningFunctions.some(runningFunction => {
            return runningFunction.blocksPageStarts.some(blockPageStart => page.startsWith(blockPageStart));
        });
    }

    componentDidMount() {
        if (this.props.pageReroute && this.props.pageReroute !== this.props.currentPage) {
        } else {
            this.beginEntryStage();
        }
    }

    componentDidUpdate() {
        if (this.props.currentPage === urlPaths.error && this.state.routingStatus === 'error') {
            this.setState({
                routingStatus: 'displaying'
            });
        }
        if (this.props.currentPage === this.state.preppedToDisplayPage) {
            return;
        }
        if (this.state.routingStatus === 'displaying') {
            this.beginExitStage(this.props.previousPageExitFunctions);
            return;
        }
        if (this.state.routingStatus === 'exiting') {
            this.beginRerouteStage();
            return;
        }
        if (this.state.routingStatus === 'rerouting') {
            if (this.props.pageReroute && this.props.pageReroute !== this.props.currentPage) {
                return;
            }
            if (this.someRunningFunctionBlockingPage(this.state.runningFunctions, this.props.currentPage)) {
                return;
            }
            this.beginEntryStage();
            return;
        }
        if (this.state.routingStatus === 'entering') {
            if (this.someRunningFunctionBlockingPage(this.state.runningFunctions, this.props.currentPage)) {
                return;
            }
            if (this.props.pageReroute) {
                this.beginRerouteStage();
                return;
            }
            this.beginDisplayStage();
            return;
        }
    }

    handleFinishedFunction(functionId: string) {
        if (getEnvironment().logDebugInfo) {
            const endingFunction = this.state.runningFunctions.find(
                runningFunction => runningFunction.id === functionId
            );
            console.info(
                `The routing was notified that function ${
                    endingFunction.id
                } has finished, and has unblocked ${endingFunction.blocksPageStarts.join(', ') || 'nothing'}`
            );
        }
        this.setState({
            runningFunctions: this.state.runningFunctions.filter(runningFunction => runningFunction.id !== functionId)
        });
    }

    beginErrorStage() {
        console.error('The routing has been set to an error state due to a failed function');
        this.setState({
            routingStatus: 'error'
        });
    }

    beginExitStage(previousPageExitFunctions: RouterFormattedBlockingFunction[]) {
        if (getEnvironment().logDebugInfo) {
            console.info(`The routing detected that ${this.state.preppedToDisplayPage} was exited`);
        }
        previousPageExitFunctions.forEach(pageExitFunction => {
            this.startRunningFunction(pageExitFunction);
        });
        this.setState({
            runningFunctions: this.state.runningFunctions.concat(previousPageExitFunctions),
            routingStatus: 'exiting',
            preppedToDisplayPage: ''
        });
    }

    beginRerouteStage() {
        this.setState({
            routingStatus: 'rerouting'
        });
    }

    beginEntryStage() {
        if (getEnvironment().logDebugInfo) {
            console.info(`The routing is preparing to display ${this.props.currentPage}`);
        }

        window.history.pushState({ prevUrl: this.props.previousUrlFull }, null, null);

        this.props.pageEnterFunctions.forEach(pageEnterFunction => {
            this.startRunningFunction(pageEnterFunction);
        });
        this.setState({
            runningFunctions: this.state.runningFunctions.concat(this.props.pageEnterFunctions),
            routingStatus: 'entering'
        });
    }

    beginDisplayStage() {
        if (getEnvironment().logDebugInfo) {
            console.info(`The routing has prepared to display ${this.props.currentPage}`);
        }
        window.scrollTo(0, 0);
        this.setState({
            preppedToDisplayPage: this.props.currentPage,
            routingStatus: 'displaying'
        });
    }

    startRunningFunction(functionToStart: RouterFormattedBlockingFunction) {
        if (getEnvironment().logDebugInfo) {
            console.info(
                `The routing is beginning function ${
                    functionToStart.id
                } and has blocked ${functionToStart.blocksPageStarts.join(', ') || 'nothing'}`
            );
        }
        functionToStart
            .promiseGenerator()
            .then(functionId => {
                this.handleFinishedFunction(functionId);
            })
            .catch(e => {
                console.error(`The function ${functionToStart.id} has caught an error`);
                const request = e ? e.request : null;
                const response = e ? e.response || e.config : null;
                const errorData: ErrorData = {
                    opportunityId: '',
                    accountId: '',
                    contactId: '',
                    subdomain: '',
                    request: {
                        response: request ? request.response : null,
                        responseText: request ? request.responseText : null,
                        responseType: request ? request.responseType : null,
                        responseURL: request ? request.responseURL : null,
                        status: request ? request.status : 0,
                        statusText: request ? request.statusText : null,
                        timeout: request ? request.timeout : 0,
                        withCredentials: request ? request.withCredentials : false
                    },
                    response: {
                        config: {
                            data: response ? (response.config ? response.config.data : null) : null,
                            headers: response
                                ? response.config
                                    ? JSON.stringify(response.config.headers)
                                    : null
                                : null,
                            method: response ? (response.config ? response.config.method : null) : null,
                            url: response ? (response.config ? response.config.url : null) : null,
                            timeout: response ? (response.config ? response.config.timeout : 0) : 0
                        },
                        data: response ? response.data : null,
                        headers: response ? JSON.stringify(response.headers) : null
                    },
                    url: window.history.state.prevUrl || e?.config.url
                };
                this.props.setErrorLoggingDataAction(errorData);
                this.beginErrorStage();
            });
    }

    render() {
        switch (this.state.routingStatus) {
            case 'displaying':
                if (this.state.preppedToDisplayPage !== this.props.currentPage) {
                    return <LoadingScreenWrapper />;
                }
                return Routes;
            case 'entering':
                return <LoadingScreenWrapper />;
            case 'exiting':
                return <LoadingScreenWrapper />;
            case 'rerouting':
                if (this.props.pageReroute && this.props.pageReroute !== this.props.currentPage) {
                    if (this.someRunningFunctionBlockingPage(this.state.runningFunctions, this.props.currentPage)) {
                        return <LoadingScreenWrapper />;
                    }
                    if (getEnvironment().logDebugInfo) {
                        console.info(
                            `The routing is redirecting from ${this.props.currentPage} to ${this.props.pageReroute}`
                        );
                    }
                    return (
                        <Fragment>
                            <Redirect to={this.props.pageReroute} />
                            <LoadingScreenWrapper />
                        </Fragment>
                    );
                }
                return <LoadingScreenWrapper />;
            case 'error':
                return <Redirect to={urlPaths.error} />;
        }
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    currentPage: string;
    previousUrlFull: string;
    pageEnterFunctions: RouterFormattedBlockingFunction[];
    pageReroute: string;
    previousPageExitFunctions: RouterFormattedBlockingFunction[];
    setErrorLoggingDataAction: (newValue: ErrorData) => void;
}

interface StateShape {
    // Shape of local state
    runningFunctions: RouterFormattedBlockingFunction[];
    preppedToDisplayPage: string;
    routingStatus: 'displaying' | 'exiting' | 'rerouting' | 'entering' | 'error';
}

export default RoutingComponent;
