import FooterComponent from './FooterComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import accountComponentSwitch from '../../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import TulipFooterWrapper from '../../../account-specific-components/accounts/tulip-cremation/footer-component/TulipFooterWrapper';
import { connect } from 'react-redux';
import { TULIP_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/tulip';
import EverdaysFooterWrapper from '../../../account-specific-components/accounts/everdays/footer-component/EverdaysFooterWrapper';
import { EVERDAYS_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/everdays';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';
import { EARTH_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/earth';
import EarthFooterWrapper from '../../../account-specific-components/accounts/earth/footer-component/EarthFooterWrapper';
import { TITAN_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/titan';
import TitanFooterWrapper from '../../../account-specific-components/accounts/titan/footer-component/TitanFooterWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const representativeId = getFormData(state, 'representative', 'id');
    // These values will be passed in as props
    return {
        phoneNumber: state.account.phone,
        stylingOptions: state.stylingOptions,
        isAgentStyling: !!representativeId,
        subdomain: state.account.subdomain,
        opportunityId: state.opportunity.id,
        accountState: state.account.state,
        facebookUrl: state.account.facebookUrl,
        instagramUrl: state.account.instagramUrl,
        twitterUrl: state.account.twitterUrl,
        yelpUrl: state.account.yelpUrl,
        address: state.account.address,
        city: state.account.city,
        funeralHomeNumber: state.account.funeralHomeNumber,
        zip: state.account.zip,
        name: state.account.name,
        mediaDomain: state.account.mediaDomain
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(FooterComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TULIP_STYLING_KEY]: TulipFooterWrapper,
    [EVERDAYS_STYLING_KEY]: EverdaysFooterWrapper,
    [EARTH_STYLING_KEY]: EarthFooterWrapper,
    [TITAN_STYLING_KEY]: TitanFooterWrapper
});
