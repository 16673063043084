import { connect } from 'react-redux';
import PurchaserOrderSummaryProductsGroupingComponent from './PurchaserOrderSummaryProductsGroupingComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { formatMonetaryAmount } from '../../../../../toolboxes/reuseable-logic/string-formatters';
import {
    getFuneralSubtotal,
    getFuneralSalesTax,
    getFuneralTotal,
    getTravelSubtotal,
    getTravelSalesTax,
    getTravelTotal
} from '../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { getDispositionText } from 'toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        arrangementProducts: state.opportunity.products,
        arrangementSubTotal: formatMonetaryAmount(getFuneralSubtotal(state).toString()),
        arrangementTax: formatMonetaryAmount(getFuneralSalesTax(state).toString()),
        arrangementTotal: formatMonetaryAmount(getFuneralTotal(state).toString()),
        disposition: getDispositionText(state, true),
        travelProtectionProducts: state.travelOpportunity.products,
        travelProtectionSubTotal: formatMonetaryAmount(getTravelSubtotal(state).toString()),
        travelProtectionTax: formatMonetaryAmount(getTravelSalesTax(state).toString()),
        travelProtectionTotal: formatMonetaryAmount(getTravelTotal(state).toString())
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryProductsGroupingComponent);
