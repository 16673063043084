import React from 'react';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import BackButtonLinkWrapper from '../../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

class FuneralDirectorStatePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountPhoneNumber: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="FuneralDirectorState"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>We're Sorry</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Due to state licensing requirements, we are unable to complete the funeral arrangement you’ve
                        planned online at this time. If you would like to explore other purchase options, please call us
                        {this.props.accountPhoneNumber
                            ? ` directly at ${formatPhoneNumber(this.props.accountPhoneNumber)}`
                            : ''}
                        .
                    </PageCardTextWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountPhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default FuneralDirectorStatePageComponent;
