import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { fetchAndStoreFuneralContractUrl } from '../../server-application-buffer/contracts-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { initiateOutgoingDataApi } from 'toolboxes/server-application-buffer/outgoing-api-triggering-data';
import { OutGoingApiEventType } from '../../../static/constants/enums/OutGoingApiEventType';

export default function loadFuneralContracts(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    let loadingFunctions = [];
    let validContractUrl = true;
    try {
        if (!state.opportunity.contractSigning.contractUrl || !state.opportunity.contractSigning.contractUrlDate) {
            validContractUrl = false;
        } else {
            const contractUrlAge =
                (new Date().getTime() - state.opportunity.contractSigning.contractUrlDate.getTime()) / 1000;
            // if the contractUrl is over 4.5 minutes, we invalidate it so get a new one (5 minute timeout on DocuSign Urls)
            if (contractUrlAge > 270) {
                validContractUrl = false;
            }
        }
    } catch {
        validContractUrl = false;
    }

    if (!validContractUrl) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    fetchAndStoreFuneralContractUrl(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }

    if (state.account.featureEnhancedWebhooks) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>(resolve => {
                    initiateOutgoingDataApi(OutGoingApiEventType.purchaserIdentity, state, dispatch, () => {
                        return resolve();
                    });
                }),
            blocksPageStarts: []
        });
    }

    return loadingFunctions;
}
