import { connect } from 'react-redux';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';
import TitanHeaderComponent from './TitanHeaderComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const representativeId = getFormData(state, 'representative', 'id');

    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions,
        isAgentStyling: !!representativeId,
        funeralHomeName: state.account.name,
        funeralHomeWebsite: state.account.website,
        funeralHomePhoneNumber: state.account.phone
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TitanHeaderComponent);
