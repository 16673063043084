import React from 'react';
import GeneralModalWrapper from '../../general-modal-component/GeneralModalWrapper';
import GeneralDisclaimerWrapper from '../../../global-components/general-disclaimer-components/general-disclaimer-component/GeneralDisclaimerWrapper';
import { PropShape } from '../../../global-components/general-disclaimer-components/general-disclaimer-component/GeneralDisclaimerComponent';
import ApplicationStyling from 'static/models/ApplicationStyling';

class DisclaimerModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraContentLength: true,
        extraCardWidth: false,
        storeOnReject: true,
        stylingOptions: undefined
    };

    render() {
        return (
            <GeneralModalWrapper
                // Can't manually close
                onRequestClose={() => {}}
            >
                <GeneralDisclaimerWrapper {...this.props}>{this.props.children}</GeneralDisclaimerWrapper>
            </GeneralModalWrapper>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default DisclaimerModalComponent;
