import Product from '../../static/models/Product';
import { addProductToList, removeProductFromList } from '../../toolboxes/reuseable-logic/product-combiner';

// Action Type
export const SET_TRAVEL_PRODUCTS = 'SET_TRAVEL_PRODUCTS';
export const ADD_TRAVEL_PRODUCT = 'ADD_TRAVEL_PRODUCT';
export const REMOVE_TRAVEL_PRODUCT = 'REMOVE_TRAVEL_PRODUCT';
// Actions
export function setTravelProductsAction(newValue: Product[]) {
    return { type: SET_TRAVEL_PRODUCTS, newValue };
}
export function addTravelProductAction(newValue: Product) {
    return { type: ADD_TRAVEL_PRODUCT, newValue };
}
export function removeTravelProductAction(newValue: Product) {
    return { type: REMOVE_TRAVEL_PRODUCT, newValue };
}
// Reducer
export function setTravelProductsReducer(state: any, action: any): Product[] {
    if (typeof state === 'undefined') {
        // Initial data
        return [];
    }
    switch (action.type) {
        case SET_TRAVEL_PRODUCTS:
            return action.newValue;
        case ADD_TRAVEL_PRODUCT:
            return addProductToList(state, action.newValue);
        case REMOVE_TRAVEL_PRODUCT:
            return removeProductFromList(state, action.newValue);
        default:
            return state;
    }
}
