import React from 'react';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class UnderageRecipientPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountPhoneNumber: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="UnderageRecipient" metaPageNumber="1" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Underage Recipient</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Unfortunately, the purchase of a pre-need arrangement for someone 17 years old &amp; under
                        currently cannot be completed online. Please call us at {this.props.accountPhoneNumber} for
                        further assistance. Thank you for understanding.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountPhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default UnderageRecipientPageComponent;
