import { connect } from 'react-redux';
import IrrevocableSelectionComponent from './IrrevocableSelectionComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setIrrevocableAction } from '../../../../../redux/yesNoAnswers/policyQuestions/irrevocable';
import getEnvironment from 'env-config/get-environment';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const fundingProviderId = state.opportunity?.selectedFundingProduct?.provider?.account?.id;
    return {
        confirmationNecessary: !state.disclaimerData.disclaimerAddressings.irrevocabilityConfirmation
            .hasBeenAcceptedOrPositivelyAddressed,
        isIrrevocable: state.yesNoAnswers.policyQuestions.irrevocable,
        isForcedIrrevocable: fundingProviderId === getEnvironment()?.accountIds?.OFDA
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIrrevocableAction: newValue => dispatch(setIrrevocableAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrevocableSelectionComponent);
