import React from 'react';
import MiniatureYesNoWrapper from '../../../../global-components/miniature-yes-no-component/MiniatureYesNoWrapper';
import { Redirect } from 'react-router';
import urlPaths from '../../../../../static/constants/enums/urlPaths';

class ReplacementCheckComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        hasCurrentPolicies: false,
        replacingCurrentPolicies: false,
        setHasCurrentPoliciesAction: () => {},
        setReplacingCurrentPoliciesAction: () => {}
    };

    render() {
        if (this.props.replacingCurrentPolicies) {
            return <Redirect to={urlPaths.unavailableReplacementState} push />;
        }
        return (
            <div>
                <MiniatureYesNoWrapper
                    title="Has Existing Policy"
                    questionText="Do you currently own any life insurance policies or annuities?"
                    answer={this.props.hasCurrentPolicies}
                    onEditClick={() => {
                        this.props.setHasCurrentPoliciesAction(null);
                    }}
                    onYesClick={() => {
                        this.props.setHasCurrentPoliciesAction(true);
                        this.props.setReplacingCurrentPoliciesAction(null);
                    }}
                    onNoClick={() => {
                        this.props.setHasCurrentPoliciesAction(false);
                        this.props.setReplacingCurrentPoliciesAction(false);
                    }}
                    yesNoId="current"
                />
                {this.props.hasCurrentPolicies === true && (
                    <MiniatureYesNoWrapper
                        title="Replace Existing Policy"
                        questionText="Will the proposed application replace any existing life insurance or annuity contracts?"
                        answer={this.props.replacingCurrentPolicies}
                        onEditClick={() => {
                            this.props.setReplacingCurrentPoliciesAction(null);
                        }}
                        onYesClick={() => {
                            this.props.setReplacingCurrentPoliciesAction(true);
                        }}
                        onNoClick={() => {
                            this.props.setReplacingCurrentPoliciesAction(false);
                        }}
                        yesNoId="replacing"
                    />
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setHasCurrentPoliciesAction: Function;
    setReplacingCurrentPoliciesAction: Function;
    hasCurrentPolicies: boolean;
    replacingCurrentPolicies: boolean;
}

interface StateShape {
    // Shape of local state
}

export default ReplacementCheckComponent;
