import React, { Fragment } from 'react';
import StripeWrapper from '../../../../../global-components/stripe/stripe-component/StripeWrapper';
import PaymentPriceCardWrapper from '../../../../../global-components/payment-price-card-component/PaymentPriceCardWrapper';
import Button from '@material-ui/core/Button';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import { Redirect } from 'react-router';
import { ReactComponent as AcceptedCardsImage } from '../../../../../../static/media/accepted-cards.svg';
import './CreditCardTrustPaymentContentComponent.css';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import { PaymentMode } from '../../../../../../static/constants/enums/paymentModes';
import PaymentTrustOptionSelectionWrapper from '../../payment-options-components/payment-trust-option-selection-component/PaymentTrustOptionSelectionWrapper';
import InstallmentPaymentTrustOptionsDisplayWrapper from '../../payment-options-components/installment-payment-trust-options-display-component/InstallmentPaymentTrustOptionsDisplayWrapper';
import YesNoWrapper from 'components/global-components/buttons/yes-no-component/YesNoWrapper';
import InfoIcon from '@material-ui/icons/Info';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import DisclaimerModalWrapper from 'components/modal-components/disclaimer-components/disclaimer-modal-component/DisclaimerModalWrapper';
import Account from 'static/models/Account';

class CreditCardTrustPaymentContentComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            paymentProcessing: false,
            showDisclaimer: false,
            redirect: false,
            error: '',
            payMonthly: undefined,
            payMonthlySelected: false,
            infoIconOpen: false
        };
        this.beginProcessingPayment = this.beginProcessingPayment.bind(this);
    }

    static defaultProps = {
        // Default prop values
        totalPrice: '',
        paymentOptions: [],
        selectedOption: null,
        setSelectedPaymentOption: () => {},
        setStripeTokenId: () => {},
        setPaymentHoldAddressingAction: () => {},
        dueToday: '',
        dueMonthly: '',
        numberOfMonthsToPay: '',
        accountName: '',
        hidePaymentHoldDisclaimer: false,
        fundingProviderAccount: null
    };

    componentDidMount() {
        if (this.props.paymentOptions.length > 1) {
            const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
            this.props.setSelectedPaymentOption(singlePayOption);
        } else {
            this.props.setSelectedPaymentOption(this.props.paymentOptions[0]);
        }
    }

    beginProcessingPayment() {
        this.setState({
            showDisclaimer: false,
            paymentProcessing: true
        });
    }

    setMonthlyTrustPayment(answer: boolean) {
        this.setState({
            payMonthly: answer,
            payMonthlySelected: true
        });
    }

    onCompletePaymentClick() {
        if (this.props.hidePaymentHoldDisclaimer) {
            // no disclaimer on Immediate sales, process immediately
            this.beginProcessingPayment();
        } else {
            // for future or imminent sales, show on hold disclaimer before processing
            this.setState({
                showDisclaimer: true
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.props.redirectUrl} push />;
        }
        const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
        const multiplePaymentOptions = this.props.paymentOptions.length > 1;

        let TotalsDisplay = null;
        if (multiplePaymentOptions) {
            TotalsDisplay = (
                <PaymentPriceCardWrapper
                    title="Total Due Today (tax included)"
                    amount={this.props.dueToday}
                    subtitle="Payment options are shown below"
                />
            );
        } else {
            const selectedPayment = this.props.paymentOptions[0];
            if (selectedPayment.paymentMode === PaymentMode.Single) {
                TotalsDisplay = (
                    <PaymentPriceCardWrapper
                        title="Total Due Today (tax included)"
                        amount={this.props.dueToday}
                        subtitle="Prepaid direct cremation package, taxes, and fees"
                    />
                );
            } else {
                TotalsDisplay = (
                    <Fragment>
                        <PaymentPriceCardWrapper
                            title="Due today"
                            amount={this.props.dueToday}
                            subtitle="This includes the first month's payment"
                        />
                        <PaymentPriceCardWrapper
                            title="Due monthly"
                            amount={`${this.props.dueMonthly} / mo`}
                            subtitle={`For ${this.props.numberOfMonthsToPay} months. Includes prepaid direct cremation package, taxes, and fees.`}
                        />
                    </Fragment>
                );
            }
        }

        let TotalPaid = null;
        let AmountDueTodayText = null;
        if (this.state.payMonthlySelected) {
            if (this.props.selectedOption.paymentMode === PaymentMode.Single) {
                TotalPaid = (
                    <div className="total-paid-text" style={{ textAlign: 'center', padding: '10px' }}>
                        total paid: {this.props.totalPrice}
                    </div>
                );
            } else {
                TotalPaid = (
                    <div className="total-paid-text" style={{ textAlign: 'center', paddingBottom: '10px' }}>
                        Total paid after {this.props.numberOfMonthsToPay + 1} months: {this.props.totalPrice}
                    </div>
                );
            }

            AmountDueTodayText = (
                <h3 className="amount-due-today-text" style={{ textAlign: 'center', paddingTop: '10px' }}>
                    The amount due today is {this.props.dueToday}
                    <InfoIcon
                        style={{ cursor: 'pointer', alignSelf: 'center', color: 'inherit' }}
                        fontSize="inherit"
                        onClick={() => {
                            this.setState({ infoIconOpen: true });
                        }}
                    />
                    {this.state.infoIconOpen && (
                        <GeneralModalWrapper onRequestClose={() => this.setState({ infoIconOpen: false })}>
                            <FullContentCenterWrapper
                                metaPageName="PaymentOptionSelection"
                                metaPageNumber="3"
                                metaPagebackAvailable="true"
                            >
                                <PageCardTitleWrapper>{this.props.dueToday} Due Today</PageCardTitleWrapper>
                                <PageCardTextWrapper>
                                    This includes any travel protection purchased, any relevant fees, and the first
                                    month's payment.
                                </PageCardTextWrapper>
                            </FullContentCenterWrapper>
                        </GeneralModalWrapper>
                    )}
                </h3>
            );
        }

        return (
            <div>
                <div className="payment-title-container">
                    <div className="payment-title">Credit Card Information</div>
                    <div>
                        <AcceptedCardsImage />
                    </div>
                </div>
                {this.state.error && (
                    <div style={{ textAlign: 'center', color: 'red', margin: '5px' }}>{this.state.error}</div>
                )}
                <StripeWrapper
                    processPayment={this.state.paymentProcessing}
                    onStripeTokenCreation={token => {
                        if (!token) {
                            this.setState({
                                paymentProcessing: false
                            });
                            return;
                        }
                        this.props.setStripeTokenId(token.id);
                        this.setState({
                            redirect: true
                        });
                    }}
                    onSubmissionError={error => {
                        this.setState({
                            paymentProcessing: false,
                            error
                        });
                    }}
                />
                {TotalsDisplay}
                {multiplePaymentOptions && (
                    <Fragment>
                        <div className="payment-monthly-select">
                            <div className="payment-monthly-title">Would you like to pay monthly?</div>
                            <YesNoWrapper
                                onYesClick={() => this.setMonthlyTrustPayment(true)}
                                onNoClick={() => this.setMonthlyTrustPayment(false)}
                                yesNoId="trustPaymentMonthly"
                                answer={this.state.payMonthly}
                            />
                        </div>
                        {this.state.payMonthly === true &&
                            this.props.paymentOptions.find((paymentOption: PaymentOption) => {
                                if (paymentOption.paymentMode === PaymentMode.Multi) {
                                    if (this.props.selectedOption.paymentMode !== PaymentMode.Multi) {
                                        this.props.setSelectedPaymentOption(paymentOption);
                                    }
                                    return true;
                                }
                                return false;
                            }) && (
                                <InstallmentPaymentTrustOptionsDisplayWrapper
                                    paymentMethod={PaymentMethod.CreditCard}
                                />
                            )}
                        {this.state.payMonthly === false &&
                            this.props.paymentOptions.find((paymentOption: PaymentOption) => {
                                if (paymentOption.paymentMode === PaymentMode.Single) {
                                    if (this.props.selectedOption.paymentMode !== PaymentMode.Single) {
                                        this.props.setSelectedPaymentOption(paymentOption);
                                    }
                                    return true;
                                }
                                return false;
                            }) && <PaymentTrustOptionSelectionWrapper paymentOption={singlePayOption} />}
                        {this.props.selectedOption.paymentMode !== PaymentMode.Single && AmountDueTodayText}
                        {TotalPaid}
                    </Fragment>
                )}
                <Button
                    id="button-complete-payment"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    disabled={
                        this.state.paymentProcessing || (multiplePaymentOptions && !this.state.payMonthlySelected)
                    }
                    onClick={() => this.onCompletePaymentClick()}
                >
                    {!this.state.paymentProcessing && 'Complete Payment'}
                    {this.state.paymentProcessing && 'Working...'}
                </Button>
                {this.state.showDisclaimer && (
                    <DisclaimerModalWrapper
                        onAccept={() => this.beginProcessingPayment()}
                        onReject={() => this.setState({ showDisclaimer: false })}
                        acceptanceText="Accept"
                        rejectionText="Decline"
                        disclaimerTitle="Payment Hold"
                        saveDisclaimerAddressingToRedux={newValue => {
                            this.props.setPaymentHoldAddressingAction(newValue);
                        }}
                        storeOnReject={true}
                    >
                        <div>
                            As a part of the credit card validation and payment authorization process, there will be a{' '}
                            <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>temporary</span> hold placed on
                            the credit card. This hold will show up in your bank account as "pending" for{' '}
                            <span style={{ fontWeight: 'bold' }}>{this.props.fundingProviderAccount?.name}</span> (the
                            trust company) until {this.props.accountName} has signed the contract. Once completed, the
                            "pending" charge will go through for the authorized amount based on the payment option
                            you've selected.
                        </div>
                        <br />
                        <div>
                            If you choose not to continue arranging and/or do not sign the contract, the "pending"
                            charge will automatically drop off your bank account within 14 days (may vary depending on
                            card issuer/bank).
                        </div>
                    </DisclaimerModalWrapper>
                )}
            </div>
        );
    }
}

interface PropShape extends React.Props<any> {
    paymentOptions: PaymentOption[];
    setStripeTokenId: Function;
    setSelectedPaymentOption: Function;
    setPaymentHoldAddressingAction: Function;
    totalPrice: string;
    redirectUrl: string;
    dueToday: string;
    dueMonthly: string;
    numberOfMonthsToPay: string;
    selectedOption: PaymentOption;
    accountName: string;
    hidePaymentHoldDisclaimer: boolean;
    fundingProviderAccount: Account;
}

interface StateShape {
    // Shape of local state
    paymentProcessing: boolean;
    showDisclaimer: boolean;
    redirect: boolean;
    error: string;
    payMonthly: boolean;
    payMonthlySelected: boolean;
    infoIconOpen: boolean;
}

export default CreditCardTrustPaymentContentComponent;
