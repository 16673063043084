import { TextAlignProperty } from 'csstype';
import efuneralStyling from '../../../static/constants/styling-company-constants/companies/efuneral';

// Action Type
export const SET_STYLING_BUTTON_FONT_SIZE = 'SET_STYLING_BUTTON_FONT_SIZE';
// Action
export function setButtonFontSizeAction(newValue: string) {
    return { type: SET_STYLING_BUTTON_FONT_SIZE, newValue };
}
// Reducer
export function setButtonFontSizeReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STYLING_BUTTON_FONT_SIZE:
            return action.newValue;
        default:
            return state;
    }
}
