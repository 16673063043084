import React, { Fragment } from 'react';
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { defaultErrorBorder } from '../../../static/styling/default-theme';

interface SearchOption {
    display: string;
    value: string;
}

const customFilterOptions = options => options;

class SearchBarComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            currentText: '',
            searching: false,
            options: [],
            searchesStacked: 0,
            searchEncounteredError: false,
            touched: false
        };
    }

    static defaultProps = {
        // Default prop values
        selectedOptionValid: false,
        placeHolder: '',
        msBetweenSearches: 500,
        label: '',
        hasError: false,
        touched: false
    };

    handleSearchTimeUp() {
        this.setState({ searchesStacked: this.state.searchesStacked - 1 });
        if (this.state.searchesStacked === 0) {
            this.props
                .searchPromiseGenerator(this.state.currentText)
                .then(result => {
                    // Needed in case they start typing in the middle of the search promise
                    if (this.state.searchesStacked === 0) {
                        this.setState({
                            options: result,
                            searching: false
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        searchEncounteredError: true
                    });
                });
        }
    }

    handleChange(newValue: string) {
        const selectedOption = this.state.options.find(option => option.display === newValue);
        if (selectedOption) {
            return this.props.onChange(selectedOption.value);
        }
        this.props.onChange(null);
        this.setState({
            searchEncounteredError: false,
            searching: true,
            currentText: newValue,
            searchesStacked: this.state.searchesStacked + 1
        });
        setTimeout(() => this.handleSearchTimeUp(), this.props.msBetweenSearches);
    }

    handleSelect(newValue: string) {
        this.props.onChange(newValue);
    }

    handleNumericSelect(newValue: string) {
        const selectedOption = this.state.options.find(option => option.display === newValue);
        if (selectedOption) {
            return this.props.onChange(selectedOption.value);
        }
    }

    render() {
        return (
            <AutoComplete
                id={`input-${this.props.id}`}
                filterOptions={customFilterOptions}
                style={{
                    marginBottom: '16px'
                }}
                onChange={(event, value, reason) => {
                    this.setState({
                        touched: true
                    });
                    if (reason === 'select-option') {
                        this.handleNumericSelect(value);
                    }
                }}
                options={this.state.searching ? [] : this.state.options.map(option => option.display)}
                loading={this.state.searching}
                autoComplete
                renderOption={option => {
                    return <span id={`option-${option}`}>{option}</span>;
                }}
                renderInput={params => {
                    let calculatedStyle;
                    if ((this.props.touched || this.state.touched) && this.props.hasError) {
                        calculatedStyle = {
                            border: defaultErrorBorder,
                            paddingTop: '13px',
                            paddingBottom: '12px'
                        };
                    } else {
                        calculatedStyle = {
                            paddingTop: '13px',
                            paddingBottom: '12px'
                        };
                    }
                    return (
                        <Fragment>
                            <label>{this.props.label}</label>
                            <TextField
                                {...params}
                                // Messy, it's to make sure that the padding is set correctly visually
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    style: calculatedStyle
                                }}
                                onChange={event => this.handleChange(event.target.value)}
                                placeholder={this.props.placeHolder}
                            />
                        </Fragment>
                    );
                }}
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    searchPromiseGenerator: (searched: string) => Promise<SearchOption[]>;
    onChange: (selectedValue: any) => void;
    selectedOptionValid: boolean;
    id: string;
    placeHolder: string;
    label: string;
    msBetweenSearches: number;
    hasError: boolean;
    touched: boolean;
}

interface StateShape {
    // Shape of local state
    currentText: string;
    searching: boolean;
    options: SearchOption[];
    searchesStacked: number;
    searchEncounteredError: boolean;
    touched: boolean;
}

export default SearchBarComponent;
