import { connect } from 'react-redux';
import SelfOrOtherSelectionComponent from './SelfOrOtherSelectionComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setOpportunityRecipientSameAsPurchaserAction } from '../../../../redux/opportunity/recipient/sameAsPurchaser';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        planningForSelf: state.opportunity.recipient.sameAsPurchaser
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setOpportunityRecipientSameAsPurchaserAction: newValue =>
            dispatch(setOpportunityRecipientSameAsPurchaserAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfOrOtherSelectionComponent);
