import { combineReducers } from 'redux';
import { setPaymentPlanHealthAddressingReducer } from './paymentPlanHealthImpact';
import { setIrrevocabilityAddressingReducer } from './irrevocabilityConfirmation';
import { setPAnnuityInsuranceddressingReducer } from './annuityInsurance';
import { setSinglePaySettingAddressingReducer } from './singlePaySetting';
import { setRecipientConsentReducer } from './recipientConsent';
import { setRecipientUnavailalbeRatesDisclaimerReducer } from './recipientUnavailableRates';
import { setStateFilingFeeReducer } from './stateFilingFee';
import { setPriceListDisclaimerAddressingReducer } from './priceList';
import { setVerifyPurchaserIdentityReducer } from './verifyPurchaserIdentity';
import { setTrustFundedprearrangementReducer } from './trustFundedPrearrangement';
import { setPurchaserRecipientSamePersonReducer } from './purchaserRecipientSamePerson';
import { setBeneficiaryRecipientSamePersonReducer } from './beneficiaryRecipientSamePerson';
import { setPaymentHoldAddressingReducer } from './paymentHold';
import { setRecipientPlaidIdentityConsentDisclaimerAddressingReducer } from './recipientPlaidIdentityConsent';
import { setPurchaserPlaidIdentityConsentDisclaimerAddressingReducer } from './purchaserPlaidIdentityConsent';

export default combineReducers({
    paymentPlanHealthImpact: setPaymentPlanHealthAddressingReducer,
    irrevocabilityConfirmation: setIrrevocabilityAddressingReducer,
    annuityInsurance: setPAnnuityInsuranceddressingReducer,
    singlePaySetting: setSinglePaySettingAddressingReducer,
    recipientConsent: setRecipientConsentReducer,
    recipientUnavailableRates: setRecipientUnavailalbeRatesDisclaimerReducer,
    stateFilingFee: setStateFilingFeeReducer,
    priceList: setPriceListDisclaimerAddressingReducer,
    verifyPurchaserIdentity: setVerifyPurchaserIdentityReducer,
    trustFundedPrearrangement: setTrustFundedprearrangementReducer,
    purchaserRecipientSamePerson: setPurchaserRecipientSamePersonReducer,
    beneficiaryRecipientSamePerson: setBeneficiaryRecipientSamePersonReducer,
    paymentHold: setPaymentHoldAddressingReducer,
    recipientPlaidIdentityConsent: setRecipientPlaidIdentityConsentDisclaimerAddressingReducer,
    purchaserPlaidIdentityConsent: setPurchaserPlaidIdentityConsentDisclaimerAddressingReducer
});
