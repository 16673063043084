import GlobalStateShape from 'redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { PaymentMethod } from 'static/constants/enums/paymentMethods';
import urlPaths from 'static/constants/enums/urlPaths';
import { connect } from 'react-redux';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import EverdaysPaymentPageWrapper from 'components/account-specific-components/accounts/everdays/payment-page-component/EverdaysPaymentPageWrapper';
import PaymentPageTrustComponent from './PaymentPageTrustComponent';
import { setPurchaserWillEnterAction } from 'redux/opportunity/pricing/purchaserWillEnter';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';

const getNextUrl = (state: GlobalStateShape, isAgent: boolean): urlPaths => {
    if (isAtNeed(state)) {
        return urlPaths.purchaseProcessing;
    }
    if (isAgent) {
        return urlPaths.representativeIdentity;
    }
    return urlPaths.ssnCapture;
};

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const paymentMethodsAvailable: PaymentMethod[] = [];
    let isAgent: boolean;

    isAgent = !!representativeId;
    if (!state.opportunity.pricing.isPredetermined) {
        state.opportunity.pricing.paymentOptions.availableOptions.forEach(option => {
            if (!paymentMethodsAvailable.includes(option.paymentMethod)) {
                paymentMethodsAvailable.push(option.paymentMethod);
            }
        });
    } else {
        paymentMethodsAvailable.push(state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod);
    }
    return {
        paymentMethodsAvailable,
        startingError: state.opportunity.pricing.creditCardInfo.cardRejected
            ? 'Card did not sucessfully process. Please retry, or use another card.'
            : '',
        isAgent: isAgent,
        nextUrl: getNextUrl(state, isAgent)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPurchaserWillEnterAction: newValue => dispatch(setPurchaserWillEnterAction(newValue))
    };
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(PaymentPageTrustComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EVERDAYS_STYLING_KEY]: EverdaysPaymentPageWrapper
});
