import React, { Fragment } from 'react';
import PersonDemographicsFormWrapper from '../person-demographics-form-component/PersonDemographicsFormWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { valueDisplaysFromEnum } from '../../../toolboxes/reuseable-logic/value-displays';
import { BeneficiaryRelationships } from '../../../static/constants/enums/relationships';
import { validateBeneficiary } from '../../../toolboxes/redux-form/redux-form-validation';
import ApplicationStyling from 'static/models/ApplicationStyling';

class BeneficiaryFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined
    };

    render() {
        return (
            <Fragment>
                <PersonDemographicsFormWrapper
                    canCopyPurchaserInfo={false}
                    formName="beneficiary"
                    validate={validateBeneficiary}
                />
                <div
                    style={{
                        marginTop: this.props.stylingOptions?.spacing?.innerBody
                            ? `-${this.props.stylingOptions?.spacing?.innerBody}`
                            : ''
                    }}
                >
                    <FormWrapper formName="beneficiary" validate={validateBeneficiary}>
                        <SelectWrapper
                            formName={'beneficiary'}
                            fieldPath={'relationshipToRecipient'}
                            valueDisplays={valueDisplaysFromEnum(BeneficiaryRelationships)}
                            labelString={'Relationship to Recipient'}
                            ariaLabel="relationship to recipient"
                            spacing={{
                                sm: 12
                            }}
                        />
                    </FormWrapper>
                </div>
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default BeneficiaryFormComponent;
