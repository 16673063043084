import ValueDisplay from '../../../static/models/ValueDisplay';
import { productCreationProductTypes } from './product-creation-subcategory-list';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { GeographicState } from '../../../static/constants/enums/states';

export function calculateAvailableProductCreationTypes(state: GlobalStateShape): ValueDisplay[] {
    const category = getFormData(state, 'productCreation', 'category');
    return productCreationProductTypes
        .filter(productCreationSubcategory => {
            const stateApplicable =
                state.account.state === GeographicState.TX
                    ? productCreationSubcategory.texasApplicable
                    : productCreationSubcategory.nonTexasApplicable;
            return productCreationSubcategory.category === category && stateApplicable;
        })
        .map(productCreationSubcategory => {
            return {
                value: productCreationSubcategory.goodsAndServicesValue,
                display: productCreationSubcategory.goodsAndServicesDisplay
            };
        });
}

export function findSelectedProductCreationType(goodsAndServicesValue: string) {
    return productCreationProductTypes.find(productCreationSubcategory => {
        return productCreationSubcategory.goodsAndServicesValue === goodsAndServicesValue;
    });
}
