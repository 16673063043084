import { combineReducers } from 'redux';
import { setSelectedPaymentOptionReducer } from './selectedOption';
import { setAvailablePaymentOptionsReducer } from './availableOptions';
import { setPaymentOptionsUpToDateReducer } from './upToDate';

export default combineReducers({
    selectedOption: setSelectedPaymentOptionReducer,
    availableOptions: setAvailablePaymentOptionsReducer,
    upToDate: setPaymentOptionsUpToDateReducer
});
