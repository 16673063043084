import Product from 'static/models/Product';

export function formatDate(date: string): string {
    const dateData = new Date(date);
    dateData.setMinutes(dateData.getMinutes() + dateData.getTimezoneOffset());
    return `${dateData.getUTCMonth() + 1}/${dateData.getUTCDate()}/${dateData.getUTCFullYear()}`;
}

export function formatPhoneNumber(phoneNumber: string): string {
    try {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return '(' + match[1] + ')\u00A0' + match[2] + '\u2011' + match[3];
    } catch {
        return phoneNumber;
    }
}

export function formatHiddenSSN(ssn: string): string {
    try {
        const cleaned = ssn.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        return `XXX-XX-${match[3]}`;
    } catch {
        return 'XXX-XX-XXXX';
    }
}

export function formatMonetaryAmount(monetaryAmount: string | number, formatWithCommas = false): string {
    try {
        if (formatWithCommas) {
            let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
            return formatter.format(Number(monetaryAmount));
        } else {
            let stringifiedNumber = monetaryAmount.toString();
            const cleaned = stringifiedNumber.replace(/[^\d.-]/g, '');
            const numericAmount = parseFloat(cleaned);
            const displayValue = `$${numericAmount.toFixed(2)}`;
            return displayValue;
        }
    } catch {
        return `$${monetaryAmount}`;
    }
}

export function formatRemoveNonNumeric(value: string): string {
    return value.replace(/\D/g, '');
}

export function getFormattedPriceForProduct(product: Product, formatWithCommas = false): string {
    if (product.included) {
        return 'Included';
    }

    let totalPrice = product.price * product.quantity;
    return formatMonetaryAmount(totalPrice, formatWithCommas);
}

export function addSpaceBetweenWords(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').trim();
}

export function getMaskedCreditCardLastFour(value: string): string {
    return `************${value}`;
}
