import React from 'react';
import { PropShape as FooterPropShape } from '../../../../global-components/limb-components/footer-component/FooterComponent';
import './EverdaysFooterComponent.css';

class EverdaysFooterComponent extends React.Component<FooterPropShape, StateShape> {
    constructor(props: FooterPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        phoneNumber: '',
        stylingOptions: undefined,
        subdomain: '',
        opportunityId: '',
        accountState: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        yelpUrl: '',
        address: '',
        city: '',
        funeralHomeNumber: '',
        zip: '',
        name: '',
        mediaDomain: ''
    };

    render() {
        return <div className="everdays-footer"></div>;
    }
}

interface StateShape {
    // Shape of local state
}

export default EverdaysFooterComponent;
