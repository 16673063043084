import Environment from '../static/models/Environment';
import { testEnvironment } from './environment-configs/test';
import { demoEnvironment } from './environment-configs/demo';
import { prodEnvironment } from './environment-configs/prod';
import activeEnvironmentImport from './active-environment';

export default function getEnvironment(): Environment {
    const activeEnvironment: string = activeEnvironmentImport;
    switch (activeEnvironment) {
        case 'testing':
            return process.env.REACT_APP_USE_LOCAL_BACKEND === 'true'
                ? { ...testEnvironment, efuneralGatewayBaseUrl: 'http://localhost:5001', tulipBaseUrl: '' }
                : testEnvironment;
        case 'demo':
            return demoEnvironment;
        case 'production':
            return prodEnvironment;
        default:
            throw new Error(`Environment configuration not found: ${activeEnvironment}`);
    }
}
