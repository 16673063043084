import React from 'react';
import 'react-tabs/style/react-tabs.css';
import './PaymentPageComponent.css';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import { PaymentMethod } from 'static/constants/enums/paymentMethods';
import BackButtonLinkWrapper from 'components/global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import Button from '@material-ui/core/Button';
import CreditCardTrustPaymentContentWrapper from './page-specific-components/payment-content-components/credit-card-trust-payment-content-component/CreditCardTrustPaymentContentWrapper';
import CheckTrustPaymentContentWrapper from './page-specific-components/payment-content-components/check-trust-payment-content-component/CheckTrustPaymentContentWrapper';

class PaymentPageTrustComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            errorMessage: props.startingError,
            useCC: this.props.paymentMethodsAvailable.includes(PaymentMethod.CreditCard)
        };
    }

    static defaultProps = {
        // Default prop values
        pricingData: '',
        importanceTheme: {},
        startingError: '',
        paymentMethodsAvailable: [],
        isAgent: false,
        setPurchaserWillEnterAction: undefined,
        nextUrl: undefined
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="Payment" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Payment Details</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please provide the following payment details to finalize the arrangement.
                    </PageCardTextWrapper>
                    {this.props.isAgent && (
                        <ButtonLinkWrapper
                            to={this.props.nextUrl}
                            buttonId="skip-payment"
                            color="secondary"
                            navigationSideAffect={() => this.props.setPurchaserWillEnterAction(true)}
                        >
                            Purchaser Will Enter Payment Information
                        </ButtonLinkWrapper>
                    )}
                    {this.state.errorMessage && <div className="errorMessage">{this.state.errorMessage}</div>}

                    {this.props.paymentMethodsAvailable.length > 1 && (
                        <Button
                            id="button-payment-switch"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                this.setState({ useCC: !this.state.useCC });
                            }}
                            style={{
                                marginBottom: '20px'
                            }}
                        >
                            {this.state.useCC && 'Pay with ACH'}
                            {!this.state.useCC && 'Pay with Credit Card'}
                        </Button>
                    )}
                    {this.state.useCC && <CreditCardTrustPaymentContentWrapper redirectUrl={this.props.nextUrl} />}
                    {!this.state.useCC && <CheckTrustPaymentContentWrapper nextUrl={this.props.nextUrl} />}

                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)\
    startingError: string;
    paymentMethodsAvailable: PaymentMethod[];
    isAgent: boolean;
    setPurchaserWillEnterAction: Function;
    nextUrl: urlPaths;
}

interface StateShape {
    // Shape of local state
    errorMessage: string;
    useCC: boolean;
}

export default PaymentPageTrustComponent;
