import GlobalStateShape from '../../redux/GlobalStateShape';
import { OpportunityStage } from '../../static/constants/opportunityStages';

export const contractsAreComplete = (state: GlobalStateShape): boolean => {
    // DEV2020-1478 - purchaserSigned replaces the AgentSignContract stage
    if (state.featureFlags.useNewOpportunityStages) {
        return state.opportunity.purchaserSigned;
    }
    return [OpportunityStage.AgentSignContract].includes(state.opportunity.activeStage);
};
