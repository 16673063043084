import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_VERIFY_PURCHASER_IDENTITY = 'SET_VERIFY_PURCHASER_IDENTITY';
// Action
export function setVerifyPurchaserIdentityAction(newValue: DisclaimerAddressing) {
    return { type: SET_VERIFY_PURCHASER_IDENTITY, newValue };
}
// Reducer
export function setVerifyPurchaserIdentityReducer(state: any, action: any): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_VERIFY_PURCHASER_IDENTITY:
            return action.newValue;
        default:
            return state;
    }
}
