import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { fetchAndStoreOpportunityStatusData } from '../../server-application-buffer/loaded-opportunity-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { fetchAndStoreAllContractSigningCredentials } from '../../server-application-buffer/contract-signing-credentials-data';

const opportunityStatus = (state: GlobalStateShape, dispatch: Function, params: any): BlockingFunction[] => {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStoreOpportunityStatusData(state, dispatch, params.id, params.key, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.opportunityStatus]
    });

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStoreAllContractSigningCredentials(dispatch, params.id, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.opportunityStatus]
    });

    return functionsToRun;
};

export default opportunityStatus;
