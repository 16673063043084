import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
import { earthStyling } from 'static/constants/styling-company-constants/companies/earth';
import ApplicationStyling from 'static/models/ApplicationStyling';
import EarthOrderSummaryCardWrapper from './page-specific-components/earth-order-summary-card-component/EarthOrderSummaryCardWrapper';

class EarthOrderSummaryPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        purchaser: {
            name: '',
            phoneNumber: '',
            address: '',
            email: '',
            gender: '',
            dateOfBirth: '',
            relationshipToRecipient: ''
        },
        recipient: {
            name: '',
            phoneNumber: '',
            address: '',
            email: '',
            gender: '',
            dateOfBirth: '',
            sameAsPurchaser: true
        },
        beneficiary: {
            paidToEstate: true,
            paidToPurchaser: false,
            name: '',
            phoneNumber: '',
            address: '',
            email: '',
            gender: '',
            dateOfBirth: '',
            relationshipToRecipient: ''
        },
        irevocability: false,
        stylingOptions: earthStyling
    };

    render() {
        // Figure out what beneficiary rows should show
        let beneficiaryRows: string[] = [];
        if (this.props.beneficiary.paidToPurchaser) {
            beneficiaryRows = [`Paid to ${this.props.purchaser.name}`];
        } else if (this.props.beneficiary.paidToEstate) {
            beneficiaryRows = ['Paid to Estate'];
        } else {
            if (this.props.recipient.sameAsPurchaser) {
                beneficiaryRows = [
                    this.props.beneficiary.name,
                    this.props.beneficiary.phoneNumber,
                    this.props.beneficiary.address,
                    this.props.beneficiary.email,
                    this.props.beneficiary.gender,
                    this.props.beneficiary.dateOfBirth,
                    `${this.props.beneficiary.relationshipToRecipient} to ${this.props.purchaser.name}`
                ];
            } else {
                beneficiaryRows = [
                    this.props.beneficiary.name,
                    this.props.beneficiary.phoneNumber,
                    this.props.beneficiary.address,
                    this.props.beneficiary.email,
                    this.props.beneficiary.gender,
                    this.props.beneficiary.dateOfBirth,
                    `${this.props.beneficiary.relationshipToRecipient} to ${this.props.recipient.name}`
                ];
            }
        }
        // Render cards
        return (
            <FullContentCenterWrapper metaPageName="Summary" metaPageNumber={undefined} metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Review</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please carefully review the information that you’ve already provided. This is the information
                        that we’ll use to generate your legal documents.
                    </PageCardTextWrapper>
                    <EarthOrderSummaryCardWrapper
                        title="Purchaser"
                        rowsContent={[
                            this.props.purchaser.name,
                            this.props.purchaser.phoneNumber,
                            this.props.purchaser.address,
                            this.props.purchaser.email,
                            this.props.purchaser.gender,
                            this.props.purchaser.dateOfBirth,
                            this.props.recipient.sameAsPurchaser
                                ? ''
                                : `${this.props.purchaser.relationshipToRecipient} to ${this.props.recipient.name}`
                        ]}
                    />
                    {!this.props.recipient.sameAsPurchaser && (
                        <EarthOrderSummaryCardWrapper
                            title="Recipient"
                            rowsContent={[
                                this.props.recipient.name,
                                this.props.recipient.phoneNumber,
                                this.props.recipient.address,
                                this.props.recipient.email,
                                this.props.recipient.gender,
                                this.props.recipient.dateOfBirth
                            ]}
                        />
                    )}
                    <EarthOrderSummaryCardWrapper title="Beneficiary" rowsContent={beneficiaryRows} />
                    <EarthOrderSummaryCardWrapper
                        title="Irrevocability"
                        rowsContent={[this.props.irevocability ? 'Yes' : 'No']}
                    />
                    {/* <PageCardWrapper> */}
                    <div>
                        <ButtonLinkWrapper to={urlPaths.payment} buttonId="continue">
                            Confirm Information
                        </ButtonLinkWrapper>
                    </div>
                    {/* </PageCardWrapper> */}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    purchaser: {
        name: string;
        phoneNumber: string;
        address: string;
        email: string;
        gender: string;
        dateOfBirth: string;
        relationshipToRecipient: string;
    };
    recipient: {
        name: string;
        phoneNumber: string;
        address: string;
        email: string;
        gender: string;
        dateOfBirth: string;
        sameAsPurchaser: boolean;
    };
    beneficiary: {
        paidToEstate: boolean;
        paidToPurchaser: boolean;
        name: string;
        phoneNumber: string;
        address: string;
        email: string;
        gender: string;
        dateOfBirth: string;
        relationshipToRecipient: string;
    };
    irevocability: boolean;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default EarthOrderSummaryPageComponent;
