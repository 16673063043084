import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment } from 'react';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import { Field } from 'redux-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ApplicationStyling from 'static/models/ApplicationStyling';

const renderField = (reduxFieldData: any) => {
    let shouldShowError = reduxFieldData.meta.touched && reduxFieldData.meta.invalid && !reduxFieldData.meta.active;
    let shouldShowErrorMessage = shouldShowError && reduxFieldData.showErrorMessage;

    if (reduxFieldData.showPassword && reduxFieldData.formattingFunction) {
        reduxFieldData.input.value = reduxFieldData.formattingFunction(reduxFieldData.input.value);
    }

    return (
        <Fragment>
            {shouldShowErrorMessage && <Alert severity="error">{reduxFieldData.meta.error}</Alert>}
            <Input
                error={shouldShowError}
                {...reduxFieldData.input}
                {...reduxFieldData.extraFieldProps}
                style={{
                    ...reduxFieldData.input.style,
                    backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                    color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor,
                    marginBottom: reduxFieldData.stylingOptions?.spacing?.innerBody
                }}
                placeholder={reduxFieldData.placeholder}
                type={reduxFieldData.showPassword ? 'text' : 'password'}
                id={`input-${reduxFieldData.id}`}
                fullWidth={true}
                inputProps={{
                    'aria-label': reduxFieldData.ariaLabel
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            area-label="toggle password visibility"
                            tabIndex={-1}
                            onClick={reduxFieldData.handleClickShowPassword}
                        >
                            {reduxFieldData.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Fragment>
    );
};

class PasswordBoxComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            showPassword: false,
            infoIconOpen: false
        };
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        placeholder: '',
        formName: '',
        spacing: defaultSpacing,
        labelString: '',
        ariaLabel: '',
        normalize: null,
        formattingFunction: null,
        showErrorMessage: false,
        accountName: '',
        trustText: '',
        stylingOptions: undefined
    };
    render() {
        return (
            <Grid item {...this.props.spacing}>
                <div
                    style={{
                        marginRight: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : '',
                        marginLeft: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : ''
                    }}
                >
                    <label>{this.props.labelString} </label>

                    {this.props.accountName !== '' && (
                        <InfoIcon
                            style={{ cursor: 'pointer', alignSelf: 'center', color: 'inherit' }}
                            fontSize="inherit"
                            onClick={() => {
                                this.setState({ infoIconOpen: true });
                            }}
                        />
                    )}

                    <Field
                        id={`${this.props.formName}.${this.props.fieldPath}`}
                        name={this.props.fieldPath}
                        component={renderField}
                        placeholder={this.props.placeholder}
                        extraFieldProps={this.props.extraFieldProps}
                        {...this.props.extraFieldProps}
                        normalize={this.props.normalize}
                        showPassword={this.state.showPassword}
                        handleClickShowPassword={this.handleClickShowPassword}
                        formattingFunction={this.props.formattingFunction}
                        showErrorMessage={this.props.showErrorMessage}
                        ariaLabel={this.props.ariaLabel}
                        stylingOptions={this.props.stylingOptions}
                    />

                    {this.state.infoIconOpen && (
                        <GeneralModalWrapper onRequestClose={() => this.setState({ infoIconOpen: false })}>
                            <FullContentCenterWrapper metaPageName="" metaPageNumber="" metaPagebackAvailable="">
                                <PageCardTitleWrapper>We need this for a few reasons.</PageCardTitleWrapper>
                                <PageCardTextWrapper>
                                    1. So that we are able to validate it against your name to prove you are indeed who
                                    you say that you are.
                                </PageCardTextWrapper>
                                <PageCardTextWrapper>
                                    2. So that we are in compliance with {this.props.trustText} regulations.
                                    <br />
                                </PageCardTextWrapper>
                                <PageCardTextWrapper>
                                    <br />
                                    {this.props.accountName} and eFuneral Solutions LLC. will not disclose an
                                    individual's SSN without consent of the individual to anyone outside of{' '}
                                    {this.props.accountName} and EFS except as mandated by law.
                                </PageCardTextWrapper>
                            </FullContentCenterWrapper>
                        </GeneralModalWrapper>
                    )}
                </div>
            </Grid>
        );
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    placeholder: string;
    extraFieldProps: Record<string, any>;
    spacing: object;
    labelString: string;
    ariaLabel: string;
    normalize: Function;
    formattingFunction: Function;
    showErrorMessage: boolean;
    accountName: string;
    trustText: string;
    stylingOptions: ApplicationStyling;
}

export interface StateShape {
    // Shape of local state
    showPassword: boolean;
    infoIconOpen: boolean;
}

export default PasswordBoxComponent;
