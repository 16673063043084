import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_RECIPIENT_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING =
    'SET_RECIPIENT_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING';
// Action
export function setRecipientPlaidIdentityConsentDisclaimerAddressingAction(newValue: DisclaimerAddressing) {
    return { type: SET_RECIPIENT_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING, newValue };
}
// Reducer
export function setRecipientPlaidIdentityConsentDisclaimerAddressingReducer(
    state: any,
    action: any
): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_RECIPIENT_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
