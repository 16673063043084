import { connect } from 'react-redux';
import MilitaryVitalFormComponent from './MilitaryVitalFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        isMilitaryVeteran: getFormData(state, 'militaryVital', 'isMilitaryVeteran')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MilitaryVitalFormComponent);
