import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { FilingFeeData } from '../../../static/constants/stateSpecificLists/filingFeeData';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { Timing } from '../../../static/constants/enums/timing';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import replacementStates from 'static/constants/stateSpecificLists/replacementStates';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { showReplacementCalculator } from 'toolboxes/reuseable-logic/show-replacement-calculator';

export function orderSummaryReroutes(state: GlobalStateShape) {
    if (
        FilingFeeData[state.account.state] &&
        !state.disclaimerData.disclaimerAddressings.stateFilingFee.hasBeenAcceptedOrPositivelyAddressed &&
        state.opportunity.timing !== Timing.IMMEDIATE &&
        !state.opportunity.hideStateFilingFee &&
        !state.account?.featureDisableCreditCardPayments
    ) {
        return urlPaths.stateFilingFee;
    }

    if (!state.disclaimerData.disclaimerAddressings.priceList.hasBeenAcceptedOrPositivelyAddressed) {
        // Doesn't matter to agent
        if (!getFormData(state, 'representative', 'id') && !state.opportunity.hidePriceListDisclaimer) {
            return urlPaths.middlePriceListDisclaimer;
        }
    }
    if (state.account.stylingKey === EARTH_STYLING_KEY) {
        // Earth's flow is different than everyone else's
        if (![true, false].includes(state.yesNoAnswers.policyQuestions.irrevocable)) {
            return urlPaths.irrevocable;
        }
        if (showReplacementCalculator(state)) {
            if (![true, false].includes(state.yesNoAnswers.policyQuestions.hasCurrentPolicies)) {
                return urlPaths.replacement;
            }
            if (![true, false].includes(state.yesNoAnswers.policyQuestions.replacingCurrentPolicies)) {
                return urlPaths.replacement;
            }
        }
        if (!state.opportunity.pricing.paymentInitiated) {
            return urlPaths.payment;
        }
    }
}
