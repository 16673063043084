import React from 'react';
import DisclaimerAddressing from 'static/models/disclaimers/DisclaimerAddressing';
import MiniDisclaimerAcceptanceWrapper from 'components/global-components/mini-disclaimer-acceptance-components/general-disclaimer-component/MiniDisclaimerAcceptanceWrapper';
/* eslint-disable */
class IrrevocableForcedComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setIrrevocabilityAddressingAction: () => {},
        setIrrevocableAction: () => {},
        irrevocabilityPositivelyAddressed: false
    };

    render() {
        return (
            <MiniDisclaimerAcceptanceWrapper
                acceptanceText={'I Agree'}
                onAccept={() => this.props.setIrrevocableAction(true)}
                disableCheckbox={this.props.irrevocabilityPositivelyAddressed}
                checked={this.props.irrevocabilityPositivelyAddressed}
                saveDisclaimerAddressingToRedux={this.props.setIrrevocabilityAddressingAction}
            >
                <div className="forced-irrevocable-title">Irrevocability </div>
                <div className="forced-irrevocable-paragraph">
                    To keep your funds secure, they will be placed into the OFDA Master Trust. This is an irrevocable
                    trust which means once you complete the purchase, you will not have the ability to receive a refund
                    or cancel your purchase. The funds will be held until payment for services provided by the funeral
                    home are needed.
                </div>
                <div className="forced-irrevocable-paragraph">
                    By continuing, you understand that this purchase is irrevocable.
                </div>
            </MiniDisclaimerAcceptanceWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setIrrevocabilityAddressingAction: (addressing: DisclaimerAddressing) => void;
    setIrrevocableAction: Function;
    irrevocabilityPositivelyAddressed: boolean;
}

interface StateShape {
    // Shape of local state
}

export default IrrevocableForcedComponent;
