import React from 'react';
import PurchaserFormWrapper from '../../form-components/purchaser-form-component/PurchaserFormWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class PurchaserPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        purchaserComplete: false,
        isAgent: false,
        firstPage: false,
        validationFunction: p => {},
        isAtNeed: false
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="Confirm Details"
                metaPageNumber="1"
                metaPagebackAvailable="true"
                metaFirstPage={this.props.firstPage.toString()}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Purchaser Information</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        {this.props.isAgent
                            ? `Please enter the information for the applicant of the ${this.props.policyContractLanguage}`
                            : `Please provide your details below. As the Purchaser, you will be signing the application and 
                                are the only person who can make changes to the arrangement up until time of need. The purchaser 
                                does not need to be the name associated to the method of payment.`}
                    </PageCardTextWrapper>
                    <PurchaserFormWrapper
                        isAtNeed={this.props.isAtNeed}
                        validationFunction={this.props.validationFunction}
                    />
                    <ButtonLinkWrapper
                        disabled={!this.props.purchaserComplete}
                        to={urlPaths.recipientCheck}
                        buttonId="Continue"
                    >
                        Continue
                    </ButtonLinkWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    purchaserComplete: boolean;
    isAgent: boolean;
    firstPage: boolean;
    validationFunction: (personData: any) => any;
    isAtNeed: boolean;
    policyContractLanguage: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserPageComponent;
