import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_TITLE_FONT_SIZE = 'SET_STYLING_TITLE_FONT_SIZE';
// Action
export function setTitleFontSizeAction(newValue: string) {
    return { type: SET_STYLING_TITLE_FONT_SIZE, newValue };
}
// Reducer
export function setTitleFontSizeReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().fonts.titleFontSize;
    }
    switch (action.type) {
        case SET_STYLING_TITLE_FONT_SIZE:
            return action.newValue;
        default:
            return state;
    }
}
