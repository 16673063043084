import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class PrefilledRecipientGratitudePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        purchaserFirstName: 'The purchaser'
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PrefilledRecipientGratitude"
                metaPageNumber="2"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Thank You</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Thank you for taking the time to answer these questions.&nbsp;
                        {this.props.purchaserFirstName} may now continue with the purchase.
                    </PageCardTextWrapper>
                    <ButtonLinkWrapper to={urlPaths.purchaserOrderSummary} buttonId="continue">
                        Continue
                    </ButtonLinkWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    purchaserFirstName: string;
}

interface StateShape {
    // Shape of local state
}

export default PrefilledRecipientGratitudePageComponent;
