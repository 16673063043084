import React from 'react';
import { Grid } from '@material-ui/core';
import { formatMonetaryAmount } from 'toolboxes/reuseable-logic/string-formatters';
import ProductCardWrapper from './product-card-component/ProductCardWrapper';

class ProductInformationComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        products: [],
        travelProducts: [],
        total: 0,
        subtotal: 0,
        salestax: 0
    };

    render() {
        return (
            <Grid container spacing={2}>
                {this.props.products.map((product, i) => (
                    <Grid item key={i} sm={6}>
                        <ProductCardWrapper product={product} />
                    </Grid>
                ))}
                {this.props.travelProducts.map((product, i) => (
                    <Grid item key={i} sm={6}>
                        <ProductCardWrapper product={product} />
                    </Grid>
                ))}
                <Grid container item sm={6}>
                    <Grid container item>
                        <div style={{ fontWeight: 'bold', fontSize: 'large' }}>Total</div>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item>Subtotal</Grid>
                        <Grid item>{formatMonetaryAmount(this.props.subtotal)}</Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item>Sales Tax</Grid>
                        <Grid item>{formatMonetaryAmount(this.props.salestax)}</Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item>Total</Grid>
                        <Grid item>{formatMonetaryAmount(this.props.total)}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    products: any;
    travelProducts: any;
    total: number;
    subtotal: number;
    salestax: number;
}

interface StateShape {
    // Shape of local state
}

export default ProductInformationComponent;
