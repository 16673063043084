import React from 'react';
import { Container, Grid, Hidden, Icon, Link } from '@material-ui/core';
import getEnvironment from '../../../../../env-config/get-environment';
import ApplicationStyling from '../../../../../static/models/ApplicationStyling';
import './TitanFooterComponent.css';
import { titanStyling } from '../../../../../static/constants/styling-company-constants/companies/titan';
import TitanFooterWhiteLogo from '../../../../../static/media/titan-white.png';
import TitanFacebookLogo from '../../../../../static/media/titan-social/titan-facebook.svg';
import TitanInstagramLogo from '../../../../../static/media/titan-social/titan-instagram.svg';
import TitanTwitterLogo from '../../../../../static/media/titan-social/titan-twitter.svg';
import TitanEmailLogo from '../../../../../static/media/titan-social/titan-email.svg';

class TitanFooterComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        phoneNumber: '',
        stylingOptions: titanStyling,
        isAgentStyling: false,
        subdomain: '',
        opportunityId: '',
        accountState: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        yelpUrl: '',
        address: '',
        city: '',
        funeralHomeNumber: '',
        zip: '',
        name: '',
        mediaDomain: '',
        notificationEmail: ''
    };

    render() {
        const showTexasDisclaimer = this.props.accountState.toLowerCase() === 'tx';
        return (
            <footer
                className="ef-footer-wrapper"
                style={{
                    borderTop: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    borderBottom: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    backgroundColor: this.props.stylingOptions.colors.footerBackgroundColor
                }}
            >
                <Container>
                    <Grid container className="ef-footer" direction="row">
                        <Grid item xs={12} sm={6}>
                            <Hidden only="xs">
                                <div className="ef-footer-left">
                                    <div className="ef-footer-links">
                                        <div className="ef-footer-social-links">
                                            {this.props.facebookUrl && (
                                                <a
                                                    className="facebook-image-footer"
                                                    href={this.props.facebookUrl}
                                                    target="_blank"
                                                    title="Facebook"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan facebook" src={TitanFacebookLogo} height="36px" />
                                                </a>
                                            )}
                                            {this.props.instagramUrl && (
                                                <a
                                                    className="instagram-image-footer"
                                                    href={this.props.instagramUrl}
                                                    target="_blank"
                                                    title="Instagram"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan instagram" src={TitanInstagramLogo} height="36px" />
                                                </a>
                                            )}
                                            {this.props.twitterUrl && (
                                                <a
                                                    className="twitter-image-footer"
                                                    href={this.props.twitterUrl}
                                                    target="_blank"
                                                    title="Twitter"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan twitter" src={TitanTwitterLogo} height="36px" />
                                                </a>
                                            )}
                                            <a
                                                className="email-image-footer"
                                                target="_blank"
                                                href={`mailto:${this.props.notificationEmail}`}
                                                title="Email"
                                                rel="noopener noreferrer"
                                            >
                                                <img alt="titan email" src={TitanEmailLogo} height="36px" />
                                            </a>
                                        </div>
                                        {this.props.subdomain && (
                                            <div
                                                className="ef-footer-internal-links"
                                                style={{ color: this.props.stylingOptions.colors.footerFontColor }}
                                            >
                                                <Link
                                                    className="ef-footer-privacy"
                                                    href="https://www.efuneral.com/privacy/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Privacy Policy
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-terms"
                                                    href="https://www.efuneral.com/terms-of-use/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Terms of Use
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-gpl"
                                                    href={`${getEnvironment().efuneralMediaBucket}/pricelists/${
                                                        this.props.subdomain
                                                    }.pdf`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    General Price List (GPL)
                                                </Link>
                                                {showTexasDisclaimer && (
                                                    <p className="ef-footer-texas-disclaimer">
                                                        Texas consumers: Visit{' '}
                                                        <Link
                                                            href="https://www.prepaidfunerals.texas.gov"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            color="inherit"
                                                        >
                                                            www.prepaidfunerals.texas.gov
                                                        </Link>{' '}
                                                        for more information.
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Hidden>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Hidden only="xs">
                                <div className="ef-footer-right">
                                    <div className="ef-footer-fh">
                                        <img alt="titan logo" src={TitanFooterWhiteLogo} height="36px" />
                                    </div>
                                </div>
                            </Hidden>
                        </Grid>

                        <Grid item xs={12}>
                            <Hidden smUp>
                                <div className="ef-footer-moblie">
                                    <div className="ef-footer-links">
                                        <div className="ef-footer-social-links">
                                            {this.props.facebookUrl && this.props.facebookUrl !== '' && (
                                                <a
                                                    className="facebook-image-footer"
                                                    href={this.props.facebookUrl}
                                                    target="_blank"
                                                    title="Facebook"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan facebook" src={TitanFacebookLogo} height="36px" />
                                                </a>
                                            )}
                                            {this.props.instagramUrl && this.props.instagramUrl !== '' && (
                                                <a
                                                    className="instagram-image-footer"
                                                    href={this.props.instagramUrl}
                                                    target="_blank"
                                                    title="Instagram"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan instagram" src={TitanInstagramLogo} height="36px" />
                                                </a>
                                            )}
                                            {this.props.twitterUrl && this.props.twitterUrl !== '' && (
                                                <a
                                                    className="twitter-image-footer"
                                                    href={this.props.twitterUrl}
                                                    target="_blank"
                                                    title="Twitter"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img alt="titan twitter" src={TitanTwitterLogo} height="36px" />
                                                </a>
                                            )}
                                            <a
                                                className="email-image-footer"
                                                target="_blank"
                                                href={`mailto:${this.props.notificationEmail}`}
                                                title="Email"
                                                rel="noopener noreferrer"
                                            >
                                                <img alt="titan email" src={TitanEmailLogo} height="36px" />
                                            </a>
                                        </div>
                                        {this.props.subdomain && this.props.subdomain !== '' && (
                                            <div
                                                className="ef-footer-internal-links"
                                                style={{ color: this.props.stylingOptions.colors.footerFontColor }}
                                            >
                                                <Link
                                                    className="ef-footer-privacy"
                                                    href="https://www.efuneral.com/privacy/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Privacy Policy
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-terms"
                                                    href="https://www.efuneral.com/terms-of-use/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Terms of Use
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-gpl"
                                                    href={`${getEnvironment().efuneralMediaBucket}/pricelists/${
                                                        this.props.subdomain
                                                    }.pdf`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    General Price List (GPL)
                                                </Link>
                                                {showTexasDisclaimer && (
                                                    <p className="ef-footer-texas-disclaimer-mobile">
                                                        Texas consumers: Visit{' '}
                                                        <Link
                                                            href="https://www.prepaidfunerals.texas.gov"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            color="inherit"
                                                        >
                                                            www.prepaidfunerals.texas.gov
                                                        </Link>{' '}
                                                        for more information.
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {this.props.name && this.props.name !== '' && (
                                    <div
                                        className="ef-footer-fh-address-mobile"
                                        style={{
                                            textAlign: 'center',
                                            color: this.props.stylingOptions.colors.footerFontColor
                                        }}
                                    >
                                        <div className="ef-footer-right">
                                            <div className="ef-footer-fh">
                                                <img alt="titan logo" src={TitanFooterWhiteLogo} height="36px" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Hidden>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    phoneNumber: string;
    stylingOptions: ApplicationStyling;
    isAgentStyling: boolean;
    subdomain: string;
    opportunityId: string;
    accountState: string;
    facebookUrl: string;
    instagramUrl: string;
    twitterUrl: string;
    yelpUrl: string;
    address: string;
    city: string;
    funeralHomeNumber: string;
    zip: string;
    name: string;
    mediaDomain: string;
    notificationEmail: string;
}

interface StateShape {
    // Shape of local state
}

export default TitanFooterComponent;
