// Action Type
export const SET_ACCOUNT_EMAIL_SENT_FROM_EFUNERAL = 'SET_ACCOUNT_EMAIL_SENT_FROM_EFUNERAL';
// Action
export function setAccountEmailSentFromEfuneralAction(newValue: boolean) {
    return { type: SET_ACCOUNT_EMAIL_SENT_FROM_EFUNERAL, newValue };
}
// Reducer
export function setAccountEmailSentFromEfuneralReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_ACCOUNT_EMAIL_SENT_FROM_EFUNERAL:
            return action.newValue;
        default:
            return state;
    }
}
