import { connect } from 'react-redux';
import PurchaseProcessingNavigationPageComponent, { PropShape } from './PurchaseProcessingNavigationPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape): PropShape => {
    // These values will be passed in as props
    return {
        nextUrl: state.opportunity.endOfExperienceReturnLink
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseProcessingNavigationPageComponent);
