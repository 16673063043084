// Action Type
export const SET_OPPORTUNITY_PURCHASER_PHONE_NUMBER = 'SET_OPPORTUNITY_PURCHASER_PHONE_NUMBER';
// Action
export function setOpportunityPurchaserPhoneNumberAction(newValue: string) {
    return { type: SET_OPPORTUNITY_PURCHASER_PHONE_NUMBER, newValue };
}
// Reducer
export function setOpportunityPurchaserPhoneNumberReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_OPPORTUNITY_PURCHASER_PHONE_NUMBER:
            return action.newValue;
        default:
            return state;
    }
}
