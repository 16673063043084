import React from 'react';
import PageCardUnderlinedSectionWrapper from '../../../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';

class PurchaserOrderSummaryPaymentSummaryComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        cardLastFour: '',
        cardType: ''
    };

    render() {
        if (!this.props.cardLastFour) {
            return null;
        }
        return (
            <PageCardUnderlinedSectionWrapper
                sectionTitle="Payment Information"
                sectionId="payment-information"
                fullContent={
                    <div className="purchaser-order-summary-split-div">
                        <div>{this.props.cardType}</div>
                        <div>**** **** **** {this.props.cardLastFour}</div>
                    </div>
                }
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    cardLastFour: string;
    cardType: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryPaymentSummaryComponent;
