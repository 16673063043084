import React from 'react';

class EmptyComponent extends React.Component<MyPropShape, StateShape> {
    constructor(props: MyPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return null;
    }
}

export interface MyPropShape {}

interface StateShape {
    // Shape of local state
}

export default EmptyComponent;
