import React, { Fragment } from 'react';
import './RecipientFormComponent.css';
import PersonDemographicsFormWrapper from '../person-demographics-form-component/PersonDemographicsFormWrapper';
import PersonSsnFormWrapper from '../person-ssn-form-component/PersonSsnFormWrapper';
import ApplicationStyling from 'static/models/ApplicationStyling';
import DisabledField from 'static/models/DisabledField';

class RecipientFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
        this.handleAutofillChange = this.handleAutofillChange.bind(this);
    }

    static defaultProps = {
        autoFillSSN: () => {},
        stylingOptions: undefined
    };

    handleAutofillChange() {
        this.props.autoFillSSN();
    }

    render() {
        return (
            <Fragment>
                <PersonDemographicsFormWrapper
                    canCopyPurchaserInfo={true}
                    formName="recipient"
                    validate={this.props.validationFunction}
                    hideBirthdate={this.props.isAtNeed}
                    hideGender={this.props.isAtNeed}
                    hideEmail={this.props.isAtNeed}
                    hidePhone={this.props.isAtNeed}
                    onAutofillChange={this.handleAutofillChange}
                    disableFields={this.props.disableFields}
                />
                {!this.props.isAtNeed && !this.props.hideSSN && (
                    <div
                        style={{
                            marginTop: this.props.stylingOptions?.spacing?.innerBody
                                ? `-${this.props.stylingOptions?.spacing?.innerBody}`
                                : ''
                        }}
                    >
                        <PersonSsnFormWrapper formName="recipient" validate={this.props.validationFunction} />
                    </div>
                )}
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    validationFunction: (p: any) => any;
    isAtNeed: boolean;
    autoFillSSN: Function;
    stylingOptions: ApplicationStyling;
    disableFields?: DisabledField[];
    hideSSN?: boolean;
}

interface StateShape {
    // Shape of local state
}

export default RecipientFormComponent;
