import React from 'react';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import { validatePhoneVerificationRequest } from '../../../toolboxes/redux-form/redux-form-validation';
import RadioGroupWrapper from '../base-form-components/radio-group-component/RadioGroupWrapper';

class PhoneVerificationRequestSecurityFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            caseCreated: false
        };
    }

    static defaultProps = {
        // Default prop values
        hideSms: false,
        hideCall: false,
        createCase: null,
        phoneNumber: '',
        createCaseFeatureFlag: true
    };

    render() {
        let displayText = `Purchaser's Phone Number`;

        if (this.props.phoneNumber) {
            displayText = `***-***-**${this.props.phoneNumber.slice(-2)}`;
        }

        if (this.props.hideSms && !this.props.hideCall) {
            return (
                <FormWrapper formName={this.props.formName} validate={validatePhoneVerificationRequest}>
                    <RadioGroupWrapper
                        formName={this.props.formName}
                        fieldPath="method"
                        valueDisplays={[
                            {
                                value: 'call',
                                display: `Call ${displayText}`
                            }
                        ]}
                        extraFieldProps={{
                            disabled: false
                        }}
                    />
                </FormWrapper>
            );
        }

        if (!this.props.hideSms && this.props.hideCall) {
            return (
                <FormWrapper formName={this.props.formName} validate={validatePhoneVerificationRequest}>
                    <RadioGroupWrapper
                        formName={this.props.formName}
                        fieldPath="method"
                        valueDisplays={[
                            {
                                value: 'sms',
                                display: `Text ${displayText}`
                            }
                        ]}
                        extraFieldProps={{
                            disabled: false
                        }}
                    />
                </FormWrapper>
            );
        }

        if (this.props.hideSms && this.props.hideCall) {
            if (!this.state.caseCreated && this.props.createCaseFeatureFlag) {
                this.props.createCase();
                this.setState({ caseCreated: true });
            }
            return null;
        }

        return (
            <FormWrapper formName={this.props.formName} validate={validatePhoneVerificationRequest}>
                <RadioGroupWrapper
                    formName={this.props.formName}
                    fieldPath="method"
                    valueDisplays={[
                        {
                            value: 'sms',
                            display: `Text ${displayText}`
                        },
                        {
                            value: 'call',
                            display: `Call ${displayText}`
                        }
                    ]}
                    extraFieldProps={{
                        disabled: false
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    formName: string;
    hideSms: boolean;
    hideCall: boolean;
    phoneNumber: string;
    createCase: Function;
    createCaseFeatureFlag: boolean;
}

interface StateShape {
    // Shape of local state
    caseCreated: boolean;
}

export default PhoneVerificationRequestSecurityFormComponent;
