import React from 'react';
import GeneralModalWrapper from '../general-modal-component/GeneralModalWrapper';
import Product from '../../../static/models/Product';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import ProductCreationFormWrapper from '../../form-components/product-creation-form/ProductCreationFormWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import { Button } from '@material-ui/core';
import LoadingScreenWrapper from '../../global-components/loading-screen-component/LoadingScreenWrapper';

class ProductCreationModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            creatingProduct: false
        };
    }

    static defaultProps = {
        // Default prop values
        translatedProduct: null,
        formValid: false
    };

    render() {
        if (this.state.creatingProduct) {
            return (
                <GeneralModalWrapper onRequestClose={() => {}}>
                    <LoadingScreenWrapper />
                </GeneralModalWrapper>
            );
        }
        return (
            <GeneralModalWrapper onRequestClose={this.props.onRequestClose}>
                <PageCardWrapper>
                    <PageCardTitleWrapper>Add Product Form</PageCardTitleWrapper>
                    <ProductCreationFormWrapper />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.props.createProduct(this.props.translatedProduct);
                            this.setState({
                                creatingProduct: true
                            });
                        }}
                        disabled={!this.props.formValid}
                        id="button-submit-product-creation"
                    >
                        Submit
                    </Button>
                </PageCardWrapper>
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    createProduct: (product: Product) => void;
    translatedProduct: Product;
    onRequestClose: () => void;
    formValid: boolean;
}

interface StateShape {
    // Shape of local state
    creatingProduct: boolean;
}

export default ProductCreationModalComponent;
