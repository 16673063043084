import React from 'react';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import { Divider, Grid } from '@material-ui/core';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import LockIcon from '@material-ui/icons/Lock';
import StandardDisclaimerPageWrapper from 'components/page-components/disclaimer-pages/standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import DisclaimerAddressing from 'static/models/disclaimers/DisclaimerAddressing';

class IdentityConfirmationFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <>
                <StandardDisclaimerPageWrapper
                    acceptanceText="Continue"
                    rejectionText=""
                    disclaimerTitle=""
                    saveDisclaimerAddressingToRedux={this.props.disclaimerAddressingLocaiton}
                    acceptTo={this.props.nextUrl}
                    rejectTo=""
                    allowBack={false}
                    pageName="Plaid Identity"
                    pageBack="false"
                    metaPageName=""
                    pageNumber=""
                    addressingContactId={this.props.contactId}
                >
                    <PageCardTextWrapper>
                        <h2 style={{ textAlign: 'center', fontWeight: 'normal' }}>
                            To continue, we need to{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                verify {this.props.contactFirstName ? this.props.contactFirstName + "'s" : 'your'}{' '}
                                identity
                            </span>
                        </h2>
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <OfflineBoltIcon fontSize="inherit" />
                            </Grid>
                            <Grid item xs={11}>
                                <div style={{ fontWeight: 'bold' }}>Fast and secure</div>
                                <div>Verification usually takes less than a few minutes and is encrypted</div>
                            </Grid>
                        </Grid>
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <LockIcon fontSize="inherit" />
                            </Grid>
                            <Grid item xs={11}>
                                <div style={{ fontWeight: 'bold' }}>How we verify you</div>
                                <div>
                                    To learn how our service provider uses data you provide and device data, see their{' '}
                                    <a
                                        href="https://cognitohq.com/privacy-statement"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="#0000FF"
                                    >
                                        Privacy Statement
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </PageCardTextWrapper>
                    <Divider style={{ height: '2px', minWidth: '100%' }} />
                    <PageCardTextWrapper>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            By selecting "Continue", you agree to the{' '}
                            <a href="https://www.efuneral.com/privacy" target="_blank" rel="noreferrer" color="#0000FF">
                                eFuneral Privacy Policy
                            </a>
                        </div>
                    </PageCardTextWrapper>
                </StandardDisclaimerPageWrapper>
            </>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    nextUrl: string;
    contactFirstName: string;
    contactId: string;
    disclaimerAddressingLocaiton: (disclaimerAddressing: DisclaimerAddressing) => void;
}

interface StateShape {
    // Shape of local state
}

export default IdentityConfirmationFormComponent;
