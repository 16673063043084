export function ABARoutingNumberIsValid(routingNumber: string) {
    if (!routingNumber) {
        return false;
    }

    // Check for 9 digit number
    const routingRegex = /^\d{9}$/;
    if (!routingRegex.test(routingNumber)) {
        return false;
    }

    // The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
    // https://en.wikipedia.org/wiki/Routing_transit_number
    const firstTwo = parseInt(routingNumber.substring(0, 2));
    const firstTwoValid =
        (0 <= firstTwo && firstTwo <= 12) ||
        (21 <= firstTwo && firstTwo <= 32) ||
        (61 <= firstTwo && firstTwo <= 72) ||
        firstTwo === 80;
    if (!firstTwoValid) {
        return false;
    }

    // This is the checksum
    // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
    const weights = [3, 7, 1];
    let sum = 0;
    for (let i = 0; i < 8; i++) {
        sum += parseInt(routingNumber[i]) * weights[i % 3];
    }

    return (10 - (sum % 10)) % 10 === parseInt(routingNumber[8]);
}
