import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import urlPaths from 'static/constants/enums/urlPaths';
import { sendIdentityFailedEmail } from 'toolboxes/server-application-buffer/contact-data';
import { setStopPageDetailAction } from 'redux/opportunity/stopPageDetail';
import {
    fetchAndStoreFuneralContractUrl,
    fetchAndStoreTravelContractUrl
} from '../../server-application-buffer/contracts-data';
import { initiateOutgoingDataApi } from 'toolboxes/server-application-buffer/outgoing-api-triggering-data';
import { OutGoingApiEventType } from '../../../static/constants/enums/OutGoingApiEventType';

const sendIdentityFailureEmail = (state: GlobalStateShape, dispatch: Function): BlockingFunction[] => {
    const loadingFunctions = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendIdentityFailedEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        if (result) {
                            dispatch(setStopPageDetailAction(result));
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.unavailableIdentityFailed]
        }
    ];

    if (state.account.featureEnhancedWebhooks) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>(resolve => {
                    initiateOutgoingDataApi(OutGoingApiEventType.identityFailed, state, dispatch, () => {
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.unavailableIdentityFailed]
        });
    }

    const alternativeID =
        state.disclaimerData.disclaimerAddressings.verifyPurchaserIdentity.hasBeenAcceptedOrPositivelyAddressed;

    if (alternativeID && state.featureFlags.alternateIdContractSigningCredentials) {
        if (!state.opportunity.contractSigning.contractUrl) {
            loadingFunctions.push({
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        fetchAndStoreFuneralContractUrl(state, dispatch, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            resolve();
                        });
                    }),
                blocksPageStarts: [urlPaths.funeralContracts]
            });
        }

        if (!state.travelOpportunity.contractUrl && state.travelOpportunity.id) {
            loadingFunctions.push({
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        fetchAndStoreTravelContractUrl(state, dispatch, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            resolve();
                        });
                    }),
                blocksPageStarts: [urlPaths.travelContracts]
            });
        }
    }
    return loadingFunctions;
};

export default sendIdentityFailureEmail;
