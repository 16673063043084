import React from 'react';
import './LoadingScreenComponent.css';
import PageCardTitleWrapper from '../page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from '../page-card-components/full-content-center-component/FullContentCenterWrapper';

class LoadingScreenComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="Loading" metaPageNumber="" metaPagebackAvailable="false">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <PageCardTitleWrapper>Loading...</PageCardTitleWrapper>
                </div>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default LoadingScreenComponent;
