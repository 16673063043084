import { connect } from 'react-redux';
import DisabledField from 'static/models/DisabledField';
import { validateBeneficiary } from 'toolboxes/redux-form/redux-form-validation';
import {
    getPurchaserValidationFunction,
    getRecipientValidationFunction
} from 'toolboxes/reuseable-logic/demographics-utils';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import GlobalStateShape from '../../../../../../../redux/GlobalStateShape';
import EarthOrderSummaryCardComponent from './EarthOrderSummaryCardComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const purchaserValidation = getPurchaserValidationFunction(state);
    const recipientValidation = getRecipientValidationFunction(state);

    const disabledReason = `This field is disabled for editing. 
    Modifying this field could impact the payment rates that have been calculated. 
    If you would like to modify this field, please contact ${state.account?.phone} or start over.`;

    let disabledPurchaserFields: DisabledField[] = [];
    let disabledRecipientFields: DisabledField[] = [];

    disabledRecipientFields.push({ field: 'birthdate', disabledReason });
    disabledPurchaserFields.push({ field: 'state', disabledReason });

    if (state.opportunity?.recipient?.sameAsPurchaser) {
        disabledPurchaserFields.push({ field: 'birthdate', disabledReason });
    }

    return {
        stylingData: state.stylingOptions,
        purchaserValidationFunction: purchaserValidation,
        recipientValidationFunction: recipientValidation,
        beneficiaryValidationFunction: validateBeneficiary,
        isAtNeed: isAtNeed(state),
        disabledPurchaserFields: disabledPurchaserFields,
        disabledRecipientFields: disabledRecipientFields
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EarthOrderSummaryCardComponent);
