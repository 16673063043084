import React from 'react';
import { Redirect } from 'react-router';

class LoadOpportunityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };

        if (this.props.firstPage === '') {
            this.props.setFirstPageAction(this.props.pageToNavigateTo);
        }
    }

    static defaultProps = {
        // Default prop values
        pageToNavigateTo: '',
        firstPage: '',
        setFirstPageAction: () => {}
    };

    render() {
        return (
            <div>
                <Redirect to={this.props.pageToNavigateTo} />
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    pageToNavigateTo: string;
    firstPage: string;
    setFirstPageAction: Function;
}

interface StateShape {
    // Shape of local state
}

export default LoadOpportunityPageComponent;
