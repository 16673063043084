// Action Type
export const SET_PHONE_VERIFICATION_REQUESTED_PURCHASER = 'SET_PHONE_VERIFICATION_REQUESTED_PURCHASER';
// Action
export function setPurchaserPhoneVerificationRequestedAction(newValue: boolean) {
    return { type: SET_PHONE_VERIFICATION_REQUESTED_PURCHASER, newValue };
}
// Reducer
export function setPurchaserPhoneVerificationRequestedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }

    switch (action.type) {
        case SET_PHONE_VERIFICATION_REQUESTED_PURCHASER:
            return action.newValue;
        default:
            return state;
    }
}
