import React from 'react';
import { Field } from 'redux-form';
import ValueDisplay from '../../../../static/models/ValueDisplay';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import ApplicationStyling from 'static/models/ApplicationStyling';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { earthStyling } from 'static/constants/styling-company-constants/companies/earth';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { InputAdornment } from '@material-ui/core';
import InfoPopupComponent from '../info-popup-component/InfoPopupComponent';

const renderField = (reduxFieldData: any) => {
    let shouldShowError = reduxFieldData.meta.touched && reduxFieldData.meta.invalid && !reduxFieldData.meta.active;

    let endAdornment = null;
    if (reduxFieldData.extraFieldProps?.disabledreason) {
        endAdornment = (
            <>
                <InputAdornment position="end">
                    <InfoPopupComponent
                        infoModalTitle="Why can I not edit this?"
                        infoModalContent={reduxFieldData.extraFieldProps?.disabledreason}
                    />
                </InputAdornment>
            </>
        );
    }

    return (
        <Select
            native
            error={shouldShowError}
            {...reduxFieldData.input}
            {...reduxFieldData.extraFieldProps}
            type={reduxFieldData.type}
            id={`select-${reduxFieldData.formName}.${reduxFieldData.fieldPath}`}
            inputProps={{
                name: reduxFieldData.placeHolder,
                'aria-label': reduxFieldData.ariaLabel
            }}
            fullWidth={true}
            style={{
                backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                fontFamily: reduxFieldData.stylingOptions?.fonts?.bodyFontFamily,
                color:
                    reduxFieldData.input.value || reduxFieldData.stylingOptions?.colors?.placeholderFullOpacity
                        ? reduxFieldData.stylingOptions?.colors?.formFieldsTextColor
                        : 'rgb(179, 179, 179)',
                marginBottom: reduxFieldData.stylingOptions?.spacing?.innerBody
            }}
            IconComponent={reduxFieldData.openArrowIcon ? ExpandMoreIcon : ArrowDropDownIcon}
            endAdornment={endAdornment}
        >
            <option
                disabled={true}
                hidden={true}
                key={`${reduxFieldData.formName}.${reduxFieldData.fieldPath}.`}
                id={`option-${reduxFieldData.formName}.${reduxFieldData.fieldPath}.`}
                value=""
                style={{
                    backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                    fontFamily: reduxFieldData.stylingOptions?.fonts?.bodyFontFamily,
                    color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor
                }}
            >
                {reduxFieldData.placeholder}
            </option>
            {reduxFieldData.valueDisplays.map((valueDisplay: ValueDisplay) => {
                return (
                    <option
                        key={`${reduxFieldData.formName}.${reduxFieldData.fieldPath}.${valueDisplay.display.replace(
                            ' ',
                            ''
                        )}`}
                        id={`option-${reduxFieldData.formName}.
                            ${reduxFieldData.fieldPath}.${valueDisplay.display.replace(' ', '')}`}
                        value={valueDisplay.value}
                        style={{
                            backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                            fontFamily: reduxFieldData.stylingOptions?.fonts?.bodyFontFamily,
                            color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor
                        }}
                    >
                        {valueDisplay.display}
                    </option>
                );
            })}
        </Select>
    );
};

class SelectComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraFieldProps: {},
        spacing: defaultSpacing,
        labelString: '',
        normalize: null,
        ariaLabel: '',
        useLabelAsPlaceholder: false,
        stylingOptions: undefined,
        openArrowIcon: false,
        disabled: false
    };

    render() {
        const { ...passthroughProps } = { ...this.props };
        return (
            <Grid item {...this.props.spacing}>
                <div
                    style={{
                        marginRight: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : '',
                        marginLeft: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : ''
                    }}
                >
                    {!this.props.useLabelAsPlaceholder && (
                        <label htmlFor={`select-${this.props.formName}.${this.props.fieldPath}`}>
                            {this.props.labelString}
                        </label>
                    )}
                    <Field
                        placeholder={this.props.useLabelAsPlaceholder ? this.props.labelString : ''}
                        id={`${this.props.formName}.${this.props.fieldPath}`}
                        name={this.props.fieldPath}
                        component={renderField}
                        type="select"
                        valueDisplays={this.props.valueDisplays}
                        extraFieldProps={this.props.extraFieldProps}
                        {...this.props.extraFieldProps}
                        {...passthroughProps}
                        normalize={this.props.normalize}
                        ariaLabel={this.props.ariaLabel}
                        stylingOptions={this.props.stylingOptions}
                        openArrowIcon={this.props.openArrowIcon}
                    />
                </div>
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    valueDisplays: ValueDisplay[];
    formName: string;
    fieldPath: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
    ariaLabel: string;
    normalize: Function;
    useLabelAsPlaceholder: boolean;
    stylingOptions: ApplicationStyling;
    openArrowIcon: boolean;
    disabled: boolean;
}

interface StateShape {
    // Shape of local state
}

export default SelectComponent;
