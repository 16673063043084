import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

const representativeIdentityReroutes = (state: GlobalStateShape): string => {
    if (!state.opportunity.pricing.paymentInitiated && !state.opportunity.pricing.purchaserWillEnter) {
        return urlPaths.payment;
    }
    if (state.featureFlags.resprentativeAccessCode) {
        return urlPaths.representativeComplete;
    }
};

export default representativeIdentityReroutes;
