import React from 'react';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import PhoneVerifyFormWrapper from '../../form-components/phone-verify-form-component/PhoneVerifyFormWrapper';
import MockLinkRedirectionWrapper from '../../global-components/buttons/mock-link-redirection-component/MockLinkRedirectionWrapper';
import { Redirect } from 'react-router';

class PurchaserPhoneVerifyPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formIsValid: false,
        phoneVerified: false,
        timesPhoneVerificationSubmitted: 0,
        reachedStopPage: false
    };

    render() {
        if (this.props.reachedStopPage) {
            return <Redirect to={urlPaths.unavailableIdentityFailed} push />;
        }
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaserPhoneVerify"
                metaPageNumber="4"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Enter the Verification Code</PageCardTitleWrapper>
                    <PageCardTextWrapper>A verification code was just sent to your phone.</PageCardTextWrapper>
                    {this.props.timesPhoneVerificationSubmitted > 0 && (
                        <PageCardTitleWrapper>Invalid Code</PageCardTitleWrapper>
                    )}
                    <PhoneVerifyFormWrapper formName="purchaserPhoneVerification" />
                    <ButtonLinkWrapper
                        to={urlPaths.funeralContracts}
                        buttonId="submit"
                        disabled={!this.props.formIsValid}
                    >
                        Verify
                    </ButtonLinkWrapper>
                    <PageCardTextWrapper>
                        <small>
                            <MockLinkRedirectionWrapper
                                to={urlPaths.purchaserPhoneVerificationRequest}
                                linkId="resend-code"
                            >
                                Click here if you did not receive a code
                            </MockLinkRedirectionWrapper>
                        </small>
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formIsValid: boolean;
    phoneVerified: boolean;
    timesPhoneVerificationSubmitted: number;
    reachedStopPage: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserPhoneVerifyPageComponent;
