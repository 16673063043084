import React from 'react';
import { Redirect } from 'react-router';
import { isMobile } from '../../../toolboxes/reuseable-logic/mobile-detection';
import Button from '@material-ui/core/Button';
import FullContentCenterWrapper from '../page-card-components/full-content-center-component/FullContentCenterWrapper';
import { getBasePath } from 'toolboxes/reuseable-logic/get-base-path';

class DocusignIframeComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            redirectUrl: '',
            isMobile: isMobile(),
            isOpen: !isMobile()
        };
        this.iframeLoaded = this.iframeLoaded.bind(this);
    }

    static defaultProps = {
        // Default prop values
        onGoFull: () => {}
    };

    shouldComponentUpdate(nextProps: PropShape, nextState: StateShape) {
        return nextState !== this.state;
    }

    iframeLoaded() {
        try {
            // @ts-ignore
            const location = document.getElementById('contract-window').contentWindow.location;
            const basePath = getBasePath();
            const origin = `${window.location.origin}${basePath}`;

            if (location.href && location.href.startsWith(window.location.origin)) {
                this.setState({
                    redirectUrl: location.href.substring(origin.length, location.href.length)
                });
            }
        } catch {
            // We're not allowed to look at location: must be cross origin and we don't care about it
        }
    }

    openIframe() {
        this.setState({
            isOpen: true
        });
        this.props.onGoFull();
    }

    render() {
        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl} push />;
        }
        if (this.state.isMobile) {
            if (this.state.isOpen) {
                return (
                    <iframe
                        title="docusign-iframe"
                        src={this.props.contractsUrl}
                        onLoad={() => this.iframeLoaded()}
                        id="contract-window"
                        style={{
                            width: '100%',
                            height: '80vh'
                        }}
                    />
                );
            } else {
                return (
                    <FullContentCenterWrapper metaPageName="" metaPageNumber="5" metaPagebackAvailable="false">
                        <Button
                            style={{ marginLeft: '20px', marginRight: '20px' }}
                            onClick={() => this.openIframe()}
                            variant="contained"
                            color="primary"
                            id="fill-out-forms"
                        >
                            Fill out forms
                        </Button>
                    </FullContentCenterWrapper>
                );
            }
        } else {
            return (
                <iframe
                    title="docusign-iframe"
                    src={this.props.contractsUrl}
                    onLoad={() => this.iframeLoaded()}
                    id="contract-window"
                    style={{
                        minHeight: '600px',
                        marginLeft: '10%',
                        marginRight: '10%',
                        marginBottom: '30px'
                    }}
                />
            );
        }
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    contractsUrl: string;
    onGoFull: Function;
}

interface StateShape {
    // Shape of local state
    redirectUrl: string;
    isMobile: boolean;
    isOpen: boolean;
}

export default DocusignIframeComponent;
