// Action Type
export const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
// Action
export function setFirstPageAction(newValue: string) {
    return { type: SET_FIRST_PAGE, newValue };
}
// Reducer
export function setFirstPageReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_FIRST_PAGE:
            return action.newValue;
        default:
            return state;
    }
}
