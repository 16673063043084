import { combineReducers } from 'redux';
import { setFuneralContractUrlReducer } from './contractUrl';
import { setFuneralContractUrlDateReducer } from './contractUrlDate';
import { setAllContractSigningCredentialsReducer } from './allContractSigningCredentials';
import { setActiveContractSigningCredentialsReducer } from './activeContractSigningCredentials';

export const setContractSigningReducer = combineReducers({
    contractUrl: setFuneralContractUrlReducer,
    contractUrlDate: setFuneralContractUrlDateReducer,
    allContractSigningCredentials: setAllContractSigningCredentialsReducer,
    activeContractSigningCredentials: setActiveContractSigningCredentialsReducer
});
