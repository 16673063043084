import { sendRequest, baseUrl, defaultPostConfiguration, defaultGetConfiguration } from '../web-request-tools';
import axios from 'axios';

export function runSendEmail(emailRequestData: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/email/send`, emailRequestData, defaultPostConfiguration), callback);
}

export function runSendFuneralReceivedEmail(opportunityId: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/purchase/sendnotice/${opportunityId}`, defaultGetConfiguration), callback);
}

export function runOutgoingDataSending(accountApiRequestData: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/accountApi`, accountApiRequestData, defaultPostConfiguration), callback);
}

export function runFailingRequest(data: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/endpointTests/FailingRequest`, data, defaultPostConfiguration), callback);
}
export function runCreateErrorCase(data: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/Feedback/createErrorCase`, data, defaultPostConfiguration), callback);
}

export function runCreateCase(data: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/Feedback/createCase`, data, defaultPostConfiguration), callback);
}

export function runUpdateErrorCase(caseId: string, data: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/Feedback/updateCase/${caseId}`, data, defaultPostConfiguration), callback);
}
