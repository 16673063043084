import React from 'react';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';
import './PageCardTextComponent.css';

class PageCardTextComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined,
        ariaLabel: 'content',
        marginBottomOverride: ''
    };

    render() {
        return (
            <div
                className="card-content-text"
                aria-label={this.props.ariaLabel}
                style={{
                    textAlign: this.props.stylingOptions?.fonts?.bodyAlignment,
                    fontSize: this.props.stylingOptions?.fonts?.bodyFontSize,
                    fontFamily: this.props.stylingOptions?.fonts?.bodyFontFamily,
                    marginBottom: this.props.marginBottomOverride
                        ? this.props.marginBottomOverride
                        : this.props.stylingOptions.spacing?.abovePageBodyContent
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    ariaLabel: string;
    marginBottomOverride: string;
}

interface StateShape {
    // Shape of local state
}

export default PageCardTextComponent;
