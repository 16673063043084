import { connect } from 'react-redux';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import PhoneVerificationRequestFormComponent from './PhoneVerificationRequestFormComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    return {};
};

const mapDispatchToProps: any = (dispatch: Function, myProps: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerificationRequestFormComponent);
