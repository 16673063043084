import React from 'react';
import './TulipPaymentPriceCardComponent.css';
import { PropShape } from '../../../../global-components/payment-price-card-component/PaymentPriceCardComponent';

class TulipPaymentPriceCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <div className="tulip-ppc">
                <div className="tulip-ppc-top">
                    <div className="tulip-ppc-title" id="text-ppc-title">
                        {this.props.title}
                    </div>
                    <div className="tulip-ppc-amount" id="text-ppc-amount">
                        {this.props.amount}
                    </div>
                </div>
                <div className="tulip-ppc-subtitle">{this.props.subtitle}</div>
            </div>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default TulipPaymentPriceCardComponent;
