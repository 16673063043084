import React, { Fragment } from 'react';
import PurchaserOrderSummaryProductsDisplayWrapper from '../purchaser-order-summary-products-display-component/PurchaserOrderSummaryProductsDisplayWrapper';
import Product from '../../../../../static/models/Product';

class PurchaserOrderSummaryProductsGroupingComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        dispositionText: '',
        arrangementProducts: [],
        arrangementTotal: '',
        arrangementSubTotal: '',
        arrangementTax: '',
        travelProtectionProducts: [],
        travelProtectionTotal: '',
        travelProtectionSubTotal: '',
        travelProtectionTax: ''
    };

    render() {
        return (
            <Fragment>
                {this.props.arrangementProducts.length > 0 && (
                    <PurchaserOrderSummaryProductsDisplayWrapper
                        sectionTitle={this.props.dispositionText + ' Services'}
                        products={this.props.arrangementProducts}
                        total={this.props.arrangementTotal}
                        subtotal={this.props.arrangementSubTotal}
                        tax={this.props.arrangementTax}
                    />
                )}
                {this.props.travelProtectionProducts.length > 0 && (
                    <PurchaserOrderSummaryProductsDisplayWrapper
                        sectionTitle="Travel Protection"
                        products={this.props.travelProtectionProducts}
                        total={this.props.travelProtectionTotal}
                        subtotal={this.props.travelProtectionSubTotal}
                        tax={this.props.travelProtectionTax}
                    />
                )}
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    dispositionText: string;
    arrangementProducts: Product[];
    arrangementTotal: string;
    arrangementSubTotal: string;
    arrangementTax: string;
    travelProtectionProducts: Product[];
    travelProtectionTotal: string;
    travelProtectionSubTotal: string;
    travelProtectionTax: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryProductsGroupingComponent;
