import React from 'react';
import PinInputWrapper from '../../../global-components/specialty-input-components/pin-input-component/PinInputWrapper';
import { Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';

const renderField = (reduxFieldData: any) => {
    return (
        <PinInputWrapper
            inputCount={reduxFieldData.inputCount}
            autoFocus={reduxFieldData.autoFocus}
            id={`${reduxFieldData.formName}.${reduxFieldData.fieldPath}`}
            {...reduxFieldData.input}
        ></PinInputWrapper>
    );
};

class PinInputFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formName: '',
        spacing: defaultSpacing,
        labelString: '',
        inputCount: null,
        autoFocus: false,
        disable: false
    };

    render() {
        return (
            <Grid item {...this.props.spacing} hidden={this.props.disable}>
                <label>{this.props.labelString}</label>
                <Field
                    id={`${this.props.formName}.${this.props.fieldPath}`}
                    name={this.props.fieldPath}
                    component={renderField}
                    inputCount={this.props.inputCount}
                    autoFocus={this.props.autoFocus}
                />
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    spacing: object;
    labelString: string;
    inputCount: number;
    autoFocus: boolean;
    disable: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PinInputFormComponent;
