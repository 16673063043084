import { connect } from 'react-redux';
import BeneficiaryCheckPageComponent from './BeneficiaryCheckPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setBeneficaryProvidedAction } from '../../../redux/yesNoAnswers/policyQuestions/beneficiaryProvided';
import accountComponentSwitch from '../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import EverdaysBeneficiaryCheckPageWrapper from '../../account-specific-components/accounts/everdays/beneficiary-check-page-component/EverdaysBeneficiaryCheckPageWrapper';
import { EVERDAYS_STYLING_KEY } from '../../../static/constants/styling-company-constants/companies/everdays';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { setBeneficaryIsPurchaserAction } from 'redux/yesNoAnswers/policyQuestions/beneficiaryIsPurchaser';
import { getBeneficiaryLanguage } from 'toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props

    const firstName = getFormData(state, 'purchaser', 'firstName');
    const lastName = getFormData(state, 'purchaser', 'lastName');

    return {
        showGiveToPurchaser:
            !state.opportunity.recipient.sameAsPurchaser && state.featureFlags.beneficiaryAssociationRework,
        purchaserName: firstName + ' ' + lastName,
        beneficiaryText: getBeneficiaryLanguage(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setBeneficiaryInformation: (provided: boolean, isPurchaser: boolean) => {
            dispatch(setBeneficaryProvidedAction(provided));
            dispatch(setBeneficaryIsPurchaserAction(isPurchaser));
        }
    };
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(BeneficiaryCheckPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EVERDAYS_STYLING_KEY]: EverdaysBeneficiaryCheckPageWrapper
});
