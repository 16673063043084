import ApplicationStyling from '../../../models/ApplicationStyling';
import { TabStyling } from '../../../models/TabStyling';
import TulipFaviconUrl from '../../../media/favicons/tulip-favicon.png';

const tulipStyling: ApplicationStyling = {
    colors: {
        secondaryContrastColor: '#000000',
        primaryContrastColor: '#FFFFFF',
        footerBackgroundColor: '#FAFAFA',
        sectionSeperatorColor: '#D5D8DC',
        titleColor: '#243965',
        bodyColor: '#243965',
        primaryColor: '',
        secondaryColor: '',
        headerBottomBorderColor: '',
        headerFontColor: '',
        headerBackgroundColor: '',
        footerFontColor: '',
        generalBackgroundColor: '#FFFFFF',
        formFieldsBackgroundColor: '#FFFFFF',
        formFieldsTextColor: '#000000',
        placeholderFullOpacity: false
    },
    fonts: {
        buttonFontFamily: "'Brown-Regular', sans-serif",
        titleFontFamily: "'Tiempos', serif",
        bodyFontFamily: "'Brown-Light', sans-serif",
        titleAlignment: 'center',
        bodyAlignment: 'center',
        titleWeight: 'bolder',
        bodyWeight: 'lighter',
        titleFontSize: '2.4rem',
        bodyFontSize: '1.4rem'
    },
    sizing: {
        headerLogoMaxHeight: '30px'
    },
    showNameInTitle: true,
    useFormLabelsAsPlaceholders: false
};

export default tulipStyling;
export const tulipTabConstants: TabStyling = {
    faviconUrl: TulipFaviconUrl,
    tabName: 'Tulip'
};

export const TULIP_STYLING_KEY = 'tulipcremation';
