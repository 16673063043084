import React from 'react';
import './EarthFooterComponent.css';
import { PropShape as FooterPropShape } from '../../../../global-components/limb-components/footer-component/FooterComponent';

class EarthFooterComponent extends React.Component<FooterPropShape, StateShape> {
    constructor(props: FooterPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        phoneNumber: '',
        stylingOptions: undefined,
        subdomain: '',
        opportunityId: '',
        accountState: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        yelpUrl: '',
        address: '',
        city: '',
        funeralHomeNumber: '',
        zip: '',
        name: '',
        mediaDomain: ''
    };

    render() {
        return (
            <div
                className="earth-footer-container"
                style={{
                    backgroundColor: this.props.stylingOptions.colors.footerBackgroundColor,
                    color: this.props.stylingOptions.colors.footerFontColor
                }}
            >
                {this.props.phoneNumber && (
                    <div
                        className="earth-questions-text"
                        style={{
                            fontFamily: this.props.stylingOptions.fonts.titleFontFamily,
                            fontSize: this.props.stylingOptions.fonts.titleFontSize
                        }}
                    >
                        Questions?
                    </div>
                )}
                {this.props.phoneNumber && <div>Call us at {this.props.phoneNumber}.</div>}
            </div>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default EarthFooterComponent;
