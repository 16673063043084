import React from 'react';
import DisclaimerAddressing from '../../../../../static/models/disclaimers/DisclaimerAddressing';
import PaymentOption from '../../../../../static/models/PaymentOption';
import { formatMonetaryAmount } from '../../../../../toolboxes/reuseable-logic/string-formatters';
import DisclaimerModalWrapper from '../../disclaimer-modal-component/DisclaimerModalWrapper';

class HealthImpactModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {};
        this.calculateSixMonthsToOneYearPayout = this.calculateSixMonthsToOneYearPayout.bind(this);
        this.generateYearsByPayableSection = this.generateYearsByPayableSection.bind(this);
        this.generateDisclaimerText = this.generateDisclaimerText.bind(this);
    }

    static defaultProps = {
        setPaymentPlanHealthAddressingAction: () => {},
        recipientPossessive: '',
        recipientName: '',
        accountPhoneNumber: '',
        prearrangementAmount: '',
        explainRejectionInCancel: false,
        paymentOption: null,
        storeOnReject: true,
        allowRejection: true,
        dispositionText: ''
    };

    calculateSixMonthsToOneYearPayout() {
        const amount =
            this.props.paymentOption.years < 5
                ? this.props.paymentOption.faceAmount * 0.5
                : this.props.paymentOption.faceAmount * 0.35;
        return formatMonetaryAmount(amount.toString());
    }

    generateSixToTwelveMonthEntry(
        recipientName: string,
        sixMonthsToTwelveMonthsPayout: string,
        recipientPossessive: string
    ): string {
        return (
            `If ${recipientName} were to pass in months 6 to 12 of the policy, the death benefit will be ` +
            `${sixMonthsToTwelveMonthsPayout} and ${recipientPossessive} family will pay what remains of the ` +
            `cost of the services at time of death.`
        );
    }

    generateMoreThanTwelveMonthEntry(recipientName: string, recipientPossessive: string): string {
        return (
            `If ${recipientName} were to pass after month 13 but prior to paying off the plan in full, we will ` +
            `provide our services at no additional cost to ${recipientPossessive} family.`
        );
    }

    generateYearsByPayableSection() {
        return (
            <div>
                <div className="disclaimer-paragraph">
                    {this.generateSixToTwelveMonthEntry(
                        this.props.recipientName,
                        this.calculateSixMonthsToOneYearPayout(),
                        this.props.recipientPossessive
                    )}
                </div>
                <div className="disclaimer-paragraph">
                    {this.generateMoreThanTwelveMonthEntry(this.props.recipientName, this.props.recipientPossessive)}
                </div>
            </div>
        );
    }

    generateFirstPartOfDisclaimer(
        recipientPossessive: string,
        prearrangementAmount: string,
        faceAmount: number
    ): string {
        return (
            `Based on ${recipientPossessive} age and prearrangement amount of ` +
            `${formatMonetaryAmount(prearrangementAmount)}, the face amount of ${recipientPossessive} life ` +
            `insurance policy will be ${formatMonetaryAmount(faceAmount.toString())}. Because you have chosen to ` +
            `pay for ${recipientPossessive} prepaid ${this.props.dispositionText} plan over time, it's important to understand what ` +
            `happens if a death occurs before paying off the full amount.`
        );
    }

    generateSecondPartOfDisclaimer(recipientPossessive: string): string {
        return (
            `If the death is anything other than by accident, ${recipientPossessive} family may be required to ` +
            `provide additional funds to cover the cost of our services. The level of additional funds depends on ` +
            `how far into your payment plan you are.`
        );
    }

    generateThirdPartOfDisclaimer(recipientName: string, recipientPossessive: string): string {
        return (
            `If ${recipientName} were to pass within the first 6 months of the policy, then ${recipientPossessive} ` +
            `family will pay the cost of our services at the time of death minus what you have paid already.`
        );
    }

    generateQuestionsPartOfDisclaimer(accountPhoneNumber: string): string {
        return `If you have any questions regarding this, please call us at ${accountPhoneNumber}.`;
    }
    generateDisclaimerText() {
        return (
            <div>
                <div className="disclaimer-paragraph">
                    {this.generateFirstPartOfDisclaimer(
                        this.props.recipientPossessive,
                        this.props.prearrangementAmount,
                        this.props.paymentOption.faceAmount
                    )}
                </div>
                <div className="disclaimer-paragraph">
                    {this.generateSecondPartOfDisclaimer(this.props.recipientPossessive)}
                </div>
                <div className="disclaimer-paragraph">
                    {this.generateThirdPartOfDisclaimer(this.props.recipientName, this.props.recipientPossessive)}
                </div>
                {this.generateYearsByPayableSection()}
                <div className="disclaimer-paragraph">
                    {this.generateQuestionsPartOfDisclaimer(this.props.accountPhoneNumber)}
                </div>
            </div>
        );
    }

    render() {
        let rejectionText = 'Cancel';
        if (this.props.explainRejectionInCancel) {
            rejectionText = 'Pay Upfront Instead';
        }
        if (!this.props.allowRejection) {
            rejectionText = '';
        }
        return (
            <DisclaimerModalWrapper
                onAccept={() => this.props.onAccept()}
                onReject={() => this.props.onReject()}
                acceptanceText="Accept"
                rejectionText={rejectionText}
                disclaimerTitle="Information regarding your selected plan"
                saveDisclaimerAddressingToRedux={this.props.setPaymentPlanHealthAddressingAction}
                storeOnReject={this.props.storeOnReject}
            >
                {this.generateDisclaimerText()}
            </DisclaimerModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    setPaymentPlanHealthAddressingAction: (addressing: DisclaimerAddressing) => void;
    recipientPossessive: string;
    recipientName: string;
    accountPhoneNumber: string;
    prearrangementAmount: string;
    paymentOption: PaymentOption;
    onAccept: Function;
    onReject: Function;
    explainRejectionInCancel: boolean;
    allowRejection: boolean;
    storeOnReject: boolean;
    dispositionText: string;
}

interface StateShape {}

export default HealthImpactModalComponent;
