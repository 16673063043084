import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { setOverridePageAction } from '../../../redux/urlPaths/overridePage';
import urlPaths from '../../../static/constants/enums/urlPaths';

export default function saveOverridePage(
    state: GlobalStateShape,
    dispatch: Function,
    pageOveride: string
): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    dispatch(setOverridePageAction(`/${pageOveride}`));
                    return resolve();
                }),
            blocksPageStarts: [urlPaths.forceNavigation]
        }
    ];
}
