// Action Type
export const SET_RECIPIENT_IDENTITY_VERIFICATION_COMPLETED = 'SET_IDENTITY_VERIFICATION_COMPLETED'; // Action
export function setOpportunityRecipientIdentityVerificationCompletedAction(newValue: boolean) {
    return { type: SET_RECIPIENT_IDENTITY_VERIFICATION_COMPLETED, newValue };
}
// Reducer
export function setOpportunityRecipientIdentityVerificationCompletedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_RECIPIENT_IDENTITY_VERIFICATION_COMPLETED:
            return action.newValue;
        default:
            return state;
    }
}
