// Action Type
export const SET_ACCOUNT_CITY = 'SET_ACCOUNT_CITY';
// Action
export function setAccountCityAction(newValue: string) {
    return { type: SET_ACCOUNT_CITY, newValue };
}
// Reducer
export function setAccountCityReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_ACCOUNT_CITY:
            return action.newValue;
        default:
            return state;
    }
}
