import { connect } from 'react-redux';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import TitanButtonSubtitledLinkComponent from './TitanButtonSubtitledLinkComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TitanButtonSubtitledLinkComponent);
