import { connect } from 'react-redux';
import DocusignReroutePageComponent from './DocusignReroutePageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { withRouter } from 'react-router';
import queryStringParser from 'query-string';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { setTravelContractsUrlAction } from '../../../redux/travelOpportunity/contractUrl';
import { setFuneralContractUrlAction } from 'redux/opportunity/contractSigning/contractUrl';

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    try {
        if (!state.opportunity.id) {
            // Wait... I'm the IFrame!
            return {
                pageToRerouteTo: null
            };
        }
        const params = queryStringParser.parse(myProps.location.search);
        let pageToRerouteTo = '';
        let outdatedInformationNeedsCleared = false;
        if (params.event === 'signing_complete') {
            pageToRerouteTo = params.intentPage.toString();
        } else {
            if (params.event === 'decline') {
                pageToRerouteTo = urlPaths.contractsDenied;
            } else {
                outdatedInformationNeedsCleared = true;
                if (params.intentPage === urlPaths.travelContracts) {
                    pageToRerouteTo = urlPaths.funeralContracts;
                }
                if (params.intentPage === urlPaths.purchaserSigningComplete) {
                    pageToRerouteTo = urlPaths.travelContracts;
                }
            }
        }
        if (!pageToRerouteTo) {
            pageToRerouteTo = urlPaths.error;
        }
        return {
            outdatedInformationNeedsCleared,
            pageToRerouteTo
        };
    } catch {
        return {
            pageToRerouteTo: urlPaths.error
        };
    }
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        clearFuneralContractUrlAction: () => dispatch(setFuneralContractUrlAction('')),
        clearTravelContractsUrlAction: () => dispatch(setTravelContractsUrlAction(''))
    };
};

const WrappedComponent = connect(mapStateToProps, mapDispatchToProps)(DocusignReroutePageComponent);

//@ts-ignore
export default withRouter(WrappedComponent);
