import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_LOGO_URL = 'SET_STYLING_LOGO_URL';
// Action
export function setStylingLogoUrlAction(newValue: string) {
    return { type: SET_STYLING_LOGO_URL, newValue };
}
// Reducer
export function setStylingLogoUrlReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().logoUrl || '';
    }
    switch (action.type) {
        case SET_STYLING_LOGO_URL:
            return action.newValue;
        default:
            return state;
    }
}
