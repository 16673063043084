import { connect } from 'react-redux';
import PurchaseProcessingPageComponent from './PurchaseProcessingPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import TulipPurchaseProcessingPageWrapper from 'components/account-specific-components/accounts/tulip-cremation/purchase-processing-page-component/TulipPurchaseProcessingPageWrapper';
import EverdaysPurchaseProcessingPageWrapper from 'components/account-specific-components/accounts/everdays/purchase-processing-page-component/EverdaysPurchaseProcessingPageWrapper';
import { TULIP_STYLING_KEY } from 'static/constants/styling-company-constants/companies/tulip';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import { PaymentMethod } from 'static/constants/enums/paymentMethods';
import {
    formIsValid,
    validateRecipientVital,
    validateMotherVital,
    validateFatherVital,
    validateSpouseVital,
    validateEmploymentVital,
    validateMilitaryVital
} from 'toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { getFuneralTotal } from 'toolboxes/reuseable-logic/pricing-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    let email = '';
    let paymentMethod = '';
    let funeralHomePhone = '';
    let recipientName = '';
    const demographicFormToGetData = state.opportunity.recipient.sameAsPurchaser ? 'purchaser' : 'recipient';

    if (state.account.emailSentFromEfuneral) {
        email = `${state.account.subdomain}@efuneral.com`;
    } else if (state.account.emailOnBehalf) {
        email = `${state.account.emailOnBehalf}`;
    } else {
        email = '';
    }

    if (state.opportunity.pricing.paymentOptions.selectedOption) {
        if (state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod === PaymentMethod.CreditCard) {
            paymentMethod = 'credit card';
        } else if (state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod === PaymentMethod.ACH) {
            paymentMethod = 'bank account';
        }
    } else {
        paymentMethod = 'payment method';
    }

    recipientName =
        getFormData(state, demographicFormToGetData, 'firstName') +
        ' ' +
        getFormData(state, demographicFormToGetData, 'lastName');
    const recipientVitalFormValid = formIsValid(getFormValues('recipientVital')(state), validateRecipientVital);
    const motherVitalFormValid = formIsValid(getFormValues('motherVital')(state), validateMotherVital);
    const fatherVitalFormValid = formIsValid(getFormValues('fatherVital')(state), validateFatherVital);
    const spouseVitalFormValid = formIsValid(getFormValues('spouseVital')(state), validateSpouseVital);
    const employmentVitalFormValid = formIsValid(getFormValues('employmentVital')(state), validateEmploymentVital);
    const militaryVitalFormValid = formIsValid(getFormValues('militaryVital')(state), validateMilitaryVital);

    const contactPresent = !!state.opportunity.purchaser.id;

    // These values will be passed in as props
    return {
        creditCardLastFour: state.opportunity.pricing.creditCardInfo.lastFour,
        funeralPrice: getFuneralTotal(state),
        timing: state.opportunity.timing,
        vitalStatisticsUrl: state.urlPaths.vitalStatistics,
        preneedContractType: state.opportunity.preneedContractType,
        opportunityId: state.opportunity.id,
        paymentMethod: paymentMethod,
        funeralHomeName: state.account.name,
        email: email,
        contactPresent: contactPresent,
        funeralHomePhone: state.account.phone,
        recipientName: recipientName,
        featureVitalStatistics: state.account.featureVitalStatistics,
        fundingProviderAccountKnownAs: state.opportunity?.selectedFundingProduct?.provider?.account?.knownAs,
        fundingProviderAccountName: state.opportunity?.selectedFundingProduct?.provider?.account?.name,
        formIsValid:
            recipientVitalFormValid &&
            motherVitalFormValid &&
            fatherVitalFormValid &&
            spouseVitalFormValid &&
            employmentVitalFormValid &&
            militaryVitalFormValid
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaseProcessingPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TULIP_STYLING_KEY]: TulipPurchaseProcessingPageWrapper,
    [EVERDAYS_STYLING_KEY]: EverdaysPurchaseProcessingPageWrapper
});
