import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';
import { getCartTotal, getFuneralTotal } from '../reuseable-logic/pricing-calculator';
import {
    fetchPaymentOptions,
    runBankInformation,
    runResetPaymentOption,
    runSubmitPaymentMethod,
    runUpdatePaymentOption
} from '../web-requests/specific-requests/payment-data-requests';
import { setAvailablePaymentOptionsAction } from '../../redux/opportunity/pricing/paymentOptions/availableOptions';
import PaymentOption from '../../static/models/PaymentOption';
import { getFormValues } from 'redux-form';
import { PaymentMethod } from '../../static/constants/enums/paymentMethods';
import { FilingFeeData } from '../../static/constants/stateSpecificLists/filingFeeData';
import { Timing } from '../../static/constants/enums/timing';
import { PaymentMode } from '../../static/constants/enums/paymentModes';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { getInsuredAge } from '../reuseable-logic/insured-calculator';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { getCommissionOptionForCalculator } from 'toolboxes/reuseable-logic/payment-options-calculator';

/* Local helper functions */
const getPaymentOptionFetchOptions = (state: GlobalStateShape) => {
    if (isAtNeed(state)) {
        return {
            amount: getFuneralTotal(state)
        };
    }
    switch (state.opportunity.preneedContractType) {
        case PreneedContractType.Trust:
            return {
                amount: getFuneralTotal(state)
            };
        default:
            // default is Insurance Options
            let ret: any = {
                state: getFormData(state, 'purchaser', 'state'),
                age: getInsuredAge(state),
                commission: getCommissionOptionForCalculator(state.opportunity.commissionOption),
                amount: getFuneralTotal(state),
                period: 'monthly',
                signed: state.opportunity.recipient.isUnavailable ? 'false' : 'true',
                product: state.opportunity.selectedFundingProduct.productId
            };

            // Check for feature flag here
            if (state.featureFlags.useNodePaymentCalculator) {
                ret = {
                    purchaserState: getFormData(state, 'purchaser', 'state'),
                    insuredAge: getInsuredAge(state),
                    commission: getCommissionOptionForCalculator(state.opportunity.commissionOption),
                    arrangementAmount: getFuneralTotal(state),
                    period: 'monthly',
                    signed: state.opportunity.recipient.isUnavailable ? 'false' : 'true',
                    product: state.opportunity.selectedFundingProduct.productId,
                    accountId: state.account.id
                };
            }
            return ret;
    }
};

const getFormattedPaymentMethod = (method: PaymentMethod): string => {
    switch (method) {
        case PaymentMethod.CreditCard:
            return 'creditCard';
        case PaymentMethod.ACH:
            return 'ach';
        default:
            return null;
    }
};

/* Exported functions */

export function sendSaveCreditCardInformation(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    runSubmitPaymentMethod(
        {
            addCard: false,
            opportunityId: state.opportunity.id,
            token: state.opportunity.pricing.stripeTokenId
        },
        (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            return callback(undefined, undefined);
        }
    );
}

export function sendSavePaymentOption(
    selectedPaymentOption: PaymentOption,
    opportunityId: string,
    callback: (result: any, error: any) => void
) {
    let selectedOption = selectedPaymentOption;
    const mode = selectedPaymentOption.paymentMode;
    const frequency = 'monthly';
    const numberOfPayments = selectedOption.paymentMode === PaymentMode.Multi ? selectedOption.years * 12 : 1;
    runUpdatePaymentOption(
        opportunityId,
        {
            billingType: 'Direct',
            faceAmount: selectedOption.faceAmount,
            frequency,
            // This field is not currently utilised by the backend, but it is included in the model
            insuredsBirthdate: null,
            mode,
            name: `${selectedOption.years} Years with Monthly Payments`,
            numberOfPayments,
            paymentMethod: selectedOption.paymentMethod,
            premiumAmount: selectedOption.premium,
            amountOfFirstPayment: selectedOption.amountOfFirstPayment,
            // This field is not currently utilised by the backend, but it is included in the model
            totalPrice: 0,
            yearsPayable: selectedOption.years.toString()
        },
        callback
    );
}

export function sendSaveEmptyPaymentOption(opportunityId: string, callback: (result: any, error: any) => void) {
    runResetPaymentOption(opportunityId, callback);
}

export function fetchAndStorePaymentOptions(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: PaymentOption[], error: any) => void
) {
    const standardOptions = getPaymentOptionFetchOptions(state);

    const paymentOptionFetchingPromiseGenerator = (plan: string, paymentMethod: PaymentMethod) =>
        new Promise<PaymentOption[]>((resolve, reject) => {
            const formattedPaymentMethod = getFormattedPaymentMethod(paymentMethod);
            if (formattedPaymentMethod === null) {
                return reject();
            }

            fetchPaymentOptions(
                {
                    ...standardOptions,
                    plan,
                    paymentMethod: formattedPaymentMethod
                },
                state.opportunity.preneedContractType,
                state.featureFlags.usePreneedContractTypeCalculator,
                state.featureFlags.useNodePaymentCalculator,
                (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    let formattedOptions: PaymentOption[] = result.map(unformattedOption => {
                        return {
                            ...unformattedOption,
                            paymentMode: plan,
                            paymentMethod
                        };
                    });

                    if (
                        state.account.disablePaymentPlansWhenBelow &&
                        getCartTotal(state) < state.account.disablePaymentPlansWhenBelow
                    ) {
                        formattedOptions = formattedOptions.filter(option => option.paymentMode === PaymentMode.Single);
                    }
                    return resolve(formattedOptions);
                }
            );
        });

    const promisesToRun = [];
    if (isAtNeed(state)) {
        promisesToRun.push(
            new Promise<PaymentOption[]>((resolve, reject) => {
                resolve([
                    {
                        paymentMode: PaymentMode.Single,
                        paymentMethod: PaymentMethod.CreditCard,
                        years: 0,
                        months: 0,
                        faceAmount: getFuneralTotal(state),
                        premium: 0,
                        amountOfFirstPayment: getFuneralTotal(state)
                    }
                ]);
            })
        );
    } else {
        const homesteadersMarketCode = getFormData(state, 'representative', 'homesteadersMarketCode');

        if (!homesteadersMarketCode && !state.account.featureDisableCreditCardPayments) {
            promisesToRun.push(paymentOptionFetchingPromiseGenerator(PaymentMode.Single, PaymentMethod.CreditCard));
            promisesToRun.push(paymentOptionFetchingPromiseGenerator(PaymentMode.Multi, PaymentMethod.CreditCard));
        }

        // FilingFeeData is true if state has a fee. this value is ignored if we want to ignore the fee (hideStateFilingFee is true)
        if (
            state.featureFlags.ACHeCheck &&
            !state.account.disableACH &&
            !state.travelOpportunity.id &&
            (!(FilingFeeData[state.account.state] && !state.opportunity.hideStateFilingFee) ||
                homesteadersMarketCode ||
                state.account.featureDisableCreditCardPayments)
        ) {
            promisesToRun.push(paymentOptionFetchingPromiseGenerator(PaymentMode.Single, PaymentMethod.ACH));
            promisesToRun.push(paymentOptionFetchingPromiseGenerator(PaymentMode.Multi, PaymentMethod.ACH));
        }
    }

    Promise.all(promisesToRun)
        .then(result => {
            let allPaymentOptions: PaymentOption[] = [].concat(...result);
            if ([Timing.IMMEDIATE, Timing.IMMINENT].includes(state.opportunity.timing)) {
                allPaymentOptions = allPaymentOptions.filter(
                    paymentOption => paymentOption.paymentMode === PaymentMode.Single
                );
            }
            dispatch(setAvailablePaymentOptionsAction(allPaymentOptions));
            return callback(allPaymentOptions, undefined);
        })
        .catch(error => {
            return callback(undefined, error);
        });
}

export function saveBankingInformation(state: GlobalStateShape, callback: (reuslt: any, error: any) => void) {
    const bankingInformation = {
        ...getFormValues('bankingInformation')(state),
        name: getFormData(state, 'bankingInformation', 'bankName')
    };

    runBankInformation(bankingInformation, state.opportunity.purchaser.id, state.opportunity.id, callback);
}
