import { combineReducers } from 'redux';
import { setTravelOpportunityIdReducer } from './id';
import { setTravelContractsUrlReducer } from './contractUrl';
import { setTravelProductsReducer } from './products';
import { setTravelContractUrlDateReducer } from './contractUrlDate';
import { setTravelIsDemoContractIncursCostReducer } from './isDemoAndContractWouldIncurCost';

export default combineReducers({
    id: setTravelOpportunityIdReducer,
    contractUrl: setTravelContractsUrlReducer,
    contractUrlDate: setTravelContractUrlDateReducer,
    products: setTravelProductsReducer,
    isDemoAndContractWouldIncurCost: setTravelIsDemoContractIncursCostReducer
});
