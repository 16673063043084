import { sendRequest, baseUrl, defaultPostConfiguration } from '../web-request-tools';
import axios from 'axios';
import PhoneRequestVerification from '../../../static/models/PhoneVerification/PhoneRequestVerification';
import PhoneVerifyRequest from '../../../static/models/PhoneVerification/PhoneVerifyRequest';

export function runSubmitPhoneVerificationRequest(
    contactId: string,
    requestVerification: PhoneRequestVerification,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/phoneverification/request/${contactId}`,
            requestVerification,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runSubmitPhoneVerify(
    contactId: string,
    verifyRequest: PhoneVerifyRequest,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/phoneverification/verify/${contactId}`, verifyRequest, defaultPostConfiguration),
        callback
    );
}

export function runSubmitPhoneVerificationSecurityRequest(
    opportunityId: string,
    requestVerification: PhoneRequestVerification,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/phoneverification/request/security/${opportunityId}`,
            requestVerification,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runSubmitPhoneSecurityVerify(
    opportunityId: string,
    verifyRequest: PhoneVerifyRequest,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/phoneverification/verify/security/${opportunityId}`,
            verifyRequest,
            defaultPostConfiguration
        ),
        callback
    );
}
