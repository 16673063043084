import Environment from '../../static/models/Environment';

export const demoEnvironment: Environment = {
    stripeKey: 'pk_test_RWUyawfBgmlSWaChbmAIZlSx',
    efuneralGatewayBaseUrl: 'https://plan.efuneraldemo.com',
    efuneralIdentityVerificationUrl: 'https://identityverification.efuneraldemo.com',
    // efuneralGatewayBaseUrl: 'https://api.funeraldemo.dev/v1',
    efuneralPaymentCalculatorUrl: 'https://api.funeraldemo.dev/v1/rates',
    efuneralNodePaymentCalculatorUrl: 'https://api.funeraldemo.dev/v3/rates',
    customerContractOrReceiptUrl: 'https://plan.efuneraldemo.com/api/document',
    smartlookKey: '',
    efuneralGatewayKey: 'z4b60gDR7r7U3Dahc8L7L1tiR7G1TdLV75otJcE9',
    sentryUrl: '',
    efuneralMediaBucket: 'https://media.efuneral.com',
    featuresUrl: 'https://features.efuneraldemo.com/api/v1/',
    tulipBaseUrl: '',
    clearRepresentativeOnPurchaserEmailLinkLoad: true,
    agentSignupUrl: '',
    googleTagManagerId: '',
    createDemoRecords: true,
    generalHellosignKey: '716b6a3a163c1fabdcb1d4077372c73c',
    tulipHelloSignKey: '716b6a3a163c1fabdcb1d4077372c73c',
    verifyHelloSignDomain: false,
    logDebugInfo: true,
    accountIds: {
        OFDA: '0011M00002U5ZdkQAF'
    }
};
