import Environment from '../../static/models/Environment';

export const testEnvironment: Environment = {
    stripeKey: 'pk_test_RWUyawfBgmlSWaChbmAIZlSx',
    efuneralGatewayBaseUrl: 'https://plan.stagingefuneral.com',
    efuneralIdentityVerificationUrl: 'https://identityverification.stagingefuneral.com',
    // efuneralGatewayBaseUrl: 'https://api.stagingfuneral.dev/v0',
    // efuneralGatewayBaseUrl: 'http://localhost:5001',
    efuneralPaymentCalculatorUrl: 'https://api.stagingfuneral.dev/v0/rates',
    efuneralNodePaymentCalculatorUrl: 'https://api.stagingfuneral.dev/v3/rates',
    // efuneralNodePaymentCalculatorUrl: 'http://localhost:3333',
    customerContractOrReceiptUrl: 'https://plan.stagingefuneral.com/api/document',
    smartlookKey: '46abf62b125fe4b37dd703e4c2c9f7b5976b70a4',
    efuneralGatewayKey: '5XXFJZsbOraYqbdQfng0g8DjaZgmDHgPRpPoWQu5',
    sentryUrl: '',
    efuneralMediaBucket: 'https://media.stagingefuneral.com',
    featuresUrl: 'https://features.stagingefuneral.com/api/v1',
    tulipBaseUrl: 'tulipcremation.stagingfuneral.dev',
    clearRepresentativeOnPurchaserEmailLinkLoad: true,
    agentSignupUrl: 'https://google.com',
    googleTagManagerId: '',
    createDemoRecords: false,
    generalHellosignKey: '35fa953488e1559062d3f022fe2c205b',
    tulipHelloSignKey: '35fa953488e1559062d3f022fe2c205b',
    verifyHelloSignDomain: false,
    logDebugInfo: true,
    accountIds: {
        OFDA: '0011M00002U5ZdkQAF'
    }
};
