import React, { Fragment } from 'react';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import PaymentOptionSelectionWrapper from '../payment-option-selection-component/PaymentOptionSelectionWrapper';
import HealthQuestionInstructionsModalWrapper from '../../../../../modal-components/health-question-components/health-question-instructions-modal-component/HealthQuestionInstructionsModalWrapper';
import PastHealthQuestionModalWrapper from '../../../../../modal-components/health-question-components/past-health-question-modal-component/PastHealthQuestionModalWrapper';
import CurrentHealthQuestionModalWrapper from '../../../../../modal-components/health-question-components/current-health-question-modal-component/CurrentHealthQuestionModalWrapper';
import HealthImpactModalWrapper from '../../../../../modal-components/disclaimer-components/specific-disclaimers/health-impact-modal-component/HealthImpactModalWrapper';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';

class InstallmentPaymentOptionsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            healthInstructionsViewed: props.wasBedriddenOrAiled !== null && props.isBedriddenOrAiled !== null,
            desiredPaymentOption: null
        };
    }

    static defaultProps = {
        // Default prop values
        multiPayOptions: [],
        wasBedriddenOrAiled: null,
        isBedriddenOrAiled: null,
        healthDisclaimerAddressedPositively: false,
        setSelectedPaymentOptionAction: () => {},
        healthQuestionsNecessary: true,
        isIncapacitatedRates: true,
        isTrust: false
    };

    componentDidUpdate(oldProps: PropShape) {
        if (
            this.props.isBedriddenOrAiled === false &&
            this.props.wasBedriddenOrAiled === false &&
            this.state.desiredPaymentOption
        ) {
            this.props.setSelectedPaymentOptionAction(this.state.desiredPaymentOption);
            this.setState({
                desiredPaymentOption: null
            });
        }
    }

    selectThisOption(option: PaymentOption) {
        if (
            this.props.isIncapacitatedRates ||
            ![true, false].includes(this.props.isBedriddenOrAiled) ||
            ![true, false].includes(this.props.wasBedriddenOrAiled)
        ) {
            this.setState({
                desiredPaymentOption: option
            });
        } else {
            this.props.setSelectedPaymentOptionAction(option);
        }
    }

    render() {
        if (!this.state.desiredPaymentOption) {
            return this.props.multiPayOptions
                .filter(option => {
                    return option.paymentMethod === this.props.paymentMethod;
                })
                .map((option, index) => {
                    return (
                        <PaymentOptionSelectionWrapper
                            key={`option-${index}`}
                            paymentOption={option}
                            onClick={() => this.selectThisOption(option)}
                        />
                    );
                });
        }

        // The health flow is open
        if (this.props.healthQuestionsNecessary) {
            if (!this.state.healthInstructionsViewed) {
                return (
                    <HealthQuestionInstructionsModalWrapper
                        onContinue={() =>
                            this.setState({
                                healthInstructionsViewed: true
                            })
                        }
                    />
                );
            }
            if (![true, false].includes(this.props.wasBedriddenOrAiled)) {
                return <PastHealthQuestionModalWrapper />;
            }
            if (![true, false].includes(this.props.isBedriddenOrAiled)) {
                return <CurrentHealthQuestionModalWrapper />;
            }
        }
        if (this.props.isTrust) {
            this.props.setSelectedPaymentOptionAction(this.state.desiredPaymentOption);
            this.setState({
                desiredPaymentOption: null
            });
            return null;
        }
        return (
            <Fragment>
                {!this.props.isTrust && (
                    <HealthImpactModalWrapper
                        onAccept={() => {
                            this.props.setSelectedPaymentOptionAction(this.state.desiredPaymentOption);
                            this.setState({
                                desiredPaymentOption: null
                            });
                        }}
                        onReject={() => {
                            this.setState({
                                desiredPaymentOption: null
                            });
                        }}
                        paymentOption={this.state.desiredPaymentOption}
                        storeOnReject={false}
                    />
                )}
            </Fragment>
        );
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    multiPayOptions: PaymentOption[];
    wasBedriddenOrAiled?: boolean;
    isBedriddenOrAiled?: boolean;
    healthQuestionsNecessary: boolean;
    setSelectedPaymentOptionAction: Function;
    paymentMethod: PaymentMethod;
    isIncapacitatedRates: boolean;
    isTrust: boolean;
}

interface StateShape {
    // Shape of local state
    healthInstructionsViewed: boolean;
    desiredPaymentOption: PaymentOption;
}

export default InstallmentPaymentOptionsDisplayComponent;
