import { change } from 'redux-form';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../redux-form/redux-form-data-access';
import getEnvironment from '../../../env-config/get-environment';
import axios from 'axios';
import { baseUrl, defaultPostConfiguration } from '../../web-requests/web-request-tools';

export const logUrlTracking = (params: any, location = window.location) => {
    if (!params?.id) return;

    const baseIncomingUrl = location.protocol + '//' + location.host + location.pathname;
    axios.post(
        `${baseUrl}/api/urlparametertracking`,
        {
            OpportunityId: params.id,
            URL: baseIncomingUrl,
            URLParams: params
        },
        defaultPostConfiguration
    );
};

export const runAPITest = () => {
    axios
        .get(`${baseUrl}/api/endpointTests/`, {
            headers: {
                'x-api-key': getEnvironment().efuneralGatewayKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response: any) => {
            console.info('API Test Completed Successfully.');
        })
        .catch((error: any) => {
            console.error(`API Test Completed with errors: ${error}`);
        });
};

export const saveParamData = (state: GlobalStateShape, params: any, dispatch: Function) => {
    const representativeId = params.representativeId || params.repId;
    if (representativeId) {
        // TODO remove
        if (representativeId === 'CLEAR_ID') {
            if (
                (getFormData(state, 'representative', 'id') || window.localStorage.getItem('representativeId')) &&
                getEnvironment().clearRepresentativeOnPurchaserEmailLinkLoad
            ) {
                dispatch(change('representative', 'id', ''));
                window.localStorage.removeItem('representativeId');
            }
        } else {
            if (!getFormData(state, 'representative', 'id') || !window.localStorage.getItem('representativeId')) {
                dispatch(change('representative', 'id', representativeId));
                window.localStorage.setItem('representativeId', representativeId);
            }
        }
    }
};
