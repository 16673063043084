import { connect } from 'react-redux';
import RecipientVitalFormComponent from './RecipientVitalFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props

    return {
        recipientVitalData: getFormValues('recipientVital')(state),
        recipientFirstName: state.opportunity.recipient.sameAsPurchaser
            ? getFormData(state, 'purchaser', 'firstName')
            : getFormData(state, 'recipient', 'firstName'),
        recipientLastName: state.opportunity.recipient.sameAsPurchaser
            ? getFormData(state, 'purchaser', 'lastName')
            : getFormData(state, 'recipient', 'lastName')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientVitalFormComponent);
