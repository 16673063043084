// Action Type
export const SET_ACCOUNT_FACEBOOKURL = 'SET_ACCOUNT_FACEBOOKURL';
// Action
export function setAccountFacebookUrlAction(newValue: string) {
    return { type: SET_ACCOUNT_FACEBOOKURL, newValue };
}
// Reducer
export function setAccountFacebookUrlReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_ACCOUNT_FACEBOOKURL:
            return action.newValue;
        default:
            return state;
    }
}
