import React from 'react';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';

class EverdaysPurchaseProcessingPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        vitalStatisticsUrl: ''
    };

    render() {
        return (
            //TODO, add event hooks for mobile devices in here by Aug26/2020.
            <FullContentCenterWrapper
                metaPageName="Complete"
                metaPageNumber=""
                metaPagebackAvailable=""
                metaLastPage="true"
            ></FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    vitalStatisticsUrl: string;
}

interface StateShape {
    // Shape of local state
}

export default EverdaysPurchaseProcessingPageComponent;
