import React, { Fragment } from 'react';
import Product from '../../../static/models/Product';
import './CartComponent.css';
import {
    formatMonetaryAmount,
    getFormattedPriceForProduct
} from '../../../toolboxes/reuseable-logic/string-formatters';
import IndeterminateCheckBoxOutlined from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import MockLinkWrapper from '../mock-link-component/MockLinkWrapper';
import {
    getSortedApplicableCategories,
    generateFullCategoryStringForProduct
} from '../../../toolboxes/reuseable-logic/product-category-display-calculator';

class CartComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        cartTotal: 0,
        funeralProducts: [],
        travelProducts: [],
        removeFuneralProduct: () => {},
        removeTravelProduct: () => {},
        addFuneralProduct: () => {},
        addTravelProduct: () => {}
    };

    render() {
        if (this.props.funeralProducts.length === 0 && this.props.travelProducts.length === 0) {
            return (
                <div className="empty-cart" style={{ display: 'flex' }}>
                    No Products Selected
                </div>
            );
        }
        const sortedCategories = getSortedApplicableCategories(
            this.props.funeralProducts.concat(this.props.travelProducts)
        );
        const mappableProductTypeLists = [
            {
                productList: this.props.funeralProducts,
                removalFunction: this.props.removeFuneralProduct,
                addingFunction: this.props.addFuneralProduct
            },
            {
                productList: this.props.travelProducts,
                removalFunction: this.props.removeTravelProduct,
                addingFunction: this.props.addTravelProduct
            }
        ];
        return (
            <div className="cart">
                {mappableProductTypeLists.map(productTypeObject => {
                    return sortedCategories.map(category => {
                        const productsInCategory = productTypeObject.productList.filter(product => {
                            return generateFullCategoryStringForProduct(product) === category;
                        });
                        if (productsInCategory.length === 0) {
                            return null;
                        }

                        return (
                            <div key={`category-${category}`}>
                                <div className="cart-category-title">{category}</div>
                                <div className="cart-category-products">
                                    <div>{/*Empty Element*/}</div>
                                    <div className="cart-product-quantity">Quantity</div>
                                    <div className="cart-product-price">Price</div>
                                    <div>{/*Empty Element*/}</div>
                                    {productsInCategory
                                        .sort((a, b) => b.price - a.price)
                                        .map(product => {
                                            return (
                                                <Fragment key={`product-${product.productId}`}>
                                                    <div className="cart-product-name">{product.name}</div>
                                                    <div className="cart-product-quantity">
                                                        <div
                                                            onClick={() =>
                                                                productTypeObject.removalFunction({
                                                                    ...product,
                                                                    quantity: 1
                                                                })
                                                            }
                                                            className="cart-product-quantity-button"
                                                        >
                                                            <IndeterminateCheckBoxOutlined />
                                                        </div>
                                                        <div className="cart-product-quantity-text">
                                                            {product.quantity}
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                productTypeObject.addingFunction({
                                                                    ...product,
                                                                    quantity: 1
                                                                })
                                                            }
                                                            className="cart-product-quantity-button"
                                                        >
                                                            <AddBoxOutlined />
                                                        </div>
                                                    </div>
                                                    <div className="cart-product-price">
                                                        {getFormattedPriceForProduct(product)}
                                                    </div>
                                                    <div className="cart-product-removal">
                                                        <MockLinkWrapper
                                                            id="remove"
                                                            onClick={() => productTypeObject.removalFunction(product)}
                                                        >
                                                            Remove
                                                        </MockLinkWrapper>
                                                    </div>
                                                </Fragment>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    });
                })}

                <div className="cart-total-section">
                    <div className="cart-total-title">Cart Total</div>
                    <div className="cart-total-amount" id="text-cart-total">
                        {formatMonetaryAmount(this.props.cartTotal.toString())}
                    </div>
                    <div className="cart-total-title-disclaimer">(Tax Included)</div>
                </div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    funeralProducts: Product[];
    travelProducts: Product[];
    removeFuneralProduct: (product: Product) => void;
    addFuneralProduct: (product: Product) => void;
    removeTravelProduct: (product: Product) => void;
    addTravelProduct: (product: Product) => void;
    cartTotal: number;
}

interface StateShape {
    // Shape of local state
}

export default CartComponent;
