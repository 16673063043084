import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { getFormData } from '../../redux-form/redux-form-data-access';
import {
    saveIsPlanningForSelf,
    saveRelationshipToRecipient
} from '../../server-application-buffer/general-opportunity-data';
import urlPaths from '../../../static/constants/enums/urlPaths';

export function saveRelationshipToRecipientData(state: GlobalStateShape): BlockingFunction[] {
    if (getFormData(state, 'purchaser', 'relationshipToRecipient')) {
        return [
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        saveRelationshipToRecipient(state, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    }),
                blocksPageStarts: []
            },
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        saveIsPlanningForSelf(state, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    }),
                blocksPageStarts: [urlPaths.orderSummary]
            }
        ];
    }
    return [];
}
