const homesteadersProductCodeToContractNumberMappings = {
    AV: '202',
    AY: '202',
    AZ: '202',
    BY: '202',
    BZ: '202',
    CP: '202',
    CY: '600',
    CZ: '600',
    EZ: '600',
    PY: '202',
    PZ: '202'
};

export default homesteadersProductCodeToContractNumberMappings;
