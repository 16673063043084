import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import ApplicationStyling from 'static/models/ApplicationStyling';

class InternalFormComponent extends React.Component<InjectedFormProps<FormShape, PropShape> & PropShape, StateShape> {
    render() {
        return (
            <form
                aria-label={`${this.props.formName} form`}
                onSubmit={e => {
                    e.preventDefault();
                }}
                style={{
                    marginBottom: this.props.stylingOptions?.spacing?.aboveContinueButton
                        ? `calc(${this.props.stylingOptions.spacing.abovePageBodyContent} - ${this.props.stylingOptions.spacing.innerBody}`
                        : '20px',
                    marginRight: this.props.stylingOptions?.spacing?.innerBody
                        ? `calc(${this.props.stylingOptions.spacing.innerBody} / -2)`
                        : '',
                    marginLeft: this.props.stylingOptions?.spacing?.innerBody
                        ? `calc(${this.props.stylingOptions.spacing.innerBody} / -2)`
                        : ''
                }}
            >
                <Grid container spacing={this.props.stylingOptions.spacing?.innerBody ? 0 : 2} justify="center">
                    {this.props.children}
                </Grid>
            </form>
        );
    }
}

export interface PropShape extends React.Props<any> {
    formName: string;
    validate: any;
    destroyOnUnmount: boolean;
    initialValues: any;
    disabled: boolean;
    stylingOptions: ApplicationStyling;
}

interface FormShape {}

interface StateShape {}

// This extra class is needed to properly pass the form name via props
class FormComponent extends React.Component<PropShape, {}> {
    private MyFormComponent: any;
    constructor(props: PropShape) {
        super(props);
        this.MyFormComponent = reduxForm<FormShape, PropShape>({
            form: this.props.formName,
            destroyOnUnmount: this.props.destroyOnUnmount,
            validate: this.props.validate,
            initialValues: this.props.initialValues
        })(InternalFormComponent);
    }

    componentDidUpdate(oldProps: PropShape) {
        if (oldProps.formName !== this.props.formName) {
            // We must recreate the form and force an update to render the new form
            // Two forms with only a name difference are too similar for react to re-render
            // If mounting a form causes that form's name to change, this could result in an infinite loop
            this.MyFormComponent = reduxForm<FormShape, PropShape>({
                form: this.props.formName,
                destroyOnUnmount: false,
                validate: this.props.validate
            })(InternalFormComponent);
            this.forceUpdate();
        }
    }

    static defaultProps = {
        disabled: false,
        destroyOnUnmount: false,
        initialValues: undefined,
        stylingOptions: undefined
    };

    render() {
        return <this.MyFormComponent {...this.props} key={'form-' + this.props.formName} />;
    }
}

export default FormComponent;
