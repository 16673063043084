export enum Relationships {
    Spouse = 'Spouse',
    Parent = 'Parent',
    Child = 'Child',
    Sibling = 'Sibling',
    Niece = 'Niece',
    Nephew = 'Nephew',
    Aunt = 'Aunt',
    Uncle = 'Uncle',
    Grandchild = 'Grandchild',
    Grandparent = 'Grandparent',
    LegalPartner = 'Legal Partner',
    PowerOfAttorney = 'Power of Attorney',
    NotListed = 'Not listed'
}

export enum BeneficiaryRelationships {
    Spouse = 'Spouse',
    Parent = 'Parent',
    Child = 'Child',
    Sibling = 'Sibling',
    Grandchild = 'Grandchild',
    Grandparent = 'Grandparent',
    Stepchild = 'Stepchild',
    Stepsibling = 'Stepsibling',
    Stepparent = 'Stepparent',
    Aunt = 'Aunt',
    Uncle = 'Uncle',
    Niece = 'Niece',
    Nephew = 'Nephew',
    Friend = 'Friend'
}
