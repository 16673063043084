import { connect } from 'react-redux';
import EmptyComponent from 'components/global-components/empty-component/EmptyComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import withTheme from '@material-ui/styles/withTheme';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import EarthBackButtonLinkComponent from './EarthBackButtonLinkComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        textColor: state.stylingOptions.colors.bodyColor,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(EarthBackButtonLinkComponent));
