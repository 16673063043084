import { connect } from 'react-redux';
import GlobalStateShape from 'redux/GlobalStateShape';
import { getTravelTotal } from 'toolboxes/reuseable-logic/pricing-calculator';
import EarthPaymentPlanDetailsComponent from './EarthPaymentPlanDetailsComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        selectedFuneralPaymentPlan: state.opportunity.pricing.paymentOptions.selectedOption,
        hasTravel: !!state.travelOpportunity.id,
        travelTotal: getTravelTotal(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EarthPaymentPlanDetailsComponent);
