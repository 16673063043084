import PrintIcon from '@material-ui/icons/PrintOutlined';
import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';
import React from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';

class TrustPurchaseProcessing extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        vitalStatisticsUrl: '',
        opportunityId: '',
        paymentMethod: '',
        funeralHomeName: '',
        email: '',
        formIsValid: false,
        contactPresent: true,
        funeralHomePhone: '',
        recipientName: '',
        featureVitalStatistics: true
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaseProcessing"
                metaPageNumber="5"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>Thank you.</PageCardTitleWrapper>
                    <PageCardTextWrapper>Arrangements Confirmation: {this.props.opportunityId}</PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Thank you for making your prearrangements with {this.props.funeralHomeName}. Be on the lookout
                        for an email from {this.props.email || 'us'} with an order confirmation.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Once our staff reviews and approves your order, we will charge the {this.props.paymentMethod}{' '}
                        you have provided. When this happens, we will send another confirmation email along with a copy
                        of the paperwork. At that time, we will also send a copy to{' '}
                        {this.props.fundingProviderAccountKnownAs ?? this.props.fundingProviderAccountName}, and they
                        will process your planning agreement.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        We will also maintain a copy of your application at our office.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        {this.props.funeralHomePhone &&
                            `If you have any questions, please don't hesitate to contact us during regular business hours at ${formatPhoneNumber(
                                this.props.funeralHomePhone
                            )}. `}
                        Thank you again for trusting {this.props.funeralHomeName} with your preneed planning
                        arrangements.
                    </PageCardTextWrapper>
                    <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
                        <MockLinkWrapper onClick={() => window.print()} id="Print-Button">
                            <PrintIcon color="primary" fontSize="inherit"></PrintIcon> Print your receipt
                        </MockLinkWrapper>
                    </div>
                    {this.props.featureVitalStatistics && (
                        <ButtonLinkWrapper to={urlPaths.vitalStatistics} buttonId="Complete Your Arrangement">
                            Complete Your Arrangement
                        </ButtonLinkWrapper>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    vitalStatisticsUrl: string;
    opportunityId: string;
    paymentMethod: string;
    funeralHomeName: string;
    email: string;
    formIsValid: boolean;
    contactPresent: boolean;
    funeralHomePhone: string;
    recipientName: string;
    featureVitalStatistics: boolean;
    fundingProviderAccountKnownAs: string;
    fundingProviderAccountName: string;
}

interface StateShape {
    // Shape of local state
}

export default TrustPurchaseProcessing;
