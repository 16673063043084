import React from 'react';
import TextBoxWrapper from '../text-box-component/TextBoxWrapper';
import { normalizePrice } from '../../../../toolboxes/redux-form/redux-form-normalizers';
import { InputAdornment } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PropShape, textBoxDefaultProps } from '../text-box-component/TextBoxComponent';

class PriceInputComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = textBoxDefaultProps;

    render() {
        return (
            <TextBoxWrapper
                {...this.props}
                normalize={normalizePrice}
                extraFieldProps={{
                    ...this.props.extraFieldProps,
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachMoneyIcon />
                        </InputAdornment>
                    )
                }}
            />
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default PriceInputComponent;
