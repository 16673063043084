import { Button } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { Redirect } from 'react-router-dom';
import ApplicationStyling from 'static/models/ApplicationStyling';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

class EarthButtonSubtitledLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false,
            isHovered: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        // Default prop values
        navigationSideEffect: null,
        buttonTitleFontFamily: '',
        stylingOptions: undefined
    };

    handleClick() {
        if (this.props.navigationSideEffect) {
            this.props.navigationSideEffect();
        }
        this.setState({ redirecting: true });
    }

    render() {
        if (this.state.redirecting) {
            return <Redirect to={this.props.to} push />;
        }
        let buttonStyle: CSSProperties = {
            color: this.props.stylingOptions.colors.formFieldsTextColor,
            backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
            fontSize: this.props.stylingOptions.fonts.bodyFontSize,
            fontFamily: this.props.stylingOptions.fonts.bodyFontFamily,
            width: '100%',
            justifyContent: 'space-between',
            height: '51px'
        };
        return (
            <div style={{ marginBottom: this.props.stylingOptions.spacing.innerBody }}>
                <Button
                    onMouseEnter={() => this.setState({ isHovered: true })}
                    onMouseLeave={() => this.setState({ isHovered: false })}
                    disableElevation={!this.state.isHovered}
                    onClick={this.handleClick}
                    id={'button-' + this.props.buttonId}
                    style={buttonStyle}
                    endIcon={<TrendingFlatIcon />}
                    variant="contained"
                >
                    <div style={{ textAlign: 'left' }}>
                        <div>{this.props.title}</div>
                        <div>{this.props.subtitle}</div>
                    </div>
                </Button>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    to: string;
    title: string;
    subtitle: string;
    navigationSideEffect: Function;
    buttonId: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
    isHovered: boolean;
}

export default EarthButtonSubtitledLinkComponent;
