import React from 'react';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import ContractSigningCredentials, {
    ContractSigningCredentialStatusOptions
} from '../../../../../static/models/ContractSigningCredentials';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
    borderless: {
        borderRadius: 3,
        border: 0
    }
});

const PurchaserSignedAlertComponent: React.FunctionComponent<PurchaserSignedAlertProps> = ({
    allContractSigningCredentials = []
}) => {
    const styles = useStyles();
    const isComplete = allContractSigningCredentials.every(
        e => e.signingStatus === ContractSigningCredentialStatusOptions.signed
    );
    const sortedCredentials = [...allContractSigningCredentials].sort(function(a, b) {
        if (parseInt(a.signingOrder) < parseInt(b.signingOrder)) return -1;
        if (parseInt(a.signingOrder) > parseInt(b.signingOrder)) return 1;
        return 0;
    });

    if (allContractSigningCredentials.length === 0) {
        return (
            <Alert icon={false} classes={{ outlinedInfo: styles.borderless }} variant="outlined" color="info">
                Contract Not Created
            </Alert>
        );
    } else if (isComplete) {
        return <Alert color="success">Contract Complete</Alert>;
    } else {
        return (
            <>
                <Alert color="info">Signatures</Alert>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Role</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Signer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedCredentials.map(credential => (
                            <TableRow key={credential.id}>
                                <TableCell>{credential.signingRole}</TableCell>
                                <TableCell>{credential.signingStatus}</TableCell>
                                <TableCell>{credential.signerContact}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    }
};

export interface PurchaserSignedAlertProps {
    // Shape of passed in props (including redux dispatch functions)
    allContractSigningCredentials?: ContractSigningCredentials[];
}

PurchaserSignedAlertComponent.propTypes = { allContractSigningCredentials: PropTypes.array };

export default PurchaserSignedAlertComponent;
