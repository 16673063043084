import React from 'react';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';

class StateFilingFeePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setStateFilingFeeAction: () => {},
        amount: '',
        fee: '',
        totalAmount: '',
        state: '',
        purchaserFirstName: '',
        purchaserLastName: '',
        isHomesteadersAgent: false
    };

    generateDisclaimerText() {
        if (this.props.isHomesteadersAgent) {
            return (
                <div>
                    The state of {this.props.state} requires a {this.props.amount} filing fee for prearrangements. This
                    is separate from the total cost of the arrangement planned. At this time we don't have the ability
                    to collect this digitally and will need you to collect this from {this.props.purchaserFirstName}{' '}
                    {this.props.purchaserLastName}.
                </div>
            );
        } else {
            return (
                <div>
                    A fee of {this.props.amount} is required by the state of {this.props.state} to process the paperwork
                    that guarantees your funeral. We charge a convenience fee of {this.props.fee} to complete this
                    transfer on your behalf. When your funeral purchase is complete, you will notice a second charge{' '}
                    {this.props.totalAmount} to cover these fees.
                </div>
            );
        }
    }

    render() {
        return (
            <StandardDisclaimerPageWrapper
                disclaimerTitle="State Filing Fee"
                acceptTo={urlPaths.orderSummary}
                acceptanceText="I agree"
                rejectionText=""
                rejectTo=""
                saveDisclaimerAddressingToRedux={this.props.setStateFilingFeeAction}
                pageName="StateFilingFee"
                pageBack="true"
                pageNumber="1"
            >
                {this.generateDisclaimerText()}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    amount: string;
    fee: string;
    totalAmount: string;
    state: string;
    purchaserFirstName: string;
    purchaserLastName: string;
    setStateFilingFeeAction: (saveDisclaimer: DisclaimerAddressing) => void;
    isHomesteadersAgent: boolean;
}

interface StateShape {
    // Shape of local state
}

export default StateFilingFeePageComponent;
