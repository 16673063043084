import React from 'react';
import './EarthHeaderComponent.css';
import ApplicationStyling from '../../../../../static/models/ApplicationStyling';
// TODO - Revert back after holidays
// import EarthHeaderLogo from '../../../../../static/media/earth-header-logo.svg';
import EarthHeaderLogo from '../../../../../static/media/earth-header-logo.svg';
import EarthProgressBarWrapper from '../progress-bar-component/EarthProgressBarWrapper';
import PageCardComponent from 'components/global-components/page-card-components/page-card-component/PageCardComponent';
import { EARTH_HEADER_BREAK_POINT } from 'static/constants/styling-company-constants/companies/earth';
class EarthHeaderComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            windowWidth: window.innerWidth
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined
    };

    componentDidMount(): void {
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        if (!this.props.stylingOptions.loadingComplete) {
            return '';
        }
        const needsMobileLayout = this.state.windowWidth < EARTH_HEADER_BREAK_POINT;
        return (
            <header
                className="ef-header-wrapper"
                style={{
                    borderBottom: needsMobileLayout
                        ? ''
                        : `1px solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    backgroundColor: needsMobileLayout
                        ? '#F5F3E6'
                        : this.props.stylingOptions.colors.headerBackgroundColor
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {needsMobileLayout ? (
                        <EarthProgressBarWrapper />
                    ) : (
                        <PageCardComponent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '-24px',
                                    marginBottom: '-24px',
                                    paddingTop: '10px',
                                    paddingBottom: '10px'
                                }}
                            >
                                <img alt="earth logo" src={EarthHeaderLogo} height="36px" />
                                <EarthProgressBarWrapper />
                            </div>
                        </PageCardComponent>
                    )}
                </div>
            </header>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    windowWidth: number;
}

export default EarthHeaderComponent;
