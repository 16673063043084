import React from 'react';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import GeneralModalWrapper from '../../general-modal-component/GeneralModalWrapper';
import Button from '@material-ui/core/Button';
import ApplicationStyling from 'static/models/ApplicationStyling';

class HealthQuestionInstructionsModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        wordForPackage: 'package',
        stylingOptions: undefined
    };

    render() {
        return (
            <GeneralModalWrapper
                // Can't close manually
                onRequestClose={() => {}}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Health History Questionnaire</PageCardTitleWrapper>
                    <div
                        style={{
                            marginTop: this.props.stylingOptions?.spacing?.abovePageSubtitle ? '' : '80px',
                            marginBottom: this.props.stylingOptions?.spacing?.aboveContinueButton ? '' : '80px'
                        }}
                    >
                        <PageCardTextWrapper>
                            We need to ask a few health related questions to determine what insurance rates and{' '}
                            {this.props.wordForPackage}s you would qualify to receive. Please answer to the best of your
                            ability.
                        </PageCardTextWrapper>
                    </div>
                    <Button
                        onClick={() => this.props.onContinue()}
                        id="instructions-continue"
                        variant="contained"
                        color="primary"
                        style={{
                            marginTop: this.props.stylingOptions?.spacing?.aboveContinueButton
                                ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                                : '',
                            fontSize: this.props.stylingOptions?.fonts?.buttonFontSize
                        }}
                    >
                        Continue
                    </Button>
                </PageCardWrapper>
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    onContinue: Function;
    wordForPackage: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default HealthQuestionInstructionsModalComponent;
