import GlobalStateShape from '../../redux/GlobalStateShape';
import {
    runUpdateProductsOnOpportunity,
    runCreateAgentProduct,
    runCreateSalesForceAgentProduct
} from '../web-requests/specific-requests/product-requests';
import Product from '../../static/models/Product';

export function updateProductsOnOpportunity(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const productsToSend = state.opportunity.products.map(product => {
        return {
            id: product.productId,
            name: product.name,
            quantity: product.quantity,
            price: product.price
        };
    });
    runUpdateProductsOnOpportunity(state.opportunity.id, productsToSend, (result, error) => {
        if (error) {
            return callback(null, error);
        }
        return callback(null, null);
    });
}

export function createAgentProduct(
    product: Product,
    funeralAgentNumber: string,
    agentId: string,
    accountId: string,
    useSalesForceAgents: boolean,
    callback: (Result: any, error: any) => void
) {
    // Using the salesforce model
    let productToSend = {
        name: product.name,
        category: product.category,
        subcategory: product.subcategory,
        price: product.price,
        salesTaxApplicable: product.taxIsApplicable,
        explicitSalesTax: product.explicitTax,
        guaranteed: product.priceIsGuaranteed,
        imageURL: product.imageUrl,
        salesDescription: product.salesDescription,
        contractDescription: product.salesDescription,
        manufacturer: product.manufacturer,
        modelNumber: product.modelNumber,
        materialType: product.material,
        interiorDescription: product.interiorDescription,
        exteriorDescription: product.exteriorDescription,
        modelName: product.modelName,
        dispositionTypes: [product.disposition],
        goodsAndServicesStatement: product.goodsAndServicesContractLabel,
        accountId: accountId
    };
    if (useSalesForceAgents) {
        runCreateSalesForceAgentProduct(productToSend, agentId, accountId, callback);
    } else {
        runCreateAgentProduct(productToSend, funeralAgentNumber.toUpperCase(), callback);
    }
}
