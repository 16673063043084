import { combineReducers } from 'redux';
import { setOpportunityPurchaserIdReducer } from './id';
import { setPurchaserPhoneVerifiedReducer } from './phoneVerified';
import { setTimesPurchaserPhoneVerificationSubmittedReducer } from './timesPhoneVerificationSubmitted';
import { setPurchaserPhoneVerificationRequestedReducer } from './phoneVerificationRequested';
import { setOpportunityPurchaserPhoneNumberReducer } from './phoneNumber';
import { setPurchaserSsnProvidedReducer } from './ssnProvided';
import { setOpportunityPurchaserIdentityVerificationPlaidReducer } from './identityVerificationPlaid';

export default combineReducers({
    id: setOpportunityPurchaserIdReducer,
    phoneNumber: setOpportunityPurchaserPhoneNumberReducer,
    phoneVerificationRequested: setPurchaserPhoneVerificationRequestedReducer,
    phoneVerified: setPurchaserPhoneVerifiedReducer,
    timesPhoneVerificationSubmitted: setTimesPurchaserPhoneVerificationSubmittedReducer,
    ssnProvided: setPurchaserSsnProvidedReducer,
    identityVerificationPlaid: setOpportunityPurchaserIdentityVerificationPlaidReducer
});
