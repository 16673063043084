import { connect } from 'react-redux';
import EverdaysBeneficiaryCheckPageComponent from './EverdaysBeneficiaryCheckPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import { setBeneficaryProvidedAction } from 'redux/yesNoAnswers/policyQuestions/beneficiaryProvided';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        disposition: state.opportunity.disposition,
        firstPage: firstPageCalculator(state, urlPaths.beneficiaryCheck)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setBeneficaryProvidedAction: newValue => dispatch(setBeneficaryProvidedAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EverdaysBeneficiaryCheckPageComponent);
