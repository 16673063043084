import { connect } from 'react-redux';
import OrderSummaryPageComponent from './OrderSummaryPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import {
    getCartTotal,
    getFuneralSalesTax,
    getFuneralSubtotal,
    getFuneralTotal,
    getMileageCost,
    getTravelSalesTax,
    getTravelSubtotal,
    getTravelTotal
} from 'toolboxes/reuseable-logic/pricing-calculator';
import replacementStates from 'static/constants/stateSpecificLists/replacementStates';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { setIrrevocableAction } from 'redux/yesNoAnswers/policyQuestions/irrevocable';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { getMileageFeeProduct } from '../../../toolboxes/reuseable-logic/product-category-display-calculator';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import EarthOrderSummaryPageWrapper from 'components/account-specific-components/accounts/earth/order-summary-page-component/EarthOrderSummaryPageWrapper';
import { getDispositionText } from 'toolboxes/reuseable-logic/language-utils';
import { FundingProductType } from '../../../static/constants/enums/fundingProductType';
import { TITAN_STYLING_KEY } from '../../../static/constants/styling-company-constants/companies/titan';
import { showReplacementCalculator } from 'toolboxes/reuseable-logic/show-replacement-calculator';

const getIsComplete = (state: GlobalStateShape, isReplacementState: boolean): boolean => {
    if (isAtNeed(state)) {
        return true;
    }
    if (![true, false].includes(state.yesNoAnswers.policyQuestions.irrevocable)) {
        return false;
    }
    if (isReplacementState && state.opportunity.selectedFundingProduct.productType !== FundingProductType.Trust) {
        if (![true, false].includes(state.yesNoAnswers.policyQuestions.hasCurrentPolicies)) {
            return false;
        }
        if (
            state.yesNoAnswers.policyQuestions.hasCurrentPolicies &&
            ![true, false].includes(state.yesNoAnswers.policyQuestions.replacingCurrentPolicies)
        ) {
            return false;
        }
    }
    return true;
};

const displayReplacement = (state: GlobalStateShape): boolean => {
    return showReplacementCalculator(state);
};

const displaySubcategories = (state: GlobalStateShape): boolean => {
    return state.account?.stylingKey !== TITAN_STYLING_KEY;
};

const formatProductPricesWithCommas = (state: GlobalStateShape): boolean => {
    return state.account?.stylingKey === TITAN_STYLING_KEY;
};

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const purchaser = getFormValues('purchaser')(state);
    let recipient: any = getFormValues('recipient')(state);
    let isReplacementState = replacementStates.includes(getFormData(state, 'purchaser', 'state'));
    if (state.opportunity.recipient.sameAsPurchaser) {
        recipient = purchaser;
    }

    let products = [...state.opportunity.products];

    if (isAtNeed(state)) {
        if (getMileageCost(state) > 0) {
            products.push(getMileageFeeProduct(state.opportunity.mileageCost));
        }
    }

    return {
        displayIrrevocable: state.account.stylingKey !== EVERDAYS_STYLING_KEY,
        dispositionText: getDispositionText(state, true),
        funeralSalesTax: getFuneralSalesTax(state),
        funeralSubtotal: getFuneralSubtotal(state),
        funeralTotal: getFuneralTotal(state),
        isAtNeed: isAtNeed(state),
        isComplete: getIsComplete(state, isReplacementState),
        isIrrevocable: state.yesNoAnswers.policyQuestions.irrevocable,
        isReplacementState,
        package: '',
        pricingData: state.opportunity.pricing,
        primaryColor: state.stylingOptions.colors.primaryColor,
        products: products,
        purchaser,
        recipient,
        recipientSameAsPurchaser: state.opportunity.recipient.sameAsPurchaser,
        total: getCartTotal(state),
        mileageCost: state.opportunity.mileageCost,
        travelProducts: state.travelOpportunity.products,
        travelSalesTax: getTravelSalesTax(state),
        travelSubtotal: getTravelSubtotal(state),
        travelTotal: getTravelTotal(state),
        displayReplacement: displayReplacement(state),
        displaySubcategories: displaySubcategories(state),
        formatProductPricesWithCommas: formatProductPricesWithCommas(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIrrevocableAction: newValue => dispatch(setIrrevocableAction(newValue))
    };
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(OrderSummaryPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EARTH_STYLING_KEY]: EarthOrderSummaryPageWrapper
});
