import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class VitalStatisticsSavedComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="" metaPageNumber="1" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Thank you</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Your information has been saved, and your purchase is processing. We appreciate you taking the
                        time to fill out these forms.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default VitalStatisticsSavedComponent;
