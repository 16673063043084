import React from 'react';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import PhoneVerificationRequestFormWrapper from '../../form-components/phone-verification-request-form-component/PhoneVerificationRequestFormWrapper';

class PurchaserPhoneVerificationRequestPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formIsValid: false,
        purchaserInfo: {}
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaserPhoneVerificationRequest"
                metaPageNumber="4"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Purchaser Verification</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        We take your identity and information seriously. In order to proceed, we need to verify it's
                        you.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>How would you like to receive your verification code?</PageCardTextWrapper>
                    <PhoneVerificationRequestFormWrapper
                        formName="purchaserPhoneVerification"
                        phoneNumber={this.props.purchaserInfo.phone}
                    />
                    <ButtonLinkWrapper
                        to={urlPaths.purchaserPhoneVerify}
                        buttonId="submit"
                        disabled={!this.props.formIsValid}
                    >
                        Next
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formIsValid: boolean;
    purchaserInfo: any;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserPhoneVerificationRequestPageComponent;
