import React from 'react';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import urlPaths from '../../../../static/constants/enums/urlPaths';

class PrefilledRecipientAgreementPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        recipientFirstName: '',
        recipientLastName: '',
        trustText: ''
    };

    render() {
        return (
            <StandardDisclaimerPageWrapper
                acceptTo={urlPaths.prefilledRecipientGratitude}
                rejectTo=""
                acceptanceText="I Agree"
                rejectionText=""
                disclaimerTitle="Consent To Be Insured"
                saveDisclaimerAddressingToRedux={newValue => this.props.setRecipientConsentAction(newValue)}
                extraContentLength={false}
                pageName="PrefilledRecipientAgreement"
                pageBack="false"
                pageNumber=""
            >
                I, {this.props.recipientFirstName} {this.props.recipientLastName}, understand that a{' '}
                {this.props.trustText}
                policy is being purchased on my life and agree to that purchase.
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    recipientFirstName: string;
    recipientLastName: string;
    setRecipientConsentAction: Function;
    trustText: string;
}

interface StateShape {
    // Shape of local state
}

export default PrefilledRecipientAgreementPageComponent;
