import React from 'react';
import PageCardTitleWrapper from '../../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import { formatMonetaryAmount } from '../../../../../toolboxes/reuseable-logic/string-formatters';

class TotalDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        total: '',
        formatProductPricesWithCommas: false
    };

    render() {
        return (
            <PageCardTitleWrapper id="cart-total">
                {formatMonetaryAmount(this.props.total, this.props.formatProductPricesWithCommas)}
            </PageCardTitleWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    total: string;
    formatProductPricesWithCommas: boolean;
}

interface StateShape {
    // Shape of local state
}

export default TotalDisplayComponent;
