import { connect } from 'react-redux';
import IrrevocableHelpModalComponent from './IrrevocableHelpModalComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getPolicyContractText } from '../../../../../toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        policyContractLanguage: getPolicyContractText(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrevocableHelpModalComponent);
