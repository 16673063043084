import React from 'react';
import Product from '../../../../static/models/Product';
import GeneralProductsDisplayWrapper from '../general-product-display-component/GeneralProductsDisplayWrapper';

class FuneralHomeProductsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        availableProducts: [],
        addProductAction: () => {}
    };

    render() {
        return (
            <GeneralProductsDisplayWrapper
                availableProducts={this.props.availableProducts}
                addProductAction={this.props.addProductAction}
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    availableProducts: Product[];
    addProductAction: (Product) => void;
}

interface StateShape {
    // Shape of local state
}

export default FuneralHomeProductsDisplayComponent;
