import { connect } from 'react-redux';
import UnderageRecipientPageComponent from './UnderageRecipientPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const phoneNumber = state.opportunity.referralAccountPhone
        ? formatPhoneNumber(state.opportunity.referralAccountPhone)
        : formatPhoneNumber(state.account.phone);
    return {
        accountPhoneNumber: phoneNumber
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnderageRecipientPageComponent);
