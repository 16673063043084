// Action Type

import ContractSigningCredentials, {
    ContractSigningCredentialStatusOptions
} from 'static/models/ContractSigningCredentials';

export const SET_ACTIVE_CONTRACT_SIGNING_CREDENTIALS = 'SET_ACTIVE_CONTRACT_SIGNING_CREDENTIALS';
// Action
export function setActiveContractSigningCredentials(newValue: ContractSigningCredentials | null) {
    return { type: SET_ACTIVE_CONTRACT_SIGNING_CREDENTIALS, newValue };
}
// Reducer
export function setActiveContractSigningCredentialsReducer(state: any, action: any): ContractSigningCredentials | null {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_ACTIVE_CONTRACT_SIGNING_CREDENTIALS:
            return action.newValue;
        default:
            return state;
    }
}
