// Action Type
export const UPDATE_HAS_CURRENT_POLICIES = 'UPDATE_HAS_CURRENT_POLICIES';
// Action
export function setHasCurrentPoliciesAction(newValue: boolean) {
    return { type: UPDATE_HAS_CURRENT_POLICIES, newValue };
}
// Reducer
export function setHasCurrentPoliciesReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case UPDATE_HAS_CURRENT_POLICIES:
            return action.newValue;
        default:
            return state;
    }
}
