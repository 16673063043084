import GlobalStateShape from 'redux/GlobalStateShape';
import {
    validateAtNeedRecipient,
    validatePerson,
    validatePurchaser,
    validatePurchaserGenderAndBirthdateNotRequired,
    validateRecipient,
    validateRecipientWithOnlyFourSSN
} from 'toolboxes/redux-form/redux-form-validation';
import { isAtNeed } from './opportunity-timing-utils';

export const getPurchaserValidationFunction = (state: GlobalStateShape) => {
    if (isAtNeed(state) || state.opportunity.recipient.sameAsPurchaser === false) {
        return validatePurchaserGenderAndBirthdateNotRequired;
    }

    return validatePerson;
};

export const getRecipientValidationFunction = (state: GlobalStateShape, isLoadingOpportunity: boolean = false) => {
    if (isAtNeed(state)) {
        return validateAtNeedRecipient;
    }
    if (state.opportunity.recipient.sameAsPurchaser) {
        return validatePerson;
    }
    if (isLoadingOpportunity) {
        return validatePerson; //We are not passed back the SSN on loads so can only validate on Person
    }
    return state.featureFlags.SSNToLast4Digits ? validateRecipientWithOnlyFourSSN : validateRecipient;
};
