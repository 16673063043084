import Product from '../../static/models/Product';

// Action Type
export const SET_TRAVEL_PROTECTION_PROVIDER_PRODUCTS = 'SET_TRAVEL_PROTECTION_PROVIDER_PRODUCTS';
// Action
export function setTravelProtectionProviderProductsAction(newValue: Product[]) {
    return { type: SET_TRAVEL_PROTECTION_PROVIDER_PRODUCTS, newValue };
}
// Reducer
export function setTravelProtectionProviderProductsReducer(state: any, action: any): Product[] {
    if (typeof state === 'undefined') {
        // Initial data
        return [];
    }
    switch (action.type) {
        case SET_TRAVEL_PROTECTION_PROVIDER_PRODUCTS:
            return action.newValue;
        default:
            return state;
    }
}
