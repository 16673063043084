import React from 'react';
import ApplicationStyling from 'static/models/ApplicationStyling';

class ButtonLinkGroupComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined
    };

    render() {
        const bgColor: string =
            this.props.stylingOptions && this.props.stylingOptions.navigationButtonGroupAlignment
                ? this.props.stylingOptions.navigationButtonGroupAlignment.backgroundColor
                : '';
        return (
            <div className="ButtonLinkGroup" style={{ backgroundColor: bgColor }}>
                {this.props.children}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default ButtonLinkGroupComponent;
