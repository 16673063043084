import FundingProduct from '../../static/models/FundingProduct';

// Action Type
export const SET_OPPORTUNITY_SELECTED_FUNDING_PRODUCT = 'SET_OPPORTUNITY_SELECTED_FUNDING_PRODUCT';
// Action
export function setSelectedFundingProductAction(newValue: FundingProduct) {
    return { type: SET_OPPORTUNITY_SELECTED_FUNDING_PRODUCT, newValue };
}
// Reducer
export function setSelectedFundingProductReducer(state: any, action: any): FundingProduct {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_OPPORTUNITY_SELECTED_FUNDING_PRODUCT:
            return action.newValue;
        default:
            return state;
    }
}
