import urlPaths from 'static/constants/enums/urlPaths';
import { sendSaveIrrevocabilityDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';
import { sendSaveIrrevocableData } from 'toolboxes/server-application-buffer/general-opportunity-data';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export function irrevocableExitFunctions(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];
    if ([true, false].includes(state.yesNoAnswers.policyQuestions.irrevocable)) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveIrrevocableData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveIrrevocabilityDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }
    return functionsToRun;
}
