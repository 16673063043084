import React from 'react';
import IdentityConfirmationFormWrapper from 'components/form-components/identity-confirmation-form-component/IdentityConfirmationFormWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';

class EverdaysPurchaserIdentityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        purchaserFirstName: '',
        purchaserId: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="Identity" metaPageNumber="3" metaPagebackAvailable="false">
                <PageCardTitleWrapper>Confirm Your Identity</PageCardTitleWrapper>
                <IdentityConfirmationFormWrapper
                    nextUrl={urlPaths.funeralContracts}
                    contactFirstName={this.props.purchaserFirstName}
                    contactId={this.props.purchaserId}
                    disclaimerAddressingLocaiton={this.props.setPurchaserPlaidIdentityConsentDisclaimerAddressingAction}
                />
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    purchaserFirstName: string;
    purchaserId: string;
    setPurchaserPlaidIdentityConsentDisclaimerAddressingAction: () => void;
}

interface StateShape {
    // Shape of local state
}

export default EverdaysPurchaserIdentityPageComponent;
