// Action Type
export const SET_RECIPIENT_EXISTANCE_PREDETERMINED = 'SET_RECIPIENT_EXISTANCE_PREDETERMINED';
// Action
export function setRecipientExistancePredeterminedAction(newValue: boolean) {
    return { type: SET_RECIPIENT_EXISTANCE_PREDETERMINED, newValue };
}
// Reducer
export function setRecipientExistancePredeterminedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_RECIPIENT_EXISTANCE_PREDETERMINED:
            return action.newValue;
        default:
            return state;
    }
}
