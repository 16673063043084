import { connect } from 'react-redux';
import FuneralAgentFormComponent from './FuneralAgentFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { FundingProductType } from '../../../static/constants/enums/fundingProductType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    let fundingProductId = '';
    const insuranceFundingProduct = state.account.fundingProducts.find(
        fundingProduct => fundingProduct.productType === FundingProductType.Insurance
    );
    if (insuranceFundingProduct) {
        fundingProductId = insuranceFundingProduct.productId;
    }
    return {
        funeralHomeProductId: fundingProductId
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralAgentFormComponent);
