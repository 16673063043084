// Action Type
export const SET_TAX_RATE = 'SET_TAX_RATE';
// Action
export function setTaxRateAction(newValue: number) {
    return { type: SET_TAX_RATE, newValue };
}
// Reducer
export function setTaxRateReducer(state: any, action: any): number {
    if (typeof state === 'undefined') {
        // Initial data
        return 0;
    }
    switch (action.type) {
        case SET_TAX_RATE:
            return action.newValue;
        default:
            return state;
    }
}
