import React from 'react';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import SectionDividerWrapper from '../order-summary-page-component/page-specific-components/section-divider-component/SectionDividerWrapper';
import ButtonFunctionWrapper from 'components/global-components/buttons/button-function-component/ButtonFunctionWrapper';
import { runCreateAccessCode } from 'toolboxes/server-application-buffer/access-code-data';
import './RepresentativeCompletePageComponent.css';
import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CircularProgress } from '@material-ui/core';
import { getBasePath } from 'toolboxes/reuseable-logic/get-base-path';

class RepresentativeCompletePageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            accessCode: null,
            generating: false,
            copied: false
        };
    }

    static defaultProps = {
        // Default prop values
        opportunityId: '',
        purchaserFullName: '',
        purcahserEmail: ''
    };

    accessCodeRef = React.createRef<HTMLDivElement>();

    getStartPlanningUrl = (): string => {
        return window.location.origin + getBasePath() + '/startPlanning';
    };

    handleGenerateAccessCode = () => {
        this.setState({ generating: true });
        runCreateAccessCode(this.props.opportunityId, (result, error) => {
            this.setState({ accessCode: result, generating: false });
        });
    };

    handleCopyAccessCode = () => {
        navigator.clipboard.writeText(this.state.accessCode + '\n' + this.getStartPlanningUrl());
        this.setState({ copied: true });
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="RepresentativeIdentity"
                metaPageNumber="4"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTextWrapper>
                        Before the contract can be signed, the customer must verify their identity. The funeral home
                        representative cannot do this on behalf of the customer, so the customer must go through the
                        verification process by answering a few questions. The customer has two ways they can access the
                        identity questions:
                    </PageCardTextWrapper>
                    <SectionDividerWrapper />
                    <PageCardTextWrapper>
                        A. The email that has been sent to {this.props.purcahserEmail} has a link they can click to
                        access the identity questions. Please allow 5-10 minutes for {this.props.purchaserFullName} to
                        receive the email.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        B. You can generate an access code below and provide it to {this.props.purchaserFullName} to
                        manually enter at {this.getStartPlanningUrl()} for access to the identity questions.
                    </PageCardTextWrapper>
                    <SectionDividerWrapper />
                    {this.state.accessCode && (
                        <PageCardTextWrapper>
                            <div>Navigate to the link below in your web browser and enter the access code:</div>
                            <div className="accessCodeBlock">
                                <div className="accessCode">{this.state.accessCode}</div>
                                <div>{this.getStartPlanningUrl()}</div>
                            </div>
                            <MockLinkWrapper id="copy-access-code" onClick={this.handleCopyAccessCode}>
                                <span className="copyAccessCode">
                                    {this.state.copied ? (
                                        <CheckCircleOutlineIcon fontSize="small" />
                                    ) : (
                                        <FileCopyIcon fontSize="small" />
                                    )}
                                    copy access code and url
                                </span>
                            </MockLinkWrapper>
                            {/* *once button is clicked, the code appears along with this copy beneath it:*
                            Navigate to the link below in your web browser and enter the access code: [ACCESS CODE GENERATED]
                            [URL] */}
                        </PageCardTextWrapper>
                    )}
                    {!this.state.accessCode && (
                        <ButtonFunctionWrapper
                            buttonId="generate-access-code"
                            onClick={this.handleGenerateAccessCode}
                            disabled={this.state.generating}
                        >
                            {this.state.generating ? <CircularProgress size="1.8rem" /> : 'Generate Access Code'}
                        </ButtonFunctionWrapper>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    opportunityId: string;
    purchaserFullName: string;
    purcahserEmail: string;
}

interface StateShape {
    // Shape of local state
    accessCode: string;
    generating: boolean;
    copied: boolean;
}

export default RepresentativeCompletePageComponent;
