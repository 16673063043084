import {
    fetchAccountBySubdomain,
    fetchProductsForAccountId,
    fetchAgentProductsForAccountId,
    fetchTravelAccountByFuneralAccountId,
    fetchAccountsBySearchTerms
} from '../web-requests/specific-requests/account-requests';
import Product from '../../static/models/Product';

export function getAccountBySubdomain(subdomain: string, callback: (result: any, error: any) => void) {
    fetchAccountBySubdomain(subdomain, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, undefined);
    });
}

export function getTravelAccountIdByFuneralAccountId(
    funeralAccountId: string,
    callback: (result: any, error: any) => void
) {
    fetchTravelAccountByFuneralAccountId(funeralAccountId, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result.id, undefined);
    });
}

const translateServerProductToFronendProduct = (product: any) => {
    return {
        name: product.name,
        price: product.price,
        productId: product.id,
        category: product.category,
        subcategory: product.subCategory,
        quantity: 0,
        taxIsApplicable: product.salesTaxApplicable,
        explicitTax: product.explicitSalesTax,
        imageUrl: product.imageURL,
        salesDescription: product.salesDescription,
        priceIsGuaranteed: product.guaranteed,
        included: product.included
    };
};

export function getProductsForAccountId(accountId: string, callback: (result: any, error: any) => void) {
    fetchProductsForAccountId(accountId, (result, error) => {
        if (error) {
            return callback(result, error);
        }
        let productsList: Product[] = [];
        result.forEach(product => {
            productsList.push(translateServerProductToFronendProduct(product));
        });
        return callback(productsList, error);
    });
}

export function getAgentProductsForAccountId(accountId: string, callback: (result: any, error: any) => void) {
    fetchAgentProductsForAccountId(accountId, (result, error) => {
        if (error) {
            return callback(result, error);
        }
        let productsList: Product[] = [];
        result.forEach(product => {
            productsList.push(translateServerProductToFronendProduct(product));
        });
        return callback(productsList, error);
    });
}

export interface AgentSearchedAccount {
    name: string;
    subdomain: string;
    address: string;
    homesteadersProductCode: string;
    id: string;
}

export function getAccountsBySearchTerms(
    searchTerms: string,
    callback: (result: AgentSearchedAccount[], error: any) => void
) {
    fetchAccountsBySearchTerms(searchTerms, (result, error) => {
        if (error) {
            return callback(null, error);
        }
        const formattedAccounts: AgentSearchedAccount[] = result.map(unformattedAccount => {
            return {
                name: unformattedAccount.name,
                subdomain: unformattedAccount.subdomain,
                address: unformattedAccount.address,
                homesteadersProductCode: unformattedAccount.productCode,
                id: unformattedAccount.id
            };
        });
        return callback(formattedAccounts, null);
    });
}
