// Action Type
export const SET_FUNERAL_CONTRACT_URL_DATE = 'SET_FUNERAL_CONTRACT_URL_DATE';
// Action
export function setFuneralContractUrlDateAction(newValue: Date) {
    return { type: SET_FUNERAL_CONTRACT_URL_DATE, newValue };
}
// Reducer
export function setFuneralContractUrlDateReducer(state: any, action: any): Date {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_FUNERAL_CONTRACT_URL_DATE:
            return action.newValue;
        default:
            return state;
    }
}
