import { connect } from 'react-redux';
import RecipientIdentityPageComponent from './RecipientIdentityPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { setRecipientPlaidIdentityConsentDisclaimerAddressingAction } from 'redux/disclaimerData/disclaimerAddressings/recipientPlaidIdentityConsent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        recipientFirstName: getFormData(state, 'recipient', 'firstName'),
        recipientId: state.opportunity.recipient.id
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setRecipientPlaidIdentityConsentDisclaimerAddressingAction: newValue =>
            dispatch(setRecipientPlaidIdentityConsentDisclaimerAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientIdentityPageComponent);
