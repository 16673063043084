import { connect } from 'react-redux';
import ButtonLinkComponent from './ButtonLinkComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonLinkComponent);
