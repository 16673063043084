// Action Type
export const SET_ERROR_LOGGING_CASE_CREATIONFAILED = 'SET_ERROR_LOGGING_CASE_CREATIONFAILED';
// Action
export function setErrorLoggingCaseCreationFailedAction(newValue: boolean) {
    return { type: SET_ERROR_LOGGING_CASE_CREATIONFAILED, newValue };
}
// Reducer
export function setErrorLoggingCaseCreationFailedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_ERROR_LOGGING_CASE_CREATIONFAILED:
            return action.newValue;
        default:
            return state;
    }
}
