// Action Type
export const SET_TRUST_PAYMENT_MONTHLY = 'SET_TRUST_PAYMENT_MONTHLY';
// Action
export function setTrustPaymentMonthlyAction(newValue: boolean) {
    return { type: SET_TRUST_PAYMENT_MONTHLY, newValue };
}
// Reducer
export function setTrustPaymentMonthlyReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_TRUST_PAYMENT_MONTHLY:
            return action.newValue;
        default:
            return state;
    }
}
