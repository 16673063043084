interface SimpleDate {
    year: string;
    month: string;
    day: string;
}

export function getValuesFromStringDate(date: string): SimpleDate {
    // ['yyyy', 'mm', 'dd']
    try {
        const brokenDownDatePieces = date.split('-');
        return {
            year: brokenDownDatePieces[0],
            month: brokenDownDatePieces[1],
            day: brokenDownDatePieces[2]
        };
    } catch {
        return {
            year: null,
            month: null,
            day: null
        };
    }
}

export function formatDateValuesForState(dateData: SimpleDate): string {
    // YYYY-MM-DD
    const day = dateData.day || '';
    const month = dateData.month || '';
    const year = dateData.year || '';
    return `${year}-${month}-${day}`;
}

export function formatDateValuesForDisplay(dateData: SimpleDate): string {
    // MM/DD/YYYY
    const day = dateData.day || '';
    const month = dateData.month || '';
    const year = dateData.year || '';
    let result = `${month}`;
    if (day) {
        result += `/${day}`;
    }
    if (year) {
        result += `/${year}`;
    }
    return result;
}
