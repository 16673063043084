import { connect } from 'react-redux';
import RecipientPageComponent from './EarthRecipientPageComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormValues, reset } from 'redux-form';
import { formIsValid } from '../../../../../toolboxes/redux-form/redux-form-validation';
import urlPaths from 'static/constants/enums/urlPaths';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { getRecipientValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';
import { areContactsSimilar } from 'toolboxes/reuseable-logic/are-contacts-similar';
import { setOpportunityRecipientSameAsPurchaserAction } from 'redux/opportunity/recipient/sameAsPurchaser';
import { setOpportunityRecipientIdAction } from 'redux/opportunity/recipient/id';
import { setPurchaserRecipientSamePersonAction } from 'redux/disclaimerData/disclaimerAddressings/purchaserRecipientSamePerson';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';

const getNextUrl = (state: GlobalStateShape): string => {
    if (isAtNeed(state)) {
        // At needs go straight to order summary from here
        return urlPaths.orderSummary;
    }
    if (state.opportunity.preneedContractType === PreneedContractType.Trust) {
        return urlPaths.trustFundedPrearrangement;
    }
    return urlPaths.disclaimerProduct;
};

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const validationFunction = getRecipientValidationFunction(state);
    const purchaser = getFormValues('purchaser')(state) as any;
    const recipient = getFormValues('recipient')(state) as any;
    const purchaserAndRecipientAreSimilar =
        !isAtNeed(state) && areContactsSimilar(purchaser, recipient, purchaser.relationshipToRecipient);

    return {
        validationFunction,
        isAtNeed: isAtNeed(state),
        recipientComplete: formIsValid(getFormValues('recipient')(state), validationFunction),
        purchaserAndRecipientAreSimilar,
        nextUrl: getNextUrl(state),
        checkIfPurchaserAndRecipientAreSimilar: state.featureFlags.checkIfPurchaserAndRecipientAreSimilar,
        purchaserName: `${purchaser.firstName} ${purchaser.lastName}`,
        recipientName: `${recipient?.firstName} ${recipient?.lastName}`,
        relationshipToRecipient: getFormData(state, 'purchaser', 'relationshipToRecipient'),
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPurchaserRecipientSamePersonAction: newValue => dispatch(setPurchaserRecipientSamePersonAction(newValue)),
        setSameAsPurchaser: newValue => dispatch(setOpportunityRecipientSameAsPurchaserAction(newValue)),
        clearRecipient: () => {
            dispatch(reset('recipient'));
            dispatch(setOpportunityRecipientIdAction(null));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientPageComponent);
