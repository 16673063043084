import React from 'react';
import './TulipHeaderComponent.css';
import { PropShape } from '../../../../global-components/limb-components/header-component/HeaderComponent';

class TulipHeaderComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        if (!this.props.stylingOptions.logoUrl) {
            return null;
        }
        return (
            <div className="header-bar">
                <div
                    className="tulip-logo-container"
                    style={{ borderColor: this.props.stylingOptions.colors.headerBottomBorderColor }}
                >
                    <img
                        src={this.props.stylingOptions.logoUrl}
                        style={{ marginRight: '30px', maxHeight: '30px' }}
                        alt=""
                    />
                </div>
            </div>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default TulipHeaderComponent;
