import { Timing } from 'static/constants/enums/timing';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from '../../redux-form/redux-form-data-access';

export function demographicsInstructionsReroutes(state: GlobalStateShape) {
    if (!state.disclaimerData.disclaimerAddressings.priceList.hasBeenAcceptedOrPositivelyAddressed) {
        // Doesn't matter to agent
        if (!getFormData(state, 'representative', 'id') && !(state.opportunity.hidePriceListDisclaimer === true)) {
            return urlPaths.earlyPriceListDisclaimer;
        }
    }
    if (state.opportunity.timing === Timing.IMMEDIATE) {
        return urlPaths.purchaser;
    }
}
