import React from 'react';
import Button from '@material-ui/core/Button';
import { defaultTheme } from '../../../../static/styling/default-theme';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class BackButtonLinkComponent extends React.Component<MyPropShape, StateShape> {
    constructor(props: MyPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        theme: defaultTheme,
        textColor: '#000000'
    };

    render() {
        return (
            <span style={{ position: 'absolute', top: '5px', left: '5px' }}>
                <Button variant="text" id="back" onClick={() => window.history.back()}>
                    <ArrowBackIosIcon />
                    Back
                </Button>
            </span>
        );
    }
}

export interface MyPropShape {
    // Shape of passed in props (including redux dispatch functions)
    theme: any;
    textColor: string;
}

interface StateShape {
    // Shape of local state
}

export default BackButtonLinkComponent;
