import React from 'react';
import DocusignIframeWrapper from '../../global-components/docusign-iframe-component/DocusignIframeWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';
import HelloSignSigningWrapper from 'components/global-components/hellosign-signing-component/HelloSignSigningWrapper';

class FuneralContractsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            hasGoneFullScreen: false,
            redirectToTravelContracts: false
        };
    }

    static defaultProps = {
        // Default prop values
        contractsUrl: ''
    };

    componentDidMount() {}

    render() {
        if (this.props.contractsUrl.toLowerCase().includes('hellosign')) {
            if (this.state.redirectToTravelContracts) {
                return <Redirect to={urlPaths.travelContracts} push />;
            }
            return (
                <HelloSignSigningWrapper
                    hellosignEmbeddedUrl={this.props.contractsUrl}
                    onSigningComplete={() =>
                        this.setState({
                            redirectToTravelContracts: true
                        })
                    }
                />
            );
        }
        if (this.state.hasGoneFullScreen) {
            return <DocusignIframeWrapper key="frame-section" contractsUrl={this.props.contractsUrl} />;
        }
        return [
            <FullContentCenterWrapper
                key="description-section"
                metaPageName="Signature"
                metaPageNumber="4"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>We're almost there!</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please take a few moments to complete the necessary legal documents in DocuSign. We've prefilled
                        the forms with the information you{`’`}ve provided, so all you have to do is review and sign the
                        documents. DocuSign is an electronic signature service that we use for your convenience. Please
                        accept their terms of service and sign your name where indicated. When you{`’`}re finished, your
                        purchase will be complete.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>,
            <DocusignIframeWrapper
                key="frame-section"
                contractsUrl={this.props.contractsUrl}
                onGoFull={() => {
                    this.setState({
                        hasGoneFullScreen: true
                    });
                }}
            />
        ];
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    contractsUrl: string;
}

interface StateShape {
    // Shape of local state
    hasGoneFullScreen: boolean;
    redirectToTravelContracts: boolean;
}

export default FuneralContractsPageComponent;
