import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';
import {
    runContactUpdate,
    runBeneficiaryCheckData,
    sendIdentityFailureEmail,
    setStopPageWithDetail,
    runBeneficiaryIsPurchaser
} from '../web-requests/specific-requests/contact-update-requests';
import { translateBooleanIntoYesNoString } from './generalized-data-buffer-tools/yes-no-translator';
import { dataWrapper } from './generalized-data-buffer-tools/data-wrapper';
import { setOpportunityPurchaserIdAction } from '../../redux/opportunity/purchaser/id';
import { setOpportunityRecipientIdAction } from '../../redux/opportunity/recipient/id';
import { getOrGenerateOriginToken } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

export function sendIdentityFailedEmail(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const opportunityId = state.opportunity.id;

    sendIdentityFailureEmail(opportunityId, 'purchaser', (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}

export function setStopPageRelationshipNotListed(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const opportunityId = state.opportunity.id;

    setStopPageWithDetail(opportunityId, 'Stopped because relationship to recipient not listed', (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}

export function sendSaveContactData(
    contactType: string,
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    let formToGetDataFrom = contactType;
    if (formToGetDataFrom === 'recipient' && state.opportunity.recipient.sameAsPurchaser) {
        formToGetDataFrom = 'purchaser';
    }
    const opportunityId = state.opportunity.id;

    const contactData = {
        id: dataWrapper(() => state.opportunity[formToGetDataFrom].id),
        firstName: getFormData(state, formToGetDataFrom, 'firstName'),
        lastName: getFormData(state, formToGetDataFrom, 'lastName'),
        phoneNumber: getFormData(state, formToGetDataFrom, 'phone'),
        birthDate: getFormData(state, formToGetDataFrom, 'birthdate'),
        ssn: getFormData(state, formToGetDataFrom, 'ssn'),
        gender: getFormData(state, formToGetDataFrom, 'gender'),
        email: getFormData(state, formToGetDataFrom, 'email'),
        address: getFormData(state, formToGetDataFrom, 'address'),
        city: getFormData(state, formToGetDataFrom, 'city'),
        stateCode: getFormData(state, formToGetDataFrom, 'state'),
        zipCode: getFormData(state, formToGetDataFrom, 'zipCode'),
        relationshipToInsured: getFormData(state, formToGetDataFrom, 'relationshipToRecipient'),
        funeralHomeAccountId: state.account.id,
        purchaserTrackingToken: undefined
    };

    if (contactType === 'purchaser' && !contactData.id) {
        contactData.purchaserTrackingToken = getOrGenerateOriginToken();
    }

    runContactUpdate(opportunityId, contactType, contactData, (originalResult, error) => {
        if (state.travelOpportunity.id) {
            contactData.id = originalResult.contactId;
            runContactUpdate(state.travelOpportunity.id, contactType, contactData, (result, error) => {
                if (error) {
                    return callback(undefined, error);
                }
                return callback(originalResult, error);
            });
        } else {
            return callback(originalResult, error);
        }
    });
}

export function sendSaveContactWithSuppliedSSN(
    state: GlobalStateShape,
    SSN: string,
    contactType: string,
    callback: (result: any, error: any) => void
) {
    const opportunityId = state.opportunity.id;
    let formToGetDataFrom = contactType;
    if (formToGetDataFrom === 'recipient' && state.opportunity.recipient.sameAsPurchaser) {
        formToGetDataFrom = 'purchaser';
    }

    const contactData = {
        id: dataWrapper(() => state.opportunity[formToGetDataFrom].id),
        firstName: getFormData(state, formToGetDataFrom, 'firstName'),
        lastName: getFormData(state, formToGetDataFrom, 'lastName'),
        phoneNumber: getFormData(state, formToGetDataFrom, 'phone'),
        birthDate: getFormData(state, formToGetDataFrom, 'birthdate'),
        ssn: SSN,
        gender: getFormData(state, formToGetDataFrom, 'gender'),
        email: getFormData(state, formToGetDataFrom, 'email'),
        address: getFormData(state, formToGetDataFrom, 'address'),
        city: getFormData(state, formToGetDataFrom, 'city'),
        stateCode: getFormData(state, formToGetDataFrom, 'state'),
        zipCode: getFormData(state, formToGetDataFrom, 'zipCode'),
        relationshipToInsured: getFormData(state, formToGetDataFrom, 'relationshipToRecipient'),
        funeralHomeAccountId: state.account.id,
        originToken: undefined
    };
    if (contactType === 'purchaser' && !contactData.id) {
        contactData.originToken = getOrGenerateOriginToken();
    }

    runContactUpdate(opportunityId, contactType, contactData, (originalResult, error) => {
        if (state.travelOpportunity.id) {
            contactData.id = originalResult.contactId;
            runContactUpdate(state.travelOpportunity.id, contactType, contactData, (result, error) => {
                if (error) {
                    return callback(undefined, error);
                }
                return callback(originalResult, error);
            });
        } else {
            return callback(originalResult, error);
        }
    });
}

export function sendSavePurchaserData(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    sendSaveContactWithSuppliedSSN(state, '', 'purchaser', (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        dispatch(setOpportunityPurchaserIdAction(result.contactId));
        return callback(undefined, undefined);
    });
}

export function sendSaveRecipientData(
    state: GlobalStateShape,
    dispatch: Function,
    callback: (result: any, error: any) => void
) {
    const sameAsPurchaser = state.opportunity.recipient.sameAsPurchaser;
    const SSNToLast4Digits = state.featureFlags.SSNToLast4Digits;
    if (sameAsPurchaser) {
        sendSaveContactWithSuppliedSSN(state, '', 'recipient', (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            dispatch(setOpportunityRecipientIdAction(result.contactId));
            return callback(undefined, undefined);
        });
    } else if (SSNToLast4Digits) {
        const ssn = getFormData(state, 'recipient', 'ssnLastFour');
        sendSaveContactWithSuppliedSSN(state, ssn, 'recipient', (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            dispatch(setOpportunityRecipientIdAction(result.contactId));
            return callback(undefined, undefined);
        });
    } else {
        const ssn = getFormData(state, 'recipient', 'ssn');
        sendSaveContactWithSuppliedSSN(state, ssn, 'recipient', (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            dispatch(setOpportunityRecipientIdAction(result.contactId));
            return callback(undefined, undefined);
        });
    }
}

export function sendSaveBeneficiaryData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    sendSaveContactData('beneficiary', state, callback);
}

export function sendSaveBeneficiaryCheckData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const yesNoBeneficiaryValue = translateBooleanIntoYesNoString(
        dataWrapper(() => state.yesNoAnswers.policyQuestions.beneficiaryProvided)
    );
    const opportunityId = dataWrapper(() => state.opportunity.id);
    if (yesNoBeneficiaryValue) {
        runBeneficiaryCheckData(
            opportunityId,
            {
                beneficiaryProvided: yesNoBeneficiaryValue,
                opportunityId
            },
            callback
        );
    } else {
        // Don't need to run the update
        return callback(null, null);
    }
}

export function sendSaveBeneficiaryIsPurchaser(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const beneficiaryIsPurchaser = state.yesNoAnswers.policyQuestions.beneficiaryIsPurchaser;
    const opportunityId = dataWrapper(() => state.opportunity.id);
    const purchaserId = dataWrapper(() => state.opportunity.purchaser.id);
    if (beneficiaryIsPurchaser) {
        runBeneficiaryIsPurchaser(opportunityId, purchaserId, callback);
    } else {
        // Don't need to run the update
        return callback(null, null);
    }
}
