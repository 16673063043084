import { connect } from 'react-redux';
import EarthHeaderComponent from './EarthHeaderComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import accountComponentSwitch from '../../../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import TulipHeaderWrapper from '../../../../account-specific-components/accounts/tulip-cremation/header-component/TulipHeaderWrapper';
import { TULIP_STYLING_KEY } from '../../../../../static/constants/styling-company-constants/companies/tulip';
import { EVERDAYS_STYLING_KEY } from '../../../../../static/constants/styling-company-constants/companies/everdays';
import EverdaysHeaderWrapper from '../../../../account-specific-components/accounts/everdays/header-component/EverdaysHeaderWrapper';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const representativeId = getFormData(state, 'representative', 'id');

    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions,
        isAgentStyling: !!representativeId,
        funeralHomeName: state.account.name,
        funeralHomeWebsite: state.account.website,
        funeralHomePhoneNumber: state.account.phone
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(EarthHeaderComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TULIP_STYLING_KEY]: TulipHeaderWrapper,
    [EVERDAYS_STYLING_KEY]: EverdaysHeaderWrapper
});
