import React, { Fragment } from 'react';
import PageCardUnderlinedSectionWrapper from '../../../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import { validatePerson } from '../../../../../toolboxes/redux-form/redux-form-validation';
import PersonContactInformationFormWrapper from '../../../../form-components/person-contact-information-form-component/PersonContactInformationFormWrapper';
import Alert from '@material-ui/lab/Alert';

class PurchaserOrderSummaryDemographicSummaryComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
        this.generateFullDemographicSummary = this.generateFullDemographicSummary.bind(this);
    }

    static defaultProps = {
        // Default prop values
        isAtNeed: false
    };

    generateFullDemographicSummary(personInfo: any) {
        return (
            <Fragment>
                <Alert severity="info">
                    Birthdate and State cannot be changed. If these are incorrect please contact your agent.
                </Alert>
                <div className="purchaser-order-summary-split-div">
                    <div>
                        {personInfo.firstName} {personInfo.lastName}
                    </div>
                    {!this.props.isAtNeed && <div>{personInfo.birthdate}</div>}
                </div>
                <PersonContactInformationFormWrapper formName="purchaser" validate={validatePerson} />
            </Fragment>
        );
    }

    render() {
        return (
            <PageCardUnderlinedSectionWrapper
                sectionTitle={this.props.title}
                sectionId={this.props.title}
                fullContent={this.generateFullDemographicSummary(this.props.personInfo)}
                summaryContent={
                    <div>
                        {this.props.personInfo.firstName} {this.props.personInfo.lastName}
                    </div>
                }
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    isAtNeed: boolean;
    personInfo: any;
    title: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryDemographicSummaryComponent;
