import React from 'react';
import './PaymentPriceCardComponent.css';
import ApplicationStyling from '../../../static/models/ApplicationStyling';

class PaymentPriceCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined
    };

    render() {
        return (
            <div className="ppc">
                <div className="ppc-top">
                    <div className="ppc-title" id="text-ppc-title">
                        {this.props.title}
                    </div>
                    <div
                        className="ppc-amount"
                        id="text-ppc-amount"
                        style={{ color: this.props.stylingOptions.colors.primaryColor }}
                    >
                        {this.props.amount}
                    </div>
                </div>
                <div className="ppc-subtitle">{this.props.subtitle}</div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    title: string;
    subtitle: string;
    amount: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default PaymentPriceCardComponent;
