import { connect } from 'react-redux';
import EmploymentVitalFormComponent from './EmploymentVitalFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { EmploymentStatus } from '../../../static/constants/enums/employmentStatus';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const employmentStatus: EmploymentStatus = getFormData(state, 'employmentVital', 'employmentStatus');

    return {
        isOrWasEmployed: employmentStatus && employmentStatus !== EmploymentStatus.NotEmployed,
        isRetired: employmentStatus === EmploymentStatus.Retired
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentVitalFormComponent);
