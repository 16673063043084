import { combineReducers } from 'redux';
import { setSpacingAboveBodyContentReducer } from './aboveBodyContent';
import { setSpacingAboveContinueButtonReducer } from './aboveContinueButton';
import { setSpacingAbovePageSubtitleReducer } from './abovePageSubtitle';
import { setSpacingAbovePageTitleReducer } from './abovePageTitle';
import { setSpacingInnerBodyReducer } from './innerBody';
import { setSpacingLGReducer } from './lg';
import { setSpacingMDReducer } from './md';
import { setSpacingSMReducer } from './sm';
import { setSpacingXLReducer } from './xl';
import { setSpacingXSReducer } from './xs';
import { setSpacingXXLReducer } from './xxl';

export default combineReducers({
    abovePageTitle: setSpacingAbovePageTitleReducer,
    abovePageSubtitle: setSpacingAbovePageSubtitleReducer,
    abovePageBodyContent: setSpacingAboveBodyContentReducer,
    innerBody: setSpacingInnerBodyReducer,
    aboveContinueButton: setSpacingAboveContinueButtonReducer,
    xs: setSpacingXSReducer,
    sm: setSpacingSMReducer,
    md: setSpacingMDReducer,
    lg: setSpacingLGReducer,
    xl: setSpacingXLReducer,
    xxl: setSpacingXXLReducer
});
