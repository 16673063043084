import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';

class RecipientAvailabilityCheckPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setInsuredAvailabilityAddressedAction: () => {},
        trustText: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="RecipientAvailabilityCheck"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Recipient Availability Check</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Will the {this.props.trustText} be available to consent to this purchase? If the{' '}
                        {this.props.trustText} will be available to consent now or in the near future select 'Yes'.
                        Otherwise, select 'No'.
                    </PageCardTextWrapper>
                    <ButtonLinkWrapper
                        buttonId="yes"
                        to={urlPaths.beneficiaryCheck}
                        navigationSideAffect={() => this.props.setInsuredAvailabilityAddressedAction(true)}
                    >
                        Yes
                    </ButtonLinkWrapper>
                    <ButtonLinkWrapper buttonId="no" to={urlPaths.recipientAbsent} color="secondary">
                        No
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setInsuredAvailabilityAddressedAction: Function;
    trustText: string;
}

interface StateShape {
    // Shape of local state
}

export default RecipientAvailabilityCheckPageComponent;
