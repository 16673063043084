import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

const loadOpportunityReroutes = (state: GlobalStateShape, params: any): string => {
    if (!params.id) {
        const savedOpportunityId = state.opportunity.id || window.localStorage.getItem('opportunityId');
        if (savedOpportunityId) {
            return `${urlPaths.loadOpportunity}?id=${savedOpportunityId}`;
        }
        return urlPaths.error;
    }
};

export default loadOpportunityReroutes;
