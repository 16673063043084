import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendSaveVitalStatisticsData } from '../../server-application-buffer/vital-statistics-data';
import { setOpportunitySentVitalStatisticsEmailAction } from '../../../redux/opportunity/sentVitalStatisticsEmail';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { translateBooleanIntoYesNoString } from 'toolboxes/server-application-buffer/generalized-data-buffer-tools/yes-no-translator';

export function saveVitalStatistics(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    // Only save if the account allows vital stats.
    if (!state.account?.featureVitalStatistics) {
        return [];
    }

    // Only save if there are values on the vital stats form
    if (!validateVitalStatistics(state)) {
        return [];
    }

    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveVitalStatisticsData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        const expiration = new Date();
                        expiration.setUTCMinutes(expiration.getUTCMinutes() + 1);
                        const vitalStatisticsSavedValue = {
                            vitalStatisticsSaved: 'true',
                            expiration: expiration
                        };
                        window.sessionStorage.setItem(
                            'vitalStatisticsSaved',
                            JSON.stringify(vitalStatisticsSavedValue)
                        );
                        dispatch(setOpportunitySentVitalStatisticsEmailAction(true));
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.vitalStatisticsSaved]
        }
    ];
}

export function validateVitalStatistics(state: GlobalStateShape): boolean {
    if (
        getFormData(state, 'recipientVital', 'middleName') ||
        getFormData(state, 'recipientVital', 'birthCity') ||
        getFormData(state, 'recipientVital', 'birthState') ||
        translateBooleanIntoYesNoString(getFormData(state, 'recipientVital', 'usaCitizen')) ||
        getFormData(state, 'employmentVital', 'employer') ||
        getFormData(state, 'recipientVital', 'highestEducation') ||
        getFormData(state, 'employmentVital', 'kindOfBusiness') ||
        getFormData(state, 'employmentVital', 'employmentNumberOfYears') ||
        getFormData(state, 'employmentVital', 'retiredYear') ||
        getFormData(state, 'employmentVital', 'employmentStatus') ||
        getFormData(state, 'employmentVital', 'usualOccupation') ||
        getFormData(state, 'recipientVital', 'race') ||
        getFormData(state, 'fatherVital', 'birthCity') ||
        getFormData(state, 'fatherVital', 'birthState') ||
        getFormData(state, 'fatherVital', 'firstName') ||
        getFormData(state, 'fatherVital', 'lastName') ||
        getFormData(state, 'fatherVital', 'middleName') ||
        getFormData(state, 'spouseVital', 'maritalStatus') ||
        getFormData(state, 'spouseVital', 'marriageDate') ||
        getFormData(state, 'spouseVital', 'marriageCity') ||
        getFormData(state, 'spouseVital', 'marriageState') ||
        getFormData(state, 'militaryVital', 'militaryServiceBranch') ||
        getFormData(state, 'militaryVital', 'honors') ||
        getFormData(state, 'militaryVital', 'militaryServiceEndDate') ||
        getFormData(state, 'militaryVital', 'militaryServiceNumber') ||
        getFormData(state, 'militaryVital', 'militaryServiceRank') ||
        getFormData(state, 'militaryVital', 'militaryServiceStartDate') ||
        translateBooleanIntoYesNoString(getFormData(state, 'militaryVital', 'isMilitaryVeteran')) ||
        getFormData(state, 'motherVital', 'birthCity') ||
        getFormData(state, 'motherVital', 'birthState') ||
        getFormData(state, 'motherVital', 'firstName') ||
        getFormData(state, 'motherVital', 'lastName') ||
        getFormData(state, 'motherVital', 'middleName') ||
        getFormData(state, 'motherVital', 'maidenName') ||
        getFormData(state, 'recipientVital', 'salutation') ||
        getFormData(state, 'spouseVital', 'firstName') ||
        getFormData(state, 'spouseVital', 'lastName') ||
        getFormData(state, 'spouseVital', 'middleName') ||
        getFormData(state, 'spouseVital', 'maidenName')
    ) {
        return true;
    }
    return false;
}
