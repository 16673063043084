// Action Type
import { OpportunityStage } from '../../static/constants/opportunityStages';

export const SET_OPPORTUNITY_ACTIVE_STAGE = 'SET_OPPORTUNITY_ACTIVE_STAGE';
// Action
export function setActiveStagedAction(newValue: OpportunityStage) {
    return { type: SET_OPPORTUNITY_ACTIVE_STAGE, newValue };
}
// Reducer
export function setActiveStageReducer(state: any, action: any): OpportunityStage {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_OPPORTUNITY_ACTIVE_STAGE:
            return action.newValue;
        default:
            return state;
    }
}
