import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import IdentityConfirmationFormWrapper from '../../form-components/identity-confirmation-form-component/IdentityConfirmationFormWrapper';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class PrefilledRecipientIdentityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        recipientFirstName: '',
        recipientId: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PrefilledRecipientIdentity"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                <IdentityConfirmationFormWrapper
                    nextUrl={urlPaths.prefilledRecipientConsent}
                    contactFirstName={this.props.recipientFirstName}
                    contactId={this.props.recipientId}
                    disclaimerAddressingLocaiton={this.props.setRecipientPlaidIdentityConsentDisclaimerAddressingAction}
                />
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    recipientFirstName: string;
    recipientId: string;
    setRecipientPlaidIdentityConsentDisclaimerAddressingAction: () => void;
}

interface StateShape {
    // Shape of local state
}

export default PrefilledRecipientIdentityPageComponent;
