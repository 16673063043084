import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class NoPaymentOptionsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="NoPaymentOptions" metaPageNumber="4" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>No available payment options</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Unfortunately, due to state regulations we are currently unable to provide you with payment
                        options for this purchase. We are constantly working to expand our availability and hope to be
                        able to service you in the near future.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default NoPaymentOptionsPageComponent;
