import GlobalStateShape from '../../redux/GlobalStateShape';
import { PaymentMode } from 'static/constants/enums/paymentModes';

function roundCurrency(amount: number): number {
    return Math.round((amount + Number.EPSILON) * 100) / 100;
}

export function getTravelSubtotal(state: GlobalStateShape): number {
    let subtotal = 0;
    state.travelOpportunity.products.forEach(product => {
        subtotal += product.quantity * product.price;
    });
    return roundCurrency(subtotal);
}

export function getTravelSalesTax(state: GlobalStateShape): number {
    let salesTax = 0;
    state.travelOpportunity.products.forEach(product => {
        if (product.explicitTax) {
            salesTax += product.explicitTax * product.quantity;
        } else if (product.taxIsApplicable) {
            salesTax += product.quantity * product.price * state.account.taxRate;
        }
    });
    return roundCurrency(salesTax);
}

export function getTravelTotal(state: GlobalStateShape): number {
    return getTravelSubtotal(state) + getTravelSalesTax(state);
}

export function getFuneralSubtotal(state: GlobalStateShape): number {
    let subtotal = 0;
    state.opportunity.products.forEach(product => {
        subtotal += product.quantity * product.price;
    });
    subtotal += getMileageCost(state);
    return roundCurrency(subtotal);
}

export function getFuneralSalesTax(state: GlobalStateShape): number {
    let salesTax = 0;
    state.opportunity.products.forEach(product => {
        if (product.explicitTax) {
            salesTax += product.explicitTax * product.quantity;
        } else if (product.taxIsApplicable) {
            salesTax += product.quantity * product.price * state.account.taxRate;
        }
    });
    return roundCurrency(salesTax);
}

export function getMileageCost(state: GlobalStateShape): number {
    return state.opportunity.mileageCost ? roundCurrency(Number(state.opportunity.mileageCost)) : 0;
}

export function getFuneralTotal(state: GlobalStateShape): number {
    return getFuneralSubtotal(state) + getFuneralSalesTax(state);
}

export function getCartSubtotal(state: GlobalStateShape): number {
    return getFuneralSubtotal(state) + getTravelSubtotal(state);
}

export function getCartSalesTax(state: GlobalStateShape): number {
    return getFuneralSalesTax(state) + getTravelSalesTax(state);
}

export function getCartTotal(state: GlobalStateShape): number {
    return getCartSubtotal(state) + getCartSalesTax(state);
}

export function getAmountToBePaidTotal(state: GlobalStateShape): number {
    const selectedOption = state.opportunity.pricing.paymentOptions.selectedOption;

    if (selectedOption.paymentMode === PaymentMode.Single) {
        return getTravelTotal(state) + selectedOption.amountOfFirstPayment;
    }

    const remaining = selectedOption.premium * (selectedOption.years * 12 - 1);
    return getTravelTotal(state) + selectedOption.amountOfFirstPayment + remaining;
}
