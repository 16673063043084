import React from 'react';
import Button from '@material-ui/core/Button';
import './YesNoComponent.css';

class YesNoComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <div className="yes-no-buttons">
                <div className="yes-no-button">
                    <Button
                        variant={this.props.answer === true ? 'contained' : 'outlined'}
                        color="primary"
                        fullWidth={true}
                        id={`${this.props.yesNoId}-yes`}
                        title={`${this.props.yesNoTitle} Yes`}
                        onClick={() => this.props.onYesClick()}
                    >
                        Yes
                    </Button>
                </div>
                <div className="yes-no-button">
                    <Button
                        variant={this.props.answer === false ? 'contained' : 'outlined'}
                        color="primary"
                        fullWidth={true}
                        id={`${this.props.yesNoId}-no`}
                        title={`${this.props.yesNoTitle} No`}
                        onClick={() => this.props.onNoClick()}
                    >
                        No
                    </Button>
                </div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    yesNoId: string;
    yesNoTitle?: string;
    answer?: boolean;
    onYesClick: Function;
    onNoClick: Function;
}

interface StateShape {
    // Shape of local state
}

export default YesNoComponent;
