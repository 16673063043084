import { connect } from 'react-redux';
import FuneralHomeProductsDisplayComponent from './FuneralHomeProductsDisplayComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { addOpportunityProductAction } from '../../../../redux/opportunity/products';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        availableProducts: state.availableProducts.funeralHomeProducts
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        addProductAction: newValue => dispatch(addOpportunityProductAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralHomeProductsDisplayComponent);
