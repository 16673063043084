import React from 'react';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import PasswordBoxWrapper from '../base-form-components/password-box-component/PasswordBoxWrapper';
import {
    normalizeLastFourSocial,
    normalizeSocialSecurityNumber,
    normalizeSocialSecurityNumberDisplay
} from '../../../toolboxes/redux-form/redux-form-normalizers';
import { validateSSN, validationLast4SSN } from '../../../toolboxes/redux-form/redux-form-validation';

class TemplateFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        SSNToLast4Digits: false,
        showErrorMessage: false
    };

    render() {
        if (this.props.SSNToLast4Digits) {
            return (
                <FormWrapper formName="SSNCaptureData" validate={validationLast4SSN}>
                    <PasswordBoxWrapper
                        labelString="Last Four of SSN  "
                        placeholder="0000"
                        formName="securityData"
                        fieldPath="ssnLastFour"
                        extraFieldProps={{
                            inputProps: {
                                inputMode: 'numeric'
                            }
                        }}
                        normalize={normalizeLastFourSocial}
                        showErrorMessage={this.props.showErrorMessage}
                    />
                </FormWrapper>
            );
        } else {
            return (
                <FormWrapper formName="SSNCaptureData" validate={validateSSN}>
                    <PasswordBoxWrapper
                        normalize={normalizeSocialSecurityNumber}
                        formattingFunction={normalizeSocialSecurityNumberDisplay}
                        formName="securityData"
                        fieldPath="ssn"
                        labelString="SSN "
                        placeholder="000-00-0000"
                        extraFieldProps={{
                            disabled: false,
                            inputProps: {
                                inputMode: 'numeric'
                            }
                        }}
                        showErrorMessage={this.props.showErrorMessage}
                    />
                </FormWrapper>
            );
        }
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    SSNToLast4Digits: boolean;
    showErrorMessage: boolean;
}

interface StateShape {
    // Shape of local state
}

export default TemplateFormComponent;
