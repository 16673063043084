import { getFormValues } from 'redux-form';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { formIsValid } from '../../redux-form/redux-form-validation';
import { sendSavePurchaserData, sendSaveRecipientData } from '../../server-application-buffer/contact-data';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import {
    saveIsPlanningForSelf,
    updateOpportunityMarketCode
} from '../../server-application-buffer/general-opportunity-data';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import productCodeMustBeCP, { cpMarketCode } from 'static/constants/stateSpecificLists/productCodeMustBeCP';
import { getPurchaserValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';

export function savePurchaserData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const validateFunction = getPurchaserValidationFunction(state);

    if (!formIsValid(getFormValues('purchaser')(state), validateFunction)) {
        return [];
    }
    const functionsToRun: BlockingFunction[] = [];
    functionsToRun.push({
        promiseGenerator: () => {
            return new Promise<void>((resolve, reject) => {
                sendSavePurchaserData(state, dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            });
        },
        blocksPageStarts: [urlPaths.recipientCheck, urlPaths.orderSummary]
    });
    if (state.opportunity.recipient.sameAsPurchaser) {
        functionsToRun.push();
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSaveRecipientData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.recipientCheck, urlPaths.orderSummary]
        });

        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    saveIsPlanningForSelf(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.recipientCheck, urlPaths.orderSummary]
        });
    }

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                const purchaserState = getFormData(state, 'purchaser', 'state');
                const accountState: string = state.account.state;

                let marketCode = state.opportunity.marketCode;

                if (productCodeMustBeCP.includes(purchaserState) || productCodeMustBeCP.includes(accountState)) {
                    marketCode = cpMarketCode;
                }

                updateOpportunityMarketCode(state, marketCode, dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
                return resolve();
            }),
        blocksPageStarts: [urlPaths.recipientCheck, urlPaths.orderSummary, urlPaths.disclaimerProduct]
    });
    return functionsToRun;
}
