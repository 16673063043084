import React from 'react';
import GeneralModalWrapper from '../general-modal-component/GeneralModalWrapper';
import Product from '../../../static/models/Product';
import './ProductDescriptionModalComponent.css';

class ProductDescriptionModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        onRequestClose: () => {}
    };

    render() {
        if (!this.props.product || !this.props.product.salesDescription) {
            return (
                <GeneralModalWrapper onRequestClose={this.props.onRequestClose}>
                    <div className="no-information">(No information)</div>
                </GeneralModalWrapper>
            );
        }
        return (
            <GeneralModalWrapper onRequestClose={this.props.onRequestClose}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.product.salesDescription
                    }}
                    className="sales-description"
                />
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    product: Product;
    onRequestClose: Function;
}

interface StateShape {
    // Shape of local state
}

export default ProductDescriptionModalComponent;
