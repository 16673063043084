import { connect } from 'react-redux';
import PurchaserStartPageComponent from './PurchaserStartPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setSelectedPaymentOptionAction } from '../../../redux/opportunity/pricing/paymentOptions/selectedOption';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        formComplete:
            state.account.subdomain &&
            state.opportunity.timing &&
            state.opportunity.disposition &&
            [true, false].includes(state.opportunity.recipient.sameAsPurchaser),
        paymentOptionPredetermined: !!state.opportunity.pricing.paymentOptions.selectedOption
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserStartPageComponent);
