import { connect } from 'react-redux';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import { formIsValid, validatePhoneVerificationRequest } from '../../../toolboxes/redux-form/redux-form-validation';
import PurchaserPhoneVerificationRequestPageComponent from './PurchaserPhoneVerificationRequestPageComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        formIsValid: formIsValid(getFormValues('purchaserPhoneVerification')(state), validatePhoneVerificationRequest),
        purchaserInfo: getFormValues('purchaser')(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserPhoneVerificationRequestPageComponent);
