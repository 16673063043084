import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';

export default function clearRepresentativeId(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    window.localStorage.removeItem('representativeId');
                    resolve();
                }),
            blocksPageStarts: [urlPaths.purchaserStart]
        }
    ];
}
