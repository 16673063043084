import { connect } from 'react-redux';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import TitanFooterComponent from './TitanFooterComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        phoneNumber: state.account.phone,
        stylingOptions: state.stylingOptions,
        subdomain: state.account.subdomain,
        opportunityId: state.opportunity.id,
        accountState: state.account.state,
        facebookUrl: state.account.facebookUrl,
        instagramUrl: state.account.instagramUrl,
        twitterUrl: state.account.twitterUrl,
        yelpUrl: state.account.yelpUrl,
        address: state.account.address,
        city: state.account.city,
        funeralHomeNumber: state.account.funeralHomeNumber,
        zip: state.account.zip,
        name: state.account.name,
        mediaDomain: state.account.mediaDomain,
        notificationEmail: state.account?.notificationEmail
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TitanFooterComponent);
