import { connect } from 'react-redux';
import TravelProductsDisplayComponent from './TravelProductsDisplayComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { addTravelProductAction } from '../../../../redux/travelOpportunity/products';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        availableProducts: state.availableProducts.travelProtectionProviderProducts
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        addProductAction: newValue => dispatch(addTravelProductAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelProductsDisplayComponent);
