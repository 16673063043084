import React from 'react';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';

class SendIdentityLinkEmailPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setIdentityLinkEmailSendAction: () => {},
        purchaserFullName: 'the purchaser',
        purcahserEmail: 'the given email'
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="SendIdentityLinkEmail"
                metaPageNumber="4"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Email sent</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        An email has been sent to {this.props.purcahserEmail}, please allow 5-10 minutes for{' '}
                        {this.props.purchaserFullName} to receive it.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setIdentityLinkEmailSendAction: Function;
    purchaserFullName: string;
    purcahserEmail: string;
}

interface StateShape {
    // Shape of local state
}

export default SendIdentityLinkEmailPageComponent;
