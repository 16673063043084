import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import activeEnvironment from '../../../env-config/active-environment';

export function purchaserStartReroutes(state: GlobalStateShape) {
    // @ts-ignore
    if (activeEnvironment === 'production') {
        return urlPaths.error;
    }
}
