import React from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
import { Redirect } from 'react-router';
import HelloSignSigningWrapper from 'components/global-components/hellosign-signing-component/HelloSignSigningWrapper';

class SecureLinkSignPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            redirectToSigningComplete: false
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        // In general, we always add push to the redirects.
        // We don't want to do that here, because we want a back click to lead back to wherever the user clicked the signing link.
        if (this.state.redirectToSigningComplete) {
            return <Redirect to={urlPaths.secureLinkSigningComplete} />;
        }
        if (!this.props.contractsUrl) {
            return <Redirect to={urlPaths.error} />;
        }
        return (
            <HelloSignSigningWrapper
                hellosignEmbeddedUrl={this.props.contractsUrl}
                onSigningComplete={() => {
                    this.setState({
                        redirectToSigningComplete: true
                    });
                }}
            />
        );
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    contractsUrl: string;
}

interface StateShape {
    // Shape of local state
    redirectToSigningComplete: boolean;
}

export default SecureLinkSignPageComponent;
