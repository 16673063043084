import React from 'react';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import PaymentTrustOptionSelectionWrapper from '../payment-trust-option-selection-component/PaymentTrustOptionSelectionWrapper';

class InstallmentPaymentTrustOptionsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            desiredPaymentOption: null
        };
    }

    static defaultProps = {
        // Default prop values
        multiPayOptions: [],
        setSelectedPaymentOptionAction: () => {},
        isTrust: false
    };

    componentDidUpdate(oldProps: PropShape) {
        if (this.state.desiredPaymentOption) {
            this.props.setSelectedPaymentOptionAction(this.state.desiredPaymentOption);
            this.setState({
                desiredPaymentOption: null
            });
        }
    }

    selectThisOption(option: PaymentOption) {
        this.setState({
            desiredPaymentOption: option
        });
    }

    render() {
        if (!this.state.desiredPaymentOption) {
            return this.props.multiPayOptions
                .filter(option => {
                    return option.paymentMethod === this.props.paymentMethod;
                })
                .map((option, index) => {
                    return (
                        <PaymentTrustOptionSelectionWrapper
                            key={`option-${index}`}
                            paymentOption={option}
                            onClick={() => this.selectThisOption(option)}
                        />
                    );
                });
        }

        if (this.props.isTrust) {
            this.props.setSelectedPaymentOptionAction(this.state.desiredPaymentOption);
            this.setState({
                desiredPaymentOption: null
            });
            return null;
        }
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    multiPayOptions: PaymentOption[];
    setSelectedPaymentOptionAction: Function;
    paymentMethod: PaymentMethod;
    isTrust: boolean;
}

interface StateShape {
    // Shape of local state
    desiredPaymentOption: PaymentOption;
}

export default InstallmentPaymentTrustOptionsDisplayComponent;
