import { connect } from 'react-redux';
import HelloSignSigningComponent from './HelloSignSigningComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import queryStringParser from 'query-string';
import { calcuateHelloSignClientKey } from 'toolboxes/reuseable-logic/hellosign-client-key-calculator';
import getEnvironment from 'env-config/get-environment';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const params = queryStringParser.parse(window.location.search);
    let forceDefaultHelloSignClientId = false;
    if (params?.forceDefaultHelloSignClientId === 'true') {
        forceDefaultHelloSignClientId = true;
    }
    return {
        helloSignClientId: calcuateHelloSignClientKey(
            state.account.hellosignKey,
            state.account.stylingKey,
            forceDefaultHelloSignClientId
        ),
        verifyDomain: getEnvironment().verifyHelloSignDomain,
        primaryButtonColor: state.stylingOptions.colors.primaryColor,
        customHellosignColorsFlag: state.featureFlags.customHellosignColors
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HelloSignSigningComponent);
