import { connect } from 'react-redux';
import CurrentHealthComponent from './CurrentHealthComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setIsBedriddenOrAiledAction } from '../../../../redux/yesNoAnswers/healthHistoryQuestions/isBedriddenOrAiled';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';

function calculatePersonIndicationText(state: GlobalStateShape): string {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return `Are you, ${getFormData(state, 'purchaser', 'firstName')},`;
    }
    return `Is the recipient, ${getFormData(state, 'recipient', 'firstName')},`;
}

function calculatePersonPronounText(state: GlobalStateShape): string {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return `have you`;
    }
    return 'have they';
}

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        customizableText: {
            personIndicationText: calculatePersonIndicationText(state),
            personPronounText: calculatePersonPronounText(state)
        }
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIsBedriddenOrAiledAction: newValue => dispatch(setIsBedriddenOrAiledAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentHealthComponent);
