import React from 'react';
import DemographicInformationWrapper from '../demographic-information-component/DemographicInformationWrapper';

class BeneficiaryInformationComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formData: null
    };

    render() {
        return (
            <div>
                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>Beneficiary</div>
                <DemographicInformationWrapper contact={this.props.formData} />
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formData?: any;
}

interface StateShape {
    // Shape of local state
}

export default BeneficiaryInformationComponent;
