import { sendRequest, baseUrl, defaultGetConfiguration } from '../web-request-tools';
import axios from 'axios';
import ContractSigningCredentials from '../../../static/models/ContractSigningCredentials';

// @ts-ignore
// eslint-disable-next-line
export function fetchSavedContractV3Urls(
    opportunityId: string,
    redirectUrl: string,
    attempt: number,
    finalCallback: (result: any, error: any) => void
) {
    if (attempt > 0) {
        sendRequest(
            axios.get(`${baseUrl}/api/v3/contracts/${opportunityId}/envelope`, {
                ...defaultGetConfiguration,
                params: { redirectUrl }
            }),
            (result, error) => {
                if (error || !!result.url) {
                    return finalCallback(result, error);
                }

                return fetchSavedContractV3Urls(opportunityId, redirectUrl, attempt - 1, finalCallback);
            }
        );
    } else {
        finalCallback(undefined, 'Exhausted all retries');
    }
}

export function fetchContractV3Urls(
    opportunityId: string,
    preneedContractType: string,
    usePreneedContractType: boolean,
    redirectUrl: string,
    callback: (result: any, error: any) => void
) {
    let route = 'Preneed Contract';
    if (!!preneedContractType && usePreneedContractType) {
        if (preneedContractType === 'Trust') {
            route = 'Trust';
        }
    }

    sendRequest(
        axios.get(`${baseUrl}/api/v3/contracts/${opportunityId}/generate/${route}`, {
            ...defaultGetConfiguration,
            params: { redirectUrl }
        }),
        (result, error) => {
            if (error && error.response.status === 504) {
                fetchSavedContractV3Urls(opportunityId, redirectUrl, 5, callback);
            } else {
                callback(result, error);
            }
        }
    );
}

export function fetchTravelContractV3Url(
    opportunityId: string,
    redirectUrl: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.get(`${baseUrl}/api/v3/contracts/${opportunityId}/generate/Travel Protection Contract`, {
            ...defaultGetConfiguration,
            params: { redirectUrl }
        }),
        (result, error) => {
            if (error && error.response.status === 504) {
                fetchSavedContractV3Urls(opportunityId, redirectUrl, 5, callback);
            } else {
                callback(result, error);
            }
        }
    );
}

export function fetchAllContractSigningCredentials(
    opportunityId: string,
    callback: (result: ContractSigningCredentials[], error: any) => void
) {
    sendRequest(
        axios.get(`${baseUrl}/api/v3/contracts/${opportunityId}/signingStatus`, defaultGetConfiguration),
        callback
    );
}

export function fetchContractSigningCredentials(
    secureSigningKey: string,
    callback: (result: ContractSigningCredentials, error: any) => void
) {
    sendRequest(
        axios.get(
            `${baseUrl}/api/v3/contracts/signingCredentialsForSigningKey/${secureSigningKey}`,
            defaultGetConfiguration
        ),
        callback
    );
}
