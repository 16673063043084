import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { connect } from 'react-redux';
import PasswordBoxComponent from './PasswordBoxComponent';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passes in as props
    return {
        accountName: state.account.name,
        trustText: state.opportunity.preneedContractType === PreneedContractType.Trust ? 'banking' : 'insurance',
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordBoxComponent);
