import { connect } from 'react-redux';
import IrrevocableForcedComponent from './IrrevocableForcedComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setIrrevocableAction } from 'redux/yesNoAnswers/policyQuestions/irrevocable';
import { setIrrevocabilityAddressingAction } from 'redux/disclaimerData/disclaimerAddressings/irrevocabilityConfirmation';
/* eslint-disable */
const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        irrevocabilityPositivelyAddressed:
            state.disclaimerData.disclaimerAddressings.irrevocabilityConfirmation.hasBeenAcceptedOrPositivelyAddressed
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIrrevocableAction: newValue => dispatch(setIrrevocableAction(newValue)),
        setIrrevocabilityAddressingAction: newValue => dispatch(setIrrevocabilityAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrevocableForcedComponent);
