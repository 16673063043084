import React from 'react';
import './HeaderComponent.css';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';
import { Container, Grid, Icon, Typography, Hidden, Divider } from '@material-ui/core';
import { formatPhoneNumber } from '../../../../toolboxes/reuseable-logic/string-formatters';
import EfuneralLogoSrc from '../../../../static/media/efuneral.png';
import { EfuneralServiceTier } from 'static/constants/enums/efuneralServiceTier';

class HeaderComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined,
        isAgentStyling: false,
        funeralHomeName: '',
        funeralHomeWebsite: '',
        funeralHomePhoneNumber: '',
        cobrandingStorefront: false
    };

    render() {
        if (!this.props.stylingOptions.loadingComplete) {
            return '';
        }

        return (
            <header
                className="ef-header-wrapper"
                style={{
                    borderTop: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    borderBottom: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    backgroundColor: this.props.stylingOptions.colors.headerBackgroundColor
                }}
            >
                <Container>
                    <Grid container className="ef-header" direction="row">
                        <Grid item container xs={12} sm={3}>
                            <div className="ef-header-left">
                                <a href={this.props.funeralHomeWebsite} target="_blank" rel="noopener noreferrer">
                                    <Grid item container spacing={1} xs={12}>
                                        {this.props.cobrandingStorefront && (
                                            <>
                                                <Grid item xs={5}>
                                                    <img
                                                        className="logo-image-header"
                                                        src={EfuneralLogoSrc}
                                                        style={{
                                                            // eslint-disable-next-line prettier/prettier
                                                            maxHeight: this.props.stylingOptions.sizing
                                                                .headerLogoMaxHeight,
                                                            maxWidth: '100%',
                                                            display: 'inline'
                                                        }}
                                                        alt=""
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Divider
                                                        orientation="vertical"
                                                        style={{ color: '#989b9c', height: '100%', width: '2px' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <img
                                                        className="logo-image-header"
                                                        src={this.props.stylingOptions.logoUrl}
                                                        style={{
                                                            maxHeight: this.props.stylingOptions.sizing
                                                                .headerLogoMaxHeight
                                                        }}
                                                        alt=""
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {!this.props.cobrandingStorefront && (
                                            <Grid item xs={12}>
                                                <img
                                                    className="logo-image-header"
                                                    src={this.props.stylingOptions.logoUrl}
                                                    style={{
                                                        maxHeight: this.props.stylingOptions.sizing.headerLogoMaxHeight
                                                    }}
                                                    alt=""
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </a>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {!this.props.isAgentStyling && this.props.stylingOptions.showNameInTitle && (
                                <Hidden only="xs">
                                    <div className="ef-header-title">
                                        <span style={{ color: this.props.stylingOptions.colors.headerFontColor }}>
                                            {this.props.funeralHomeName}
                                        </span>
                                    </div>
                                </Hidden>
                            )}
                        </Grid>
                        <Grid item sm={3}>
                            {!this.props.isAgentStyling && (
                                <Hidden only="xs">
                                    {this.props.funeralHomePhoneNumber && (
                                        <div className="ef-header-right">
                                            <a
                                                className="ef-header-phone"
                                                href={`tel:${this.props.funeralHomePhoneNumber}`}
                                            >
                                                <span
                                                    style={{
                                                        backgroundColor: this.props.stylingOptions.colors.primaryColor
                                                    }}
                                                >
                                                    <Icon className="ef-header-phone-icon">phone</Icon>
                                                </span>

                                                <Typography className="ef-header-phone-number">
                                                    {formatPhoneNumber(this.props.funeralHomePhoneNumber)}
                                                </Typography>
                                            </a>
                                        </div>
                                    )}
                                </Hidden>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </header>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    isAgentStyling: boolean;
    funeralHomeName: string;
    funeralHomeWebsite: string;
    funeralHomePhoneNumber: string;
    cobrandingStorefront: boolean;
}

interface StateShape {
    // Shape of local state
}

export default HeaderComponent;
