import { connect } from 'react-redux';
import FuneralHomeSearchComponent from './FuneralHomeSearchComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setAccountSubdomainAction } from '../../../redux/account/subdomain';
import { setAccountFundingProductsAction } from '../../../redux/account/fundingProducts';
import { setAccountIdAction } from '../../../redux/account/id';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        currentSubdomain: state.account.subdomain
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setAccountSubdomainAction: newValue => dispatch(setAccountSubdomainAction(newValue)),
        setAccountFundingProductsAction: newValue => dispatch(setAccountFundingProductsAction(newValue)),
        setAccountIdAction: newValue => dispatch(setAccountIdAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralHomeSearchComponent);
