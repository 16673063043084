import React, { Fragment } from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FuneralHomeSearchWrapper from '../../global-components/funeral-home-search-component/FuneralHomeSearchWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BasicOpportunityInformationGroupingWrapper from '../../global-components/basic-opportunity-information-components/basic-opportunity-information-grouping-component/BasicOpportunityInformationGroupingWrapper';
import RadioDescriptionWrapper from '../../global-components/buttons/radio-description-component/RadioDescriptionWrapper';
import PageCardUnderlinedSectionWrapper from '../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import PaymentOption from '../../../static/models/PaymentOption';
import { PaymentMethod } from '../../../static/constants/enums/paymentMethods';
import TimingSelectionWrapper from '../../global-components/basic-opportunity-information-components/timing-selection-component/TimingSelectionWrapper';
import { PaymentMode } from '../../../static/constants/enums/paymentModes';

class PurchaserStartPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        formComplete: false,
        paymentOptionPredetermined: null,
        setSelectedPaymentOptionAction: () => {}
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="PurchaserStart" metaPageNumber="1" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Subdomain Selection</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please Search for and select a funeral home. Also, provide the basic information regarding the
                        sale.
                    </PageCardTextWrapper>
                    <FuneralHomeSearchWrapper />
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="What is the timing of this purchase?"
                        sectionId="timing"
                        fullContent={<TimingSelectionWrapper />}
                    />
                    <BasicOpportunityInformationGroupingWrapper />
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="What type of payment?"
                        sectionId="payment"
                        fullContent={
                            <Fragment>
                                <RadioDescriptionWrapper
                                    onClick={() =>
                                        this.props.setSelectedPaymentOptionAction({
                                            years: 3,
                                            months: 36,
                                            faceAmount: 1250.0,
                                            premium: 50.0,
                                            amountOfFirstPayment: 50,
                                            paymentMode: PaymentMode.Multi,
                                            paymentMethod: PaymentMethod.CreditCard
                                        })
                                    }
                                    isSelected={this.props.paymentOptionPredetermined === true}
                                    mainContent="Predetermined"
                                    descriptionContent="This test will look at a user who's already picked a payment option"
                                    id="predetermined"
                                />
                                <RadioDescriptionWrapper
                                    onClick={() => this.props.setSelectedPaymentOptionAction(null)}
                                    isSelected={this.props.paymentOptionPredetermined === false}
                                    mainContent="Non-Predetermined"
                                    descriptionContent="This test will look at a user who hasn't picked a payment option"
                                    id="nonpredetermined"
                                />
                            </Fragment>
                        }
                    />
                    <ButtonLinkWrapper
                        to={urlPaths.productSelection}
                        buttonId="continue"
                        disabled={!this.props.formComplete}
                    >
                        Continue
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    paymentOptionPredetermined: boolean;
    setSelectedPaymentOptionAction: (newValue: PaymentOption) => void;
    formComplete: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserStartPageComponent;
