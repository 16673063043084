import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    initiateOutgoingDataApi,
    sendAgentFlowCompleteEmail,
    sendIdentityEmail
} from '../../server-application-buffer/outgoing-api-triggering-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { OutGoingApiEventType } from '../../../static/constants/enums/OutGoingApiEventType';

export default function representativeComplete(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendIdentityEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.sendIdentityLinkEmail]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    if (!getFormData(state, 'representative', 'homesteadersMarketCode')) {
                        return resolve();
                    }
                    sendAgentFlowCompleteEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.sendIdentityLinkEmail]
        },
        {
            promiseGenerator: () =>
                new Promise<void>(resolve => {
                    if (state.account.featureEnhancedWebhooks) {
                        initiateOutgoingDataApi(OutGoingApiEventType.representativeComplete, state, dispatch, () => {
                            return resolve();
                        });

                        return resolve();
                    }
                }),
            blocksPageStarts: [urlPaths.sendIdentityLinkEmail]
        }
    ];
}
