import { sendRequest, baseUrl, defaultPostConfiguration } from '../web-request-tools';
import axios from 'axios';
import getEnvironment from 'env-config/get-environment';

export function runContactUpdate(
    opportunityId: string,
    contactType: string,
    contactData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/${contactType}`,
            {
                demoRecord: getEnvironment().createDemoRecords ? true : null,
                ...contactData
            },
            defaultPostConfiguration
        ),
        callback
    );
}

export function sendIdentityFailureEmail(
    opportunityId: string,
    contactType: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/${contactType}/sendIdentityFailedEmail`,
            null,
            defaultPostConfiguration
        ),
        callback
    );
}

export function setStopPageWithDetail(
    opportunityId: string,
    reason: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/setStopPageWithDetail`,
            JSON.stringify(reason),
            defaultPostConfiguration
        ),
        callback
    );
}

export function runRepresentativeUpdate(
    opportunityId: string,
    associationData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/createContactAssociation`,
            associationData,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runBeneficiaryCheckData(
    opportunityId: string,
    beneficiaryData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/beneficiaryProvided`,
            beneficiaryData,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runBeneficiaryIsPurchaser(
    opportunityId: string,
    purchaserId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/beneficiary/purchaser/${purchaserId}`,
            null,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runVitalStatisticsUpdate(
    opportunityId: string,
    vitalStatisticsData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/Recipient/extended`,
            vitalStatisticsData,
            defaultPostConfiguration
        ),
        callback
    );
}
