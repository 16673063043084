// Action Type
export const SET_DISABLE_PAYMENT_PLANS_WHEN_BELOW = 'SET_DISABLE_PAYMENT_PLANS_WHEN_BELOW';
// Action
export function setAccountDisablePaymentPlansWhenBelow(newValue: number) {
    return { type: SET_DISABLE_PAYMENT_PLANS_WHEN_BELOW, newValue };
}
// Reducer
export function setAccountDisablePaymentPlansWhenBelowReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_DISABLE_PAYMENT_PLANS_WHEN_BELOW:
            return action.newValue;
        default:
            return state;
    }
}
