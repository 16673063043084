import React from 'react';
import { ReactComponent as PetalsImage } from '../../../../../static/media/petals.svg';
import ButtonLinkWrapper from '../../../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import { PropShape } from '../../../../page-components/purchase-processing-page-component/PurchaseProcessingPageComponent';
import FullContentCenterWrapper from '../../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../../global-components/page-card-components/page-card-component/PageCardWrapper';
class TulipPurchaseProcessingPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        vitalStatisticsUrl: ''
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="TulipPurchaseProcessing"
                metaPageNumber=""
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <div style={{ textAlign: 'center' }}>
                        <PetalsImage />
                    </div>
                    <PageCardTitleWrapper>Thank you.</PageCardTitleWrapper>
                    <PageCardTextWrapper>You have now purchased your Tulip prepaid plan.</PageCardTextWrapper>
                    <PageCardTextWrapper>
                        The final step is to complete the cremation paperwork. These are the legal documents that will
                        allow us to register the death and authorize the cremation when the time comes. You will need
                        about 15 minutes to complete this paperwork.&nbsp;
                        {!this.props.vitalStatisticsUrl.startsWith('http') &&
                            'Please check your email to start the Tulip cremation paperwork.'}
                    </PageCardTextWrapper>
                    {this.props.vitalStatisticsUrl.startsWith('http') && (
                        <ButtonLinkWrapper
                            to={this.props.vitalStatisticsUrl}
                            buttonId="continue"
                            navigationSideAffect={() => {
                                window.location.href = this.props.vitalStatisticsUrl;
                            }}
                        >
                            Continue
                        </ButtonLinkWrapper>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default TulipPurchaseProcessingPageComponent;
