import validator from 'validator';
import { getValuesFromStringDate } from '../reuseable-logic/date-form-formatter';
import { ABARoutingNumberIsValid } from '../reuseable-logic/routing-number-validator';
import getEnvironment from '../../env-config/get-environment';
import { prodEnvironment } from 'env-config/environment-configs/prod';

export const stringOfLengthRequired = (value: string, minimumLength: number, maximumLength: number) => {
    if (!value) {
        return 'Please enter this required information';
    }
    if (value.length < minimumLength || value.length > maximumLength) {
        if (minimumLength === maximumLength) {
            return `This information must be ${minimumLength} characters`;
        }
        return `This information must be between ${minimumLength} and ${maximumLength} characters`;
    }
};

export const stringWithMaxLength = (
    value: string,
    maximumLength: number,
    errorMessage: string = `Must be less than ${maximumLength} characters`
) => {
    if (!value) {
        return 'Please enter this required information';
    }
    if (value.length > maximumLength) {
        return errorMessage;
    }
};

export const nameMustBeLessThanMax = (value: string) => {
    const maximumLength = 85; // this was tested to be the max the name (first + last with a space between) can be
    const errorMessage = `Name, first and last together, must be less than ${maximumLength} characters`;
    return stringWithMaxLength(value, maximumLength, errorMessage);
};

export const stringIsRequired = (value: string) => {
    if (!value) {
        return 'Please enter this required information';
    }
};

export const booleanIsRequired = (value: boolean) => {
    if (value !== false && value !== true) {
        return 'Please enter this required information';
    }
};

export const emailIsRequried = (value: string) => {
    if (!value) {
        return 'Please enter this required information';
    }
    if (!validator.isEmail(value)) {
        return 'Invalid email address';
    }
};

export const emailIsRequiredAndValid = (value: any) => {
    if (!value.email) {
        return 'Please enter this required information';
    }
    if (!validator.isEmail(value.email)) {
        return 'Invalid email address';
    }
    if (value.email === value.funeralHomeRepresentativeEmail || value.email === value.funeralHomeAgentEmail) {
        return `The purchaser's email cannot be the same as the agent or funeral representative`;
    }
};

export const phoneIsRequired = (value: string) => {
    if (!value) {
        return 'Please enter this required information';
    }
    if (!validator.isMobilePhone(value, 'any')) {
        return 'Invalid phone number';
    }
};

export const validYearRequired = (value: string) => {
    const yearRegex = /^\d{4}$/;
    if (!yearRegex.test(value)) {
        return 'Invalid year';
    }
    const numeralYear = parseInt(value);
    if (numeralYear < 1900) {
        return 'Please enter a year after 1900';
    }
};

export const validMonthRequired = (value: string) => {
    const monthRegex = /^\d{2}$|^\d{1}$/;
    if (!monthRegex.test(value)) {
        return 'Please enter a month between 1 and 12';
    }
    const numeralMonth = parseInt(value);
    if (numeralMonth < 1 || numeralMonth > 12) {
        return 'Please enter a month between 1 and 12';
    }
};

export const validDayRequired = (value: string) => {
    const dayRegex = /^\d{2}$|^\d{1}$/;
    if (!dayRegex.test(value)) {
        return 'Please enter a day between 1 and 31';
    }
    const numeralDay = parseInt(value);
    if (numeralDay < 1 || numeralDay > 31) {
        return 'Please enter a day between 1 and 31';
    }
};

export const validCurrentDateRequired = (value: string) => {
    const currentDate = new Date(Date.now());
    const birthdate = new Date(value);
    if (currentDate < birthdate) {
        return 'Date in the future.';
    }
};

export const validDateRequired = (value: string) => {
    const brokenDownDateData = getValuesFromStringDate(value);
    const monthErrors = validMonthRequired(brokenDownDateData.month);
    const dayErrors = validDayRequired(brokenDownDateData.day);
    const yearErrors = validYearRequired(brokenDownDateData.year);
    const futureDateErrors = validCurrentDateRequired(value);
    return monthErrors || dayErrors || yearErrors || futureDateErrors;
};

export const validPriceIsRequired = (value: string) => {
    if ((value = '0')) {
        return undefined;
    }
    if (!value) {
        return 'Required';
    }
    const priceRegex = /[0-9]+(\.[0-9][0-9]?)?/;
    if (priceRegex.test(value)) {
        return 'Please enter valid price with dollars and cents seperated by a decimal';
    }
};

export const ssnIsRequired = (value: string) => {
    const environment = getEnvironment();

    if (!value) {
        return 'Please enter this required information to help us verify your identity';
    }
    let ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;
    if (!ssnRegex.test(value)) {
        return 'Invalid social security number';
    }
    if (environment === prodEnvironment) {
        ssnRegex = /^\d{3}-?\d{2}-?(0000)$/;
        if (ssnRegex.test(value)) {
            return 'Invalid social security number';
        }
        ssnRegex = /^\d{3}-?\d{2}-?(9999)$/;
        if (ssnRegex.test(value)) {
            return 'Invalid social security number';
        }
        ssnRegex = /^\d{3}-?\d{2}-?(0001)$/;
        if (ssnRegex.test(value)) {
            return 'Invalid social security number';
        }
    }
};

export const validateLast4SSN = (value: any) => {
    const environment = getEnvironment();
    if (!value || !value.ssnLastFour) {
        return 'Please enter this required information to help us verify your identity';
    }
    let ssnRegex = /^[0-9]{4}$/;
    if (!ssnRegex.test(value.ssnLastFour)) {
        return 'Invalid social security number';
    }
    if (environment === prodEnvironment) {
        ssnRegex = /^(0000)$/;
        if (ssnRegex.test(value.ssnLastFour)) {
            return 'Invalid social security number';
        }
        ssnRegex = /^(9999)$/;
        if (ssnRegex.test(value.ssnLastFour)) {
            return 'Invalid social security number';
        }
        ssnRegex = /^(0001)$/;
        if (ssnRegex.test(value.ssnLastFour)) {
            return 'Invalid social security number';
        }
    }
};

export const validationLast4SSN = (value: any) => {
    return { ssnLastFour: validateLast4SSN(value) };
};

export const routingNumberIsRequired = (value: string) => {
    if (!value) {
        return 'Please enter this required information';
    }
    if (!ABARoutingNumberIsValid(value)) {
        return 'This routing number is invalid';
    }
};

export const validateAccountNumber = (value: string) => {
    return stringOfLengthRequired(value, 4, 20);
};

export const phoneVerificationCodeIsRequired = (value: string) => {
    if (!value) {
        return 'Enter verification code';
    }
    if (value.length !== 6) {
        return 'Invalid verification code';
    }
};

const recursiveErrorSearchValid = (formValidationState: any): boolean => {
    if (!formValidationState) {
        return true;
    }
    if (typeof formValidationState === 'string' && formValidationState) {
        // Found error in form
        return false;
    }
    if (typeof formValidationState === 'object') {
        return !Object.keys(formValidationState).some(key => !recursiveErrorSearchValid(formValidationState[key]));
    }
    // No errors found
    return true;
};

export const formIsValid = (formData: any, validationFunction: any): boolean => {
    if (!formData) {
        return false;
    }
    const validationState = validationFunction(formData);
    return recursiveErrorSearchValid(validationState);
};

export const validatePerson = personValues => {
    return {
        name: nameMustBeLessThanMax(personValues.firstName + personValues.lastName),
        firstName:
            stringWithMaxLength(personValues.firstName, 40) ||
            nameMustBeLessThanMax(personValues.firstName + ' ' + personValues.lastName),
        lastName:
            stringWithMaxLength(personValues.lastName, 80) ||
            nameMustBeLessThanMax(personValues.firstName + ' ' + personValues.lastName),
        address: stringOfLengthRequired(personValues.address, 3, 1000),
        city: stringIsRequired(personValues.city),
        state: stringIsRequired(personValues.state),
        zipCode: stringOfLengthRequired(personValues.zipCode, 5, 5),
        phone: phoneIsRequired(personValues.phone),
        birthdate: validDateRequired(personValues.birthdate),
        gender: stringIsRequired(personValues.gender),
        email: emailIsRequiredAndValid(personValues)
    };
};

export const validateErrorForm = values => {
    return {
        name: stringIsRequired(values.name),
        phone: phoneIsRequired(values.phone),
        email: emailIsRequried(values.email),
        subject: stringIsRequired(values.subject),
        details: stringIsRequired(values.details)
    };
};

export const validatePersonWithSSN = (personValues: any) => {
    return {
        ...validatePerson(personValues),
        ssn: ssnIsRequired(personValues.ssn)
    };
};

export const validatePersonWithOnlyFourSSN = (personValues: any) => {
    return {
        ...validatePerson(personValues),
        ssnLastFour: validateLast4SSN(personValues)
    };
};

export const validateBeneficiary = personValues => {
    const validate = {
        ...validatePerson(personValues),
        relationshipToRecipient: stringIsRequired(personValues.relationshipToRecipient)
    };
    return validate;
};

export const validateRecipientWithOnlyFourSSN = personValues => {
    return validatePersonWithOnlyFourSSN(personValues);
};

export const validatePurchaser = personValues => {
    return validatePersonWithSSN(personValues);
};

export const validatePurchaserGenderAndBirthdateNotRequired = personValues => {
    const validPurchaser = validatePerson(personValues);
    // At need purchasers don't need birthdate or gender; take it out
    const { birthdate: lintIgnoredBirthdate, gender: lintIgnoredGender, ...validPurchaserAtNeed } = validPurchaser;
    return validPurchaserAtNeed;
};

export const validateAtNeedRecipient = personValues => {
    const validRecipient = validatePerson(personValues);
    // At need recipients don't need birthdate, gender, phone, or email; take it out
    const {
        birthdate: lintIgnoredBirthdate,
        gender: lintIgnoredGender,
        phone: lintIgnoredPhone,
        email: lintIgnoredEmail,
        ...validPurchaserAtNeed
    } = validRecipient;
    return validPurchaserAtNeed;
};

export const validateRecipient = personValues => {
    return validatePersonWithSSN(personValues);
};

export const validateSSN = (values: any) => {
    return { ssn: ssnIsRequired(values.ssn) };
};

export const validateRepresentativeStart = (values: any) => {
    return {
        representativeId: stringIsRequired(values.representativeId),
        opportunityId: stringIsRequired(values.opportunityId)
    };
};

export const validateIdentityQuestions = (values: any) => {
    const validations = {};
    for (let i = 1; i <= 5; i++) {
        validations[`question-${i}`] = stringIsRequired(values[`question-${i}`]);
    }
    return validations;
};

export const validatePhoneVerificationRequest = (values: any) => {
    return {
        method: stringIsRequired(values.method)
    };
};

export const validatePhoneVerify = (values: any) => {
    return {
        code: phoneVerificationCodeIsRequired(values.code)
    };
};

export const validateSecurityData = (values: any) => {
    return {
        ssnLastFour: stringIsRequired(values.ssnLastFour),
        birthdate: validDateRequired(values.birthdate)
    };
};

export const validateRecipientVital = (values: any) => {
    return {};
};

export const validateMotherVital = (values: any) => {
    return {};
};

export const validateFatherVital = (values: any) => {
    return {};
};

export const validateSpouseVital = (values: any) => {
    return {
        marriageDate: values.marriageDate && validDateRequired(values.marriageDate)
    };
};

export const validateEmploymentVital = (values: any) => {
    return {};
};

export const validateMilitaryVital = (values: any) => {
    return {};
};

export const validateBankingInformation = (values: any) => {
    return {
        nameOnAccount: stringIsRequired(values.nameOnAccount),
        bankName: stringIsRequired(values.bankName),
        accountType: stringIsRequired(values.accountType),
        routingNumber: routingNumberIsRequired(values.routingNumber),
        accountNumber: validateAccountNumber(values.accountNumber)
    };
};

export const validateFakeOpportunity = (values: any) => {
    return {
        predeterminedPayment: booleanIsRequired(values.predeterminedPayment),
        accountSubdomain: stringIsRequired(values.accountSubdomain)
    };
};

export const validateFuneralAgent = (values: any) => {
    return {
        id: stringOfLengthRequired(values.id, 5, 5),
        firstName: stringIsRequired(values.firstName),
        lastName: stringIsRequired(values.lastName),
        homesteadersMarketCode: stringOfLengthRequired(values.homesteadersMarketCode, 2, 2),
        phone: phoneIsRequired(values.phone),
        email: emailIsRequried(values.email),
        timing: stringIsRequired(values.timing)
    };
};

export const validateProductCreation = (values: any) => {
    let validationValues = {
        category: stringIsRequired(values.category),
        goodsAndServicesValue: stringIsRequired(values.goodsAndServicesValue),
        name: stringIsRequired(values.name),
        price: validPriceIsRequired(values.price),
        priceGuaranteed: booleanIsRequired(values.priceGuaranteed),
        hasSalesTax: booleanIsRequired(values.hasSalesTax),
        material: undefined,
        disposition: undefined
    };
    if (['m_casket_cost', 'm_vault_cost'].includes(values.goodsAndServicesValue)) {
        validationValues.material = stringIsRequired(values.material);
    }
    if (['m_casket_cost', 'm_vault_cost'].includes(values.goodsAndServicesValue)) {
        validationValues.disposition = stringIsRequired(values.disposition);
    }
    return validationValues;
};
