import { sendRequest, baseUrl, defaultPostConfiguration, defaultGetConfiguration } from '../web-request-tools';
import axios from 'axios';

// @ts-ignore
// eslint-disable-next-line
export function fetchSavedContractUrls(
    opportunityId: string,
    redirectUrlData: any,
    attempt: number,
    finalCallback: (result: any, error: any) => void
) {
    if (attempt > 0) {
        sendRequest(
            axios.post(
                `${baseUrl}/api/contract/${opportunityId}/DocusignUrl/Preneed Contract/saved`,
                redirectUrlData,
                defaultPostConfiguration
            ),
            (result, error) => {
                if (error || !!result.url) {
                    return finalCallback(result, error);
                }

                return fetchSavedContractUrls(opportunityId, redirectUrlData, attempt - 1, finalCallback);
            }
        );
    } else {
        finalCallback(undefined, 'Exhausted all retries');
    }
}

export function fetchSavedTravelContractUrl(
    opportunityId: string,
    redirectUrlData: any,
    attempt: number,
    finalCallback: (result: any, error: any) => void
) {
    if (attempt > 0) {
        sendRequest(
            axios.post(
                `${baseUrl}/api/contract/${opportunityId}/DocusignUrl/Travel Protection Contract/saved`,
                redirectUrlData,
                defaultPostConfiguration
            ),
            (result, error) => {
                if (error || !!result.url) {
                    return finalCallback(result, error);
                }

                return fetchSavedTravelContractUrl(opportunityId, redirectUrlData, attempt - 1, finalCallback);
            }
        );
    } else {
        finalCallback(undefined, 'Exhausted all retries');
    }
}

export function fetchContractUrls(
    opportunityId: string,
    redirectUrlData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/contract/${opportunityId}/DocusignUrl/Preneed Contract`,
            redirectUrlData,
            defaultPostConfiguration
        ),
        (result, error) => {
            if (error && error.response.status === 504) {
                fetchSavedContractUrls(opportunityId, redirectUrlData, 5, callback);
            } else {
                callback(result, error);
            }
        }
    );
}

export function fetchTravelContractUrl(
    opportunityId: string,
    redirectUrlData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/contract/${opportunityId}/DocusignUrl/Travel Protection Contract`,
            redirectUrlData,
            defaultPostConfiguration
        ),
        (result, error) => {
            if (error && error.response.status === 504) {
                fetchSavedTravelContractUrl(opportunityId, redirectUrlData, 5, callback);
            } else {
                callback(result, error);
            }
        }
    );
}

export function getVerificationGeneratedFuneralContract(
    opportunityId: string,
    callback: (result: any, error: any) => void,
    contractType: 'Preneed Contract' | 'Travel Protection Contract'
) {
    sendRequest(
        axios.get(
            `${baseUrl}/api/contract/${opportunityId}/verifyGeneratedContract/${contractType}`,
            defaultGetConfiguration
        ),
        callback
    );
}

export function getSecureSigningContractUrl(key: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/v3/contracts/urlForSigningKey/${key}`, defaultGetConfiguration), callback);
}

export function runCustomerDeclinedContract(opportunityId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/v3/contracts/${opportunityId}/customerDeclinedContract`,
            null,
            defaultPostConfiguration
        ),
        callback
    );
}
