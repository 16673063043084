import { combineReducers } from 'redux';
import { setStylingLogoUrlReducer } from './logoUrl';
import fontReducer from './fonts/root';
import colorsReducer from './colors/root';
import sizingReducer from './sizing/root';
import spacingReducer from './spacing/root';
import { setStylingLoadingCompleteReducer } from './loadingComplete';
import { setStylingShowNameInTitleReducer } from './showNameInTitle';
import { setUseFormLabelsAsPlaceholdersReducer } from './useFormLabelsAsPlaceholders';

export default combineReducers({
    logoUrl: setStylingLogoUrlReducer,
    colors: colorsReducer,
    fonts: fontReducer,
    sizing: sizingReducer,
    spacing: spacingReducer,
    loadingComplete: setStylingLoadingCompleteReducer,
    showNameInTitle: setStylingShowNameInTitleReducer,
    useFormLabelsAsPlaceholders: setUseFormLabelsAsPlaceholdersReducer
});
