export interface FeatureFlagShape {
    ACHeCheck: boolean;
    agentEditPrices: boolean;
    contractsV3: boolean;
    customHellosignColors: boolean;
    purchaserPhoneVerificationReact: boolean;
    SSNToLast4Digits: boolean;
    useEndOfExperienceOption: boolean; // TODO remove with DEV2020-1830
    usePreneedContractTypeCalculator: boolean;
    useReactForAtNeeds: boolean; // TODO remove with DEV2020-1831
    useSalesForceAgents: boolean;
    useNewOpportunityStages: boolean;
    resprentativeAccessCode: boolean;
    useCustomerPathAssociationObject: boolean; // DEV2020-2171
    contractProductMarketAgentCodeCalculationUpdate: boolean; // EFIT-599
    alternateIdContractSigningCredentials: boolean; // EFIT-1020
    beneficiaryAssociationRework: boolean; //EFIT-1173
    checkIfPurchaserAndRecipientAreSimilar: boolean; // EFIT-1171
    checkIfBeneficiaryAndRecipientAreSimilar: boolean; //EFIT-1172
    returnLaterCase: boolean; //EFIT-1192
    useNodePaymentCalculator: boolean; // EFIT-2925
    cobrandingStorefront: boolean; // EFIT-2214
}

export const defaultFeatureFlags: FeatureFlagShape = {
    ACHeCheck: true,
    agentEditPrices: true,
    contractsV3: true,
    customHellosignColors: false,
    purchaserPhoneVerificationReact: true,
    SSNToLast4Digits: true,
    useEndOfExperienceOption: false,
    usePreneedContractTypeCalculator: false,
    useReactForAtNeeds: false,
    useSalesForceAgents: false,
    useNewOpportunityStages: false,
    resprentativeAccessCode: false,
    useCustomerPathAssociationObject: false, // DEV2020-2171
    contractProductMarketAgentCodeCalculationUpdate: false, // EFIT-599
    alternateIdContractSigningCredentials: false, // EFIT-1020
    beneficiaryAssociationRework: false, //EFIT-1173
    checkIfPurchaserAndRecipientAreSimilar: false, // EFIT-1171
    checkIfBeneficiaryAndRecipientAreSimilar: false, // EFIT-1172
    returnLaterCase: true,
    useNodePaymentCalculator: false, // EFIT-2925
    cobrandingStorefront: false // EFIT-224
};

const actionPrefix = `SET_FEATURE_FLAG_`;

export const setFeatureFlagAction = (flagName: string, value: boolean) => ({
    type: actionPrefix + flagName,
    newValue: value
});

export default (state = defaultFeatureFlags, action) => {
    if (action.type.startsWith(actionPrefix)) {
        const flagName = action.type.replace(actionPrefix, '');
        return { ...state, [flagName]: action.newValue };
    }
    return state;
};
