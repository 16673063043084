export const sortedCategories = [
    {
        category: 'Professional Services',
        subcategories: ['Staff', 'Facilities']
    },
    {
        category: 'Transportation',
        subcategories: ['Transfer', 'Procession']
    },
    {
        category: 'Merchandise',
        subcategories: ['Casket', 'Urn', 'Outer Burial Container']
    },
    {
        category: 'Additional Items',
        subcategories: ['Personalizations', 'Tributes', 'Jewelry', 'Keepsakes', 'Flowers']
    }
];

const getSortedCategoryStrings = () => {
    const sortedCategoryStrings: string[] = [];
    sortedCategories.forEach(categoryObject => {
        categoryObject.subcategories.forEach(subcategory => {
            sortedCategoryStrings.push(`${categoryObject.category} - ${subcategory}`);
        });
        sortedCategoryStrings.push(`${categoryObject.category} - Uncategorized`);
    });
    sortedCategoryStrings.push('Uncategorized');
    return sortedCategoryStrings;
};

export const sortedCategoryStrings = getSortedCategoryStrings();
