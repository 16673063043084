import React from 'react';
import { Redirect } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ApplicationStyling from 'static/models/ApplicationStyling';

class ButtonLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps: any = {
        // Default prop values
        disabled: false,
        navigationSideAffect: null,
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        stylingOptions: undefined
    };

    handleClick() {
        if (this.props.navigationSideAffect) {
            this.props.navigationSideAffect();
        }
        this.setState({ redirecting: true });
    }

    render() {
        if (this.state.redirecting) {
            return <Redirect to={this.props.to} push />;
        }

        // Peel off navigationSideAffect from passed through props
        const { navigationSideAffect, buttonId, ...passthroughProps } = { ...this.props };
        // Avoid never used variable lint error
        // eslint-disable-next-line
        const lintIgnoredNavigationSideAffect = navigationSideAffect;

        return (
            <Button
                {...passthroughProps}
                onClick={this.handleClick}
                style={{
                    fontFamily: ``,
                    marginBottom: '20px',
                    marginTop: this.props.stylingOptions?.spacing?.aboveContinueButton
                        ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                        : '',
                    fontSize: this.props.stylingOptions?.fonts?.buttonFontSize
                }}
                id={'button-' + buttonId.toLowerCase()}
            >
                {this.props.children}
            </Button>
        );
    }
}

export interface PropShape extends ButtonProps {
    to: string;
    buttonId: string;
    navigationSideAffect: Function;
    children: any;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
}

export default ButtonLinkComponent;
