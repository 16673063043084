// Action Type
export const SET_PAYMENT_OPTIONS_UP_TO_DATE = 'SET_PAYMENT_OPTIONS_UP_TO_DATE';
// Action
export function setPaymentOptionsUpToDateAction(newValue: boolean) {
    return { type: SET_PAYMENT_OPTIONS_UP_TO_DATE, newValue };
}
// Reducer
export function setPaymentOptionsUpToDateReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_PAYMENT_OPTIONS_UP_TO_DATE:
            return action.newValue;
        default:
            return state;
    }
}
