import { connect } from 'react-redux';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';
import { setRecipientIsUnavailableAction } from '../../../../redux/opportunity/recipient/isUnavailable';
import { getCartTotal } from '../../../../toolboxes/reuseable-logic/pricing-calculator';
import { setRecipientUnavailalbeRatesDisclaimerAction } from '../../../../redux/disclaimerData/disclaimerAddressings/recipientUnavailableRates';
import PrefilledRecipientIdentityFailedPageComponent from './PrefilledRecipientIdentityFailedPageComponent';
import { PaymentMode } from 'static/constants/enums/paymentModes';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        totalAmount: getCartTotal(state),
        recipientFirstName: getFormData(state, 'recipient', 'firstName'),
        recipientLastName: getFormData(state, 'recipient', 'lastName'),
        hasSelectedMultiPay:
            state.opportunity?.pricing?.isPredetermined &&
            state.opportunity?.pricing?.paymentOptions?.selectedOption?.paymentMode === PaymentMode.Multi
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setRecipientUnavailalbeRatesDisclaimerAction: newValue =>
            dispatch(setRecipientUnavailalbeRatesDisclaimerAction(newValue)),
        setRecipientIsUnavailableAction: newValue => dispatch(setRecipientIsUnavailableAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrefilledRecipientIdentityFailedPageComponent);
