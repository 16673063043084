// Action Type
export const SET_REPLACING_CURRENT_POLICIES = 'SET_REPLACING_CURRENT_POLICIES';
// Action
export function setReplacingCurrentPoliciesAction(newValue: boolean) {
    return { type: SET_REPLACING_CURRENT_POLICIES, newValue };
}
// Reducer
export function setReplacingCurrentPoliciesReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_REPLACING_CURRENT_POLICIES:
            return action.newValue;
        default:
            return state;
    }
}
