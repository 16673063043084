import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_HEADER_LOGO_MAX_HEIGHT = 'SET_STYLING_HEADER_LOGO_MAX_HEIGHT';
// Action
export function setStylingHeaderLogoMaxHeightAction(newValue: string) {
    return { type: SET_STYLING_HEADER_LOGO_MAX_HEIGHT, newValue };
}
// Reducer
export function setStylingHeaderLogoMaxHeightReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().sizing.headerLogoMaxHeight;
    }
    switch (action.type) {
        case SET_STYLING_HEADER_LOGO_MAX_HEIGHT:
            return action.newValue;
        default:
            return state;
    }
}
