import GlobalStateShape from '../../redux/GlobalStateShape';
import { runVitalStatisticsUpdate } from '../web-requests/specific-requests/contact-update-requests';
import { getFormData } from '../redux-form/redux-form-data-access';
import { translateBooleanIntoYesNoString } from './generalized-data-buffer-tools/yes-no-translator';

export function sendSaveVitalStatisticsData(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    runVitalStatisticsUpdate(
        state.opportunity.id,
        {
            middleName: getFormData(state, 'recipientVital', 'middleName'),
            birthplaceCity: getFormData(state, 'recipientVital', 'birthCity'),
            birthplaceStateCode: getFormData(state, 'recipientVital', 'birthState'),
            citizenUSA: translateBooleanIntoYesNoString(getFormData(state, 'recipientVital', 'usaCitizen')),
            employmentEmployer: getFormData(state, 'employmentVital', 'employer'),
            employmentHighestEducation: getFormData(state, 'recipientVital', 'highestEducation'),
            employmentKindOfBusiness: getFormData(state, 'employmentVital', 'kindOfBusiness'),
            employmentNumberOfYears: getFormData(state, 'employmentVital', 'employmentNumberOfYears'),
            employmentRetiredYear: getFormData(state, 'employmentVital', 'retiredYear'),
            employmentStatus: getFormData(state, 'employmentVital', 'employmentStatus'),
            employmentUsualOccupation: getFormData(state, 'employmentVital', 'usualOccupation'),
            ethnicity: getFormData(state, 'recipientVital', 'race'),
            fatherBirthplaceCity: getFormData(state, 'fatherVital', 'birthCity'),
            fatherBirthplaceStateCode: getFormData(state, 'fatherVital', 'birthState'),
            fatherFirstName: getFormData(state, 'fatherVital', 'firstName'),
            fatherLastName: getFormData(state, 'fatherVital', 'lastName'),
            fatherMiddleName: getFormData(state, 'fatherVital', 'middleName'),
            funeralHomeAccountId: state.account.id,
            id: state.opportunity.recipient.id,
            maritalStatus: getFormData(state, 'spouseVital', 'maritalStatus'),
            marriageDate: getFormData(state, 'spouseVital', 'marriageDate'),
            marriagePlaceCity: getFormData(state, 'spouseVital', 'marriageCity'),
            marriagePlaceStateCode: getFormData(state, 'spouseVital', 'marriageState'),
            militaryServiceBranch: getFormData(state, 'militaryVital', 'militaryServiceBranch'),
            militaryServiceCommendations: getFormData(state, 'militaryVital', 'honors'),
            militaryServiceEndDate: getFormData(state, 'militaryVital', 'militaryServiceEndDate'),
            militaryServiceNumber: getFormData(state, 'militaryVital', 'militaryServiceNumber'),
            militaryServiceRank: getFormData(state, 'militaryVital', 'militaryServiceRank'),
            militaryServiceStartDate: getFormData(state, 'militaryVital', 'militaryServiceStartDate'),
            militaryServiceVeteranStatus: translateBooleanIntoYesNoString(
                getFormData(state, 'militaryVital', 'isMilitaryVeteran')
            ),
            motherBirthplaceCity: getFormData(state, 'motherVital', 'birthCity'),
            motherBirthplaceStateCode: getFormData(state, 'motherVital', 'birthState'),
            motherFirstName: getFormData(state, 'motherVital', 'firstName'),
            motherLastName: getFormData(state, 'motherVital', 'lastName'),
            motherMiddleName: getFormData(state, 'motherVital', 'middleName'),
            motherMaidenLastName: getFormData(state, 'motherVital', 'maidenName'),
            opportunityId: state.opportunity.id,
            salutation: getFormData(state, 'recipientVital', 'salutation'),
            spouseFirstName: getFormData(state, 'spouseVital', 'firstName'),
            spouseLastName: getFormData(state, 'spouseVital', 'lastName'),
            spouseMiddleName: getFormData(state, 'spouseVital', 'middleName'),
            spouseMaidenName: getFormData(state, 'spouseVital', 'maidenName')
        },
        callback
    );
}
