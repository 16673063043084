import React from 'react';
import './TitanHeaderComponent.css';
import { Container, Grid, Icon, Typography, Hidden } from '@material-ui/core';
import { titanStyling } from '../../../../../static/constants/styling-company-constants/companies/titan';
import { formatPhoneNumber } from '../../../../../toolboxes/reuseable-logic/string-formatters';
import ApplicationStyling from '../../../../../static/models/ApplicationStyling';
import TitanHeaderBlackLogo from '../../../../../static/media/titan-black.png';

class TitanHeaderComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: titanStyling,
        isAgentStyling: false,
        funeralHomeName: '',
        funeralHomeWebsite: '',
        funeralHomePhoneNumber: ''
    };

    render() {
        if (!this.props.stylingOptions.loadingComplete) {
            return '';
        }

        return (
            <header
                className="ef-header-wrapper"
                style={{
                    borderTop: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    borderBottom: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    backgroundColor: this.props.stylingOptions.colors.headerBackgroundColor
                }}
            >
                <Container>
                    <Grid container className="ef-header" direction="row">
                        <Grid item xs={12} sm={3}>
                            <div className="ef-header-left">
                                <a href={this.props.funeralHomeWebsite} target="_blank" rel="noopener noreferrer">
                                    <img alt="titan logo" src={TitanHeaderBlackLogo} height="36px" />
                                </a>
                            </div>
                        </Grid>
                        <Grid item sm={9}>
                            {!this.props.isAgentStyling && (
                                <Hidden only="xs">
                                    {this.props.funeralHomePhoneNumber && (
                                        <div className="ef-header-right">
                                            <a
                                                className="ef-header-phone"
                                                href={`tel:${this.props.funeralHomePhoneNumber}`}
                                            >
                                                <Icon className="ef-header-phone-icon">phone</Icon>
                                                <Typography className="ef-header-phone-number">
                                                    {formatPhoneNumber(this.props.funeralHomePhoneNumber)}
                                                </Typography>
                                            </a>
                                        </div>
                                    )}
                                </Hidden>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </header>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    isAgentStyling: boolean;
    funeralHomeName: string;
    funeralHomeWebsite: string;
    funeralHomePhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default TitanHeaderComponent;
