import { connect } from 'react-redux';
import TravelContractsPageComponent from './TravelContractsPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getTravelTotal } from '../../../toolboxes/reuseable-logic/pricing-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        contractsUrl: state.travelOpportunity.contractUrl,
        price: getTravelTotal(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelContractsPageComponent);
