import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from 'static/constants/enums/urlPaths';
import { EndOfExperienceOptions } from 'static/constants/enums/endOfExperienceOptions';
import { validateVitalStatistics } from 'toolboxes/page-exit-functions/pages/vitalStatistics';

export function APIEndofExperienceReroutes(state: GlobalStateShape) {
    // Old logic, remove this with DEV2020-1830
    if (
        !state.featureFlags.useEndOfExperienceOption &&
        state.urlPaths.vitalStatistics !== urlPaths.vitalStatistics &&
        state.account?.featureVitalStatistics
    ) {
        window.location.href = state.urlPaths.vitalStatistics;
        return state.urlPaths.vitalStatistics;
    }

    if (state.opportunity.pricing.creditCardInfo.cardRejected) {
        return urlPaths.payment;
    }

    // Reroute to vital stats saved if there are values
    // in the vital stats form and the email has been sent
    if (
        state.opportunity.sentVitalStatisticsEmail &&
        state.account?.featureVitalStatistics &&
        validateVitalStatistics(state)
    ) {
        return urlPaths.vitalStatisticsSaved;
    }

    switch (state.opportunity.endOfExperienceOption) {
        case EndOfExperienceOptions.NavigateToUrl:
            window.location.href = state.opportunity.endOfExperienceReturnLink;
            return state.opportunity.endOfExperienceReturnLink;
        case EndOfExperienceOptions.NavigationPageWithUrl:
            return urlPaths.purchaseProcessingNavigation;
        case EndOfExperienceOptions.BlankPage:
            return urlPaths.purchaseProcessingBlank;
        default:
            // Don't reroute
            return '';
    }
}
