import { connect } from 'react-redux';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import { getAmountToBePaidTotal } from '../../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { setSelectedPaymentOptionAction } from '../../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { formatMonetaryAmount } from '../../../../../../toolboxes/reuseable-logic/string-formatters';
import { setStripeTokenAction } from '../../../../../../redux/opportunity/pricing/stripeTokenId';
import {
    getPaymentOptionsForPaymentType,
    calculateAmountDueToday
} from '../../../../../../toolboxes/reuseable-logic/payment-options-calculator';
import { dataWrapper } from '../../../../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import CreditCardTrustPaymentContentComponent from './CreditCardTrustPaymentContentComponent';
import { setPaymentHoldAddressingAction } from 'redux/disclaimerData/disclaimerAddressings/paymentHold';
import { shouldHidePaymentHoldDisclaimer } from 'toolboxes/reuseable-logic/disclaimer-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        totalPrice: formatMonetaryAmount(getAmountToBePaidTotal(state).toString()),
        dueToday: formatMonetaryAmount(calculateAmountDueToday(state).toString()),
        dueMonthly: formatMonetaryAmount(
            dataWrapper(() => state.opportunity.pricing.paymentOptions.selectedOption.premium)
        ),
        numberOfMonthsToPay: dataWrapper(() => state.opportunity.pricing.paymentOptions.selectedOption.years * 12 - 1),
        paymentOptions: state.opportunity.pricing.isPredetermined
            ? [state.opportunity.pricing.paymentOptions.selectedOption]
            : getPaymentOptionsForPaymentType(state, PaymentMethod.CreditCard),
        selectedOption: state.opportunity.pricing.paymentOptions.selectedOption,
        accountName: state.account.name,
        hidePaymentHoldDisclaimer: shouldHidePaymentHoldDisclaimer(state),
        fundingProviderAccount: state.opportunity.selectedFundingProduct?.provider?.account
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOption: newValue => dispatch(setSelectedPaymentOptionAction(newValue)),
        setStripeTokenId: newValue => dispatch(setStripeTokenAction(newValue)),
        setPaymentHoldAddressingAction: newValue => dispatch(setPaymentHoldAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardTrustPaymentContentComponent);
