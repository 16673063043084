import React from 'react';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import { validatePhoneVerify } from '../../../toolboxes/redux-form/redux-form-validation';
import PinInputFormWrapper from '../base-form-components/pin-input-form-component/PinInputFormWrapper';
class PhoneVerifySecurityFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        disabled: false
    };

    render() {
        return (
            <FormWrapper formName={this.props.formName} validate={validatePhoneVerify}>
                <PinInputFormWrapper
                    fieldPath="code"
                    formName={this.props.formName}
                    inputCount={6}
                    autoFocus={true}
                    disable={this.props.disabled}
                ></PinInputFormWrapper>
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    formName: string;
    disabled: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PhoneVerifySecurityFormComponent;
