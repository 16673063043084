import React from 'react';
import './TulipFooterComponent.css';
import { PropShape as FooterPropShape } from '../../../../global-components/limb-components/footer-component/FooterComponent';

class TulipFooterComponent extends React.Component<FooterPropShape, StateShape> {
    constructor(props: FooterPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        phoneNumber: '',
        stylingOptions: undefined,
        subdomain: '',
        opportunityId: '',
        accountState: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        yelpUrl: '',
        address: '',
        city: '',
        funeralHomeNumber: '',
        zip: '',
        name: '',
        mediaDomain: ''
    };

    render() {
        return (
            <div
                className="footer-container"
                style={{ backgroundColor: this.props.stylingOptions.colors.footerBackgroundColor }}
            >
                {this.props.phoneNumber && <div className="questions-text">Questions?</div>}
                {this.props.phoneNumber && <div className="contact-text">Call us at {this.props.phoneNumber}.</div>}
                <div className="copywrite-text">© 2019 Tulip Cremation, Inc.</div>
            </div>
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default TulipFooterComponent;
