import { defaultTheme } from '../../static/styling/default-theme';
import GlobalStateShape from '../../redux/GlobalStateShape';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { PaletteColorOptions } from '@material-ui/core/styles/createPalette';
import memoizeOne from 'memoize-one';
import { CSSProperties } from 'react';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export function calculateGlobalStyle(state: GlobalStateShape): CSSProperties {
    const globalStyle: CSSProperties = {};
    if (state.stylingOptions.colors.bodyColor) {
        globalStyle.color = state.stylingOptions.colors.bodyColor;
    }
    if (state.stylingOptions.fonts.bodyFontFamily) {
        globalStyle.fontFamily = state.stylingOptions.fonts.bodyFontFamily;
    }
    if (state.stylingOptions.colors.generalBackgroundColor) {
        globalStyle.backgroundColor = state.stylingOptions.colors.generalBackgroundColor;
    }
    return globalStyle;
}

const createMemoizedMuiTheme = memoizeOne(createMuiTheme, (newArgs, lastArgs) => {
    return JSON.stringify(newArgs) === JSON.stringify(lastArgs);
});

export function calculateGlobalMuiStyle(state: GlobalStateShape) {
    // Typed as any, as material has typing issues
    const theme: any = JSON.parse(JSON.stringify(defaultTheme));
    if (state.stylingOptions.colors.primaryColor) {
        const primaryPalette: PaletteColorOptions = {
            main: state.stylingOptions.colors.primaryColor,
            contrastText: state.stylingOptions.colors.primaryContrastColor
        };
        theme.palette.primary = primaryPalette;
    }
    if (state.stylingOptions.colors.secondaryColor) {
        const secondaryPalette: PaletteColorOptions = {
            main: state.stylingOptions.colors.secondaryColor,
            contrastText: state.stylingOptions.colors.secondaryContrastColor
        };
        theme.palette.secondary = secondaryPalette;
    }
    if (state.stylingOptions.fonts.titleFontFamily) {
        const titleTypography: TypographyOptions = {
            fontFamily: state.stylingOptions.fonts.titleFontFamily
        };
        theme.typography.h1.fontFamily = titleTypography;
        theme.typography.h2.fontFamily = titleTypography;
        theme.typography.h3.fontFamily = titleTypography;
        theme.typography.h4.fontFamily = titleTypography;
        theme.typography.h5.fontFamily = titleTypography;
        theme.typography.h6.fontFamily = titleTypography;
    }
    if (state.stylingOptions.fonts.buttonFontFamily) {
        theme.typography.button.fontFamily = state.stylingOptions.fonts.buttonFontFamily;
    }
    if (state.stylingOptions.colors.placeholderFullOpacity) {
        theme.overrides.MuiInput.input = {
            '&::placeholder': {
                opacity: 1
            }
        };
    }

    return createMemoizedMuiTheme(theme);
}
