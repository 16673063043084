import { connect } from 'react-redux';
import RecipientFormComponent from './RecipientFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { change } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        autoFillSSN: () => dispatch(change('recipient', 'ssn', '000000000'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientFormComponent);
