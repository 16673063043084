import GlobalStateShape from '../../redux/GlobalStateShape';
import { dataWrapper } from '../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { PaymentMode } from '../../static/constants/enums/paymentModes';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { isAtNeed } from './opportunity-timing-utils';

export function calculateIsIncapacitated(state: GlobalStateShape) {
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    // With the current product, everyone gets incapacitated rates
    const selectedFundingProduct = state.opportunity.selectedFundingProduct;
    // Homesteaders 600's are always incapacitated
    const contractDefaultsToIncapacitated =
        selectedFundingProduct.provider?.key === 'Homesteaders' && selectedFundingProduct.contractId.startsWith('6');
    const healthHistoryResponses = state.yesNoAnswers.healthHistoryQuestions;
    const insuredIsIncapacitated =
        healthHistoryResponses.isBedriddenOrAiled || healthHistoryResponses.wasBedriddenOrAiledInLastFiveYears;
    return !isTrust && (contractDefaultsToIncapacitated || insuredIsIncapacitated);
}

export function calculateHealthQuestionsRelevant(state: GlobalStateShape) {
    if (isAtNeed(state)) {
        return false;
    }
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    const selectedFundingProduct = state.opportunity.selectedFundingProduct;
    return (
        !isTrust &&
        selectedFundingProduct.contractId &&
        selectedFundingProduct.contractId.startsWith('2') &&
        selectedFundingProduct.provider?.key === 'Homesteaders' &&
        state.opportunity.pricing.paymentOptions.selectedOption.paymentMode === PaymentMode.Multi
    );
}

export function calculateHealthQuestionsNecessary(state: GlobalStateShape) {
    if (isAtNeed(state)) {
        return false;
    }
    // Homesteaders 200 contracts get health questions
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    return (
        !isTrust &&
        calculateHealthQuestionsRelevant(state) &&
        (![true, false].includes(state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled) ||
            ![true, false].includes(state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears))
    );
}

export function calculateIncapacitatedDisclaimerNecessary(state: GlobalStateShape) {
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    return dataWrapper(() => {
        return (
            !isTrust &&
            state.opportunity.pricing.paymentOptions.selectedOption.paymentMode === PaymentMode.Multi &&
            calculateIsIncapacitated(state) &&
            !state.disclaimerData.disclaimerAddressings.paymentPlanHealthImpact.hasBeenAcceptedOrPositivelyAddressed
        );
    });
}
