import { connect } from 'react-redux';
import OpportunityStatusPageComponent from './OpportunityStatusPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions,
        stageName: state.opportunity.activeStage,
        opportunityId: state.opportunity.id,
        returnLaterLink: state.opportunity.returnLaterLink
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityStatusPageComponent);
