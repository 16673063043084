import { connect } from 'react-redux';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import PhoneVerificationRequestSecurityFormComponent from './PhoneVerificationRequestSecurityFormComponent';
import { sendDataToCreateTwilioVerificationCase } from '../../../toolboxes/server-application-buffer/outgoing-api-triggering-data';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    return {
        createCase: () =>
            sendDataToCreateTwilioVerificationCase(state, (result: any, error: any) => {
                console.log(result);
            }),
        phoneNumber: state.opportunity?.purchaser?.phoneNumber,
        createCaseFeatureFlag: state.featureFlags?.returnLaterCase
    };
};

const mapDispatchToProps: any = (dispatch: Function, myProps: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerificationRequestSecurityFormComponent);
