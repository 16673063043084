import ButtonSubtitledLinkWrapper from 'components/global-components/buttons/button-subtitled-link-component/ButtonSubtitledLinkWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import React, { Fragment } from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
class ReplacementPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setHasCurrentPoliciesAction: () => {},
        setReplacingCurrentPoliciesAction: () => {},
        hasCurrentPolicies: false
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName={undefined}
                metaPageNumber={undefined}
                metaPagebackAvailable={undefined}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Policy Replacement</PageCardTitleWrapper>
                    {this.props.hasCurrentPolicies ? (
                        <Fragment>
                            <PageCardTextWrapper>
                                Will the proposed application replace any existing life insurance or annuity contracts?
                            </PageCardTextWrapper>
                            <ButtonSubtitledLinkWrapper
                                // This key is necessary for react to keep track of who's redirect we should be using
                                key="1"
                                to={urlPaths.unavailableReplacementState}
                                title={'Yes'}
                                subtitle=""
                                buttonId="is-replacing"
                                navigationSideEffect={() => {
                                    this.props.setReplacingCurrentPoliciesAction(true);
                                }}
                            />
                            <ButtonSubtitledLinkWrapper
                                // This key is necessary for react to keep track of who's redirect we should be using
                                key="2"
                                to={urlPaths.orderSummary}
                                title={'No'}
                                subtitle=""
                                buttonId="is-not-replacing"
                                navigationSideEffect={() => {
                                    this.props.setReplacingCurrentPoliciesAction(false);
                                }}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <PageCardTextWrapper>
                                Do you currently own any life insurance policies or annuities?
                            </PageCardTextWrapper>
                            <ButtonSubtitledLinkWrapper
                                // This key is necessary for react to keep track of who's redirect we should be using
                                key="3"
                                to={urlPaths.orderSummary}
                                title={'Yes'}
                                subtitle=""
                                buttonId="has-policy"
                                navigationSideEffect={() => {
                                    this.props.setHasCurrentPoliciesAction(true);
                                    this.props.setReplacingCurrentPoliciesAction(null);
                                }}
                            />
                            <ButtonSubtitledLinkWrapper
                                // This key is necessary for react to keep track of who's redirect we should be using
                                key="4"
                                to={urlPaths.orderSummary}
                                title={'No'}
                                subtitle=""
                                buttonId="has-no-policy"
                                navigationSideEffect={() => {
                                    this.props.setHasCurrentPoliciesAction(false);
                                    this.props.setReplacingCurrentPoliciesAction(false);
                                }}
                            />
                        </Fragment>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setHasCurrentPoliciesAction: (newValue: boolean) => void;
    setReplacingCurrentPoliciesAction: (newValue: boolean) => void;
    hasCurrentPolicies: boolean;
}

interface StateShape {
    // Shape of local state
}

export default ReplacementPageComponent;
