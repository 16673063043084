// Action Type
export const SET_REPRESENTATIVE_DID_HANDOFF = 'SET_REPRESENTATIVE_DID_HANDOFF';
// Action
export function setRepresentativeDidHandoffAction(newValue: boolean) {
    return { type: SET_REPRESENTATIVE_DID_HANDOFF, newValue };
}
// Reducer
export function setRepresentativeDidHandoffReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_REPRESENTATIVE_DID_HANDOFF:
            return action.newValue;
        default:
            return state;
    }
}
