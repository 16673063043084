import { Grid } from '@material-ui/core';
import React from 'react';
import { orderedNewStages } from 'static/constants/opportunityStages';
import { addSpaceBetweenWords } from 'toolboxes/reuseable-logic/string-formatters';
import './StageProgressComponent.css';

class StageProgressComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: null,
        stageName: null
    };

    getStageStyle = (index: number) => {
        for (let i = 0; i < orderedNewStages.length; i++) {
            if (orderedNewStages[i] === this.props.stageName) {
                if (index < i) {
                    return {
                        color: this.props.stylingOptions.colors.primaryColor,
                        backgroundColor: `${this.props.stylingOptions.colors.primaryColor}80`,
                        border: `1px solid ${this.props.stylingOptions.colors.primaryColor}`
                    };
                }
                if (index === i) {
                    return {
                        backgroundColor: this.props.stylingOptions.colors.primaryColor,
                        border: `1px solid ${this.props.stylingOptions.colors.primaryColor}`
                    };
                }
                if (index > i) {
                    return {
                        backgroundColor: '#FFD72E80',
                        border: '1px solid #FFD72E'
                    };
                }
            }
        }

        return {};
    };

    render() {
        return (
            <Grid container>
                {orderedNewStages.map((stage, i) => (
                    <Grid item className="stage" style={this.getStageStyle(i)} key={i}>
                        {addSpaceBetweenWords(stage)}
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: any;
    stageName: string;
}

interface StateShape {
    // Shape of local state
}

export default StageProgressComponent;
