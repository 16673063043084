// Action Type
export const SET_STRIPE_TOKEN = 'SET_STRIPE_TOKEN';
// Action
export function setStripeTokenAction(newValue: string) {
    return { type: SET_STRIPE_TOKEN, newValue };
}
// Reducer
export function setStripeTokenReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STRIPE_TOKEN:
            return action.newValue;
        default:
            return state;
    }
}
