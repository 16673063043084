import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import TotalDisplayWrapper from '../order-summary-page-component/page-specific-components/total-display-component/TotalDisplayWrapper';
import PageCardUnderlinedSectionWrapper from '../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import './PurchaserOrderSummaryPageComponent.css';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import PurchaserOrderSummaryProductsGroupingWrapper from './page-specific-components/purchaser-order-summary-products-grouping-component/PurchaserOrderSummaryProductsGroupingWrapper';
import PurchaserOrderSummaryDemographicsGroupingWrapper from './page-specific-components/purchaser-order-summary-demographics-grouping-component/PurchaserOrderSummaryDemographicsGroupingWrapper';
import PurchaserOrderSummaryPaymentSummaryWrapper from './page-specific-components/purchaser-order-summary-payment-summary-component/PurchaserOrderSummaryPaymentSummaryWrapper';
import PurchaserOrderSummaryHealthQuestionsWrapper from './page-specific-components/purchaser-order-summary-health-questions-component/PurchaserOrderSummaryHealthQuestionsWrapper';
import IrrevocableSelectionWrapper from '../order-summary-page-component/page-specific-components/irrevocable-selection-component/IrrevocableSelectionWrapper';

class PurchaserOrderSummaryPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        totalAmount: '',
        isPurchaserValid: false,
        nextUrl: undefined
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="PurchaserOrderSummary"
                metaPageNumber="2"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Your Total</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please review the information you've provided below. When you are ready, click continue to
                        verify your identity and complete your preneed contract and insurance enrollment form.
                    </PageCardTextWrapper>
                    <TotalDisplayWrapper />
                    <PurchaserOrderSummaryDemographicsGroupingWrapper />
                    <PurchaserOrderSummaryHealthQuestionsWrapper />
                    <PurchaserOrderSummaryProductsGroupingWrapper />
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="Total Amount"
                        sectionId="total-amount"
                        fullContent={this.props.totalAmount}
                    />
                    <PurchaserOrderSummaryPaymentSummaryWrapper />
                    <div className="lower-information-card">
                        <IrrevocableSelectionWrapper />
                    </div>
                    <ButtonLinkWrapper
                        buttonId="continue"
                        to={this.props.nextUrl}
                        disabled={!this.props.isPurchaserValid}
                    >
                        Continue
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    totalAmount: string;
    isPurchaserValid: boolean;
    nextUrl: urlPaths;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryPageComponent;
