import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { formIsValid, validatePhoneVerify } from '../../redux-form/redux-form-validation';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendSubmitPhoneVerify } from '../../server-application-buffer/phone-verification-data';
import { setTimesPurchaserPhoneVerificationSubmittedAction } from '../../../redux/opportunity/purchaser/timesPhoneVerificationSubmitted';
import { setPurchaserPhoneVerifiedAction } from '../../../redux/opportunity/purchaser/phoneVerified';

export function submitPurchaserPhoneVerify(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    if (!formIsValid(getFormValues('purchaserPhoneVerification')(state), validatePhoneVerify)) {
        return [];
    }
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSubmitPhoneVerify(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        dispatch(
                            setTimesPurchaserPhoneVerificationSubmittedAction(
                                state.opportunity.purchaser.timesPhoneVerificationSubmitted + 1
                            )
                        );
                        dispatch(setPurchaserPhoneVerifiedAction(result));

                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        }
    ];
}
