import { getFormValues } from 'redux-form';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { formIsValid } from '../../redux-form/redux-form-validation';
import { sendSaveRecipientData, sendSavePurchaserData } from '../../server-application-buffer/contact-data';
import {
    saveIsPlanningForSelf,
    saveRelationshipToRecipient
} from '../../server-application-buffer/general-opportunity-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getRecipientValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';
import { sendSavePurchaserRecipientSamePersonDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import { calculateContractCodes } from 'toolboxes/page-enter-functions/pages/annuityInsurance';

export function saveRecipientData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    const validateFunction = getRecipientValidationFunction(state);

    if (
        state.opportunity.recipient.sameAsPurchaser &&
        formIsValid(getFormValues('purchaser')(state), validateFunction)
    ) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSavePurchaserData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.orderSummary]
        });
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSaveRecipientData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.orderSummary, urlPaths.recipientIdentity]
        });
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    saveIsPlanningForSelf(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.orderSummary]
        });
        if (state.account.stylingKey === EARTH_STYLING_KEY) {
            // We're saving recipient info for earth, we need to calculate contract codes before we reach payment
            functionsToRun.push(...calculateContractCodes(state, dispatch));
        }
    } else if (formIsValid(getFormValues('recipient')(state), validateFunction)) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSaveRecipientData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.orderSummary, urlPaths.recipientIdentity]
        });
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    saveIsPlanningForSelf(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.orderSummary]
        });
        if (state.account.stylingKey === EARTH_STYLING_KEY) {
            functionsToRun.push({
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        saveRelationshipToRecipient(state, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    }),
                blocksPageStarts: [urlPaths.orderSummary]
            });
        }
        if (state.account.stylingKey === EARTH_STYLING_KEY) {
            // We're saving recipient info for earth, we need to calculate contract codes before we reach payment
            functionsToRun.push(...calculateContractCodes(state, dispatch));
        }
    }

    if (state.disclaimerData.disclaimerAddressings.purchaserRecipientSamePerson.responseToDisclaimer) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSavePurchaserRecipientSamePersonDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: []
        });
    }

    return functionsToRun;
}
