import React from 'react';
import { formatDate, formatHiddenSSN, formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import './DemographicInformationCopmponent.css';

class DemographicInformationCopmponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        contact: null
    };

    render() {
        return (
            <div>
                {this.props.contact ? (
                    <>
                        <div className="contact-block">
                            <div>
                                {this.props.contact.firstName} {this.props.contact.lastName}
                            </div>
                            <div>{this.props.contact.address}</div>
                            <div>
                                {this.props.contact.city}, {this.props.contact.state} {this.props.contact.zipCode}
                            </div>
                        </div>
                        <div className="contact-block">
                            <div>Email:{this.props.contact.email}</div>
                            <div>Phone: {formatPhoneNumber(this.props.contact.phone)}</div>
                            <div>DOB: {formatDate(this.props.contact.birthdate)}</div>
                            <div>Gender: {this.props.contact.gender}</div>
                            <div>{this.props.contact.ssn && `${formatHiddenSSN(this.props.contact.ssn)}`}</div>
                        </div>
                    </>
                ) : (
                    <div>None</div>
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    contact?: any;
}

interface StateShape {
    // Shape of local state
}

export default DemographicInformationCopmponent;
