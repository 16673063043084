import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { setOpportunityTimingAction } from '../../../redux/opportunity/timing';
import urlPaths from 'static/constants/enums/urlPaths';
import { sendSaveAgentData } from 'toolboxes/server-application-buffer/agent-data';
import { setAccountAgentIdAction } from 'redux/account/agent/id';

export function saveAgentData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];
    if (state.featureFlags.useSalesForceAgents) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSaveAgentData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }

                        dispatch(setAccountAgentIdAction(result.id));
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.productSelection]
        });
    }

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                window.localStorage.setItem('representativeId', getFormData(state, 'representative', 'id'));
                window.localStorage.setItem(
                    'respresentativeFirstName',
                    getFormData(state, 'representative', 'firstName')
                );
                window.localStorage.setItem(
                    'respresentativeLastName',
                    getFormData(state, 'representative', 'lastName')
                );
                window.localStorage.setItem('respresentativePhone', getFormData(state, 'representative', 'phone'));
                window.localStorage.setItem('respresentativeEmail', getFormData(state, 'representative', 'email'));
                dispatch(setOpportunityTimingAction(getFormData(state, 'representative', 'timing')));
                resolve();
            }),
        blocksPageStarts: []
    });

    return functionsToRun;
}
