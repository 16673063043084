import { sendRequest, baseUrl, defaultPostConfiguration, defaultGetConfiguration } from '../web-request-tools';
import axios from 'axios';

export function runAgentUpdate(contactData: any, accountId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/agent/${accountId}`,
            {
                ...contactData
            },
            defaultPostConfiguration
        ),
        callback
    );
}

export function runGetAgentPermissionsForAccountId(
    agentId: string,
    accountId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.get(`${baseUrl}/api/agent/${agentId}/permissions/${accountId}`, defaultGetConfiguration),
        callback
    );
}
