import { connect } from 'react-redux';
import PastHealthComponent from './PastHealthComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setWasBedriddenOrAiledAction } from '../../../../redux/yesNoAnswers/healthHistoryQuestions/wasBedriddenOrAiledInLastFiveYears';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';

function calculatePersonIndicationText(state: GlobalStateShape): string {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return `Have you ${getFormData(state, 'purchaser', 'firstName')}`;
    }
    return `Has the recipient ${getFormData(state, 'recipient', 'firstName')}`;
}

function calculatePersonPronounText(state: GlobalStateShape): string {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return `are you`;
    }
    return 'are they';
}

function calculatePersonPronounCapitalized(state: GlobalStateShape): string {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return `I`;
    }
    return 'They';
}

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        customizableText: {
            personIndicationText: calculatePersonIndicationText(state),
            personPronounText: calculatePersonPronounText(state),
            personPronounCapitalized: calculatePersonPronounCapitalized(state)
        }
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setWasBedriddenOrAiledAction: newValue => dispatch(setWasBedriddenOrAiledAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastHealthComponent);
