import css from 'css';
import memoizeOne from 'memoize-one';
import { dataWrapper } from '../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
const memoizedCssParser = memoizeOne(css.parse);
// This toolbox can find a property on a given css classname within a given css string
export default function getCssClassProperty(className: string, propertyName: string, fullCssString: string): string {
    return dataWrapper(() => {
        const cssAst = memoizedCssParser(fullCssString);
        const rule = cssAst.stylesheet.rules.find(rule => rule.selectors.includes(className));
        const declaration = rule.declarations.find(declaration => declaration.property === propertyName);
        return declaration.value;
    });
}
