import { connect } from 'react-redux';
import IrrevocableConfirmationModalComponent from './IrrevocableConfirmationModalComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setIrrevocableAction } from '../../../../../redux/yesNoAnswers/policyQuestions/irrevocable';
import { setIrrevocabilityAddressingAction } from '../../../../../redux/disclaimerData/disclaimerAddressings/irrevocabilityConfirmation';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        purchaserState: getFormData(state, 'purchaser', 'state')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIrrevocableAction: newValue => dispatch(setIrrevocableAction(newValue)),
        setIrrevocabilityAddressingAction: newValue => dispatch(setIrrevocabilityAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrevocableConfirmationModalComponent);
