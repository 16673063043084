import { connect } from 'react-redux';
import RecipientUnavailableImpactPageComponent from './RecipientUnavailableImpactPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';
import { setRecipientIsUnavailableAction } from '../../../../redux/opportunity/recipient/isUnavailable';
import { setRecipientUnavailalbeRatesDisclaimerAction } from '../../../../redux/disclaimerData/disclaimerAddressings/recipientUnavailableRates';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        recipientFirstName: getFormData(state, 'recipient', 'firstName'),
        trustText:
            state.opportunity.preneedContractType === PreneedContractType.Trust ? 'have a trust policy' : 'be insured'
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setRecipientUnavailalbeRatesDisclaimerAction: newValue =>
            dispatch(setRecipientUnavailalbeRatesDisclaimerAction(newValue)),
        setRecipientIsUnavailableAction: newValue => dispatch(setRecipientIsUnavailableAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientUnavailableImpactPageComponent);
