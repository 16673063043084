// The information that the user enters is different than what is saved. This translates to prepare for saving

import Product from '../../../static/models/Product';
import { dataWrapper } from '../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { Disposition } from '../../../static/constants/enums/disposition';
import { findSelectedProductCreationType } from '../../form-components/product-creation-form/product-subcategory-calculator';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';

export default function translateCreatedProduct(state: GlobalStateShape) {
    const unformattedProduct: any = getFormValues('productCreation')(state);
    return dataWrapper(() => {
        const subcategory = findSelectedProductCreationType(unformattedProduct.goodsAndServicesValue).subcategory;
        const newProduct: Product = {
            name: unformattedProduct.name,
            productId: null,
            category: unformattedProduct.category,
            subcategory,
            quantity: 0,
            price: parseFloat(unformattedProduct.price),
            taxIsApplicable: unformattedProduct.hasSalesTax,
            explicitTax: null,
            salesDescription: unformattedProduct.description,
            imageUrl: '',
            priceIsGuaranteed: unformattedProduct.priceGuaranteed,
            goodsAndServicesContractLabel: unformattedProduct.goodsAndServicesValue,
            modelNumber: unformattedProduct.modelNumber,
            manufacturer: unformattedProduct.manufacturer,
            material: unformattedProduct.material,
            exteriorDescription: unformattedProduct.exteriorDescription,
            interiorDescription: unformattedProduct.interiorDescription,
            disposition: unformattedProduct.disposition,
            included: false // the product creation form needs to be updated to allow included
        };
        if (newProduct.goodsAndServicesContractLabel === 'm_urn') {
            newProduct.disposition = Disposition.Cremation;
        }
        if (['m_casket_cost', 'm_vault_cost', 'm_urn'].includes(newProduct.goodsAndServicesContractLabel)) {
            newProduct.modelName = newProduct.name;
        }
        return newProduct;
    }, null);
}
