import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { fetchAndStoreTravelContractUrl } from '../../server-application-buffer/contracts-data';
import urlPaths from '../../../static/constants/enums/urlPaths';

export default function loadTravelContracts(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    let validContractUrl = true;
    if (!state.travelOpportunity.contractUrl || !state.travelOpportunity.contractUrlDate) {
        validContractUrl = false;
    } else {
        const contractUrlAge = (new Date().getTime() - state.travelOpportunity.contractUrlDate.getTime()) / 1000;
        // if the contractUrl is over 4.5 minutes, we invalidate it so get a new one (5 minute timeout on DocuSign Urls)
        if (contractUrlAge > 270) {
            validContractUrl = false;
        }
    }

    if (state.travelOpportunity.id && !validContractUrl) {
        return [
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        fetchAndStoreTravelContractUrl(state, dispatch, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            resolve();
                        });
                    }),
                blocksPageStarts: [urlPaths.travelContracts]
            }
        ];
    }
    return [];
}
