import React, { Fragment } from 'react';
import PageCardUnderlinedSectionWrapper from '../../../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import Product from '../../../../../static/models/Product';
import {
    formatMonetaryAmount,
    getFormattedPriceForProduct
} from '../../../../../toolboxes/reuseable-logic/string-formatters';

const sortableCategories = ['Professional Services', 'Mechandise', 'Additional Items'];

class PurchaserOrderSummaryProductsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    generateProductDescription(product: Product, index: number) {
        return (
            <div key={'product-' + index} className="purchaser-order-summary-split-div">
                <div>
                    {product.quantity} x {product.name}
                </div>
                <div id={`text-product-${product.productId}-total`}>{getFormattedPriceForProduct(product)}</div>
            </div>
        );
    }

    render() {
        const uncategorizedArrangementProducts = this.props.products.filter(
            product => !sortableCategories.includes(product.category)
        );
        const dashedSectionTitle = this.props.sectionTitle.replace(/ /g, '-');
        return (
            <PageCardUnderlinedSectionWrapper
                sectionTitle={this.props.sectionTitle}
                sectionId="funeral-products"
                fullContent={
                    <Fragment>
                        {sortableCategories.map((category, index) => {
                            let filteredProducts = this.props.products.filter(product => product.category === category);
                            if (filteredProducts.length === 0) {
                                return null;
                            }
                            return (
                                <Fragment key={'category-' + index}>
                                    <div className="sorted-category-title">{category}</div>
                                    {filteredProducts.map((product, index) =>
                                        this.generateProductDescription(product, index)
                                    )}
                                </Fragment>
                            );
                        })}
                        {uncategorizedArrangementProducts.length > 0 && (
                            <Fragment>
                                <div className="sorted-category-title">Other</div>
                                {uncategorizedArrangementProducts.map((product, index) =>
                                    this.generateProductDescription(product, index)
                                )}
                            </Fragment>
                        )}
                        <br />
                        <div className="purchaser-order-summary-split-div">
                            <div>Subtotal</div>
                            <div id={`text-subtotal-${dashedSectionTitle}`}>
                                {formatMonetaryAmount(this.props.subtotal)}
                            </div>
                        </div>
                        <div className="purchaser-order-summary-split-div">
                            <div>Sales Tax</div>
                            <div id={`text-tax-${dashedSectionTitle}`}>{formatMonetaryAmount(this.props.tax)}</div>
                        </div>
                        <div className="purchaser-order-summary-split-div">
                            <div>Total</div>
                            <div id={`text-total-${dashedSectionTitle}`}>{formatMonetaryAmount(this.props.total)}</div>
                        </div>
                    </Fragment>
                }
                summaryContent={
                    <div className="purchaser-order-summary-split-div">
                        <div>{this.props.sectionTitle}</div>
                        <div id={`text-total-${dashedSectionTitle}`}>{formatMonetaryAmount(this.props.total)}</div>
                    </div>
                }
            />
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    sectionTitle: string;
    products: Product[];
    subtotal: string;
    tax: string;
    total: string;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryProductsDisplayComponent;
