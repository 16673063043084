import React from 'react';
import './PageCardComponent.css';
import FailingRequestButtonWrapper from 'components/global-components/page-card-components/page-card-component/FailingRequestButtonComponent/FailingRequestButtonWrapper';
import ApplicationStyling from 'static/models/ApplicationStyling';

class PageCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraCardWidth: false,
        extraCardLength: false,
        fullWidth: false,
        includeFailingRequestButton: false,
        stylingOptions: undefined
    };

    render() {
        let maxWidth = '';
        if (this.props.extraCardWidth) {
            maxWidth = '720px';
        } else if (this.props.fullWidth) {
            maxWidth = 'none';
        } else {
            maxWidth = '480px';
        }

        return (
            <div
                className="page-card"
                style={{
                    maxWidth,
                    justifyContent:
                        this.props.extraCardLength || this.props.stylingOptions?.spacing?.abovePageTitle
                            ? 'flex-start'
                            : '',
                    flexBasis: this.props.fullWidth ? '100%' : '',
                    paddingTop: this.props.stylingOptions?.spacing?.abovePageTitle
                }}
            >
                {this.props.children}
                {this.props.includeFailingRequestButton && <FailingRequestButtonWrapper />}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    extraCardWidth: boolean;
    extraCardLength: boolean;
    fullWidth: boolean;
    includeFailingRequestButton: boolean;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default PageCardComponent;
