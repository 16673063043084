import React, { Fragment } from 'react';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import { Disposition } from '../../../../static/constants/enums/disposition';
import { GeographicState } from '../../../../static/constants/enums/states';
import MockLinkWrapper from '../../../global-components/mock-link-component/MockLinkWrapper';
import { openTabWithUrl } from '../../../../toolboxes/reuseable-logic/tab-opener';

class ProductDisclaimerPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        isInsurance: false,
        accountName: '',
        setAnnuityInsuranceAddressingAction: () => {},
        purchaserState: '',
        accountState: '',
        efuneralMediaUrl: '',
        firstPage: false
    };

    generateDisclaimerTitle() {
        return this.props.isInsurance
            ? 'Buying Insurance for Prepaid Arrangements'
            : 'Buying an Annuity for Prepaid Arrangements';
    }

    generateDisclaimerText() {
        const containerText = this.props.isInsurance ? 'life insurance policy' : 'annuity';
        const prefacedContainerText = this.props.isInsurance ? 'a life insurance policy' : 'an annuity';
        const dispositionText = this.props.disposition ? this.props.disposition.toLowerCase() : 'funeral';
        return (
            <div>
                When prepaying for a {dispositionText}, your funds will be put into {prefacedContainerText} to ensure
                your money is protected and available when needed. The death benefit of this policy will be assigned to{' '}
                {this.props.accountName} in exchange for them performing your prepaid {dispositionText} agreement when
                the need arises. The {containerText} funding your prearranged funeral contract will be issued by
                Homesteaders Life Company.{' '}
                {this.props.purchaserState === GeographicState.CA && (
                    <Fragment>
                        In order to be compliant with CPRA regulations, Homesteaders has provided information about the
                        usage of your personal information. For those applicable, the information can be found{' '}
                        <MockLinkWrapper
                            onClick={() =>
                                openTabWithUrl(`${this.props.efuneralMediaUrl}/notices/CA/CPRA+Notice+Rev1222.pdf`)
                            }
                            id="CPRA-notice"
                        >
                            here
                        </MockLinkWrapper>
                        .
                    </Fragment>
                )}
                {this.props.accountState === GeographicState.TX && (
                    <Fragment>
                        Finally, we want to provide you with the Texas Department of Banking Notice. You may click
                        <MockLinkWrapper
                            onClick={() => openTabWithUrl(`${this.props.efuneralMediaUrl}/notices/TX/texas-dob.pdf`)}
                            id="DOB-notice"
                        >
                            &nbsp;here&nbsp;
                        </MockLinkWrapper>
                        to view it, or it is also displayed below.
                        <br />
                        <br />
                        <iframe
                            title="Texas Department of Banking Notice"
                            src={`${this.props.efuneralMediaUrl}/notices/TX/texas-dob.pdf`}
                            style={{
                                minWidth: '100%',
                                minHeight: '300px'
                            }}
                        />
                    </Fragment>
                )}
            </div>
        );
    }

    render() {
        return (
            <StandardDisclaimerPageWrapper
                disclaimerTitle={this.generateDisclaimerTitle()}
                acceptTo={urlPaths.beneficiaryCheck}
                acceptanceText="I agree"
                rejectionText=""
                rejectTo=""
                saveDisclaimerAddressingToRedux={this.props.setAnnuityInsuranceAddressingAction}
                backTo={urlPaths.recipient}
                pageName=""
                pageBack=""
                pageNumber=""
                metaFirstPage={this.props.firstPage.toString()}
                extraCardWidth
            >
                {this.generateDisclaimerText()}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    isInsurance: boolean;
    accountName: string;
    disposition?: Disposition;
    setAnnuityInsuranceAddressingAction: (savedDislciamer: DisclaimerAddressing) => void;
    purchaserState: string;
    accountState: string;
    efuneralMediaUrl: string;
    firstPage: boolean;
}

interface StateShape {
    // Shape of local state
}

export default ProductDisclaimerPageComponent;
