// Action Type
export const SET_IS_BEDRIDDEN_OR_AILED = 'SET_IS_BEDRIDDEN_OR_AILED';
// Action
export function setIsBedriddenOrAiledAction(newValue: boolean) {
    return { type: SET_IS_BEDRIDDEN_OR_AILED, newValue };
}
// Reducer
export function setIsBedriddenOrAiledReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_IS_BEDRIDDEN_OR_AILED:
            return action.newValue;
        default:
            return state;
    }
}
