import { connect } from 'react-redux';
import RepresentativeCompletePageComponent from './RepresentativeCompletePageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const firstName = getFormData(state, 'purchaser', 'firstName');
    const lastName = getFormData(state, 'purchaser', 'lastName');

    return {
        opportunityId: state.opportunity.id,
        purchaserFullName: `${firstName} ${lastName}`,
        purcahserEmail: getFormData(state, 'purchaser', 'email')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeCompletePageComponent);
