import { connect } from 'react-redux';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setVerifyPurchaserIdentityAction } from 'redux/disclaimerData/disclaimerAddressings/verifyPurchaserIdentity';
import VerifyPurchaserIdentityPageComponent from './VerifyPurchaserIdentityPageComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        chooseAlternativeID:
            state.disclaimerData.disclaimerAddressings.verifyPurchaserIdentity.hasBeenAcceptedOrPositivelyAddressed
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setVerifyPurchaserIdentityAction: newValue => dispatch(setVerifyPurchaserIdentityAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPurchaserIdentityPageComponent);
