import { connect } from 'react-redux';
import PriceListDisclaimerPageComponent from './PriceListDisclaimerPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setPriceListDisclaimerAddressingAction } from '../../../../redux/disclaimerData/disclaimerAddressings/priceList';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';

// This is done becasue we have this page in two different routes, and there are slight variations
const generateMapStateToProps = (acceptTo: string) => {
    return (state: GlobalStateShape) => {
        // These values will be passed in as props
        return {
            acceptTo,
            subdomain: state.account.subdomain,
            accountState: state.account.state,
            firstPage: firstPageCalculator(state, urlPaths.middlePriceListDisclaimer)
        };
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPriceListDisclaimerAddressingAction: newValue => dispatch(setPriceListDisclaimerAddressingAction(newValue))
    };
};

export default function generatePriceListDisclaimer(acceptTo: string) {
    return connect(generateMapStateToProps(acceptTo), mapDispatchToProps)(PriceListDisclaimerPageComponent);
}
