import React, { CSSProperties } from 'react';
import { Redirect } from 'react-router-dom';
import './ButtonSubtitledLinkComponent.css';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';

class ButtonSubtitledLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false,
            isHovered: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        // Default prop values
        navigationSideEffect: null,
        buttonTitleFontFamily: '',
        stylingOptions: null
    };

    handleClick() {
        if (this.props.navigationSideEffect) {
            this.props.navigationSideEffect();
        }
        this.setState({ redirecting: true });
    }

    render() {
        if (this.state.redirecting) {
            return <Redirect to={this.props.to} push />;
        }
        let buttonStyle: CSSProperties = {
            color: this.props.stylingOptions.colors.primaryColor,
            backgroundColor: this.props.stylingOptions.colors.primaryContrastColor,
            borderColor: this.props.stylingOptions.colors.primaryColor
        };
        if (this.state.isHovered) {
            buttonStyle = {
                ...buttonStyle,
                color: this.props.stylingOptions.colors.primaryContrastColor,
                backgroundColor: this.props.stylingOptions.colors.primaryColor
            };
        }
        return (
            <div
                onMouseEnter={() => this.setState({ isHovered: true })}
                onMouseLeave={() => this.setState({ isHovered: false })}
                onClick={this.handleClick}
                className="subtitled-button"
                id={'button-' + this.props.buttonId}
                style={buttonStyle}
            >
                <div
                    className="subtitled-button-title"
                    style={{ fontFamily: this.props.stylingOptions.fonts.buttonFontFamily }}
                >
                    {this.props.title}
                </div>
                <div className="subtitled-button-subtitle">{this.props.subtitle}</div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    to: string;
    title: string;
    subtitle: string;
    navigationSideEffect: Function;
    buttonId: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
    isHovered: boolean;
}

export default ButtonSubtitledLinkComponent;
