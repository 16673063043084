import ApplicationStyling from '../../../models/ApplicationStyling';
import { TabStyling } from '../../../models/TabStyling';

const everdaysStyling: ApplicationStyling = {
    colors: {
        primaryColor: '#0072cd',
        secondaryColor: '#ffffff',
        secondaryContrastColor: '#000000',
        primaryContrastColor: '#FFFFFF',
        headerBottomBorderColor: '#bdbdbd',
        footerBackgroundColor: '#000000',
        sectionSeperatorColor: '#D5D8DC',
        titleColor: '#000000',
        bodyColor: '#000000',
        headerFontColor: '',
        headerBackgroundColor: '',
        footerFontColor: '',
        generalBackgroundColor: '#FFFFFF',
        formFieldsBackgroundColor: '#FFFFFF',
        formFieldsTextColor: '#000000',
        placeholderFullOpacity: false
    },
    fonts: {
        buttonFontFamily: "'Open Sans', sans-serif",
        titleFontFamily: "'Open Sans', sans-serif",
        titleAlignment: 'left',
        bodyFontFamily: "'Open Sans', sans-serif",
        bodyAlignment: 'left',
        bodyWeight: 'normal',
        titleWeight: 'bold',
        titleFontSize: '2.4rem',
        bodyFontSize: '1.4rem'
    },
    sizing: {
        headerLogoMaxHeight: '80px'
    },
    navigationButtonGroupAlignment: {
        backgroundColor: 'Black'
    },
    showNameInTitle: true,
    useFormLabelsAsPlaceholders: false
};

export default everdaysStyling;

export const evedaysTabConstants: TabStyling = {
    faviconUrl: '',
    tabName: 'Everdays'
};

export const EVERDAYS_STYLING_KEY = 'everdays';
