// Action Type
export const SET_OPPORTUNITY_PURCHASER_ID = 'SET_OPPORTUNITY_PURCHASER_ID';
// Action
export function setOpportunityPurchaserIdAction(newValue: string) {
    return { type: SET_OPPORTUNITY_PURCHASER_ID, newValue };
}
// Reducer
export function setOpportunityPurchaserIdReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_OPPORTUNITY_PURCHASER_ID:
            return action.newValue;
        default:
            return state;
    }
}
