import { combineReducers } from 'redux';
import { setOverridePageReducer } from './overridePage';
import { setVitalStatisticsUrlReducer } from './vitalStatistics';
import { setFirstPageReducer } from './firstPage';

export default combineReducers({
    overridePage: setOverridePageReducer,
    vitalStatistics: setVitalStatisticsUrlReducer,
    firstPage: setFirstPageReducer
});
