import { combineReducers } from 'redux';
import { setStylingPrimaryColorReducer } from './primaryColor';
import { setStylingSecondaryColorReducer } from './secondaryColor';
import { setStylingHeaderBottomBorderColorReducer } from './headerBottomBorderColor';
import { setStylingFooterBackgroundColorReducer } from './footerBackgroundColor';
import { setStylingTitleColorReducer } from './titleColor';
import { setSectionSeperatorColorReducer } from './sectionSeperatorColor';
import { setStylingPrimaryConrastColorReducer } from './primaryConrastColor';
import { setStylingSecondaryConrastColorReducer } from './secondaryConrastColor';
import { setStylingBodyColorReducer } from './bodyColor';
import { setStylingHeaderFontColorReducer } from './headerFontColor';
import { setStylingFooterFontColorReducer } from './footerFontColor';
import { setStylingHeaderBackgroundColorReducer } from './headerBackgroundColor';
import { setStylingGeneralBackgroundColorReducer } from './generalBackgroundColor';
import { setStylingFormFieldsBackgroundColorReducer } from './formFieldsBackgroundColor';
import { setStylingFormFieldsTextColorReducer } from './formFieldsTextColor';
import { setStylingPlaceholderFullOpacityReducer } from './placeholderFullOpacity';

export default combineReducers({
    primaryColor: setStylingPrimaryColorReducer,
    primaryContrastColor: setStylingPrimaryConrastColorReducer,
    secondaryColor: setStylingSecondaryColorReducer,
    secondaryContrastColor: setStylingSecondaryConrastColorReducer,
    headerBottomBorderColor: setStylingHeaderBottomBorderColorReducer,
    headerBackgroundColor: setStylingHeaderBackgroundColorReducer,
    headerFontColor: setStylingHeaderFontColorReducer,
    footerBackgroundColor: setStylingFooterBackgroundColorReducer,
    footerFontColor: setStylingFooterFontColorReducer,
    titleColor: setStylingTitleColorReducer,
    sectionSeperatorColor: setSectionSeperatorColorReducer,
    bodyColor: setStylingBodyColorReducer,
    generalBackgroundColor: setStylingGeneralBackgroundColorReducer,
    formFieldsBackgroundColor: setStylingFormFieldsBackgroundColorReducer,
    formFieldsTextColor: setStylingFormFieldsTextColorReducer,
    placeholderFullOpacity: setStylingPlaceholderFullOpacityReducer
});
