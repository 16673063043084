import GlobalStateShape from '../../redux/GlobalStateShape';
import BlockingFunction from '../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../static/constants/enums/urlPaths';
import { Action } from 'history';
import { savePurchaserData } from './pages/purchaser';
import { saveRecipientData } from './pages/recipient';
import { saveBeneficaryCheckData } from './pages/beneficiaryCheck';
import { saveBeneficaryData } from './pages/beneficiary';
import { submitIdentityQuestions } from './pages/purchaserIdentity';
import { savePaymentData } from './pages/payment';
import { saveAnnuityInsuranceConfirmation } from './pages/disclaimers/annuityInsurance';
import saveRecipientCheckData from './pages/recipientCheck';
import { saveOrderSummaryData } from './pages/orderSummary';
import { saveRecipientConsent } from './pages/recipientConsent';
import { submitRecipientIdentityQuestions } from './pages/recipientIdentity';
import { saveRecipientAbsentData } from './pages/disclaimers/recipientAbsent';
import { saveVitalStatistics } from './pages/vitalStatistics';
import { saveRelationshipToRecipientData } from './pages/relationshipToRecipient';
import { submitPurchaserPhoneVerificationRequest } from './pages/purchaserPhoneVerificationRequest';
import { submitPurchaserPhoneVerify } from './pages/purchaserPhoneVerify';
import { exitStateFilingFee } from './pages/statefilingFee';
import { createOpportunity } from './pages/productSelection';
import { saveAgentData } from './pages/agentStart';
import { savePriceListDisclaimer } from './pages/disclaimers/priceList';
import { savePurchaserOrderSummaryData } from './pages/purchaserOrderSummary';
import { savePurchaserSSNData } from './pages/ssnCapture';
import { saveAlternativePurchaserIdentityDisclaimer } from './pages/disclaimers/alternativePurchaserIdentity';
import { saveTrustFundedPrearrangementDisclaimer } from './pages/disclaimers/trustFundedPrearragement';
import { saveErrorFormData } from './pages/error';
import { irrevocableExitFunctions } from './pages/irrevocable';
import { replacementExitFunctions } from './pages/replacement';
import { secureLinkSigning } from './pages/secureLinkSigning';

// This function recieves the state, and can find out what we need to load
// If there are things that need to happen before we can show a url's page
// Return those things as executable asyncrenous functions here
// The promises should end by returning a boolean indicating success
export default function generatePageExitFunctions(
    state: GlobalStateShape,
    dispatch: Function,
    urlPath: string,
    params: any,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    switch (urlPath) {
        case urlPaths.disclaimerProduct:
            return saveAnnuityInsuranceConfirmation(state);
        case urlPaths.purchaser:
            return savePurchaserData(state, dispatch);
        case urlPaths.recipientCheck:
            return saveRecipientCheckData(state, dispatch);
        case urlPaths.relationshipToRecipient:
            return saveRelationshipToRecipientData(state);
        case urlPaths.recipient:
            return saveRecipientData(state, dispatch);
        case urlPaths.beneficiaryCheck:
            return saveBeneficaryCheckData(state);
        case urlPaths.beneficiary:
            return saveBeneficaryData(state);
        case urlPaths.purchaserIdentity:
            return submitIdentityQuestions(state, dispatch);
        case urlPaths.purchaserPhoneVerificationRequest:
            return submitPurchaserPhoneVerificationRequest(state, dispatch, lastNavigationWasInternal);
        case urlPaths.purchaserPhoneVerify:
            return submitPurchaserPhoneVerify(state, dispatch);
        case urlPaths.payment:
            return savePaymentData(state, dispatch, lastNavigationWasInternal);
        case urlPaths.orderSummary:
            return saveOrderSummaryData(state, dispatch);
        case urlPaths.irrevocable:
            return irrevocableExitFunctions(state, dispatch);
        case urlPaths.replacement:
            return replacementExitFunctions(state, dispatch);
        case urlPaths.recipientIdentity:
            return submitRecipientIdentityQuestions(state, dispatch);
        case urlPaths.prefilledRecipientIdentity:
            return submitRecipientIdentityQuestions(state, dispatch);
        case urlPaths.recipientConsent:
            return saveRecipientConsent(state);
        case urlPaths.prefilledRecipientConsent:
            return saveRecipientConsent(state);
        case urlPaths.recipientAbsent:
            return saveRecipientAbsentData(state, dispatch);
        case urlPaths.vitalStatistics:
            return saveVitalStatistics(state, dispatch);
        case urlPaths.recipientIdentityFailed:
            return saveRecipientAbsentData(state, dispatch);
        case urlPaths.prefilledRecipientIdentityFailed:
            return saveRecipientAbsentData(state, dispatch);
        case urlPaths.stateFilingFee:
            return exitStateFilingFee(state, dispatch, lastNavigationWasInternal);
        case urlPaths.productSelection:
            return createOpportunity(state, dispatch);
        case urlPaths.agentStart:
            return saveAgentData(state, dispatch);
        case urlPaths.earlyPriceListDisclaimer:
            return savePriceListDisclaimer(state);
        case urlPaths.middlePriceListDisclaimer:
            return savePriceListDisclaimer(state);
        case urlPaths.latePriceListDisclaimer:
            return savePriceListDisclaimer(state);
        case urlPaths.purchaserOrderSummary:
            return savePurchaserOrderSummaryData(state);
        case urlPaths.ssnCapture:
            return savePurchaserSSNData(state);
        case urlPaths.verifyPurchaserIdentity:
            return saveAlternativePurchaserIdentityDisclaimer(state);
        case urlPaths.trustFundedPrearrangement:
            return saveTrustFundedPrearrangementDisclaimer(state);
        case urlPaths.purchaseProcessing:
            return state.account.stylingKey === 'tulipcremation' ? [] : saveVitalStatistics(state, dispatch);
        case urlPaths.secureLinkSigning:
            return secureLinkSigning();
        case urlPaths.error:
            return saveErrorFormData(state, dispatch);
        //checks if the stying key is tulip - if it is, returns the default as below, else saveVitalStatistics
        //(otherwise pressing back button a couple of times would take tulip user to savedVitalStats page and send a Death Cert Info email)
        default:
            return [];
    }
}
