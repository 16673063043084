import Product from '../../static/models/Product';

// Action Type
export const SET_FUNERAL_HOME_PRODUCTS = 'SET_FUNERAL_HOME_PRODUCTS';
// Action
export function setFuneralHomeProductsAction(newValue: Product[]) {
    return { type: SET_FUNERAL_HOME_PRODUCTS, newValue };
}
// Reducer
export function setFuneralHomeProductsReducer(state: any, action: any): Product[] {
    if (typeof state === 'undefined') {
        // Initial data
        return [];
    }
    switch (action.type) {
        case SET_FUNERAL_HOME_PRODUCTS:
            return action.newValue;
        default:
            return state;
    }
}
