import React from 'react';
import { Redirect } from 'react-router';
import urlPaths from '../../../static/constants/enums/urlPaths';

class ForceNavigationPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return <Redirect to={urlPaths.loadOpportunity} />;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default ForceNavigationPageComponent;
