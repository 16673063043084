import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

const recipientConsentReroutes = (state: GlobalStateShape): string => {
    if (
        state.opportunity?.recipient?.identityVerificationPlaid &&
        state.opportunity?.recipient?.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
    ) {
        return urlPaths.recipientIdentityFailed;
    }
};

export default recipientConsentReroutes;
