import React, { CSSProperties } from 'react';
import Modal from 'react-modal';
import { ThemeProvider } from '@material-ui/styles';

const customeStylesContent: CSSProperties = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    maxWidth: '90vw',
    minWidth: '300px',
    overflow: 'auto',
    padding: '0px'
};
const customStyles = {
    content: customeStylesContent,
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
};

class GeneralModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        let successfulRootFind = false;
        super(props);
        try {
            Modal.setAppElement('#styled-application-root');
            successfulRootFind = true;
        } catch (e) {
            try {
                Modal.setAppElement('div');
                successfulRootFind = true;
            } catch {}
        }
        this.state = {
            // Starting state values
            modalIsOpen: false,
            successfulRootFind
        };
    }

    componentDidMount() {
        this.setState({
            modalIsOpen: true
        });
    }

    static defaultProps = {
        // Default prop values
        applicationMuiTheme: {},
        applicationTheme: {}
    };

    onRequestClose() {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    render() {
        return (
            <ThemeProvider theme={this.props.applicationMuiTheme}>
                <Modal
                    style={customStyles}
                    isOpen={this.state.modalIsOpen}
                    ariaHideApp={this.state.successfulRootFind}
                    onRequestClose={() => this.onRequestClose()}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                            ...this.props.applicationTheme
                        }}
                    >
                        {this.props.children}
                    </div>
                </Modal>
            </ThemeProvider>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    applicationMuiTheme: any;
    applicationTheme: any;
    onRequestClose: Function;
}

interface StateShape {
    // Shape of local state
    modalIsOpen: boolean;
    successfulRootFind: boolean;
}

export default GeneralModalComponent;
