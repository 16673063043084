import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { sendFailingRequest } from 'toolboxes/server-application-buffer/outgoing-api-triggering-data';
import GlobalStateShape from 'redux/GlobalStateShape';

export default function sendTestFailingRequest(state: GlobalStateShape): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendFailingRequest(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve;
                    });
                }),
            blocksPageStarts: []
        }
    ];
}
