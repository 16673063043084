export enum OpportunityStage {
    Visitor = 'Visitor',
    DemographicInformation = 'DemographicInformation',
    PaymentInfo = 'PaymentInfo',
    PurchaserIdentityVerification = 'PurchaserIdentityVerification',
    PurchaserSignContract = 'PurchaserSignContract',
    AgentSignContract = 'AgentSignContract',
    UnknownShopper = 'UnknownShopper',
    KnownShopper = 'KnownShopper',
    PurchasingIntent = 'PurchasingIntent',
    ClosedWon = 'ClosedWon',
    ClosedLost = 'ClosedLost'
}

export const orderedNewStages = [
    OpportunityStage.Visitor,
    OpportunityStage.UnknownShopper,
    OpportunityStage.KnownShopper,
    OpportunityStage.PurchasingIntent,
    OpportunityStage.ClosedWon,
    OpportunityStage.ClosedLost
];
