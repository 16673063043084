import './SectionDividerComponent.css';
import React from 'react';

class SectionDividerComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        isSubsection: false
    };

    getHorizontalRule() {
        if (this.props.isSubsection === true) {
            return <div className="subsection-divider-hr" />;
        }
        return <div className="section-divider-hr" />;
    }

    render() {
        return <div className="subsection-divider-container">{this.getHorizontalRule()}</div>;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    isSubsection: boolean;
}

interface StateShape {
    // Shape of local state
}

export default SectionDividerComponent;
