import React from 'react';
import 'react-tabs/style/react-tabs.css';
import './EarthPaymentPageComponent.css';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import { PaymentMethod } from 'static/constants/enums/paymentMethods';
import BackButtonLinkWrapper from 'components/global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import HealthImpactModalWrapper from 'components/modal-components/disclaimer-components/specific-disclaimers/health-impact-modal-component/HealthImpactModalWrapper';
import PaymentOption from 'static/models/PaymentOption';
import HealthQuestionInstructionsModalWrapper from 'components/modal-components/health-question-components/health-question-instructions-modal-component/HealthQuestionInstructionsModalWrapper';
import PastHealthQuestionModalWrapper from 'components/modal-components/health-question-components/past-health-question-modal-component/PastHealthQuestionModalWrapper';
import CurrentHealthQuestionModalWrapper from 'components/modal-components/health-question-components/current-health-question-modal-component/CurrentHealthQuestionModalWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import CreditCardPaymentContentWrapper from 'components/page-components/payment-page-component/page-specific-components/payment-content-components/credit-card-payment-content-component/CreditCardPaymentContentWrapper';

class EarthPaymentPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            processingPayment: false,
            errorMessage: props.startingError,
            healthInstructionsViewed: false,
            initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer:
                props.healthQuestionsNecessary || props.incapacitatedDisclaimerNecessary,
            useCC: this.props.paymentMethodsAvailable.includes(PaymentMethod.CreditCard)
        };
    }

    static defaultProps = {
        // Default prop values
        pricingData: '',
        importanceTheme: {},
        startingError: '',
        paymentMethodsAvailable: [],
        stripeId: '',
        incapacitatedDisclaimerNecessary: false,
        setSelectedPaymentOptionAction: () => {},
        healthQuestionsNecessary: false,
        wasBedriddenOrAiled: false,
        isBedriddenOrAiled: false,
        isAgent: false,
        nextUrl: undefined,
        singlePayAlternative: undefined,
        setPurchaserWillEnterAction: undefined
    };

    render() {
        if (
            this.state.initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer &&
            this.props.healthQuestionsNecessary
        ) {
            if (!this.state.healthInstructionsViewed) {
                return (
                    <HealthQuestionInstructionsModalWrapper
                        onContinue={() =>
                            this.setState({
                                healthInstructionsViewed: true
                            })
                        }
                    />
                );
            }
            if (![true, false].includes(this.props.wasBedriddenOrAiled)) {
                return <PastHealthQuestionModalWrapper />;
            }
            if (![true, false].includes(this.props.isBedriddenOrAiled)) {
                return <CurrentHealthQuestionModalWrapper />;
            }
        }

        if (
            this.state.initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer &&
            this.props.incapacitatedDisclaimerNecessary
        ) {
            return (
                <HealthImpactModalWrapper
                    onAccept={() => {}}
                    onReject={() => {
                        this.props.setSelectedPaymentOptionAction(this.props.singlePayAlternative);
                    }}
                    explainRejectionInCancel={true}
                />
            );
        }

        return (
            <FullContentCenterWrapper metaPageName="Payment" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Payment Details</PageCardTitleWrapper>
                    {this.props.isAgent && (
                        <ButtonLinkWrapper
                            to={this.props.nextUrl}
                            buttonId="skip-payment"
                            color="secondary"
                            navigationSideAffect={() => this.props.setPurchaserWillEnterAction(true)}
                        >
                            Purchaser Will Enter Payment Information
                        </ButtonLinkWrapper>
                    )}
                    {this.state.errorMessage && <div className="errorMessage">{this.state.errorMessage}</div>}
                    <CreditCardPaymentContentWrapper redirectUrl={this.props.nextUrl} />
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)\
    startingError: string;
    paymentMethodsAvailable: PaymentMethod[];
    stripeId: string;
    incapacitatedDisclaimerNecessary: boolean;
    singlePayAlternative: PaymentOption;
    setSelectedPaymentOptionAction: Function;
    healthQuestionsNecessary: boolean;
    wasBedriddenOrAiled: boolean;
    isBedriddenOrAiled: boolean;
    isAgent: boolean;
    nextUrl: urlPaths;
    setPurchaserWillEnterAction: Function;
}

interface StateShape {
    // Shape of local state
    processingPayment: boolean;
    errorMessage: string;
    healthInstructionsViewed: boolean;
    initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer: boolean;
    useCC: boolean;
}

export default EarthPaymentPageComponent;
