// Action Type
export const SET_STYLING_SPACING_SM = 'SET_STYLING_SPACING_SM';
// Action
export function setSpacingSMAction(newValue: string) {
    return { type: SET_STYLING_SPACING_SM, newValue };
}
// Reducer
export function setSpacingSMReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STYLING_SPACING_SM:
            return action.newValue;
        default:
            return state;
    }
}
