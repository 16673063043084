import { connect } from 'react-redux';
import LoadOpportunityPageComponent from './LoadOpportunityPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import calculatePageToJumpTo from './jump-to-page-calculator';
import { setFirstPageAction } from 'redux/urlPaths/firstPage';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props

    console.warn(calculatePageToJumpTo(state));

    return {
        pageToNavigateTo: calculatePageToJumpTo(state),
        firstPage: state.urlPaths.firstPage
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setFirstPageAction: newValue => dispatch(setFirstPageAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadOpportunityPageComponent);
