import React from 'react';
import GeneralModalWrapper from '../general-modal-component/GeneralModalWrapper';
import { Button } from '@material-ui/core';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class ErrorModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <GeneralModalWrapper onRequestClose={this.props.onRequestClose}>
                <PageCardWrapper>
                    <PageCardTextWrapper>
                        An error occurred. We've been notified of it and are looking into it. Thank you.
                    </PageCardTextWrapper>
                    <Button onClick={() => this.props.onRequestClose()} variant="contained" color="secondary">
                        Close
                    </Button>
                </PageCardWrapper>
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    onRequestClose: Function;
}

interface StateShape {
    // Shape of local state
}

export default ErrorModalComponent;
