import React, { CSSProperties } from 'react';
import Button from '@material-ui/core/Button';
import './EarthYesNoComponent.css';
import ApplicationStyling from 'static/models/ApplicationStyling';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

class EarthYesNoComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            isYesHovered: false,
            isNoHovered: false
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined
    };

    render() {
        let buttonStyle: CSSProperties = {
            color: this.props.stylingOptions.colors.formFieldsTextColor,
            backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
            fontSize: this.props.stylingOptions.fonts.bodyFontSize,
            fontFamily: this.props.stylingOptions.fonts.bodyFontFamily,
            justifyContent: 'space-between'
        };
        return (
            <div className="yes-no-buttons">
                <div className="yes-no-button">
                    <Button
                        variant="contained"
                        onMouseEnter={() => this.setState({ isYesHovered: true })}
                        onMouseLeave={() => this.setState({ isYesHovered: false })}
                        disableElevation={!this.state.isYesHovered}
                        style={buttonStyle}
                        fullWidth={true}
                        id={`${this.props.yesNoId}-yes`}
                        title={`${this.props.yesNoTitle} Yes`}
                        onClick={() => this.props.onYesClick()}
                        endIcon={<TrendingFlatIcon />}
                    >
                        Yes
                    </Button>
                </div>
                <div className="yes-no-button">
                    <Button
                        variant="contained"
                        onMouseEnter={() => this.setState({ isNoHovered: true })}
                        onMouseLeave={() => this.setState({ isNoHovered: false })}
                        disableElevation={!this.state.isNoHovered}
                        style={buttonStyle}
                        fullWidth={true}
                        id={`${this.props.yesNoId}-no`}
                        title={`${this.props.yesNoTitle} No`}
                        onClick={() => this.props.onNoClick()}
                        endIcon={<TrendingFlatIcon />}
                    >
                        No
                    </Button>
                </div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    yesNoId: string;
    yesNoTitle?: string;
    answer?: boolean;
    onYesClick: Function;
    onNoClick: Function;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    isYesHovered: boolean;
    isNoHovered: boolean;
}

export default EarthYesNoComponent;
