import { connect } from 'react-redux';
import PurchaserSignedAlertComponent from './PurchaserSignedAlertComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    // These values will be passed in as props
    return { allContractSigningCredentials: state.opportunity.contractSigning.allContractSigningCredentials };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserSignedAlertComponent);
