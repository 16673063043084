import { connect } from 'react-redux';
import RecipientAvailabilityCheckPageComponent from './RecipientAvailabilityCheckPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setInsuredAvailabilityAddressedAction } from '../../../redux/representative/insuredAvailabilityAddressed';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        trustText: state.opportunity.preneedContractType === PreneedContractType.Trust ? 'recipient' : 'insured'
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setInsuredAvailabilityAddressedAction: newValue => dispatch(setInsuredAvailabilityAddressedAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientAvailabilityCheckPageComponent);
