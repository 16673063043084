import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { normalizeBooleanFromString } from '../../../toolboxes/redux-form/redux-form-normalizers';
import StateSelectionWrapper from '../base-form-components/state-selection-component/StateSelectionWrapper';
import { validateRecipientVital } from '../../../toolboxes/redux-form/redux-form-validation';

class RecipientVitalFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        recipientVitalData: {},
        recipientFirstName: '',
        recipientLastName: ''
    };

    render() {
        return (
            <FormWrapper formName="recipientVital" validate={validateRecipientVital}>
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="firstName"
                    labelString="First Name"
                    placeholder={this.props.recipientFirstName}
                    disabled={true}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="lastName"
                    labelString="Last Name"
                    placeholder={this.props.recipientLastName}
                    disabled={true}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="salutation"
                    labelString="Salutation"
                    spacing={{
                        xs: 12,
                        sm: 4
                    }}
                />

                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="middleName"
                    labelString="Middle Name"
                    spacing={{
                        xs: 12,
                        sm: 8
                    }}
                />
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="race"
                    labelString="Race / Ethnicity"
                    spacing={{
                        xs: 12,
                        sm: 8
                    }}
                />
                <SelectWrapper
                    formName="recipientVital"
                    fieldPath="usaCitizen"
                    labelString="USA Citizen"
                    valueDisplays={[
                        {
                            value: true,
                            display: 'Yes'
                        },
                        {
                            value: false,
                            display: 'No'
                        }
                    ]}
                    normalize={normalizeBooleanFromString}
                    spacing={{
                        xs: 12,
                        sm: 4
                    }}
                />
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="birthCity"
                    labelString="City of Birth"
                    spacing={{
                        xs: 12,
                        sm: 8
                    }}
                />
                <StateSelectionWrapper
                    formName="recipientVital"
                    fieldPath="birthState"
                    labelString="State of Birth"
                    spacing={{
                        xs: 12,
                        sm: 4
                    }}
                />
                <TextBoxWrapper
                    formName="recipientVital"
                    fieldPath="highestEducation"
                    labelString="Highest Education"
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    recipientVitalData: any;
    recipientFirstName: string;
    recipientLastName: string;
}

interface StateShape {
    // Shape of local state
}

export default RecipientVitalFormComponent;
