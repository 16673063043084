import React from 'react';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import LoadingScreenWrapper from 'components/global-components/loading-screen-component/LoadingScreenWrapper';

class SendingTestFailingRequestComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="sendingTestFailingRequest"
                metaPageNumber=""
                metaPagebackAvailable="false"
            >
                <LoadingScreenWrapper />
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default SendingTestFailingRequestComponent;
