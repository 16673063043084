import React from 'react';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';

class ReplacementSorryPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="ReplacementSorry" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Please Contact Us</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Unfortunately, we are unable to process your purchase at this time. Our online planning system
                        is not able to handle contract replacement at this time. We are always working to expand our
                        services and hope we will be able to accomodate you soon. To continue this purchase, please
                        contact us directly at (515) 305-2600.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default ReplacementSorryPageComponent;
