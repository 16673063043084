import GlobalStateShape from '../../redux/GlobalStateShape';
import loadOpportunityReroutes from './pages/loadOpportunity';
import recipientCheckReroutes from './pages/recipientCheck';
import { funeralContractsReroutes } from './pages/funeralContracts';
import urlPaths from '../../static/constants/enums/urlPaths';
import { travelContractsReroutes } from './pages/travelContracts';
import recipientConsentReroutes from './pages/recipientConsent';
import { purchaserIdentityReroutes } from './pages/purchaserIdentity';
import { getPhaseRedirect } from '../../static/constants/flowPhases';
import { beneficiaryCheckReroutes } from './pages/beneficiaryCheck';
import recipientIdentityReroutes from './pages/recipientIdentity';
import prefilledRecipientConsentReroutes from './pages/prefilledRecipientConsent';
import { numberOfPurchaserPhoneVerificationTriesAllowed } from '../../static/constants/identity-verification-allowances';
import { Action } from 'history';
import disclaimerProductReroutes from './pages/disclaimerProduct';
import { orderSummaryReroutes } from './pages/orderSummary';
import { productSelectionReroutes } from './pages/productSelection';
import { checkForAccountBaseUrlReroute } from './company-specific-base-urls';
import { demographicsInstructionsReroutes } from './pages/demographicsInstructions';
import { purchaserOrderSummaryReroutes } from './pages/purchaserOrderSummary';
import { paymentReroutes } from './pages/payment';
import { purchaserStartReroutes } from './pages/purchaserStart';
import ssnCaptureReroutes from './pages/ssnCapture';
import representativeIdentityReroutes from './pages/representativeIdentity';
import { APIEndofExperienceReroutes } from './pages/purchaseProcessing';
import relationshipToReceipientReroutes from './pages/relationshipToRecipient';
import { secureLinkSigningReroutes } from './pages/secureLinkSigning/secureLinkSigning';
import { secureLinkSigningCompleteReroutes } from './pages/secureLinkSigningComplete/secureLinkSigningComplete';
import { securityCheckReroutes } from './pages/securityCheck';
import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';

const pagesThatDontRequireAnOpportunity: string[] = [
    urlPaths.loadOpportunity,
    urlPaths.error,
    urlPaths.securityCheck,
    urlPaths.forceNavigation,
    urlPaths.docusignReroute,
    urlPaths.agentStart,
    urlPaths.productSelection,
    urlPaths.purchaserStart,
    urlPaths.loadFromGuided,
    urlPaths.secureLinkSigning,
    urlPaths.secureLinkSigningComplete,
    urlPaths.expired,
    urlPaths.startPlanning,
    urlPaths.opportunityStatus,
    urlPaths.invalidContractSigningCredentials,
    urlPaths.purchaserSigningComplete,
    urlPaths.purchaseProcessing
];

// This function receives the state, and can find out where the user should be directed to
// If the user should be redirected to a different url path, return it here
export default function generatePageReroute(
    state: GlobalStateShape,
    urlPath: string,
    previousUrl: string,
    params: any,
    lastNavigationWasInternal: boolean
): string {
    // Never redirect from an error
    if (urlPath === urlPaths.error) {
        return '';
    }

    checkForAccountBaseUrlReroute(state, urlPath, params);

    // Redirect to load an opportunity if none is available
    if (
        !state.opportunity.id &&
        !pagesThatDontRequireAnOpportunity.some(page => {
            return urlPath.startsWith(page) || urlPath?.toLowerCase().startsWith(urlPaths.startPlanning.toLowerCase());
        })
    ) {
        console.info('Load is redirecting from ' + urlPath);
        return urlPaths.loadOpportunity;
    }

    if (
        (state.opportunity.stopPageDetail === 'Stopped because they failed identity too many times' ||
            state.opportunity.stopPageDetail ===
                'Stopped because purchaser decided to provide alternative form of identification' ||
            state.opportunity.stopPageDetail === 'Stopped because the identity is not valid' ||
            state.opportunity.stopPageDetail === 'Stopped because the identity is not valid - special reasons') &&
        urlPath !== urlPaths.unavailableIdentityFailed &&
        urlPath !== urlPaths.opportunityStatus
    ) {
        return urlPaths.unavailableIdentityFailed;
    }

    if (state.featureFlags.purchaserPhoneVerificationReact) {
        if (
            state.opportunity.purchaser.phoneVerificationRequested &&
            state.opportunity.purchaser.timesPhoneVerificationSubmitted >=
                numberOfPurchaserPhoneVerificationTriesAllowed &&
            state.opportunity.purchaser.phoneVerified !== true &&
            urlPath !== urlPaths.unavailableIdentityFailed
        ) {
            return urlPaths.unavailableIdentityFailed;
        }
    } else {
        if (
            state.opportunity.purchaser.identityVerificationPlaid === PlaidIdentityStatus.FAILED &&
            urlPath !== urlPaths.unavailableIdentityFailed
        ) {
            return urlPaths.unavailableIdentityFailed;
        }
    }
    // Normal redirections
    const standardRedirect = getStandardRedirect(state, urlPath, params);
    if (standardRedirect) {
        return standardRedirect;
    }
    // Data safety redirects
    const phaseRedirect = getPhaseRedirect(state, urlPath);
    if (phaseRedirect) {
        return phaseRedirect;
    }
}

const getStandardRedirect = (state: GlobalStateShape, urlPath: string, params: any) => {
    switch (urlPath) {
        case '':
            return urlPaths.loadOpportunity;
        case urlPaths.loadOpportunity:
            return loadOpportunityReroutes(state, params);
        case urlPaths.recipientCheck:
            return recipientCheckReroutes(state);
        case urlPaths.travelContracts:
            return travelContractsReroutes(state);
        case urlPaths.funeralContracts:
            return funeralContractsReroutes(state);
        case urlPaths.recipientConsent:
            return recipientConsentReroutes(state);
        case urlPaths.purchaserIdentity:
            return purchaserIdentityReroutes(state);
        case urlPaths.beneficiaryCheck:
            return beneficiaryCheckReroutes(state);
        case urlPaths.relationshipToRecipient:
            return relationshipToReceipientReroutes(state);
        case urlPaths.recipientIdentity:
            return recipientIdentityReroutes(state);
        case urlPaths.prefilledRecipientConsent:
            return prefilledRecipientConsentReroutes(state);
        case urlPaths.disclaimerProduct:
            return disclaimerProductReroutes(state);
        case urlPaths.orderSummary:
            return orderSummaryReroutes(state);
        case urlPaths.productSelection:
            return productSelectionReroutes(state);
        case urlPaths.demographicsInstructions:
            return demographicsInstructionsReroutes(state);
        case urlPaths.purchaserOrderSummary:
            return purchaserOrderSummaryReroutes(state);
        case urlPaths.payment:
            return paymentReroutes(state);
        case urlPaths.purchaserStart:
            return purchaserStartReroutes(state);
        case urlPaths.representativeIdentity:
            return representativeIdentityReroutes(state);
        case urlPaths.ssnCapture:
            return ssnCaptureReroutes(state);
        case urlPaths.purchaseProcessing:
            return APIEndofExperienceReroutes(state);
        case urlPaths.secureLinkSigning:
            return secureLinkSigningReroutes(state);
        case urlPaths.secureLinkSigningComplete:
            return secureLinkSigningCompleteReroutes(state);
        case urlPaths.securityCheck:
            return securityCheckReroutes(state);
        default:
            return null;
    }
};
