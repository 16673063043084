import { Grid } from '@material-ui/core';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import React from 'react';
import ApplicationStyling from 'static/models/ApplicationStyling';

class EarthTotalsGridComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        applicationStyling: null
    };

    render() {
        return (
            <div
                style={{
                    marginBottom: this.props.applicationStyling.spacing?.md,
                    fontFamily: this.props.applicationStyling.fonts.bodyFontFamily,
                    fontSize: this.props.applicationStyling.fonts.bodyFontSize,
                    fontWeight: this.props.applicationStyling.fonts.bodyWeight,
                    color: this.props.applicationStyling.colors.bodyColor
                }}
            >
                <div
                    style={{
                        fontWeight: 700,
                        marginBottom: this.props.applicationStyling.spacing?.innerBody
                    }}
                >
                    {this.props.title}
                </div>
                {this.props.keyValueDisplayPairs.map(keyValueDisplayPair => {
                    const keyEmpty = !keyValueDisplayPair.key || !/\S/.test(keyValueDisplayPair.key);
                    const valueEmpty = !keyValueDisplayPair.value || !/\S/.test(keyValueDisplayPair.value);
                    if (keyEmpty || valueEmpty) {
                        return null;
                    }
                    return (
                        <div
                            key={keyValueDisplayPair.key}
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                border: `1px solid ${this.props.applicationStyling.colors.footerBackgroundColor}`
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={7}
                                    style={{
                                        padding: '.8rem'
                                    }}
                                >
                                    {keyValueDisplayPair.key}
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    style={{
                                        borderLeft: `1px solid ${this.props.applicationStyling.colors.footerBackgroundColor}`,
                                        padding: '.8rem'
                                    }}
                                >
                                    {keyValueDisplayPair.value}
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    title: string;
    keyValueDisplayPairs: {
        key: string;
        value: string;
    }[];
    applicationStyling: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default EarthTotalsGridComponent;
