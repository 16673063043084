import React from 'react';
import GeneralModalWrapper from '../../../general-modal-component/GeneralModalWrapper';
import PageCardWrapper from '../../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import Button from '@material-ui/core/Button/Button';

class IrrevocableHelpModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        policyContractLanguage: ''
    };

    render() {
        return (
            <GeneralModalWrapper onRequestClose={() => this.props.onClose()}>
                <PageCardWrapper>
                    <PageCardTitleWrapper>What does it mean to make my arrangement irrevocable?</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        By making your {this.props.policyContractLanguage} irrevocable, you are waiving your rights to
                        cancel or receive a refund of the monies paid. A common reason for doing this is to qualify for
                        Medicaid or other public assistance programs.
                    </PageCardTextWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: '20px' }}
                        onClick={() => {
                            this.props.onClose();
                        }}
                        id="close"
                    >
                        Close
                    </Button>
                </PageCardWrapper>
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    onClose: Function;
    policyContractLanguage: string;
}

interface StateShape {
    // Shape of local state
}

export default IrrevocableHelpModalComponent;
