import GlobalStateShape from '../../redux/GlobalStateShape';
import { getFormData } from '../redux-form/redux-form-data-access';
import {
    runSubmitPhoneVerificationRequest,
    runSubmitPhoneVerify,
    runSubmitPhoneVerificationSecurityRequest,
    runSubmitPhoneSecurityVerify
} from '../web-requests/specific-requests/phone-verification-requests';

export function sendSubmitPhoneVerificationRequest(
    state: GlobalStateShape,
    callback: (result: any, error: any) => void
) {
    const verificationRequestData = {
        phoneNumber: getFormData(state, 'purchaser', 'phone'),
        method: getFormData(state, 'purchaserPhoneVerification', 'method')
    };

    runSubmitPhoneVerificationRequest(state.opportunity.purchaser.id, verificationRequestData, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}

export function sendSubmitPhoneVerify(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const phoneVerifyData = {
        phoneNumber: getFormData(state, 'purchaser', 'phone'),
        code: getFormData(state, 'purchaserPhoneVerification', 'code')
    };

    runSubmitPhoneVerify(state.opportunity.purchaser.id, phoneVerifyData, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}

export function sendSubmitPhoneVerificationSecurityRequest(
    methodType: string,
    callback: (result: any, error: any) => void
) {
    const verificationRequestData = {
        phoneNumber: '',
        method: methodType
    };

    runSubmitPhoneVerificationSecurityRequest(
        window.localStorage.getItem('opportunityId'),
        verificationRequestData,
        (result, error) => {
            if (error) {
                return callback(undefined, error);
            }
            return callback(result, error);
        }
    );
}

export function sendSubmitPhoneSecurityVerify(state: GlobalStateShape, callback: (result: any, error: any) => void) {
    const phoneVerifyData = {
        phoneNumber: '',
        code: getFormData(state, 'purchaserPhoneVerification', 'code')
    };

    runSubmitPhoneSecurityVerify(window.localStorage.getItem('opportunityId'), phoneVerifyData, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, error);
    });
}
