import React, { ReactNode } from 'react';
import PageCardWrapper from '../../page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../page-card-components/page-card-title-component/PageCardTitleWrapper';
import Button from '@material-ui/core/Button';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import PageCardTextWrapper from '../../page-card-components/page-card-text-component/PageCardTextWrapper';
import './GeneralDisclaimerComponent.css';
import ApplicationStyling from 'static/models/ApplicationStyling';

class GeneralDisclaimerComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        extraContentLength: false,
        extraCardWidth: false,
        storeOnReject: true,
        stylingOptions: undefined
    };

    render() {
        return (
            <PageCardWrapper extraCardLength={this.props.extraContentLength} extraCardWidth={this.props.extraCardWidth}>
                {this.props.disclaimerTitle && (
                    <PageCardTitleWrapper>{this.props.disclaimerTitle}</PageCardTitleWrapper>
                )}
                <PageCardTextWrapper>{this.props.children}</PageCardTextWrapper>
                {this.props.acceptanceText && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            marginBottom: this.props.stylingOptions.spacing?.aboveContinueButton ? '' : '20px',
                            marginTop: this.props.stylingOptions.spacing?.aboveContinueButton
                                ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                                : '',
                            fontSize: this.props.stylingOptions.fonts.buttonFontSize
                        }}
                        onClick={() => {
                            this.props.onAccept();
                            this.props.saveDisclaimerAddressingToRedux({
                                disclaimerText: calculateChildrenTextRecursive(this.props.children),
                                hasBeenAcceptedOrPositivelyAddressed: true,
                                responseToDisclaimer: this.props.acceptanceText,
                                addressedByContactId: this.props.addressingContactId
                            });
                        }}
                        id="accept"
                    >
                        {this.props.acceptanceText}
                    </Button>
                )}
                {this.props.rejectionText && (
                    <Button
                        style={{
                            marginBottom: this.props.stylingOptions.spacing?.aboveContinueButton ? '' : '20px',
                            marginTop: this.props.stylingOptions.spacing?.aboveContinueButton
                                ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                                : '',
                            fontSize: this.props.stylingOptions.fonts.buttonFontSize
                        }}
                        variant="contained"
                        id="reject"
                        color="secondary"
                        onClick={() => {
                            this.props.onReject();
                            if (this.props.storeOnReject) {
                                this.props.saveDisclaimerAddressingToRedux({
                                    disclaimerText: calculateChildrenTextRecursive(this.props.children),
                                    hasBeenAcceptedOrPositivelyAddressed: false,
                                    responseToDisclaimer: this.props.rejectionText,
                                    addressedByContactId: this.props.addressingContactId
                                });
                            }
                        }}
                    >
                        {this.props.rejectionText}
                    </Button>
                )}
            </PageCardWrapper>
        );
    }
}

export const calculateChildrenTextRecursive = (reactNode: ReactNode): string => {
    let fullText = '';
    if (reactNode) {
        React.Children.forEach(reactNode, child => {
            if (!child) return;
            if (typeof child === 'string') {
                return (fullText += child.endsWith('.') ? `${child} ` : child);
            }
            if (typeof child !== 'boolean' && typeof child !== 'number' && 'props' in child) {
                fullText += calculateChildrenTextRecursive(child.props.children);
            }
        });
    }
    return fullText;
};

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    acceptanceText: string;
    rejectionText: string;
    disclaimerTitle: string;
    saveDisclaimerAddressingToRedux: (addressing: DisclaimerAddressing) => void;
    onAccept: Function;
    onReject: Function;
    // Optional defaults to purchaser
    addressingContactId?: string;
    // Optional defaults to funeral opportunity
    opportunityId?: string;
    extraContentLength: boolean;
    storeOnReject: boolean;
    extraCardWidth: boolean;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default GeneralDisclaimerComponent;
