import React, { Fragment } from 'react';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import MockLinkWrapper from '../../../global-components/mock-link-component/MockLinkWrapper';
import getEnvironment from '../../../../env-config/get-environment';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import { openTabWithUrl } from '../../../../toolboxes/reuseable-logic/tab-opener';
import { GeographicState } from '../../../../static/constants/enums/states';

class PriceListDisclaimerPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setPriceListDisclaimerAddressingAction: () => {},
        subdomain: '',
        acceptTo: '',
        accountState: null,
        firstPage: false
    };

    render() {
        return (
            <StandardDisclaimerPageWrapper
                acceptanceText="I understand"
                rejectionText=""
                disclaimerTitle="Price List Information"
                saveDisclaimerAddressingToRedux={this.props.setPriceListDisclaimerAddressingAction}
                acceptTo={this.props.acceptTo}
                rejectTo=""
                allowBack={false}
                pageName="Disclaimer"
                pageBack="false"
                metaPageName=""
                metaFirstPage={this.props.firstPage.toString()}
                pageNumber=""
                extraCardWidth={this.props.accountState === GeographicState.TX}
            >
                Before we begin, we want to provide you with our&nbsp;
                {this.props.accountState === GeographicState.TX ? (
                    'Price List'
                ) : (
                    <MockLinkWrapper
                        onClick={() =>
                            openTabWithUrl(
                                `${getEnvironment().efuneralMediaBucket}/pricelists/${this.props.subdomain}.pdf`
                            )
                        }
                        id="price-list"
                    >
                        Price List
                    </MockLinkWrapper>
                )}{' '}
                and{' '}
                <MockLinkWrapper
                    onClick={() =>
                        openTabWithUrl(`${getEnvironment().efuneralMediaBucket}/notices/CA/CPRA+Notice+Rev1222.pdf`)
                    }
                    id="cpra-disclosure"
                >
                    CPRA Disclosure
                </MockLinkWrapper>{' '}
                for your reference. We have also included a link to our price list at the bottom of each page. When
                clicking these links, our offerings will open in a new tab where you can download a copy.
                {this.props.accountState === GeographicState.TX && (
                    <Fragment>
                        <br />
                        <br />
                        <iframe
                            title="general-price-list"
                            style={{ minWidth: '100%', minHeight: '300px' }}
                            src={`${getEnvironment().efuneralMediaBucket}/pricelists/${this.props.subdomain}.pdf`}
                        />
                    </Fragment>
                )}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setPriceListDisclaimerAddressingAction: (disclaimerAddressing: DisclaimerAddressing) => void;
    subdomain: string;
    acceptTo: string;
    accountState: GeographicState;
    firstPage: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PriceListDisclaimerPageComponent;
