import { connect } from 'react-redux';
import GeneralModalComponent from './GeneralModalComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import {
    calculateGlobalMuiStyle,
    calculateGlobalStyle
} from '../../../toolboxes/basic-styling/global-style-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const applicationMuiTheme = calculateGlobalMuiStyle(state);
    const applicationTheme = calculateGlobalStyle(state);
    return {
        applicationMuiTheme,
        applicationTheme
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralModalComponent);
