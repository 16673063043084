import { EndOfExperienceOptions } from 'static/constants/enums/endOfExperienceOptions';

// Action Type
export const SET_END_OF_EXPERIENCE_OPTION = 'SET_END_OF_EXPERIENCE_OPTION';
// Action
export function setEndOfExperienceOptionAction(newValue: EndOfExperienceOptions) {
    return { type: SET_END_OF_EXPERIENCE_OPTION, newValue };
}
// Reducer
export function setEndOfExperienceOptionReducer(state: any, action: any): EndOfExperienceOptions {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_END_OF_EXPERIENCE_OPTION:
            return action.newValue;
        default:
            return state;
    }
}
