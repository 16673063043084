import React from 'react';
import 'react-tabs/style/react-tabs.css';
import './EverdaysPaymentPageComponent.css';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import { PaymentMethod } from 'static/constants/enums/paymentMethods';
import CheckPaymentContentWrapper from 'components/page-components/payment-page-component/page-specific-components/payment-content-components/check-payment-content-component/CheckPaymentContentWrapper';
import CreditCardPaymentContentWrapper from 'components/page-components/payment-page-component/page-specific-components/payment-content-components/credit-card-payment-content-component/CreditCardPaymentContentWrapper';
import BackButtonLinkWrapper from 'components/global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import HealthImpactModalWrapper from 'components/modal-components/disclaimer-components/specific-disclaimers/health-impact-modal-component/HealthImpactModalWrapper';
import PaymentOption from 'static/models/PaymentOption';
import HealthQuestionInstructionsModalWrapper from 'components/modal-components/health-question-components/health-question-instructions-modal-component/HealthQuestionInstructionsModalWrapper';
import PastHealthQuestionModalWrapper from 'components/modal-components/health-question-components/past-health-question-modal-component/PastHealthQuestionModalWrapper';
import CurrentHealthQuestionModalWrapper from 'components/modal-components/health-question-components/current-health-question-modal-component/CurrentHealthQuestionModalWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import Button from '@material-ui/core/Button';

class EverdaysPaymentPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            processingPayment: false,
            errorMessage: props.startingError,
            healthInstructionsViewed: false,
            initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer:
                props.healthQuestionsNecessary || props.incapacitatedDisclaimerNecessary,
            useCC: this.props.defaultPaymentMethod === PaymentMethod.CreditCard
        };
    }

    componentDidMount() {
        if (this.props.selectedOption !== null) {
            const PaymentOptionLookup = this.props.allPaymentOptions.find(
                x =>
                    x.paymentMethod === this.props.selectedOption.paymentMethod &&
                    x.years === this.props.selectedOption.years
            );

            this.props.setSelectedPaymentOptionAction(PaymentOptionLookup);
        }
    }

    static defaultProps = {
        // Default prop values
        pricingData: '',
        importanceTheme: {},
        startingError: '',
        paymentMethodsAvailable: [],
        stripeId: '',
        incapacitatedDisclaimerNecessary: false,
        setSelectedPaymentOptionAction: () => {},
        healthQuestionsNecessary: false,
        wasBedriddenOrAiled: false,
        isBedriddenOrAiled: false,
        isAgent: false,
        selectedOption: undefined,
        allPaymentOptions: []
    };

    render() {
        if (
            this.state.initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer &&
            this.props.healthQuestionsNecessary
        ) {
            if (!this.state.healthInstructionsViewed) {
                return (
                    <HealthQuestionInstructionsModalWrapper
                        onContinue={() =>
                            this.setState({
                                healthInstructionsViewed: true
                            })
                        }
                    />
                );
            }
            if (![true, false].includes(this.props.wasBedriddenOrAiled)) {
                return <PastHealthQuestionModalWrapper />;
            }
            if (![true, false].includes(this.props.isBedriddenOrAiled)) {
                return <CurrentHealthQuestionModalWrapper />;
            }
        }

        if (
            this.state.initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer &&
            this.props.incapacitatedDisclaimerNecessary
        ) {
            return (
                <HealthImpactModalWrapper
                    onAccept={() => {}}
                    onReject={() => {
                        this.props.setSelectedPaymentOptionAction(this.props.singlePayAlternative);
                    }}
                    explainRejectionInCancel={true}
                />
            );
        }

        return (
            <FullContentCenterWrapper metaPageName="Payment" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Payment</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        {this.state.useCC && 'We accept all major credit and debit cards.'}
                        {!this.state.useCC &&
                            'Add your bank details for simple payments and never worry about an expired credit card.'}
                    </PageCardTextWrapper>
                    {this.props.isAgent && (
                        <ButtonLinkWrapper
                            to={urlPaths.representativeIdentity}
                            buttonId="skip-payment"
                            color="secondary"
                        >
                            Purchaser Will Enter Payment Information
                        </ButtonLinkWrapper>
                    )}

                    {this.state.errorMessage && <div className="errorMessage">{this.state.errorMessage}</div>}

                    {this.props.paymentMethodsAvailable.length > 1 && (
                        <Button
                            id="button-payment-switch"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const newPaymentMethod = !this.state.useCC
                                    ? PaymentMethod.CreditCard
                                    : PaymentMethod.ACH;

                                const newOption = this.props.allPaymentOptions.find(
                                    x =>
                                        x.paymentMethod === newPaymentMethod &&
                                        x.years === this.props.selectedOption.years
                                );

                                this.props.setSelectedPaymentOptionAction(newOption);

                                // wasn't set fast enough for use in the above code.
                                this.setState({ useCC: !this.state.useCC });
                            }}
                            style={{
                                marginBottom: '20px'
                            }}
                        >
                            {this.state.useCC && 'Pay with ACH'}
                            {!this.state.useCC && 'Pay with Credit Card'}
                        </Button>
                    )}
                    {this.state.useCC && <CreditCardPaymentContentWrapper redirectUrl={urlPaths.ssnCapture} />}
                    {!this.state.useCC && <CheckPaymentContentWrapper />}

                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)\
    startingError: string;
    paymentMethodsAvailable: PaymentMethod[];
    stripeId: string;
    incapacitatedDisclaimerNecessary: boolean;
    singlePayAlternative: PaymentOption;
    setSelectedPaymentOptionAction: Function;
    healthQuestionsNecessary: boolean;
    wasBedriddenOrAiled: boolean;
    isBedriddenOrAiled: boolean;
    isAgent: boolean;
    defaultPaymentMethod: PaymentMethod;
    selectedOption: PaymentOption;
    allPaymentOptions: PaymentOption[];
}

interface StateShape {
    // Shape of local state
    processingPayment: boolean;
    errorMessage: string;
    healthInstructionsViewed: boolean;
    initialPaymentSelectionRequiresHealthQuestionsOrDisclaimer: boolean;
    useCC: boolean;
}

export default EverdaysPaymentPageComponent;
