import { setOpportunitySentFuneralReceivedEmailAction } from 'redux/opportunity/sentFuneralReceivedEmail';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { sendFuneralReceivedEmail } from '../../server-application-buffer/outgoing-api-triggering-data';

export default function markContractsAsCompleted(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    // Don't resend funeral received email if we've already sent it
    if (state.opportunity?.sentFuneralReceivedEmail) {
        return [];
    }
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendFuneralReceivedEmail(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        dispatch(setOpportunitySentFuneralReceivedEmailAction(true));
                        return resolve();
                    });
                }),
            blocksPageStarts: []
        }
    ];
}
