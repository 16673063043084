import ApplicationStyling from '../../../models/ApplicationStyling';
import { TabStyling } from '../../../models/TabStyling';
import EarthFavicon from '../../../media/favicons/earth-favicon.png';
export const earthStyling: ApplicationStyling = {
    colors: {
        secondaryContrastColor: '#E5E2C1',
        primaryContrastColor: '#E5E2C1',
        footerBackgroundColor: '#5F6949',
        sectionSeperatorColor: '#7F876D',
        titleColor: '#5F6949',
        bodyColor: '#5F6949',
        primaryColor: '#85A763',
        secondaryColor: '#85A763',
        headerBottomBorderColor: '#7F876D',
        headerFontColor: '#5F6949',
        headerBackgroundColor: '#E5E2C1',
        footerFontColor: '#E5E2C1',
        generalBackgroundColor: '#E5E2C1',
        formFieldsBackgroundColor: '#F5F3E6',
        formFieldsTextColor: '#5F6949',
        placeholderFullOpacity: true
    },
    fonts: {
        buttonFontFamily: 'Neue-Montreal-Regular',
        titleFontFamily: 'Morion-Regular',
        bodyFontFamily: 'Neue-Montreal-Regular',
        titleAlignment: 'left',
        bodyAlignment: 'left',
        titleWeight: 'lighter',
        bodyWeight: 'lighter',
        titleFontSize: '24px',
        bodyFontSize: '16px',
        buttonFontSize: '14px'
    },
    sizing: {
        headerLogoMaxHeight: '30px'
    },
    spacing: {
        abovePageTitle: '36px',
        abovePageSubtitle: '12px',
        abovePageBodyContent: '24px',
        innerBody: '12px',
        aboveContinueButton: '36px',
        xs: '8px',
        sm: '12px',
        md: '24px',
        lg: '36px',
        xl: '48px',
        xxl: '72px'
    },
    showNameInTitle: true,
    useFormLabelsAsPlaceholders: true
};

export const earthTabConstants: TabStyling = {
    faviconUrl: EarthFavicon,
    tabName: 'Earth'
};

export const EARTH_STYLING_KEY = 'earth';

export const EARTH_HEADER_BREAK_POINT = 700;
