import { combineReducers } from 'redux';
import { setRepresentativeDidHandoffReducer } from './didHandoff';
import { setRepresentativeTypeReducer } from './representativeType';
import { setInsuredAvailabilityAddressedReducer } from './insuredAvailabilityAddressed';

export default combineReducers({
    didHandoff: setRepresentativeDidHandoffReducer,
    representativeType: setRepresentativeTypeReducer,
    insuredAvailabilityAddressed: setInsuredAvailabilityAddressedReducer
});
