import { combineReducers } from 'redux';
import { setOpportunityIdReducer } from './id';
import { setOpportunityProductsReducer } from './products';
import { setActiveStageReducer } from './activeStage';
import { setOpportunityTimingReducer } from './timing';
import setPurchaserReducer from './purchaser/root';
import setRecipientReducer from './recipient/root';
import { setPricingReducer } from './pricing/root';
import { setOpportunityDispositionReducer } from './disposition';
import { setSelectedFundingProductReducer } from './selectedFundingProduct';
import { setStopPageDetailReducer } from './stopPageDetail';
import { setOpportunityHidePriceListDisclaimerReducer } from './hidePriceListDisclaimer';
import { setOpportunityHideStateFilingFeeReducer } from './hideStateFilingFee';
import { setOpportunityHideAnnuityDisclaimerReducer } from './hideAnnuityDisclaimer';
import { setOpportunityHideInsuranceDisclaimerReducer } from './hideInsuranceDisclaimer';
import { setOpportunityHideDemographicsDisclaimerReducer } from './hideDemographicsDisclaimer';
import { setOpportunityHidePaymentHoldDisclaimerReducer } from './hidePaymentHoldDisclaimer';
import { setFuneralIsDemoContractIncursCostReducer } from './isDemoAndContractWouldIncurCost';
import { setOpportunityUpdateAgentCodeReducer } from './agentCode';
import { setOpportunityUpdateMarketCodeReducer } from './marketCode';
import { setOpportunityPreneedContractTypeReducer } from './preneedContractType';
import { setEndOfExperienceOptionReducer } from './endOfExperienceOption';
import { setEndOfExperienceReturnLinkReducer } from './endOfExperienceReturnLink';
import { setOpportunitySentVitalStatisticsEmailReducer } from './sentVitalStatisticsEmail';
import { setOpportunityPurchaserSignedReducer } from './purchaserSigned';
import { setOpportunityReferralAccountPhoneReducer } from './referralAccountPhone';
import { setOpportunityMileageCostReducer } from './mileageCharge';
import { setContractSigningReducer } from './contractSigning/root';
import { setOpportunityReturnLaterLinkReducer } from './returnLaterLink';
import { setOpportunityCommissionOptionReducer } from './commissionOption';
import { setOpportunitySentFuneralReceivedEmailReducer } from './sentFuneralReceivedEmail';

export default combineReducers({
    id: setOpportunityIdReducer,
    products: setOpportunityProductsReducer,
    activeStage: setActiveStageReducer,
    timing: setOpportunityTimingReducer,
    disposition: setOpportunityDispositionReducer,
    pricing: setPricingReducer,
    purchaser: setPurchaserReducer,
    recipient: setRecipientReducer,
    selectedFundingProduct: setSelectedFundingProductReducer,
    preneedContractType: setOpportunityPreneedContractTypeReducer,
    purchaserSigned: setOpportunityPurchaserSignedReducer,
    stopPageDetail: setStopPageDetailReducer,
    hidePriceListDisclaimer: setOpportunityHidePriceListDisclaimerReducer,
    hideStateFilingFee: setOpportunityHideStateFilingFeeReducer,
    hideAnnuityDisclaimer: setOpportunityHideAnnuityDisclaimerReducer,
    hideDemographicsDisclaimer: setOpportunityHideDemographicsDisclaimerReducer,
    hideInsuranceDisclaimer: setOpportunityHideInsuranceDisclaimerReducer,
    hidePaymentHoldDisclaimer: setOpportunityHidePaymentHoldDisclaimerReducer,
    isDemoAndContractWouldIncurCost: setFuneralIsDemoContractIncursCostReducer,
    agentCode: setOpportunityUpdateAgentCodeReducer,
    marketCode: setOpportunityUpdateMarketCodeReducer,
    commissionOption: setOpportunityCommissionOptionReducer,
    endOfExperienceOption: setEndOfExperienceOptionReducer,
    endOfExperienceReturnLink: setEndOfExperienceReturnLinkReducer,
    sentVitalStatisticsEmail: setOpportunitySentVitalStatisticsEmailReducer,
    referralAccountPhone: setOpportunityReferralAccountPhoneReducer,
    mileageCost: setOpportunityMileageCostReducer,
    contractSigning: setContractSigningReducer,
    returnLaterLink: setOpportunityReturnLaterLinkReducer,
    sentFuneralReceivedEmail: setOpportunitySentFuneralReceivedEmailReducer
});
