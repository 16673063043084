// Action Type
export const SET_TIMES_PHONE_VERIFICATION_SUBMITTED_PURCHASER = 'SET_TIMES_PHONE_VERIFICATION_SUBMITTED_PURCHASER';
// Action
export function setTimesPurchaserPhoneVerificationSubmittedAction(newValue: number) {
    return { type: SET_TIMES_PHONE_VERIFICATION_SUBMITTED_PURCHASER, newValue };
}
// Reducer
export function setTimesPurchaserPhoneVerificationSubmittedReducer(state: any, action: any): number {
    if (typeof state === 'undefined') {
        // Initial data
        return 0;
    }
    switch (action.type) {
        case SET_TIMES_PHONE_VERIFICATION_SUBMITTED_PURCHASER:
            return action.newValue;
        default:
            return state;
    }
}
