import React, { ReactNode } from 'react';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import PageCardTextWrapper from '../../page-card-components/page-card-text-component/PageCardTextWrapper';
import './MiniDisclaimerAcceptanceComponent.css';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { calculateChildrenTextRecursive } from 'components/global-components/general-disclaimer-components/general-disclaimer-component/GeneralDisclaimerComponent';

class MiniDisclaimerAcceptanceComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <>
                <PageCardTextWrapper>{this.props.children}</PageCardTextWrapper>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                required={true}
                                disabled={this.props.disableCheckbox}
                                checked={this.props.checked}
                                color="primary"
                                onClick={() => {
                                    this.props.onAccept();
                                    this.props.saveDisclaimerAddressingToRedux({
                                        disclaimerText: calculateChildrenTextRecursive(this.props.children),
                                        hasBeenAcceptedOrPositivelyAddressed: true,
                                        responseToDisclaimer: this.props.acceptanceText,
                                        addressedByContactId: this.props.addressingContactId
                                    });
                                }}
                                id="accept"
                            />
                        }
                        label={<Typography style={{ fontFamily: 'open-sans' }}>I Understand</Typography>}
                    />
                </FormGroup>
            </>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    acceptanceText: string;
    saveDisclaimerAddressingToRedux: (addressing: DisclaimerAddressing) => void;
    onAccept: Function;
    addressingContactId?: string;
    opportunityId?: string;
    disableCheckbox: boolean;
    checked: boolean;
}

interface StateShape {
    // Shape of local state
}

export default MiniDisclaimerAcceptanceComponent;
