import React, { Fragment } from 'react';
import './OrderSummaryPageComponent.css';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';

import {
    formatDate,
    formatHiddenSSN,
    formatMonetaryAmount,
    formatPhoneNumber,
    getFormattedPriceForProduct
} from 'toolboxes/reuseable-logic/string-formatters';
import Product from 'static/models/Product';
import MockLinkRedirectionWrapper from 'components/global-components/buttons/mock-link-redirection-component/MockLinkRedirectionWrapper';
import { ReactComponent as PencilIcon } from 'static/media/pencil.svg';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import IrrevocableSelectionWrapper from './page-specific-components/irrevocable-selection-component/IrrevocableSelectionWrapper';
import ReplacementCheckWrapper from './page-specific-components/replacement-check-component/ReplacementCheckWrapper';
import TotalDisplayWrapper from './page-specific-components/total-display-component/TotalDisplayWrapper';
import BackButtonLinkWrapper from 'components/global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PricingData from 'static/models/PricingData';
import PageCardUnderlinedSectionWrapper from 'components/global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import SectionDividerWrapper from './page-specific-components/section-divider-component/SectionDividerWrapper';

const sortableCategories = ['Professional Services', 'Mechandise', 'Additional Items', 'Transportation'];

class OrderSummaryPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };

        if (!this.props.displayIrrevocable) {
            this.props.setIrrevocableAction(false);
        }
    }

    static defaultProps = {
        // Default prop values
        displayIrrevocable: true,
        dispositionText: '',
        funeralSalesTax: 0,
        funeralSubtotal: 0,
        funeralTotal: 0,
        isAtNeed: false,
        isComplete: false,
        isIrrevocable: false,
        isReplacementState: false,
        packageName: '',
        pricingData: undefined,
        products: [],
        purchaser: {},
        recipient: {},
        recipientSameAsPurchaser: false,
        setIrrevocableAction: () => {},
        total: 0,
        travelProducts: [],
        travelSalesTax: 0,
        travelSubtotal: 0,
        travelTotal: 0,
        mileageCost: '',
        displaySubcategories: true,
        formatProductPricesWithCommas: false
    };

    generateProductDescription(product: Product) {
        return (
            <div key={`${product.productId}-container`} className="price-container">
                <div>
                    {product.quantity.toString().split('.')[0]} X {product.name}{' '}
                </div>
                <div id={`text-product-${product.productId}-total`}>
                    {getFormattedPriceForProduct(product, this.props.formatProductPricesWithCommas)}
                </div>
            </div>
        );
    }

    render() {
        const uncategorizedArrangementProducts = this.props.products.filter(
            product => !sortableCategories.includes(product.category)
        );

        return (
            <FullContentCenterWrapper metaPageName="Summary" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Your Total</PageCardTitleWrapper>
                    <TotalDisplayWrapper formatProductPricesWithCommas={this.props.formatProductPricesWithCommas} />
                    <PageCardTextWrapper>
                        Please review the information you’ve provided below. When you are ready, click continue to enter
                        payment information.
                    </PageCardTextWrapper>
                    <SectionDividerWrapper />
                    <PageCardUnderlinedSectionWrapper
                        sectionTitle="Planned by"
                        sectionId="planned-by"
                        fullContent={
                            <Fragment>
                                <div style={{ color: this.props.primaryColor, fontWeight: 'bold', fontSize: 'large' }}>
                                    {this.props.purchaser.firstName} {this.props.purchaser.lastName}
                                </div>
                                <SectionDividerWrapper isSubsection />
                                <div>{this.props.purchaser.address}</div>
                                <div>
                                    {this.props.purchaser.city}, {this.props.purchaser.state}{' '}
                                    {this.props.purchaser.zipCode}
                                </div>
                                <div>{this.props.purchaser.email}</div>
                                <div>{formatPhoneNumber(this.props.purchaser.phone)}</div>
                                {!this.props.isAtNeed && (
                                    <div>
                                        {this.props.purchaser.birthdate
                                            ? formatDate(this.props.purchaser.birthdate)
                                            : ''}
                                        {this.props.purchaser.gender
                                            ? (this.props.purchaser.birthdate ? ', ' : '') + this.props.purchaser.gender
                                            : ''}
                                    </div>
                                )}
                                <div>{this.props.purchaser.ssn && `${formatHiddenSSN(this.props.purchaser.ssn)}`}</div>
                                <MockLinkRedirectionWrapper to={urlPaths.purchaser} linkId="purchaser">
                                    <PencilIcon style={{ verticalAlign: 'text-bottom' }} /> Edit this information
                                </MockLinkRedirectionWrapper>
                            </Fragment>
                        }
                    />
                    {!this.props.recipientSameAsPurchaser && (
                        <PageCardUnderlinedSectionWrapper
                            sectionTitle="Planned for"
                            sectionId="planned-for"
                            fullContent={
                                <Fragment>
                                    <div
                                        style={{
                                            color: this.props.primaryColor,
                                            fontWeight: 'bold',
                                            fontSize: 'large'
                                        }}
                                    >
                                        {this.props.recipient.firstName} {this.props.recipient.lastName}
                                    </div>
                                    <SectionDividerWrapper isSubsection />
                                    <div>{this.props.recipient.address}</div>
                                    <div>
                                        {this.props.recipient.city}, {this.props.recipient.state}{' '}
                                        {this.props.recipient.zipCode}
                                    </div>
                                    <div>{this.props.recipient.email}</div>
                                    <div>{formatPhoneNumber(this.props.recipient.phone)}</div>
                                    {!this.props.isAtNeed && (
                                        <div>
                                            {formatDate(this.props.recipient.birthdate)}, {this.props.recipient.gender}
                                        </div>
                                    )}
                                    <div>
                                        {this.props.recipient.ssn && `, ${formatHiddenSSN(this.props.recipient.ssn)}`}
                                    </div>
                                    <MockLinkRedirectionWrapper to={urlPaths.recipient} linkId="recipient">
                                        <PencilIcon style={{ verticalAlign: 'text-bottom' }} /> Edit this information
                                    </MockLinkRedirectionWrapper>
                                </Fragment>
                            }
                        />
                    )}
                    {(this.props.packageName || this.props.products.length > 0) && (
                        <PageCardUnderlinedSectionWrapper
                            sectionTitle={`${this.props.dispositionText} Summary`}
                            sectionId="funeral-summary"
                            fullContent={
                                <Fragment>
                                    {sortableCategories.map((category, index) => {
                                        let filteredProducts = this.props.products.filter(
                                            product => product.category === category
                                        );
                                        if (filteredProducts.length === 0) {
                                            return null;
                                        }
                                        return (
                                            <Fragment key={'category-' + index}>
                                                {this.props.displaySubcategories && (
                                                    <div className="sorted-category-title">{category}</div>
                                                )}
                                                {filteredProducts.map((product, index) =>
                                                    this.generateProductDescription(product)
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                    {uncategorizedArrangementProducts.length > 0 && (
                                        <Fragment>
                                            {this.props.displaySubcategories && (
                                                <div className="sorted-category-title">Other</div>
                                            )}
                                            {uncategorizedArrangementProducts.map((product, index) =>
                                                this.generateProductDescription(product)
                                            )}
                                        </Fragment>
                                    )}
                                    <Fragment>
                                        <SectionDividerWrapper isSubsection />
                                        <b>Total</b>
                                        <div className="price-container">
                                            <div>Subtotal</div>
                                            <div>
                                                {formatMonetaryAmount(
                                                    this.props.funeralSubtotal.toString(),
                                                    this.props.formatProductPricesWithCommas
                                                )}
                                            </div>
                                        </div>
                                        <div className="price-container">
                                            <div>Sales Tax</div>
                                            <div>
                                                {formatMonetaryAmount(
                                                    this.props.funeralSalesTax.toString(),
                                                    this.props.formatProductPricesWithCommas
                                                )}
                                            </div>
                                        </div>
                                        <div className="price-container">
                                            <div id={`text-funeral-total`}>{this.props.dispositionText} Total</div>
                                            <div style={{ color: this.props.primaryColor }}>
                                                <b>
                                                    {formatMonetaryAmount(
                                                        this.props.funeralTotal.toString(),
                                                        this.props.formatProductPricesWithCommas
                                                    )}
                                                </b>
                                            </div>
                                        </div>
                                    </Fragment>
                                </Fragment>
                            }
                        />
                    )}
                    {this.props.travelProducts.length > 0 && (
                        <PageCardUnderlinedSectionWrapper
                            sectionTitle="Travel Protection Summary"
                            sectionId="travel-protection-summary"
                            fullContent={
                                <Fragment>
                                    <b>Included Services</b>
                                    {this.props.travelProducts.map(product => this.generateProductDescription(product))}
                                    <SectionDividerWrapper isSubsection />
                                    <b>Total</b>
                                    <div className="price-container">
                                        <div>Subtotal</div>
                                        <div>
                                            {formatMonetaryAmount(
                                                this.props.travelSubtotal.toString(),
                                                this.props.formatProductPricesWithCommas
                                            )}
                                        </div>
                                    </div>
                                    <div className="price-container">
                                        <div>Sales Tax</div>
                                        <div>
                                            {formatMonetaryAmount(
                                                this.props.travelSalesTax.toString(),
                                                this.props.formatProductPricesWithCommas
                                            )}
                                        </div>
                                    </div>
                                    <div className="price-container">
                                        <div>Travel Protection Total</div>
                                        <div id={`text-travel-total`} style={{ color: this.props.primaryColor }}>
                                            <b>
                                                {formatMonetaryAmount(
                                                    this.props.travelTotal.toString(),
                                                    this.props.formatProductPricesWithCommas
                                                )}
                                            </b>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        />
                    )}
                    {this.props.displayReplacement && (
                        <div className="lower-information-card">
                            <ReplacementCheckWrapper />
                        </div>
                    )}
                    {this.props.displayIrrevocable && !this.props.isAtNeed && (
                        <div className="lower-information-card">
                            <IrrevocableSelectionWrapper />
                        </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ maxWidth: '420px', flexGrow: 1 }}>
                            <ButtonLinkWrapper
                                disabled={!this.props.isComplete}
                                to={urlPaths.payment}
                                buttonId="continue"
                            >
                                Payment options
                            </ButtonLinkWrapper>
                        </div>
                    </div>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    displayIrrevocable: boolean;
    dispositionText: string;
    funeralSalesTax: number;
    funeralSubtotal: number;
    funeralTotal: number;
    isAtNeed: boolean;
    isComplete: boolean;
    isIrrevocable: boolean;
    isReplacementState: boolean;
    packageName: string;
    pricingData: PricingData;
    primaryColor: string;
    products: Product[];
    purchaser: any;
    recipient: any;
    recipientSameAsPurchaser: boolean;
    setIrrevocableAction: Function;
    total: number;
    travelProducts: Product[];
    travelSalesTax: number;
    travelSubtotal: number;
    travelTotal: number;
    mileageCost: string;
    displayReplacement: boolean;
    displaySubcategories: boolean;
    formatProductPricesWithCommas: boolean;
}

interface StateShape {
    // Shape of local state
}

export default OrderSummaryPageComponent;
