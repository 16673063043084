import React from 'react';
import TextBoxWrapper from '../text-box-component/TextBoxWrapper';
import { PropShape, textBoxDefaultProps } from '../text-box-component/TextBoxComponent';

class TextAreaComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = textBoxDefaultProps;

    render() {
        return (
            <TextBoxWrapper
                {...this.props}
                extraFieldProps={{
                    rows: 3,
                    multiline: true,
                    ...this.props.extraFieldProps
                }}
            />
        );
    }
}

interface StateShape {
    // Shape of local state
}

export default TextAreaComponent;
