import React from 'react';
import ButtonSubtitledLinkWrapper from '../../global-components/buttons/button-subtitled-link-component/ButtonSubtitledLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import ButtonSubtitledLinkGroupWrapper from '../../global-components/buttons/button-subtitled-link-component/button-subtitled-link-group-component/ButtonSubtitledLinkGroupWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';

class BeneficiaryCheckPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setBeneficiaryInformation: () => {},
        showGiveToPurchaser: false,
        purchaserName: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="Beneficiary" metaPageNumber="1" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Who should any remaining funds be paid to?</PageCardTitleWrapper>
                    <PageCardTextWrapper>{this.props.beneficiaryText}</PageCardTextWrapper>
                    <ButtonSubtitledLinkGroupWrapper>
                        {this.props.showGiveToPurchaser && (
                            <ButtonSubtitledLinkWrapper
                                to={urlPaths.orderSummary}
                                title={
                                    'Give proceeds to' +
                                    (this.props.purchaserName ? ': ' + this.props.purchaserName : ' Purchaser')
                                }
                                subtitle=""
                                buttonId="purchaser-beneficiary"
                                navigationSideEffect={() => this.props.setBeneficiaryInformation(true, true)}
                            />
                        )}
                        <ButtonSubtitledLinkWrapper
                            to={urlPaths.beneficiary}
                            title={
                                this.props.showGiveToPurchaser ? 'Give proceeds to someone else' : 'Name a beneficiary'
                            }
                            subtitle=""
                            buttonId="name-beneficiary"
                            navigationSideEffect={() => this.props.setBeneficiaryInformation(true, false)}
                        />
                        <ButtonSubtitledLinkWrapper
                            to={urlPaths.orderSummary}
                            title="Give proceeds to estate"
                            subtitle=""
                            buttonId="give-to-estate"
                            navigationSideEffect={() => this.props.setBeneficiaryInformation(false, false)}
                        />
                    </ButtonSubtitledLinkGroupWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    beneficiaryText: string;
    showGiveToPurchaser: boolean;
    purchaserName: string;
    setBeneficiaryInformation: Function;
}

interface StateShape {
    // Shape of local state
}

export default BeneficiaryCheckPageComponent;
