import { getFormValues } from 'redux-form';
import { formIsValid } from 'toolboxes/redux-form/redux-form-validation';
import { getPurchaserValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { GeographicState } from '../../../static/constants/enums/states';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    sendSaveBeneficiaryCheckData,
    sendSavePurchaserData,
    sendSaveRecipientData
} from '../../server-application-buffer/contact-data';
import { sendSaveIrrevocabilityDisclaimer } from '../../server-application-buffer/disclaimer-data';
import {
    sendSaveCurrentPolicyData,
    sendSaveIrrevocableData,
    sendSaveReplacingCurrentPolicyData
} from '../../server-application-buffer/general-opportunity-data';

export function saveOrderSummaryData(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const validateFunction = getPurchaserValidationFunction(state);

    const functionsToRun: BlockingFunction[] = [];
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSaveIrrevocableData(state, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.funeralContracts]
    });
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSaveIrrevocabilityDisclaimer(state, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.funeralContracts]
    });
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSaveCurrentPolicyData(state, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.funeralContracts]
    });
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSaveReplacingCurrentPolicyData(state, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.funeralContracts]
    });
    /*
            TODO - Below is an exit function which falsly sets beneficiary check data to false (saving beneficiaryProvided to false regardless of the truth)
            This logic is a translation from guided where the same thing happened
            The goal of this logic is to cause beneficiary data to not appear on irrevocable contracts in minnesota
            This type of goal should be achieved in a contract generation service, instead of misuing data to cause it to happen in here
            The time to implement such a service is not available at this time, so this logic will remain
            However, a ticket has been created to deal with this once a better solution is found
        */
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise((resolve, reject) => {
                if (state.account.state === GeographicState.MN && state.yesNoAnswers.policyQuestions.irrevocable) {
                    sendSaveBeneficiaryCheckData(
                        {
                            ...state,
                            yesNoAnswers: {
                                ...state.yesNoAnswers,
                                policyQuestions: {
                                    ...state.yesNoAnswers.policyQuestions,
                                    beneficiaryProvided: false
                                }
                            }
                        },
                        (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        }
                    );
                } else {
                    resolve();
                }
            }),
        blocksPageStarts: [urlPaths.payment]
    });

    if (formIsValid(getFormValues('purchaser')(state), validateFunction)) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSavePurchaserData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.ssnCapture]
        });
    }

    if (formIsValid(getFormValues('recipient')(state), validateFunction)) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    sendSaveRecipientData(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.ssnCapture]
        });
    }
    return functionsToRun;
}
