import React from 'react';
import './FooterComponent.css';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';
import getEnvironment from '../../../../env-config/get-environment';
import { Container, Grid, Hidden, Link } from '@material-ui/core';
import { FaFacebook, FaInstagram, FaTwitter, FaYelp } from 'react-icons/fa';

class FooterComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        phoneNumber: '',
        stylingOptions: undefined,
        isAgentStyling: false,
        subdomain: '',
        opportunityId: '',
        accountState: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        yelpUrl: '',
        address: '',
        city: '',
        funeralHomeNumber: '',
        zip: '',
        name: '',
        mediaDomain: ''
    };

    render() {
        if (!this.props.stylingOptions.loadingComplete || this.props.isAgentStyling || !this.props.subdomain) {
            return '';
        }

        const showTexasDisclaimer = this.props.accountState.toLowerCase() === 'tx';
        return (
            <footer
                className="ef-footer-wrapper"
                style={{
                    borderTop: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    borderBottom: `0.1875rem solid ${this.props.stylingOptions.colors.headerBottomBorderColor}`,
                    backgroundColor: this.props.stylingOptions.colors.footerBackgroundColor
                }}
            >
                <Container>
                    <Grid container className="ef-footer" direction="row">
                        <Grid item xs={12} sm={6}>
                            <Hidden only="xs">
                                <div className="ef-footer-left">
                                    <div className="ef-footer-links">
                                        <div className="ef-footer-social-links">
                                            {this.props.facebookUrl && (
                                                <a
                                                    className="facebook-image-footer"
                                                    href={this.props.facebookUrl}
                                                    target="_blank"
                                                    title="Facebook"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaFacebook size="2rem" />
                                                </a>
                                            )}
                                            {this.props.instagramUrl && (
                                                <a
                                                    className="instagram-image-footer"
                                                    href={this.props.instagramUrl}
                                                    target="_blank"
                                                    title="Instagram"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaInstagram size="2rem" />
                                                </a>
                                            )}
                                            {this.props.twitterUrl && (
                                                <a
                                                    className="twitter-image-footer"
                                                    href={this.props.twitterUrl}
                                                    target="_blank"
                                                    title="Twitter"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaTwitter size="2rem" />
                                                </a>
                                            )}
                                            {this.props.yelpUrl && (
                                                <a
                                                    className="yelp-image-footer"
                                                    href={this.props.yelpUrl}
                                                    target="_blank"
                                                    title="Yelp"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaYelp size="2rem" />
                                                </a>
                                            )}
                                        </div>
                                        {this.props.subdomain && (
                                            <div
                                                className="ef-footer-internal-links"
                                                style={{ color: this.props.stylingOptions.colors.footerFontColor }}
                                            >
                                                <Link
                                                    className="ef-footer-privacy"
                                                    href="https://www.efuneral.com/privacy/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Privacy Policy
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-terms"
                                                    href="https://www.efuneral.com/terms-of-use/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Terms of Use
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-gpl"
                                                    href={`${getEnvironment().efuneralMediaBucket}/pricelists/${
                                                        this.props.subdomain
                                                    }.pdf`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    General Price List (GPL)
                                                </Link>
                                                {showTexasDisclaimer && (
                                                    <p className="ef-footer-texas-disclaimer">
                                                        Texas consumers: Visit{' '}
                                                        <Link
                                                            href="https://www.prepaidfunerals.texas.gov"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            color="inherit"
                                                        >
                                                            www.prepaidfunerals.texas.gov
                                                        </Link>{' '}
                                                        for more information.
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Hidden>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Hidden only="xs">
                                <div className="ef-footer-right">
                                    <div className="ef-footer-fh">
                                        {this.props.name && this.props.name !== '' && (
                                            <div
                                                className="ef-footer-fh-address"
                                                style={{ color: this.props.stylingOptions.colors.footerFontColor }}
                                            >
                                                <h4 className="ef-footer-fh-name">{`${this.props.name}`}</h4>
                                                <p className="ef-footer-fh-street-address">{`${this.props.address}`}</p>
                                                <p className="ef-footer-fh-city-state-zip">
                                                    {`${this.props.city}`}, {`${this.props.accountState}`}{' '}
                                                    {`${this.props.zip}`}
                                                </p>
                                                {this.props.funeralHomeNumber &&
                                                    this.props.funeralHomeNumber !== '' && (
                                                        <p className="ef-footer-fh-number">
                                                            FD#: {`${this.props.funeralHomeNumber}`}
                                                        </p>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Hidden>
                        </Grid>

                        <Grid item xs={12}>
                            <Hidden smUp>
                                <div className="ef-footer-moblie">
                                    <div className="ef-footer-links">
                                        <div className="ef-footer-social-links">
                                            {this.props.facebookUrl && this.props.facebookUrl !== '' && (
                                                <a
                                                    className="facebook-image-footer"
                                                    href={this.props.facebookUrl}
                                                    target="_blank"
                                                    title="Facebook"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaFacebook size="2rem" />
                                                </a>
                                            )}
                                            {this.props.instagramUrl && this.props.instagramUrl !== '' && (
                                                <a
                                                    className="instagram-image-footer"
                                                    href={this.props.instagramUrl}
                                                    target="_blank"
                                                    title="Instagram"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaInstagram size="2rem" />
                                                </a>
                                            )}
                                            {this.props.twitterUrl && this.props.twitterUrl !== '' && (
                                                <a
                                                    className="twitter-image-footer"
                                                    href={this.props.twitterUrl}
                                                    target="_blank"
                                                    title="Twitter"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaTwitter size="2rem" />
                                                </a>
                                            )}
                                            {this.props.yelpUrl && this.props.yelpUrl !== '' && (
                                                <a
                                                    className="yelp-image-footer"
                                                    href={this.props.yelpUrl}
                                                    target="_blank"
                                                    title="Yelp"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FaYelp size="2rem" />
                                                </a>
                                            )}
                                        </div>
                                        {this.props.subdomain && this.props.subdomain !== '' && (
                                            <div
                                                className="ef-footer-internal-links"
                                                style={{ color: this.props.stylingOptions.colors.footerFontColor }}
                                            >
                                                <Link
                                                    className="ef-footer-privacy"
                                                    href="https://www.efuneral.com/privacy/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Privacy Policy
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-terms"
                                                    href="https://www.efuneral.com/terms-of-use/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    Terms of Use
                                                </Link>
                                                <span> | </span>
                                                <Link
                                                    className="ef-footer-gpl"
                                                    href={`${getEnvironment().efuneralMediaBucket}/pricelists/${
                                                        this.props.subdomain
                                                    }.pdf`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="inherit"
                                                >
                                                    General Price List (GPL)
                                                </Link>
                                                {showTexasDisclaimer && (
                                                    <p className="ef-footer-texas-disclaimer-mobile">
                                                        Texas consumers: Visit{' '}
                                                        <Link
                                                            href="https://www.prepaidfunerals.texas.gov"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            color="inherit"
                                                        >
                                                            www.prepaidfunerals.texas.gov
                                                        </Link>{' '}
                                                        for more information.
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {this.props.name && this.props.name !== '' && (
                                    <div
                                        className="ef-footer-fh-address-mobile"
                                        style={{
                                            textAlign: 'center',
                                            color: this.props.stylingOptions.colors.footerFontColor
                                        }}
                                    >
                                        <h4 className="ef-footer-fh-name">{`${this.props.name}`}</h4>
                                        <p className="ef-footer-fh-street-address">{`${this.props.address}`}</p>
                                        <p className="ef-footer-fh-city-state-zip">
                                            {`${this.props.city}`}, {`${this.props.accountState}`} {`${this.props.zip}`}
                                        </p>
                                        {this.props.funeralHomeNumber && this.props.funeralHomeNumber !== '' && (
                                            <p className="ef-footer-fh-number">
                                                FD#: {`${this.props.funeralHomeNumber}`}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </Hidden>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    phoneNumber: string;
    stylingOptions: ApplicationStyling;
    isAgentStyling: boolean;
    subdomain: string;
    opportunityId: string;
    accountState: string;
    facebookUrl: string;
    instagramUrl: string;
    twitterUrl: string;
    yelpUrl: string;
    address: string;
    city: string;
    funeralHomeNumber: string;
    zip: string;
    name: string;
    mediaDomain: string;
}

interface StateShape {
    // Shape of local state
}

export default FooterComponent;
