import React from 'react';
import GeneralModalWrapper from '../../general-modal-component/GeneralModalWrapper';
import CurrentHealthWrapper from '../../../global-components/health-question-components/current-health-component/CurrentHealthWrapper';

class CurrentHealthQuestionModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <GeneralModalWrapper
                //Can't close manually
                onRequestClose={() => {}}
            >
                <CurrentHealthWrapper />
            </GeneralModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default CurrentHealthQuestionModalComponent;
