import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendCustomerDeclinedContract } from 'toolboxes/server-application-buffer/contracts-data';

function contractsDeniedEntry(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const loadingFunctions: BlockingFunction[] = [];

    // DEV2020-1478 - with new opportunity stages
    // stages are not set directly by the font end
    // for a declined contract, we need to hit a new endpoint
    // to set the contract to 'Closed Lost'
    if (state.featureFlags.useNewOpportunityStages) {
        loadingFunctions.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendCustomerDeclinedContract(state.opportunity.id, (result, error) => {
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.contractsDenied]
        });
    }

    return loadingFunctions;
}

export default contractsDeniedEntry;
