import PaymentOption from '../../../../static/models/PaymentOption';

// Action Type
export const SET_SELECTED_PAYMENT_OPTION = 'SET_SELECTED_PAYMENT_OPTION';
// Action
export function setSelectedPaymentOptionAction(newValue: PaymentOption) {
    return { type: SET_SELECTED_PAYMENT_OPTION, newValue };
}
// Reducer
export function setSelectedPaymentOptionReducer(state: any, action: any): PaymentOption {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_SELECTED_PAYMENT_OPTION:
            return action.newValue;
        default:
            return state;
    }
}
