import React from 'react';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import MockLinkRedirectionWrapper from '../../global-components/buttons/mock-link-redirection-component/MockLinkRedirectionWrapper';
import PhoneVerificationRequestSecurityFormWrapper from 'components/form-components/phone-verification-request-security-form-component/PhoneVerificationRequestSecurityFormWrapper';
import Button from '@material-ui/core/Button';
import PhoneVerifySecurityFormWrapper from 'components/form-components/phone-verify-security-form-component/PhoneVerifySecurityFormWrapper';
import LoadingScreenWrapper from '../../global-components/loading-screen-component/LoadingScreenWrapper';
import { sendSubmitPhoneVerificationSecurityRequest } from '../../../toolboxes/server-application-buffer/phone-verification-data';
import { Redirect } from 'react-router-dom';
import Environment from '../../../static/models/Environment';
import { testEnvironment } from '../../../env-config/environment-configs/test';
import ApplicationStyling from 'static/models/ApplicationStyling';

class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            sentCode: false,
            error: null,
            loading: false,
            redirectTo: null,
            attemptedSms: false,
            attemptedCall: false
        };
    }

    static defaultProps = {
        // Default prop values
        radioFormComplete: false,
        formComplete: false,
        methodType: '',
        environment: null,
        setSecurityBypass: () => {},
        createCase: () => {},
        stylingOptions: undefined
    };

    render() {
        if (this.state.loading) {
            return <LoadingScreenWrapper />;
        }

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push />;
        }

        let pageText =
            'We take your information security seriously. To get access to the data of the sale you have in progress, please select how you would like to receive a verification code.';

        let attemptedAllMethods = this.state.attemptedSms && this.state.attemptedCall;
        let attemptedNoMethods = !(this.state.attemptedSms || this.state.attemptedCall);
        let attemptedOnlySms = this.state.attemptedSms && !this.state.attemptedCall;
        let attemptedOnlyCall = !this.state.attemptedSms && this.state.attemptedCall;

        if (attemptedAllMethods) {
            pageText =
                'We were unable to use the phone number you provided as verification. Please contact support for assistance.';
        }

        if (attemptedOnlySms || attemptedOnlyCall) {
            pageText =
                'We were unable to use the method you chose with the phone number you provided as verification. Please try the other option.';
        }
        return (
            <FullContentCenterWrapper metaPageName="SecurityCheck" metaPageNumber="" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Security Check</PageCardTitleWrapper>
                    <PageCardTextWrapper>{pageText}</PageCardTextWrapper>
                    <PhoneVerificationRequestSecurityFormWrapper
                        formName="purchaserPhoneVerification"
                        hideSms={this.state.attemptedSms}
                        hideCall={this.state.attemptedCall}
                    />
                    <Button
                        disabled={!this.props.radioFormComplete || this.state.sentCode || attemptedAllMethods}
                        id="button-send-code"
                        variant="contained"
                        color="primary"
                        style={{
                            fontFamily: ``,
                            marginBottom: this.props.stylingOptions?.spacing?.abovePageBodyContent
                                ? this.props.stylingOptions.spacing.abovePageBodyContent
                                : '20px',
                            marginTop: this.props.stylingOptions?.spacing?.aboveContinueButton
                                ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                                : '',
                            fontSize: this.props.stylingOptions?.fonts?.buttonFontSize
                        }}
                        onClick={() => {
                            this.setState({ sentCode: true, loading: true });

                            // Function Call to backend to send the code to the phone
                            sendSubmitPhoneVerificationSecurityRequest(this.props.methodType, (result, error) => {
                                this.setState({ sentCode: true });
                                if (error) {
                                    this.setState({ sentCode: false });
                                    if (this.props.methodType === 'sms') {
                                        this.setState({ attemptedSms: true });
                                    }
                                    if (this.props.methodType === 'call') {
                                        this.setState({ attemptedCall: true });
                                    }
                                }
                                this.setState({ loading: false });
                            });
                        }}
                    >
                        Send Code
                    </Button>
                    <PhoneVerifySecurityFormWrapper
                        formName="purchaserPhoneVerification"
                        disabled={!this.state.sentCode}
                    />
                    <div hidden={!this.state.sentCode}>
                        <ButtonLinkWrapper
                            to={urlPaths.loadOpportunity}
                            disabled={!this.state.sentCode || !this.props.formComplete}
                            buttonId="verify"
                        >
                            Verify
                        </ButtonLinkWrapper>
                    </div>
                    <small>
                        <MockLinkRedirectionWrapper
                            to={urlPaths.loadOpportunity}
                            linkId="resend-code"
                            hidden={!this.state.sentCode}
                        >
                            Click here if you did not receive a code
                        </MockLinkRedirectionWrapper>
                    </small>

                    {this.props.environment === testEnvironment && (
                        <div hidden={!(this.props.environment === testEnvironment)}>
                            <ButtonLinkWrapper
                                disabled={!(this.props.environment === testEnvironment)}
                                to={urlPaths.loadOpportunity}
                                buttonId="bypass"
                                navigationSideAffect={() => {
                                    this.props.setSecurityBypass(true);
                                }}
                            >
                                Security Check Bypass
                            </ButtonLinkWrapper>
                        </div>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    radioFormComplete: boolean;
    formComplete: boolean;
    methodType: string;
    environment: Environment;
    setSecurityBypass: Function;
    stylingOptions: ApplicationStyling;
    createCase: Function;
}

interface StateShape {
    // Shape of local state
    error: any;
    sentCode: boolean;
    loading: boolean;
    redirectTo: urlPaths;
    attemptedSms: boolean;
    attemptedCall: boolean;
}

export default TemplateComponent;
