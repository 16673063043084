import { connect } from 'react-redux';
import InstallmentPaymentOptionsDisplayComponent from './InstallmentPaymentOptionsDisplayComponent';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import { setSelectedPaymentOptionAction } from '../../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { calculateIsIncapacitated } from '../../../../../../toolboxes/reuseable-logic/incapacitated-calculator';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

function calculateHealthQuestionsWouldBeNecessaryIfMultiPayWasChosen(state: GlobalStateShape) {
    // Homesteaders 200 contracts get health questions
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    const selectedFundingProduct = state.opportunity.selectedFundingProduct;
    return (
        !isTrust &&
        selectedFundingProduct.contractId.startsWith('2') &&
        selectedFundingProduct.provider?.key === 'Homesteaders' &&
        (![true, false].includes(state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled) ||
            ![true, false].includes(state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears))
    );
}

const mapStateToProps: any = (state: GlobalStateShape) => {
    const isTrust = state.opportunity.preneedContractType === PreneedContractType.Trust;
    // These values will be passed in as props
    return {
        multiPayOptions: state.opportunity.pricing.paymentOptions.availableOptions.filter(
            option => option.paymentMode === 'multi'
        ),
        wasBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears,
        isBedriddenOrAiled: state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled,
        isIncapacitatedRates: calculateIsIncapacitated(state),
        healthQuestionsNecessary: calculateHealthQuestionsWouldBeNecessaryIfMultiPayWasChosen(state),
        isTrust: isTrust
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOptionAction: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentPaymentOptionsDisplayComponent);
