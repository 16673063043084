import { connect } from 'react-redux';
import RecipientCheckPageComponent from './RecipientCheckPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setOpportunityRecipientSameAsPurchaserAction } from '../../../redux/opportunity/recipient/sameAsPurchaser';
import { getFormValues, reset } from 'redux-form';
import { setOpportunityRecipientIdAction } from '../../../redux/opportunity/recipient/id';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { getDispositionText } from 'toolboxes/reuseable-logic/language-utils';
import { getPurchaserValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';
import { formIsValid } from 'toolboxes/redux-form/redux-form-validation';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const purchaserValidationFunction = getPurchaserValidationFunction(state);
    let planningForSelfNextUrl = urlPaths.disclaimerProduct;

    // EFIT-3146 we need to run the purchaser validation if they are planning for self as in some edges cases they can skip entering birthdate and gender
    // after selecting planning for other and returning to purchaser demographic form. If they skip entering those pieces of information and then
    // click planning for self we will want to re-validate the form to verify that they entered al required information.
    if (!formIsValid(getFormValues('purchaser')(state), values => purchaserValidationFunction({ ...values }))) {
        planningForSelfNextUrl = urlPaths.purchaser;
    } else if (state.opportunity.preneedContractType === PreneedContractType.Trust) {
        planningForSelfNextUrl = urlPaths.trustFundedPrearrangement;
    }

    // These values will be passed in as props
    return {
        isSameAsPurchaser: state.opportunity.recipient.sameAsPurchaser,
        dispositionText: getDispositionText(state),
        firstPage: firstPageCalculator(state, urlPaths.recipientCheck),
        planningForSelfNextUrl: planningForSelfNextUrl,
        planningForOtherNextUrl:
            state.opportunity.preneedContractType === PreneedContractType.Trust
                ? urlPaths.recipient
                : urlPaths.relationshipToRecipient
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSameAsPurchaser: newValue => dispatch(setOpportunityRecipientSameAsPurchaserAction(newValue)),
        clearRecipient: () => {
            dispatch(reset('recipient'));
            dispatch(setOpportunityRecipientIdAction(null));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientCheckPageComponent);
