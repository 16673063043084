import React, { Fragment } from 'react';
import { formatMonetaryAmount } from '../../../../../toolboxes/reuseable-logic/string-formatters';
import { isMobile } from '../../../../../toolboxes/reuseable-logic/mobile-detection';
import './TulipTotalDisplayComponent.css';
import PaymentOption from '../../../../../static/models/PaymentOption';
import { PaymentMode } from '../../../../../static/constants/enums/paymentModes';
import { paymentOptionsAreEquivalent } from 'toolboxes/reuseable-logic/payment-options-calculator';

class TulipTotalDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        selectedPaymentPlan: null,
        allPaymentPlans: [],
        travelProtectionCost: 0,
        setSelectedPaymentOptionAction: () => {}
    };

    render() {
        if (
            !this.props.selectedPaymentPlan ||
            !this.props.allPaymentPlans.some(x => {
                return paymentOptionsAreEquivalent(this.props.selectedPaymentPlan, x);
            })
        ) {
            return (
                <div className="large-amount-container-container">
                    <div className="large-total-container">
                        <Fragment>
                            <div className="large-total" style={{ fontSize: isMobile() ? '2rem' : '4rem' }}>
                                {this.props.arrangementTotal}
                            </div>
                            <div className="large-total-descriptor">Total (tax included)</div>
                        </Fragment>
                    </div>
                </div>
            );
        }
        const dueToday = this.props.travelProtectionCost + this.props.selectedPaymentPlan.premium;
        let totalToBePaid =
            this.props.selectedPaymentPlan.premium * this.props.selectedPaymentPlan.years * 12 +
            this.props.travelProtectionCost;
        if (this.props.selectedPaymentPlan.paymentMode === PaymentMode.Single) {
            totalToBePaid = this.props.selectedPaymentPlan.premium + this.props.travelProtectionCost;
        }
        return (
            <div className="large-amount-container-container">
                <div className="large-total-container">
                    {this.props.selectedPaymentPlan.paymentMode === PaymentMode.Single ? (
                        <Fragment>
                            <div
                                className="large-total"
                                style={{ fontSize: isMobile() ? '2rem' : '4rem' }}
                                id="text-card-title-cart-total"
                            >
                                {formatMonetaryAmount(totalToBePaid.toString())}
                            </div>
                            <div className="large-total-descriptor">Total (tax included)</div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div
                                className="large-total"
                                style={{ fontSize: isMobile() ? '2rem' : '3rem' }}
                                id="text-monthly-premium"
                            >
                                {`${formatMonetaryAmount(this.props.selectedPaymentPlan.premium.toString())} Monthly`}
                            </div>
                            <div className="large-total-descriptor">
                                <div>Number of payments:</div>
                                <div>{this.props.selectedPaymentPlan.years * 12}</div>
                            </div>
                            {this.props.travelProtectionCost > 0 && (
                                <div className="large-total-descriptor">
                                    <div>Due today:</div>
                                    <div id="text-due-today">{formatMonetaryAmount(dueToday.toString())}</div>
                                </div>
                            )}
                            <div className="large-total-descriptor">
                                <div>Total cost:</div>
                                <div id="text-card-title-cart-total">
                                    {formatMonetaryAmount(totalToBePaid.toString())}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    travelProtectionCost: number;
    selectedPaymentPlan: PaymentOption;
    allPaymentPlans: PaymentOption[];
    arrangementTotal: string;
    setSelectedPaymentOptionAction: Function;
}

interface StateShape {
    // Shape of local state
}

export default TulipTotalDisplayComponent;
