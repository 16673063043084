import { connect } from 'react-redux';
import DemographicsInstructionsPageComponent from './DemographicsInstructionsPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { Disposition } from '../../../static/constants/enums/disposition';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';
import { getDispositionText } from 'toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    let preneedContractType = state.opportunity.preneedContractType;

    return {
        timing: state.opportunity.timing,
        disposition: getDispositionText(state),
        firstPage: firstPageCalculator(state, urlPaths.demographicsInstructions),
        preneedContractType: preneedContractType
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DemographicsInstructionsPageComponent);
