import {
    createAccessCode,
    fetchOpportunityIdByAccessCode
} from 'toolboxes/web-requests/specific-requests/load-opportunity-requests';

export function getOpportunityIdWithAccessCode(accessCode: string, callback: (result: any, error: any) => void) {
    fetchOpportunityIdByAccessCode(accessCode, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, undefined);
    });
}

export function runCreateAccessCode(opportunityId: string, callback: (result: any, error: any) => void) {
    createAccessCode(opportunityId, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        return callback(result, undefined);
    });
}
