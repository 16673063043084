import React, { Fragment } from 'react';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import DisclaimerAddressing from '../../../../static/models/disclaimers/DisclaimerAddressing';
import { Redirect } from 'react-router';

class VerifyPurchaserIdentityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setVerifyPurchaserIdentityAction: () => {},
        chooseAlternativeID: false
    };

    generateDisclaimerText() {
        return (
            <Fragment>
                <div>
                    If you would like to provide an alternative form of identification, you will need to provide a photo
                    of your Driver's License. By clicking "I Agree", we will provide directions on the next screen on
                    how to provide the photo and verify your identity. By clicking "I Agree", you understand that you
                    will not be able to finalize your plans until we process your Driver's License photo and verify your
                    identity.
                </div>
                <div>
                    If you wish to provide your SSN instead, please click "Cancel" to go back and provide us with your
                    SSN.
                </div>
            </Fragment>
        );
    }

    render() {
        if (this.props.chooseAlternativeID) {
            return <Redirect to={urlPaths.unavailableIdentityFailed} push />;
        }
        return (
            <StandardDisclaimerPageWrapper
                disclaimerTitle="Alternative Purchaser Identity Verification"
                acceptTo={urlPaths.unavailableIdentityFailed}
                acceptanceText="I agree"
                rejectionText="Cancel"
                rejectTo={urlPaths.ssnCapture}
                saveDisclaimerAddressingToRedux={this.props.setVerifyPurchaserIdentityAction}
                pageName="VerifyPurchaserIdentity"
                pageBack="true"
                pageNumber=""
            >
                {this.generateDisclaimerText()}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setVerifyPurchaserIdentityAction: (saveDisclaimer: DisclaimerAddressing) => void;
    chooseAlternativeID: boolean;
}

interface StateShape {
    // Shape of local state
}

export default VerifyPurchaserIdentityPageComponent;
