import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import {
    sendSaveBeneficiaryCheckData,
    sendSaveBeneficiaryIsPurchaser
} from '../../server-application-buffer/contact-data';
import urlPaths from '../../../static/constants/enums/urlPaths';

export function saveBeneficaryCheckData(state: GlobalStateShape): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveBeneficiaryIsPurchaser(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        },
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveBeneficiaryCheckData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        }
    ];
}
