import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { createOpportunityAndSaveId } from '../../server-application-buffer/general-opportunity-data';
import { updateProductsOnOpportunity } from '../../server-application-buffer/products-data';

export function createOpportunity(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    if (!state.opportunity.id) {
                        createOpportunityAndSaveId(state, dispatch, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    } else {
                        updateProductsOnOpportunity(state, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            return resolve();
                        });
                    }
                }),
            blocksPageStarts: [urlPaths.loadOpportunity]
        }
    ];
}
