export enum ContractSigningCredentialsRole {
    FUNERAL_PURCHASER = 'Funeral_Purchaser',
    FUNERAL_PROVIDER_REPRESENTATIVE = 'Funeral_Provider_Representative',
    AGENT = 'Agent'
}

export default interface ContractSigningCredentials {
    id: string;
    guidSecureKey: string;
    opportunityId: string;
    signingRole?: ContractSigningCredentialsRole;
    signingOrder: string;
    secureSigningLink: string;
    signingDate: string;
    signerContact: string;
    demoRecord: boolean | null;
    signingStatus: ContractSigningCredentialStatusOptions;
    helloSignSignatureId: string;
    redirectLink: string | null;
}

export enum ContractSigningCredentialStatusOptions {
    signed = 'signed',
    awaitingSignature = 'awaiting_signature',
    awaitingPreviousSigners = 'awaiting_previous_signers',
    void = 'void'
}
