// Action Type

import ContractSigningCredentials from 'static/models/ContractSigningCredentials';

export const SET_ALL_CONTRACT_SIGNING_CREDENTIALS = 'SET_ALL_CONTRACT_SIGNING_CREDENTIALS';
// Action
export function setAllContractSigningCredentials(newValue: ContractSigningCredentials[]) {
    return { type: SET_ALL_CONTRACT_SIGNING_CREDENTIALS, newValue };
}
// Reducer
export function setAllContractSigningCredentialsReducer(state: any, action: any): ContractSigningCredentials[] {
    switch (action.type) {
        case SET_ALL_CONTRACT_SIGNING_CREDENTIALS:
            return action.newValue;
        default:
            return state || [];
    }
}
