import React, { Fragment } from 'react';
import urlPaths from '../../../static/constants/enums/urlPaths';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import Product from '../../../static/models/Product';
import CartWrapper from '../../global-components/cart-component/CartWrapper';
import TravelProductsDisplayWrapper from '../../global-components/product-displays/travel-product-display-component/TravelProductsDisplayWrapper';
import FuneralHomeProductsDisplayWrapper from '../../global-components/product-displays/funeral-product-display-component/FuneralHomeProductsDisplayWrapper';
import ProductCreationModalWrapper from '../../modal-components/product-creation-modal-component/ProductCreationModalWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import MockLinkWrapper from '../../global-components/mock-link-component/MockLinkWrapper';
import { createAgentProduct } from '../../../toolboxes/server-application-buffer/products-data';
import { getAgentProductsForAccountId } from '../../../toolboxes/server-application-buffer/account-data';
import ErrorModalWrapper from '../../modal-components/error-modal-component/ErrorModalWrapper';

class AccountProductsDisplayComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            creatingNewProduct: false,
            inErrorState: false
        };
    }

    static defaultProps = {
        // Default prop values
        selectedFuneralProducts: [],
        isAgentDriven: true,
        accountId: '',
        setFuneralHomeProducts: () => {},
        homesteadersAgentNumber: '',
        agentId: '',
        canAddProducts: false,
        useSalesForceAgents: false
    };

    render() {
        return (
            <FullContentCenterWrapper
                alignTop
                metaPageName="ProductSelection"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                {this.state.inErrorState && (
                    <ErrorModalWrapper
                        onRequestClose={() => {
                            this.setState({
                                inErrorState: false
                            });
                        }}
                    />
                )}
                {this.state.creatingNewProduct && (
                    <ProductCreationModalWrapper
                        onRequestClose={() =>
                            this.setState({
                                creatingNewProduct: false
                            })
                        }
                        createProduct={product => {
                            createAgentProduct(
                                product,
                                this.props.homesteadersAgentNumber,
                                this.props.agentId,
                                this.props.accountId,
                                this.props.useSalesForceAgents,
                                (result, error) => {
                                    if (error) {
                                        this.setState({
                                            creatingNewProduct: false,
                                            inErrorState: true
                                        });
                                        return;
                                    }
                                    getAgentProductsForAccountId(this.props.accountId, (result, error) => {
                                        if (error) {
                                            this.setState({
                                                creatingNewProduct: false,
                                                inErrorState: true
                                            });
                                            return;
                                        }
                                        this.props.setFuneralHomeProducts(result);
                                        this.setState({
                                            creatingNewProduct: false
                                        });
                                    });
                                }
                            );
                        }}
                    />
                )}
                <PageCardWrapper extraCardWidth extraCardLength>
                    <PageCardTitleWrapper>Product Selection</PageCardTitleWrapper>
                    {this.props.canAddProducts && (
                        <PageCardTextWrapper>
                            If you don't see the product here that you'd like to include in the sale, you can create the
                            new product&nbsp;
                            <MockLinkWrapper onClick={() => this.setState({ creatingNewProduct: true })} id="here">
                                here
                            </MockLinkWrapper>
                            .
                        </PageCardTextWrapper>
                    )}
                    <FuneralHomeProductsDisplayWrapper />
                    {!this.props.isAgentDriven && (
                        <Fragment>
                            <PageCardTitleWrapper>Travel Product Selection</PageCardTitleWrapper>
                            <TravelProductsDisplayWrapper />
                        </Fragment>
                    )}
                </PageCardWrapper>
                <PageCardWrapper extraCardLength>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <PageCardTitleWrapper>Customer Cart</PageCardTitleWrapper>
                        <CartWrapper />
                        <ButtonLinkWrapper
                            buttonId="continue"
                            disabled={this.props.selectedFuneralProducts.length === 0}
                            to={urlPaths.loadOpportunity}
                            fullWidth
                        >
                            Continue
                        </ButtonLinkWrapper>
                    </div>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    selectedFuneralProducts: Product[];
    isAgentDriven: boolean;
    accountId: string;
    setFuneralHomeProducts: Function;
    homesteadersAgentNumber: string;
    agentId: string;
    canAddProducts: boolean;
    useSalesForceAgents: boolean;
}

interface StateShape {
    // Shape of local state
    creatingNewProduct: boolean;
    inErrorState: boolean;
}

export default AccountProductsDisplayComponent;
