import React from 'react';
import PaymentPageWrapper from './PaymentPageWrapper';
import PaymentPageTrustWarpper from './PaymentPageTrustWrapper';

class PaymentPageVersionPickerComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        isTrust: false
    };

    render() {
        if (this.props.isTrust) {
            return <PaymentPageTrustWarpper />;
        }
        return <PaymentPageWrapper />;
    }
}

export interface PropShape extends React.Props<any> {
    isTrust: boolean;
}

interface StateShape {
    // Shape of local state
}

export default PaymentPageVersionPickerComponent;
