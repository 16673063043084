import ApplicationStyling from '../../../models/ApplicationStyling';
import EfuneralLogoSrc from '../../../media/efuneral.png';
import { TabStyling } from '../../../models/TabStyling';
import EfuneralFavicon from '../../../media/favicons/efuneral-favicon.png';
const efuneralStyling: ApplicationStyling = {
    colors: {
        primaryColor: '#D3A02A',
        secondaryColor: '#596257',
        primaryContrastColor: '#FFFFFF',
        secondaryContrastColor: '#FFFFFF',
        headerBottomBorderColor: '#D3A02A ',
        headerBackgroundColor: '#FFFFFF',
        headerFontColor: '#D3A02A',
        footerBackgroundColor: '000000',
        footerFontColor: '#000000',
        sectionSeperatorColor: '#BDBDBD',
        bodyColor: '#313638',
        titleColor: '#596257',
        generalBackgroundColor: '#FFFFFF',
        formFieldsBackgroundColor: '#FFFFFF',
        formFieldsTextColor: '#000000',
        placeholderFullOpacity: false
    },
    fonts: {
        titleFontFamily: 'goudy-old-style, serif',
        bodyFontFamily: 'open-sans',
        titleAlignment: 'left',
        bodyAlignment: 'left',
        titleWeight: 'bold',
        bodyWeight: 'lighter',
        buttonFontFamily: 'open-sans',
        titleFontSize: '2.4rem',
        bodyFontSize: '1.4rem'
    },
    sizing: {
        headerLogoMaxHeight: '3.5rem'
    },
    logoUrl: EfuneralLogoSrc,
    useFormLabelsAsPlaceholders: false,
    showNameInTitle: true
};

export const efuneralTabConstants: TabStyling = {
    faviconUrl: EfuneralFavicon,
    tabName: 'eFuneral'
};

export default efuneralStyling;
