import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import { validateFuneralAgent } from '../../../toolboxes/redux-form/redux-form-validation';
import {
    normalizePhoneNumber,
    normalizeAgentCode,
    normalizeMarketCode
} from '../../../toolboxes/redux-form/redux-form-normalizers';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { Timing } from '../../../static/constants/enums/timing';
class FuneralAgentFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        funeralHomeProductId: ''
    };

    render() {
        return (
            <FormWrapper
                formName="representative"
                validate={validateFuneralAgent}
                initialValues={{
                    id: window.localStorage.getItem('representativeId'),
                    firstName: window.localStorage.getItem('respresentativeFirstName'),
                    lastName: window.localStorage.getItem('respresentativeLastName'),
                    phone: window.localStorage.getItem('respresentativePhone'),
                    email: window.localStorage.getItem('respresentativeEmail')
                }}
            >
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="id"
                    labelString="Agent Number"
                    normalize={normalizeAgentCode}
                    spacing={{
                        xs: 6
                    }}
                />
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="homesteadersMarketCode"
                    labelString="Agent Market Code"
                    spacing={{
                        xs: 6
                    }}
                    normalize={normalizeMarketCode}
                />
                <SelectWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="timing"
                    labelString="Product Code"
                    valueDisplays={[
                        {
                            value: Timing.IMMINENT,
                            display: 'AN'
                        },
                        {
                            value: Timing.FUTURE,
                            display: this.props.funeralHomeProductId
                        }
                    ]}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="phone"
                    labelString="Agent Phone Number"
                    normalize={normalizePhoneNumber}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="firstName"
                    labelString="Agent First Name"
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="lastName"
                    labelString="Agent Last Name"
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    extraFieldProps={{
                        disabled: !this.props.funeralHomeProductId
                    }}
                    formName="representative"
                    fieldPath="email"
                    labelString="Agent Email"
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    funeralHomeProductId: string;
}

interface StateShape {
    // Shape of local state
}

export default FuneralAgentFormComponent;
