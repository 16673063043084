import GlobalStateShape from '../../../redux/GlobalStateShape';
import { connect } from 'react-redux';
import PaymentPageVersionPickerComponent from './PaymentPageVersionPickerComponent';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    return {
        isTrust: state.opportunity.preneedContractType === PreneedContractType.Trust
    };
};

export default connect(mapStateToProps, null)(PaymentPageVersionPickerComponent);
