import { connect } from 'react-redux';
import PersonSsnFormComponent from './PersonSsnFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const ssnValueName = state.featureFlags.SSNToLast4Digits ? 'ssnLastFour' : 'ssn';
    const purchaserSSN = getFormData(state, 'purchaser', ssnValueName);
    const recipientSSN = getFormData(state, 'recipient', ssnValueName);
    // These values will be passed in as props
    return {
        SSNToLast4Digits: state.featureFlags.SSNToLast4Digits,
        showPurchaserErrorMessage: !!purchaserSSN,
        showRecipientErrorMessage: !!recipientSSN
    };
};

const mapDispatchToProps: any = (dispatch: Function, myProps: any) => {
    // These functions will be passed in as props
    return {};
};

const mergeProps: any = (stateProps: any, dispatchProps: any, myProps: any) => {
    return {
        ...myProps
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PersonSsnFormComponent);
