import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { calculateAge } from '../../reuseable-logic/age-calculator';
import { getFormData } from '../../redux-form/redux-form-data-access';
import { setOpportunityTimingAction } from '../../../redux/opportunity/timing';
import { Timing } from '../../../static/constants/enums/timing';
import {
    calculateAndUpdateOpportunityContractCodes,
    updateOpportunityMarketCode,
    updateOpportunityProductCode,
    updateOpportunityTiming
} from '../../server-application-buffer/general-opportunity-data';
import { dataWrapper } from '../../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import {
    calculateFundingProduct,
    calculateFundingProductWithExplicits
} from '../../reuseable-logic/funding-product-calculator';
import { setSelectedFundingProductAction } from '../../../redux/opportunity/selectedFundingProduct';
import { getInsuredBirthday } from '../../reuseable-logic/insured-calculator';
import productCodeMustBeCP, { cpMarketCode } from 'static/constants/stateSpecificLists/productCodeMustBeCP';
import { GeographicState } from '../../../static/constants/enums/states';
import noAnnuityStates from '../../../static/constants/stateSpecificLists/noAnnuityStates';

function calculateForcedTimings(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const age = dataWrapper(() => {
        const personConcerned = state.opportunity.recipient.sameAsPurchaser ? 'purchaser' : 'recipient';
        const birthdate = new Date(getFormData(state, personConcerned, 'birthdate'));
        const calculatedAge = calculateAge(birthdate);
        return calculatedAge;
    }, 0);
    if (age >= 99) {
        return [
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        updateOpportunityTiming(state, Timing.IMMINENT, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            dispatch(setOpportunityTimingAction(Timing.IMMINENT));
                            const selectedFundingProduct = calculateFundingProductWithExplicits(
                                getFormData(state, 'purchaser', 'state'),
                                getFormData(state, 'recipient', 'state'),
                                //@ts-ignore
                                state.account.state, // TODO update account state to enum
                                getInsuredBirthday(state),
                                Timing.IMMINENT,
                                state.account.fundingProducts,
                                state.opportunity.preneedContractType
                            );
                            dispatch(setSelectedFundingProductAction(selectedFundingProduct));
                            updateOpportunityProductCode(
                                state,
                                selectedFundingProduct.productId,
                                dispatch,
                                (result, error) => {
                                    if (error) {
                                        return reject(error);
                                    }
                                    return resolve();
                                }
                            );
                        });
                    }),
                blocksPageStarts: [urlPaths.disclaimerProduct]
            }
        ];
    }
    const purchaserState = getFormData(state, 'purchaser', 'state');

    /** DEV2020-1421 (https://efuneral.atlassian.net/browse/DEV2020-1421)
     * If the insured is greater than or equal to 65 in Florida, they should be switched to Insurance.
     * In addition, their Timing should switch to Future so that the correct contract is generated.
     **/
    if (purchaserState === GeographicState.FL && age >= 65) {
        return [
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        updateOpportunityTiming(state, Timing.FUTURE, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            dispatch(setOpportunityTimingAction(Timing.FUTURE));
                            const selectedFundingProduct = calculateFundingProductWithExplicits(
                                getFormData(state, 'purchaser', 'state'),
                                getFormData(state, 'recipient', 'state'),
                                //@ts-ignore
                                state.account.state, // TODO update account state to enum
                                getInsuredBirthday(state),
                                Timing.FUTURE,
                                state.account.fundingProducts,
                                state.opportunity.preneedContractType
                            );
                            dispatch(setSelectedFundingProductAction(selectedFundingProduct));

                            updateOpportunityProductCode(
                                state,
                                selectedFundingProduct.productId,
                                dispatch,
                                (result, error) => {
                                    if (error) {
                                        return reject(error);
                                    }
                                    return resolve();
                                }
                            );
                        });
                    }),
                blocksPageStarts: [urlPaths.disclaimerProduct]
            }
        ];
    }

    /** DEV2020-1637 (https://efuneral.atlassian.net/browse/DEV2020-1637)
     * Annuities/Imminent sales are not allowed in Washington. Therefore, they need to be converted to
     * insurance which requires the timing to be changed to Future.
     **/

    /**
     * DEV2020-1787 (https://efuneral.atlassian.net/browse/DEV2020-1787)
     * Annuities are not allowed in ME, MO, MT, NY, NH, ND. Therefore, they need to be converted to
     * insurance which requires the timing to be changed to Future. The current contract generator requires Timing to be
     * Future in order to pull insurance contracts. Rather than duplicate code, I updated DEV2020-1637 (above) to use the
     * noAnnuityStates constant.
     * LONG-TERM FIX: Re-evaluate how we set the product code/product type on Opportunity (should Opportunities with forced
     * insurance still be listed as imminents?) and update contract generator to no longer depend on Timing.
     */
    if (noAnnuityStates.includes(purchaserState) && state.opportunity.timing === Timing.IMMINENT) {
        return [
            {
                promiseGenerator: () =>
                    new Promise<void>((resolve, reject) => {
                        updateOpportunityTiming(state, Timing.FUTURE, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            dispatch(setOpportunityTimingAction(Timing.FUTURE));
                            const selectedFundingProduct = calculateFundingProductWithExplicits(
                                getFormData(state, 'purchaser', 'state'),
                                getFormData(state, 'recipient', 'state'),
                                //@ts-ignore
                                state.account.state, // TODO update account state to enum
                                getInsuredBirthday(state),
                                Timing.FUTURE,
                                state.account.fundingProducts,
                                state.opportunity.preneedContractType
                            );
                            dispatch(setSelectedFundingProductAction(selectedFundingProduct));
                            updateOpportunityProductCode(
                                state,
                                selectedFundingProduct.productId,
                                dispatch,
                                (result, error) => {
                                    if (error) {
                                        return reject(error);
                                    }
                                    return resolve();
                                }
                            );
                        });
                    }),
                blocksPageStarts: [urlPaths.disclaimerProduct]
            }
        ];
    }
    return [
        {
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    const selectedFundingProduct = calculateFundingProduct(state);
                    dispatch(setSelectedFundingProductAction(selectedFundingProduct));

                    updateOpportunityProductCode(state, selectedFundingProduct.productId, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });

                    const purchaserState = getFormData(state, 'purchaser', 'state');
                    const accountState: string = state.account.state;

                    let marketCode = state.opportunity.marketCode;

                    /**
                     * DEV2020-1956
                     * Unchanged code, but relevant to DEV2020-1956
                     * If purchaser state IS Michigan and Virginia AND Account State IS NOT Michigan or Virginia assign
                     * contract product code to CP and use Market Code ??.
                     * Else, if the purchaser state IS NOT Michigan or Virginia AND Account State IS Michigan or Virginia assign
                     * contract product code EZ and use Market Code ??.
                     * Else Pull both product code and market code from the funeral home.
                     */
                    /*
                     * DEV2020-2150
                     * Unchanged Code, but relevant to DEV2020-2150
                     * Updating logic from DEV2020-1956
                     * Now, only checking if purchaser state is Michigan or Virginia to assigned product code CP
                     * The account state does not matter when setting CP
                     */
                    if (productCodeMustBeCP.includes(purchaserState) || productCodeMustBeCP.includes(accountState)) {
                        marketCode = cpMarketCode;
                    }

                    updateOpportunityMarketCode(state, marketCode, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });

                    resolve();
                }),
            blocksPageStarts: [urlPaths.disclaimerProduct]
        }
    ];
}

export default calculateForcedTimings;

export function calculateContractCodes(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    calculateAndUpdateOpportunityContractCodes(state, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.disclaimerProduct, urlPaths.payment]
        }
    ];
}
