import React from 'react';
import { Alert } from '@material-ui/lab';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    borderless: {
        borderRadius: 3,
        border: 0
    }
});

const BlockscoreAlertComponent: React.FunctionComponent<PropShape> = ({ blockscoreQuestionScore = null }) => {
    const styles = useStyles();
    if (blockscoreQuestionScore === null) {
        return (
            <Alert
                classes={{ outlinedInfo: styles.borderless }}
                color="info"
                variant="outlined"
                icon={<RadioButtonUncheckedIcon />}
            >
                Identity Not Attempted
            </Alert>
        );
    }
    if (blockscoreQuestionScore < 60) {
        return (
            <Alert icon={<HighlightOffIcon />} severity="error">
                Identity Failed
            </Alert>
        );
    }

    return <Alert severity="success">Identity Passed</Alert>;
};

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    blockscoreQuestionScore?: number;
}

BlockscoreAlertComponent.propTypes = { blockscoreQuestionScore: PropTypes.number };

interface StateShape {
    // Shape of local state
}

export default BlockscoreAlertComponent;
