import { connect } from 'react-redux';
import TotalDisplayComponent from './TotalDisplayComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getCartTotal } from '../../../../../toolboxes/reuseable-logic/pricing-calculator';
import accountComponentSwitch from '../../../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import { TULIP_STYLING_KEY } from '../../../../../static/constants/styling-company-constants/companies/tulip';
import TulipTotalDisplayWrapper from '../../../../account-specific-components/accounts/tulip-cremation/total-display-component/TulipTotalDisplayWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        total: getCartTotal(state).toString()
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(TotalDisplayComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TULIP_STYLING_KEY]: TulipTotalDisplayWrapper
});
