import React from 'react';
import './StartPlanningPageComponent.css';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import { TextField } from '@material-ui/core';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import ButtonFunctionWrapper from 'components/global-components/buttons/button-function-component/ButtonFunctionWrapper';
import { Redirect } from 'react-router';
import { getOpportunityIdWithAccessCode } from 'toolboxes/server-application-buffer/access-code-data';

class StartPlanningPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            accessCode: '',
            showError: false,
            buttonActive: false,
            fetchingCompleted: false,
            path: ''
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.processRedirect = this.processRedirect.bind(this);
    }

    static defaultProps = {
        // Default prop values
    };

    handleInput(event) {
        let input = event.currentTarget.value;
        input = input.toUpperCase().trim();
        this.setState({ accessCode: input });
        this.setState({ buttonActive: input.length > 5 });
    }

    processRedirect(opportunityId: string) {
        if (opportunityId) {
            let calcPath = `${urlPaths.loadOpportunity.toString()}?id=${opportunityId}`;
            this.setState({ path: calcPath });
            this.setState({ fetchingCompleted: true });
        } else {
            this.setState({ showError: true });
        }
    }

    handleClick() {
        this.setState({ showError: false });
        getOpportunityIdWithAccessCode(this.state.accessCode, (result, error) => {
            this.processRedirect(result);
        });
    }

    render() {
        if (this.state.fetchingCompleted) {
            return <Redirect to={this.state.path} push />;
        }
        return (
            <FullContentCenterWrapper metaPageName="StartPlanning" metaPageNumber="1" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <div className="access-code-container">
                        <PageCardTitleWrapper>
                            <div id="text-title">Start Planning</div>
                        </PageCardTitleWrapper>
                        {this.state.showError && (
                            <h2 className="error">Unable to find your access code, please try again</h2>
                        )}
                        <TextField
                            id="input-access-code"
                            inputProps={{ style: { textAlign: 'center' } }}
                            variant="outlined"
                            onChange={this.handleInput}
                            placeholder="Enter your access code"
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ maxWidth: '420px', flexGrow: 1 }}>
                            <ButtonFunctionWrapper
                                disabled={!this.state.buttonActive}
                                buttonId="button-continue"
                                onClick={this.handleClick}
                            >
                                Continue
                            </ButtonFunctionWrapper>
                        </div>
                    </div>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
    accessCode: string;
    showError: boolean;
    buttonActive: boolean;
    fetchingCompleted: boolean;
    path: string;
}

export default StartPlanningPageComponent;
