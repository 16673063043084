import React from 'react';
import IdentityConfirmationFormWrapper from '../../form-components/identity-confirmation-form-component/IdentityConfirmationFormWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import { Redirect } from 'react-router';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { FundingProductType } from '../../../static/constants/enums/fundingProductType';

class PurchaserIdentityPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        funeralFundingProduct: FundingProductType.Insurance,
        purchaserFirstName: '',
        purchaserId: ''
    };

    render() {
        if (this.props.funeralFundingProduct === FundingProductType.Trust) {
            return <Redirect to={urlPaths.funeralContracts} />;
        }
        return (
            <FullContentCenterWrapper metaPageName="PurchaserIdentity" metaPageNumber="1" metaPagebackAvailable="false">
                <IdentityConfirmationFormWrapper
                    nextUrl={urlPaths.funeralContracts}
                    contactFirstName={this.props.purchaserFirstName}
                    contactId={this.props.purchaserId}
                    disclaimerAddressingLocaiton={this.props.setPurchaserPlaidIdentityConsentDisclaimerAddressingAction}
                />
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    funeralFundingProduct: FundingProductType;
    purchaserFirstName: string;
    purchaserId: string;
    setPurchaserPlaidIdentityConsentDisclaimerAddressingAction: () => void;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserIdentityPageComponent;
