import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const defaultErrorBorder = '1px solid red';

export const defaultTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#000000',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#000000',
            contrastText: '#FFFFFF'
        }
    },
    spacing: 8,
    shape: {
        borderRadius: 2
    },
    props: {
        MuiInput: {
            disableUnderline: true
        }
    },
    overrides: {
        MuiInput: {
            root: {
                '&$error': {
                    border: defaultErrorBorder
                },
                border: '1px solid #D5D8DC',
                borderRadius: '2px',
                boxShadow: '0px 2px 0px rgba(210, 213, 218, 0.4)',
                padding: '10px'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: '1.2rem'
            }
        }
    },
    typography: {
        h1: {},
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
        button: {}
    }
};
