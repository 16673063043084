import { combineReducers } from 'redux';
import { setTitleFontFamilyReducer } from './titleFontFamily';
import { setBodyFontFamilyReducer } from './bodyFontFamily';
import { setButtonFontFamilyReducer } from './buttonFontFamily';
import { setBodyAlignmentReducer } from './bodyAlignment';
import { setTitleAlignmentReducer } from './titleAlignment';
import { setTitleWeightReducer } from './titleWeight';
import { setBodyWeightReducer } from './bodyWeight';
import { setTitleFontSizeReducer } from './titleFontSize';
import { setBodyFontSizeReducer } from './bodyFontSize';
import { setButtonFontSizeReducer } from './bodyAlignment copy';

export default combineReducers({
    titleFontFamily: setTitleFontFamilyReducer,
    bodyFontFamily: setBodyFontFamilyReducer,
    buttonFontFamily: setButtonFontFamilyReducer,
    bodyAlignment: setBodyAlignmentReducer,
    titleAlignment: setTitleAlignmentReducer,
    titleWeight: setTitleWeightReducer,
    bodyWeight: setBodyWeightReducer,
    titleFontSize: setTitleFontSizeReducer,
    bodyFontSize: setBodyFontSizeReducer,
    buttonFontSize: setButtonFontSizeReducer
});
