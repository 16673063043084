import React from 'react';
import RecipientFormWrapper from '../../../../form-components/recipient-form-component/RecipientFormWrapper';
import ButtonLinkWrapper from '../../../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import { Button, Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import StandardDisclaimerPageWrapper from 'components/page-components/disclaimer-pages/standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import RelationshipToRecipientFormWrapper from 'components/form-components/relationship-to-recipient-form-component/RelationshipToRecipientFormWrapper';
import { Relationships } from 'static/constants/enums/relationships';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { earthStyling } from 'static/constants/styling-company-constants/companies/earth';

class RecipientPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            showModal: false,
            redirect: false
        };
    }

    static defaultProps = {
        // Default prop values
        validationFunction: p => {},
        setPurchaserRecipientSamePersonAction: () => {},
        setSameAsPurchaser: () => {},
        clearRecipient: () => {},
        isAtNeed: false,
        recipientComplete: false,
        purchaserAndRecipientAreSimilar: false,
        nextUrl: undefined,
        checkIfPurchaserAndRecipientAreSimilar: false,
        purchaserName: '',
        recipientName: '',
        relationshipToRecipient: undefined,
        stylingOptions: earthStyling
    };

    onContinue() {
        if (this.props.purchaserAndRecipientAreSimilar) {
            this.setState({ showModal: true });
        } else {
            this.setState({ redirect: true });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.props.nextUrl} />;
        }
        return (
            <FullContentCenterWrapper metaPageName="Recipient" metaPageNumber="1" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Your loved one</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please provide some information about the recipient of this plan.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper marginBottomOverride={this.props.stylingOptions.spacing?.xs}>
                        What is your relationship to the plan recipient?
                    </PageCardTextWrapper>
                    <RelationshipToRecipientFormWrapper />
                    {this.props.relationshipToRecipient &&
                        this.props.relationshipToRecipient !== Relationships.NotListed && (
                            <div style={{ marginTop: `-${this.props.stylingOptions.spacing?.innerBody}` }}>
                                <RecipientFormWrapper
                                    validationFunction={this.props.validationFunction}
                                    isAtNeed={this.props.isAtNeed}
                                />
                            </div>
                        )}
                    {this.props.relationshipToRecipient &&
                        (this.props.checkIfPurchaserAndRecipientAreSimilar ? (
                            <Button
                                onClick={() => this.onContinue()}
                                id="instructions-continue"
                                variant="contained"
                                color="primary"
                                aria-label="continue"
                                disabled={
                                    !(
                                        this.props.recipientComplete ||
                                        this.props.relationshipToRecipient === Relationships.NotListed
                                    )
                                }
                            >
                                Continue
                            </Button>
                        ) : (
                            <ButtonLinkWrapper
                                disabled={
                                    !(
                                        this.props.recipientComplete ||
                                        this.props.relationshipToRecipient === Relationships.NotListed
                                    )
                                }
                                to={this.props.nextUrl}
                                buttonId="Continue"
                                aria-label="continue"
                            >
                                Continue
                            </ButtonLinkWrapper>
                        ))}

                    <BackButtonLinkWrapper />

                    {this.state.showModal && (
                        <GeneralModalWrapper onRequestClose={() => this.setState({ redirect: true })}>
                            <StandardDisclaimerPageWrapper
                                acceptTo=""
                                rejectTo=""
                                acceptanceText="Yes"
                                rejectionText="No"
                                disclaimerTitle="Are these the same person?"
                                saveDisclaimerAddressingToRedux={newValue => {
                                    this.props.setPurchaserRecipientSamePersonAction(newValue);
                                }}
                                extraContentLength={false}
                                pageName=""
                                pageBack=""
                                pageNumber=""
                                allowBack={false}
                                rejectionSideAffect={() => this.setState({ redirect: true })}
                                acceptanceSideAffect={() => {
                                    this.props.clearRecipient();
                                    this.props.setSameAsPurchaser(true);
                                    this.setState({ redirect: true });
                                }}
                            >
                                <Typography>
                                    We noticed much of the information provided about {this.props.purchaserName} and{' '}
                                    {this.props.recipientName} match. Are these the same person?
                                </Typography>
                            </StandardDisclaimerPageWrapper>
                        </GeneralModalWrapper>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    validationFunction: (p: any) => any;
    setPurchaserRecipientSamePersonAction: Function;
    setSameAsPurchaser: Function;
    clearRecipient: Function;
    isAtNeed: boolean;
    recipientComplete: boolean;
    purchaserAndRecipientAreSimilar: boolean;
    purchaserName: string;
    recipientName: string;
    nextUrl: urlPaths;
    checkIfPurchaserAndRecipientAreSimilar: boolean;
    relationshipToRecipient?: Relationships;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    showModal: boolean;
    redirect: boolean;
}

export default RecipientPageComponent;
