import { connect } from 'react-redux';
import InvalidContractSigningCredentialsPageComponent from './InvalidContractSigningCredentialsPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        signingStatus: state.opportunity.contractSigning.activeContractSigningCredentials?.signingStatus
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvalidContractSigningCredentialsPageComponent);
