import { connect } from 'react-redux';
import IdentityConfirmationFormComponent, { PropShape } from './IdentityConfirmationFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, myProps: PropShape) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityConfirmationFormComponent);
