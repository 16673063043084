import { connect } from 'react-redux';
import BasicProductCardComponent from './BasicProductCardComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        canEditPrice: state.featureFlags.agentEditPrices && getFormData(state, 'representative', 'id')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicProductCardComponent);
