import { combineReducers } from 'redux';
import { setCardRejectedReducer } from './cardRejected';
import { setCardLastFourReducer } from './lastFour';
import { setCardTypeReducer } from './type';

export default combineReducers({
    cardRejected: setCardRejectedReducer,
    lastFour: setCardLastFourReducer,
    type: setCardTypeReducer
});
