import ValueDisplay from '../../static/models/ValueDisplay';

export function valueDisplaysFromArray(options: string[]): ValueDisplay[] {
    return options.map(x => {
        const value: ValueDisplay = {
            display: x,
            value: x
        };
        return value;
    });
}

export function valueDisplaysFromEnum(options: any): ValueDisplay[] {
    return Object.keys(options).map(key => {
        return {
            value: options[key],
            display: options[key]
        };
    });
}
