import PaymentOption from '../../../../static/models/PaymentOption';

// Action Type
export const SET_AVAILABLE_PAYMENT_OPTIONS = 'SET_AVAILABLE_PAYMENT_OPTIONS';
// Action
export function setAvailablePaymentOptionsAction(newValue: PaymentOption[]) {
    return { type: SET_AVAILABLE_PAYMENT_OPTIONS, newValue };
}
// Reducer
export function setAvailablePaymentOptionsReducer(state: any, action: any): PaymentOption[] {
    if (typeof state === 'undefined') {
        // Initial data
        return [];
    }
    switch (action.type) {
        case SET_AVAILABLE_PAYMENT_OPTIONS:
            return action.newValue;
        default:
            return state;
    }
}
