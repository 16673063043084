import { setOpportunityIdAction } from '../../redux/opportunity/id';
import { change } from 'redux-form';
import { setAccountIdAction } from '../../redux/account/id';
import { setIrrevocableAction } from '../../redux/yesNoAnswers/policyQuestions/irrevocable';
import { setHasCurrentPoliciesAction } from '../../redux/yesNoAnswers/policyQuestions/hasCurrentPolicies';
import { setReplacingCurrentPoliciesAction } from '../../redux/yesNoAnswers/policyQuestions/replacingCurrentPolicies';
import { setOpportunityProductsAction } from '../../redux/opportunity/products';
import { setBeneficaryProvidedAction } from '../../redux/yesNoAnswers/policyQuestions/beneficiaryProvided';
import { setPaymentInitiatedAction } from '../../redux/opportunity/pricing/paymentInitiated';
import { setAccountStateAction } from '../../redux/account/state';
import { setActiveStagedAction } from '../../redux/opportunity/activeStage';
import { setAccountPhoneAction } from '../../redux/account/phone';
import { setAnnuityInsuranceAddressingAction } from '../../redux/disclaimerData/disclaimerAddressings/annuityInsurance';
import { setIrrevocabilityAddressingAction } from '../../redux/disclaimerData/disclaimerAddressings/irrevocabilityConfirmation';
import { setPaymentPlanHealthAddressingAction } from '../../redux/disclaimerData/disclaimerAddressings/paymentPlanHealthImpact';
import { setAccountNameAction } from '../../redux/account/name';
import { setTaxRateAction } from '../../redux/account/taxRate';
import { dataWrapper } from './generalized-data-buffer-tools/data-wrapper';
import { translateYesNoStringIntoBoolean } from './generalized-data-buffer-tools/yes-no-translator';
import GlobalStateShape from '../../redux/GlobalStateShape';
import {
    fetchFullOpportunity,
    fetchOpportunityData
} from '../web-requests/specific-requests/load-opportunity-requests';
import { getFormData } from '../redux-form/redux-form-data-access';
import getCssClassProperty from '../reuseable-logic/css-ast-parser';
import { setStylingPrimaryColorAction } from '../../redux/stylingOptions/colors/primaryColor';
import { setStylingSecondaryColorAction } from '../../redux/stylingOptions/colors/secondaryColor';
import { setStylingLogoUrlAction } from '../../redux/stylingOptions/logoUrl';
import { setStylingHeaderBottomBorderColorAction } from '../../redux/stylingOptions/colors/headerBottomBorderColor';
import { setStylingTitleColorAction } from '../../redux/stylingOptions/colors/titleColor';
import { setBodyFontFamilyAction } from '../../redux/stylingOptions/fonts/bodyFontFamily';
import { setTitleFontFamilyAction } from '../../redux/stylingOptions/fonts/titleFontFamily';
import { setAccountSubdomainAction } from '../../redux/account/subdomain';
import { setButtonFontFamilyAction } from '../../redux/stylingOptions/fonts/buttonFontFamily';
import { setSectionSeperatorColorAction } from '../../redux/stylingOptions/colors/sectionSeperatorColor';
import { setStylingFooterBackgroundColorAction } from '../../redux/stylingOptions/colors/footerBackgroundColor';
import { setStylingPrimaryConrastColorAction } from '../../redux/stylingOptions/colors/primaryConrastColor';
import { setStylingSecondaryConrastColorAction } from '../../redux/stylingOptions/colors/secondaryConrastColor';
import getStylesForCompany, {
    getStylingKeyOverrideForApiBrand,
    getTabStylesForCompany
} from '../../static/constants/styling-company-constants/company-styling-router';
import { setBodyAlignmentAction } from '../../redux/stylingOptions/fonts/bodyAlignment';
import { setTitleAlignmentAction } from '../../redux/stylingOptions/fonts/titleAlignment';
import { setBodyWeightAction } from '../../redux/stylingOptions/fonts/bodyWeight';
import { setTitleWeightAction } from '../../redux/stylingOptions/fonts/titleWeight';
import PaymentOption from '../../static/models/PaymentOption';
import { setSelectedPaymentOptionAction } from '../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { setTabFavicon } from '../basic-styling/tab-style-setters';
import { setOpportunityRecipientSameAsPurchaserAction } from '../../redux/opportunity/recipient/sameAsPurchaser';
import { setOpportunityPurchaserIdAction } from '../../redux/opportunity/purchaser/id';
import { setOpportunityRecipientIdAction } from '../../redux/opportunity/recipient/id';
import { setOpportunityHidePriceListDisclaimerAction } from 'redux/opportunity/hidePriceListDisclaimer';
import { setOpportunityHideAnnuityDisclaimerAction } from 'redux/opportunity/hideAnnuityDisclaimer';
import { setOpportunityHideDemographicsDisclaimerAction } from 'redux/opportunity/hideDemographicsDisclaimer';
import { setOpportunityHideInsuranceDisclaimerAction } from 'redux/opportunity/hideInsuranceDisclaimer';
import { setOpportunityHideStateFilingFeeAction } from 'redux/opportunity/hideStateFilingFee';
import { setOpportunityTimingAction } from '../../redux/opportunity/timing';
import { setTravelOpportunityIdAction } from '../../redux/travelOpportunity/id';
import { setTravelProductsAction } from '../../redux/travelOpportunity/products';
import { setPaymentIsPredeterminedAction } from '../../redux/opportunity/pricing/isPredetermined';
import { setRecipientConsentAction } from '../../redux/disclaimerData/disclaimerAddressings/recipientConsent';
import { setWasBedriddenOrAiledAction } from '../../redux/yesNoAnswers/healthHistoryQuestions/wasBedriddenOrAiledInLastFiveYears';
import { setIsBedriddenOrAiledAction } from '../../redux/yesNoAnswers/healthHistoryQuestions/isBedriddenOrAiled';
import { setRecipientIsUnavailableAction } from '../../redux/opportunity/recipient/isUnavailable';
import { setRecipientUnavailalbeRatesDisclaimerAction } from '../../redux/disclaimerData/disclaimerAddressings/recipientUnavailableRates';
import { setSinglePaySettingAddressingAction } from '../../redux/disclaimerData/disclaimerAddressings/singlePaySetting';
import { setRecipientExistancePredeterminedAction } from '../../redux/opportunity/recipient/existancePredetermined';
import { setOpportunityDispositionAction } from '../../redux/opportunity/disposition';
import { setRepresentativeDidHandoffAction } from '../../redux/representative/didHandoff';
import { setCardLastFourAction } from '../../redux/opportunity/pricing/creditCardInfo/lastFour';
import { setCardTypeAction } from '../../redux/opportunity/pricing/creditCardInfo/type';
import { setPurchaserPhoneVerifiedAction } from '../../redux/opportunity/purchaser/phoneVerified';
import { OpportunityStage } from '../../static/constants/opportunityStages';
import { PaymentMethod } from '../../static/constants/enums/paymentMethods';
import Product from '../../static/models/Product';
import { setAccountFundingProductsAction } from '../../redux/account/fundingProducts';
import FundingProduct from '../../static/models/FundingProduct';
import { setSelectedFundingProductAction } from '../../redux/opportunity/selectedFundingProduct';
import {
    calculateFundingProductWithExplicits,
    calculateHomesteadersFundingProductsForAccount
} from '../reuseable-logic/funding-product-calculator';
import { setStateFilingFeeAction } from '../../redux/disclaimerData/disclaimerAddressings/stateFilingFee';
import { setPriceListDisclaimerAddressingAction } from '../../redux/disclaimerData/disclaimerAddressings/priceList';
import { setStylingHeaderFontColorAction } from '../../redux/stylingOptions/colors/headerFontColor';
import { setStylingFooterFontColorAction } from '../../redux/stylingOptions/colors/footerFontColor';
import { setAccountWebsiteAction } from '../../redux/account/website';
import { setStylingLoadingCompleteAction } from '../../redux/stylingOptions/loadingComplete';
import { setAccountFacebookUrlAction } from '../../redux/account/facebookUrl';
import { setAccountTwitterUrlAction } from '../../redux/account/twitterUrl';
import { setAccountInstagramUrlAction } from '../../redux/account/instagramUrl';
import { setAccountYelpUrlAction } from '../../redux/account/yelpUrl';
import { setAccountAddressAction } from '../../redux/account/address';
import { setAccountCityAction } from '../../redux/account/city';
import { setAccountFuneralHomeNumberAction } from '../../redux/account/funeralHomeNumber';
import { setAccountZipAction } from '../../redux/account/zip';
import { setDisableACHAction } from 'redux/account/disableACH';
import { setStylingHeaderBackgroundColorAction } from '../../redux/stylingOptions/colors/headerBackgroundColor';
import { setAccountMediaDomainAction } from '../../redux/account/mediaDomain';
import getEnvironment from '../../env-config/get-environment';
import { setStylingKeyAction } from '../../redux/account/stylingKey';
import { setStylingGeneralBackgroundColorAction } from 'redux/stylingOptions/colors/generalBackgroundColor';
import { setStopPageDetailAction } from 'redux/opportunity/stopPageDetail';
import { setVerifyPurchaserIdentityAction } from 'redux/disclaimerData/disclaimerAddressings/verifyPurchaserIdentity';
import { setTrustFundedPrearrangementAction } from 'redux/disclaimerData/disclaimerAddressings/trustFundedPrearrangement';
import { setOpportunityPreneedContractTypeAction } from 'redux/opportunity/preneedContractType';
import { setAccountEmailOnBehalfAction } from 'redux/account/emailOnBehalf';
import { setAccountEmailSentFromEfuneralAction } from 'redux/account/emailSentFromEfuneral';
import { TULIP_STYLING_KEY } from 'static/constants/styling-company-constants/companies/tulip';
import { setVitalStatisticsUrlAction } from 'redux/urlPaths/vitalStatistics';
import { setOpportunityUpdateMarketCodeAction } from 'redux/opportunity/marketCode';
import homesteadersProductCodeToContractNumberMappings from 'static/constants/homesteadersProductCodeToContractNumberMappings';
import { setEndOfExperienceOptionAction } from 'redux/opportunity/endOfExperienceOption';
import { setEndOfExperienceReturnLinkAction } from 'redux/opportunity/endOfExperienceReturnLink';
import {
    EndOfExperienceOptions,
    requiresEndOfExperienceReturnLink
} from 'static/constants/enums/endOfExperienceOptions';
import { setOpportunityPurchaserSignedAction } from 'redux/opportunity/purchaserSigned';
import { Timing } from 'static/constants/enums/timing';
import { setOpportunityReferralAccountPhoneAction } from 'redux/opportunity/referralAccountPhone';
import { setOpportunityRecipientIdentityVerificationCompletedAction } from '../../redux/opportunity/recipient/identityVerificationCompleted';
import { setOpportunityMileageCostAction } from '../../redux/opportunity/mileageCharge';
import { setAccountHellosignKeyAction } from 'redux/account/hellosignClientKey';
import { setAccountHostedDomainAction } from 'redux/account/hostedDomain';
import { setAccountDemoHostedDomainAction } from 'redux/account/demoHostedDomain';
import { setStylingShowNameInTitleAction } from 'redux/stylingOptions/showNameInTitle';
import { setBeneficaryIsPurchaserAction } from 'redux/yesNoAnswers/policyQuestions/beneficiaryIsPurchaser';
import { setFeatureVitalStatistics } from '../../redux/account/featureVitalStatistics';
import { setAccountDisablePaymentPlansWhenBelow } from '../../redux/account/disablePaymentPlansWhenBelow';
import { setTitleFontSizeAction } from 'redux/stylingOptions/fonts/titleFontSize';
import { setUseFormLabelsAsPlaceholdersAction } from 'redux/stylingOptions/useFormLabelsAsPlaceholders';
import { setStylingBodyColorAction } from 'redux/stylingOptions/colors/bodyColor';
import { setBodyFontSizeAction } from 'redux/stylingOptions/fonts/bodyFontSize';
import { setStylingFormFieldsBackgroundColorAction } from 'redux/stylingOptions/colors/formFieldsBackgroundColor';
import { setStylingFormFieldsTextColorAction } from 'redux/stylingOptions/colors/formFieldsTextColor';
import { setSpacingAboveContinueButtonAction } from 'redux/stylingOptions/spacing/aboveContinueButton';
import { setSpacingAboveBodyContentAction } from 'redux/stylingOptions/spacing/aboveBodyContent';
import { setSpacingAbovePageSubitleAction } from 'redux/stylingOptions/spacing/abovePageSubtitle';
import { setSpacingAbovePageTitleAction } from 'redux/stylingOptions/spacing/abovePageTitle';
import { setSpacingInnerBodyAction } from 'redux/stylingOptions/spacing/innerBody';
import { setSpacingXSAction } from 'redux/stylingOptions/spacing/xs';
import { setSpacingLGAction } from 'redux/stylingOptions/spacing/lg';
import { setSpacingMDAction } from 'redux/stylingOptions/spacing/md';
import { setSpacingSMAction } from 'redux/stylingOptions/spacing/sm';
import { setSpacingXLAction } from 'redux/stylingOptions/spacing/xl';
import { setSpacingXXLAction } from 'redux/stylingOptions/spacing/xxl';
import { setButtonFontSizeAction } from 'redux/stylingOptions/fonts/bodyAlignment copy';
import { setAccountGoogleTagManagerId } from 'redux/account/googleTagManagerId';
import { setOpportunityHidePaymentHoldDisclaimerAction } from 'redux/opportunity/hidePaymentHoldDisclaimer';
import { setOpportunityPurchaserPhoneNumberAction } from '../../redux/opportunity/purchaser/phoneNumber';
import { setFeatureDisableCreditCardPayments } from '../../redux/account/featureDisableCreditCardPayments';
import { setFeatureEnhancedWebhooksAction } from 'redux/account/featureEnhancedWebhooks';
import { setAccountNotificationEmail } from '../../redux/account/notificationEmail';
import { FundingProductType } from 'static/constants/enums/fundingProductType';
import { setOpportunityReturnLaterLinkAction } from 'redux/opportunity/returnLaterLink';
import { setOpportunityCommissionOptionAction } from 'redux/opportunity/commissionOption';
import { setStylingPlaceholderFullOpacityAction } from 'redux/stylingOptions/colors/placeholderFullOpacity';
import { setRecipientSsnProvidedAction } from 'redux/opportunity/recipient/ssnProvided';
import { setPurchaserSsnProvidedAction } from 'redux/opportunity/purchaser/ssnProvided';
import { setAccountEfuneralServiceTierAction } from 'redux/account/efuneralServiceTier';
import { EfuneralServiceTier } from 'static/constants/enums/efuneralServiceTier';
import { setOpportunityRecipientIdentityVerificationPlaidAction } from 'redux/opportunity/recipient/identityVerificationPlaid';

function storeServerOpportunityData(opportunityData: any, dispatch: Function): void {
    dispatch(setActiveStagedAction(opportunityData.stageName));

    if (!opportunityData.opportunityId) {
        return;
    }

    /* DEV2020-1826 if the end of experience option isn't set, it should default to VitalStatistics.
     * If an option requiring a link is set but no link exists, it should also be set to VitalStatistics.
     */
    if (!opportunityData.endOfExperienceOption) {
        opportunityData.endOfExperienceOption = EndOfExperienceOptions.PurchaseProcessing;
    }
    if (
        requiresEndOfExperienceReturnLink(opportunityData.endOfExperienceOption) &&
        !opportunityData.endOfExperienceReturnLink
    ) {
        dispatch(setEndOfExperienceOptionAction(EndOfExperienceOptions.PurchaseProcessing));
    } else {
        dispatch(setEndOfExperienceOptionAction(opportunityData.endOfExperienceOption));
    }

    /* DEV2020-1826 right now, the vital statistics URL is being manipulated to be able to navigate to a custom URL.
     * That logic will stay and be feature flagged for now, but in the future, we should look to the end of experience link instead.
     */
    if (opportunityData.endOfExperienceReturnLink) {
        dispatch(setVitalStatisticsUrlAction(opportunityData.endOfExperienceReturnLink)); // TODO should be removed with DEV2020-1830
        dispatch(setEndOfExperienceReturnLinkAction(opportunityData.endOfExperienceReturnLink));
    }

    dispatch(setOpportunityPreneedContractTypeAction(opportunityData.preneedContractType));
    dispatch(setOpportunityPurchaserSignedAction(opportunityData.purchaserSigned));

    dispatch(setOpportunityIdAction(dataWrapper(() => opportunityData.opportunityId)));
    if (
        opportunityData.closedToUser &&
        opportunityData.stageName !== OpportunityStage.ClosedWon &&
        !opportunityData.purchaserSigned && // DEV2020-1478 - purchaserSigned replaces the AgentSignContract stage
        opportunityData.stageName !== OpportunityStage.AgentSignContract
    ) {
        dispatch(setActiveStagedAction(OpportunityStage.ClosedLost));
    } else {
        dispatch(setActiveStagedAction(opportunityData.stageName));
    }
    dispatch(setOpportunityTimingAction(dataWrapper(() => opportunityData.timing, '')));

    if (opportunityData.closedToUser) {
        return;
    }

    if (opportunityData.returnLaterLink) {
        dispatch(setOpportunityReturnLaterLinkAction(opportunityData.returnLaterLink));
    }

    const representativeId = window.localStorage.getItem('representativeId');
    if (!representativeId) {
        if (opportunityData.externalPartnerRepresentativeId) {
            dispatch(setRepresentativeDidHandoffAction(true));
        }
    } else {
        dispatch(change('representative', 'id', representativeId));
    }

    //Set irrevocable
    const irrevocable = translateYesNoStringIntoBoolean(dataWrapper(() => opportunityData.irrevocable));
    if ([true, false].includes(irrevocable)) {
        dispatch(setIrrevocableAction(irrevocable));
    }

    // Set has existing policy
    const hasExistingPolicy = translateYesNoStringIntoBoolean(
        dataWrapper(() => opportunityData.replacementExistingCoverage)
    );
    if ([true, false].includes(hasExistingPolicy)) {
        dispatch(setHasCurrentPoliciesAction(hasExistingPolicy));
    }

    // Set replacing existing policy
    const replacingCurrentPolicies = translateYesNoStringIntoBoolean(
        dataWrapper(() => opportunityData.replacementReplacing)
    );
    if ([true, false].includes(replacingCurrentPolicies)) {
        dispatch(setReplacingCurrentPoliciesAction(replacingCurrentPolicies));
    }

    //Set beneficiary provided
    const beneficiaryProvided = translateYesNoStringIntoBoolean(dataWrapper(() => opportunityData.beneficiaryProvided));
    if ([true, false].includes(beneficiaryProvided)) {
        dispatch(setBeneficaryProvidedAction(beneficiaryProvided));
    }

    //Set was bedridden or ailed
    const wasBedriddenOrAiled = translateYesNoStringIntoBoolean(
        dataWrapper(() => opportunityData.recipient.hasAilment)
    );
    if ([true, false].includes(wasBedriddenOrAiled)) {
        dispatch(setWasBedriddenOrAiledAction(wasBedriddenOrAiled));
    }

    //Set is bedridden or ailed
    const isBedriddenOrAiled = translateYesNoStringIntoBoolean(
        dataWrapper(() => opportunityData.recipient.hasCurrentHealthProblem)
    );
    if ([true, false].includes(isBedriddenOrAiled)) {
        dispatch(setIsBedriddenOrAiledAction(isBedriddenOrAiled));
    }

    //Set Funeral Home Agent and Representative Emails
    dispatch(
        change(
            'purchaser',
            'funeralHomeAgentEmail',
            dataWrapper(() => opportunityData.funeralHomeAgentEmail)
        )
    );
    dispatch(
        change(
            'purchaser',
            'funeralHomeRepresentativeEmail',
            dataWrapper(() => opportunityData.funeralHomeRepresentativeEmail)
        )
    );

    //Set Recipient Unavailable
    dispatch(setRecipientIsUnavailableAction(dataWrapper(() => opportunityData.incapacitatedRates)));

    // TODO ================================================================================================
    // set contact to contact data if exists, otherwise use landingdata from opportunity
    dispatch(setOpportunityDispositionAction(dataWrapper(() => opportunityData.disposition, '')));
    const contactTypeMappings = [
        {
            formName: 'purchaser',
            givenName: 'purchaser'
        },
        {
            formName: 'recipient',
            givenName: 'recipient'
        }
    ];
    const contactFieldMappings = [
        {
            formName: 'firstName',
            givenName: 'firstName'
        },
        {
            formName: 'lastName',
            givenName: 'lastName'
        },
        {
            formName: 'phone',
            givenName: 'phoneNumber'
        },
        {
            formName: 'birthdate',
            givenName: 'birthDate'
        },
        {
            formName: 'ssn',
            givenName: 'ssn'
        },
        {
            formName: 'gender',
            givenName: 'gender'
        },
        {
            formName: 'email',
            givenName: 'email'
        },
        {
            formName: 'address',
            givenName: 'address'
        },
        {
            formName: 'city',
            givenName: 'city'
        },
        {
            formName: 'state',
            givenName: 'stateCode'
        },
        {
            formName: 'zipCode',
            givenName: 'zipCode'
        }
    ];

    contactTypeMappings.forEach(typeMapping => {
        contactFieldMappings.forEach(fieldMapping => {
            dispatch(
                change(
                    typeMapping.formName,
                    fieldMapping.formName,
                    dataWrapper(() => opportunityData[typeMapping.givenName][fieldMapping.givenName], '')
                )
            );
        });
        if (dataWrapper(() => opportunityData[typeMapping.givenName].lastName) === '[not provided]') {
            dispatch(change(typeMapping.formName, 'lastName', ''));
        }
    });
    if (opportunityData.beneficiary?.Id && opportunityData.beneficiary.Id === opportunityData.purchaser.id) {
        dispatch(setBeneficaryIsPurchaserAction(true));
    }
    dispatch(
        change(
            'beneficiary',
            'firstName',
            dataWrapper(() => opportunityData.beneficiary.FirstName, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'lastName',
            dataWrapper(() => opportunityData.beneficiary.LastName, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'phone',
            dataWrapper(() => opportunityData.beneficiary.Phone, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'birthdate',
            dataWrapper(() => opportunityData.beneficiary.Birthdate, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'ssn',
            dataWrapper(() => opportunityData.beneficiary.Social_Security_Number__c, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'gender',
            dataWrapper(() => opportunityData.beneficiary.Gender__c, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'email',
            dataWrapper(() => opportunityData.beneficiary.Email, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'address',
            dataWrapper(() => opportunityData.beneficiary.MailingStreet, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'city',
            dataWrapper(() => opportunityData.beneficiary.MailingCity, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'state',
            dataWrapper(() => opportunityData.beneficiary.MailingState, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'zipCode',
            dataWrapper(() => opportunityData.beneficiary.MailingPostalCode, '')
        )
    );
    dispatch(
        change(
            'beneficiary',
            'relationshipToRecipient',
            dataWrapper(() => opportunityData.beneficiary.Relationship_To_Insured__c, '')
        )
    );
    if (dataWrapper(() => opportunityData.relationship)) {
        dispatch(
            change(
                'purchaser',
                'relationshipToRecipient',
                dataWrapper(() => opportunityData.relationship, '')
            )
        );
    }

    if (dataWrapper(() => opportunityData.referralAgentMarketCode)) {
        dispatch(
            change(
                'representative',
                'homesteadersMarketCode',
                dataWrapper(() => opportunityData.referralAgentMarketCode)
            )
        );
    }

    if (opportunityData.referralAccountPhone) {
        dispatch(setOpportunityReferralAccountPhoneAction(opportunityData.referralAccountPhone));
    }

    dispatch(setOpportunityPurchaserIdAction(dataWrapper(() => opportunityData.purchaser.id)));
    dispatch(setPurchaserSsnProvidedAction(dataWrapper(() => opportunityData.purchaser.ssnProvided, false)));
    dispatch(setOpportunityRecipientIdAction(dataWrapper(() => opportunityData.recipient.id)));
    dispatch(setRecipientSsnProvidedAction(dataWrapper(() => opportunityData.recipient.ssnProvided, false)));

    dispatch(
        setOpportunityRecipientIdentityVerificationCompletedAction(
            dataWrapper(() => opportunityData.recipient.identityVerificationCompleted)
        )
    );
    if (opportunityData?.recipient?.externalIdentityStatus) {
        dispatch(
            setOpportunityRecipientIdentityVerificationPlaidAction(
                dataWrapper(() => opportunityData.recipient.externalIdentityStatus)
            )
        );
    }

    dispatch(setStopPageDetailAction(dataWrapper(() => opportunityData.stopPageDetail)));

    dispatch(setPurchaserPhoneVerifiedAction(dataWrapper(() => opportunityData.purchaser.phoneVerified)));

    dispatch(
        setTravelOpportunityIdAction(dataWrapper(() => opportunityData.travelProtectionOpportunity.opportunityId, ''))
    );
    dispatch(
        setTravelProductsAction(
            dataWrapper(() => opportunityData.travelProtectionOpportunity.selectedProducts || [], [])
        )
    );
    const loadedProducts: Product[] = opportunityData.selectedProducts.map(product => {
        return {
            name: product.name,
            category: product.category,
            subcategory: product.subCategory,
            productId: product.id,
            quantity: product.quantity || 0,
            price: product.price,
            taxIsApplicable: product.salesTaxApplicable,
            explicitTax: product.explicitSalesTax,
            imageUrl: product.imageURL,
            salesDescription: product.salesDescription,
            included: product.included
        };
    });
    dispatch(setOpportunityProductsAction(loadedProducts));

    dispatch(setPaymentInitiatedAction(opportunityData.paymentInitiated));

    function resolvePaymentOptions(selectedOption: string): PaymentMethod {
        if (selectedOption === 'ACH') {
            return PaymentMethod.ACH;
        } else {
            return PaymentMethod.CreditCard;
        }
    }

    const selectedPaymentPlan: PaymentOption = dataWrapper(() => {
        const plan: PaymentOption = {
            years: parseInt(opportunityData.paymentPlan.yearsPayable),
            months: parseInt(opportunityData.paymentPlan.yearsPayable) * 12,
            faceAmount: opportunityData.paymentPlan.faceAmount,
            premium: opportunityData.paymentPlan.premiumAmount,
            amountOfFirstPayment: opportunityData.paymentPlan.amountOfFirstPayment,
            paymentMode: opportunityData.paymentPlan.mode.toLowerCase(),
            paymentMethod: resolvePaymentOptions(opportunityData.paymentPlan.paymentMethod)
        };
        return plan;
    });
    if (selectedPaymentPlan) {
        dispatch(setSelectedPaymentOptionAction(selectedPaymentPlan));
    }
    dispatch(setCardLastFourAction(dataWrapper(() => opportunityData.creditCard.lastFourDigits, '')));
    dispatch(setCardTypeAction(dataWrapper(() => opportunityData.creditCard.type)));

    dispatch(setPaymentIsPredeterminedAction(dataWrapper(() => opportunityData.paymentOptionPredetermined, false)));
    if (opportunityData.timing === Timing.IMMEDIATE) {
        dispatch(setRecipientExistancePredeterminedAction(true));
        dispatch(setOpportunityRecipientSameAsPurchaserAction(false));
    } else {
        dispatch(
            setRecipientExistancePredeterminedAction(
                dataWrapper(() => opportunityData.recipientExistencePredetermined, false)
            )
        );
        const recipientSameAsPurchaser = dataWrapper(() => {
            return opportunityData.recipient.id === opportunityData.purchaser.id;
        });
        dispatch(setOpportunityRecipientSameAsPurchaserAction(recipientSameAsPurchaser));
        if (recipientSameAsPurchaser) {
            dispatch(setRecipientSsnProvidedAction(dataWrapper(() => opportunityData.purchaser.ssnProvided, false)));
        }
    }
    dispatch(setAccountIdAction(dataWrapper(() => opportunityData.funeralHomeAccountId)));
    dispatch(setAccountSubdomainAction(opportunityData.accountData.subdomain));
    dispatch(setAccountStateAction(opportunityData.accountData.state));
    dispatch(setAccountPhoneAction(opportunityData.accountData.phoneNumber));
    dispatch(setAccountNameAction(opportunityData.accountData.name));
    dispatch(setAccountWebsiteAction(opportunityData.accountData.website));
    dispatch(setAccountFacebookUrlAction(opportunityData.accountData.facebookUrl));
    dispatch(setAccountTwitterUrlAction(opportunityData.accountData.twitterUrl));
    dispatch(setAccountInstagramUrlAction(opportunityData.accountData.instagramUrl));
    dispatch(setAccountYelpUrlAction(opportunityData.accountData.yelpUrl));
    dispatch(setAccountAddressAction(opportunityData.accountData.address));
    dispatch(setAccountCityAction(opportunityData.accountData.city));
    dispatch(setAccountFuneralHomeNumberAction(opportunityData.accountData.funeralHomeNumber));
    dispatch(setAccountZipAction(opportunityData.accountData.zip));
    dispatch(setAccountEmailOnBehalfAction(opportunityData.accountData.sendOnBehalfEmail));
    dispatch(setAccountEmailSentFromEfuneralAction(opportunityData.accountData.feature_SendEmailFromEfuneral));
    dispatch(setAccountGoogleTagManagerId(opportunityData.accountData.googleTagManagerId));
    dispatch(setAccountNotificationEmail(opportunityData.accountData.notificationEmail));
    dispatch(setAccountEfuneralServiceTierAction(opportunityData.accountData.efuneralServiceTier));

    if (opportunityData.accountData.disablePaymentPlansWhenBelow) {
        dispatch(
            setAccountDisablePaymentPlansWhenBelow(parseFloat(opportunityData.accountData.disablePaymentPlansWhenBelow))
        );
    }

    dispatch(setDisableACHAction(opportunityData.accountData.feature_Disable_ACH_Payment));
    dispatch(setFeatureVitalStatistics(opportunityData.accountData.feature_DeathCertificates));
    dispatch(setFeatureDisableCreditCardPayments(opportunityData.accountData.feature_DisableCreditCardPayments));
    dispatch(setFeatureEnhancedWebhooksAction(opportunityData.accountData.featureEnhancedWebhooks));

    dispatch(setOpportunityMileageCostAction(opportunityData.mileageCost));

    const salesTax =
        opportunityData.accountData.salesTax > 1
            ? opportunityData.accountData.salesTax / 100
            : opportunityData.accountData.salesTax;
    dispatch(setTaxRateAction(salesTax));

    // Assemble the account's funding products
    const fundingProduct: FundingProduct = {
        productId: opportunityData.contractCodes?.contractProductCode,
        contractId: opportunityData.contractCodes?.contractId,
        productType: opportunityData.contractCodes?.funeralFundingProduct,
        provider: opportunityData.contractCodes?.funeralFundingProvider
    };

    dispatch(setOpportunityUpdateMarketCodeAction(opportunityData.contractCodes?.contractMarketCode));
    dispatch(setOpportunityTimingAction(opportunityData.contractCodes?.timing));
    dispatch(setSelectedFundingProductAction(fundingProduct));
    dispatch(setOpportunityCommissionOptionAction(opportunityData.contractCommissionOption));

    const disclaimerTopicFunctionMappings = [
        {
            topic: 'Annuity',
            disclaimerUpdateFunction: setAnnuityInsuranceAddressingAction
        },
        {
            topic: 'Insurance',
            disclaimerUpdateFunction: setAnnuityInsuranceAddressingAction
        },
        {
            topic: 'Payment Plan',
            disclaimerUpdateFunction: setPaymentPlanHealthAddressingAction
        },
        {
            topic: 'Irrevocable',
            disclaimerUpdateFunction: setIrrevocabilityAddressingAction
        },
        {
            topic: 'Insured Consent',
            disclaimerUpdateFunction: setRecipientConsentAction
        },
        {
            topic: 'Incapacitated Rates',
            disclaimerUpdateFunction: setRecipientUnavailalbeRatesDisclaimerAction
        },
        {
            topic: 'Incapacitated Rates',
            disclaimerUpdateFunction: setSinglePaySettingAddressingAction
        },
        {
            topic: 'State Filing Fee',
            disclaimerUpdateFunction: setStateFilingFeeAction
        },
        {
            topic: 'Price List',
            disclaimerUpdateFunction: setPriceListDisclaimerAddressingAction
        },
        {
            topic: 'Verify Purchaser Identity',
            disclaimerUpdateFunction: setVerifyPurchaserIdentityAction
        },
        {
            topic: 'Trust Funded Prearrangement',
            disclaimerUpdateFunction: setTrustFundedPrearrangementAction
        }
    ];

    disclaimerTopicFunctionMappings.forEach(mapping => {
        const disclaimerData = opportunityData.disclaimerData.find(data => {
            return translateYesNoStringIntoBoolean(data.accepted) && data.topic === mapping.topic;
        });
        if (disclaimerData) {
            dispatch(
                mapping.disclaimerUpdateFunction({
                    hasBeenAcceptedOrPositivelyAddressed: true,
                    responseToDisclaimer: disclaimerData.userDecision,
                    disclaimerText: disclaimerData.text,
                    addressedByContactId: disclaimerData.contactId
                })
            );
        }
    });

    dispatch(setOpportunityHidePriceListDisclaimerAction(dataWrapper(() => opportunityData.hidePriceListDisclaimer)));

    dispatch(setOpportunityHideStateFilingFeeAction(dataWrapper(() => opportunityData.hideStateFilingFee)));

    dispatch(setOpportunityHideAnnuityDisclaimerAction(dataWrapper(() => opportunityData.hideAnnuityDisclaimer)));

    dispatch(setOpportunityHideInsuranceDisclaimerAction(dataWrapper(() => opportunityData.hideInsuranceDisclaimer)));

    dispatch(
        setOpportunityHideDemographicsDisclaimerAction(dataWrapper(() => opportunityData.hideDemographicsDisclaimer))
    );

    dispatch(
        setOpportunityHidePaymentHoldDisclaimerAction(dataWrapper(() => opportunityData.hidePaymentHoldDisclaimer))
    );
}

export function setStylingData(stylingKey: string, stylingData: any, dispatch: Function, serviceTier: string = '') {
    const isBasic = serviceTier === EfuneralServiceTier.BASIC;

    // If account is basic use local styles. Doing it this way means that if it's we styles built out for a provider we will
    // still apply their account styles but for all others will get the eFuneral default styling.
    const localStyles = getStylesForCompany(stylingKey);

    let primaryColor = dataWrapper(
        () => getCssClassProperty('.custom-btn', 'background-color', stylingData).substring(0, 7),
        ''
    );

    if (!primaryColor || (isBasic && localStyles.colors.primaryColor)) {
        primaryColor = localStyles.colors.primaryColor;
    }

    dispatch(setStylingPrimaryColorAction(primaryColor));

    let secondaryColor = dataWrapper(
        () => getCssClassProperty('.custom-btn-info', 'background-color', stylingData).substring(0, 7),
        ''
    );
    if (!secondaryColor || (isBasic && localStyles.colors.secondaryColor)) {
        secondaryColor = localStyles.colors.secondaryColor;
    }
    dispatch(setStylingSecondaryColorAction(secondaryColor));

    let headerBackgroundColor = dataWrapper(
        () => getCssClassProperty('.custom-hdr-ftr-bkgrnd', 'background-color', stylingData).substring(0, 7),
        ''
    );
    if (!headerBackgroundColor || (isBasic && localStyles.colors.headerBackgroundColor)) {
        headerBackgroundColor = localStyles.colors.headerBackgroundColor;
    }
    dispatch(setStylingHeaderBackgroundColorAction(headerBackgroundColor));

    dispatch(setStylingGeneralBackgroundColorAction(localStyles.colors.generalBackgroundColor));

    let footerBackgroundColor = dataWrapper(
        () => getCssClassProperty('.custom-hdr-ftr-bkgrnd', 'background-color', stylingData).substring(0, 7),
        ''
    );
    if (!footerBackgroundColor || (isBasic && localStyles.colors.footerBackgroundColor)) {
        footerBackgroundColor = localStyles.colors.footerBackgroundColor;
    }
    dispatch(setStylingFooterBackgroundColorAction(footerBackgroundColor));

    let headerBorder = dataWrapper(
        () => getCssClassProperty('.custom-hdr-ftr-border', 'border-color', stylingData).substring(0, 7),
        ''
    );
    if (!headerBorder || (isBasic && localStyles.colors.headerBottomBorderColor)) {
        headerBorder = localStyles.colors.headerBottomBorderColor;
    }
    dispatch(setStylingHeaderBottomBorderColorAction(headerBorder));

    let headerFontColor = dataWrapper(
        () => getCssClassProperty('.custom-name-text', 'color', stylingData).substring(0, 7),
        ''
    );
    if (!headerFontColor || (isBasic && localStyles.colors.headerFontColor)) {
        headerFontColor = localStyles.colors.headerFontColor;
    }
    dispatch(setStylingHeaderFontColorAction(headerFontColor));

    let footerFontColor = dataWrapper(
        () => getCssClassProperty('.custom-footer-text', 'color', stylingData).substring(0, 7),
        ''
    );
    if (!footerFontColor || (isBasic && localStyles.colors.footerFontColor)) {
        footerFontColor = localStyles.colors.footerFontColor;
    }
    dispatch(setStylingFooterFontColorAction(footerFontColor));

    const logoUrl = `${getEnvironment().efuneralMediaBucket}/account-logos/${stylingKey}.png`;
    dispatch(setStylingLogoUrlAction(logoUrl));
    dispatch(setAccountMediaDomainAction(logoUrl));

    if (localStyles.colors.titleColor) {
        dispatch(setStylingTitleColorAction(localStyles.colors.titleColor));
    }

    if (localStyles.colors.bodyColor) {
        dispatch(setStylingBodyColorAction(localStyles.colors.bodyColor));
    }

    if (localStyles.colors.formFieldsBackgroundColor) {
        dispatch(setStylingFormFieldsBackgroundColorAction(localStyles.colors.formFieldsBackgroundColor));
    }

    if (localStyles.colors.formFieldsTextColor) {
        dispatch(setStylingFormFieldsTextColorAction(localStyles.colors.formFieldsTextColor));
    }

    if (localStyles.fonts.bodyFontFamily) {
        dispatch(setBodyFontFamilyAction(localStyles.fonts.bodyFontFamily));
    }

    if (localStyles.fonts.titleFontFamily) {
        dispatch(setTitleFontFamilyAction(localStyles.fonts.titleFontFamily));
    }

    if (localStyles.fonts.bodyFontSize) {
        dispatch(setBodyFontSizeAction(localStyles.fonts.bodyFontSize));
    }

    if (localStyles.fonts.titleFontSize) {
        dispatch(setTitleFontSizeAction(localStyles.fonts.titleFontSize));
    }

    if (localStyles.fonts.buttonFontFamily) {
        dispatch(setButtonFontFamilyAction(localStyles.fonts.buttonFontFamily));
    }

    if (localStyles.colors.sectionSeperatorColor) {
        dispatch(setSectionSeperatorColorAction(localStyles.colors.sectionSeperatorColor));
    }

    if (localStyles.colors.primaryContrastColor) {
        dispatch(setStylingPrimaryConrastColorAction(localStyles.colors.primaryContrastColor));
    }

    if (localStyles.colors.secondaryContrastColor) {
        dispatch(setStylingSecondaryConrastColorAction(localStyles.colors.secondaryContrastColor));
    }
    if (localStyles.colors.placeholderFullOpacity) {
        dispatch(setStylingPlaceholderFullOpacityAction(localStyles.colors.placeholderFullOpacity));
    }
    if (localStyles.fonts.bodyAlignment) {
        dispatch(setBodyAlignmentAction(localStyles.fonts.bodyAlignment));
    }

    if (localStyles.fonts.titleAlignment) {
        dispatch(setTitleAlignmentAction(localStyles.fonts.titleAlignment));
    }

    if (localStyles.fonts.bodyWeight) {
        dispatch(setBodyWeightAction(localStyles.fonts.bodyWeight));
    }

    if (localStyles.fonts.titleWeight) {
        dispatch(setTitleWeightAction(localStyles.fonts.titleWeight));
    }

    if (localStyles.fonts.buttonFontSize) {
        dispatch(setButtonFontSizeAction(localStyles.fonts.buttonFontSize));
    }

    if (localStyles.spacing) {
        if (localStyles.spacing.aboveContinueButton) {
            dispatch(setSpacingAboveContinueButtonAction(localStyles.spacing.aboveContinueButton));
        }
        if (localStyles.spacing.abovePageBodyContent) {
            dispatch(setSpacingAboveBodyContentAction(localStyles.spacing.abovePageBodyContent));
        }
        if (localStyles.spacing.abovePageSubtitle) {
            dispatch(setSpacingAbovePageSubitleAction(localStyles.spacing.abovePageSubtitle));
        }
        if (localStyles.spacing.abovePageTitle) {
            dispatch(setSpacingAbovePageTitleAction(localStyles.spacing.abovePageTitle));
        }
        if (localStyles.spacing.innerBody) {
            dispatch(setSpacingInnerBodyAction(localStyles.spacing.innerBody));
        }
        if (localStyles.spacing.xs) {
            dispatch(setSpacingXSAction(localStyles.spacing.xs));
        }
        if (localStyles.spacing.sm) {
            dispatch(setSpacingSMAction(localStyles.spacing.sm));
        }
        if (localStyles.spacing.md) {
            dispatch(setSpacingMDAction(localStyles.spacing.md));
        }
        if (localStyles.spacing.lg) {
            dispatch(setSpacingLGAction(localStyles.spacing.lg));
        }
        if (localStyles.spacing.xl) {
            dispatch(setSpacingXLAction(localStyles.spacing.xl));
        }
        if (localStyles.spacing.xxl) {
            dispatch(setSpacingXXLAction(localStyles.spacing.xxl));
        }
    }

    if ([true, false].includes(localStyles.useFormLabelsAsPlaceholders)) {
        dispatch(setUseFormLabelsAsPlaceholdersAction(localStyles.useFormLabelsAsPlaceholders));
    }

    const tabStyling = getTabStylesForCompany(stylingKey);
    const newFaviconUrl = tabStyling.faviconUrl;
    setTabFavicon(newFaviconUrl);
    const newTabName = tabStyling.tabName;
    document.title = newTabName;
}

export function fetchAndStoreOpportunityLoadData(
    state: GlobalStateShape,
    dispatch: Function,
    opportunityId: string,
    callback: (result: any, error: any) => void
): void {
    window.localStorage.setItem('opportunityId', opportunityId);

    let last4SSN = getFormData(state, 'securityData', 'ssnLastFour');
    if (!last4SSN) {
        const otherSsnEntry: string = getFormData(state, 'purchaser', 'ssn');
        if (otherSsnEntry && otherSsnEntry.length > 4) {
            last4SSN = otherSsnEntry.substring(otherSsnEntry.length - 4);
        }
    }
    let birthdate = getFormData(state, 'securityData', 'birthdate') || getFormData(state, 'purchaser', 'birthdate');
    let twilioCode = getFormData(state, 'purchaserPhoneVerification', 'code');
    let securityCheckBypass = state.testing.isBypassingSecurity;
    fetchOpportunityData(
        opportunityId,
        {
            last4SSN,
            birthdate,
            twilioCode,
            securityCheckBypass
        },
        (result, error) => {
            if (error) {
                return callback(undefined, error);
            }

            if (result.accountData?.customColors) {
                let customColors = null;

                try {
                    customColors = JSON.parse(result.accountData.customColors);
                } catch (_ex) {
                    // Keep default true value
                }
                // We want to default to showing the name in the title, but also not show it
                // if it's explicitly set to not show.

                const showNameInTitle = customColors?.showNameInHeader !== false;

                dispatch(setStylingShowNameInTitleAction(showNameInTitle));
            }

            if (result.purchaser?.phoneNumber) {
                dispatch(setOpportunityPurchaserPhoneNumberAction(result.purchaser.phoneNumber));
            }

            if (result.accountData.subdomain) {
                dispatch(setAccountSubdomainAction(result.accountData.subdomain));
                if (result.accountData.hostedDomain) {
                    dispatch(setAccountHostedDomainAction(result.accountData.hostedDomain));
                }
                if (result.accountData.demoHostedDomain) {
                    dispatch(setAccountDemoHostedDomainAction(result.accountData.demoHostedDomain));
                }
                if (result.accountData.helloSignClientId) {
                    dispatch(setAccountHellosignKeyAction(result.accountData.helloSignClientId));
                }

                // The styling key should match the sales platform if a sales platform created the opportunity
                // referralSubdomain could be an account like everdays
                let stylingKey = result.referralSubdomain || result.accountData.subdomain;
                const migrateCustomFlowChecks =
                    (result?.featureFlags as {
                        description: string;
                        enabled: boolean;
                    }[])?.find(featureFlag => {
                        return featureFlag.description === 'migrateCustomFlowChecks';
                    })?.enabled || false;
                if (migrateCustomFlowChecks) {
                    const stylingKeyOverride = getStylingKeyOverrideForApiBrand(result?.accountData?.apiBrand);
                    if (stylingKeyOverride) {
                        stylingKey = stylingKeyOverride;
                    }
                } else {
                    if (result?.accountData?.isTulip) {
                        // If the account is a tulip account, we should use the tulip parent account's custom styling
                        stylingKey = TULIP_STYLING_KEY;
                    }
                }
                dispatch(setStylingKeyAction(stylingKey));
                // TODO remove and market code check
                if (!dataWrapper(() => result.referralAgentMarketCode)) {
                    setStylingData(stylingKey, result.stylingData, dispatch, result?.accountData?.efuneralServiceTier);
                }

                dispatch(setStylingLoadingCompleteAction(true));
            }

            storeServerOpportunityData(result, dispatch);
            return callback(result, undefined);
        }
    );
}

export function fetchAndStoreOpportunityStatusData(
    state: GlobalStateShape,
    dispatch: Function,
    opportunityId: string,
    opportunityKey: string,
    callback: (result: any, error: any) => void
) {
    fetchFullOpportunity(opportunityId, opportunityKey, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        if (result.accountData.subdomain) {
            dispatch(setAccountSubdomainAction(result.accountData.subdomain));
            // The styling key should match the sales platform if a sales platform created the opportunity
            let stylingKey = result.referralSubdomain || result.accountData.subdomain;
            const migrateCustomFlowChecks =
                (result?.featureFlags as {
                    description: string;
                    enabled: boolean;
                }[])?.find(featureFlag => {
                    return featureFlag.description === 'migrateCustomFlowChecks';
                })?.enabled || false;
            if (migrateCustomFlowChecks) {
                const stylingKeyOverride = getStylingKeyOverrideForApiBrand(result?.accountData?.apiBrand);
                if (stylingKeyOverride) {
                    stylingKey = stylingKeyOverride;
                }
            } else {
                // If the account is a tulip account, we should use the tulip parent account's custom styling
                if (result?.accountData?.isTulip) {
                    stylingKey = TULIP_STYLING_KEY;
                }
            }
            dispatch(setStylingKeyAction(stylingKey));
            // TODO remove and market code check
            if (!dataWrapper(() => result.referralAgentMarketCode)) {
                setStylingData(stylingKey, result.stylingData, dispatch, result?.accountData?.efuneralServiceTier);
            }
            dispatch(setStylingLoadingCompleteAction(true));
        }

        storeServerOpportunityData(result, dispatch);
        return callback(result, undefined);
    });
}
