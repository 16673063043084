import React from 'react';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

class DemographicsInstructionsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        timing: '',
        disposition: 'funeral',
        firstPage: false,
        preneedContractType: '',
        regulationText: ''
    };

    render() {
        let fundingText = '';
        let disclaimerText = `To be in compliance with insurance regulations, we need to ask you some additional questions 
            to help us verify your identity and complete your ${this.props.disposition} plan purchase. All your 
            information is kept securely.`;

        switch (this.props.timing.toLowerCase()) {
            case 'imminent':
                fundingText =
                    'Your funds will be put into an annuity to ensure your investment is protected and available when needed.';
                break;
            case 'future':
                fundingText =
                    'Your funds will be put into a life insurance policy to ensure your investment is protected and available when needed.';
                break;
        }

        if (this.props.preneedContractType === PreneedContractType.Trust) {
            fundingText =
                'Your funds will be put into a trust account to ensure your investment is protected and available when needed.';
            disclaimerText = `To be in compliance with your state’s preneed funeral and cemetery regulations, we need to ask you some 
                additional questions to help us verify your identity and complete your funeral plan purchase. All your information is kept securely.`;
        }

        return (
            <FullContentCenterWrapper
                metaPageName="Instructions"
                metaPageNumber=""
                metaPagebackAvailable="false"
                metaFirstPage={this.props.firstPage.toString()}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>We just need a few more pieces of information from you</PageCardTitleWrapper>
                    <PageCardTextWrapper>{fundingText}</PageCardTextWrapper>
                    <PageCardTextWrapper>{disclaimerText}</PageCardTextWrapper>
                    <ButtonLinkWrapper to={urlPaths.purchaser} buttonId="continue">
                        Continue
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    timing: string;
    disposition: string;
    firstPage: boolean;
    preneedContractType: string;
}

interface StateShape {
    // Shape of local state
}

export default DemographicsInstructionsPageComponent;
