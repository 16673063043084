import React, { Fragment } from 'react';
import PurchaserOrderSummaryDemographicSummaryWrapper from '../purchaser-order-summary-demographic-summary-component/PurchaserOrderSummaryDemographicSummaryWrapper';
import PageCardUnderlinedSectionWrapper from '../../../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';

class PurchaserOrderSummaryDemographicsGroupingComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        purchaserInfo: {},
        planningForSelf: true,
        recipientInfo: {},
        beneficiaryProvided: false,
        beneficiaryInfo: {}
    };

    render() {
        return (
            <Fragment>
                <PageCardUnderlinedSectionWrapper
                    sectionTitle="Planning for"
                    sectionId="planning-for"
                    fullContent={
                        this.props.planningForSelf ? `I'm planning for myself` : `I'm planning for a loved one`
                    }
                />
                <PurchaserOrderSummaryDemographicSummaryWrapper
                    personInfo={this.props.purchaserInfo}
                    title="Purchaser"
                />
                {!this.props.planningForSelf && (
                    <PurchaserOrderSummaryDemographicSummaryWrapper
                        personInfo={this.props.recipientInfo}
                        title="Recipient"
                    />
                )}
                {this.props.beneficiaryProvided && (
                    <PurchaserOrderSummaryDemographicSummaryWrapper
                        personInfo={this.props.beneficiaryInfo}
                        title="Beneficiary"
                    />
                )}
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    purchaserInfo: any;
    planningForSelf: boolean;
    recipientInfo: any;
    beneficiaryProvided: boolean;
    beneficiaryInfo: any;
}

interface StateShape {
    // Shape of local state
}

export default PurchaserOrderSummaryDemographicsGroupingComponent;
