import { getFormData } from '../../redux-form/redux-form-data-access';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { calculateAge } from '../../reuseable-logic/age-calculator';
import { Timing } from '../../../static/constants/enums/timing';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import { Relationships } from 'static/constants/enums/relationships';
import { beneficiaryIsComplete } from 'toolboxes/reuseable-logic/beneficiary-is-complete';

const disclaimerProductReroutes = (state: GlobalStateShape) => {
    const personToPull = state.opportunity.recipient.sameAsPurchaser ? 'purchaser' : 'recipient';
    const birthDate = getFormData(state, personToPull, 'birthdate');
    const age = calculateAge(new Date(birthDate));
    const timing = state.opportunity.timing;
    if (timing !== Timing.IMMEDIATE && age < 18) {
        return urlPaths.underageRecipient;
    }
    if (state.opportunity.selectedFundingProduct && !state.opportunity.selectedFundingProduct.productType) {
        return urlPaths.noFundingProductAvailable;
    }
    // Relationship is checked at this point because earth asks for relationship on the recipient page, not a seperate page
    if (getFormData(state, 'purchaser', 'relationshipToRecipient') === Relationships.NotListed) {
        return urlPaths.recipientRelationshipNotListed;
    }
    if (state.account.stylingKey === EARTH_STYLING_KEY && !state.opportunity.pricing.paymentInitiated) {
        // Earth's flow is different than everyone else's
        if (!beneficiaryIsComplete(state)) {
            return urlPaths.beneficiaryCheck;
        } else {
            return urlPaths.orderSummary;
        }
    }
};

export default disclaimerProductReroutes;
