import { connect } from 'react-redux';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import ErrorSubmissionPageComponent from './ErrorSubmissionPageComponent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        caseNumber: state.errorLogging.errorCaseNumber,
        errorCaseCreationFailed: state.errorLogging.errorCaseCreationFailed,
        funeralHomePhone: state.account.phone
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorSubmissionPageComponent);
