import { connect } from 'react-redux';
import PersonDemographicsFormComponent from './PersonDemographicsFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { change, getFormValues } from 'redux-form';
import * as faker from 'faker';
import moment from 'moment';
import getEnvironment from '../../../env-config/get-environment';
import { prodEnvironment } from '../../../env-config/environment-configs/prod';

const mapStateToProps: any = (state: GlobalStateShape) => {
    return {
        purchaserData: getFormValues('purchaser')(state),
        accountData: state.account
    };
};

const mapDispatchToProps: any = (dispatch: Function, myProps: any) => {
    return {
        setFormData: (fieldName, newValue) => dispatch(change(myProps.formName, fieldName, newValue))
    };
};

const mergeProps: any = (stateProps: any, dispatchProps: any, myProps: any) => {
    return {
        includeAutofillButton: getEnvironment() !== prodEnvironment,
        copyPurchaserPhone: () => dispatchProps.setFormData('phone', stateProps.purchaserData.phone),
        copyPurchaserEmail: () => dispatchProps.setFormData('email', stateProps.purchaserData.email),
        copyPurchaserFullAddress: () => {
            dispatchProps.setFormData('address', stateProps.purchaserData.address);
            dispatchProps.setFormData('city', stateProps.purchaserData.city);
            dispatchProps.setFormData('state', stateProps.purchaserData.state);
            dispatchProps.setFormData('zipCode', stateProps.purchaserData.zipCode);
        },
        autoFillForm: () => {
            dispatchProps.setFormData('firstName', faker.name.firstName());
            dispatchProps.setFormData('lastName', faker.name.lastName());
            dispatchProps.setFormData('address', faker.address.streetAddress());
            dispatchProps.setFormData('city', stateProps.accountData.city);
            dispatchProps.setFormData('state', stateProps.accountData.state);
            dispatchProps.setFormData('zipCode', stateProps.accountData.zip);

            let genders = ['Male', 'Female'];
            dispatchProps.setFormData('gender', faker.random.arrayElement(genders));

            dispatchProps.setFormData(
                'birthdate',
                moment(faker.date.between('1960-01-01', '1990-01-01')).format('YYYY-MM-DD')
            );
            if (myProps.onAutofillChange) {
                myProps.onAutofillChange();
            }
        },
        ...myProps
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PersonDemographicsFormComponent);
