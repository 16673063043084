// Action Type
export const SET_TRAVEL_DOCUSIGN_COST = 'SET_TRAVEL_DOCUSIGN_COST';
// Action
export function setFuneralIsDemoContractIncursCostAction(newValue: boolean) {
    return { type: SET_TRAVEL_DOCUSIGN_COST, newValue };
}
// Reducer
export function setFuneralIsDemoContractIncursCostReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_TRAVEL_DOCUSIGN_COST:
            return action.newValue;
        default:
            return state;
    }
}
