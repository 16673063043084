import GlobalStateShape from 'redux/GlobalStateShape';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { Timing } from 'static/constants/enums/timing';
import urlPaths from 'static/constants/enums/urlPaths';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';

const relationshipToReceipientReroutes = (state: GlobalStateShape) => {
    if (state.opportunity.preneedContractType === PreneedContractType.Trust) {
        return urlPaths.recipient;
    }
    if (state.opportunity.timing === Timing.IMMEDIATE) {
        return urlPaths.recipient;
    }
    if (state.account.stylingKey === EARTH_STYLING_KEY) {
        // We ask for recipient relation on the recipient form for earth
        return urlPaths.recipient;
    }
};

export default relationshipToReceipientReroutes;
