import { connect } from 'react-redux';
import OpportunityClosedPageComponent from './OpportunityClosedPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(OpportunityClosedPageComponent);

export default DefaultComponent;
