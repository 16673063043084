import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendSubmitIdentityPlaid } from 'toolboxes/server-application-buffer/plaid-identity-data';
import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';
import { setOpportunityRecipientIdentityVerificationPlaidAction } from 'redux/opportunity/recipient/identityVerificationPlaid';
import { sendSaveRecipientIdentityPlaidConsentDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';

export function submitRecipientIdentityQuestions(state: GlobalStateShape, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSaveRecipientIdentityPlaidConsentDisclaimer(state, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.recipientConsent, urlPaths.prefilledRecipientConsent]
    });
    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                sendSubmitIdentityPlaid(state.opportunity?.recipient?.id, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    if (result?.status === PlaidIdentityStatus.SUCCESS) {
                        dispatch(setOpportunityRecipientIdentityVerificationPlaidAction(result?.status));
                    } else {
                        dispatch(setOpportunityRecipientIdentityVerificationPlaidAction(PlaidIdentityStatus.FAILED));
                    }
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.recipientConsent, urlPaths.prefilledRecipientConsent]
    });
    return functionsToRun;
}
