import React from 'react';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import LoadingScreenWrapper from 'components/global-components/loading-screen-component/LoadingScreenWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import { fetchDemoFuneralContract, fetchDemoTravelContract } from 'toolboxes/server-application-buffer/contracts-data';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import { VerificationContractsDetails } from 'static/models/VerificationContractDetails';
import { dataWrapper } from 'toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import JSONPretty from 'react-json-pretty';
import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';

const framedContent: React.CSSProperties = {
    border: '1px solid',
    overflow: 'auto',
    width: '100%',
    height: '400px'
};

class DocusignFreeContractPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            loading: true,
            errored: false,
            travelContractsData: null,
            funeralContractsData: null,
            showContractsJson: false
        };
    }

    static defaultProps = {
        // Default prop values
        funeralOpportunityId: '',
        travelOpportunityId: ''
    };

    componentDidMount() {
        if (this.props.funeralOpportunityId) {
            // Funeral contract should be shown
            fetchDemoFuneralContract(this.props.funeralOpportunityId, (funeralContracts, error) => {
                if (error) {
                    this.setState({
                        loading: false,
                        errored: true
                    });
                } else if (this.props.travelOpportunityId) {
                    // Funeral and travel contract should be shown
                    fetchDemoTravelContract(this.props.travelOpportunityId, (travelContracts, error) => {
                        if (error) {
                            this.setState({
                                loading: false,
                                errored: true
                            });
                        }
                        this.setState({
                            loading: false,
                            funeralContractsData: funeralContracts,
                            travelContractsData: travelContracts
                        });
                    });
                } else {
                    this.setState({
                        loading: false,
                        funeralContractsData: funeralContracts
                    });
                }
            });
        } else if (this.props.travelOpportunityId) {
            // Only Travel contract should be shown
            fetchDemoTravelContract(this.props.travelOpportunityId, (travelContracts, error) => {
                if (error) {
                    this.setState({
                        loading: false,
                        errored: true
                    });
                } else {
                    this.setState({
                        loading: false,
                        travelContractsData: travelContracts
                    });
                }
            });
        }
        // No contracts to show
        else {
            this.setState({
                loading: false
            });
        }
    }

    removeHtmlFromVerifiactionContractsDetails(data: VerificationContractsDetails): VerificationContractsDetails {
        return dataWrapper(() => {
            return {
                ...data,
                generatedContracts: data.generatedContracts.map(contract => ({
                    ...contract,
                    html: 'Removed'
                })),
                generatedContract: {
                    ...data.generatedContract,
                    html: 'Removed'
                }
            };
        });
    }

    render() {
        if (this.state.loading && !this.state.errored) {
            return <LoadingScreenWrapper />;
        }

        const funeralContractsHtml =
            dataWrapper(
                () => this.state.funeralContractsData.generatedContracts.map(contract => contract.html).join(''),
                ''
            ) + dataWrapper(() => this.state.funeralContractsData.generatedContract.html, '');
        let travelContractsHtml = '';
        if (this.props.travelOpportunityId) {
            travelContractsHtml =
                dataWrapper(
                    () => this.state.travelContractsData.generatedContracts.map(contract => contract.html).join(''),
                    ''
                ) + dataWrapper(() => this.state.travelContractsData.generatedContract.html, '');
        }
        const fullContractsHtml = funeralContractsHtml + travelContractsHtml;

        let content = null;
        if (this.state.errored || !fullContractsHtml) {
            content = (
                <PageCardTextWrapper>
                    The customer would see contracts here, and be able to sign them using DocuSign.
                </PageCardTextWrapper>
            );
        } else {
            content = (
                <PageCardTextWrapper>
                    <iframe
                        title="Verification Contract"
                        style={framedContent}
                        sandbox="allow-same-origin"
                        srcDoc={fullContractsHtml}
                        id="component-iframed-verification-contracts"
                    />
                    <PageCardTitleWrapper>
                        Contracts Data -&nbsp;
                        <MockLinkWrapper
                            id="show-contracts"
                            onClick={() => {
                                this.setState({
                                    showContractsJson: !this.state.showContractsJson
                                });
                            }}
                        >
                            {this.state.showContractsJson ? 'Hide' : 'Show'}
                        </MockLinkWrapper>
                    </PageCardTitleWrapper>
                    {this.state.showContractsJson && (
                        <JSONPretty
                            style={framedContent}
                            data={{
                                funeralContracts: dataWrapper(() => ({
                                    ...this.removeHtmlFromVerifiactionContractsDetails(this.state.funeralContractsData)
                                })),
                                travelContracts: dataWrapper(() => ({
                                    ...this.removeHtmlFromVerifiactionContractsDetails(this.state.travelContractsData)
                                }))
                            }}
                        />
                    )}
                </PageCardTextWrapper>
            );
        }
        return (
            <FullContentCenterWrapper metaPageName="" metaPageNumber="" metaPagebackAvailable="false">
                <PageCardWrapper extraCardWidth extraCardLength>
                    <PageCardTitleWrapper>Contracts Example</PageCardTitleWrapper>
                    {content}
                    <ButtonLinkWrapper to={urlPaths.purchaseProcessing} buttonId="continue">
                        Continue
                    </ButtonLinkWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    funeralOpportunityId: string;
    travelOpportunityId: string;
}

interface StateShape {
    // Shape of local state
    loading: boolean;
    errored: boolean;
    funeralContractsData: VerificationContractsDetails;
    travelContractsData: VerificationContractsDetails;
    showContractsJson: boolean;
}

export default DocusignFreeContractPageComponent;
