import { GeographicState } from '../enums/states';

const noAnnuityStates: string[] = [
    GeographicState.MO,
    GeographicState.NH,
    GeographicState.ND,
    GeographicState.WA,
    GeographicState.MT,
    GeographicState.NY
];

export default noAnnuityStates;
