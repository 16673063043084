import { connect } from 'react-redux';
import HealthImpactModalComponent from './HealthImpactModalComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setPaymentPlanHealthAddressingAction } from '../../../../../redux/disclaimerData/disclaimerAddressings/paymentPlanHealthImpact';
import { getFuneralTotal } from '../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { getFormData } from '../../../../../toolboxes/redux-form/redux-form-data-access';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import { getDispositionText } from 'toolboxes/reuseable-logic/language-utils';

function calculateRecipientPossessive(state) {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return 'your';
    }
    return `${getFormData(state, 'recipient', 'firstName')}'s`;
}

function calculateRecipientName(state) {
    if (state.opportunity.recipient.sameAsPurchaser) {
        return 'you';
    }
    return `${getFormData(state, 'recipient', 'firstName')}`;
}

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    const phoneNumber = state.opportunity.referralAccountPhone
        ? formatPhoneNumber(state.opportunity.referralAccountPhone)
        : formatPhoneNumber(state.account.phone);
    return {
        recipientPossessive: calculateRecipientPossessive(state),
        recipientName: calculateRecipientName(state),
        accountPhoneNumber: phoneNumber,
        prearrangementAmount: getFuneralTotal(state),
        paymentOption: myProps.paymentOption || state.opportunity.pricing.paymentOptions.selectedOption,
        dispositionText: getDispositionText(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPaymentPlanHealthAddressingAction: newValue => dispatch(setPaymentPlanHealthAddressingAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthImpactModalComponent);
