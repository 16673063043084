// Action Type
export const SET_STYLING_SPACING_LG = 'SET_STYLING_SPACING_LG';
// Action
export function setSpacingLGAction(newValue: string) {
    return { type: SET_STYLING_SPACING_LG, newValue };
}
// Reducer
export function setSpacingLGReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STYLING_SPACING_LG:
            return action.newValue;
        default:
            return state;
    }
}
