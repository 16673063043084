import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { getFormData, getFormMetaData, getFormSyncErrorsData } from 'toolboxes/redux-form/redux-form-data-access';
import urlPaths from 'static/constants/enums/urlPaths';
import { sendSaveContactWithSuppliedSSN } from 'toolboxes/server-application-buffer/contact-data';

export function savePurchaserSSNData(state: GlobalStateShape): BlockingFunction[] {
    const SSNToLast4Digits = state.featureFlags.SSNToLast4Digits;
    const valueName = SSNToLast4Digits ? 'ssnLastFour' : 'ssn';
    const ssn = getFormData(state, 'SSNCaptureData', valueName);
    const ssnFieldName = SSNToLast4Digits ? 'ssnLastFour' : 'ssn';
    const digitsExpected = SSNToLast4Digits ? 4 : 9;

    let touched = false;
    let correctErrorMessage = false;
    let digitsEntered = false;

    if (getFormMetaData(state, 'SSNCaptureData') && getFormMetaData(state, 'SSNCaptureData')[ssnFieldName]) {
        if (getFormMetaData(state, 'SSNCaptureData')[ssnFieldName].touched === true) {
            touched = true;
        } else {
            // This is added for stability if removed make sure to test that default 0000 isn't saved to salesForce
            touched = false;
        }
        if (getFormSyncErrorsData(state, 'SSNCaptureData')[ssnFieldName] === 'Invalid social security number') {
            correctErrorMessage = true;
        } else {
            // This is added for stability if removed make sure to test that default 0000 isn't saved to salesForce
            correctErrorMessage = false;
        }
        if (
            getFormData(state, 'SSNCaptureData', ssnFieldName) &&
            getFormData(state, 'SSNCaptureData', ssnFieldName).length === digitsExpected
        ) {
            digitsEntered = true;
        } else {
            // This is added for stability if removed make sure to test that default 0000 isn't saved to salesForce
            digitsEntered = false;
        }
    }

    if (touched && correctErrorMessage && digitsEntered) {
        return [];
    }

    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveContactWithSuppliedSSN(state, ssn, 'purchaser', (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserIdentity]
        }
    ];
}
