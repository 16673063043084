import React from 'react';
import BeneficiaryFormWrapper from '../../form-components/beneficiary-form-component/BeneficiaryFormWrapper';
import ButtonLinkWrapper from '../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from '../../../static/constants/enums/urlPaths';
import BackButtonLinkWrapper from '../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import { Redirect } from 'react-router';
import { Button, Typography } from '@material-ui/core';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import StandardDisclaimerPageWrapper from '../disclaimer-pages/standard-disclaimer-page-component/StandardDisclaimerPageWrapper';
import ApplicationStyling from 'static/models/ApplicationStyling';

class BeneficiaryPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            showModal: false,
            redirect: false,
            beneficiaryRecipientSame: false
        };
    }

    static defaultProps = {
        // Default prop values
        beneficiaryComplete: false,
        beneficiaryAndRecipientAreSimilar: false,
        checkIfBeneficiaryAndRecipientAreSimilar: false,
        beneficiaryName: '',
        recipientName: '',
        setBeneficiaryRecipientSamePersonAction: () => {},
        stylingOptions: undefined
    };

    onContinue() {
        if (this.props.beneficiaryAndRecipientAreSimilar) {
            this.setState({ showModal: true });
        } else {
            this.setState({ redirect: true });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={urlPaths.orderSummary} />;
        }
        return (
            <FullContentCenterWrapper metaPageName="Add Beneficiary" metaPageNumber="1" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Beneficiary Information</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please provide the following details of the person named as the beneficiary.
                        {this.state.beneficiaryRecipientSame && (
                            <Typography color="error">
                                Beneficiary cannot be the insured. Please enter another beneficiaries information.
                            </Typography>
                        )}
                    </PageCardTextWrapper>
                    <BeneficiaryFormWrapper />

                    {this.props.checkIfBeneficiaryAndRecipientAreSimilar ? (
                        <Button
                            onClick={() => this.onContinue()}
                            id="instructions-continue"
                            variant="contained"
                            color="primary"
                            aria-label="continue"
                            disabled={!this.props.beneficiaryComplete}
                            style={{
                                marginTop: this.props.stylingOptions.spacing?.aboveContinueButton
                                    ? `calc(${this.props.stylingOptions.spacing.aboveContinueButton} - ${this.props.stylingOptions.spacing.abovePageBodyContent})`
                                    : '',
                                fontSize: this.props.stylingOptions.fonts.buttonFontSize
                            }}
                        >
                            Continue
                        </Button>
                    ) : (
                        <ButtonLinkWrapper
                            disabled={!this.props.beneficiaryComplete}
                            to={urlPaths.orderSummary}
                            buttonId="Continue"
                            aria-label="continue"
                        >
                            Continue
                        </ButtonLinkWrapper>
                    )}

                    {this.state.showModal && (
                        <GeneralModalWrapper onRequestClose={() => this.setState({ redirect: true })}>
                            <StandardDisclaimerPageWrapper
                                acceptTo=""
                                rejectTo=""
                                acceptanceText="Yes"
                                rejectionText="No"
                                disclaimerTitle="Are these the same person?"
                                saveDisclaimerAddressingToRedux={newValue => {
                                    this.props.setBeneficiaryRecipientSamePersonAction(newValue);
                                    this.setState({ showModal: false });
                                }}
                                extraContentLength={false}
                                pageName=""
                                pageBack=""
                                pageNumber=""
                                allowBack={false}
                                rejectionSideAffect={() => this.setState({ redirect: true })}
                                acceptanceSideAffect={() => {
                                    this.setState({ beneficiaryRecipientSame: true });
                                }}
                            >
                                <Typography>
                                    We noticed much of the information provided about {this.props.beneficiaryName} and{' '}
                                    {this.props.recipientName} match. Are these the same person?
                                </Typography>
                            </StandardDisclaimerPageWrapper>
                        </GeneralModalWrapper>
                    )}
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    beneficiaryComplete: boolean;
    beneficiaryAndRecipientAreSimilar: boolean;
    beneficiaryName: string;
    recipientName: string;
    checkIfBeneficiaryAndRecipientAreSimilar: boolean;
    setBeneficiaryRecipientSamePersonAction: Function;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    showModal: boolean;
    redirect: boolean;
    beneficiaryRecipientSame: boolean;
}

export default BeneficiaryPageComponent;
