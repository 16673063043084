import { connect } from 'react-redux';
import ProductInformationComponent from './ProductInformationComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getCartSalesTax, getCartSubtotal, getCartTotal } from 'toolboxes/reuseable-logic/pricing-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        products: state.opportunity.products,
        travelProducts: state.travelOpportunity.products,
        total: getCartTotal(state),
        subtotal: getCartSubtotal(state),
        salesTax: getCartSalesTax(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInformationComponent);
