import ApplicationStyling from '../../models/ApplicationStyling';
import tulipStyling, { TULIP_STYLING_KEY, tulipTabConstants } from './companies/tulip';
import { TabStyling } from '../../models/TabStyling';
import everdaysStyling, { EVERDAYS_STYLING_KEY, evedaysTabConstants } from './companies/everdays';
import efuneralStyling, { efuneralTabConstants } from './companies/efuneral';
import { earthStyling, earthTabConstants, EARTH_STYLING_KEY } from './companies/earth';
import { TITAN_STYLING_KEY, titanStyling, titanTabConstants } from './companies/titan';

export default function getStylesForCompany(stylingKey: string): ApplicationStyling {
    switch (stylingKey) {
        case EVERDAYS_STYLING_KEY:
            return everdaysStyling;
        case TULIP_STYLING_KEY:
            return tulipStyling;
        case EARTH_STYLING_KEY:
            return earthStyling;
        case TITAN_STYLING_KEY:
            return titanStyling;
        default:
            return efuneralStyling;
    }
}
export function getTabStylesForCompany(stylingKey: string): TabStyling {
    switch (stylingKey) {
        case EVERDAYS_STYLING_KEY:
            return evedaysTabConstants;
        case TULIP_STYLING_KEY:
            return tulipTabConstants;
        case EARTH_STYLING_KEY:
            return earthTabConstants;
        case TITAN_STYLING_KEY:
            return titanTabConstants;
        default:
            return efuneralTabConstants;
    }
}
export function getStylingKeyOverrideForApiBrand(apiBrand: string): string {
    switch (apiBrand) {
        case 'Everdays':
            return EVERDAYS_STYLING_KEY;
        case 'Tulip':
            return TULIP_STYLING_KEY;
        case 'Earth':
            return EARTH_STYLING_KEY;
        case 'Titan':
            return TITAN_STYLING_KEY;
        default:
            return null;
    }
}

// These functions are ONLY supposed to be used to set the initial state quickly if possible, they shouldn't be used outside of setting initial application state
export const getStylingKeyForDomain = () => {
    // Initial data
    const currentDomain = window.location.href
        .replace('www.', '')
        .replace('http://', '')
        .replace('https://', '')
        .split('.');

    if (currentDomain.some(domainPiece => domainPiece.toLowerCase() === 'earthfuneral')) {
        return EARTH_STYLING_KEY;
    }
    return '';
};

// These functions are ONLY supposed to be used to set the initial state quickly if possible, they shouldn't be used outside of setting initial application state
export const getApplicationStylingForDomain = () => {
    const stylingKey = getStylingKeyForDomain();
    return getStylesForCompany(stylingKey);
};
