import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class NoAvailableProductPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountName: '',
        accountPhoneNumber: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="NoAvailableProduct" metaPageNumber="2" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Purchase Unavailable</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Sorry, the funeral plans you wish to complete with {this.props.accountName} cannot be arranged
                        through our site at this time. We are always working to expand our services and hope we will be
                        able to accommodate you soon. If you would like to continue with your purchase please contact{' '}
                        {this.props.accountName} directly at {this.props.accountPhoneNumber}.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountName: string;
    accountPhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default NoAvailableProductPageComponent;
