import { connect } from 'react-redux';
import SecurityCheckPageComponent from './SecurityCheckPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import {
    formIsValid,
    validateSecurityData,
    validatePhoneVerificationRequest,
    validatePhoneVerify
} from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import getEnvironment from '../../../env-config/get-environment';
import { setIsBypassingSecurityAction } from '../../../redux/testing/isBypassingSecurity';
import { sendDataToCreateTwilioVerificationCase } from '../../../toolboxes/server-application-buffer/outgoing-api-triggering-data';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        radioFormComplete: formIsValid(
            getFormValues('purchaserPhoneVerification')(state),
            validatePhoneVerificationRequest
        ),
        formComplete: formIsValid(getFormValues('purchaserPhoneVerification')(state), validatePhoneVerify),
        methodType: getFormData(state, 'purchaserPhoneVerification', 'method'),
        environment: getEnvironment(),
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSecurityBypass: newValue => dispatch(setIsBypassingSecurityAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityCheckPageComponent);
