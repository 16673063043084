import { connect } from 'react-redux';
import IrrevocablePageComponent from './IrrevocablePageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setIrrevocableAction } from 'redux/yesNoAnswers/policyQuestions/irrevocable';
import { getPolicyContractText } from '../../../toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        policyContractLanguage: getPolicyContractText(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setIrrevocableAction: newValue => dispatch(setIrrevocableAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrevocablePageComponent);
