import React, { Fragment } from 'react';
import PaymentPriceCardWrapper from '../../../../../global-components/payment-price-card-component/PaymentPriceCardWrapper';
import BankingInformationFormWrapper from '../../../../../form-components/banking-information-form-component/BankingInformationFormWrapper';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import ButtonLinkWrapper from '../../../../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../../../../static/constants/enums/urlPaths';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import { PaymentMode } from '../../../../../../static/constants/enums/paymentModes';
import InstallmentPaymentTrustOptionsDisplayWrapper from '../../payment-options-components/installment-payment-trust-options-display-component/InstallmentPaymentTrustOptionsDisplayWrapper';
import PaymentTrustOptionSelectionWrapper from '../../payment-options-components/payment-trust-option-selection-component/PaymentTrustOptionSelectionWrapper';
import YesNoWrapper from 'components/global-components/buttons/yes-no-component/YesNoWrapper';
import InfoIcon from '@material-ui/icons/Info';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class CheckTrustPaymentContentComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            payMonthly: undefined,
            payMonthlySelected: false,
            infoIconOpen: false
        };
    }

    static defaultProps = {
        // Default prop values
        totalPrice: '',
        paymentOptions: [],
        setSelectedPaymentOption: () => {},
        formValid: false,
        dueToday: '',
        dueMonthly: '',
        numberOfMonthsToPay: '',
        nextUrl: undefined,
        selectedOption: null
    };

    componentDidMount() {
        if (this.props.paymentOptions.length > 1) {
            const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
            this.props.setSelectedPaymentOption(singlePayOption);
        } else {
            this.props.setSelectedPaymentOption(this.props.paymentOptions[0]);
        }
    }

    setMonthlyTrustPayment(answer: boolean) {
        this.setState({
            payMonthly: answer,
            payMonthlySelected: true
        });
    }

    render() {
        const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
        const multiplePaymentOptions = this.props.paymentOptions.length > 1;

        let TotalsDisplay = null;
        if (multiplePaymentOptions) {
            TotalsDisplay = (
                <PaymentPriceCardWrapper
                    title="Total Due Today (tax included)"
                    amount={this.props.dueToday}
                    subtitle="Payment options are shown below"
                />
            );
        } else {
            const selectedPayment = this.props.paymentOptions[0];
            if (selectedPayment.paymentMode === PaymentMode.Single) {
                TotalsDisplay = (
                    <PaymentPriceCardWrapper
                        title="Total Due Today (tax included)"
                        amount={this.props.dueToday}
                        subtitle="Prepaid direct cremation package, taxes, and fees"
                    />
                );
            } else {
                TotalsDisplay = (
                    <Fragment>
                        <PaymentPriceCardWrapper
                            title="Due today"
                            amount={this.props.dueToday}
                            subtitle="This includes the first month's payment"
                        />
                        <PaymentPriceCardWrapper
                            title="Due monthly"
                            amount={`${this.props.dueMonthly} / mo`}
                            subtitle={`For ${this.props.numberOfMonthsToPay} months. Includes prepaid direct cremation package, taxes, and fees.`}
                        />
                    </Fragment>
                );
            }
        }

        let TotalPaid = null;
        let AmountDueTodayText = null;
        if (this.state.payMonthlySelected) {
            if (this.props.selectedOption.paymentMode === PaymentMode.Single) {
                TotalPaid = (
                    <div className="total-paid-text" style={{ textAlign: 'center', padding: '10px' }}>
                        total paid: {this.props.totalPrice}
                    </div>
                );
            } else {
                TotalPaid = (
                    <div className="total-paid-text" style={{ textAlign: 'center', paddingBottom: '10px' }}>
                        Total paid after {this.props.numberOfMonthsToPay + 1} months: {this.props.totalPrice}
                    </div>
                );
            }

            AmountDueTodayText = (
                <h3 className="amount-due-today-text" style={{ textAlign: 'center', paddingTop: '10px' }}>
                    The amount due today is {this.props.dueToday}
                    <InfoIcon
                        style={{ cursor: 'pointer', alignSelf: 'center', color: 'inherit' }}
                        fontSize="inherit"
                        onClick={() => {
                            this.setState({ infoIconOpen: true });
                        }}
                    />
                    {this.state.infoIconOpen && (
                        <GeneralModalWrapper onRequestClose={() => this.setState({ infoIconOpen: false })}>
                            <FullContentCenterWrapper
                                metaPageName="PaymentOptionSelection"
                                metaPageNumber="3"
                                metaPagebackAvailable="true"
                            >
                                <PageCardTitleWrapper>{this.props.dueToday} Due Today</PageCardTitleWrapper>
                                <PageCardTextWrapper>
                                    This includes any travel protection purchased, any relevant fees, and the first
                                    month's payment.
                                </PageCardTextWrapper>
                            </FullContentCenterWrapper>
                        </GeneralModalWrapper>
                    )}
                </h3>
            );
        }

        return (
            <div>
                <div style={{ fontSize: '1.4rem', padding: '5px' }}>Banking Information</div>
                <BankingInformationFormWrapper />
                {TotalsDisplay}
                {multiplePaymentOptions && (
                    <Fragment>
                        <div className="payment-monthly-select">
                            <div className="payment-monthly-title">Would you like to pay monthly?</div>
                            <YesNoWrapper
                                onYesClick={() => this.setMonthlyTrustPayment(true)}
                                onNoClick={() => this.setMonthlyTrustPayment(false)}
                                yesNoId="trustPaymentMonthly"
                                answer={this.state.payMonthly}
                            />
                        </div>
                        {this.state.payMonthly === true &&
                            this.props.paymentOptions.find((paymentOption: PaymentOption) => {
                                if (paymentOption.paymentMode === PaymentMode.Multi) {
                                    if (this.props.selectedOption.paymentMode !== PaymentMode.Multi) {
                                        this.props.setSelectedPaymentOption(paymentOption);
                                    }
                                    return true;
                                }
                                return false;
                            }) && <InstallmentPaymentTrustOptionsDisplayWrapper paymentMethod={PaymentMethod.ACH} />}
                        {this.state.payMonthly === false &&
                            this.props.paymentOptions.find((paymentOption: PaymentOption) => {
                                if (paymentOption.paymentMode === PaymentMode.Single) {
                                    if (this.props.selectedOption.paymentMode !== PaymentMode.Single) {
                                        this.props.setSelectedPaymentOption(paymentOption);
                                    }
                                    return true;
                                }
                                return false;
                            }) && <PaymentTrustOptionSelectionWrapper paymentOption={singlePayOption} />}
                        {this.props.selectedOption.paymentMode !== PaymentMode.Single && AmountDueTodayText}
                        {TotalPaid}
                    </Fragment>
                )}
                <ButtonLinkWrapper
                    buttonId="complete-payment"
                    to={this.props.nextUrl}
                    disabled={!this.props.formValid || (multiplePaymentOptions && !this.state.payMonthlySelected)}
                >
                    Complete Payment
                </ButtonLinkWrapper>
            </div>
        );
    }
}
interface PropShape extends React.Props<any> {
    paymentOptions: PaymentOption[];
    setSelectedPaymentOption: Function;
    totalPrice: string;
    formValid: boolean;
    dueToday: string;
    dueMonthly: string;
    numberOfMonthsToPay: string;
    nextUrl: urlPaths;
    selectedOption: PaymentOption;
}

interface StateShape {
    // Shape of local state
    payMonthly: boolean;
    payMonthlySelected: boolean;
    infoIconOpen: boolean;
}

export default CheckTrustPaymentContentComponent;
