import React from 'react';
import ApplicationStyling from '../../../../static/models/ApplicationStyling';
import './PageCardTitleComponent.css';

class PageCardTitleComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined,
        id: '',
        ariaLabel: 'title'
    };

    render() {
        const idAddition = this.props.id ? `-${this.props.id}` : '';
        return (
            <div
                className="card-title"
                style={{
                    fontWeight: this.props.stylingOptions?.fonts?.titleWeight,
                    fontFamily: this.props.stylingOptions?.fonts?.titleFontFamily,
                    textAlign: this.props.stylingOptions?.fonts?.titleAlignment,
                    color: this.props.stylingOptions?.colors?.titleColor,
                    fontSize: this.props.stylingOptions?.fonts?.titleFontSize,
                    marginBottom: this.props.stylingOptions?.spacing?.abovePageSubtitle
                }}
                aria-label={this.props.ariaLabel}
                id={`text-card-title${idAddition}`}
            >
                {this.props.children}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    id: string;
    ariaLabel?: string;
}

interface StateShape {
    // Shape of local state
}

export default PageCardTitleComponent;
