import { FundingProductType } from 'static/constants/enums/fundingProductType';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import recipientConsentIsStillNecessary from '../../reuseable-logic/recipient-identity-still-necessary';

export function beneficiaryCheckReroutes(state: GlobalStateShape) {
    if (recipientConsentIsStillNecessary(state)) {
        return urlPaths.recipientIdentity;
    }
    if (state.account.stylingKey === EARTH_STYLING_KEY && state.opportunity.pricing.paymentInitiated) {
        // Earth has different flow than everyone else
        return urlPaths.orderSummary;
    }

    // Trust doesn't need need the Beneficiary page
    if (state.opportunity.selectedFundingProduct.productType === FundingProductType.Trust) {
        return urlPaths.orderSummary;
    }
}
