// Action Type
export const SET_PAYMENT_IS_PREDETERMINED = 'SET_PAYMENT_IS_PREDETERMINED';
// Action
export function setPaymentIsPredeterminedAction(newValue: boolean) {
    return { type: SET_PAYMENT_IS_PREDETERMINED, newValue };
}
// Reducer
export function setPaymentIsPredeterminedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_PAYMENT_IS_PREDETERMINED:
            return action.newValue;
        default:
            return state;
    }
}
