// Action Type
export const SET_ACCOUNT_NOTIFICATION_EMAIL = 'SET_ACCOUNT_NOTIFICATION_EMAIL';
// Action
export function setAccountNotificationEmail(newValue: boolean) {
    return { type: SET_ACCOUNT_NOTIFICATION_EMAIL, newValue };
}
// Reducer
export function setAccountNotificationEmailReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_ACCOUNT_NOTIFICATION_EMAIL:
            return action.newValue;
        default:
            return state;
    }
}
