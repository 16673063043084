import React, { Fragment } from 'react';
import PageCardUnderlinedSectionWrapper from '../../../../global-components/page-card-components/page-card-underlined-section-component/PageCardUnderlinedSectionWrapper';
import CurrentHealthQuestionModalWrapper from '../../../../modal-components/health-question-components/current-health-question-modal-component/CurrentHealthQuestionModalWrapper';
import PastHealthQuestionModalWrapper from '../../../../modal-components/health-question-components/past-health-question-modal-component/PastHealthQuestionModalWrapper';
import './PurchaserOrderSummaryHealthQuestionsComponent.css';
import MockLinkWrapper from '../../../../global-components/mock-link-component/MockLinkWrapper';
import HealthImpactModalWrapper from '../../../../modal-components/disclaimer-components/specific-disclaimers/health-impact-modal-component/HealthImpactModalWrapper';

class PurchaserOrderSummaryHealthQuestionsComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            answeringHealthDisclaimer: false
        };
    }

    componentDidUpdate(oldProps: PropShape, oldState: StateShape) {
        if (this.props.isAiled || this.props.wasAiled) {
            // Incapacitated Rates
            if (!(oldProps.isAiled || oldProps.wasAiled)) {
                // Wasn't incapacitated a moment ago
                this.setState({
                    answeringHealthDisclaimer: true
                });
            }
        }
    }

    static defaultProps = {
        // Default prop values
        isAiled: false,
        wasAiled: false,
        clearCurrentHealthAnswer: () => {},
        clearPreviousHealthAnswer: () => {},
        healthQuestionsRelevant: false
    };

    render() {
        if (!this.props.healthQuestionsRelevant) {
            return null;
        }
        return (
            <Fragment>
                {![true, false].includes(this.props.wasAiled) && <PastHealthQuestionModalWrapper />}
                {![true, false].includes(this.props.isAiled) && <CurrentHealthQuestionModalWrapper />}
                {this.state.answeringHealthDisclaimer && (
                    <HealthImpactModalWrapper
                        allowRejection={false}
                        onAccept={() => {
                            this.setState({
                                answeringHealthDisclaimer: false
                            });
                        }}
                        onReject={() => {}}
                    />
                )}
                <PageCardUnderlinedSectionWrapper
                    sectionTitle="Health History Answers"
                    sectionId="total-amount"
                    summaryContent={
                        <div>
                            <div>Indicated Ailment?</div>
                            <div>{this.props.isAiled || this.props.wasAiled ? 'Yes' : 'No'}</div>
                        </div>
                    }
                    fullContent={
                        <Fragment>
                            <div className="editable-health-question">
                                <div>Is currently ailed?</div>
                                <div>{this.props.isAiled ? 'Yes' : 'No'}</div>
                                <MockLinkWrapper
                                    id="current-health-edit"
                                    onClick={() => this.props.clearCurrentHealthAnswer()}
                                >
                                    Edit
                                </MockLinkWrapper>
                            </div>
                            <div className="editable-health-question">
                                <div>Was recently ailed?</div>
                                <div>{this.props.wasAiled ? 'Yes' : 'No'}</div>
                                <MockLinkWrapper
                                    id="previous-health-edit"
                                    onClick={() => this.props.clearPreviousHealthAnswer()}
                                >
                                    Edit
                                </MockLinkWrapper>
                            </div>
                        </Fragment>
                    }
                />
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    isAiled: boolean;
    wasAiled: boolean;
    clearCurrentHealthAnswer: Function;
    clearPreviousHealthAnswer: Function;
    healthQuestionsRelevant: boolean;
}

interface StateShape {
    // Shape of local state
    answeringHealthDisclaimer: boolean;
}

export default PurchaserOrderSummaryHealthQuestionsComponent;
