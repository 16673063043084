import { connect } from 'react-redux';
import PurchaserOrderSummaryPageComponent from './PurchaserOrderSummaryPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getCartTotal } from '../../../toolboxes/reuseable-logic/pricing-calculator';
import { formatMonetaryAmount } from '../../../toolboxes/reuseable-logic/string-formatters';
import { formIsValid, validatePerson } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    let nextUrl = '';
    const isAgent = !!representativeId;

    if (isAgent) {
        nextUrl = urlPaths.representativeIdentity;
    } else {
        nextUrl = urlPaths.ssnCapture;
    }

    return {
        totalAmount: formatMonetaryAmount(getCartTotal(state).toString()),
        isPurchaserValid: formIsValid(getFormValues('purchaser')(state), validatePerson),
        nextUrl: nextUrl
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryPageComponent);
