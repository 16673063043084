// Action Type
export const SET_PHONE_VERIFIED_PURCHASER = 'SET_PHONE_VERIFIED_PURCHASER';
// Action
export function setPurchaserPhoneVerifiedAction(newValue: boolean) {
    return { type: SET_PHONE_VERIFIED_PURCHASER, newValue };
}
// Reducer
export function setPurchaserPhoneVerifiedReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_PHONE_VERIFIED_PURCHASER:
            return action.newValue;
        default:
            return state;
    }
}
