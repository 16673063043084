import { connect } from 'react-redux';
import RelationshipToRecipientFormComponent from './RelationshipToRecipientFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        relationShipToRecipient: getFormData(state, 'purchaser', 'relationshipToRecipient')
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipToRecipientFormComponent);
