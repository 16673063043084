import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import { validateErrorForm } from '../../../toolboxes/redux-form/redux-form-validation';
import TextAreaWrapper from '../base-form-components/text-area-component/TextAreaWrapper';
import { normalizePhoneNumber } from 'toolboxes/redux-form/redux-form-normalizers';
class ErrorPageFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FormWrapper formName="errorForm" validate={validateErrorForm}>
                <TextBoxWrapper
                    formName="errorForm"
                    fieldPath="name"
                    placeholder=""
                    labelString="Name"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
                <TextBoxWrapper
                    formName="errorForm"
                    fieldPath="email"
                    placeholder=""
                    labelString="Email"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
                <TextBoxWrapper
                    formName="errorForm"
                    normalize={normalizePhoneNumber}
                    fieldPath="phone"
                    placeholder=""
                    labelString="Phone Number"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
                <TextBoxWrapper
                    formName="errorForm"
                    fieldPath="subject"
                    placeholder=""
                    labelString="Subject"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
                <TextAreaWrapper
                    formName="errorForm"
                    fieldPath="details"
                    placeholder=""
                    labelString="Details"
                    extraFieldProps={{
                        disabled: false
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
}

interface StateShape {
    // Shape of local state
}

export default ErrorPageFormComponent;
