import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import { defaultSpacing } from '../../../../static/styling/default-form-spacing';
import MockLinkWrapper from '../../../global-components/mock-link-component/MockLinkWrapper';
import { Alert } from '@material-ui/lab';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { Info } from '@material-ui/icons';
import InfoPopupComponent from '../info-popup-component/InfoPopupComponent';
import { InputAdornment } from '@material-ui/core';

export const textBoxDefaultProps = {
    // Default Props
    extraFieldProps: {},
    placeholder: '',
    formName: '',
    spacing: defaultSpacing,
    labelString: '',
    ariaLabel: '',
    normalize: null,
    copyText: '',
    copyFunction: null,
    disabled: false,
    useLabelAsPlaceholder: false,
    stylingOptions: undefined
};

const renderField = (reduxFieldData: any) => {
    let shouldShowError = reduxFieldData.meta.touched && reduxFieldData.meta.invalid && !reduxFieldData.meta.active;

    let endAdornment = null;
    if (reduxFieldData.extraFieldProps?.disabledreason) {
        endAdornment = (
            <>
                <InputAdornment position="end">
                    <InfoPopupComponent
                        infoModalTitle="Why can I not edit this?"
                        infoModalContent={reduxFieldData.extraFieldProps?.disabledreason}
                    />
                </InputAdornment>
            </>
        );
    }
    return (
        <Fragment>
            <Input
                error={shouldShowError}
                {...reduxFieldData.input}
                {...reduxFieldData.extraFieldProps}
                placeholder={reduxFieldData.placeholder}
                type={reduxFieldData.type}
                id={reduxFieldData.id}
                fullWidth={true}
                disabled={reduxFieldData.disabled}
                style={{
                    backgroundColor: reduxFieldData.stylingOptions?.colors?.formFieldsBackgroundColor,
                    fontFamily: reduxFieldData.stylingOptions?.fonts?.bodyFontFamily,
                    color: reduxFieldData.stylingOptions?.colors?.formFieldsTextColor,
                    marginBottom: reduxFieldData.stylingOptions?.spacing?.innerBody
                }}
                inputProps={{
                    'aria-label': reduxFieldData.ariaLabel
                }}
                endAdornment={endAdornment}
            />
            {shouldShowError && <Alert severity="error">{reduxFieldData.meta.error}</Alert>}
        </Fragment>
    );
};

class TextBoxComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = textBoxDefaultProps;

    render() {
        let copyId = '';
        if (this.props.copyText) {
            copyId = this.props.copyText.toString().replace(' ', '-');
        }

        return (
            <Grid item {...this.props.spacing}>
                <div
                    style={{
                        marginRight: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : '',
                        marginLeft: this.props.stylingOptions?.spacing?.innerBody
                            ? `calc(${this.props.stylingOptions.spacing.innerBody} / 2)`
                            : ''
                    }}
                >
                    {!this.props.useLabelAsPlaceholder && <label>{this.props.labelString}</label>}
                    {this.props.copyFunction && (
                        <label style={{ float: 'right' }}>
                            <MockLinkWrapper onClick={() => this.props.copyFunction()} id={copyId}>
                                {this.props.copyText}
                            </MockLinkWrapper>
                        </label>
                    )}
                    <Field
                        id={`input-${this.props.formName}.${this.props.fieldPath}`}
                        name={this.props.fieldPath}
                        disabled={this.props.disabled}
                        placeholder={this.props.useLabelAsPlaceholder ? this.props.labelString : this.props.placeholder}
                        component={renderField}
                        type="text"
                        extraFieldProps={this.props.extraFieldProps}
                        {...this.props.extraFieldProps}
                        normalize={this.props.normalize}
                        ariaLabel={this.props.ariaLabel}
                        stylingOptions={this.props.stylingOptions}
                    />
                </div>
            </Grid>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    formName: string;
    fieldPath: string;
    placeholder: string;
    extraFieldProps: object;
    spacing: object;
    labelString: string;
    ariaLabel: string;
    normalize: Function;
    copyText: string;
    copyFunction: Function;
    disabled: boolean;
    useLabelAsPlaceholder: boolean;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default TextBoxComponent;
