import { connect } from 'react-redux';
import PaymentOptionSelectionComponent from './PaymentOptionSelectionComponent';
import GlobalStateShape from '../../../../../../redux/GlobalStateShape';
import { setSelectedPaymentOptionAction } from '../../../../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import { getTravelTotal, getAmountToBePaidTotal } from '../../../../../../toolboxes/reuseable-logic/pricing-calculator';
import { formatMonetaryAmount } from '../../../../../../toolboxes/reuseable-logic/string-formatters';

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    return {
        selectedOption: state.opportunity.pricing.paymentOptions.selectedOption,
        amountDueToday: formatMonetaryAmount(getTravelTotal(state) + myProps.paymentOption.amountOfFirstPayment),
        amountToBePaidTotal: formatMonetaryAmount(getAmountToBePaidTotal(state).toString())
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setSelectedPaymentOption: newValue => dispatch(setSelectedPaymentOptionAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionSelectionComponent);
