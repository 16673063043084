import React from 'react';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class UnderagePurchaserPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="UnderagePurchaser" metaPageNumber="1" metaPagebackAvailable="true">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Underage Purchaser</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Unfortunately, we cannot allow an underage purchaser to make a purchase through our site at this
                        time.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default UnderagePurchaserPageComponent;
