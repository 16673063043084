import React from 'react';
import { Redirect } from 'react-router-dom';
import MockLinkWrapper from '../../mock-link-component/MockLinkWrapper';
class MockLinkRedirectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        // Default prop values
        navigationSideAffect: null,
        hidden: false
    };

    handleClick() {
        if (this.props.navigationSideAffect) {
            this.props.navigationSideAffect();
        }
        this.setState({ redirecting: true });
    }

    render() {
        if (this.state.redirecting) {
            return <Redirect to={this.props.to} push />;
        }

        return (
            <MockLinkWrapper
                hide={this.props.hidden}
                id={this.props.linkId.toLowerCase()}
                onClick={() => this.handleClick()}
            >
                {this.props.children}
            </MockLinkWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    to: string;
    linkId: string;
    navigationSideAffect: Function;
    hidden: boolean;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
}

export default MockLinkRedirectionComponent;
