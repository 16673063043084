import React, { Fragment } from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { ProductCreationType } from './product-creation-subcategory-list';
import RadioGroupWrapper from '../base-form-components/radio-group-component/RadioGroupWrapper';
import ValueDisplay from '../../../static/models/ValueDisplay';
import { dataWrapper } from '../../../toolboxes/server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { Disposition } from '../../../static/constants/enums/disposition';
import { validateProductCreation } from '../../../toolboxes/redux-form/redux-form-validation';
import PriceInputWrapper from '../base-form-components/price-component/PriceInputWrapper';
import TextAreaWrapper from '../base-form-components/text-area-component/TextAreaWrapper';

class ProductCreationFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        selectedCategory: '',
        selectedProductCreationType: null,
        availableProductCreationTypes: [],
        clearFormExceptForCategory: () => {}
    };

    componentDidUpdate(prevProps: PropShape, prevState: StateShape) {
        if (prevProps.selectedCategory !== this.props.selectedCategory) {
            this.props.clearFormExceptForCategory();
        }
    }

    render() {
        const goodsAndServicesValue = dataWrapper(() => {
            return this.props.selectedProductCreationType.goodsAndServicesValue;
        }, '');
        const manufacturerApplicable = ['m_casket_cost', 'm_vault_cost', 'm_urn'].includes(goodsAndServicesValue);
        const modelNumberApplicable = ['m_casket_cost', 'm_vault_cost', 'm_urn'].includes(goodsAndServicesValue);
        const materialRequired = ['m_casket_cost', 'm_vault_cost'].includes(goodsAndServicesValue);
        const materialOptional = !materialRequired && ['m_urn'].includes(goodsAndServicesValue);
        const interiorDescriptionApplicable = ['m_casket_cost'].includes(goodsAndServicesValue);
        const exteriorDescriptionApplicable = ['m_casket_cost', 'm_vault_cost', 'm_urn'].includes(
            goodsAndServicesValue
        );
        // Urn is always cremation, and is not listed here as it's not necessary
        const dispositionApplicable = ['m_casket_cost', 'm_vault_cost'].includes(goodsAndServicesValue);
        return (
            <FormWrapper formName="productCreation" validate={validateProductCreation}>
                <SelectWrapper
                    formName="productCreation"
                    fieldPath="category"
                    labelString="Product Category"
                    valueDisplays={[
                        {
                            value: 'Professional Services',
                            display: 'Professional Services'
                        },
                        {
                            value: 'Transportation',
                            display: 'Transportation'
                        },
                        {
                            value: 'Merchandise',
                            display: 'Merchandise'
                        },
                        // Agents are more used to this terminology
                        {
                            value: 'Additional Items',
                            display: 'Cash Advance'
                        }
                    ]}
                />
                <SelectWrapper
                    formName="productCreation"
                    fieldPath="goodsAndServicesValue"
                    // Not actually a subcategory, but a goods and services option
                    labelString="Product Subcategory"
                    valueDisplays={this.props.availableProductCreationTypes}
                    extraFieldProps={{
                        disabled: !this.props.selectedCategory
                    }}
                />
                {this.props.selectedProductCreationType && this.props.selectedCategory && (
                    <Fragment>
                        <TextBoxWrapper formName="productCreation" fieldPath="name" labelString="Product Name" />
                        <PriceInputWrapper formName="productCreation" fieldPath="price" labelString="Price" />
                        <RadioGroupWrapper
                            valueDisplays={[
                                {
                                    value: true,
                                    display: 'Yes'
                                },
                                {
                                    value: false,
                                    display: 'No'
                                }
                            ]}
                            formName="productCreation"
                            fieldPath="priceGuaranteed"
                            labelString="Price Guaranteed?"
                        />
                        <RadioGroupWrapper
                            valueDisplays={[
                                {
                                    value: true,
                                    display: 'Yes'
                                },
                                {
                                    value: false,
                                    display: 'No'
                                }
                            ]}
                            formName="productCreation"
                            fieldPath="hasSalesTax"
                            labelString="Has Sales Tax?"
                        />
                        <TextAreaWrapper
                            formName="productCreation"
                            fieldPath="description"
                            labelString="Product Description (Optional)"
                            extraFieldProps={{
                                multiline: true,
                                rows: 3
                            }}
                        />
                        {manufacturerApplicable && (
                            <TextBoxWrapper
                                formName="productCreation"
                                fieldPath="manufacturer"
                                labelString="Manufacturer Name (Optional)"
                            />
                        )}
                        {modelNumberApplicable && (
                            <TextBoxWrapper
                                formName="productCreation"
                                fieldPath="modelNumber"
                                labelString="Model Number (Optional)"
                            />
                        )}
                        {materialRequired && (
                            <TextBoxWrapper formName="productCreation" fieldPath="material" labelString="Material" />
                        )}
                        {materialOptional && (
                            <TextBoxWrapper
                                formName="productCreation"
                                fieldPath="material"
                                labelString="Material (Optional)"
                            />
                        )}
                        {interiorDescriptionApplicable && (
                            <TextAreaWrapper
                                formName="productCreation"
                                fieldPath="interiorDescription"
                                labelString="Interior Description (Optional)"
                            />
                        )}
                        {exteriorDescriptionApplicable && (
                            <TextAreaWrapper
                                formName="productCreation"
                                fieldPath="exteriorDescription"
                                labelString="Exterior Description (Optional)"
                            />
                        )}
                        {dispositionApplicable && (
                            <SelectWrapper
                                formName="productCreation"
                                fieldPath="disposition"
                                labelString="Disposition"
                                valueDisplays={[
                                    {
                                        value: Disposition.Burial,
                                        display: Disposition.Burial
                                    },
                                    {
                                        value: Disposition.Cremation,
                                        display: Disposition.Cremation
                                    }
                                ]}
                            />
                        )}
                    </Fragment>
                )}
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    selectedCategory: string;
    selectedProductCreationType: ProductCreationType;
    availableProductCreationTypes: ValueDisplay[];
    clearFormExceptForCategory: () => void;
}

interface StateShape {
    // Shape of local state
}

export default ProductCreationFormComponent;
