// Action Type
export const SET_RECIPIENT_SAME_AS_PURCHASER = 'SET_RECIPIENT_SAME_AS_PURCHASER';
// Action
export function setOpportunityRecipientSameAsPurchaserAction(newValue: boolean) {
    return { type: SET_RECIPIENT_SAME_AS_PURCHASER, newValue };
}
// Reducer
export function setOpportunityRecipientSameAsPurchaserReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return null;
    }
    switch (action.type) {
        case SET_RECIPIENT_SAME_AS_PURCHASER:
            return action.newValue;
        default:
            return state;
    }
}
