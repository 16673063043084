import React from 'react';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import BackButtonLinkWrapper from 'components/global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import ButtonLinkGroupComponent from 'components/global-components/buttons/button-link-component/button-link-group-component/ButtonLinkGroupComponent';

class EverdaysBeneficiaryCheckPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setBeneficaryProvidedAction: () => {},
        firstPage: false
    };

    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="Beneficiary"
                metaPageNumber="1"
                metaPagebackAvailable="true"
                metaFirstPage={this.props.firstPage.toString()}
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Who should remaining funds be paid to</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Occasionally there are remaining proceeds after the services are paid for. You can designate a
                        specific beneficiary to receive these if they are available. You can set this now or later.
                    </PageCardTextWrapper>

                    <BackButtonLinkWrapper />

                    <ButtonLinkGroupComponent>
                        <ButtonLinkWrapper
                            to={urlPaths.beneficiary}
                            buttonId="addBeneficiary"
                            navigationSideAffect={() => {
                                this.props.setBeneficaryProvidedAction(false);
                            }}
                        >
                            Add a Beneficiary
                        </ButtonLinkWrapper>
                        <ButtonLinkWrapper
                            to={urlPaths.orderSummary}
                            buttonId="next"
                            navigationSideAffect={() => {
                                this.props.setBeneficaryProvidedAction(false);
                            }}
                        >
                            Give Proceeds to Estate
                        </ButtonLinkWrapper>
                    </ButtonLinkGroupComponent>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setBeneficaryProvidedAction: Function;
    firstPage: boolean;
}

interface StateShape {
    // Shape of local state
}

export default EverdaysBeneficiaryCheckPageComponent;
