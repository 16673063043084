import React from 'react';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import { formatRemoveNonNumeric } from '../../../../toolboxes/reuseable-logic/string-formatters';

class RecipientRelationshipNotListedPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountPhoneNumber: ''
    };

    render() {
        const cleanedPhoneNumber = formatRemoveNonNumeric(this.props.accountPhoneNumber);

        return (
            <FullContentCenterWrapper
                metaPageName="RecipientRelationshipNotListed"
                metaPageNumber=""
                metaPagebackAvailable="false"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>Relationship to Recipient Not Listed</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Unfortunately, we cannot continue this arrangement online at this time. The relationships listed
                        indicate insurable interest, and in order to purchase a loved one’s arrangement online, proof of
                        insurable interest (i.e. legal family relationship) is required to validate and protect your
                        loved one’s insurance policy.
                    </PageCardTextWrapper>
                    <PageCardTextWrapper>
                        Please have someone with a legal family relationship to your loved one plan on their behalf, if
                        your loved one is unable to plan for themselves, or call us at{' '}
                        <a href={'tel:' + cleanedPhoneNumber}>{this.props.accountPhoneNumber}</a> for assistance. Thank
                        you for understanding. You indicated that your relationship to the recipient was not listed.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountPhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default RecipientRelationshipNotListedPageComponent;
