import { connect } from 'react-redux';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import RadioGroupComponent from './RadioGroupComponent';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    return {
        currentValue: getFormData(state, myProps.formName, myProps.fieldPath),
        applicationStyling: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioGroupComponent);
