import React from 'react';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import { formatRemoveNonNumeric } from '../../../toolboxes/reuseable-logic/string-formatters';

class ContractsDeniedPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        accountPhoneNumber: ''
    };

    render() {
        const cleanedPhoneNumber = formatRemoveNonNumeric(this.props.accountPhoneNumber);

        return (
            <FullContentCenterWrapper metaPageName="ContractsDenied" metaPageNumber="5" metaPagebackAvailable="false">
                <PageCardWrapper>
                    <PageCardTitleWrapper>Contracts Declined</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        We are unable to continue with your purchase, as you've declined to sign the necessary
                        contracts. If you would like to sign the contracts, don't hesitate to reach us at
                        <a href={'tel:' + cleanedPhoneNumber}>{this.props.accountPhoneNumber}</a>.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountPhoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default ContractsDeniedPageComponent;
