import { connect } from 'react-redux';
import PurchaserOrderSummaryHealthQuestionsComponent from './PurchaserOrderSummaryHealthQuestionsComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setIsBedriddenOrAiledAction } from '../../../../../redux/yesNoAnswers/healthHistoryQuestions/isBedriddenOrAiled';
import { setWasBedriddenOrAiledAction } from '../../../../../redux/yesNoAnswers/healthHistoryQuestions/wasBedriddenOrAiledInLastFiveYears';
import { calculateHealthQuestionsRelevant } from '../../../../../toolboxes/reuseable-logic/incapacitated-calculator';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        isAiled: state.yesNoAnswers.healthHistoryQuestions.isBedriddenOrAiled,
        wasAiled: state.yesNoAnswers.healthHistoryQuestions.wasBedriddenOrAiledInLastFiveYears,
        healthQuestionsRelevant: calculateHealthQuestionsRelevant(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        clearCurrentHealthAnswer: () => dispatch(setIsBedriddenOrAiledAction(null)),
        clearPreviousHealthAnswer: () => dispatch(setWasBedriddenOrAiledAction(null))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryHealthQuestionsComponent);
