import { AxiosResponse, AxiosRequestConfig } from 'axios';
import getEnvironment from '../../env-config/get-environment';
import * as Sentry from '@sentry/browser';

export const baseUrl = getEnvironment().efuneralGatewayBaseUrl;
export const documentUrl = getEnvironment().customerContractOrReceiptUrl;
const efuneralApiKey = getEnvironment().efuneralGatewayKey;

export function sendRequest(
    axiosPromise: Promise<AxiosResponse<any>>,
    callback: (result: any, error: any) => void,
    logErrors = true
) {
    axiosPromise
        .then(response => {
            callback(response.data, undefined);
        })
        .catch(error => {
            if (logErrors) {
                console.error(error);
                Sentry.captureException(error);
            }
            callback(undefined, error);
        });
}

export function sendRequests(
    axiosRequests: { promise: Promise<AxiosResponse<any>>; key: string; ignoreErrors: boolean }[],
    callback: (result: any, error: any) => void
) {
    const dataFetchingAndStoringPromises = axiosRequests.map(request => {
        return new Promise((resolve, reject) => {
            request.promise
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (request.ignoreErrors) {
                        resolve(null);
                    } else {
                        Sentry.captureException(error);
                        reject(error);
                    }
                });
        });
    });

    Promise.all(dataFetchingAndStoringPromises)
        .then(finalPromiseValues => {
            let responses = {};
            finalPromiseValues.forEach((response, index) => {
                responses[axiosRequests[index].key] = response;
            });
            return callback(responses, undefined);
        })
        .catch(error => {
            console.error(error);
            return callback(undefined, error);
        });
}

const defaultAxiosConfiguration: AxiosRequestConfig = {
    headers: {
        'x-api-key': efuneralApiKey,
        'Content-Type': 'application/json'
    }
};

export const defaultGetConfiguration: AxiosRequestConfig = {
    ...defaultAxiosConfiguration
};

export const defaultPostConfiguration: AxiosRequestConfig = {
    ...defaultAxiosConfiguration
};

/* export const defaultPutConfiguration: AxiosRequestConfig = {
    ...defaultAxiosConfiguration
}

export const defaultDeleteConfiguration: AxiosRequestConfig = {
    ...defaultAxiosConfiguration
}

export const defaultPatchConfiguration: AxiosRequestConfig = {
    ...defaultAxiosConfiguration
} */
