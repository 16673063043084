import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_USE_FORM_LABELS_AS_PLACEHOLDERS = 'SET_USE_FORM_LABELS_AS_PLACEHOLDERS';
// Action
export function setUseFormLabelsAsPlaceholdersAction(newValue: boolean) {
    return { type: SET_USE_FORM_LABELS_AS_PLACEHOLDERS, newValue };
}
// Reducer
export function setUseFormLabelsAsPlaceholdersReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().useFormLabelsAsPlaceholders;
    }
    switch (action.type) {
        case SET_USE_FORM_LABELS_AS_PLACEHOLDERS:
            return action.newValue;
        default:
            return state;
    }
}
