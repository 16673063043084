import axios from 'axios';
import { baseUrl, defaultPostConfiguration, sendRequest } from '../web-request-tools';

export function runUpdateProductsOnOpportunity(
    opportunityId: string,
    productsArray: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/packages/${opportunityId}/updateProducts`, productsArray, defaultPostConfiguration),
        callback
    );
}

export function runCreateAgentProduct(
    productData: any,
    agentNumber: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/agent/product/${agentNumber}`, productData, defaultPostConfiguration),
        callback
    );
}

export function runCreateSalesForceAgentProduct(
    productData: any,
    agentId: string,
    accountId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/agent/${agentId}/product/${accountId}`, productData, defaultPostConfiguration),
        callback
    );
}
