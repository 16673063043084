import React from 'react';
import './MiniatureYesNoComponent.css';
import MockLinkWrapper from '../mock-link-component/MockLinkWrapper';
import { ReactComponent as PencilIcon } from '../../../static/media/pencil.svg';
import YesNoWrapper from '../buttons/yes-no-component/YesNoWrapper';

class MiniatureYesNoComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        if (![true, false].includes(this.props.answer)) {
            return (
                <div>
                    <div className="mini-yes-no-text">
                        {this.props.questionText}
                        {this.props.onHelpClick && (
                            <MockLinkWrapper
                                id={`${this.props.id}-help`}
                                title={`${this.props.title} Help`}
                                onClick={() => this.props.onHelpClick()}
                            >
                                {' '}
                                What is this?
                            </MockLinkWrapper>
                        )}
                    </div>
                    <YesNoWrapper
                        onYesClick={() => this.props.onYesClick()}
                        onNoClick={() => this.props.onNoClick()}
                        answer={this.props.answer}
                        yesNoId={this.props.yesNoId}
                        yesNoTitle={this.props.title}
                    />
                </div>
            );
        }
        return (
            <div>
                <b>{this.props.questionText}</b>
                <br />
                {this.props.answer ? 'Yes' : 'No'}
                <br />
                <MockLinkWrapper
                    id={this.props.id}
                    title={`${this.props.title}`}
                    onClick={() => this.props.onEditClick()}
                >
                    <PencilIcon style={{ verticalAlign: 'text-bottom' }} /> Edit this information
                </MockLinkWrapper>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    id?: string;
    questionText: string;
    answer?: boolean;
    onYesClick: Function;
    onNoClick: Function;
    onEditClick: Function;
    onHelpClick?: Function;
    yesNoId: string;
    title?: string;
}

interface StateShape {
    // Shape of local state
}

export default MiniatureYesNoComponent;
