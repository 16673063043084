import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import recipientConsentIsStillNecessary from 'toolboxes/reuseable-logic/recipient-identity-still-necessary';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';

const ssnCaptureReroutes = (state: GlobalStateShape): string => {
    if (!state.opportunity.pricing.paymentInitiated) {
        return urlPaths.payment;
    }
    if (
        state.account.stylingKey === EARTH_STYLING_KEY &&
        !state.disclaimerData.disclaimerAddressings.annuityInsurance.hasBeenAcceptedOrPositivelyAddressed
    ) {
        return urlPaths.disclaimerProduct;
    }
    if (recipientConsentIsStillNecessary(state)) {
        return urlPaths.prefilledRecipientIdentity;
    }
};

export default ssnCaptureReroutes;
