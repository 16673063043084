import React, { Fragment } from 'react';
import { PaymentMode } from 'static/constants/enums/paymentModes';
import ApplicationStyling from 'static/models/ApplicationStyling';
import PaymentOption from 'static/models/PaymentOption';
import { formatMonetaryAmount } from 'toolboxes/reuseable-logic/string-formatters';
import EarthTotalsGridWrapper from '../earth-totals-grid-component/EarthTotalsGridWrapper';

class EarthPaymentPlanDetailsComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        selectedFuneralPaymentPlan: null,
        hasTravel: false,
        travelTotal: 0
    };

    render() {
        if (!this.props.selectedFuneralPaymentPlan) {
            return null;
        }
        // Figure out what the grids need to show
        const orderSummaryKeyValues: {
            key: string;
            value: string;
        }[] = [];
        const dueTodayKeyValues: {
            key: string;
            value: string;
        }[] = [];
        // Switch between single and multi pay
        const EARTH_PACKAGE_DENOTATION = 'Earth™ Prepaid Plan';
        if (this.props.selectedFuneralPaymentPlan.paymentMode === PaymentMode.Single) {
            orderSummaryKeyValues.push({
                key: EARTH_PACKAGE_DENOTATION,
                value: formatMonetaryAmount(this.props.selectedFuneralPaymentPlan.amountOfFirstPayment)
            });
        } else {
            orderSummaryKeyValues.push({
                key: EARTH_PACKAGE_DENOTATION,
                value: `${formatMonetaryAmount(this.props.selectedFuneralPaymentPlan.premium)} / month`
            });
            orderSummaryKeyValues.push({
                key: 'Payment term',
                value: `${this.props.selectedFuneralPaymentPlan.years * 12} months`
            });
            dueTodayKeyValues.push({
                key: 'First monthly payment',
                value: formatMonetaryAmount(this.props.selectedFuneralPaymentPlan.amountOfFirstPayment)
            });
        }
        // Check for travel protection
        if (this.props.hasTravel) {
            orderSummaryKeyValues.push({
                key: 'Travel protection',
                value: formatMonetaryAmount(this.props.travelTotal)
            });
            if (this.props.selectedFuneralPaymentPlan.paymentMode !== PaymentMode.Single) {
                dueTodayKeyValues.push({
                    key: 'Travel protection',
                    value: formatMonetaryAmount(this.props.travelTotal)
                });
            }
        }
        // Render
        return (
            <Fragment>
                <EarthTotalsGridWrapper title="Order summary" keyValueDisplayPairs={orderSummaryKeyValues} />
                {dueTodayKeyValues.length > 0 && (
                    <EarthTotalsGridWrapper title="Due today" keyValueDisplayPairs={dueTodayKeyValues} />
                )}
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    selectedFuneralPaymentPlan?: PaymentOption;
    hasTravel: boolean;
    travelTotal: number;
}

interface StateShape {
    // Shape of local state
}

export default EarthPaymentPlanDetailsComponent;
