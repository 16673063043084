import urlPaths from '../../static/constants/enums/urlPaths';

// Action Type
export const SET_VITAL_STATISTICS_URL = 'SET_VITAL_STATISTICS_URL';
// Action
export function setVitalStatisticsUrlAction(newValue: string) {
    return { type: SET_VITAL_STATISTICS_URL, newValue };
}
// Reducer
export function setVitalStatisticsUrlReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return urlPaths.vitalStatistics;
    }
    switch (action.type) {
        case SET_VITAL_STATISTICS_URL:
            return action.newValue;
        default:
            return state;
    }
}
