import React from 'react';
import ApplicationStyling from '../../../static/models/ApplicationStyling';

class MockLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        stylingOptions: undefined,
        hide: false
    };

    render() {
        return (
            <span
                hidden={this.props.hide}
                style={{
                    cursor: 'pointer',
                    color: this.props.stylingOptions.colors.primaryColor
                }}
                onClick={() => this.props.onClick()}
                id={`link-${this.props.id}`}
                aria-label={this.props.title}
            >
                {this.props.children}
            </span>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    stylingOptions: ApplicationStyling;
    onClick: Function;
    id: string;
    title?: string;
    hide: boolean;
}

interface StateShape {
    // Shape of local state
}

export default MockLinkComponent;
