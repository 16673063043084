import { Info } from '@material-ui/icons';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import GeneralModalWrapper from 'components/modal-components/general-modal-component/GeneralModalWrapper';
import React from 'react';

class InfoPopupComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            isInfoPopupOpen: false
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        const handleClick = () => {
            this.setState({
                isInfoPopupOpen: !this.state.isInfoPopupOpen
            });
        };

        return (
            <>
                <Info onClick={handleClick} />
                {this.state.isInfoPopupOpen && (
                    <GeneralModalWrapper onRequestClose={() => this.setState({ isInfoPopupOpen: false })}>
                        <FullContentCenterWrapper metaPageName="" metaPageNumber="" metaPagebackAvailable="">
                            <PageCardTitleWrapper>{this.props.infoModalTitle}</PageCardTitleWrapper>
                            <PageCardTextWrapper>{this.props.infoModalContent}</PageCardTextWrapper>
                        </FullContentCenterWrapper>
                    </GeneralModalWrapper>
                )}
            </>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    infoModalTitle: string;
    infoModalContent: string;
}

interface StateShape {
    // Shape of local state
    isInfoPopupOpen: boolean;
}

export default InfoPopupComponent;
