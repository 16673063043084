import { connect } from 'react-redux';
import PurchaserInformationComponent from './PurchaserInformationComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    const formData = getFormValues('purchaser')(state);

    // These values will be passed in as props
    return {
        formData,
        purchaser: state.opportunity.purchaser
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserInformationComponent);
