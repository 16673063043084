import React from 'react';
import DisclaimerAddressing from '../../../../../static/models/disclaimers/DisclaimerAddressing';
import DisclaimerModalWrapper from '../../disclaimer-modal-component/DisclaimerModalWrapper';
import { GeographicState } from '../../../../../static/constants/enums/states';

class IrrevocableConfirmationModalComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        setIrrevocableAction: () => {},
        setIrrevocabilityAddressingAction: () => {},
        onClose: () => {},
        purchaserState: ''
    };

    render() {
        return (
            <DisclaimerModalWrapper
                acceptanceText="I Agree"
                rejectionText="Cancel"
                disclaimerTitle="Please Confirm your Selection"
                saveDisclaimerAddressingToRedux={this.props.setIrrevocabilityAddressingAction}
                onAccept={() => {
                    this.props.setIrrevocableAction(true);
                    this.props.onClose();
                }}
                onReject={() => {
                    this.props.onClose();
                }}
            >
                <div style={{ fontSize: '1.4rem' }}>
                    <div className="disclaimer-paragraph">
                        It is important that you understand the impact of choosing to make your policy irrevocable.
                    </div>
                    <div className="disclaimer-paragraph">
                        A common reason for doing this is to qualify for Medicaid or other public assistance programs.
                        By making this policy irrevocable, you are waiving your rights to cancel or receive a refund of
                        the monies paid. If you elect to not make it irrevocable now, you are able to change it at a
                        later date and time.
                    </div>
                    <div className="disclaimer-paragraph">
                        If you are unsure, you can always make a policy irrevocable at a later time if you choose to
                        decline the option now. If you choose to make your policy irrevocable, the irrevocable period
                        begins after the initial 30-day free look period.
                    </div>
                    <div className="disclaimer-paragraph">
                        If you have questions, please consult with an attorney or a Medicaid specialist.
                    </div>
                    {this.props.purchaserState === GeographicState.OK && (
                        <div className="disclaimer-paragraph">
                            <h3>OK Additional Medicaid Information</h3>
                            Oklahoma Medicaid rules limit the face amount to $20,000 on irrevocable cantracts.
                            <br />
                            Oklahoma has a $20,000 maximum face at the time of issue for Medicaid.
                        </div>
                    )}
                </div>
            </DisclaimerModalWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    onClose: Function;
    setIrrevocableAction: Function;
    setIrrevocabilityAddressingAction: (addressing: DisclaimerAddressing) => void;
    purchaserState: string;
}

interface StateShape {
    // Shape of local state
}

export default IrrevocableConfirmationModalComponent;
