import { connect } from 'react-redux';
import ButtonSubtitledLinkGroupComponent from './ButtonSubtitledLinkGroupComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import accountComponentSwitch from '../../../../../toolboxes/account-specific-component-switch/account-flexible-connect';
import { TITAN_STYLING_KEY } from '../../../../../static/constants/styling-company-constants/companies/titan';
import TitanButtonSubtitledLinkGroupWrapper from '../../../../account-specific-components/accounts/titan/button-subtitled-link-group-component/TitanButtonSubtitledLinkGroupWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(ButtonSubtitledLinkGroupComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [TITAN_STYLING_KEY]: TitanButtonSubtitledLinkGroupWrapper
});
