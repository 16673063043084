import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_IRREVOCABILITY_ADDRESSING = 'SET_IRREVOCABILITY_ADDRESSING';
// Action
export function setIrrevocabilityAddressingAction(newValue: DisclaimerAddressing) {
    return { type: SET_IRREVOCABILITY_ADDRESSING, newValue };
}
// Reducer
export function setIrrevocabilityAddressingReducer(state: any, action: any): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_IRREVOCABILITY_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
