import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import { formIsValid, stringIsRequired } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import funeralDirectorStates from '../../../static/constants/stateSpecificLists/funeralDirectorStates';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { OpportunityStage } from '../../../static/constants/opportunityStages';
import {
    // getBlockScorePassingScore,
    numberOfPurchaserPhoneVerificationTriesAllowed
} from '../../../static/constants/identity-verification-allowances';
import { contractsAreComplete } from '../../../toolboxes/reuseable-logic/contractCompleteness';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { Relationships } from 'static/constants/enums/relationships';
import {
    getPurchaserValidationFunction,
    getRecipientValidationFunction
} from 'toolboxes/reuseable-logic/demographics-utils';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { Timing } from 'static/constants/enums/timing';
import { beneficiaryIsComplete } from 'toolboxes/reuseable-logic/beneficiary-is-complete';
import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';

export default function calculatePageToJumpTo(state: GlobalStateShape): string {
    if (state.opportunity.activeStage === OpportunityStage.ClosedLost) {
        return urlPaths.expired;
    }

    const vitalStatisticsSaved = window.sessionStorage.getItem('vitalStatisticsSaved');
    if (vitalStatisticsSaved) {
        const now = new Date().toISOString();
        const vitalStatisticsSavedValue = JSON.parse(vitalStatisticsSaved);

        if (now > vitalStatisticsSavedValue.expiration) {
            window.sessionStorage.removeItem('vitalStatisticsSaved');
        } else {
            // On closed Won opportunities we aren't givien any contact information and very limited opportunity information
            // So if closed won we don't want to show the purchase processing page
            if (
                vitalStatisticsSavedValue.vitalStatisticsSaved === 'false' &&
                state.opportunity.activeStage !== OpportunityStage.ClosedWon
            ) {
                return urlPaths.purchaseProcessing;
            }

            if (vitalStatisticsSavedValue.vitalStatisticsSaved === 'true') {
                return urlPaths.purchaserSaleComplete;
            }
        }
    }

    if (!state.opportunity.id) {
        // The server refused to send us data, we need to give it security details
        return urlPaths.securityCheck;
    }

    if (state.urlPaths.overridePage) {
        // We were given an override page
        return `${state.urlPaths.overridePage}`;
    }

    if (state.opportunity.activeStage === OpportunityStage.ClosedWon) {
        // The full process is complete
        return urlPaths.purchaserSaleComplete;
    }

    if (contractsAreComplete(state)) {
        // The full process is complete
        return urlPaths.purchaseProcessing;
    }

    if (
        state.opportunity.stopPageDetail === 'Stopped because they failed identity too many times' ||
        state.opportunity.stopPageDetail ===
            'Stopped because purchaser decided to provide alternative form of identification' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid - special reasons'
    ) {
        return urlPaths.unavailableIdentityFailed;
    }

    if (state.opportunity.stopPageDetail === 'Stopped because relationship to recipient not listed') {
        return urlPaths.recipientRelationshipNotListed;
    }

    if (state.featureFlags.purchaserPhoneVerificationReact) {
        if (
            state.opportunity.purchaser.timesPhoneVerificationSubmitted &&
            state.opportunity.purchaser.timesPhoneVerificationSubmitted >=
                numberOfPurchaserPhoneVerificationTriesAllowed &&
            state.opportunity.purchaser.phoneVerified !== true
        ) {
            return urlPaths.unavailableIdentityFailed;
        }

        if (
            state.opportunity.purchaser.identityVerificationPlaid &&
            state.opportunity.purchaser.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
        ) {
            return urlPaths.purchaserPhoneVerificationRequest;
        }
    } else {
        if (
            state.opportunity.purchaser.identityVerificationPlaid &&
            state.opportunity.purchaser.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
        ) {
            return urlPaths.unavailableIdentityFailed;
        }
    }

    const validatePurchaserFunction = getPurchaserValidationFunction(state);
    if (!formIsValid(getFormValues('purchaser')(state), values => validatePurchaserFunction({ ...values }))) {
        const representativeId = getFormData(state, 'representative', 'id');
        const homesteadersMarketCode = getFormData(state, 'representative', 'homesteadersMarketCode');

        if ((representativeId && homesteadersMarketCode) || state.opportunity.hideDemographicsDisclaimer) {
            return urlPaths.purchaser;
        }
        return urlPaths.demographicsInstructions;
    }

    if (
        state.opportunity.timing !== Timing.IMMEDIATE &&
        funeralDirectorStates.includes(state.account.state) &&
        getFormData(state, 'purchaser', 'state') !== state.account.state
    ) {
        return urlPaths.unavailableFuneralDirectorState;
    }

    const validateRecipientFunction = getRecipientValidationFunction(state, true);
    if (!state.opportunity.recipient.sameAsPurchaser) {
        if (state.opportunity.recipient.existancePredetermined) {
            if (
                state.opportunity.preneedContractType !== PreneedContractType.Trust &&
                state.opportunity.timing !== Timing.IMMEDIATE
            ) {
                if (!formIsValid(getFormData(state, 'purchaser', 'relationshipToRecipient'), stringIsRequired)) {
                    return urlPaths.relationshipToRecipient;
                }
                if (getFormData(state, 'purchaser', 'relationshipToRecipient') === Relationships.NotListed) {
                    // This check is needed in case someone selected "Not listed" but for some reason the stop page detail didn't get set on the opportunity.
                    // This can happen if there's an error while attempting to set the stop page information in the page enter function.
                    return urlPaths.recipientRelationshipNotListed;
                }
            }
            // SSN Not sent back over
            /**
             * DEV2020-1745 (https://efuneral.atlassian.net/browse/DEV2020-1745)
             * Originally, the following if-statement included a check to see if recipient consent was still necessary. It was added as part of DEV2020-1341
             * to create a fallback for if SSN flag accidentally gets turned off. However, this allowed someone who was planning for Other to proceed without
             * adding any recipient information and caused the recipient to be listed on the Opportunity as [not provided]. On 2020/12/16 at Standup, it was
             * decided that this fallback was no longer needed.
             **/

            /**
             * DEV2020-2227
             * Identity Verification Completed looks to two fields on the Opportunity -- Twilio Phone Verified and Identity Validated. If either
             * are true, then the Recipient has completed Identity Verification. This story was updated to include a fix for dropping a user
             * on the Recipient page after a Load Opportunity even if they entered SSN.
             */
            /**
             * EFIT-3238
             * Tulip was able to pass enough information through to skip recipient demogrpahics page. In
             * doing so it was skipping the collection of SSN. With this we have created a flag on the backend
             * to see if the ssn has been provided. If it hasn't then we need to drop them on the recipient
             * demographics page.
             */
            if (
                (!formIsValid(getFormValues('recipient')(state), values => validateRecipientFunction({ ...values })) &&
                    !state.opportunity.recipient.identityVerificationCompleted) ||
                !state.opportunity.recipient.ssnProvided
            ) {
                return urlPaths.recipient;
            }
        } else {
            // SSN Not sent back over
            if (!formIsValid(getFormValues('recipient')(state), values => validateRecipientFunction({ ...values }))) {
                return urlPaths.recipientCheck;
            }
        }
    }

    // DEV2020-2163 - do not check product disclaimers for Immediates
    if (state.opportunity.timing !== Timing.IMMEDIATE) {
        if (
            state.opportunity.preneedContractType === PreneedContractType.Trust &&
            !state.disclaimerData.disclaimerAddressings.trustFundedPrearrangement.hasBeenAcceptedOrPositivelyAddressed
        ) {
            return urlPaths.trustFundedPrearrangement;
        } else if (
            !state.disclaimerData.disclaimerAddressings.annuityInsurance.hasBeenAcceptedOrPositivelyAddressed &&
            !state.opportunity.hideAnnuityDisclaimer &&
            state.opportunity.preneedContractType !== PreneedContractType.Trust
        ) {
            return urlPaths.disclaimerProduct;
        }
    }

    if (isAtNeed(state) && formIsValid(getFormValues('recipient')(state), validateRecipientFunction)) {
        // Can't have a beneficiary
        return urlPaths.orderSummary;
    }
    if (isAtNeed(state) && state.opportunity.pricing.paymentInitiated) {
        return urlPaths.purchaseProcessing;
    }

    if (!beneficiaryIsComplete(state)) {
        return urlPaths.beneficiaryCheck;
    }

    if (!state.opportunity.pricing.paymentInitiated) {
        return urlPaths.orderSummary;
    }

    // DEV2020-1478 - using the new opportunity stages
    // if a customer has gotten to identity or contracts
    // they are in 'Purchasing Intent', because we don't have
    // the 'Purchaser Identity' stage, we will send them to orderSummary
    if (
        state.opportunity.activeStage === OpportunityStage.PaymentInfo ||
        state.opportunity.activeStage === OpportunityStage.PurchasingIntent
    ) {
        return urlPaths.orderSummary;
    }

    if (!contractsAreComplete(state)) {
        if (state.featureFlags.useNewOpportunityStages) {
            if (
                state.representative.didHandoff &&
                !state.opportunity.purchaserSigned &&
                ![OpportunityStage.ClosedLost, OpportunityStage.ClosedWon].includes(state.opportunity.activeStage)
            ) {
                return urlPaths.purchaserOrderSummary;
            }
        } else {
            if (
                state.representative.didHandoff &&
                ![
                    OpportunityStage.AgentSignContract,
                    OpportunityStage.PurchaserSignContract,
                    OpportunityStage.PurchaserIdentityVerification,
                    OpportunityStage.ClosedLost,
                    OpportunityStage.ClosedWon
                ].includes(state.opportunity.activeStage)
            ) {
                return urlPaths.purchaserOrderSummary;
            }
        }
        if (state.featureFlags.purchaserPhoneVerificationReact) {
            if (state.opportunity.purchaser.identityVerificationPlaid === null) {
                return urlPaths.purchaserIdentity;
            } else {
                return urlPaths.purchaserPhoneVerificationRequest;
            }
        } else {
            return urlPaths.purchaserIdentity;
        }
    }

    return urlPaths.error;
}
