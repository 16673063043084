import React, { CSSProperties } from 'react';
import requiredScripts from '../../../static/constants/requiredScripts';
import '../../../static/styling/GlobalStyles.css';
import './AppComponent.css';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { BrowserRouter } from 'react-router-dom';
import Script from 'react-load-script';
import TagManager from 'react-gtm-module';
import RoutingWrapper from '../../routing-component/RoutingWrapper';
import LoadingScreenWrapper from '../loading-screen-component/LoadingScreenWrapper';
import HeaderWrapper from '../limb-components/header-component/HeaderWrapper';
import FooterWrapper from '../limb-components/footer-component/FooterWrapper';
import { getBasePath } from '../../../toolboxes/reuseable-logic/get-base-path';

class TemplateComponent extends React.Component<PropShape, StateShape> {
    constructor(props: any) {
        super(props);
        // Starting state values
        this.state = {
            numberOfScriptsToLoad: requiredScripts.length,
            tagManagerLoaded: false
        };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }

    static defaultProps = {
        // Default prop values
        applicationMuiTheme: {},
        applicationTheme: {},
        googleTagManagerId: ''
    };

    handleScriptLoad() {
        this.setState({
            numberOfScriptsToLoad: this.state.numberOfScriptsToLoad - 1
        });
    }

    componentDidUpdate() {
        if (!!this.props.googleTagManagerId && !this.state.tagManagerLoaded) {
            const tagManagerArgs = {
                gtmId: this.props.googleTagManagerId,
                dataLayerName: 'accountDataLayer'
            };

            TagManager.initialize(tagManagerArgs);
            this.setState({ tagManagerLoaded: true });
        }
    }

    render() {
        const basePath = getBasePath();
        const ContentWrapper = this.state.numberOfScriptsToLoad === 0 ? <RoutingWrapper /> : <LoadingScreenWrapper />;
        return (
            <ThemeProvider theme={this.props.applicationMuiTheme}>
                {requiredScripts.map((url, index) => {
                    return <Script url={url} onLoad={this.handleScriptLoad} key={`script-${index}`} />;
                })}
                <span
                    className="application-flex-container"
                    id="styled-application-root"
                    style={this.props.applicationTheme}
                >
                    <HeaderWrapper />
                    <BrowserRouter basename={basePath}>{ContentWrapper}</BrowserRouter>
                    <FooterWrapper />
                </span>
            </ThemeProvider>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    applicationMuiTheme: any;
    applicationTheme: CSSProperties;
    googleTagManagerId: string;
}

interface StateShape {
    // Shape of local state
    numberOfScriptsToLoad: number;
    tagManagerLoaded: boolean;
}

export default TemplateComponent;
