// Action Type
export const SET_OPPORTUNITY_SENT_VITAL_STATISTICS_EMAIL = 'SET_OPPORTUNITY_SENT_VITAL_STATISTICS_EMAIL'; // Action
export function setOpportunitySentVitalStatisticsEmailAction(newValue: boolean) {
    return { type: SET_OPPORTUNITY_SENT_VITAL_STATISTICS_EMAIL, newValue };
}
// Reducer
export function setOpportunitySentVitalStatisticsEmailReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return false;
    }
    switch (action.type) {
        case SET_OPPORTUNITY_SENT_VITAL_STATISTICS_EMAIL:
            return action.newValue;
        default:
            return state;
    }
}
