import GlobalStateShape from '../../../redux/GlobalStateShape';
import SSNCaptureFormComponent from './SSNCaptureFormComponent';
import { connect } from 'react-redux';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const ssnValueName = state.featureFlags.SSNToLast4Digits ? 'ssnLastFour' : 'ssn';
    const ssnInput = getFormData(state, 'SSNCaptureData', ssnValueName);
    const showErrorMessage = !!ssnInput;
    // These values will be passed in as props
    return {
        SSNToLast4Digits: state.featureFlags.SSNToLast4Digits,
        showErrorMessage
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SSNCaptureFormComponent);
