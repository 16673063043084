import React from 'react';
import { getFormattedPriceForProduct } from 'toolboxes/reuseable-logic/string-formatters';

class ProductCardComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        product: null
    };

    render() {
        if (!this.props.product) {
            return null;
        }
        return (
            <div>
                <div style={{ fontWeight: 'bold', fontSize: 'large' }}>{this.props.product.category}</div>
                <div>
                    {this.props.product.quantity}x {this.props.product.name}
                </div>
                <div>{getFormattedPriceForProduct(this.props.product)}</div>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    product?: any;
}

interface StateShape {
    // Shape of local state
}

export default ProductCardComponent;
