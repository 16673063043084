import React, { Fragment } from 'react';
import DisabledField from 'static/models/DisabledField';
import PersonDemographicsFormWrapper from '../person-demographics-form-component/PersonDemographicsFormWrapper';

class PurchaserFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <Fragment>
                <PersonDemographicsFormWrapper
                    canCopyPurchaserInfo={false}
                    formName="purchaser"
                    validate={this.props.validationFunction}
                    hideBirthdate={this.props.isAtNeed}
                    hideGender={this.props.isAtNeed}
                    disableFields={this.props.disableFields}
                />
            </Fragment>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    validationFunction: (personData: any) => any;
    isAtNeed: boolean;
    disableFields?: DisabledField[];
}

interface StateShape {
    // Shape of local state
}

export default PurchaserFormComponent;
