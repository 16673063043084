import ApplicationStyling from '../../../models/ApplicationStyling';
import { TabStyling } from '../../../models/TabStyling';
export const titanStyling: ApplicationStyling = {
    colors: {
        secondaryContrastColor: '#FFFFFF',
        primaryContrastColor: '#FFFFFF',
        footerBackgroundColor: '#092461',
        sectionSeperatorColor: '#FFFFFF',
        titleColor: '#1A1A1A',
        bodyColor: '#1A1A1A',
        primaryColor: '#092461',
        secondaryColor: '#1A1A1A',
        headerBottomBorderColor: '#FFFFFF',
        headerFontColor: '#092461',
        headerBackgroundColor: '#FFFFFF',
        footerFontColor: '#F2F6FF',
        generalBackgroundColor: '#FFFFFF',
        formFieldsBackgroundColor: '#FFFFFF',
        formFieldsTextColor: '#092461',
        placeholderFullOpacity: false
    },
    fonts: {
        buttonFontFamily: 'Cabin',
        titleFontFamily: 'LeMonde Livre Classic',
        bodyFontFamily: 'Cabin',
        titleAlignment: 'center',
        bodyAlignment: 'center',
        titleWeight: 'normal',
        bodyWeight: 'normal',
        titleFontSize: '38px',
        bodyFontSize: '18px',
        buttonFontSize: '12px'
    },
    sizing: {
        headerLogoMaxHeight: '30px'
    },
    spacing: {
        abovePageTitle: '36px',
        abovePageSubtitle: '50px',
        abovePageBodyContent: '24px',
        innerBody: '12px',
        aboveContinueButton: '36px',
        xs: '8px',
        sm: '12px',
        md: '24px',
        lg: '36px',
        xl: '48px',
        xxl: '72px'
    },
    showNameInTitle: false,
    useFormLabelsAsPlaceholders: true
};

export const titanTabConstants: TabStyling = {
    faviconUrl: null,
    tabName: 'Titan'
};

export const TITAN_STYLING_KEY = 'titancasket';

export const TITAN_HEADER_BREAK_POINT = 700;
