import { connect } from 'react-redux';
import ReplacementPageComponent from './ReplacementPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { setHasCurrentPoliciesAction } from 'redux/yesNoAnswers/policyQuestions/hasCurrentPolicies';
import { setReplacingCurrentPoliciesAction } from 'redux/yesNoAnswers/policyQuestions/replacingCurrentPolicies';
import { getPolicyContractText } from '../../../toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        hasCurrentPolicies: state.yesNoAnswers.policyQuestions.hasCurrentPolicies
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setHasCurrentPoliciesAction: newValue => dispatch(setHasCurrentPoliciesAction(newValue)),
        setReplacingCurrentPoliciesAction: newValue => dispatch(setReplacingCurrentPoliciesAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementPageComponent);
