import { connect } from 'react-redux';
import ReplacementCheckComponent from './ReplacementCheckComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { setHasCurrentPoliciesAction } from '../../../../../redux/yesNoAnswers/policyQuestions/hasCurrentPolicies';
import { setReplacingCurrentPoliciesAction } from '../../../../../redux/yesNoAnswers/policyQuestions/replacingCurrentPolicies';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        hasCurrentPolicies: state.yesNoAnswers.policyQuestions.hasCurrentPolicies,
        replacingCurrentPolicies: state.yesNoAnswers.policyQuestions.replacingCurrentPolicies
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setHasCurrentPoliciesAction: newValue => dispatch(setHasCurrentPoliciesAction(newValue)),
        setReplacingCurrentPoliciesAction: newValue => dispatch(setReplacingCurrentPoliciesAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementCheckComponent);
