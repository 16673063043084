import { combineReducers } from 'redux';
import { setAccountAgentIdReducer } from './id';
import { setAccountAgentAgentCodeReducer } from './agentCode';
import { setAccountAgentCanAddProductsReducer } from './canAddProducts';

export default combineReducers({
    id: setAccountAgentIdReducer,
    agentCode: setAccountAgentAgentCodeReducer,
    canAddProducts: setAccountAgentCanAddProductsReducer
});
