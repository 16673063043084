import { connect } from 'react-redux';
import PurchaserPageComponent from './PurchaserPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import { formIsValid } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { firstPageCalculator } from 'toolboxes/reuseable-logic/first-page-calculator';
import urlPaths from 'static/constants/enums/urlPaths';
import { getPurchaserValidationFunction } from 'toolboxes/reuseable-logic/demographics-utils';
import { isAtNeed } from 'toolboxes/reuseable-logic/opportunity-timing-utils';
import { getPolicyContractText } from '../../../toolboxes/reuseable-logic/language-utils';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const representativeId = getFormData(state, 'representative', 'id');
    const validationFunction = getPurchaserValidationFunction(state);
    return {
        purchaserComplete: formIsValid(getFormValues('purchaser')(state), validationFunction),
        isAgent: !!representativeId,
        firstPage: firstPageCalculator(state, urlPaths.purchaser),
        validationFunction,
        isAtNeed: isAtNeed(state),
        policyContractLanguage: getPolicyContractText(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserPageComponent);
