import { connect } from 'react-redux';
import PageCardTextComponent, { PropShape } from './PageCardTextComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, ownProps: Partial<PropShape>) => {
    // These values will be passed in as props
    return {
        ...ownProps,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCardTextComponent);
