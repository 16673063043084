import React from 'react';
import './PinInputComponent.css';
import { Input } from '@material-ui/core';
import ApplicationStyling from 'static/models/ApplicationStyling';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;
const DELETE_KEY = 46;

class PinInputComponent extends React.Component<PropShape, StateShape> {
    textInputs: any[] = [];

    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            inputs: [],
            value: null
        };

        //const inputs = []
        for (let i = 0; i < this.props.inputCount; i++) {
            this.state.inputs.push('');
        }
        //this.handleValueChange(inputs);

        this.handleFocus = this.handleFocus.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    static defaultProps = {
        // Default prop values
        autoFocus: false,
        length: null,
        onChange: null,
        stylingOptions: undefined
    };

    componentDidMount() {
        if (this.props.autoFocus && this.textInputs.length > 0) {
            this.textInputs[0].focus();
        }
    }

    handlePaste(e) {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain');

        if (pasteData) {
            const pasteDigitData = pasteData.replace(/\D/g, '');

            if (pasteDigitData) {
                const inputs = [...this.state.inputs];
                const dataArray = pasteDigitData.split('');

                for (let i = 0; i < this.textInputs.length; i++) {
                    if (i < dataArray.length || dataArray.length === this.textInputs.length) {
                        inputs[i] = dataArray[i];
                        this.textInputs[i].value = dataArray[i];
                    } else if (dataArray.length < this.textInputs.length) {
                        this.textInputs[i].select();
                        return;
                    }
                }

                this.handleValueChange(inputs);
            }
        }
    }

    handleFocus(e) {
        if (e.target.value) {
            e.target.select();
            return;
        }
        for (let i = 0; i < this.textInputs.length; i++) {
            if (!this.textInputs[i].value) {
                this.textInputs[i].focus();
                return;
            }
        }
    }

    handleChange(e) {
        let targetValue = String(e.target.value);
        let digitValue = targetValue.replace(/\D/g, '');
        let inputs = [...this.state.inputs];

        if (!digitValue) {
            e.target.value = '';
            return;
        }

        if (digitValue.length > 1) {
            digitValue = digitValue[digitValue.length - 1];
        }

        inputs[e.target.dataset.id] = digitValue;
        this.textInputs[e.target.dataset.id].value = digitValue;

        const newTarget = this.textInputs[Number(e.target.dataset.id) + 1];
        if (newTarget) {
            newTarget.focus();
            newTarget.select();
        }

        this.handleValueChange(inputs);
    }

    handleKeyDown(e) {
        const targetId = Number(e.target.dataset.id);
        const nextTarget = this.textInputs[targetId + 1];
        const prevTarget = this.textInputs[targetId - 1];

        let inputs = [...this.state.inputs];

        switch (e.keyCode) {
            case BACKSPACE_KEY:
                e.preventDefault();
                inputs[targetId] = '';
                this.textInputs[targetId].value = '';

                if (prevTarget) {
                    prevTarget.focus();
                    prevTarget.select();
                }

                this.handleValueChange(inputs);
                break;
            case RIGHT_ARROW_KEY:
                e.preventDefault();
                if (nextTarget) {
                    nextTarget.focus();
                    nextTarget.select();
                }
                break;
            case LEFT_ARROW_KEY:
                e.preventDefault();
                if (prevTarget) {
                    prevTarget.focus();
                    prevTarget.select();
                }
                break;
            case DELETE_KEY:
                e.preventDefault();
                let nextValue = '';
                for (let i = targetId; i < this.state.inputs.length; i++) {
                    if (i !== this.state.inputs.length - 1) {
                        nextValue = this.state.inputs[i + 1];
                    } else {
                        nextValue = '';
                    }
                    inputs[i] = nextValue;
                    this.textInputs[i].value = nextValue;
                }

                this.handleValueChange(inputs);
                break;
            default:
                if (e.key.toString() === e.target.value) {
                    e.preventDefault();

                    if (nextTarget) {
                        nextTarget.focus();
                        nextTarget.select();
                    }
                    return;
                }
                break;
        }
    }

    handleValueChange(inputs) {
        const value = inputs.join('');
        this.setState({ value, inputs });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div className="code-input">
                {this.state.inputs.map((value, i) => {
                    return (
                        <Input
                            id={`input-${this.props.id}-${i}`}
                            key={`${this.props.id}-${i}`}
                            type="text"
                            autoComplete="off"
                            onFocus={this.handleFocus}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            onPaste={this.handlePaste}
                            inputRef={ref => {
                                this.textInputs[i] = ref;
                            }}
                            inputProps={{
                                'data-id': i,
                                inputMode: 'numeric'
                            }}
                            style={{
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor
                            }}
                        />
                    );
                })}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    id: string;
    autoFocus: boolean;
    inputCount: number;
    onChange: Function;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    inputs: string[];
    value: string;
}

export default PinInputComponent;
