import { connect } from 'react-redux';
import ProductCreationFormComponent from './ProductCreationFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import {
    findSelectedProductCreationType,
    calculateAvailableProductCreationTypes
} from './product-subcategory-calculator';
import { change } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const selectedCategory = getFormData(state, 'productCreation', 'category');
    return {
        selectedCategory,
        selectedProductCreationType: findSelectedProductCreationType(
            getFormData(state, 'productCreation', 'goodsAndServicesValue')
        ),
        availableProductCreationTypes: calculateAvailableProductCreationTypes(state)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        clearFormExceptForCategory: () => {
            dispatch(change('productCreation', 'goodsAndServicesValue', ''));
            dispatch(change('productCreation', 'price', ''));
            dispatch(change('productCreation', 'priceGuaranteed', ''));
            dispatch(change('productCreation', 'hasSalesTax', ''));
            dispatch(change('productCreation', 'description', ''));
            dispatch(change('productCreation', 'manufacturer', ''));
            dispatch(change('productCreation', 'modelNumber', ''));
            dispatch(change('productCreation', 'material', ''));
            dispatch(change('productCreation', 'interiorDescription', ''));
            dispatch(change('productCreation', 'exteriorDescription', ''));
            dispatch(change('productCreation', 'disposition', ''));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreationFormComponent);
