import React from 'react';
import Button from '@material-ui/core/Button';
import ApplicationStyling from 'static/models/ApplicationStyling';
import { defaultTheme } from 'static/styling/default-theme';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

class EarthBackButtonLinkComponent extends React.Component<MyPropShape, StateShape> {
    constructor(props: MyPropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        theme: defaultTheme,
        textColor: '#000000',
        stylingOptions: undefined
    };

    render() {
        return (
            <span
                style={{
                    position: 'absolute',
                    top: '3px',
                    left: '5px'
                }}
            >
                <Button
                    variant="text"
                    id="back"
                    onClick={() => window.history.back()}
                    style={{
                        color: this.props.stylingOptions?.colors?.headerFontColor,
                        fontFamily: this.props.stylingOptions?.fonts?.bodyFontFamily,
                        fontSize: this.props.stylingOptions?.fonts?.bodyFontSize
                    }}
                >
                    <KeyboardBackspaceIcon style={{ marginRight: '5px' }} />
                    Back
                </Button>
            </span>
        );
    }
}

export interface MyPropShape {
    // Shape of passed in props (including redux dispatch functions)
    theme: any;
    textColor: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
}

export default EarthBackButtonLinkComponent;
