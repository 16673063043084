import { connect } from 'react-redux';
import StandardDisclaimerPageComponent from './StandardDisclaimerPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    return {
        opportunityId: state.opportunity.id,
        acceptingContactId: myProps.acceptingContactId || state.opportunity.purchaser.id
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardDisclaimerPageComponent);
