import { sendRequest, defaultGetConfiguration, baseUrl, defaultPostConfiguration } from '../web-request-tools';
import axios from 'axios';

export function fetchAccountBySubdomain(subdomain: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/FuneralHome/SubDomain/all/${subdomain}`, defaultGetConfiguration), callback);
}

export function fetchTravelAccountByFuneralAccountId(accountId: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.get(`${baseUrl}/api/travelProtection/${accountId}/provider`, defaultGetConfiguration), callback);
}

export function fetchProductsForAccountId(accountId: string, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/account/${accountId}/all_products`, {}, defaultPostConfiguration), callback);
}

export function fetchAgentProductsForAccountId(accountId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(`${baseUrl}/api/account/${accountId}/agent_products`, {}, defaultPostConfiguration),
        callback
    );
}

export function fetchAccountsBySearchTerms(searchTerms: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.get(`${baseUrl}/api/funeralHome/search/subdomain/${searchTerms}`, defaultGetConfiguration),
        callback
    );
}
