export function calculateAge(birthDate: Date) {
    const currentDate = new Date(Date.now());
    let years = currentDate.getFullYear() - birthDate.getFullYear();
    if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
        years--;
    }
    return years;
}
