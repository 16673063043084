// Action Type
export const SET_STYLING_SHOW_NAME_IN_HEADER = 'SET_STYLING_SHOW_NAME_IN_HEADER';
// Action
export function setStylingShowNameInTitleAction(newValue: boolean) {
    return { type: SET_STYLING_SHOW_NAME_IN_HEADER, newValue };
}
// Reducer
export function setStylingShowNameInTitleReducer(state: any, action: any): boolean {
    if (typeof state === 'undefined') {
        // Initial data
        return true;
    }
    switch (action.type) {
        case SET_STYLING_SHOW_NAME_IN_HEADER:
            return action.newValue;
        default:
            return state;
    }
}
