import { connect } from 'react-redux';
import RelationshipToRecipientPageComponent from './RelationshipToRecipientPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormData } from '../../../toolboxes/redux-form/redux-form-data-access';
import { Relationships } from 'static/constants/enums/relationships';
import urlPaths from 'static/constants/enums/urlPaths';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const relationship = getFormData(state, 'purchaser', 'relationshipToRecipient');
    // These values will be passed in as props
    return {
        relationshipIsSelected: !!relationship,
        reachedStopPage: state.opportunity.stopPageDetail === 'Stopped because relationship to recipient not listed',
        nextUrl: relationship === Relationships.NotListed ? urlPaths.recipientRelationshipNotListed : urlPaths.recipient
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipToRecipientPageComponent);
