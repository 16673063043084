import reservedSubdomains from '../../static/constants/reservedSubdomains';

export function getBasePath(): string {
    let subdomain = '';

    let host = window.location.host;
    let splitHost = host.split('.');
    if (splitHost.length > 1) {
        subdomain = splitHost[0];
    }

    let basePath = '';
    if (subdomain && reservedSubdomains.indexOf(subdomain) === -1) {
        basePath = '/checkout';
    }

    return basePath;
}

export function getSubdomain(): string {
    const urlSplit = window.location.host.split('.');
    return urlSplit[0];
}
