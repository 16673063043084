import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { formIsValid, validateBeneficiary } from '../../redux-form/redux-form-validation';
import { sendSaveBeneficiaryData } from '../../server-application-buffer/contact-data';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendSaveBeneficiaryRecipientSamePersonDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';

export function saveBeneficaryData(state: GlobalStateShape): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    if (formIsValid(getFormValues('beneficiary')(state), validateBeneficiary)) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveBeneficiaryData(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.funeralContracts]
        });
    }

    if (state.disclaimerData.disclaimerAddressings.beneficiaryRecipientSamePerson.responseToDisclaimer) {
        functionsToRun.push({
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveBeneficiaryRecipientSamePersonDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: []
        });
    }

    return functionsToRun;
}
