import { sortedCategoryStrings } from '../../static/constants/sortedCategories';
import Product from '../../static/models/Product';
import { initialProduct } from '../../redux/opportunity/products';

export const generateFullCategoryString = (category: string, subcategory: string) => {
    let fullCategoryString = '';
    if (category) {
        if (subcategory) {
            fullCategoryString = `${category} - ${subcategory}`;
        } else {
            fullCategoryString = `${category} - Uncategorized`;
        }
    } else {
        fullCategoryString = 'Uncategorized';
    }
    if (!sortedCategoryStrings.includes(fullCategoryString)) {
        fullCategoryString = 'Uncategorized';
    }
    return fullCategoryString;
};

export const generateFullCategoryStringForProduct = (product: Product) => {
    return generateFullCategoryString(product.category, product.subcategory);
};

export const getSortedApplicableCategories = (products: Product[]) => {
    let productCategoriesFound: string[] = [];
    products.forEach(product => {
        const categoryString = generateFullCategoryStringForProduct(product);
        if (!productCategoriesFound.includes(categoryString)) {
            productCategoriesFound.push(categoryString);
        }
    });
    let sortedApplicableCategories: string[] = [];
    sortedCategoryStrings.forEach(categoryString => {
        if (productCategoriesFound.includes(categoryString)) {
            sortedApplicableCategories.push(categoryString);
        }
    });
    return sortedApplicableCategories;
};

export const getMileageFeeProduct = (mileageCost: string) => {
    const mileageProduct: Product = {
        ...initialProduct,
        name: 'Mileage Fee',
        category: 'Transportation',
        quantity: 1,
        price: Number(mileageCost)
    };
    return mileageProduct;
};
