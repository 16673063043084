import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import productCodeMustBeCP from 'static/constants/stateSpecificLists/productCodeMustBeCP';
import GlobalStateShape from '../../redux/GlobalStateShape';
import { FundingProductType } from '../../static/constants/enums/fundingProductType';
import { GeographicState } from '../../static/constants/enums/states';
import { Timing } from '../../static/constants/enums/timing';
import homesteadersProductCodeToContractNumberMappings from '../../static/constants/homesteadersProductCodeToContractNumberMappings';
import noAnnuityStates from '../../static/constants/stateSpecificLists/noAnnuityStates';
import { noFundingProductAvailableStates } from '../../static/constants/stateSpecificLists/noFundingProductAvailableStates';
import FundingProduct from '../../static/models/FundingProduct';
import { getFormData } from '../redux-form/redux-form-data-access';
import { dataWrapper } from '../server-application-buffer/generalized-data-buffer-tools/data-wrapper';
import { calculateAge } from './age-calculator';
import { getInsuredBirthday } from './insured-calculator';

export default function calculateFundingProductTypeWithExplicits(
    purchaserState: GeographicState,
    recipientState: GeographicState,
    insuredBirthdate: string,
    opportunityTiming: Timing,
    preneedContractType: PreneedContractType
): FundingProductType {
    const formattedInsuredBirthdate = Date.parse(insuredBirthdate);
    const insuredAge = calculateAge(new Date(formattedInsuredBirthdate));

    if (preneedContractType === PreneedContractType.Trust) {
        return FundingProductType.Trust;
    }

    if (
        noFundingProductAvailableStates.includes(purchaserState) ||
        noFundingProductAvailableStates.includes(recipientState)
    ) {
        return null;
    }

    if (noAnnuityStates.includes(purchaserState)) {
        if (insuredAge >= 99) {
            return null;
        }
        return FundingProductType.Insurance;
    }

    if (purchaserState === GeographicState.FL) {
        if (insuredAge >= 99) {
            return null;
        }
        if (insuredAge >= 65) {
            return FundingProductType.Insurance;
        }
    }

    if (opportunityTiming === Timing.IMMINENT) {
        return FundingProductType.Annuity;
    }

    if (opportunityTiming === Timing.FUTURE) {
        return FundingProductType.Insurance;
    }

    return null;
}

export function calculateFundingProductWithExplicits(
    purchaserState: GeographicState,
    recipientState: GeographicState,
    funeralHomeState: GeographicState,
    insuredBirthdate: string,
    opportunityTiming: Timing,
    fundingProducts: FundingProduct[],
    preneedContractType: PreneedContractType
): FundingProduct {
    const fundingProductType = calculateFundingProductTypeWithExplicits(
        purchaserState,
        recipientState,
        insuredBirthdate,
        opportunityTiming,
        preneedContractType
    );

    const fundingProductsAvailable = fundingProducts.filter(fundingProduct => {
        return dataWrapper(() => fundingProduct.productType === fundingProductType, false);
    });

    if (fundingProductsAvailable.length !== 1) {
        throw new Error('Found multiple possible funding products');
    }

    /**
     * DEV2020-1956
     * If purchaser state IS Michigan and Virginia AND Account State IS NOT Michigan or Virginia assign
     * contract product code to CP and use Market Code ??.
     * Else, if the purchaser state IS NOT Michigan or Virginia AND Account State IS Michigan or Virginia assign
     * contract product code EZ and use Market Code ??.
     * Else Pull both product code and market code from the funeral home.
     */
    /*
     * DEV2020-2150
     * Updating logic from DEV2020-1956
     * Now, only checking if purchaser state is Michigan or Virginia to assigned product code CP
     * The account state does not matter when setting CP
     */
    if (
        productCodeMustBeCP.includes(purchaserState) &&
        fundingProductsAvailable[0].productType === FundingProductType.Insurance
    ) {
        // Annuities and trust OK to sell as is
        const fundingProduct: FundingProduct = {
            productId: 'CP',
            contractId: homesteadersProductCodeToContractNumberMappings['CP'],
            provider: fundingProductsAvailable[0].provider,
            productType: fundingProductsAvailable[0].productType
        };

        return fundingProduct;
    }

    if (
        !productCodeMustBeCP.includes(purchaserState) &&
        productCodeMustBeCP.includes(funeralHomeState) &&
        fundingProductsAvailable[0].productType === FundingProductType.Insurance
    ) {
        // Annuities and trust OK to sell as is
        const fundingProduct: FundingProduct = {
            productId: 'EZ',
            contractId: homesteadersProductCodeToContractNumberMappings['EZ'],
            provider: fundingProductsAvailable[0].provider,
            productType: fundingProductsAvailable[0].productType
        };

        return fundingProduct;
    }

    return fundingProductsAvailable[0];
}

export function calculateFundingProductType(state: GlobalStateShape): FundingProductType {
    const purchaserState = getFormData(state, 'purchaser', 'state');
    const recipientState = getFormData(state, 'recipient', 'state');
    const insuredBirthdate = getInsuredBirthday(state);
    const opportunityTiming = state.opportunity.timing;
    const preneedContractType = state.opportunity.preneedContractType;
    return calculateFundingProductTypeWithExplicits(
        purchaserState,
        recipientState,
        insuredBirthdate,
        opportunityTiming,
        preneedContractType
    );
}

export function calculateFundingProduct(state: GlobalStateShape): FundingProduct {
    const purchaserState = getFormData(state, 'purchaser', 'state');
    const recipientState = getFormData(state, 'purchaser', 'state');
    //@ts-ignore
    const funeralHomeState: GeographicState = state.account.state;
    const insuredBirthdate = getInsuredBirthday(state);
    const opportunityTiming = state.opportunity.timing;
    const fundingProducts = state.account.fundingProducts;
    const preneedContractType = state.opportunity.preneedContractType;
    return calculateFundingProductWithExplicits(
        purchaserState,
        recipientState,
        funeralHomeState,
        insuredBirthdate,
        opportunityTiming,
        fundingProducts,
        preneedContractType
    );
}

export function calculateHomesteadersFundingProductsForAccount(accountProductCode: string): FundingProduct[] {
    if (!accountProductCode) {
        return [];
    }
    return [
        {
            productId: 'AN',
            provider: {
                account: {
                    knownAs: 'Homesteaders',
                    name: 'Homesteaders Life Company'
                },
                key: 'Homesteaders'
            },
            contractId: 'Not Used',
            productType: FundingProductType.Annuity
        },
        {
            productId: accountProductCode,
            provider: {
                account: {
                    knownAs: 'Homesteaders',
                    name: 'Homesteaders Life Company'
                },
                key: 'Homesteaders'
            },
            contractId: homesteadersProductCodeToContractNumberMappings[accountProductCode],
            productType: FundingProductType.Insurance
        },
        {
            productId: accountProductCode,
            provider: {
                account: {
                    knownAs: 'ClearPoint',
                    name: 'ClearPoint Federal Bank & Trust'
                },
                key: 'ClearPoint'
            },
            contractId: 'Not Used',
            productType: FundingProductType.Trust
        }
    ];
}
