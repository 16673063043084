import { combineReducers } from 'redux';
import { setOpportunityRecipientIdReducer } from './id';
import { setOpportunityRecipientSameAsPurchaserReducer } from './sameAsPurchaser';
import { setRecipientIsUnavailableReducer } from './isUnavailable';
import { setRecipientExistancePredeterminedReducer } from './existancePredetermined';
import { setOpportunityRecipientIdentityVerificationCompletedReducer } from './identityVerificationCompleted';
import { setRecipientSsnProvidedReducer } from './ssnProvided';
import { setOpportunityRecipientIdentityVerificationPlaidReducer } from './identityVerificationPlaid';

export default combineReducers({
    id: setOpportunityRecipientIdReducer,
    identityVerificationCompleted: setOpportunityRecipientIdentityVerificationCompletedReducer,
    sameAsPurchaser: setOpportunityRecipientSameAsPurchaserReducer,
    isUnavailable: setRecipientIsUnavailableReducer,
    existancePredetermined: setRecipientExistancePredeterminedReducer,
    ssnProvided: setRecipientSsnProvidedReducer,
    identityVerificationPlaid: setOpportunityRecipientIdentityVerificationPlaidReducer
});
