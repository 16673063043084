import { sendRequest, baseUrl, defaultPostConfiguration, defaultGetConfiguration } from '../web-request-tools';
import axios from 'axios';
import getEnvironment from 'env-config/get-environment';

export function runOpportunityCreation(fakeOpportunityData: any, callback: (result: any, error: any) => void) {
    sendRequest(axios.post(`${baseUrl}/api/purchaseLanding`, fakeOpportunityData, defaultPostConfiguration), callback);
}

export function runAgentOpportunitySetting(
    opportunityId,
    agentInformation,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/agent/opportunity/${opportunityId}`, agentInformation, defaultPostConfiguration),
        callback
    );
}

export function runUpdateOpportunity(
    opportunityId: string,
    accountId: string,
    opportunityData: any,
    callback: (result: any, error: any) => void
) {
    if (!opportunityId) {
        callback(undefined, 'No opportunity Id provided');
        return;
    }
    if (!accountId) {
        callback(undefined, 'No account Id provided');
        return;
    }
    sendRequest(
        axios.post(
            `${baseUrl}/api/account/${accountId}/create_opportunity`,
            {
                opportunityId,
                // If not creating demo records, don't override whatever is already set to avoid unexpected behavior
                demoRecord: getEnvironment().createDemoRecords ? true : null,
                ...opportunityData
            },
            defaultPostConfiguration
        ),
        callback
    );
}

export function runUpdateStageName(opportunityId: string, stageData: any, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(`${baseUrl}/api/opportunity/${opportunityId}/stagename`, stageData, defaultPostConfiguration),
        callback
    );
}

export function runSubmitHealthHistoryAnswers(
    recipientId: string,
    healthQuestionData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/contact/${recipientId}/medicalQuestions`,
            healthQuestionData,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runIrrevocableUpdate(
    irrevocableData: any,
    opportunityId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/irrevocable`,
            irrevocableData,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runStateFilingFeeUpdate(
    opportunityId: string,
    stateFee: number,
    customerServiceFee: number,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/associatedSales/stateFilingFee`,
            {
                stateFee,
                customerServiceFee
            },
            defaultPostConfiguration
        ),
        callback
    );
}

export function runSaveCustomerPathAddition(
    opportunityId: string,
    newPath: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/opportunity/${opportunityId}/addToCustomerPath`, newPath, defaultPostConfiguration),
        callback
    );
}

/**
 * DEV2020-2171
 * Since adding a new addition to the Customer Path field in Salesforce requires accessing
 * the Opportunity multiple times, often paths weren't getting added or we ended up with the
 * "Unable to Lock Row" error. This adds an association object to the Opportunity rather than
 * updates the Opportunity itself so we don't run into these issues.
 */
export function runSaveCustomerPathAssociation(
    opportunityId: string,
    newPath: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(
            `${baseUrl}/api/opportunity/${opportunityId}/addCustomerPathAssociation`,
            newPath,
            defaultPostConfiguration
        ),
        callback
    );
}

export function runCalculateContractCodes(opportunityId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.get(`${baseUrl}/api/contractCodesCalculator/${opportunityId}`, defaultGetConfiguration),
        callback
    );
}
