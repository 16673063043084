import getEnvironment from '../../../env-config/get-environment';
import axios from 'axios';
import { sendRequest } from '../web-request-tools';
import { FeatureFlagResult } from 'static/models/network/FeatureFlagResult';

export function getRequestForGlobalFeatureFlags() {
    return axios.get(`${getEnvironment().featuresUrl}/flags`, {
        //@ts-ignore
        'Content-Type': 'application/json'
    });
}

export function getSpecificFeatureFlag(featureFlagDescription: string, callback: (result: boolean, error) => void) {
    sendRequest(
        axios.get(`${getEnvironment().featuresUrl}/flags?description=${featureFlagDescription}`),
        (result, error) => {
            if (error) {
                return callback(null, error);
            }
            return callback(result[0].enabled, null);
        }
    );
}

export function fetchGlobalFeatureFlags(callback: (result: FeatureFlagResult[], error) => void) {
    sendRequest(getRequestForGlobalFeatureFlags(), callback, false);
}
