import React from 'react';
import ButtonLinkWrapper from 'components/global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from 'static/constants/enums/urlPaths';
import MockLinkWrapper from 'components/global-components/mock-link-component/MockLinkWrapper';

class FailingRequestButtonComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            hidden: true
        };
    }

    static defaultProps = {
        // Default prop values
        disabled: true,
        footerBackgroundColor: '#FFFFFF'
    };

    render() {
        return (
            <div>
                {this.state.hidden && (
                    <MockLinkWrapper
                        onClick={() =>
                            this.setState({
                                hidden: false
                            })
                        }
                        id="toggleFailingButtonVisability"
                    >
                        <div style={{ color: this.props.footerBackgroundColor, textAlign: 'center' }}>
                            Show Failing Request Button
                        </div>
                    </MockLinkWrapper>
                )}
                {!this.state.hidden && (
                    <ButtonLinkWrapper
                        disabled={this.props.disabled}
                        to={urlPaths.sendTestFailingRequest}
                        buttonId="sendFailingRequest"
                        hidden={this.props.disabled && this.state.hidden}
                    >
                        Send Failing Request
                    </ButtonLinkWrapper>
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    disabled: boolean;
    footerBackgroundColor: string;
}

interface StateShape {
    // Shape of local state
    hidden: boolean;
}

export default FailingRequestButtonComponent;
