import React from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement, ReactStripeElements } from 'react-stripe-elements';
import './InjectedStripeComponent.css';
import { ReactComponent as CardIcon } from '../../../../static/media/card-icon.svg';
import { ReactComponent as LockIcon } from '../../../../static/media/lock-icon.svg';
import { stringIsRequired, stringOfLengthRequired } from '../../../../toolboxes/redux-form/redux-form-validation';
import ApplicationStyling from 'static/models/ApplicationStyling';

class InjectedStripeComponent extends React.Component<ReactStripeElements.InjectedStripeProps & PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            postalCode: '',
            cardholderName: ''
        };
        this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
        this.createStripeToken = this.createStripeToken.bind(this);
    }

    componentDidUpdate(oldProps: PropShape) {
        if (this.props.processPayment && !oldProps.processPayment) {
            this.createStripeToken();
        }
    }

    handlePostalCodeChange(newValue: string) {
        let formattedValue = newValue;
        if (formattedValue.length > 5) {
            formattedValue = formattedValue.substring(0, 5);
        }
        formattedValue = formattedValue.replace(/\D/g, '');
        this.setState({
            postalCode: formattedValue
        });
    }

    handleCardholderNameChange(newValue: string) {
        this.setState({
            cardholderName: newValue
        });
    }

    createStripeToken() {
        if (stringOfLengthRequired(this.state.postalCode, 5, 5)) {
            return this.props.onSubmissionError('Zip code is invalid');
        }
        if (stringIsRequired(this.state.cardholderName)) {
            return this.props.onSubmissionError('Cardholder name required');
        }
        if (this.props.stripe) {
            this.props.stripe
                // eslint-disable-next-line
                .createToken({ name: this.state.cardholderName, address_zip: this.state.postalCode })
                .then(response => {
                    if (response.error) {
                        return this.props.onSubmissionError(response.error.message);
                    } else {
                        this.props.setCreditCardLastFour(response.token.card.last4);
                        return this.props.onStripeTokenCreation(response.token);
                    }
                });
        } else {
            return this.props.onSubmissionError('Some functionality is still loading. Please try again momentarily.');
        }
    }

    static defaultProps = {
        // Default prop values
        // eslint-disable-next-line prettier/prettier
        setCreditCardLastFour: () => {},
        stylingOptions: undefined
    };

    render() {
        return (
            <form
                style={{
                    marginRight: this.props.stylingOptions.spacing?.innerBody
                        ? `calc(${this.props.stylingOptions.spacing?.innerBody} / -2)`
                        : '-5px',
                    marginLeft: this.props.stylingOptions.spacing?.innerBody
                        ? `calc(${this.props.stylingOptions.spacing?.innerBody} / -2)`
                        : '-5px'
                }}
            >
                <div>
                    <div style={{ display: 'flex' }}>
                        <input
                            className="stripe-element-container"
                            value={this.state.cardholderName}
                            onChange={e => this.handleCardholderNameChange(e.target.value)}
                            placeholder="CARDHOLDER NAME"
                            style={{
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor,
                                marginBottom: this.props.stylingOptions.spacing?.innerBody
                                    ? this.props.stylingOptions.spacing.innerBody
                                    : ''
                            }}
                        />
                    </div>
                    <div
                        className="stripe-element-container"
                        style={{
                            display: 'flex',
                            backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                            color: this.props.stylingOptions.colors.formFieldsTextColor,
                            marginBottom: this.props.stylingOptions.spacing?.innerBody
                                ? this.props.stylingOptions.spacing.innerBody
                                : ''
                        }}
                    >
                        <CardIcon />
                        <div
                            style={{
                                flexGrow: 1,
                                marginLeft: '10px',
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor
                            }}
                        >
                            <CardNumberElement
                                style={{ base: { color: this.props.stylingOptions.colors.formFieldsTextColor } }}
                            />
                        </div>
                    </div>
                    <div className="bottom-card-info-container">
                        <div
                            className="stripe-element-container"
                            style={{
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor,
                                marginBottom: this.props.stylingOptions.spacing?.innerBody
                                    ? this.props.stylingOptions.spacing.innerBody
                                    : ''
                            }}
                        >
                            <CardExpiryElement
                                style={{ base: { color: this.props.stylingOptions.colors.formFieldsTextColor } }}
                            />
                        </div>
                        <div
                            className="stripe-element-container"
                            style={{
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor,
                                marginBottom: this.props.stylingOptions.spacing?.innerBody
                                    ? this.props.stylingOptions.spacing.innerBody
                                    : ''
                            }}
                        >
                            <CardCVCElement
                                style={{
                                    base: {
                                        color: this.props.stylingOptions.colors.formFieldsTextColor
                                    }
                                }}
                            />
                        </div>
                        <input
                            className="stripe-element-container"
                            value={this.state.postalCode}
                            onChange={e => this.handlePostalCodeChange(e.target.value)}
                            placeholder="ZIP"
                            inputMode="numeric"
                            style={{
                                backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
                                color: this.props.stylingOptions.colors.formFieldsTextColor,
                                marginBottom: this.props.stylingOptions.spacing?.innerBody
                                    ? this.props.stylingOptions.spacing.innerBody
                                    : ''
                            }}
                        />
                    </div>
                    <div
                        style={{
                            marginLeft: this.props.stylingOptions.spacing?.innerBody
                                ? `calc(${this.props.stylingOptions.spacing?.innerBody} / 2)`
                                : '5px',
                            marginBottom: this.props.stylingOptions.spacing?.innerBody
                                ? this.props.stylingOptions.spacing.innerBody
                                : ''
                        }}
                    >
                        <LockIcon /> Secure checkout
                    </div>
                </div>
            </form>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    setCreditCardLastFour: (lastFour: string) => void;
    processPayment: boolean;
    onStripeTokenCreation: Function;
    onSubmissionError: Function;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    postalCode: string;
    cardholderName: string;
}

export default InjectedStripeComponent;
