import React from 'react';
import urlPaths from '../../../../static/constants/enums/urlPaths';
import { formatMonetaryAmount } from '../../../../toolboxes/reuseable-logic/string-formatters';
import StandardDisclaimerPageWrapper from '../standard-disclaimer-page-component/StandardDisclaimerPageWrapper';

class RecipientIdentityFailedPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        totalAmount: 0,
        recipientFirstName: '',
        recipientLastName: '',
        setRecipientUnavailalbeRatesDisclaimerAction: null,
        setRecipientIsUnavailableAction: null
    };

    getPageText() {
        if (this.props.hasSelectedMultiPay) {
            return (
                <>
                    <p>
                        We were unable to verify the identity of&nbsp;
                        {this.props.recipientFirstName} {this.props.recipientLastName}.
                    </p>
                    <p>
                        Unfortunately we cannot continue with the selected payment option. Should you choose to
                        continue, you will be required to pay the full amount of{' '}
                        {formatMonetaryAmount(this.props.totalAmount.toString())} today.
                    </p>
                </>
            );
        }
        return (
            <p>
                Thank you for providing us with this information. We have a few more questions to ask before collecting
                your payment and completing your arrangement. If there is anything else that needs clarified, we will
                follow up via email afterwards.
            </p>
        );
    }

    render() {
        return (
            <StandardDisclaimerPageWrapper
                acceptTo={urlPaths.beneficiaryCheck}
                rejectTo=""
                acceptanceText={this.props.hasSelectedMultiPay ? 'I agree' : 'Continue'}
                rejectionText=""
                disclaimerTitle={
                    this.props.hasSelectedMultiPay ? 'Identity Verification Failed' : 'A few more questions'
                }
                saveDisclaimerAddressingToRedux={newValue => {
                    this.props.setRecipientUnavailalbeRatesDisclaimerAction(newValue);
                }}
                acceptanceSideAffect={() => this.props.setRecipientIsUnavailableAction(true)}
                pageName="RecipientIdentityFailed"
                pageBack="false"
                pageNumber=""
            >
                {this.getPageText()}
            </StandardDisclaimerPageWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    totalAmount: number;
    recipientFirstName: string;
    recipientLastName: string;
    setRecipientUnavailalbeRatesDisclaimerAction: Function;
    setRecipientIsUnavailableAction: Function;
    hasSelectedMultiPay: boolean;
}

interface StateShape {
    // Shape of local state
}

export default RecipientIdentityFailedPageComponent;
