import { connect } from 'react-redux';
import PurchaserOrderSummaryPaymentSummaryComponent from './PurchaserOrderSummaryPaymentSummaryComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        cardLastFour: state.opportunity.pricing.creditCardInfo.lastFour,
        cardType: state.opportunity.pricing.creditCardInfo.type
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryPaymentSummaryComponent);
