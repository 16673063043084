import { connect } from 'react-redux';
import DemographicInformationCopmponent from './DemographicInformationCopmponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DemographicInformationCopmponent);
