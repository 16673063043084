import { getFormData } from '../../redux-form/redux-form-data-access';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import funeralDirectorStates from '../../../static/constants/stateSpecificLists/funeralDirectorStates';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { calculateAge } from '../../reuseable-logic/age-calculator';
import { noFundingProductAvailableStates } from '../../../static/constants/stateSpecificLists/noFundingProductAvailableStates';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';
import { Timing } from 'static/constants/enums/timing';

const recipientCheckReroutes = (state: GlobalStateShape) => {
    const birthDate = getFormData(state, 'purchaser', 'birthdate');
    const age = calculateAge(new Date(birthDate));
    const purchaserAddressState = getFormData(state, 'purchaser', 'state');
    const relationship = getFormData(state, 'purchaser', 'relationshipToRecipient');
    const nextDisclaimerUrl =
        state.opportunity.preneedContractType === PreneedContractType.Trust
            ? urlPaths.trustFundedPrearrangement
            : urlPaths.disclaimerProduct;

    if (
        state.opportunity.timing !== Timing.IMMEDIATE &&
        purchaserAddressState !== state.account.state &&
        funeralDirectorStates.includes(state.account.state)
    ) {
        return urlPaths.unavailableFuneralDirectorState;
    }

    // We don't need to check Funding product for Trust sales since there's no calculation to it.
    // There will always be a funding product available for Trust sales, and it'll be whatever is
    // set on the Funeral Home account in SalesForce.
    // Additionally, we don't need to check funding product for immediate sales, as we don't put the money into a funding product
    if (
        state.opportunity.preneedContractType !== PreneedContractType.Trust &&
        noFundingProductAvailableStates.includes(purchaserAddressState) &&
        state.opportunity.timing !== Timing.IMMEDIATE
    ) {
        return urlPaths.noFundingProductAvailable;
    }

    if (age < 18) {
        return urlPaths.underagePurchaser;
    }

    if (state.opportunity.recipient.existancePredetermined) {
        if (state.opportunity.recipient.sameAsPurchaser) {
            return nextDisclaimerUrl;
        }
        if (state.opportunity.preneedContractType !== PreneedContractType.Trust && !relationship) {
            return urlPaths.relationshipToRecipient;
        }
        return urlPaths.recipient;
    }
};

export default recipientCheckReroutes;
