import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_PURCHASER_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING =
    'SET_PURCHASER_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING';
// Action
export function setPurchaserPlaidIdentityConsentDisclaimerAddressingAction(newValue: DisclaimerAddressing) {
    return { type: SET_PURCHASER_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING, newValue };
}
// Reducer
export function setPurchaserPlaidIdentityConsentDisclaimerAddressingReducer(
    state: any,
    action: any
): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_PURCHASER_PLAID_IDENTITY_CONSENT_DISCLAIMER_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
