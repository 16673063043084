import React from 'react';
import './EverdaysHeaderComponent.css';
import { PropShape } from '../../../../global-components/limb-components/header-component/HeaderComponent';

class EverdaysHeaderComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return null;
    }
}

interface StateShape {
    // Shape of local state
}

export default EverdaysHeaderComponent;
