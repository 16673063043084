import { connect } from 'react-redux';
import IdentityFailedPageComponent from './IdentityFailedPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    const yesToAlternitiveID =
        state.disclaimerData.disclaimerAddressings.verifyPurchaserIdentity.hasBeenAcceptedOrPositivelyAddressed;
    // These values will be passed in as props
    return {
        yesToAlternitiveID: yesToAlternitiveID
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityFailedPageComponent);
