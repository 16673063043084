// TODO - Remove the need for this logic by transitioning to typed form

import { touch } from 'redux-form';

export function getArrayOfFormFields(validationFunction: (values: any) => any): string[] {
    const validationResponse = validationFunction({});
    return Object.keys(validationResponse).map(key => key);
}

export function generateTouchFullFormFunction(
    validationFunction: (values: any) => any,
    formName: string,
    dispatch: Function
): Function {
    const arrayOfFields = getArrayOfFormFields(validationFunction);
    return () => {
        arrayOfFields.forEach(fieldName => {
            dispatch(touch(formName, fieldName));
        });
    };
}
