import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import yesNoReducer from './yesNoAnswers/root';
import opportunityReducer from './opportunity/root';
import representativeReducer from './representative/root';
import disclaimerDataReducer from './disclaimerData/root';
import accountReducer from './account/root';
import stylingOptionsReducer from './stylingOptions/root';
import travelOpportunityReducer from './travelOpportunity/root';
import urlPathsReducer from './urlPaths/root';
import availableProductsReducer from './availableProducts/root';
import featureFlagsReducer from './featureFlags/root';
import testingReducer from './testing/root';
import errorLoggingReducer from './errorLogging/root';

export default combineReducers({
    form: formReducer,
    yesNoAnswers: yesNoReducer,
    opportunity: opportunityReducer,
    representative: representativeReducer,
    disclaimerData: disclaimerDataReducer,
    account: accountReducer,
    stylingOptions: stylingOptionsReducer,
    travelOpportunity: travelOpportunityReducer,
    urlPaths: urlPathsReducer,
    availableProducts: availableProductsReducer,
    featureFlags: featureFlagsReducer,
    testing: testingReducer,
    errorLogging: errorLoggingReducer
});
