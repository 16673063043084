// Action Type
export const SET_OPPORTUNITY_PURCHASER_IDENTITY_VERIFICATION_PLAID =
    'SET_OPPORTUNITY_PURCHASER_IDENTITY_VERIFICATION_PLAID';
// Action
export function setOpportunityPurchaserIdentityVerificationPlaidAction(newValue: string) {
    return { type: SET_OPPORTUNITY_PURCHASER_IDENTITY_VERIFICATION_PLAID, newValue };
}
// Reducer
export function setOpportunityPurchaserIdentityVerificationPlaidReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_OPPORTUNITY_PURCHASER_IDENTITY_VERIFICATION_PLAID:
            return action.newValue;
        default:
            return state;
    }
}
