import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { fetchAndStorePaymentOptions } from '../../server-application-buffer/payment-data';
import { setSelectedPaymentOptionAction } from '../../../redux/opportunity/pricing/paymentOptions/selectedOption';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { PaymentMethod } from '../../../static/constants/enums/paymentMethods';
import { setPaymentOptionsUpToDateAction } from '../../../redux/opportunity/pricing/paymentOptions/upToDate';
import { PaymentMode } from '../../../static/constants/enums/paymentModes';
import { paymentOptionsAreEquivalent } from 'toolboxes/reuseable-logic/payment-options-calculator';
import { setPaymentIsPredeterminedAction } from 'redux/opportunity/pricing/isPredetermined';

export default function loadPaymentOptionsAndSaveDefaultChoice(
    state: GlobalStateShape,
    dispatch: Function
): BlockingFunction[] {
    const loadFunctions: BlockingFunction[] = [];

    loadFunctions.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                fetchAndStorePaymentOptions(state, dispatch, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    if (!state.opportunity.pricing.paymentOptions.selectedOption) {
                        let defaultOption = result.find(
                            option =>
                                option.paymentMode === PaymentMode.Single &&
                                option.paymentMethod === PaymentMethod.CreditCard
                        );
                        if (!defaultOption) {
                            defaultOption = result.find(
                                option =>
                                    option.paymentMode === PaymentMode.Single &&
                                    option.paymentMethod === PaymentMethod.ACH
                            );
                        }
                        if (!defaultOption) {
                            defaultOption = result.find(option => option.paymentMethod === PaymentMethod.CreditCard);
                        }
                        if (!defaultOption && result.length > 0) {
                            defaultOption = result[0];
                        }
                        if (defaultOption) {
                            dispatch(setSelectedPaymentOptionAction(defaultOption));
                        }
                    } else {
                        if (
                            !result.some(x => {
                                return paymentOptionsAreEquivalent(
                                    x,
                                    state.opportunity.pricing.paymentOptions.selectedOption
                                );
                            })
                        ) {
                            let PaymentOptionLookup = result.find(
                                x =>
                                    x.paymentMethod ===
                                        state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod &&
                                    x.years === state.opportunity.pricing.paymentOptions.selectedOption.years
                            );
                            if (!PaymentOptionLookup) {
                                // If we can't find a payment option that matches the payment method and number of years predetermined
                                // Then, we need to allow the user to select a new option, defaulting to single pay
                                dispatch(setPaymentIsPredeterminedAction(false));
                                PaymentOptionLookup = result.find(
                                    x =>
                                        state.opportunity.pricing.paymentOptions.selectedOption.paymentMethod &&
                                        x.paymentMode === PaymentMode.Single
                                );
                            }
                            if (!PaymentOptionLookup) {
                                // This should never happen as far as I can tell
                                // I think this would a broken situation (Not allowing a user to single pay with their preferred payment method)
                                dispatch(setSelectedPaymentOptionAction(null));
                                return resolve();
                            }
                            dispatch(setSelectedPaymentOptionAction(PaymentOptionLookup));
                        }
                    }
                    dispatch(setPaymentOptionsUpToDateAction(true));
                    return resolve();
                });
            }),
        blocksPageStarts: [urlPaths.payment]
    });

    return loadFunctions;
}
