import { connect } from 'react-redux';
import GeneralDisclaimerComponent from './GeneralDisclaimerComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape, myProps: any) => {
    // These values will be passed in as props
    return {
        addressingContactId: myProps.addressingContactId || state.opportunity.purchaser.id,
        opportunityId: myProps.opportunityId || state.opportunity.id,
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDisclaimerComponent);
