import GlobalStateShape from '../../redux/GlobalStateShape';
import { getTabStylesForCompany } from '../../static/constants/styling-company-constants/company-styling-router';
import { TULIP_STYLING_KEY } from '../../static/constants/styling-company-constants/companies/tulip';
import getEnvironment from '../../env-config/get-environment';
import urlPaths from '../../static/constants/enums/urlPaths';
import { getFormData } from '../redux-form/redux-form-data-access';
import { demoEnvironment } from 'env-config/environment-configs/demo';

const ignoredPaths = [
    urlPaths.agentStart,
    urlPaths.productSelection,
    urlPaths.purchaserStart,
    urlPaths.opportunityStatus
];

export function checkForAccountBaseUrlReroute(state: GlobalStateShape, urlPath: string, params: any) {
    // @ts-ignore
    if (ignoredPaths.includes(urlPath)) {
        return;
    }

    //Don't pre-emptively leave the page without know what our opp id is
    if (urlPath === urlPaths.loadOpportunity && !params.id) {
        return;
    }

    // When earth generates contracts on their site with our widget they are tied to our HelloSign Client Id and Domain
    // because of this we can not redirect to earths domain, otherwise contracts will not load
    if (params.forceDefaultHelloSignClientId === 'true') {
        return;
    }

    // TODO Remove tulip specific logic
    if (state.account.stylingKey === TULIP_STYLING_KEY) {
        const baseUrl = getEnvironment().tulipBaseUrl;
        if (baseUrl && window.location.host !== baseUrl) {
            loadOpportunityOnRedirect(baseUrl, state, params);
        }
    }

    if (getEnvironment() === demoEnvironment) {
        if (state.account.demoHostedDomain && state.account.demoHostedDomain !== window.location.host) {
            loadOpportunityOnRedirect(state.account.demoHostedDomain, state, params);
        }
    } else {
        if (state.account.hostedDomain && state.account.hostedDomain !== window.location.host) {
            loadOpportunityOnRedirect(state.account.hostedDomain, state, params);
        }
    }

    const stylingData = getTabStylesForCompany(state.account.subdomain);
    if (stylingData.baseUrl && window.location.host !== stylingData.baseUrl) {
        const newUrl = new URL(`https://${stylingData.baseUrl}${urlPath}`);
        Object.keys(params).forEach(paramKey => {
            newUrl.searchParams.append(paramKey, params[paramKey]);
        });
        window.location.href = newUrl.toString();
    }
}

function loadOpportunityOnRedirect(baseUrl: string, state: GlobalStateShape, params: any) {
    const newUrl = new URL(`https://${baseUrl}${urlPaths.loadOpportunity}`);
    // Append whatever parameters may be necessary to load the opportunity
    newUrl.searchParams.append('id', window.localStorage.getItem('opportunityId'));
    newUrl.searchParams.append(
        'representativeId',
        params.representativeId ||
            params.repId ||
            window.localStorage.getItem('representativeId') ||
            getFormData(state, 'representative', 'id') ||
            (getEnvironment().clearRepresentativeOnPurchaserEmailLinkLoad ? 'CLEAR_ID' : '')
    );
    window.location.href = newUrl.toString();
}
