import { connect } from 'react-redux';
import BeneficiaryInformationComponent from './BeneficiaryInformationComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    const formData = getFormValues('beneficiary')(state);

    // These values will be passed in as props
    return {
        formData
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryInformationComponent);
