import { connect } from 'react-redux';
import InjectedStripeComponent from './InjectedStripeComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { injectStripe } from 'react-stripe-elements';
import { setCardLastFourAction } from 'redux/opportunity/pricing/creditCardInfo/lastFour';
const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setCreditCardLastFour: (lastFour: string) => dispatch(setCardLastFourAction(lastFour))
    };
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(InjectedStripeComponent));
