import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import urlPaths from 'static/constants/enums/urlPaths';
import { sendSaveAlternativePurchaserIdentityDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';

export function saveAlternativePurchaserIdentityDisclaimer(state: GlobalStateShape): BlockingFunction[] {
    let savingFunctions = [
        {
            blocksPageStarts: [urlPaths.unavailableIdentityFailed, urlPaths.ssnCapture],
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveAlternativePurchaserIdentityDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                })
        }
    ];
    return savingFunctions;
}
