import GlobalStateShape from '../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import { formIsValid, validatePhoneVerificationRequest } from '../../redux-form/redux-form-validation';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { sendSubmitPhoneVerificationRequest } from '../../server-application-buffer/phone-verification-data';
import { setPurchaserPhoneVerificationRequestedAction } from '../../../redux/opportunity/purchaser/phoneVerificationRequested';
import { Action } from 'history';

export function submitPurchaserPhoneVerificationRequest(
    state: GlobalStateShape,
    dispatch: Function,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    return [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    if (
                        !formIsValid(
                            getFormValues('purchaserPhoneVerification')(state),
                            validatePhoneVerificationRequest
                        )
                    ) {
                        return resolve();
                    }
                    if (!lastNavigationWasInternal) {
                        return resolve();
                    }
                    sendSubmitPhoneVerificationRequest(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }

                        dispatch(setPurchaserPhoneVerificationRequestedAction(true));
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.purchaserPhoneVerify]
        }
    ];
}
