// Action Type
export const SET_STOP_PAGE_DETAIL = 'SET_STOP_PAGE_DETAIL';
// Action
export function setStopPageDetailAction(newValue: string) {
    return { type: SET_STOP_PAGE_DETAIL, newValue };
}
// Reducer
export function setStopPageDetailReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_STOP_PAGE_DETAIL:
            return action.newValue;
        default:
            return state;
    }
}
