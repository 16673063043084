import urlPaths from 'static/constants/enums/urlPaths';
import { ContractSigningCredentialStatusOptions } from 'static/models/ContractSigningCredentials';
import { fetchAndStoreActiveContractSigningCredentials } from 'toolboxes/server-application-buffer/contract-signing-credentials-data';
import { fetchAndStoreSecureSigningContractUrl } from 'toolboxes/server-application-buffer/contracts-data';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export default function loadSecureLinkContracts(
    state: GlobalStateShape,
    params: any,
    dispatch: Function
): BlockingFunction[] {
    const loadingFunctions: BlockingFunction[] = [
        {
            blocksPageStarts: [urlPaths.secureLinkSigning],
            promiseGenerator: () =>
                new Promise((resolve, reject) => {
                    if (!params.key) {
                        return reject('Secure signing was attempted without a key - indicating a broken link');
                    }
                    fetchAndStoreActiveContractSigningCredentials(params.key, dispatch, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        if (result?.signingStatus === ContractSigningCredentialStatusOptions.awaitingSignature) {
                            fetchAndStoreSecureSigningContractUrl(params.key, dispatch, (result, error) => {
                                if (error) {
                                    return reject(error);
                                }
                                return resolve();
                            });
                        } else {
                            return resolve();
                        }
                    });
                })
        }
    ];

    return loadingFunctions;
}
