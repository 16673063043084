import { connect } from 'react-redux';
import PurchaserOrderSummaryDemographicsGroupingComponent from './PurchaserOrderSummaryDemographicsGroupingComponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';
import { formIsValid, validateBeneficiary } from '../../../../../toolboxes/redux-form/redux-form-validation';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        planningForSelf: state.opportunity.recipient.sameAsPurchaser,
        purchaserInfo: getFormValues('purchaser')(state),
        recipientInfo: getFormValues('recipient')(state),
        beneficiaryInfo: getFormValues('beneficiary')(state),
        beneficiaryProvided: formIsValid('beneficiary', validateBeneficiary)
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserOrderSummaryDemographicsGroupingComponent);
