import React from 'react';
import './TitanButtonSubtitledLinkGroupComponent.css';

class TitanButtonSubtitledLinkGroupComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return <div className="side-by-side-buttons">{this.props.children}</div>;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
}

export default TitanButtonSubtitledLinkGroupComponent;
