import React from 'react';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import { validatePhoneVerificationRequest } from '../../../toolboxes/redux-form/redux-form-validation';
import RadioGroupWrapper from '../base-form-components/radio-group-component/RadioGroupWrapper';

class PhoneVerificationRequestFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        let phoneEndingIn = '';

        if (this.props.phoneNumber) {
            phoneEndingIn = this.props.phoneNumber.slice(-4);
        }

        return (
            <FormWrapper formName={this.props.formName} validate={validatePhoneVerificationRequest}>
                <RadioGroupWrapper
                    formName={this.props.formName}
                    fieldPath="method"
                    valueDisplays={[
                        {
                            value: 'sms',
                            display: `Text ***-***-${phoneEndingIn}`
                        },
                        {
                            value: 'call',
                            display: `Call ***-***-${phoneEndingIn}`
                        }
                    ]}
                    extraFieldProps={{
                        disabled: false
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    formName: string;
    phoneNumber: string;
}

interface StateShape {
    // Shape of local state
}

export default PhoneVerificationRequestFormComponent;
