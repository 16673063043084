import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';

export function paymentReroutes(state: GlobalStateShape) {
    const opportunityPricing = state.opportunity.pricing.paymentOptions;
    if (opportunityPricing.availableOptions.length === 0 && opportunityPricing.upToDate) {
        return urlPaths.noPaymentOptions;
    }
    if (state.account.stylingKey === EARTH_STYLING_KEY && state.opportunity.pricing.paymentInitiated) {
        return urlPaths.ssnCapture;
    }
}
