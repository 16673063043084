import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import recipientConsentIsStillNecessary from 'toolboxes/reuseable-logic/recipient-identity-still-necessary';

export function purchaserOrderSummaryReroutes(state: GlobalStateShape) {
    if (!state.disclaimerData.disclaimerAddressings.priceList.hasBeenAcceptedOrPositivelyAddressed) {
        return urlPaths.latePriceListDisclaimer;
    }
    if (recipientConsentIsStillNecessary(state)) {
        return urlPaths.prefilledRecipientIdentity;
    }
}
