import GlobalStateShape from '../../../redux/GlobalStateShape';
import { formIsValid, validateSSN, validationLast4SSN } from '../../../toolboxes/redux-form/redux-form-validation';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import SSNCapturePageComponent from './SSNCapturePageComponent';
import {
    getFormData,
    getFormMetaData,
    getFormSyncErrorsData
} from '../../../toolboxes/redux-form/redux-form-data-access';
import getEnvironment from 'env-config/get-environment';
import { testEnvironment } from 'env-config/environment-configs/test';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    const SSNToLast4Digits = state.featureFlags.SSNToLast4Digits;
    const ssnFieldName = SSNToLast4Digits ? 'ssnLastFour' : 'ssn';
    const digitsExpected = SSNToLast4Digits ? 4 : 9;
    const environment = getEnvironment();
    const purchaser = getFormValues('purchaser')(state) as any;

    const reachedStopPage =
        state.opportunity.stopPageDetail === 'Stopped because they failed identity too many times' ||
        state.opportunity.stopPageDetail ===
            'Stopped because purchaser decided to provide alternative form of identification' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid' ||
        state.opportunity.stopPageDetail === 'Stopped because the identity is not valid - special reasons';

    let ProdEnvironment = true;

    if (environment === testEnvironment) {
        ProdEnvironment = false;
    }

    const validationFunction = SSNToLast4Digits ? validationLast4SSN : validateSSN;

    let touched = false;
    let correctErrorMessage = false;
    let digitsEntered = false;

    if (getFormMetaData(state, 'SSNCaptureData') && getFormMetaData(state, 'SSNCaptureData')[ssnFieldName]) {
        if (getFormMetaData(state, 'SSNCaptureData')[ssnFieldName].touched === true) {
            touched = true;
        } else {
            // This is added for stability if removed make sure to test that the alternative ID form link doesn't show
            touched = false;
        }
        if (getFormSyncErrorsData(state, 'SSNCaptureData')[ssnFieldName] === 'Invalid social security number') {
            correctErrorMessage = true;
        } else {
            // This is added for stability if removed make sure to test that the alternative ID form link doesn't show
            correctErrorMessage = false;
        }
        if (
            getFormData(state, 'SSNCaptureData', ssnFieldName) &&
            getFormData(state, 'SSNCaptureData', ssnFieldName).length === digitsExpected
        ) {
            digitsEntered = true;
        } else {
            // This is added for stability if removed make sure to test that the alternative ID form link doesn't show
            digitsEntered = false;
        }
    }

    let showAlternativeLink = (touched && correctErrorMessage && digitsEntered) || !ProdEnvironment;

    return {
        formComplete: formIsValid(getFormValues('SSNCaptureData')(state), validationFunction),
        stylingOptions: state.stylingOptions,
        SSNToLast4Digits: state.featureFlags.SSNToLast4Digits,
        showAlternativeLink: showAlternativeLink,
        reachedStopPage: reachedStopPage,
        purchaserName: `${purchaser.firstName} ${purchaser.lastName}`
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SSNCapturePageComponent);
