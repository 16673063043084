import React, { Fragment } from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import SelectWrapper from '../base-form-components/select-component/SelectWrapper';
import { EmploymentStatus } from '../../../static/constants/enums/employmentStatus';
import { validateEmploymentVital } from '../../../toolboxes/redux-form/redux-form-validation';
class EmploymentVitalFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        isOrWasEmployed: false,
        isRetired: false
    };

    render() {
        return (
            <FormWrapper formName="employmentVital" validate={validateEmploymentVital}>
                <SelectWrapper
                    formName="employmentVital"
                    fieldPath="employmentStatus"
                    labelString="Employment Status"
                    valueDisplays={Object.keys(EmploymentStatus).map(key => {
                        return {
                            value: EmploymentStatus[key],
                            display: EmploymentStatus[key]
                        };
                    })}
                />
                {this.props.isOrWasEmployed && (
                    <Fragment>
                        <TextBoxWrapper
                            formName="employmentVital"
                            fieldPath="usualOccupation"
                            labelString="Usual Occupation"
                        />
                        <TextBoxWrapper
                            formName="employmentVital"
                            fieldPath="kindOfBusiness"
                            labelString="Kind Of Business"
                        />
                        <TextBoxWrapper formName="employmentVital" fieldPath="employer" labelString="Employer" />
                        <TextBoxWrapper
                            formName="employmentVital"
                            fieldPath="employmentNumberOfYears"
                            labelString="Employment Number Of Years"
                            extraFieldProps={{
                                inputProps: {
                                    inputMode: 'numeric'
                                }
                            }}
                            spacing={{
                                xs: 12,
                                sm: this.props.isRetired ? 6 : 12
                            }}
                        />
                    </Fragment>
                )}
                {this.props.isRetired && (
                    <Fragment>
                        <TextBoxWrapper
                            formName="employmentVital"
                            fieldPath="retiredYear"
                            labelString="Retired Year"
                            extraFieldProps={{
                                inputProps: {
                                    inputMode: 'numeric'
                                }
                            }}
                            spacing={{
                                xs: 12,
                                sm: 6
                            }}
                        />
                    </Fragment>
                )}
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
    isOrWasEmployed: boolean;
    isRetired: boolean;
}

interface StateShape {
    // Shape of local state
}

export default EmploymentVitalFormComponent;
