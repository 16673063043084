import axios from 'axios';
import getEnvironment from 'env-config/get-environment';
import { baseUrl, defaultGetConfiguration, defaultPostConfiguration, sendRequest } from '../web-request-tools';

export function runSubmitPaymentMethod(paymentData: any, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(`${baseUrl}/api/dynamicPayment/paymentInformation`, paymentData, defaultPostConfiguration),
        callback,
        false
    );
}

export function fetchPaymentOptions(
    fetchingParams: any,
    preneedContractType: string,
    usePreneedContractType: boolean,
    useNodePaymentCalculator: boolean,
    callback: (result: any, error: any) => void
) {
    // @ts-ignore

    // Feature flag this
    let endpoint = useNodePaymentCalculator
        ? getEnvironment().efuneralNodePaymentCalculatorUrl
        : getEnvironment().efuneralPaymentCalculatorUrl;

    let route = '';
    if (!!preneedContractType && usePreneedContractType) {
        route = preneedContractType.toLowerCase();
    }

    sendRequest(
        axios.get(`${endpoint}/${route}`, {
            ...defaultGetConfiguration,
            params: fetchingParams
        }),
        callback
    );
}

export function runUpdatePaymentOption(
    opportunityId: string,
    paymentData: any,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        axios.post(`${baseUrl}/api/opportunity/${opportunityId}/paymentOption`, paymentData, defaultPostConfiguration),
        callback
    );
}

export function runResetPaymentOption(opportunityId: string, callback: (result: any, error: any) => void) {
    sendRequest(
        axios.post(`${baseUrl}/api/opportunity/${opportunityId}/resetPaymentOption`, defaultPostConfiguration),
        callback
    );
}

export function runBankInformation(
    bankInformation: any,
    purchaserId: string,
    opportunityId: string,
    callback: (result: any, error: any) => void
) {
    sendRequest(
        // DEV2020-1478 - new ach enpoint that takes opportunity id
        // so the stage can be updated from the backend
        axios.post(
            `${baseUrl}/api/contact/${purchaserId}/bank/${opportunityId}`,
            bankInformation,
            defaultPostConfiguration
        ),
        callback
    );
}
