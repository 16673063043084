import { ContractSigningCredentialsRole } from 'static/models/ContractSigningCredentials';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import urlPaths from '../../../../static/constants/enums/urlPaths';

export function secureLinkSigningCompleteReroutes(state: GlobalStateShape) {
    const signingRole = state.opportunity.contractSigning.activeContractSigningCredentials?.signingRole;
    const redirectLink = state.opportunity.contractSigning.activeContractSigningCredentials?.redirectLink;
    const redirectLinkIsUrlPath = Object.values(urlPaths).includes(redirectLink as urlPaths);

    if (redirectLink) {
        if (redirectLinkIsUrlPath) {
            //Check is used to avoid infinite loop
            if (redirectLink !== urlPaths.secureLinkSigningComplete) {
                return redirectLink;
            }
        } else {
            window.location.href = redirectLink;
            return;
        }
    } else {
        if (signingRole && signingRole == ContractSigningCredentialsRole.FUNERAL_PURCHASER) {
            return urlPaths.purchaserSigningComplete;
        }
    }
}
