import React from 'react';
import PageCardTitleWrapper from '../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import FullContentCenterWrapper from '../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../global-components/page-card-components/page-card-component/PageCardWrapper';
import PageCardTextWrapper from '../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';

class ErrorSubmissionPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        caseNumber: '',
        errorCaseCreationFailed: false,
        funeralHomePhone: ''
    };

    render() {
        return (
            <FullContentCenterWrapper metaPageName="ErrorPage" metaPageNumber="" metaPagebackAvailable="false">
                <PageCardWrapper>
                    {!this.props.errorCaseCreationFailed && (
                        <>
                            <PageCardTitleWrapper>Case Received</PageCardTitleWrapper>
                            <PageCardTextWrapper>
                                Your case has been sent to our support team.{' '}
                                {this.props.caseNumber ? 'Your case number is: ' + this.props.caseNumber : ''}
                            </PageCardTextWrapper>
                        </>
                    )}
                    {this.props.errorCaseCreationFailed && (
                        <>
                            <PageCardTitleWrapper>Failed to Make Bug Ticket.</PageCardTitleWrapper>
                            <PageCardTextWrapper>
                                Sorry, there's seems to be a problem on our end, and we couldn't make the bug ticket.
                            </PageCardTextWrapper>
                            <PageCardTextWrapper>
                                Please email us at help@efuneral.com for assistance.
                            </PageCardTextWrapper>
                        </>
                    )}
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    caseNumber: string;
    errorCaseCreationFailed: boolean;
    funeralHomePhone: string;
}

interface StateShape {
    // Shape of local state
}

export default ErrorSubmissionPageComponent;
