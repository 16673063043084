import { ContractSigningCredentialStatusOptions } from 'static/models/ContractSigningCredentials';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import urlPaths from '../../../../static/constants/enums/urlPaths';

export function secureLinkSigningReroutes(state: GlobalStateShape) {
    const signingStatus = state.opportunity.contractSigning.activeContractSigningCredentials?.signingStatus;
    if (signingStatus && signingStatus != ContractSigningCredentialStatusOptions.awaitingSignature) {
        return urlPaths.invalidContractSigningCredentials;
    }
}
