import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import urlPaths from 'static/constants/enums/urlPaths';
import { setStopPageRelationshipNotListed } from 'toolboxes/server-application-buffer/contact-data';
import { setStopPageDetailAction } from 'redux/opportunity/stopPageDetail';

const recipientRelationshipNotListed = (state: GlobalStateShape, dispatch: Function): BlockingFunction[] => {
    const functionsToRun = [
        {
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    setStopPageRelationshipNotListed(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        if (result) {
                            dispatch(setStopPageDetailAction(result));
                        }
                        return resolve();
                    });
                }),
            blocksPageStarts: [urlPaths.recipientRelationshipNotListed]
        }
    ];
    return functionsToRun;
};

export default recipientRelationshipNotListed;
