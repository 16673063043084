import { connect } from 'react-redux';
import PrefilledRecipientAgreementPageComponent from './PrefilledRecipientAgreementPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { setRecipientConsentAction } from '../../../../redux/disclaimerData/disclaimerAddressings/recipientConsent';
import { getFormData } from '../../../../toolboxes/redux-form/redux-form-data-access';
import { PreneedContractType } from 'static/constants/enums/preneedContractType';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        recipientFirstName: getFormData(state, 'recipient', 'firstName'),
        recipientLastName: getFormData(state, 'recipient', 'lastName'),
        trustText: state.opportunity.preneedContractType === PreneedContractType.Trust ? 'trust' : 'life insurance'
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setRecipientConsentAction: newValue => dispatch(setRecipientConsentAction(newValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrefilledRecipientAgreementPageComponent);
