import uaParse from 'ua-parser-js';

export const isSafari = () => {
    try {
        const browserInfo = uaParse();
        return ['Mobile Safari', 'Safari'].includes(browserInfo.browser.name);
    } catch {
        return false;
    }
};

export const browserSupportsDatePickers = () => {
    return !isSafari();
};
