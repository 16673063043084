import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedStripeWrapper from '../injected-stripe-component/InjectedStripeWrapper';
import getEnvironment from '../../../../env-config/get-environment';

class StripeComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <StripeProvider apiKey={getEnvironment().stripeKey}>
                <Elements>
                    <InjectedStripeWrapper
                        processPayment={this.props.processPayment}
                        onStripeTokenCreation={this.props.onStripeTokenCreation}
                        onSubmissionError={this.props.onSubmissionError}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    processPayment: boolean;
    onStripeTokenCreation: Function;
    onSubmissionError: Function;
}

interface StateShape {
    // Shape of local state
}

export default StripeComponent;
