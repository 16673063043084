import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import recipientConsentIsStillNecessary from '../../reuseable-logic/recipient-identity-still-necessary';
import { getFormData } from '../../redux-form/redux-form-data-access';

export function purchaserIdentityReroutes(state: GlobalStateShape) {
    if (!state.opportunity.pricing.paymentInitiated) {
        return urlPaths.payment;
    }
    if (getFormData(state, 'representative', 'id')) {
        return urlPaths.representativeIdentity;
    }
    if (recipientConsentIsStillNecessary(state)) {
        return urlPaths.prefilledRecipientIdentity;
    }
}
