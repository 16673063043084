import { Action } from 'history';
import GlobalStateShape from '../../redux/GlobalStateShape';
import urlPaths from '../../static/constants/enums/urlPaths';
import BlockingFunction from '../../static/models/blockingFunctions/BlockingFunction';
import {
    addCustomerPathAssociation,
    addPathToCustomerPathHistory
} from '../server-application-buffer/general-opportunity-data';
import { agentStartEntry } from './pages/agentStart';
import { saveParamData } from './pages/ALL';
import calculateForcedTimings, { calculateContractCodes } from './pages/annuityInsurance';
import contractsDeniedEntry from './pages/contractsDenied';
import sendErrorFunctions from './pages/error';
import sendTestFailingRequest from './pages/error-sending-test-failing-request';
import saveOverridePage from './pages/forceNavigation';
import loadFuneralContracts from './pages/funeralContracts';
import loadFromGuidedFunctions from './pages/loadFromGuided';
import loadOpportunityLoadFunctions from './pages/loadOpportunity';
import opportunityStatus from './pages/opportunityStatus';
import loadOrderSummaryPage from './pages/orderSummary';
import loadPaymentOptionsAndSaveDefaultChoice from './pages/payment';
import getProductSelectionInformation from './pages/productSelection';
import loadBlockScoreData from './pages/purchaserIdentity';
import markContractsAsCompleted from './pages/purchaserSigningComplete';
import clearRepresentativeId from './pages/purchaserStart';
import loadRecipientBlockScoreData from './pages/recipientIdentity';
import recipientRelationshipNotListed from './pages/recipientRelationshipNotListed';
import representativeComplete from './pages/representativeComplete';
import loadSecureLinkContracts from './pages/secureLinkSigning';
import loadFeatureFlags from './pages/securityCheck';
import sendIdentityEmailLink from './pages/sendIdentityLinkEmail';
import loadTravelContracts from './pages/travelContracts';
import setTrustFundingProduct from './pages/trustFundedPrearrangement';
import sendIdentityFailureEmail from './pages/unavailableIdentityFailed';
import setVitalStatisticsSavedFalse from './pages/vitalStatistics';

// This function receives the state, and can find out what we need to load
// If there are things that need to happen before we can show a url's page
// Return those things as executable asyncrenous functions here
// The promises should end by returning a boolean indicating success
function generateBasePageEnterFunctions(
    state: GlobalStateShape,
    dispatch: Function,
    urlPath: string,
    params: any,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    saveParamData(state, params, dispatch);
    switch (urlPath) {
        case urlPaths.loadFromGuided:
            return loadFromGuidedFunctions(state, dispatch, params);
        case urlPaths.disclaimerProduct:
            if (state.featureFlags.contractProductMarketAgentCodeCalculationUpdate) {
                return calculateContractCodes(state, dispatch);
            } else {
                return calculateForcedTimings(state, dispatch);
            }
        case urlPaths.forceNavigation:
            return saveOverridePage(state, dispatch, params.overridePage);
        case urlPaths.error:
            return sendErrorFunctions(state, dispatch);
        case urlPaths.sendTestFailingRequest:
            return sendTestFailingRequest(state);
        case urlPaths.loadOpportunity:
            return loadOpportunityLoadFunctions(state, dispatch, params);
        case urlPaths.purchaserIdentity:
            return loadBlockScoreData(state, dispatch);
        case urlPaths.sendIdentityLinkEmail:
            return sendIdentityEmailLink(state, dispatch);
        case urlPaths.funeralContracts:
            return loadFuneralContracts(state, dispatch);
        case urlPaths.travelContracts:
            return loadTravelContracts(state, dispatch);
        case urlPaths.purchaserSigningComplete:
            return markContractsAsCompleted(state, dispatch);
        case urlPaths.payment:
            return loadPaymentOptionsAndSaveDefaultChoice(state, dispatch);
        case urlPaths.recipientIdentity:
            return loadRecipientBlockScoreData(state, dispatch);
        case urlPaths.prefilledRecipientIdentity:
            return loadRecipientBlockScoreData(state, dispatch);
        case urlPaths.contractsDenied:
            return contractsDeniedEntry(state, dispatch);
        case urlPaths.productSelection:
            return getProductSelectionInformation(state, dispatch);
        case urlPaths.purchaserStart:
            return clearRepresentativeId(state, dispatch);
        case urlPaths.agentStart:
            return agentStartEntry(state, dispatch);
        case urlPaths.securityCheck:
            return loadFeatureFlags(state, dispatch);
        case urlPaths.unavailableIdentityFailed:
            return sendIdentityFailureEmail(state, dispatch);
        case urlPaths.recipientRelationshipNotListed:
            return recipientRelationshipNotListed(state, dispatch);
        case urlPaths.trustFundedPrearrangement:
            return setTrustFundingProduct(state, dispatch);
        case urlPaths.secureLinkSigning:
            return loadSecureLinkContracts(state, params, dispatch);
        case urlPaths.orderSummary:
            return loadOrderSummaryPage(state, dispatch);
        case urlPaths.purchaseProcessing:
            return setVitalStatisticsSavedFalse();
        case urlPaths.representativeComplete:
            return representativeComplete(state, dispatch);
        case urlPaths.opportunityStatus:
            return opportunityStatus(state, dispatch, params);
        default:
            return [];
    }
}

// This is used to keep track of each page entry
export default function generatePageEnterFunctions(
    state: GlobalStateShape,
    dispatch: Function,
    urlPath: string,
    params: any,
    lastNavigationWasInternal: boolean
): BlockingFunction[] {
    const baseEntryFunctions = generateBasePageEnterFunctions(
        state,
        dispatch,
        urlPath,
        params,
        lastNavigationWasInternal
    );
    baseEntryFunctions.push({
        promiseGenerator: () =>
            new Promise<void>((resolve, reject) => {
                if (state.featureFlags.useCustomerPathAssociationObject) {
                    addCustomerPathAssociation(state, urlPath, () => {
                        return resolve();
                    });
                } else {
                    addPathToCustomerPathHistory(state, urlPath, (result, error) => {
                        return resolve();
                    });
                }
            }),
        blocksPageStarts: []
    });
    return baseEntryFunctions;
}
