import { TextAlignProperty } from 'csstype';
import { getApplicationStylingForDomain } from 'static/constants/styling-company-constants/company-styling-router';

// Action Type
export const SET_STYLING_TITLE_ALIGNMENT = 'SET_STYLING_TITLE_ALIGNMENT';
// Action
export function setTitleAlignmentAction(newValue: TextAlignProperty) {
    return { type: SET_STYLING_TITLE_ALIGNMENT, newValue };
}
// Reducer
export function setTitleAlignmentReducer(state: any, action: any): TextAlignProperty {
    if (typeof state === 'undefined') {
        // Initial data
        return getApplicationStylingForDomain().fonts.titleAlignment;
    }
    switch (action.type) {
        case SET_STYLING_TITLE_ALIGNMENT:
            return action.newValue;
        default:
            return state;
    }
}
