import React, { Fragment } from 'react';
import PaymentPriceCardWrapper from '../../../../../global-components/payment-price-card-component/PaymentPriceCardWrapper';
import PaymentOptionSelectionWrapper from '../../payment-options-components/payment-option-selection-component/PaymentOptionSelectionWrapper';
import InstallmentPaymentOptionsDisplayWrapper from '../../payment-options-components/installment-payment-options-display-component/InstallmentPaymentOptionsDisplayWrapper';
import BankingInformationFormWrapper from '../../../../../form-components/banking-information-form-component/BankingInformationFormWrapper';
import PaymentOption from '../../../../../../static/models/PaymentOption';
import ButtonLinkWrapper from '../../../../../global-components/buttons/button-link-component/ButtonLinkWrapper';
import urlPaths from '../../../../../../static/constants/enums/urlPaths';
import { PaymentMethod } from '../../../../../../static/constants/enums/paymentMethods';
import { PaymentMode } from '../../../../../../static/constants/enums/paymentModes';

class CheckPaymentContentComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
        totalPrice: '',
        paymentOptions: [],
        setSelectedPaymentOption: () => {},
        formValid: false,
        dueToday: '',
        dueMonthly: '',
        numberOfMonthsToPay: '',
        nextUrl: undefined
    };

    componentDidMount() {
        if (this.props.paymentOptions.length > 1) {
            const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
            this.props.setSelectedPaymentOption(singlePayOption);
        } else {
            this.props.setSelectedPaymentOption(this.props.paymentOptions[0]);
        }
    }

    render() {
        const singlePayOption = this.props.paymentOptions.find(option => option.paymentMode === PaymentMode.Single);
        const multiplePaymentOptions = this.props.paymentOptions.length > 1;

        let TotalsDisplay = null;
        if (multiplePaymentOptions) {
            TotalsDisplay = (
                <PaymentPriceCardWrapper
                    title="Total Due Today (tax included)"
                    amount={this.props.dueToday}
                    subtitle="Payment options are shown below"
                />
            );
        } else {
            const selectedPayment = this.props.paymentOptions[0];
            if (selectedPayment.paymentMode === PaymentMode.Single) {
                TotalsDisplay = (
                    <PaymentPriceCardWrapper
                        title="Total Due Today (tax included)"
                        amount={this.props.dueToday}
                        subtitle="Prepaid direct cremation package, taxes, and fees"
                    />
                );
            } else {
                TotalsDisplay = (
                    <Fragment>
                        <PaymentPriceCardWrapper
                            title="Due today"
                            amount={this.props.dueToday}
                            subtitle="This includes the first month's payment"
                        />
                        <PaymentPriceCardWrapper
                            title="Due monthly"
                            amount={`${this.props.dueMonthly} / mo`}
                            subtitle={`For ${this.props.numberOfMonthsToPay} months. Includes prepaid direct cremation package, taxes, and fees.`}
                        />
                    </Fragment>
                );
            }
        }

        return (
            <div>
                <div style={{ fontSize: '1.4rem', padding: '5px' }}>Banking Information</div>
                <BankingInformationFormWrapper />
                {TotalsDisplay}
                {multiplePaymentOptions && (
                    <Fragment>
                        <PaymentOptionSelectionWrapper paymentOption={singlePayOption} />
                        <InstallmentPaymentOptionsDisplayWrapper paymentMethod={PaymentMethod.ACH} />
                    </Fragment>
                )}
                <ButtonLinkWrapper buttonId="complete-payment" to={this.props.nextUrl} disabled={!this.props.formValid}>
                    Complete Payment
                </ButtonLinkWrapper>
            </div>
        );
    }
}
interface PropShape extends React.Props<any> {
    paymentOptions: PaymentOption[];
    setSelectedPaymentOption: Function;
    totalPrice: string;
    formValid: boolean;
    dueToday: string;
    dueMonthly: string;
    numberOfMonthsToPay: string;
    nextUrl: urlPaths;
}

interface StateShape {
    // Shape of local state
}

export default CheckPaymentContentComponent;
