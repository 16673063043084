import { connect } from 'react-redux';
import StageProgressComponent from './StageProgressComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions,
        stageName: state.opportunity.activeStage
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StageProgressComponent);
