import DisclaimerAddressing from '../../../static/models/disclaimers/DisclaimerAddressing';

// Action Type
export const SET_PAYMENT_HOLD_ADDRESSING = 'SET_PAYMENT_HOLD_ADDRESSING';
// Action
export function setPaymentHoldAddressingAction(newValue: DisclaimerAddressing) {
    return { type: SET_PAYMENT_HOLD_ADDRESSING, newValue };
}
// Reducer
export function setPaymentHoldAddressingReducer(state: any, action: any): DisclaimerAddressing {
    if (typeof state === 'undefined') {
        // Initial data
        return {
            hasBeenAcceptedOrPositivelyAddressed: false,
            disclaimerText: '',
            responseToDisclaimer: '',
            addressedByContactId: ''
        };
    }
    switch (action.type) {
        case SET_PAYMENT_HOLD_ADDRESSING:
            return action.newValue;
        default:
            return state;
    }
}
