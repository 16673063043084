import { connect } from 'react-redux';
import RecipientInformationCopmponent from './RecipientInformationCopmponent';
import GlobalStateShape from '../../../../../redux/GlobalStateShape';
import { getFormValues } from 'redux-form';

const mapStateToProps: any = (state: GlobalStateShape, props: any) => {
    const formData = getFormValues('recipient')(state);

    // These values will be passed in as props
    return {
        formData,
        recipient: state.opportunity.recipient
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientInformationCopmponent);
