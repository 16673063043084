import React from 'react';
import MiniatureYesNoWrapper from '../../../../global-components/miniature-yes-no-component/MiniatureYesNoWrapper';
import IrrevocableConfirmationModalWrapper from '../../../../modal-components/disclaimer-components/specific-disclaimers/irrevocable-confirmation-modal-component/IrrevocableConfirmationModalWrapper';
import IrrevocableHelpModalWrapper from '../../../../modal-components/help-components/specific-help-modal-components/irrevocable-help-modal-component/IrrevocableHelpModalWrapper';
import IrrevocableForcedWrapper from '../irrevocable-forced-component/IrrevocableForcedWrapper';

class IrrevocableSelectionComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            confirmationModalOpen: false,
            helpModalOpen: false
        };
    }

    static defaultProps = {
        // Default prop values
        confirmationNecessary: true,
        setIrrevocableAction: () => {},
        isIrrevocable: null,
        isForcedIrrevocable: undefined
    };

    generateDisclaimerWrapper() {
        if (this.props.isForcedIrrevocable) {
            return <IrrevocableForcedWrapper />;
        } else {
            return (
                <MiniatureYesNoWrapper
                    key="question-component"
                    answer={this.props.isIrrevocable}
                    questionText="Would you like to make your policy irrevocable?"
                    onEditClick={() => this.props.setIrrevocableAction(null)}
                    onYesClick={() => {
                        if (this.props.confirmationNecessary) {
                            this.setState({
                                confirmationModalOpen: true
                            });
                        } else {
                            this.props.setIrrevocableAction(true);
                        }
                    }}
                    onNoClick={() => this.props.setIrrevocableAction(false)}
                    onHelpClick={() => {
                        this.setState({
                            helpModalOpen: true
                        });
                    }}
                    yesNoId="irrevocable"
                />
            );
        }
    }

    render() {
        const questionComponent = this.generateDisclaimerWrapper();

        if (this.state.confirmationModalOpen) {
            return [
                questionComponent,
                <IrrevocableConfirmationModalWrapper
                    onClose={() =>
                        this.setState({
                            confirmationModalOpen: false
                        })
                    }
                    key="modal-component"
                />
            ];
        }

        if (this.state.helpModalOpen) {
            return [
                questionComponent,
                <IrrevocableHelpModalWrapper
                    onClose={() => {
                        this.setState({
                            helpModalOpen: false
                        });
                    }}
                    key="help-modal-component"
                />
            ];
        }
        return questionComponent;
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    confirmationNecessary: boolean;
    setIrrevocableAction: Function;
    isIrrevocable?: boolean;
    isForcedIrrevocable: boolean;
}

interface StateShape {
    // Shape of local state
    confirmationModalOpen: boolean;
    helpModalOpen: boolean;
}

export default IrrevocableSelectionComponent;
