import React from 'react';
import DocusignIframeWrapper from 'components/global-components/docusign-iframe-component/DocusignIframeWrapper';
import PageCardTitleWrapper from 'components/global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from 'components/global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from 'components/global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from 'components/global-components/page-card-components/page-card-component/PageCardWrapper';
import HelloSignSigningWrapper from 'components/global-components/hellosign-signing-component/HelloSignSigningWrapper';
import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';
import { Helmet } from 'react-helmet';

class EverdaysFuneralContractsPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            hasGoneFullScreen: false,
            redirectToTravelContracts: false
        };
    }

    static defaultProps = {
        // Default prop values
        contractsUrl: ''
    };

    render() {
        if (this.props.contractsUrl.toLowerCase().includes('hellosign')) {
            if (this.state.redirectToTravelContracts) {
                return <Redirect to={urlPaths.travelContracts} push />;
            }
            return (
                <div>
                    <Helmet>
                        <meta name="pageName" content="Signature" />
                        <meta name="pageNumber" content="4" />
                        <meta name="pageBackAvailable" content="false" />
                    </Helmet>
                    <HelloSignSigningWrapper
                        hellosignEmbeddedUrl={this.props.contractsUrl}
                        onSigningComplete={() =>
                            this.setState({
                                redirectToTravelContracts: true
                            })
                        }
                    />
                </div>
            );
        }
        if (this.state.hasGoneFullScreen) {
            return <DocusignIframeWrapper key="frame-section" contractsUrl={this.props.contractsUrl} />;
        }
        return [
            <FullContentCenterWrapper
                key="description-section"
                metaPageName="Signature"
                metaPageNumber="4"
                metaPagebackAvailable="false"
            >
                <PageCardWrapper extraCardWidth>
                    <PageCardTitleWrapper>Sign Your Policy</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        Please review and sign your preneed agreement and insurance enrollment application
                        {this.props.funeralHomeName && ' with ' + this.props.funeralHomeName}.
                    </PageCardTextWrapper>
                </PageCardWrapper>
            </FullContentCenterWrapper>,
            <DocusignIframeWrapper
                key="frame-section"
                contractsUrl={this.props.contractsUrl}
                onGoFull={() => {
                    this.setState({
                        hasGoneFullScreen: true
                    });
                }}
            />
        ];
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    contractsUrl: string;
    funeralHomeName: string;
}

interface StateShape {
    // Shape of local state
    hasGoneFullScreen: boolean;
    redirectToTravelContracts: boolean;
}

export default EverdaysFuneralContractsPageComponent;
