// Action Type
export const SET_OPPORTUNITY_TIMING = 'SET_OPPORTUNITY_TIMING';
// Action
export function setOpportunityTimingAction(newValue: string) {
    return { type: SET_OPPORTUNITY_TIMING, newValue };
}
// Reducer
export function setOpportunityTimingReducer(state: any, action: any): string {
    if (typeof state === 'undefined') {
        // Initial data
        return '';
    }
    switch (action.type) {
        case SET_OPPORTUNITY_TIMING:
            return action.newValue;
        default:
            return state;
    }
}
