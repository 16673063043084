import { sendSaveTrustFundedPrearrangementDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';
import urlPaths from 'static/constants/enums/urlPaths';
import GlobalStateShape from 'redux/GlobalStateShape';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';

export function saveTrustFundedPrearrangementDisclaimer(state: GlobalStateShape): BlockingFunction[] {
    let savingFunctions = [
        {
            blocksPageStarts: [urlPaths.orderSummary],
            promiseGenerator: () =>
                new Promise<void>((resolve, reject) => {
                    sendSaveTrustFundedPrearrangementDisclaimer(state, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve();
                    });
                })
        }
    ];
    return savingFunctions;
}
