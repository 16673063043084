import { setActiveContractSigningCredentials } from 'redux/opportunity/contractSigning/activeContractSigningCredentials';
import { setAllContractSigningCredentials } from 'redux/opportunity/contractSigning/allContractSigningCredentials';
import ContractSigningCredentials from 'static/models/ContractSigningCredentials';
import {
    fetchAllContractSigningCredentials,
    fetchContractSigningCredentials
} from '../web-requests/specific-requests/contracts-v3-requests';

export function fetchAndStoreAllContractSigningCredentials(
    dispatch: Function,
    opportunityId: string,
    callback: (result: any, error: any) => void
) {
    fetchAllContractSigningCredentials(opportunityId, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        dispatch(setAllContractSigningCredentials(result));

        return callback(result, undefined);
    });
}

export function fetchAndStoreActiveContractSigningCredentials(
    secureSigningLink: string,
    dispatch: Function,
    callback: (result: ContractSigningCredentials, error: any) => void
) {
    fetchContractSigningCredentials(secureSigningLink, (result, error) => {
        if (error) {
            return callback(undefined, error);
        }
        dispatch(setActiveContractSigningCredentials(result));

        return callback(result, undefined);
    });
}
