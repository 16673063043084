import React from 'react';
import urlPaths from 'static/constants/enums/urlPaths';
import EarthFlat10 from '../../../../../static/media/earth-progress-bar/earth-10-flat.svg';
import EarthFlat20 from '../../../../../static/media/earth-progress-bar/earth-20-flat.svg';
import EarthFlat30 from '../../../../../static/media/earth-progress-bar/earth-30-flat.svg';
import EarthFlat40 from '../../../../../static/media/earth-progress-bar/earth-40-flat.svg';
import EarthFlat50 from '../../../../../static/media/earth-progress-bar/earth-50-flat.svg';
import EarthFlat60 from '../../../../../static/media/earth-progress-bar/earth-60-flat.svg';
import EarthFlat70 from '../../../../../static/media/earth-progress-bar/earth-70-flat.svg';
import EarthFlat80 from '../../../../../static/media/earth-progress-bar/earth-80-flat.svg';
import EarthFlat90 from '../../../../../static/media/earth-progress-bar/earth-90-flat.svg';
import EarthFlat100 from '../../../../../static/media/earth-progress-bar/earth-100-flat.svg';
import EarthRound10 from '../../../../../static/media/earth-progress-bar/earth-10-round.svg';
import EarthRound20 from '../../../../../static/media/earth-progress-bar/earth-20-round.svg';
import EarthRound30 from '../../../../../static/media/earth-progress-bar/earth-30-round.svg';
import EarthRound40 from '../../../../../static/media/earth-progress-bar/earth-40-round.svg';
import EarthRound50 from '../../../../../static/media/earth-progress-bar/earth-50-round.svg';
import EarthRound60 from '../../../../../static/media/earth-progress-bar/earth-60-round.svg';
import EarthRound70 from '../../../../../static/media/earth-progress-bar/earth-70-round.svg';
import EarthRound80 from '../../../../../static/media/earth-progress-bar/earth-80-round.svg';
import EarthRound90 from '../../../../../static/media/earth-progress-bar/earth-90-round.svg';
import EarthRound100 from '../../../../../static/media/earth-progress-bar/earth-100-round.svg';
import { EARTH_HEADER_BREAK_POINT } from 'static/constants/styling-company-constants/companies/earth';
const refreshTime = 50; //ms

const urlPathProgressMappings = [
    {
        progress: 10,
        urlPaths: [urlPaths.demographicsInstructions, urlPaths.purchaser, urlPaths.earlyPriceListDisclaimer]
    },
    {
        progress: 20,
        urlPaths: [
            urlPaths.recipientCheck,
            urlPaths.recipient,
            urlPaths.recipientIdentity,
            urlPaths.recipientAbsent,
            urlPaths.recipientAvailabilityCheck,
            urlPaths.recipientConsent,
            urlPaths.recipientGratitude
        ]
    },
    {
        progress: 30,
        urlPaths: [urlPaths.beneficiaryCheck]
    },
    {
        progress: 40,
        urlPaths: [urlPaths.beneficiary]
    },
    {
        progress: 60,
        urlPaths: [
            urlPaths.irrevocable,
            urlPaths.stateFilingFee,
            urlPaths.middlePriceListDisclaimer,
            urlPaths.replacement,
            urlPaths.payment
        ]
    },
    {
        progress: 70,
        urlPaths: [urlPaths.disclaimerProduct]
    },
    {
        progress: 80,
        urlPaths: [urlPaths.orderSummary]
    },
    {
        progress: 90,
        urlPaths: [
            urlPaths.latePriceListDisclaimer,
            urlPaths.purchaserOrderSummary,
            urlPaths.ssnCapture,
            urlPaths.prefilledRecipientIdentity,
            urlPaths.prefilledRecipientConsent,
            urlPaths.prefilledRecipientGratitude,
            urlPaths.purchaserIdentity,
            urlPaths.purchaserPhoneVerificationRequest,
            urlPaths.purchaserPhoneVerify,
            urlPaths.funeralContracts,
            urlPaths.travelContracts,
            urlPaths.docusignFreeContractPage,
            urlPaths.docusignReroute,
            urlPaths.ssnCapture
        ]
    },
    {
        progress: 100,
        urlPaths: [
            urlPaths.purchaserSigningComplete,
            urlPaths.purchaseProcessing,
            urlPaths.vitalStatistics,
            urlPaths.vitalStatisticsSaved
        ]
    }
];

class EarthProgressBarComponent extends React.Component<PropShape, StateShape> {
    refreshPathName() {
        if (this.state && window.location.pathname !== this.state.activePath) {
            this.setState({
                activePath: window.location.pathname
            });
        }
        setTimeout(this.refreshPathName.bind(this), refreshTime);
    }

    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            activePath: window.location.pathname,
            windowWidth: window.innerWidth
        };
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
        setTimeout(this.refreshPathName.bind(this), refreshTime);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        const needsMobileLayout = this.state.windowWidth < EARTH_HEADER_BREAK_POINT;
        let progress = 0;
        urlPathProgressMappings.forEach(urlPathProgressMapping => {
            if (urlPathProgressMapping.urlPaths.some(urlPath => this.state?.activePath?.endsWith(urlPath))) {
                progress = urlPathProgressMapping.progress;
            }
        });
        let ProgressBarImageComponentSrc = '';
        switch (progress) {
            case 10:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound10 : EarthFlat10;
                break;
            case 20:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound20 : EarthFlat20;
                break;
            case 30:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound30 : EarthFlat30;
                break;
            case 40:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound40 : EarthFlat40;
                break;
            case 50:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound50 : EarthFlat50;
                break;
            case 60:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound60 : EarthFlat60;
                break;
            case 70:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound70 : EarthFlat70;
                break;
            case 80:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound80 : EarthFlat80;
                break;
            case 90:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound90 : EarthFlat90;
                break;
            case 100:
                ProgressBarImageComponentSrc = needsMobileLayout ? EarthRound100 : EarthFlat100;
                break;
        }
        return (
            <div>
                {ProgressBarImageComponentSrc && (
                    <img
                        style={{ width: needsMobileLayout ? '36px' : '100px' }}
                        src={ProgressBarImageComponentSrc}
                        alt="progress-bar"
                    />
                )}
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
}

interface StateShape {
    // Shape of local state
    activePath: string;
    windowWidth: number;
}

export default EarthProgressBarComponent;
