import React from 'react';
import HelloSign from 'hellosign-embedded';
import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';
import * as Sentry from '@sentry/browser';

class HelloSignSigningComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
            hasError: false
        };
    }

    static defaultProps = {
        // Default prop values
        helloSignClientId: '',
        verifyDomain: true,
        primaryButtonColor: '#00B3E6',
        customHellosignColorsFlag: false
    };

    componentDidMount() {
        if (this.props.hellosignEmbeddedUrl.toLowerCase().includes('hellosign')) {
            const helloSignClient = new HelloSign({
                clientId: this.props.helloSignClientId,
                timeout: 180000
            });
            helloSignClient.open(this.props.hellosignEmbeddedUrl, {
                skipDomainVerification: !this.props.verifyDomain,
                allowCancel: false,
                // https://app.hellosign.com/api/reference#WhiteLabeling
                whiteLabeling: this.props.customHellosignColorsFlag
                    ? {
                          page_background_color: '#F7F8F9', // eslint-disable-line
                          header_background_color: '#1A1A1A', // eslint-disable-line
                          text_color1: '#808080', // eslint-disable-line
                          text_color2: '#FFFFFF', // eslint-disable-line
                          link_color: this.props.primaryButtonColor, // eslint-disable-line
                          primary_button_color: this.props.primaryButtonColor, // eslint-disable-line
                          primary_button_text_color: '#FFFFFF', // eslint-disable-line
                          primary_button_color_hover: this.props.primaryButtonColor, // eslint-disable-line
                          primary_button_text_color_hover: '#FFFFFF', // eslint-disable-line
                          secondary_button_color: '#FFFFFF', // eslint-disable-line
                          secondary_button_text_color: this.props.primaryButtonColor, // eslint-disable-line
                          secondary_button_color_hover: '#FFFFFF', // eslint-disable-line
                          secondary_button_text_color_hover: this.props.primaryButtonColor // eslint-disable-line
                      }
                    : {}
            });
            helloSignClient.on('sign', data => {
                this.props.onSigningComplete();
            });
            helloSignClient.on('error', data => {
                console.error('User encountered error while signing hellosign contracts');
                Sentry.captureException(data);
                this.setState({
                    hasError: true
                });
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return <Redirect to={urlPaths.error} push />;
        }
        return null;
    }
}

export interface PropShape {
    // Shape of passed in props (including redux dispatch functions)
    hellosignEmbeddedUrl: string;
    onSigningComplete: () => void;
    helloSignClientId: string;
    verifyDomain: boolean;
    primaryButtonColor: string;
    customHellosignColorsFlag: boolean;
}

interface StateShape {
    // Shape of local state
    hasError: boolean;
}

export default HelloSignSigningComponent;
