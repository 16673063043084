import { connect } from 'react-redux';
import PurchaserIdentityPageComponent from './PurchaserIdentityPageComponent';
import GlobalStateShape from 'redux/GlobalStateShape';
import EverdaysPurchaserIdentityPageWrapper from 'components/account-specific-components/accounts/everdays/purchaser-identity-confirmation-page-component/EverdaysPurchaserIdentityPageWrapper';
import { EVERDAYS_STYLING_KEY } from 'static/constants/styling-company-constants/companies/everdays';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { setPurchaserPlaidIdentityConsentDisclaimerAddressingAction } from 'redux/disclaimerData/disclaimerAddressings/purchaserPlaidIdentityConsent';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        funeralFundingProduct: state.opportunity?.selectedFundingProduct?.productType,
        purchaserFirstName: getFormData(state, 'purchaser', 'firstName'),
        purchaserId: state.opportunity.purchaser.id
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {
        setPurchaserPlaidIdentityConsentDisclaimerAddressingAction: newValue =>
            dispatch(setPurchaserPlaidIdentityConsentDisclaimerAddressingAction(newValue))
    };
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(PurchaserIdentityPageComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EVERDAYS_STYLING_KEY]: EverdaysPurchaserIdentityPageWrapper
});
