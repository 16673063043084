import { connect } from 'react-redux';
import LoadingScreenComponent from './LoadingScreenComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreenComponent);
