import { GeographicState } from '../enums/states';

export const FilingFeeData = {
    MO: {
        stateCode: GeographicState.MO,
        stateName: 'Missouri',
        filingFee: 25,
        serviceFee: 1.5
    },
    SC: {
        stateCode: GeographicState.SC,
        stateName: 'South Carolina',
        filingFee: 20,
        serviceFee: 1.5
    },
    NC: {
        stateCode: GeographicState.NC,
        stateName: 'North Carolina',
        filingFee: 20,
        serviceFee: 1.5
    },
    IA: {
        stateCode: GeographicState.IA,
        stateName: 'Iowa',
        filingFee: 20,
        serviceFee: 1.5
    }
};
