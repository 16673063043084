import GlobalStateShape from '../../../redux/GlobalStateShape';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';
import urlPaths from '../../../static/constants/enums/urlPaths';
import {
    getAccountBySubdomain,
    getProductsForAccountId,
    getAgentProductsForAccountId,
    getTravelAccountIdByFuneralAccountId
} from '../../server-application-buffer/account-data';
import { setFuneralHomeProductsAction } from '../../../redux/availableProducts/funeralHomeProducts';
import { setTravelProtectionProviderProductsAction } from '../../../redux/availableProducts/travelProtectionProviderProducts';
import { setTaxRateAction } from '../../../redux/account/taxRate';
import { getAgentPermissionsForAccountId } from 'toolboxes/server-application-buffer/agent-data';
import { setAccountAgentAgentCodeAction } from 'redux/account/agent/agentCode';
import { setAccountAgentCanAddProductsAction } from 'redux/account/agent/canAddProducts';

export default function getProductSelectionInformation(
    state: GlobalStateShape,
    dispatch: Function
): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    if (state.featureFlags.useSalesForceAgents) {
        functionsToRun.push({
            promiseGenerator: () => {
                return new Promise<void>((resolve, reject) => {
                    getAgentPermissionsForAccountId(state.account.agent.id, state.account.id, (result, error) => {
                        if (error) {
                            return reject(error);
                        }

                        dispatch(setAccountAgentAgentCodeAction(result.contact.agentCode));
                        dispatch(setAccountAgentCanAddProductsAction(result.agentCanAddProducts));
                        return resolve();
                    });
                });
            },
            blocksPageStarts: [urlPaths.productSelection]
        });
    }

    functionsToRun.push({
        promiseGenerator: () => {
            return new Promise<void>((resolve, reject) => {
                const selectedSubdomain = state.account.subdomain;

                getAccountBySubdomain(selectedSubdomain, (result, error) => {
                    if (error) {
                        return reject(error);
                    }
                    const salesTax = result.salesTax > 1 ? result.salesTax / 100 : result.salesTax;
                    dispatch(setTaxRateAction(salesTax));
                    const accountId = result.id;
                    getAgentProductsForAccountId(accountId, (result, error) => {
                        if (error) {
                            return reject(error);
                        }
                        dispatch(setFuneralHomeProductsAction(result));
                        getTravelAccountIdByFuneralAccountId(accountId, (result, error) => {
                            if (error) {
                                return reject(error);
                            }
                            if (!result) {
                                return resolve();
                            }
                            getProductsForAccountId(result, (result, error) => {
                                if (error) {
                                    return reject(error);
                                }
                                dispatch(setTravelProtectionProviderProductsAction(result));
                                return resolve();
                            });
                        });
                    });
                });
            });
        },
        blocksPageStarts: [urlPaths.productSelection]
    });

    return functionsToRun;
}
