import { connect } from 'react-redux';
import ButtonSubtitledLinkComponent from './ButtonSubtitledLinkComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import accountComponentSwitch from 'toolboxes/account-specific-component-switch/account-flexible-connect';
import { EARTH_STYLING_KEY } from 'static/constants/styling-company-constants/companies/earth';
import EarthButtonSubtitledLinkWrapper from 'components/account-specific-components/accounts/earth/button-subtitled-link-component/EarthButtonSubtitledLinkWrapper';
import { TITAN_STYLING_KEY } from '../../../../static/constants/styling-company-constants/companies/titan';
import TitanButtonSubtitledLinkWrapper from '../../../account-specific-components/accounts/titan/button-subtitled-link-group-component/button-subtitled-link-button-component/TitanButtonSubtitledLinkWrapper';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        stylingOptions: state.stylingOptions
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

const DefaultComponent = connect(mapStateToProps, mapDispatchToProps)(ButtonSubtitledLinkComponent);

export default accountComponentSwitch<typeof DefaultComponent>({
    default: DefaultComponent,
    [EARTH_STYLING_KEY]: EarthButtonSubtitledLinkWrapper,
    [TITAN_STYLING_KEY]: TitanButtonSubtitledLinkWrapper
});
