import React, { CSSProperties } from 'react';
import { Redirect } from 'react-router-dom';
import ApplicationStyling from '../../../../../../static/models/ApplicationStyling';
import { Button } from '@material-ui/core';

class TitanButtonSubtitledLinkComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            redirecting: false,
            isHovered: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        // Default prop values
        navigationSideEffect: null,
        buttonTitleFontFamily: '',
        stylingOptions: null
    };

    handleClick() {
        if (this.props.navigationSideEffect) {
            this.props.navigationSideEffect();
        }
        this.setState({ redirecting: true });
    }

    render() {
        if (this.state.redirecting) {
            return <Redirect to={this.props.to} push />;
        }
        let buttonStyle: CSSProperties = {
            color: this.props.stylingOptions.colors.formFieldsTextColor,
            backgroundColor: this.props.stylingOptions.colors.formFieldsBackgroundColor,
            fontSize: this.props.stylingOptions.fonts.buttonFontSize,
            fontFamily: this.props.stylingOptions.fonts.bodyFontFamily,
            width: '90%',
            height: '51px',
            borderColor: this.props.stylingOptions.colors.primaryColor,
            borderStyle: 'solid',
            borderWidth: 3,
            borderRadius: '15px'
        };

        if (this.state.isHovered) {
            buttonStyle = {
                ...buttonStyle,
                color: this.props.stylingOptions.colors.primaryContrastColor,
                backgroundColor: this.props.stylingOptions.colors.primaryColor
            };
        }
        return (
            <div style={{ marginRight: '5px', marginLeft: '5px' }}>
                <Button
                    onMouseEnter={() => this.setState({ isHovered: true })}
                    onMouseLeave={() => this.setState({ isHovered: false })}
                    onClick={this.handleClick}
                    id={'button-' + this.props.buttonId}
                    style={buttonStyle}
                    variant="contained"
                    disableElevation
                    disableRipple
                >
                    <div style={{ textAlign: 'center' }}>
                        <div>{this.props.title}</div>
                        <div>{this.props.subtitle}</div>
                    </div>
                </Button>
            </div>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    to: string;
    title: string;
    subtitle: string;
    navigationSideEffect: Function;
    buttonId: string;
    stylingOptions: ApplicationStyling;
}

interface StateShape {
    // Shape of local state
    redirecting: boolean;
    isHovered: boolean;
}

export default TitanButtonSubtitledLinkComponent;
