export enum EndOfExperienceOptions {
    PurchaseProcessing = 'PurchaseProcessing',
    NavigateToUrl = 'NavigateToUrl',
    NavigationPageWithUrl = 'NavigationPageWithUrl',
    BlankPage = 'BlankPage'
}

export const requiresEndOfExperienceReturnLink = (opt: EndOfExperienceOptions): boolean => {
    return [EndOfExperienceOptions.NavigateToUrl, EndOfExperienceOptions.NavigationPageWithUrl].includes(opt);
};
