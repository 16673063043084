import React from 'react';
import TextBoxWrapper from '../base-form-components/text-box-component/TextBoxWrapper';
import FormWrapper from '../base-form-components/form-component/FormWrapper';
import StateSelectionWrapper from '../base-form-components/state-selection-component/StateSelectionWrapper';
import { validateMotherVital } from '../../../toolboxes/redux-form/redux-form-validation';
class MotherVitalFormComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }

    static defaultProps = {
        // Default prop values
    };

    render() {
        return (
            <FormWrapper formName="motherVital" validate={validateMotherVital}>
                <TextBoxWrapper
                    formName="motherVital"
                    fieldPath="firstName"
                    placeholder=""
                    labelString="First Name"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="motherVital"
                    fieldPath="middleName"
                    placeholder=""
                    labelString="Middle Name"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="motherVital"
                    fieldPath="lastName"
                    placeholder=""
                    labelString="Last Name"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="motherVital"
                    fieldPath="maidenName"
                    placeholder=""
                    labelString="Maiden Name"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 6
                    }}
                />
                <TextBoxWrapper
                    formName="motherVital"
                    fieldPath="birthCity"
                    placeholder=""
                    labelString="City of Birth"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 8
                    }}
                />
                <StateSelectionWrapper
                    formName="motherVital"
                    fieldPath="birthState"
                    labelString="State of Birth"
                    extraFieldProps={{
                        disabled: false
                    }}
                    spacing={{
                        xs: 12,
                        sm: 4
                    }}
                />
            </FormWrapper>
        );
    }
}

export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    // The combination of redux form and typescript may require that some properties be optional
}

interface StateShape {
    // Shape of local state
}

export default MotherVitalFormComponent;
