import { connect } from 'react-redux';
import ContractsDeniedPageComponent from './ContractsDeniedPageComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        phoneNumber: state.account.phone
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsDeniedPageComponent);
