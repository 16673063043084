import { connect } from 'react-redux';
import BankingInformationFormComponent from './BankingInformationFormComponent';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import { validateAccountNumber } from '../../../toolboxes/redux-form/redux-form-validation';

const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        accountNumberValidation: validateAccountNumber
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankingInformationFormComponent);
